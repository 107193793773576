import React from "react";
import { pick, uniqBy } from "lodash";
import { FiDownload } from "react-icons/fi";
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalContent, ModalHeader, ModalBody, SimpleGrid, useBoolean, ModalFooter, Checkbox, IconButton } from '@chakra-ui/react';
import { Form, renderField } from "~/components/Form/Form";
import { fields, forms } from '~/pages/projects/meta/data/documents.schema';
import { ProjectIds } from "~/pages/projects/types";
import { join, resolveFormFields } from "~/utils";
import { useModal, useSnippetId } from "~/hooks";
import log from "~/log";

export const CoverLetterModal = (props?: ProjectIds & { id?: string, documents?: any }) => {
    const [isLoading, setIsLoading] = useBoolean(false);
    const [saveDocument, setSaveDocument] = useBoolean()
    const { typSnippetId: documentTypeSnippetId, data: snippetData }: any = useSnippetId({ name: 'Anschreiben', category: 'Dokument', property: 'Typ', });
    const formFields = resolveFormFields(forms.coverLetter, fields.coverLetter);
    const { onClose, dispatch, state } = useModal();
    const { pdf, createDocumentFromSignedUrl, subprojectId } = state?.modals?.props || {}
    const { handleSubmit, reset, control, register, formState: { errors } } = useForm({
        defaultValues: {},
    });

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (values) => {
            log.debug(
                'onSubmit.values',
                JSON.stringify({ values, subproject: pdf?.subproject }, null, 2)
            );

            /** pass the address of object if contact has no address  */
            const client = [
                values?.coverLetterAddress?.value?.kontakt?.plz,
                values?.coverLetterAddress?.value?.kontakt?.ort,
                values?.coverLetterAddress?.value?.kontakt?.strasse,
            ].every(Boolean)
                ? values?.coverLetterAddress?.value?.kontakt
                : {
                    ...values?.coverLetterAddress?.value?.kontakt,
                    ...pick(pdf.subproject.projekt, 'plz', 'ort', 'strasse'),
                };

            const { response } = await pdf.coverLetter({
                client,
                freitext: JSON.stringify(values?.coverLetterText),
                ...values,
            });

            if (saveDocument) {
                await createDocumentFromSignedUrl(response?.signedUrl, {
                    data: {
                        beschreibung: ' ',
                        subprojektId: subprojectId,
                        typSnippetId: documentTypeSnippetId
                    },
                    freitext: JSON.stringify(values?.coverLetterText),
                    fileName: snippetData?.[0]?.kuerzel || 'anschreiben'
                });
            }

        },
        [pdf.coverLetter, createDocumentFromSignedUrl, saveDocument]
    );

    const onSubmitWithOnClose = async (values: any) => {
        setIsLoading.on();
        await onSubmit(values);
        setIsLoading.off();
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset({});
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                p={5}
                borderColor="gray.200"
            >
                Anschreiben
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={1} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {
                                        options: uniqBy(pdf?.subproject?.beteiligte, 'kontakt.id')?.map(
                                            (participant: any) => ({
                                                label: join([
                                                    participant?.kontakt?.name,
                                                    participant?.kontakt?.vorname,
                                                    participant?.kontakt?.firma1,
                                                ]),
                                                value: participant,
                                            })
                                        ),
                                    },
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Checkbox onChange={setSaveDocument.toggle} isChecked={saveDocument}>als Dokument speichern</Checkbox>
                <IconButton
                    ml={3}
                    aria-label="download-icon-button"
                    colorScheme="blue"
                    icon={<FiDownload />}
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmitWithOnClose)}
                />
            </ModalFooter>
        </ModalContent>
    );
};
