import { tableColumnMapper } from '~/utils';

// Tables
const columns = {
  documents: {
    __graphqlType: 'Stromzaehler',
    id: { header: 'Id', accessor: 'id' },
    typSnippet: { header: 'Typ', accessor: 'typSnippet.name', width: 80 },
    upload: { header: 'Upload', accessor: 'upload' },
    size: { header: 'Grösse', accessor: 'groesse' },
    name: { header: 'Name', accessor: 'name', renderer: { name: 'DownloadLink' } },
    description: { header: 'Beschreibung', accessor: 'beschreibung' },
    controls: { accessor: 'controls', width: 20, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  documents: {
    columns: ['id', 'typSnippet', 'name', 'description', 'controls'].map(tableColumnMapper(columns.documents)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  documents: {
    __graphqlInput: 'DokumentInput',
    name: { api: { path: 'name', required: true } },
    path: { api: { path: 'pfad', required: true } },
    typeSnippetId: { api: { path: 'typSnippetId' } },
    subprojectId: { api: { path: 'subprojektId' } },
    description: { api: { path: 'beschreibung', required: true } },
  },
  coverLetter: {
    text: { api: { path: 'anschreiben' } },
    address: { path: 'anschrift' },
  },
};

export const forms = {
  documents: [
    { path: 'subprojectId', ui: { label: 'Subprojekt Id' }, visibility: { hide: () => true }, validation: false },
    {
      path: 'documents',
      ui: (context = {}) => ({ label: 'Anhang', colSpan: 2, component: 'DocumentUpload', props: context }),
      spread: true,
      // required: true,
      decorators: {
        api: [
          (value = {} as any) => {
            const { key, fileName } = value;
            return { name: fileName, pfad: key };
          },
        ],
      },
    },
    { path: 'description', ui:({ isDisabled= false } = {} as any) =>({ label: 'Beschreibung', colSpan: 2, component: 'Textarea', props:{ isDisabled } }) },
    { path: 'name', ui: { label: 'Name' }, visibility: { hide: () => true }, validation: false },
    { path: 'path', ui: { label: 'Pfad' }, visibility: { hide: () => true }, validation: false },
    { path: 'typeSnippetId', ui: { label: 'Typ' }, visibility: { hide: () => true }, validation: false },
  ],
  coverLetter: [
    {
      path: 'coverLetter.address',
      ui: (context: any = {}) => ({
        label: 'Adressat',
        component: 'Select',
        props: {
          options: context?.options || [],
        },
      }),
    },
    { path: 'coverLetter.text', ui: { label: 'Anschreiben', hideLabel: true, component: 'RichTextEditor' } },
  ],
};
