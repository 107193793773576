import React from 'react';
import { Editor, Element as SlateElement, Transforms, Node } from 'slate';
import { useSlate } from 'slate-react';
import { Textbaustein } from "~/gql/ucpw/graphql";
import { Button, Box, IconButton, HStack, Tooltip, SimpleGrid } from '@chakra-ui/react';
import { BiBold, BiItalic, BiUnderline, BiListUl, BiListOl } from 'react-icons/bi';
import { RiPageSeparator } from 'react-icons/ri';
import { SelectTextSnippet } from './SelectTextSnippet';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const toggleBlock = (editor: any, format: any) => {
    const { isActive } = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
        split: true,
    });
    const newProperties: Partial<SlateElement> = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};


const isBlockActive = (editor: any, format: any) => {
    try {
        const { selection } = editor;
        if (!selection) {
            return { isActive: false };
        }
        const [match] = Editor.nodes(editor, {
            match: (n: any) => {
                return n?.type === format
            },
        });
        return { isActive: !!match, match }
    } catch (error) {
        // TODO: log to sentry
        console.error(`Cannot find a descendant at path`);
        return { isActive: false }
    }

}

// FIXME: does not work
const isMarkActive = (editor: any, format: any) => {
    try {
        const marks = Editor.marks(editor);
        return marks ? marks?.[format as keyof typeof marks] === true : false;
    } catch (error) {
        // TODO: log to sentry
        console.error(`Cannot find a descendant at path`);
        return false;
    }
};

const BlockButton = ({ format, icon, ...props }: any) => {
    const editor = useSlate();
    const { isActive } = isBlockActive(editor, format)
    const [nodeType] = Object.values(editor?.children?.[editor?.selection?.anchor?.path?.[0] as keyof typeof editor.children] || {})
    const filtered = ['numbered-list', 'bulleted-list', 'paragraph-mark', 'page-break'].filter(item => !(item === nodeType));
    const map = {
        'numbered-list': 'Nummerierte Liste',
        'bulleted-list': 'Liste',
        'page-break': 'Seitenumbruch'
    }

    return (<Tooltip hasArrow label={map?.[format as keyof typeof map]} placement='top'>
        <IconButton
            isDisabled={[undefined, 'paragraph', 'list-item', ''].includes(nodeType) ? false : filtered.includes(format)}
            isActive={isActive}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
            aria-label={format}
            icon={icon}
            {...props}
        />
    </Tooltip>
    );
};

const MarkButton = ({ format, icon, ...props }: any) => {
    const map = {
        bold: 'Fett',
        italic: 'Kursiv',
        underline: 'Unterstrich'
    }
    const editor = useSlate();

    return (
        <Tooltip hasArrow label={map?.[format as keyof typeof map]} placement='top'>
            <IconButton
                bg="gray.100"
                isActive={isMarkActive(editor, format)}
                onMouseDown={(event) => {
                    event.preventDefault();
                    toggleMark(editor, format);
                }}
                aria-label={format}
                icon={icon}
                {...props}
            />
        </Tooltip>
    );
};

function Toolbar({ insertNode = () => null, hasSnippets = true, isDisabled = false }: { insertNode?: (nodes?: Node | Node[]) => void, hasSnippets?: boolean, isDisabled?: boolean }) {
    const [state, setState] = React.useState<{
        textbaustein: Textbaustein;
        nodes: Node | Node[];
    }>();

    const props = { ...(!hasSnippets && { size: 'sm' }), isDisabled }

    return (
        <SimpleGrid columns={{ sm: 1, md: 1, lg: 1, xl: 1, '2xl': 2 }} spacing={4} >
            <HStack spacing={2}>
                <MarkButton format="bold" icon={<BiBold />} {...props} />
                <MarkButton format="italic" icon={<BiItalic />}  {...props} />
                <MarkButton format="underline" icon={<BiUnderline />}  {...props} />
                <BlockButton format="numbered-list" icon={<BiListOl />}  {...props} />
                <BlockButton format="bulleted-list" icon={<BiListUl />}  {...props} />
                {hasSnippets && <BlockButton format="page-break" icon={<RiPageSeparator />}  {...props} />}
            </HStack>
            {hasSnippets && <HStack spacing={2} alignSelf="end">
                <Box minWidth={250} data-test-id="rte-select-snippet">
                    <SelectTextSnippet
                        textbaustein={state?.textbaustein}
                        onChange={(textbaustein: any) => {
                            try {
                                setState({
                                    textbaustein,
                                    nodes: textbaustein?.beschreibung?.raw ? JSON.parse(textbaustein?.beschreibung?.raw) : [{ type: 'paragraph', children: [{ text: '' }] }],
                                });
                            } catch (error) {
                                console.error(`Could not parse Textbaustein`);
                            }
                        }}
                    />
                </Box>
                <Button
                    ml={2}
                    data-test-id="rte-add-button"
                    colorScheme="blue"
                    disabled={!state?.textbaustein}
                    onClick={() => {
                        if (state?.nodes) {
                            insertNode(state?.nodes);
                        };
                        setState(undefined);
                    }}
                >
                    Einfügen
                </Button>
            </HStack>}
        </SimpleGrid>
    );
}

export { Toolbar };
