import { DataTable, Card, CardHeader, CardActions, CardContent, CardHeading, PlusButton } from '@ucc/react/ui';
import { HasPermission } from '~/layout/HasPermission';
import { useParticipants } from '../hooks/useParticipants';

export function Participants({ subprojectId }: any) {
    const { onCreate, ...dataTableProps } = useParticipants({ subprojectId });

    return (
        <HasPermission resource="project.participants" permission="view">
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>Beteiligte</CardHeading>
                    <CardActions>
                        <HasPermission resource="project.participants" permission="create">
                            <PlusButton onClick={onCreate}>Neuer Beteiligter</PlusButton>
                        </HasPermission>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </HasPermission>
    );
}
