import { DataTable, Card, CardHeader, CardActions, CardContent, CardHeading, PlusButton } from '@ucc/react/ui';
import { HasPermission } from '~/layout/HasPermission';
import { useTasks } from '../hooks/useTasks';

export function Tasks(state: any) {
    const { onCreate, ...dataTableProps } = useTasks({ subprojectId: state.subprojectId, state });

    return (
        <HasPermission resource="project.tasks" permission="view">
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>Aufgaben</CardHeading>
                    <CardActions>
                        <HasPermission resource="project.tasks" permission="create">
                            <PlusButton onClick={onCreate}>Neue Aufgabe</PlusButton>
                        </HasPermission>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </HasPermission>
    );
}
