import React from 'react';
import { chakra } from '@chakra-ui/react';
import { useAsset } from '~/hooks';
import log from '~/log';

type DownloadLinkProps = {
    getValue: () => string;
    getPath: (row: any, value: any) => string;
    row: any;
};

export const DownloadLink = ({
    row,
    getPath = (row: any) => row?.original?.pfad,
    getValue,
}: DownloadLinkProps) => {
    const value = getValue();
    const { download } = useAsset();

    const downloadAsset = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const path = getPath(row, value);
        const migratedFile = path?.startsWith('docs/');

        // Migrated files (~200GB) from the legacy system are not available in development
        // these files can be identified by the path starting with docs/
        // new files are stored in the assets/ folder
        if (process.env.NODE_ENV === 'development' && migratedFile) {
            log.error(`In development we don't have access to the migrated files (~200GB).`, {
                row,
                value,
                path,
                migratedFile,
            });
            return;
        }

        const url = await download(path);

        log.debug('DownloadLink.url', { path, url });
        if (url) {
            window.open(url);
        }
    };

    return (
        <chakra.a
            color="blue.500"
            cursor="pointer"
            aria-label="asset-link"
            onClick={downloadAsset}
            whiteSpace="nowrap"
        >
            {value}
        </chakra.a>
    );
};
