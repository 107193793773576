import React, { useCallback, useState } from 'react';
import { useDialog } from '~/hooks/useDialog';
import { useQuery } from '@apollo/client';
import { SelectSubprojectResidentialUnitsDocument, SortOrder } from '~/gql/ucpw/graphql';
import { useWattro } from '~/pages/projects/ui/Wattro/hooks/useWattro';

export const useWattroDialog = () => {
    const { isOpen, onClose, type, data } = useDialog();
    const isModalOpen = isOpen && type === 'createWattro';
    const context = { clientName: 'ucpw' };
    const variables = {
        limit: 50,
        orderBy: [{ wohneinheit: { istGesamtobjekt: SortOrder.Asc } }],
        filter: { subprojektId: data.id },
    };
    const {
        data: selectSubprojectResidentialUnitsData,
        loading: selectSubprojectResidentialUnitsLoading,
    } = useQuery(SelectSubprojectResidentialUnitsDocument, {
        context,
        variables,
        skip: !data?.id,
    });
    const { wattroCreateProjectForSubprojektWohneinheit, loading: wattroLoading } = useWattro({
        refetchQueries: [
            {
                query: SelectSubprojectResidentialUnitsDocument,
                context,
                variables,
            },
        ],
    });
    const residentialUnits = selectSubprojectResidentialUnitsData?.items?.items || [];
    const loading = wattroLoading || selectSubprojectResidentialUnitsLoading;

    console.log('WattroDialog', { data, residentialUnits });

    const [units, setUnits] = useState<any[]>([]);
    const hint = units?.some?.((unit: any) => unit?.wohneinheit?.istGesamtobjekt === false)
        ? 'Wählen Sie hier die Wohneinheiten aus, in denen Trocknungsgeräte aufgebaut werden sollen. Für jede ausgewählte Wohneinheit legen wir Ihr Projekt in wattro an.'
        : 'Es wurden noch keine Wohneinheiten angelegt. Die Anlage der wattro Projekte könnte unvollständig sein. Bitte prüfen Sie die Wohneinheiten und legen den wattro Termin gegebenenfalls nachträglich aus dem Geräteeinsatz an.';

    const onSubmit = useCallback(async () => {
        await Promise.all(
            units
                .filter((unit: any) => unit.isChecked && !unit.wattroProjectExists)
                .map(
                    async (unit: any) =>
                        await wattroCreateProjectForSubprojektWohneinheit({
                            variables: { subprojektWohneinheitId: unit.id },
                        })
                )
        );
        onClose();
    }, [units, wattroCreateProjectForSubprojektWohneinheit, onClose]);

    const onCheck = (e: React.ChangeEvent<HTMLInputElement>, id: number) =>
        setUnits((prev: any) =>
            prev.map((unit: any) =>
                unit?.id === id ? { ...unit, isChecked: e?.target?.checked } : unit
            )
        );

    React.useEffect(() => {
        if (residentialUnits.length) {
            setUnits(
                residentialUnits?.map?.((unit: any) => ({
                    ...unit,
                    isChecked: !unit?.wohneinheit?.istGesamtobjekt || unit?.wattroProjectExists,
                }))
            );
        }
    }, [residentialUnits]);

    return { isOpen: isModalOpen, hint, loading, units, onClose, onSubmit, onCheck };
};
