import React from 'react';
import { VStack } from '@chakra-ui/react';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { FinanceOverview } from './FinanceOverview';
import { useProjectParams } from '../../hooks/useProjectParams';
import { FinanceOfferForm } from './FinanceOfferForm';
import { FinanceInvoiceForm } from './FinanceInvoiceForm';
import { MultiModal } from '~/components/MultiModal';
import { SelectGeneralAggrementsModalContent } from './SelectGeneralAggrementsModalContent';
import { calculationReducer, combineReducers, modalReducer, rowReducer } from '~/reducers';
import { FreePositionModalContent } from './FinanceFreePositionModalContent';
import { GeneralAgreementModalContent } from './FinanceGeneralAgreementModalContent';
import { useModal } from '~/hooks';
import { useIsStandardGeneralAgreementId } from '~/pages/projects/hooks/useIsStandardGeneralAgreementId';
import { financeMachine } from './financeMachine';
import { useFinanceServices } from './hooks/useFinanceServices';
import { ProjectIds } from '../../types';
import { LOCAL_STORAGE_KEY } from './constants'
import { usePdfRefetchQueries } from '../../hooks/usePdf';
import { useViewer, usePersistedMachine } from '~/hooks';
import { join } from '~/utils';
import log from '~/log'

export const Modals = {
    EditFreePosition: 'EditFreePosition',
    SelectGeneralAggrements: 'SelectGeneralAggrements',
    SelectStandardGeneralAggrements: 'SelectStandardGeneralAggrements',
    EditGeneralAggrementPosition: 'EditGeneralAggrementPosition',
};


export function Finance() {
    const viewer = useViewer()
    const canCreateInvoice = viewer?.hasPermission('additional.createInvoice', 'enabled')
    log.debug('Finance.permissions', { canCreateInvoice })
    const { projectId, subprojectId } = useProjectParams()
    const { refetchQueries } = usePdfRefetchQueries({ subprojectId })
    const { editable, editType } = useEditable();
    const isOffer = editable && editType === 'offer';
    const isInvoice = editable && editType === 'invoice';
    const services = useFinanceServices({ refetchQueries, subprojectId });
    const sessionStorageKey = join([LOCAL_STORAGE_KEY, projectId, subprojectId], '-')
    const isStandard: any = useIsStandardGeneralAgreementId()
    const [state, send] = usePersistedMachine(financeMachine, {
        sessionStorageKey,
        services,
        context: {
            projectId,
            subprojectId,
            isOffer,
            isInvoice,
            // isStandard: 2543,
            isStandard,
        },
    })
    const { subproject } = state.context;
    const loading = !state.matches('idle');

    const projectIds: ProjectIds = { subprojectId, projectId }

    const props: any = {
        ...projectIds,
        send,
        context: state?.context,
    }


    useModal({
        defaultState: { modals: { activeModal: Modals.EditFreePosition } },
        dependencies: [state?.context, isStandard],
        component: <MultiModal>
            <FreePositionModalContent id={Modals.EditFreePosition} {...props} />
            <SelectGeneralAggrementsModalContent
                id={Modals.SelectGeneralAggrements}
                generalAgreementId={state?.context?.generalAgreementId}
                {...props}
            />
            <SelectGeneralAggrementsModalContent
                id={Modals.SelectStandardGeneralAggrements}
                generalAgreementId={isStandard}
                {...props}
            />
            <GeneralAgreementModalContent id={Modals.EditGeneralAggrementPosition} {...props} />
        </MultiModal>,
        reducer: combineReducers({
            modals: modalReducer(Modals.EditFreePosition),
            row: rowReducer,
            calculation: calculationReducer,
        }),
    });

    return <VStack spacing={6} p={6}>
        {isOffer &&
            <FinanceOfferForm
                viewer={viewer}
                send={send}
                context={state?.context}
                loading={state.matches('updateOffer') || state.matches('createOffer')}
                {...projectIds} />}
        {isInvoice &&
            <FinanceInvoiceForm
                viewer={viewer}
                send={send}
                context={state?.context}
                loading={state.matches('updateInvoice') || state.matches('createInvoice')}
                {...projectIds} />}
        {!editable &&
            <FinanceOverview
                send={send}
                subproject={subproject}
                context={state?.context}
                loading={loading}
                viewer={viewer}
                {...projectIds}
            />}
    </VStack>
}
