import { gql, useApolloClient } from '@apollo/client';
import { GetSignedUrlGetDocument, GetSignedUrlPutDocument } from '~/gql/ucc/graphql';
import log from '~/log';

export const GET_SIGNED_URL_GET = gql`
    query GetSignedUrlGet($key: String!) {
        assets {
            getSignedUrlGet(key: $key) {
                data
                error {
                    code
                    data
                    message
                }
            }
        }
    }
`;

export const GET_SIGNED_URL_PUT = gql`
    query GetSignedUrlPut($input: AssetsGetSignedUrlPutInput!) {
        assets {
            getSignedUrlPut(input: $input) {
                data {
                    type
                    key
                    name
                    signedUrl
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    }
`;

function useAsset() {
    const client = useApolloClient();

    const upload = async (
        file: File,
        { prefix = 'assets' } = {}
    ): Promise<{ key?: string | null; name?: string | null }> => {
        const response = await client.query({
            query: GetSignedUrlPutDocument,
            fetchPolicy: 'no-cache',
            variables: { input: { name: file.name, size: file.size, type: file.type, prefix } },
        });

        const { getSignedUrlPut } = response.data.assets || {};
        const { signedUrl, key, name } = getSignedUrlPut?.data || {};

        if (!signedUrl) {
            log.error('Failed to get signed URL for upload.');
            return {};
        }

        try {
            const { status, statusText } = await fetch(signedUrl, {
                method: 'PUT',
                body: file,
                headers: { 'Content-Type': file.type },
            });
            console.log('useAsset.upload', { status, statusText });
            return { key, name };
        } catch (error) {
            log.error(error);
            return {};
        }
    };

    const download = async (key: string) => {
        const { data } = await client.query({ query: GetSignedUrlGetDocument, variables: { key } });
        const signedUrl = data?.assets?.getSignedUrlGet?.data;
        log.debug('useAsset.download', { key, signedUrl });
        return signedUrl;
    };

    return { upload, download };
}

export { useAsset };
