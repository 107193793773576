import { get } from 'lodash';
import { camelCase } from 'change-case';
import { format } from 'date-fns';
import log from '~/log';

const RTE_DEFAULT_VALUE = [{ type: 'paragraph', children: [{ text: '' }] }];

// TODO: typescript types
const formDecorators = [
    // Values format decorator
    (value: any, context: any) => {
        const { field } = context;
        const ui = typeof field?.ui === 'function' ? field?.ui() : field?.ui;
        if (['QuerySelect', 'Select', 'SnippetSelect'].includes(ui?.component)) {
            return Array.isArray(value)
                ? value.map((optionValue) => ({
                      value: optionValue,
                      label: 'loading...',
                  }))
                : { value: value, label: 'loading...' };
        }

        if (['RichTextEditor'].includes(ui?.component)) {
            try {
                const raw = value.__typename === 'Richtext' ? value.raw : value;
                return JSON.parse(raw);
            } catch (error) {
                log.error('RichTextEditor.error', { field, value }, error);
                return RTE_DEFAULT_VALUE;
            }
        }

        return value;
    },
    (value: any, context: any) => {
        const { field } = context;
        const ui = typeof field?.ui === 'function' ? field?.ui() : field?.ui;

        const dateFormat =
            ui?.props?.type === 'datetime-local' ? `yyyy-MM-dd'T'hh:mm` : `yyyy-MM-dd`;

        if (['DatePicker'].includes(ui?.component)) {
            return format(new Date(value), dateFormat);
        }

        return value;
    },
];

export const graphQlToFormFields =
    (form = [], fields: any = {}, options: { pathLookup?: 'req' | 'res' } = {}) =>
    (data: any = {}) => {
        const { pathLookup = 'res' } = options;

        return form.filter(Boolean).reduce((acc, field: any) => {
            const { api = {} } = get(fields, field.path) || {};
            const { isDisabled = false } = field;
            const formFieldName = camelCase(field.path);
            const path = api.path?.[pathLookup] || api.path;
            const graphQLResponseValue = get(data, path);

            if (isDisabled || !graphQLResponseValue) {
                log.trace(`gqlToForm.earlyExit.${formFieldName}`, {
                    isDisabled,
                    graphQLResponseValue,
                    data,
                    path,
                });
                return acc;
            }

            const decorators = [...(field.decorators?.form || []), ...formDecorators];
            const context = { field, data };
            const formFieldValue = decorators.reduce(
                (value, decorate) => decorate(value, context),
                graphQLResponseValue
            );
            log.trace('gqlToForm.formFieldValue', formFieldValue);
            const replacedHtmlCode =
                typeof formFieldValue === 'string'
                    ? formFieldValue
                          ?.replace?.(/m&sup2/g, 'm²')
                          ?.replace?.(/m&sup3/g, 'm³')
                          ?.trim()
                    : formFieldValue;

            log.trace('gqlToForm', formFieldName, replacedHtmlCode);

            return {
                ...acc,
                [formFieldName]: replacedHtmlCode,
            };
        }, {});
    };
