import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { omit, isEqual } from 'lodash';
import { Button, Container, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { Card } from '@ucc/react/ui';
import { Form, renderField } from '~/components/Form/Form';
import { SectionCard } from '~/components/SectionCard';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { fields, forms } from '~/pages/projects/meta/data/finance.schema';
import { resolveFormFields } from '~/utils';
import { ProjectIds } from '~/pages/projects/types';
import { FinanceCalculation } from './FinanceCalculation';
import { AngebotInput } from '~/gql/ucpw/graphql';
import { VALID_OFFER_STATUS_KEY } from './constants';
import { FinanceContext } from './financeMachine';
import { useFinanceOfferCalculation } from '../../hooks/useFinanceOfferCalculation';
import { OverrideActions } from '~/components/Layout/PageHeader';
import { usePermission } from '~/hooks';
import log from '~/log';

type FinanceOfferFormProps = ProjectIds & { generalAgreementIdIsStandard?: boolean, context?: FinanceContext, send?: (args?: any) => void, loading?: boolean, viewer?: any }

export const FinanceOfferForm = ({ projectId, subprojectId, send, loading, viewer, ...props }: FinanceOfferFormProps) => {
    const permissions = usePermission('finance.offer')
    const releaseOfferEnabled = viewer?.hasPermission?.('additional.releaseOffer', 'enabled')
    const { canEdit, canCreate, canView } = permissions || {}
    const { cache = {}, client, branchId } = props?.context || {}
    const { updateEditable }: any = useEditable();
    const { offerId, offer = {}, isValidOffer } = cache;
    const vatRate = offer?.mwstSatz === 0 ? { vatRate: { value: 0, label: '0%' } } : {}
    const formFields = resolveFormFields(forms.offer, fields.offer);
    const offerValues = formFields.toForm(offer);
    const defaultValues = {
        ...formFields.defaultValues,
        ...offerValues,
        ...vatRate,
        subprojectId,
        client,
    };
    const defaultValuesRef = React.useRef(defaultValues)
    const canSave = offerId && canEdit ? true : !offerId && canCreate ? true : false


    const { control, register, handleSubmit, formState: { errors }, watch, reset } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    const { statusSnippet } = watch();

    const isValidStatus = statusSnippet?.key === VALID_OFFER_STATUS_KEY;
    const isCreatePositions = !isValidOffer && isValidStatus;
    const isFixedCalculation = isValidOffer;

    const context = {
        isFixedCalculation,
        offerId,
        subprojectId,
        branchId,
        releaseOfferEnabled: releaseOfferEnabled  // "Angebot freigeben" only with enabled additional.releaseOffer and role "Kalkulationsprüfer"
    };

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (values) => {
            const vatRate = values?.vatRate?.value === 0 ? { mwstSatz: 0.0 } : {}
            log.debug('onSubmit.values', values);
            const fixedValues = { subprojectId: subprojectId };
            log.debug('onSubmit.fixedValues', fixedValues);
            const data = omit(
                formFields.toGql(values, defaultValues, fixedValues),
                'attachmentId',
                'dokument',
                'client'
            ) as AngebotInput;
            log.debug('onSubmit.data', data);
            log.debug('onSubmit.createPositions', {
                isValidOffer,
                isValidStatus,
                isCreatePositions,
            });


            offerId ?
                send?.({ type: 'SUBMIT', variables: { id: offerId, data: { ...data, ...vatRate } } }) :
                send?.({ type: 'SUBMIT', variables: { data: { ...data, ...vatRate, nummer: ' ' } } })


            updateEditable();
        },
        [send, subprojectId, offerId, isCreatePositions]
    );


    React.useEffect(() => {
        if (!isEqual(defaultValues, defaultValuesRef.current)) {
            reset(defaultValues)
            defaultValuesRef.current = defaultValues
        }
    }, [defaultValues, defaultValuesRef.current])

    const fixed = isValidStatus || !canSave
    const { columns = [], hiddenColumns = [] } = useFinanceOfferCalculation({ send, fixed: canView ? !canView : fixed, permissions: { ...permissions, canDelete: canSave } });

    return (
        <>
            <OverrideActions>
                <HStack>
                    <Button
                        data-test-id={`cancel`}
                        size="sm"
                        isDisabled={loading}
                        onClick={() => {
                            updateEditable()
                            send?.('CANCEL')
                        }}>
                        {canSave ? 'Abbrechen' : 'Schließen'}
                    </Button>
                    {canSave && <Button
                        data-test-id={`save`}
                        colorScheme="blue"
                        size="sm"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={loading}
                    >
                        Speichern
                    </Button>}
                </HStack>
            </OverrideActions>
            <Container maxW="container.lg" p={3}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <VStack alignItems="center" spacing={14}>
                        <SectionCard title="Angebotsstammdaten">
                            <SimpleGrid spacing={4} columns={2} mb={4}>
                                {formFields.fields
                                    .filter(
                                        (field: any) => !['description', 'footer'].includes(field.name)
                                    )
                                    .map((field: any) => (
                                        <React.Fragment key={field.name}>
                                            {renderField({
                                                field,
                                                control,
                                                register,
                                                errors,
                                                context
                                            })}
                                        </React.Fragment>
                                    ))}
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard title="Kopftext">
                            {formFields.fields
                                .filter((field: any) => field.name === 'description')
                                .map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            context,
                                        })}
                                    </React.Fragment>
                                ))}
                        </SectionCard>
                        <SectionCard title="Fußtext">
                            {formFields.fields
                                .filter((field: any) => field.name === 'footer')
                                .map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            context,
                                        })}
                                    </React.Fragment>
                                ))}
                        </SectionCard>
                    </VStack>
                </Form>
            </Container>
            <Card boxShadow="none">
                <FinanceCalculation
                    context={{ ...props?.context, ...context }}
                    fixed={fixed}
                    columns={columns}
                    hiddenColumns={hiddenColumns as string[]}
                />
            </Card>
        </>
    );
};
