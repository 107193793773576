import { Beteiligter } from '~/gql/ucpw/graphql';
import { join } from '~/utils';

export const dateIsValid = (dateStr: string) => {
    if (dateStr && dateStr.match(/^\d{4}-\d{2}-\d{2}$/) === null) {
        return false;
    }

    const date = new Date(dateStr);
    const timestamp = date?.getTime();
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
    }

    return date.toISOString().startsWith(dateStr);
};

export function localeDateString(
    date: string,
    options?: { hour?: 'numeric' | '2-digit'; minute?: 'numeric' | '2-digit' }
) {
    return date
        ? new Date(date).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              ...options,
          })
        : null;
}

export const projectParticipant = (
    typName: string,
    participants: Beteiligter[] = [],
    returnParticipant?: boolean
) => {
    const participant = participants.find((p) => p.typSnippet?.name === typName);

    if (returnParticipant && participant) {
        return participant;
    }

    return participant
        ? join([
              participant?.kontakt?.firma1,
              participant?.kontakt?.firma2,
              participant?.kontakt?.name,
              participant?.kontakt?.vorname,
          ])
        : null;
};
