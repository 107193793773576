import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, SimpleGrid } from '@chakra-ui/react';

// @ts-ignore
export const Dependants = ({ control, options = [], hasDisabled = false, ...props }) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });

    // @ts-ignore
    const parentName = options.find((option) => option.parent)?.name;
    const parent = value?.[parentName];
    const values = options.reduce(
        (acc, { name, parent: isParent }) => ({
            ...acc,
            [name]: {
                isChecked: acc?.[name]?.isChecked || false,
                isDisabled: !parent?.isChecked && isParent !== true && hasDisabled,
            },
        }),
        value
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        console.log('update', { ...values, [name]: { isChecked: checked } });
        inputProps.onChange({ ...values, [name]: { isChecked: checked } });
    };

    return (
        <SimpleGrid spacing={4} columns={2}>
            {options.map(({ name, label }) => (
                <Checkbox
                    key={name}
                    name={name}
                    onChange={onChange}
                    data-test-id={name}
                    {...(values[name] || {})}
                >
                    {label}
                </Checkbox>
            ))}
        </SimpleGrid>
    );
};
