import { Badge, BadgeProps } from "@chakra-ui/react";
import logo from '~/assets/wattro.svg'


type WattroPros = {
    hasLogo?: boolean;
    shorten?: boolean;
} & BadgeProps

export const Wattro = ({ hasLogo = false, shorten = false, ...props }: WattroPros) => (
    <Badge
        ml="1"
        rounded="md"
        bg="#FCED51"
        fontWeight="extrabold"
        size="sm"
        minH={22}
        display="flex"
        maxW={55}
        userSelect="none"
        {...props}
    >
        {hasLogo ?
            <img src={logo} width={50} height="auto" alt="wattro logo" /> :
            shorten ?
                'w' :
                'wattro'
        }
    </Badge>
)
