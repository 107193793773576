import { client } from '~/apollo';
import { FetchPolicy, useMutation } from '@apollo/client';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import {
    ListCalculationPositionsDocument,
    ListOffersPositionsDocument,
    ListInvoicePositionsDocument,
    ListInvoicesDocument,
    ListOffersDocument,
    CreateCalculationPositionDocument,
    UpdateCalculationPositionDocument,
    DeleteCalculationPositionDocument,
    CreateOfferPositionDocument,
    UpdateOfferPositionDocument,
    DeleteOfferPositionDocument,
    CreateInvoicePositionDocument,
    UpdateInvoicePositionDocument,
    DeleteInvoicePositionDocument,
    CreateInvoiceDocument,
    UpdateInvoiceDocument,
    DeleteInvoiceDocument,
    CreateOfferDocument,
    UpdateOfferDocument,
    DeleteOfferDocument,
    GetSubprojectDocument,
    SortOrder,
    Kalkulationsposition,
    UpdateSalesForecastDocument,
    GetSnippetDocument,
    Snippet,
} from '~/gql/ucpw/graphql';
import { fields, forms } from '~/pages/projects/meta/data/finance.schema';
import { omit, sortBy } from 'lodash';
import { resolveFormFields } from '~/utils';
import { FinancePosition } from '../financeMachine';
import log from '~/log';

export const LIMIT = 250;

export const useQueries = () => {
    const context = { clientName: 'ucpw' };

    const listQuery = async ({
        query,
        variables = {},
        fetchPolicy = 'network-only' as FetchPolicy,
    }: any) => {
        const response = await client.query({
            query,
            variables,
            fetchPolicy,
            context,
        });
        const data = response.data.items.items || []; // items
        return data;
    };

    const getQuery = async ({
        query,
        variables = {},
        fetchPolicy = 'network-only' as FetchPolicy,
    }: any) => {
        const response = await client.query({
            query,
            variables,
            fetchPolicy,
            context,
        });
        const data = response.data.item.item || {}; // item
        return data;
    };

    return { listQuery, getQuery };
};

export const useMutations = ({ refetchQueries = [] }: any = {}) => {
    const mutate = async ({
        mutation,
        variables = {},
        fetchPolicy = 'network-only' as MutationFetchPolicy,
        refetchQueries: controlledRefetchQueries = [],
        enableRefetchQueries = true,
    }: any) => {
        const response = await client.mutate({
            mutation,
            variables,
            fetchPolicy,
            ...(enableRefetchQueries && {
                refetchQueries: [...refetchQueries, ...controlledRefetchQueries],
            }),
            context: { clientName: 'ucpw' },
        });
        const data = response.data.item.item || {}; // item
        return data;
    };

    return { mutate };
};

export function useFinanceServices(props: any = {}) {
    const context = { clientName: 'ucpw' };
    const { listQuery, getQuery } = useQueries();
    const { mutate } = useMutations(props);

    const orderBy = [{ datum: SortOrder.Asc }, { nummer: SortOrder.Desc }];

    const formFields = resolveFormFields(
        forms.generalAggrementPosition,
        fields.generalAgreementPosition
    );

    const checkStatus = async (id: number): Promise<Snippet> => {
        const response = await client.query({
            query: GetSnippetDocument,
            variables: { id },
            context: { clientName: 'ucpw' },
        });
        const status: Snippet | any = response?.data?.item?.item || {};
        return status;
    };

    const [updateSalesForecastMutation] = useMutation(UpdateSalesForecastDocument, {
        variables: { subprojektId: props?.subprojectId as number },
        context,
    });

    const calculateTotalPriceNet = (positions = []) => {
        const totalPriceNet = positions.reduce(
            (acc: number, pos: FinancePosition) => acc + pos?.menge * pos?.einzelpreis,
            0
        );
        return totalPriceNet;
    };

    const cleanPosition = (position: FinancePosition) => {
        const { id, __typename, subprojektId, einheit, einzelpreis, ...rest } =
            position as Kalkulationsposition;
        const { service, ...fields }: any = formFields.toForm(rest);
        const fixedValues = { unitPrice: einzelpreis, unit: einheit };
        const data: any = formFields.toGql(fields, {}, fixedValues);
        log.debug('financeMachine.cleanPosition', { __typename, fields, fixedValues, data });
        return { ...data, einheit: data.einheit || '' };
    };

    const createMutation =
        (mutation: any) =>
        async (ctx: any, { variables = {} }: any) =>
            await mutate({
                mutation,
                variables,
            });

    const createUpdateDelete = async (
        variant: 'calculation' | 'offer' | 'invoice',
        data: any = {}
    ) => {
        const { created = [], updated = [], deleted = [] } = data;

        const variants = {
            calculation: {
                create: CreateCalculationPositionDocument,
                update: UpdateCalculationPositionDocument,
                delete: DeleteCalculationPositionDocument,
                omit: ['angebotId', 'rechnungId'],
            },
            offer: {
                create: CreateOfferPositionDocument,
                update: UpdateOfferPositionDocument,
                delete: DeleteOfferPositionDocument,
                omit: ['subprojektId'],
            },
            invoice: {
                create: CreateInvoicePositionDocument,
                update: UpdateInvoicePositionDocument,
                delete: DeleteInvoicePositionDocument,
                omit: ['subprojektId'],
            },
        };

        return await Promise.all([
            ...created.map(
                async ({ data }: any) =>
                    await mutate({
                        mutation: variants[variant].create,
                        variables: { data: omit(data, ...(variants[variant]?.omit || [])) },
                    })
            ),
            ...updated.map(
                async ({ id, data }: any) =>
                    await mutate({
                        mutation: variants[variant].update,
                        variables: { id, data: omit(data, ...(variants[variant]?.omit || [])) },
                    })
            ),
            ...deleted.map(
                async ({ id }: any) =>
                    await mutate({
                        mutation: variants[variant].delete,
                        variables: { id },
                    })
            ),
        ]);
    };

    // ===============================================
    // Invoices
    // ===============================================

    const getInvoices = async ({ subprojectId }: any) =>
        await listQuery({
            query: ListInvoicesDocument,
            variables: {
                filter: { subprojektId: subprojectId },
                orderBy,
            },
        });

    const createInvoice = async ({ cache = {}, ...ctx }: any, { variables = {} }: any) => {
        const {
            data: positions = [],
            created = [],
            updated = [],
            deleted = [],
            fromOfferId,
        } = cache;
        const totalPriceNet = calculateTotalPriceNet(positions);
        const data = { ...variables.data, gesamtpreisNetto: totalPriceNet };

        const response = await mutate({
            mutation: CreateInvoiceDocument,
            variables: { data },
        });

        const invoiceId: number = response?.id;
        const status = await checkStatus(variables?.data?.statusSnippetId);
        const statusIsOpen = status?.name === 'Offen';

        log.debug('financeMachine.createInvoice', {
            invoiceId,
            fromOfferId,
            positions,
            response,
            totalPriceNet,
            variables,
            status,
            statusIsOpen,
            created,
            updated,
            deleted,
        });

        if (invoiceId) {
            if (!statusIsOpen || (statusIsOpen && fromOfferId)) {
                for (const position of positions) {
                    const einzelpreis = position?.einzelpreis || 0;
                    const data = { ...cleanPosition(position), rechnungId: invoiceId, einzelpreis };
                    try {
                        log.debug('financeMachine.createInvoicePosition', { data });
                        await mutate({
                            mutation: CreateInvoicePositionDocument,
                            variables: { data },
                        });
                    } catch (error) {
                        log.error(error, data);
                    }
                }
            }

            if (statusIsOpen) {
                try {
                    await createUpdateDelete('calculation', cache);
                } catch (error) {
                    log.error(error);
                }
            }

            return await mutate({
                mutation: UpdateInvoiceDocument,
                variables: {
                    id: invoiceId,
                    data: {
                        ...variables?.data,
                        nummer: invoiceId?.toString(),
                        gesamtpreisNetto: totalPriceNet,
                    },
                },
            });
        }

        await updateSalesForecastMutation();

        return response;
    };

    const updateInvoice = async ({ cache = {} }: any, { variables = {} }: any) => {
        const {
            data: positions = [],
            created = [],
            updated = [],
            deleted = [],
            hasRechnungspositionenAndIsOpen,
        } = cache;
        const hasRechnungspositionen = positions?.find(
            (pos: any) => pos?.__typename === 'Rechnungsposition'
        );
        const totalPriceNet = calculateTotalPriceNet(positions);
        const data = {
            ...variables.data,
            gesamtpreisNetto: totalPriceNet,
            ...(!variables?.data?.nummer && { nummer: variables?.id }),
        };
        const response = await mutate({
            mutation: UpdateInvoiceDocument,
            variables: { ...variables, data },
        });

        const invoiceId: number = response?.id;
        const status = await checkStatus(variables?.data?.statusSnippetId);
        const statusIsOpen = status?.name === 'Offen';

        console.log('financeMachine.updateInvoice', {
            invoiceId,
            created,
            updated,
            deleted,
            totalPriceNet,
        });

        if (invoiceId) {
            if (statusIsOpen || hasRechnungspositionenAndIsOpen) {
                try {
                    await createUpdateDelete(
                        hasRechnungspositionenAndIsOpen ? 'invoice' : 'calculation',
                        cache
                    );
                } catch (error) {
                    log.error(error);
                }
            }

            if (!statusIsOpen && !hasRechnungspositionen) {
                for (const position of positions) {
                    const einzelpreis = position?.einzelpreis || 0;
                    const data = { ...cleanPosition(position), rechnungId: invoiceId, einzelpreis };
                    try {
                        log.debug('financeMachine.createInvoicePosition', { data });
                        await mutate({
                            mutation: CreateInvoicePositionDocument,
                            variables: { data },
                        });
                    } catch (error) {
                        log.error(error, data);
                    }
                }
            }
        }

        return response;
    };
    const deleteInvoice = createMutation(DeleteInvoiceDocument);

    // ===============================================
    // Offers
    // ===============================================

    const getOffers = async ({ subprojectId }: any) =>
        await listQuery({
            query: ListOffersDocument,
            variables: {
                filter: { subprojektId: subprojectId },
                orderBy,
            },
        });

    const createOffer = async ({ cache = {} }: any, { variables = {} }: any) => {
        const { data: positions = [], created = [], updated = [], deleted = [] } = cache;
        const totalPriceNet = calculateTotalPriceNet(positions);
        const data = { ...variables.data, gesamtpreisNetto: totalPriceNet };

        const response = await mutate({
            mutation: CreateOfferDocument,
            variables: { data },
        });

        const offerId: number = response?.id;
        const status = await checkStatus(variables?.data?.statusSnippetId);
        const statusIsValid = status?.name === 'gültig';

        log.debug('financeMachine.createOffer', {
            offerId,
            positions,
            response,
            totalPriceNet,
            variables,
            status,
            statusIsValid,
            created,
            updated,
            deleted,
        });

        if (offerId) {
            if (!statusIsValid) {
                try {
                    await createUpdateDelete('calculation', cache);
                } catch (error) {
                    log.error(error);
                }
            }

            if (statusIsValid) {
                for (const position of positions) {
                    const einzelpreis = position?.einzelpreis || 0;
                    const data = { ...cleanPosition(position), angebotId: offerId, einzelpreis };
                    try {
                        log.debug('financeMachine.createOfferPosition', { data });
                        await mutate({
                            mutation: CreateOfferPositionDocument,
                            variables: { data },
                            enableRefetchQueries: false,
                        });
                    } catch (error) {
                        log.error(error);
                    }
                }
            }

            return await mutate({
                mutation: UpdateOfferDocument,
                variables: {
                    id: offerId,
                    data: {
                        ...variables?.data,
                        nummer: offerId?.toString(),
                        gesamtpreisNetto: totalPriceNet,
                    },
                },
            });
        }

        await updateSalesForecastMutation();

        return response;
    };

    const updateOffer = async ({ cache = {} }: any, { variables = {} }: any) => {
        const { data: positions = [], hasOfferPositions = false } = cache;
        // const hasAngebotspositionen = positions?.find(
        //     (pos: any) => pos?.__typename === 'Angebotsposition'
        // );
        const totalPriceNet = calculateTotalPriceNet(positions);
        const data = {
            ...variables.data,
            gesamtpreisNetto: totalPriceNet,
            ...(!variables?.data?.nummer && { nummer: variables?.id }),
        };
        const response = await mutate({
            mutation: UpdateOfferDocument,
            variables: { ...variables, data },
        });

        const offerId: number = response?.id;
        const status = await checkStatus(variables?.data?.statusSnippetId);
        const statusIsValid = status?.name === 'gültig';

        console.log('financeMachine.updateOffer', {
            offerId,
            totalPriceNet,
        });

        if (offerId) {
            if (!statusIsValid || hasOfferPositions) {
                try {
                    await createUpdateDelete(hasOfferPositions ? 'offer' : 'calculation', cache);
                } catch (error) {
                    log.error(error);
                }
            }

            if (statusIsValid && !hasOfferPositions) {
                for (const position of positions) {
                    const einzelpreis = position?.einzelpreis || 0;
                    const data = { ...cleanPosition(position), angebotId: offerId, einzelpreis };
                    try {
                        log.debug('financeMachine.createOfferPosition', { data });
                        await mutate({
                            mutation: CreateOfferPositionDocument,
                            variables: { data },
                            enableRefetchQueries: false,
                        });
                    } catch (error) {
                        log.error(error);
                    }
                }
            }
        }

        await updateSalesForecastMutation();

        return response;
    };

    const deleteOffer = createMutation(DeleteOfferDocument);

    // ===============================================
    // Subproject
    // ===============================================

    const getSubproject = async ({ subprojectId: id }: any) =>
        await getQuery({
            query: GetSubprojectDocument,
            variables: { id },
        });

    // ===============================================
    // Kalkulationspositionen
    // ===============================================

    const getLfdNr = (lfdNr: any) => (isNaN(lfdNr) ? lfdNr : parseFloat(lfdNr));

    const getCalculationpositions = async ({ subprojectId }: any) => {
        const positions = await listQuery({
            query: ListCalculationPositionsDocument,
            variables: {
                filter: { subprojektId: subprojectId },
                limit: LIMIT,
                orderBy: [{ lfdNr: SortOrder.Asc }],
            },
        });
        return sortBy(
            positions?.map((item: any) => ({
                ...item,
                lfdNr: getLfdNr(item?.lfdNr),
                einzelpreis: item?.einzelpreis || 0,
            })),
            'lfdNr'
        );
    };

    const getOfferPositions = async ({ scopes, scopeId }: any) => {
        const scope = scopes?.[scopeId];
        const positions = await listQuery({
            query: ListOffersPositionsDocument,
            variables: {
                filter: { angebotId: scopeId },
                limit: LIMIT,
                orderBy: [{ lfdNr: SortOrder.Asc }],
            },
        });

        return {
            [scopeId]: {
                ...scope,
                angebotspositionen: sortBy(
                    positions?.map((item: any) => ({
                        ...item,
                        lfdNr: getLfdNr(item?.lfdNr),
                        einzelpreis: item?.einzelpreis || 0,
                    })),
                    'lfdNr'
                ),
            },
        };
    };

    const getInvoicePositions = async ({ scopes, scopeId }: any) => {
        const scope = scopes?.[scopeId];
        const positions = await listQuery({
            query: ListInvoicePositionsDocument,
            variables: {
                filter: { rechnungId: scopeId },
                limit: LIMIT,
                orderBy: [{ lfdNr: SortOrder.Asc }],
            },
        });
        return {
            [scopeId]: {
                ...scope,
                rechnungspositionen: sortBy(
                    positions?.map((item: any) => ({
                        ...item,
                        lfdNr: getLfdNr(item?.lfdNr),
                        einzelpreis: item?.einzelpreis || 0,
                    })),
                    'lfdNr'
                ),
            },
        };
    };

    const createCalculationPosition = createMutation(CreateCalculationPositionDocument);
    const updateCalculationPosition = createMutation(UpdateCalculationPositionDocument);
    const deleteCalculationPosition = createMutation(DeleteCalculationPositionDocument);

    // ===============================================
    // Angebotspositionen
    // ===============================================

    const createOfferPosition = createMutation(CreateOfferPositionDocument);
    const updateOfferPosition = createMutation(UpdateOfferPositionDocument);
    const deleteOfferPosition = createMutation(DeleteOfferPositionDocument);

    // ===============================================
    // Rechnungspositionen
    // ===============================================

    const createInvoicePosition = createMutation(CreateInvoicePositionDocument);
    const updateInvoicePosition = createMutation(UpdateInvoicePositionDocument);
    const deleteInvoicePosition = createMutation(DeleteInvoicePositionDocument);

    return {
        // calculation
        getCalculationpositions,
        createCalculationPosition,
        updateCalculationPosition,
        deleteCalculationPosition,
        // invoices
        getInvoices,
        createInvoice,
        updateInvoice,
        deleteInvoice,
        getInvoicePositions,
        createInvoicePosition,
        updateInvoicePosition,
        deleteInvoicePosition,
        // offers
        getOffers,
        createOffer,
        updateOffer,
        deleteOffer,
        getOfferPositions,
        createOfferPosition,
        updateOfferPosition,
        deleteOfferPosition,
        // subproject
        getSubproject,
    };
}
