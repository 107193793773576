import { format, getDaysInMonth, eachDayOfInterval, getDay } from 'date-fns';
import { weekdays } from './data/absences.schema';

export function generateDays(date: Date) {
    const month = eachDayOfInterval({
        start: new Date(date.getFullYear(), date?.getMonth(), 1),
        end: new Date(date.getFullYear(), date?.getMonth(), getDaysInMonth(date)),
    });

    return month?.map((date) => ({
        date: format(date, 'yyyy-MM-dd'),
        weekday: weekdays?.[getDay(date)],
        day: format(date, 'dd'),
    }));
}
