import React from 'react';
import {
    Box,
    CloseButton,
    Container,
    Icon,
    Square,
    Stack,
    Collapse,
    Text,
    useDisclosure
} from '@chakra-ui/react'
import { BiWifiOff } from 'react-icons/bi'



export const Connectivity = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    React.useEffect(() => {
        const setOffline = () => onOpen()
        const setOnline = () => onClose()
        window.addEventListener("offline", setOffline);
        window.addEventListener("online", setOnline);
        console.log('navigator', navigator)
        return () => {
            window.removeEventListener("offline", setOffline);
            window.removeEventListener("online", setOnline);
        }
    }, []);

    return (
        <Collapse in={isOpen} animateOpacity>
            <Box bg="red.500" color="white">
                <Container py={{ base: '4', md: '2.5' }} position="relative">
                    <CloseButton display={{ md: 'none' }} position="absolute" right="2" top="2" />
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        justify="space-between"
                        spacing={{ base: '3', md: '2' }}
                    >
                        <Stack
                            spacing="4"
                            direction={{ base: 'column', md: 'row' }}
                            align={{ base: 'start', md: 'center' }}
                        >
                            <Square size="12" bg="white" color="red.500" borderRadius="md">
                                <Icon as={BiWifiOff} boxSize="6" />
                            </Square>
                            <Stack spacing="0.5" pe={{ base: '4', md: '0' }}>
                                <Text fontWeight="medium">

                                </Text>
                                <Text as="b">
                                    Es besteht derzeit keine Internetverbindung!
                                </Text>
                            </Stack>
                        </Stack>
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: '3', md: '2' }}
                            align={{ base: 'stretch', md: 'center' }}>
                            <CloseButton display={{ base: 'none', md: 'inline-flex' }} onClick={onClose} />
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Collapse>
    )
}

export const useOnlineStatus = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const checkOnlineStatus = async () => {
        try {
            const online = await fetch("/1x1.png", { cache: "no-cache" });
            return online.status >= 200 && online.status < 300;
        } catch (err) {
            return false;
        }
    };


    React.useEffect(() => {
        const interval = setInterval(async () => (await checkOnlineStatus()) ? onClose() : onOpen(), 5000);
        return () => clearInterval(interval);
    }, []);

    return { isOpen, onClose, onOpen }
}