import React from 'react';
import { useController } from 'react-hook-form';
import { SimpleGrid, Checkbox } from '@chakra-ui/react';

export const PressureTest = ({ control, ...props }: { control: any; name: string }) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });

    const isChecked = (name: string) => value?.[name]?.jaNein === true;
    const isPressureTest = isChecked('druckpruefung_massnahme');

    const togglePressureTest = () => {
        inputProps.onChange({
            ...(value || {}),
            druckpruefung_massnahme: { jaNein: !isPressureTest },
        });
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        inputProps.onChange({ ...(value || {}), [name]: { jaNein: checked } });
    };

    const innerGrids = { sm: 2, md: 2, lg: 2, xl: 1 };

    return (
        <SimpleGrid columns={{ sm: 1, md: 1, lg: 1, xl: 4 }} spacingX={6} mb={4}>
            <Checkbox
                alignSelf="start"
                data-test-id="druckpruefung_massnahme"
                name="druckpruefung_massnahme"
                isChecked={isChecked('druckpruefung_massnahme')}
                onChange={togglePressureTest}
            >
                Druckprüfung
            </Checkbox>
            <SimpleGrid columns={innerGrids}>
                <Checkbox
                    data-test-id="kaltwasser_druckpruefung_massnahme"
                    name="kaltwasser_druckpruefung_massnahme"
                    isChecked={isChecked('kaltwasser_druckpruefung_massnahme')}
                    isDisabled={!isPressureTest}
                    onChange={onChange}
                >
                    Kaltwasser
                </Checkbox>
                <Checkbox
                    data-test-id="druckverlust_kaltwasser_druckpruefung"
                    name="druckverlust_kaltwasser_druckpruefung"
                    isChecked={isChecked('druckverlust_kaltwasser_druckpruefung')}
                    isDisabled={!isPressureTest || !isChecked('kaltwasser_druckpruefung_massnahme')}
                    onChange={onChange}
                >
                    Druckverlust
                </Checkbox>
            </SimpleGrid>
            <SimpleGrid columns={innerGrids}>
                <Checkbox
                    data-test-id="warmwasser_druckpruefung_massnahme"
                    name="warmwasser_druckpruefung_massnahme"
                    isChecked={isChecked('warmwasser_druckpruefung_massnahme')}
                    isDisabled={!isPressureTest}
                    onChange={onChange}
                >
                    Warmwasser
                </Checkbox>
                <Checkbox
                    data-test-id="druckverlust_warmwasser_druckpruefung"
                    name="druckverlust_warmwasser_druckpruefung"
                    isChecked={isChecked('druckverlust_warmwasser_druckpruefung')}
                    isDisabled={!isPressureTest || !isChecked('warmwasser_druckpruefung_massnahme')}
                    onChange={onChange}
                >
                    Druckverlust
                </Checkbox>
            </SimpleGrid>
            <SimpleGrid columns={innerGrids}>
                <Checkbox
                    data-test-id="heizung_druckpruefung_massnahme"
                    name="heizung_druckpruefung_massnahme"
                    isChecked={isChecked('heizung_druckpruefung_massnahme')}
                    isDisabled={!isPressureTest}
                    onChange={onChange}
                >
                    Heizung
                </Checkbox>
                <Checkbox
                    data-test-id="druckverlust_heizung_druckpruefung"
                    name="druckverlust_heizung_druckpruefung"
                    isChecked={isChecked('druckverlust_heizung_druckpruefung')}
                    isDisabled={!isPressureTest || !isChecked('heizung_druckpruefung_massnahme')}
                    onChange={onChange}
                >
                    Druckverlust
                </Checkbox>
            </SimpleGrid>
        </SimpleGrid>
    );
};
