import { useQuery } from '@apollo/client';
import { ListGeneralAgreementsDocument } from '~/gql/ucpw/graphql';

export const useIsStandardGeneralAgreementId = () => {
    const { data } = useQuery(ListGeneralAgreementsDocument, {
        context: { clientName: 'ucpw' },
        variables: {
            filter: {
                isStandard: true,
            },
        },
    });
    const [agreement]: any = data?.items?.items || [];

    return agreement?.id || 3716;
};
