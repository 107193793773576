import { FiDownload } from 'react-icons/fi';
import { AiOutlinePlus } from 'react-icons/ai';
import { Text, IconButton, chakra, Menu, MenuButton, MenuList, MenuItem, Stack } from '@chakra-ui/react';

export const Download = ({
    title,
    onClick,
    isLoading = false,
    variant = 'download',
    menuItems = [],
    isDisabled = false,
    ...props
}: {
    title?: string;
    onClick?: (args?: any) => void;
    isLoading?: boolean;
    variant?: 'download' | 'create',
    menuItems?: any[]
    isDisabled?: boolean;
    props?: Record<string, any>;
}) => {
    const hasHandler = Boolean(onClick);


    const variants = {
        download: { 'aria-label': 'download-icon-button', icon: <FiDownload /> },
        create: { 'aria-label': 'create-icon-button', icon: <AiOutlinePlus /> }
    }

    const buttonProps = {
        isLoading,
        ...(hasHandler && { colorScheme: 'blue' }),
        isDisabled: isDisabled || !hasHandler,
        ...variants?.[variant]
    }

    return (
        <Stack
            flexDir={{
                md: 'column', lg: 'column', xl: 'row', '2xl': 'row'
            }}
            alignItems="center"
            justify="space-between"
            borderBottomWidth={1}
            borderColor="gray.200"
            _last={{ borderBottomColor: 'transparent' }}
            px={6}
            py={6}
            {...props}
        >
            <Text fontSize={16} fontWeight={600}>
                {title}
            </Text>
            {!Boolean(menuItems?.length) ? (
                <chakra.a onClick={onClick} aria-label="download">
                    <IconButton {...buttonProps} />
                </chakra.a>) :
                (<Menu>
                    <MenuButton as={IconButton} {...buttonProps} />
                    <MenuList >
                        {menuItems.map((item, idx) => <MenuItem key={[item?.id, item.label, idx].filter(Boolean).join('-')} onClick={item.onClick}>{item.label}</MenuItem>)}
                    </MenuList>
                </Menu>
                )
            }
        </Stack>
    );
};
