import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import {
    Button,
    HStack,
    ModalBody,
    ModalContent,
    ModalHeader,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { DataTable, Card, CardContent, CardHeader } from '@ucc/react/ui';
import { Form, renderField } from '~/components/Form/Form';
import { useModal, usePermission, useYupValidationResolver } from '~/hooks';
import { useElectricityMeter } from '~/pages/projects/hooks/useElectricityMeter';
import { fields, forms } from '~/pages/projects/meta/data/electricity-meter.schema';
import { resolveFormFields } from '~/utils';
import { ProjectIds } from '~/pages/projects/types';
import { Stromzaehler } from '~/gql/ucpw/graphql';
import { HasPermission } from '~/layout/HasPermission';
import { useTimeRange } from './useTimeRange';
import { WattroButton } from '~/components/WattroButton';
import log from '~/log';


type Props = ProjectIds;


export const ElectricityMeter = ({ projectId, subprojectId, residentialUnit, ...props }: Props & { residentialUnit: any, dataTestId?: string | number }) => {
    const electricalMeter: any = useElectricityMeter({
        projectId,
        subprojectId,
        residentialUnitId: residentialUnit?.id,
        residentialUnit,
    });

    const multiTimeRanges = useTimeRange(electricalMeter.data);
    const isConsumption = Boolean(multiTimeRanges?.length);
    const totalConsumption = electricalMeter.data?.reduce((acc: any, meter: any) => {
        const { beginnWert: startingValue, endeWert: endingValue } = meter;
        return acc + (endingValue - startingValue);
    }, 0);

    return (
        <Card boxShadow="none">
            <CardHeader justify="space-between" alignItems="flex-end">
                <VStack align="flex-start">
                    <HStack>
                        <Text as="h5" fontSize={18} fontWeight={700} lineHeight={7}>
                            {residentialUnit?.isOverallObject
                                ? `Stromzähler Ort`
                                : `Stromzähler Wohneinheit`}
                        </Text>
                        <WattroButton
                            wattroProjectExists={residentialUnit?.wattroProjectExists}
                            subprojectId={subprojectId}
                            subprojektResidentialUnitId={residentialUnit.id}
                            humanid={residentialUnit?.humanid}
                        />
                    </HStack>
                    <Text>
                        {residentialUnit?.isOverallObject
                            ? residentialUnit?.title
                            : `Wohneinheit ${residentialUnit?.title}`}
                    </Text>
                </VStack>
                {isConsumption && (
                    <VStack align="flex-end">
                        <Text as="h5" fontSize={18} fontWeight={700} lineHeight={7}>
                            Verbrauch Gesamt:{' '}
                            {new Intl.NumberFormat('de-DE').format(totalConsumption)}
                        </Text>
                        {multiTimeRanges?.map((item: any) => (
                            <Text key={item}>{item}</Text>
                        ))}
                    </VStack>
                )}
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={electricalMeter.columns}
                    hiddenColumns={electricalMeter.hiddenColumns}
                    data={electricalMeter.data}
                    pageSize={electricalMeter.pageSize}
                    loading={electricalMeter.loading}
                    {...props}
                />
            </CardContent>
        </Card>
    );
};


export const ElectricityMeterModal = (props?: { id: string }) => {
    const { onClose, dispatch, state } = useModal();
    const { row: electricityMeter = {}, rowId: electricityMeterId } = state.rows || {};
    const { createMutation, updateMutation, residentialUnit } = state?.modals?.props || {}
    const residentialUnitId =
        (electricityMeter as Stromzaehler)?.subprojektWohneinheitId || residentialUnit?.id;
    const formFields = resolveFormFields(forms.electricityMeter, fields.electricityMeter);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(electricityMeter),
        residentialUnitId: residentialUnit?.title,
    };

    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    /** Permit */
    const { canCreate, canEdit, canView } = usePermission('project.deviceUsage');
    const readOnly = electricityMeterId && canView && !(canCreate || canEdit);
    const modalTitle = readOnly ? 'Stromzähler ansehen' : electricityMeterId ? 'Stromzähler bearbeiten' : 'Neuer Stromzähler';
    const permssionType = electricityMeterId ? 'update' : 'create';
    const cursor = readOnly ? 'not-allowed' : 'auto';
    const pointerEvents = readOnly ? 'none' : 'auto';

    const onSubmit: SubmitHandler<any> = async (values) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const fixedValues = { residentialUnitId };
        log.debug('onSubmit.fixedValues', JSON.stringify(fixedValues, null, 2));
        const data: any = formFields.toGql(values, {}, fixedValues);
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));
        const response = electricityMeterId
            ? await updateMutation({ variables: { id: electricityMeterId, data } })
            : await createMutation({ variables: { data } });

        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <HasPermission resource='project.deviceUsage' permission={permssionType}>
                        <Button
                            data-test-id="button-save"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmitWithOnClose)}
                        >
                            Speichern
                        </Button>
                    </HasPermission>
                </HStack>
            </ModalHeader>
            <ModalBody cursor={cursor}>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4} pointerEvents={pointerEvents}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({ field, control, register, errors })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};
