import { Amplify } from 'aws-amplify';

const env = import.meta.env;

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsconfig = {
    Auth: {
        identityPoolId: env.VITE_IDENTITY_POOL_ID,
        region: env.VITE_REGION,
        userPoolId: env.VITE_USER_POOL_ID,
        userPoolWebClientId: env.VITE_USER_POOL_CLIENT_ID,
        mandatorySignIn: true,
        oauth: {
            domain: env.VITE_SSO_DOMAIN,
            redirectSignIn: env.VITE_SSO_REDIRECT_SIGN_IN,
            redirectSignOut: env.VITE_SSO_REDIRECT_SIGN_OUT,
            responseType: 'code',
        },
    },
};

// console.log(awsconfig);

Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = 'DEBUG';
