import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { pick } from 'lodash';
import {
    Box,
    Popover,
    PopoverTrigger,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverCloseButton,
    PopoverFooter,
    PopoverBody,
    Text,
    Link,
    Tooltip,
} from '@chakra-ui/react';
import { isJsonString, join } from '~/utils';
import { renderElement } from './Renderers';

type ShowContactProps = {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    label: string;
    contact: any;
    selectedEntry: string;
    hasCopied: boolean;
    isHovered: string;
    setValue: (value: string) => void;
    setIsHovered: (value: string) => void;
}

export const ShowContactPopover = ({ isOpen, onOpen, onClose, label, contact, selectedEntry, hasCopied, isHovered, setValue, setIsHovered }: ShowContactProps) => {
    const freitext = contact?.freitext?.raw && isJsonString(contact?.freitext?.raw) && JSON.parse(contact?.freitext?.raw)
    const freitextIsEmpty = freitext?.length === 1 && freitext?.[0]?.type === 'paragraph' && freitext?.[0]?.children?.[0]?.text === ''
    const noData = Object.values(
        pick(
            contact,
            'plz',
            'ort',
            'strasse',
            'fax',
            'email',
            'telefon',
            'telefonDirekt',
            'telefonMobil',
            'telefonPrivat'
        ) || {}
    )?.map((item: any) => (typeof item === 'string' && item === ' ' ? '' : item)).filter(Boolean)?.length;

    return (
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <PopoverTrigger>
                <Box
                    as="button"
                    minH="24px"
                    height="auto"
                    lineHeight="1.2"
                    borderRadius="2px"
                    fontSize={14}
                    fontWeight={600}
                    _hover={{ color: 'blue.500' }}
                    textAlign="start"
                >
                    {label}
                </Box>
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader display="flex" flexDirection="column" fontWeight="bold">
                        {contact?.firma1 && contact?.firma1 !== ' ' || contact?.firma2 && contact?.firma2 !== ' '
                            ? label.split(',').map((child: any) => <Text key={child}>{child}</Text>)
                            : label}
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                        {!noData ? (
                            <Text>Keine Daten</Text>
                        ) : (
                            <>
                                <Text>{contact.strasse}</Text>
                                <Text>{join([contact?.plz, contact?.ort], ' ')}</Text>
                                {Object.entries(contact).map(([property, value]) => {
                                    if (value && value !== ' ') {
                                        const entryMap = {
                                            fax: 'Fax: ',
                                            email: 'Email: ',
                                            telefon: 'Tel.: ',
                                            telefonDirekt: 'Tel.(direkt): ',
                                            telefonMobil: 'Tel.(mobil): ',
                                            telefonPrivat: 'Tel.(privat): ',
                                        };
                                        const entry = entryMap?.[property as keyof typeof entryMap];

                                        return entry ? (
                                            <Text key={property}>
                                                {entry}
                                                <Tooltip
                                                    label={hasCopied ? 'kopiert' : 'kopieren'}
                                                    isOpen={!hasCopied ? isHovered === value && value !== selectedEntry : value === selectedEntry}
                                                    placement="top"
                                                    hasArrow
                                                >
                                                    <Link
                                                        {...(selectedEntry === value
                                                            ? { color: 'blue.500' }
                                                            : { color: 'revert' })}
                                                        onClick={() => setValue(value as string)}
                                                        _hover={{ textDecoration: 'none', color: 'blue.500' }}
                                                        onMouseEnter={() => setIsHovered(value as string)}
                                                        onMouseLeave={() => setIsHovered('')}
                                                    >
                                                        <>
                                                            {value}
                                                        </>
                                                    </Link>
                                                </Tooltip>
                                            </Text>
                                        ) : null;
                                    }

                                    return null;
                                })}
                            </>
                        )}
                    </PopoverBody>
                    {freitext && !freitextIsEmpty && (
                        <PopoverFooter>
                            {freitext.map((node: any) => (
                                <Fragment key={`contact-popover-footer-${uuidv4()}`}>
                                    {renderElement(node)}
                                </Fragment>
                            ))}
                        </PopoverFooter>
                    )}
                </PopoverContent>
            </Portal>
        </Popover>
    );
}