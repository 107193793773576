import React from 'react';
import * as graphql from '@ucc/graphql/documents/docs';
import { DocumentUpload } from '@ucc/graphql/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { withRenderers } from '~/utils/withRenderers';
import { tables } from '../meta/data/open-documents.schema';
import { useModal } from '~/hooks/useModal';
import { useMutation, useQuery } from '@apollo/client';
import { OpenDocumentsModal } from '../ui/OpenDocumentsModal';
import { usePermission } from '~/hooks';
import log from '~/log';

type Props = {
    selection?: [any[], (args?: any) => void];
    refetchQueries?: [];
};

export const useOpenDocuments = ({ selection }: Props = {}) => {
    const { canEdit, canDelete } = usePermission('project.documents');
    const navigate = useNavigate();
    const {
        data: uploadsData,
        loading: listLoading,
        subscribeToMore,
    } = useQuery(graphql.listUploads);
    const uploads = uploadsData?.documents?.uploads || [];

    subscribeToMore({
        document: graphql.documentUploadsSubscription,
        variables: {},
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
                return prev;
            }

            const { documents: doc } = subscriptionData.data;
            console.log('subscription.doc', doc);

            const previous =
                prev.documents?.uploads?.filter((upload) => upload?.id !== doc?.id) || [];

            const result = {
                documents: {
                    uploads: [doc as DocumentUpload, ...previous],
                },
            };

            return result;
        },
    });

    // ===============================================
    // MUTATIONS
    // ===============================================

    const [deleteMutation, { loading: deleteLoading }] = useMutation(graphql.deleteUpload, {
        refetchQueries: [{ query: graphql.listUploads }],
    });

    const [moveMutation, { loading: moveLoading }] = useMutation(graphql.moveUploads, {
        refetchQueries: [{ query: graphql.listUploads }],
    });

    // ===============================================
    // MODAL
    // ===============================================

    const { dispatch, onOpen } = useModal(<OpenDocumentsModal />);

    const onMoveSelection = React.useCallback(() => {
        console.log('onMoveSelection', { selection });
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'OpenDocumentsModal',
                props: {
                    onSelectProject: async (project: any) => {
                        const { projectId, subprojectId } = project;
                        const { data } = await moveMutation({
                            variables: { ids: selection?.[0] || [], subprojectId },
                            refetchQueries: [{ query: graphql.listUploads }],
                        });
                        const success = data?.documents?.moveUploads.reduce(
                            (acc, success) => acc && success,
                            true
                        );

                        if (success) {
                            selection?.[1]?.([]);
                            const link = `/projekte/${projectId}/${subprojectId}/dokumente`;
                            log.debug('onMoveSelection.onSelectProject', { link, project, selection });
                            navigate(link);
                        }
                    },
                },
            },
        });
        onOpen?.();
    }, [selection, moveMutation]);

    const onMove = (row: any) => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'OpenDocumentsModal',
                props: {
                    onSelectProject: async (project: any) => {
                        const doc = row.original as DocumentUpload;
                        const { projectId, subprojectId } = project;
                        const { data } = await moveMutation({
                            variables: { ids: [doc.id!], subprojectId },
                            refetchQueries: [{ query: graphql.listUploads }],
                        });
                        const success = data?.documents?.moveUploads.reduce(
                            (acc, success) => acc && success,
                            true
                        );
                        if (success) {
                            const link = `/projekte/${projectId}/${subprojectId}/dokumente`;
                            log.debug('onMove.onSelectProject', { doc, link, project });
                            navigate(link);
                        }
                    },
                },
            },
        });
        onOpen?.();
    };

    // ===============================================
    // TABLE
    // ===============================================

    const controls = [
        {
            title: 'Verschieben',
            props: { onClick: (row: any) => onMove(row) },
            enabled: () => canEdit
        },
        {
            title: 'divider',
            enabled: () => canDelete && canEdit,
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: async (row: any) => {
                    await deleteMutation({
                        variables: { id: row.original?.id },
                    });
                },
            },
            enabled: () => canDelete,
        },
    ].filter(Boolean);

    const data = React.useMemo(
        () => uploads.map((upload: any) => ({ ...upload, selection })),
        [selection, uploads]
    );

    const columns = React.useMemo(() => withRenderers(tables.openDocuments.columns, controls), []);

    const loading = listLoading || deleteLoading || moveLoading;

    return {
        data,
        columns,
        loading,
        onMoveSelection,
    };
};
