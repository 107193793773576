export const formStateReducer = (state: any = {}, action: any = {}) => {
    const { type, data = {} } = action;
    switch (type) {
        case 'formState': {
            return { ...state, formState: data };
        }
        case 'keepFormState': {
            return {
                ...state,
                formState: { ...((state?.formState && state?.formState) || {}), ...data },
            };
        }
        case 'persistFormState': {
            return { ...state, persistFormState: data };
        }
        case 'resetState': {
            const resetState = { ...state, formState: {}, persistFormState: {} };
            console.log('formStateReducer.resetState', resetState);
            return resetState;
        }
    }
};
