import { useApolloClient, FetchPolicy } from '@apollo/client';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import {
    ListBranchesDocument,
    ListEmployeesDocument,
    ListEventsDocument,
    CreateEventDocument,
    UpdateEventDocument,
    DeleteEventDocument,
    GetSubprojectDocument,
    ListCorrespondencesAndEventsDocument,
    ListAbsencesDocument,
    ListRolesDocument,
    ListFunctionsDocument,
    SortOrder
} from '~/gql/ucpw/graphql';
import { format } from 'date-fns';
import { client } from '~/apollo';
import { addBreadcrumb, captureMessage } from '~/utils';
import { pick } from 'lodash';
import { useViewer } from '~/hooks';

export const getFormat = (date: Date) => format(date, 'yyyy-MM-dd');

export const useQueries = () => {

    const listQuery = async ({
        query,
        variables = {},
        fetchPolicy = 'network-only' as FetchPolicy }: any) => {
        const response = await client.query({
            query,
            variables,
            fetchPolicy,
            context: { clientName: 'ucpw' }
        })
        const data = response.data.items.items || [] // items
        return data
    }

    const getQuery = async ({
        query,
        variables = {},
        fetchPolicy = 'network-only' as FetchPolicy, }: any) => {
        const response = await client.query({
            query,
            variables,
            fetchPolicy,
            context: { clientName: 'ucpw' }
        })
        const data = response.data.item.item || {} // item
        return data
    }

    return { listQuery, getQuery }
}


export const useMutations = ({ refetchQueries = [] }: any = {}) => {
    const client = useApolloClient();

    const mutate = async ({
        mutation,
        variables = {},
        fetchPolicy = 'network-only' as MutationFetchPolicy,
        refetchQueries: controlledRefetchQueries = [],
    }: any) => {
        const response = await client.mutate({
            mutation,
            variables,
            fetchPolicy,
            refetchQueries: [
                ...refetchQueries,
                ...controlledRefetchQueries
            ],
            context: { clientName: 'ucpw' }
        })
        const data = response.data.item.item || {} // item
        return data
    }

    const mutateWithShowingErrors = async ({
        mutation,
        variables = {},
        fetchPolicy = 'network-only' as MutationFetchPolicy,
        refetchQueries: controlledRefetchQueries = [],
    }: any) => {
        const response = await client.mutate({
            mutation,
            variables,
            fetchPolicy,
            refetchQueries: [
                ...refetchQueries,
                ...controlledRefetchQueries
            ],
            context: { clientName: 'ucpw' }
        })
        const data = response.data.item || {} // item
        const { error, item } = data

        if (error) {
            const isOutdatedVersion = error?.code === 'OutdatedVersion';
            if (isOutdatedVersion) {
                captureMessage(error);
                addBreadcrumb({
                    ...error,
                    ...(!error?.data &&
                        item && { data: pick(item, 'id', 'version', '__typename') }),
                });
            }
        }

        return data
    }

    return { mutate, mutateWithShowingErrors }
}



export function useServices(props?: any): any {
    const viewer = useViewer()
    const branchId = viewer?.employee?.niederlassungId
    const { listQuery, getQuery } = useQueries()
    const { mutate, mutateWithShowingErrors } = useMutations(props)

    /** queries */
    const fetchBranches = async () => {
        const branches = await listQuery({ query: ListBranchesDocument, variables: { filter: { bezeichnungExists: true }, orderBy: [{ nummer: SortOrder.Asc }] } });
        return { branches, branchId }
    };

    const fetchRoles = async () => await listQuery({
        query: ListRolesDocument,
        variables: { filter: {} } // FIXME: throws somehow an error if no filter is provided 
    });

    const fetchFunctions = async () => await listQuery({
        query: ListFunctionsDocument,
        variables: { filter: {}, orderBy: [{ bezeichnung: SortOrder.Asc }] }
    })

    const limit = import.meta.env.MODE === 'development' ? 100 : 1000

    const fetchEmployees = async ({ branchId: niederlassungId, roleId: rolleId, functionId: funktionId }: any) => await listQuery({
        query: ListEmployeesDocument,
        variables: {
            limit,
            orderBy: [{ name: SortOrder.Asc }],
            filter: {
                niederlassungId,
                ...(rolleId && { rolleId }),
                ...(funktionId && { mitarbeiterFunktion: { funktionId } }),
            }
        },
    })

    const fetchEvents = async ({ scheduleType, branchId: niederlassungId, from: geplantFrom, until: geplantTo, employeeId: mitarbeiterIdSachbearbeiter }: any) => await listQuery({
        query: ListEventsDocument,
        variables: {
            limit,
            filter: {
                geplantFrom,
                geplantTo,
                // ...(niederlassungId && { subprojekt: { projekt: { niederlassungId } } }), FIXME: what if there is no related subprojekt
                ...(niederlassungId && { sachbearbeiter: { niederlassungId } }),
                ...(scheduleType === 'week' && mitarbeiterIdSachbearbeiter && { mitarbeiterIdSachbearbeiter }),
            }
        }
    })

    const fetchAbsences = async ({ scheduleType, branchId: niederlassungId, from: datumUntilFrom, until: datumFromTo, employeeId: mitarbeiterId }: any) => await listQuery({
        query: ListAbsencesDocument,
        variables: {
            // limit: 500,
            limit,
            filter: {
                datumFromTo: getFormat(datumFromTo),
                datumUntilFrom: getFormat(datumUntilFrom),
                ...(mitarbeiterId && scheduleType === 'week' && { mitarbeiterId }),
                ...(niederlassungId && { mitarbeiter: { niederlassungId } })

            }
        }
    })

    const getSubproject = async ({ subprojectId }: any) => await getQuery({ query: GetSubprojectDocument, variables: { id: subprojectId } })

    const generateCorrespondencesAndEventsRefetchQueries = (props: any) => {
        const subprojektId = props?.data?.subprojektId || props?.subprojektId
        return subprojektId ? [
            {
                query: ListCorrespondencesAndEventsDocument,
                variables: { subprojektId: props?.data?.subprojektId || props?.subprojektId },
                context: { clientName: 'ucpw' },
            },
        ] : []
    }

    const createEvent = async (_: any, { variables = {} }: any) => await mutate({
        mutation: CreateEventDocument,
        refetchQueries: generateCorrespondencesAndEventsRefetchQueries(variables),
        variables,
    })

    const updateEvent = async (_: any, { variables = {} }: any) => await mutateWithShowingErrors({
        mutation: UpdateEventDocument,
        refetchQueries: generateCorrespondencesAndEventsRefetchQueries(variables),
        variables
    })

    const deleteEvent = async (_: any, { variables = {} }: any) => await mutateWithShowingErrors({
        mutation: DeleteEventDocument,
        refetchQueries: generateCorrespondencesAndEventsRefetchQueries(variables),
        variables
    })




    return {
        /** queries */
        fetchBranches,
        fetchRoles,
        fetchFunctions,
        fetchEmployees,
        fetchEvents,
        fetchAbsences,
        getSubproject,
        /** mutations */
        createEvent,
        updateEvent,
        deleteEvent,
    }
}