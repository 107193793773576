import { tableColumnMapper, getCommaDecimals } from '~/utils';

// Tables
const columns = {
  measure: {
    __graphqlType: 'Aufmass',
    id: { header: 'Id', accessor: 'id' },
    description: { header: 'Bezeichnung', accessor: 'bezeichnung' },
    type: { header: 'Typ', accessor: 'typSnippet.name' },
    length: { header: 'Länge', accessor: 'laenge', renderer: { name: 'CommaDecimals' } },
    width: { header: 'Breite', accessor: 'breite', renderer: { name: 'CommaDecimals' } },
    height: { header: 'Höhe', accessor: 'hoehe', renderer: { name: 'CommaDecimals' } },
    size: {
      header: 'Grösse',
      accessor: 'groesse',
      cell: ({ row }:any) => {
        const values = [row?.original?.breite, row?.original?.laenge, row?.original?.hoehe];
        if (values.every(Boolean)) {
          return `${getCommaDecimals(values.reduce((acc, value) => acc * value, 1))} m³`;
        }
        return `${getCommaDecimals(values.filter(Boolean).reduce((acc, value) => acc * value, 1))} m²`;
      },
    },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  measure: {
    columns: ['id', 'description', 'type', 'length', 'width', 'height', 'size', 'controls'].map(tableColumnMapper(columns.measure)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  measure: {
    __graphqlInput: 'AufmassInput',
    residentialUnitId: { api: { path: 'subprojektWohneinheitId', required: true } },
    typeSnippetId: { api: { path: 'typSnippetId', required: true } },
    width: { api: { path: 'breite' } },
    length: { api: { path: 'laenge' } },
    height: { api: { path: 'hoehe' } },
    description: { api: { path: 'bezeichnung', required: true } },
  },
};

export const forms = {
  measure: [
    { path: 'residentialUnitId', visibility: { hide: () => true }, validation: false },
    { path: 'description', ui: { label: 'Etage + Raum', colSpan: 3 } },
    { path: 'typeSnippetId', ui: { label: 'Typ', colSpan: 3, component: 'SnippetSelect', props: { category: 'Aufmaß', property: 'Typ' } } },
    { path: 'length', ui: { label: 'Länge / L₁ in m', colSpan: 2, type: 'number', props: { step: '0.01', type: 'number' } } },
    { path: 'width', ui: { label: 'Breite in m', colSpan: 2, type: 'number', props: { step: '0.01', type: 'number' } } },
    { path: 'height', ui: { label: 'Höhe in m', colSpan: 2, type: 'number', props: { step: '0.01', type: 'number' } } },
  ],
};
