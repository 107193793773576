import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, FormHelperText, VStack } from '@chakra-ui/react';

export const CheckboxControl = ({ control, label, defaultChecked = false, description = '', ...props }: any) => {
    const {
        field: { name, value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        // defaultValue: null,
        defaultValue: defaultChecked,
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        inputProps.onChange(checked);
    };

    return (
        <VStack spacing={1} align="start">
            <Checkbox
                name={name}
                onChange={onChange}
                isChecked={value || false}
                data-test-id={`field-${name}`}
                {...props}
            >
                {label}
            </Checkbox>
            {description && <FormHelperText>{description}</FormHelperText>}
        </VStack>

    );
};
