import React from 'react';
import { useNavigate, useLocation, generatePath, createSearchParams } from 'react-router-dom';

export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useEditable = () => {
    useNavigate;
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const [state, setState] = React.useState<{
        editable: boolean;
        editId?: string | null;
        editType?: string | null;
        editBase?: string | null;
    }>({
        editable: !!query.get('edit'),
    });
    const { editable, editId, editType, editBase } = state;

    React.useEffect(() => {
        setState({
            editable: !!query.get('edit'),
            editId: query.get('edit'),
            editType: query.get('type'),
            editBase: query.get('base'),
        });
    }, [location.search]);

    const updateEditable = (id?: string | number, type?: string, base?: string | number) => {
        const path = {
            pathname: location.pathname,
            ...(!!id && {
                search: createSearchParams({
                    edit: String(id),
                    ...(type && { type }),
                    ...(base && { base: String(base) }),
                }).toString(),
            }),
        };

        navigate(path);
    };

    return {
        editId,
        editable,
        updateEditable,
        editType,
        editBase,
    };
};
