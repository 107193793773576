import { Grid, VStack, Flex, Center, Checkbox } from '@chakra-ui/react';
import React from 'react';
import { Column, PermissionGroup, PermissionResource } from './types';

type Props = {
    permissionGroup: PermissionGroup;
    columns: Column[];
    height?: number;
    width?: number;
    gap?: number;
    onChange?: (permissionGroup: PermissionGroup) => void;
};

export function CRUDL({ permissionGroup, columns, height, width, gap, onChange }: Props) {
    const gridProps = {
        templateColumns: `1fr repeat(${columns?.length}, ${width}px)`,
        h: height + 'px',
        w: 'full',
        borderTopWidth: 1,
        borderColor: 'rgb(209, 217, 224)',
        gap,
    };

    const permissionIds = columns.filter((c) => c.id !== 'all').map((c) => c.id);
    const { resources, selectedColumns } = React.useMemo(() => {
        const { resources, selectedColumns } = permissionGroup.resources.reduce(
            (acc, resource) => {
                const all: boolean = permissionIds.every((p) => resource.permissions.includes(p));
                const permissions = [...resource.permissions, all && 'all'].filter(
                    Boolean
                ) as string[];

                return {
                    ...acc,
                    resources: [...acc.resources, { ...resource, permissions }],
                    selectedColumns: all
                        ? acc.selectedColumns
                        : acc.selectedColumns.filter((c) => permissions.includes(c)),
                };
            },
            { resources: [] as PermissionResource[], selectedColumns: columns.map((c) => c.id) }
        );
        return { resources, selectedColumns };
    }, [permissionGroup]);

    const onPermissionAll = React.useCallback(
        (permission: string, checked: boolean) => {
            const resources = permissionGroup.resources.map((resource) => {
                const permissions =
                    permission === 'all' // all for all resources
                        ? checked
                            ? columns.map((c) => c.id)
                            : []
                        : checked // permission for all resources
                        ? [...new Set([...resource.permissions, permission])]
                        : resource.permissions.filter((p) => p !== permission);

                return {
                    ...resource,
                    permissions,
                };
            });
            onChange?.({ ...permissionGroup, resources });
        },
        [permissionGroup]
    );

    const onPermissionChange = React.useCallback(
        (event: { resource: PermissionResource; column: Column; checked: boolean }) => {
            const permission = event.column.id;
            const resources = permissionGroup.resources.map((resource) => {
                if (event.resource.id !== resource.id) {
                    return resource;
                }

                const permissions =
                    permission === 'all' // all permissions
                        ? event.checked
                            ? columns.map((c) => c.id)
                            : []
                        : event.checked // single permission
                        ? [...new Set([...resource.permissions, permission])]
                        : resource.permissions.filter((p) =>
                              p === 'all' ? false : p !== permission
                          );

                return {
                    ...resource,
                    permissions,
                };
            });
            onChange?.({ ...permissionGroup, resources });
        },
        [permissionGroup, selectedColumns]
    );

    return (
        <VStack spacing={0}>
            <Grid {...gridProps} bg="gray.50">
                <Flex align="center" fontWeight="bold" pl={5}>
                    {permissionGroup.name}
                </Flex>
                {columns.map((column) => {
                    return (
                        <Center key={column.id}>
                            <Checkbox
                                isChecked={selectedColumns.includes(column.id)}
                                onChange={(event) =>
                                    onPermissionAll(column.id, event?.target?.checked)
                                }
                            />
                        </Center>
                    );
                })}
            </Grid>
            {resources.map((resource) => {
                return (
                    <Grid key={resource.id} {...gridProps}>
                        <Flex align="center" pl={10}>
                            {resource.name}
                        </Flex>
                        {columns.map((column) => {
                            return (
                                <Center key={`${resource.id}-${column.id}`}>
                                    <Checkbox
                                        isChecked={resource.permissions.includes(column.id)}
                                        onChange={(event) => {
                                            onPermissionChange({
                                                resource,
                                                column,
                                                checked: event?.target?.checked,
                                            });
                                        }}
                                    />
                                </Center>
                            );
                        })}
                    </Grid>
                );
            })}
        </VStack>
    );
}
