import React, { Fragment } from 'react';
import { Permission, Resource, useViewer } from '~/hooks/useViewer';

interface HasPermissionProps {
    resource: Resource;
    permission: Permission;
    children: React.ReactNode;
}

export const HasPermission = ({ children, ...props }: HasPermissionProps) => {
    const viewer = useViewer();

    const hasPermission = viewer.hasPermission(props.resource, props.permission);

    return hasPermission ? <Fragment>{children}</Fragment> : null;
};
