import { Flex, Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { PageHeaderPortal } from '~/components/Layout/PageHeader';

export function Page() {
    return (
        <Flex flex={1} px={0} overflow="hidden" flexDir="column">
            <PageHeaderPortal />
            <Stack flex={1} spacing={{ base: '8', lg: '6' }} overflowY="scroll" py={8} px={6}>
                <Outlet />
            </Stack>
        </Flex>
    );
}
