import { format } from 'date-fns';
import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import { CreateProject } from './pages/projects/ui/CreateProject';
import { CreateSubProject } from './pages/projects/ui/CreateSubProject';
import { Dashboard } from './pages/dashboard/ui/Dashboard';
import { DeviceUsageAndElectricalMeters } from './pages/projects/ui/DeviceUsageAndElectricalMeters';
import { Documents } from './pages/projects/ui/Documents';
import { Finance } from './pages/projects/ui/Finance';
import { Measure } from './pages/projects/ui/Measure';
import { OpenDocuments } from './pages/projects/ui/OpenDocuments';
import { OpenTasks } from './pages/projects/ui/OpenTasks';
import { OperationalReports } from './pages/projects/ui/OperationalReports';
import { ProjectDetails } from './pages/projects/ui/ProjectDetails';
import { ProjectOverview } from './pages/projects/ui/ProjectOverview';
import { RoleForm } from './pages/auth/ui/RoleForm';
import { Roles } from './pages/auth/ui/Roles';
import { SearchProject } from './pages/projects/ui/SearchProject/SearchProject';
import { MasterData } from './pages/master-data/ui/MasterData';
import { Controlling } from './pages/master-data/ui/Controlling';
import { GeneralAgreements } from './pages/master-data/ui/GeneralAgreements';
import { Contacts } from './pages/master-data/ui/Contacts';
import { Staff } from './pages/staffing/ui/Staff';
import { Calendar } from './pages/projects/ui/Calendar/Calendar';
import { EditProject } from './pages/projects/ui/EditProject';
import { Project } from './pages/projects/ui/Project';
import { Schedule } from './pages/projects/ui/Schedule';
import { Snippets } from './pages/master-data/ui/Snippets';
import { Branches } from './pages/master-data/ui/Branches';
import { Account } from './pages/staffing/ui/Account';
import { SecureRoute } from './layout/SecureRoute';
import { Insurances } from './pages/master-data/ui/Insurances';
import { Absences } from './pages/staffing/ui/Absences';
import { SentryTesting } from './pages/testing/SentryTesting';
import { EmergencyProjects } from './pages/projects/ui/Wattro/EmergencyProjects';

export type Nav = { label: string; to?: string; children?: Nav[] };

export type Auth = { resource: string; permission?: string };

export type Meta = { nav?: Nav; loader?: string; auth?: Auth };

export type Route = Omit<RouteObject, 'children'> & {
    children?: Route[];
    meta?: Meta;
};

const today = format(new Date(), 'yyyy-MM-dd');

export const routes: Route[] = [
    {
        path: '/dashboard',
        element: <Dashboard />,
        meta: {
            nav: { label: 'Dashboard' },
        },
    },
    {
        meta: { nav: { label: 'Projekte' } },
        element: <Outlet />,
        children: [
            {
                path: '/projekt',
                element: <Outlet />,
                children: [
                    {
                        path: 'neu',
                        element: (
                            <SecureRoute resource="project.project" permission="create">
                                <CreateProject title="Neues Projekt" />
                            </SecureRoute>
                        ),
                        meta: {
                            nav: { label: 'Neues Projekt' },
                        },
                    },
                    {
                        path: ':projectId/kopieren',
                        element: (
                            <SecureRoute resource="project.project" permission="create">
                                <CreateProject title="Projekt kopieren" />
                            </SecureRoute>
                        ),
                    },
                    {
                        path: ':projectId/neu',
                        element: (
                            <SecureRoute resource="project.project" permission="create">
                                <CreateSubProject title="Neues SubProjekt" />
                            </SecureRoute>
                        ),
                    },
                    {
                        path: ':projectId/:subprojectId/kopieren',
                        element: (
                            <SecureRoute resource="project.project" permission="create">
                                <CreateSubProject title="Subprojekt kopieren" />
                            </SecureRoute>
                        ),
                    },
                    {
                        path: 'suchen',
                        element: (
                            <SecureRoute resource="project.project">
                                <SearchProject title="Projekt suchen" />
                            </SecureRoute>
                        ),
                        meta: {
                            nav: { label: 'Projekt suchen' },
                        },
                    },
                    {
                        path: ':projectId/:subprojectId/bearbeiten',
                        element: (
                            <SecureRoute resource="project.project" permission="update">
                                <EditProject title="Projekt bearbeiten" />
                            </SecureRoute>
                        ),
                    },
                ],
            },
            {
                path: '/projekte/:projectId',
                element: (
                    <SecureRoute resource="project.project">
                        <Project />
                    </SecureRoute>
                ),
                children: [
                    { index: true, element: <ProjectOverview /> },
                    { path: ':subprojectId/details', element: <ProjectDetails /> },
                    { path: ':subprojectId/dokumente', element: <SecureRoute resource="project.documents"><Documents /></SecureRoute> },
                    { path: ':subprojectId/aufmass', element: <Measure /> },
                    { path: ':subprojectId/einsatzberichte', element: <OperationalReports /> },
                    {
                        path: ':subprojectId/geraeteeinsatz',
                        element: <DeviceUsageAndElectricalMeters />,
                    },
                    { path: ':subprojectId/kalkulation?', element: <Finance /> },
                ],
            },
            {
                path: '/projekte/aufgaben',
                element: (
                    <SecureRoute resource="project.tasks">
                        <OpenTasks title="Offene Aufgaben" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Offene Aufgaben' },
                },
            },
            {
                path: '/projekte/termine',
                element: (
                    <SecureRoute resource="project.schedule">
                        <Schedule />
                    </SecureRoute>
                ),
                children: [
                    { index: true, element: <Navigate to={today} /> },
                    { path: 'person', element: <Calendar key="person" /> },
                    { path: ':date?', element: <Calendar /> },
                ],
                meta: {
                    nav: {
                        label: 'Terminplan',
                        to: `/projekte/termine/${format(new Date(), 'yyyy-MM-dd')}`,
                    },
                },
            },
            {
                path: '/projekte/dokumente',
                element: (
                    <SecureRoute resource="project.documents">
                        <OpenDocuments title="Offene Dokumente" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Offene Dokumente' },
                },
            },
            {
                path: '/projekte/notdienst',
                element: (
                    <SecureRoute resource="project.emergencies">
                        <EmergencyProjects title='Notdienst' />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Notdienst' },
                },
            }
        ],
    },
    {
        meta: { nav: { label: 'Stammdaten' } },
        children: [
            {
                path: '/stammdaten/rahmenvereinbarungen',
                element: (
                    <SecureRoute resource="masterData.agreements">
                        <GeneralAgreements
                            key="Rahmenvereinbarungen"
                            title="Rahmenvereinbarungen"
                        />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Rahmenvereinbarungen' },
                },
            },
            {
                path: '/stammdaten/versicherungen',
                element: (
                    <SecureRoute resource="masterData.insurances">
                        <Insurances />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Versicherungen' },
                },
            },
            {
                path: '/stammdaten/textbausteine',
                element: (
                    <SecureRoute resource="masterData.textSnippets">
                        <MasterData key="Textbausteine" title="Textbausteine" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Textbausteine' },
                },
            },
            {
                path: '/stammdaten/gewerk',
                element: (
                    <SecureRoute resource="masterData.trade">
                        <MasterData key="Gewerk" title="Gewerk" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Gewerk' },
                },
            },
            {
                path: '/stammdaten/niederlassungen/:id?',
                element: (
                    <SecureRoute resource="masterData.branches">
                        <Branches title="Niederlassungen" />
                    </SecureRoute>
                ),
                meta: {
                    loader: 'getBranches',
                    nav: { label: 'Niederlassungen', to: '/stammdaten/niederlassungen' },
                },
            },
            {
                path: '/stammdaten/projekttyp',
                element: (
                    <SecureRoute resource="masterData.projectType">
                        <MasterData key="Projekttyp" title="Projekttyp" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Projekttyp' },
                },
            },
            {
                path: '/stammdaten/geraetetyp',
                element: (
                    <SecureRoute resource="masterData.deviceType">
                        <MasterData key="Gerätetyp" title="Gerätetyp" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Gerätetyp' },
                },
            },
            {
                path: '/stammdaten/kontakte',
                element: (
                    <SecureRoute resource="masterData.contacts">
                        <Contacts key="Kontakte" title="Kontakte" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Kontakte' },
                },
            },
            {
                path: '/stammdaten/controlling',
                element: (
                    <SecureRoute resource="masterData.controlling">
                        <Controlling
                            key="Controlling"
                            title="Aufbaudatum und erwarteter Umsatz offener Projekte"
                        />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Controlling' },
                },
            },
            {
                path: '/stammdaten/funktion',
                element: (
                    <SecureRoute resource="masterData.function">
                        <MasterData key="Funktion" title="Funktion" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Funktion' },
                },
            },
            {
                path: '/stammdaten/sonstige-stammdaten/:category?',
                element: (
                    <SecureRoute resource="masterData.others">
                        <Snippets title="Sonstige Stammdaten" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Sonstige Stammdaten', to: '/stammdaten/sonstige-stammdaten/aufgabe' },
                },
            },
        ],
    },
    {
        meta: { nav: { label: 'Personal' } },
        element: <Outlet />,
        children: [
            {
                path: '/personal/berechtigungen',
                meta: {
                    nav: { label: 'Berechtigungen' },
                },
                element: (
                    <SecureRoute resource="staff.permissions">
                        <Outlet />
                    </SecureRoute>
                ),
                children: [
                    { index: true, element: <Roles /> },
                    { path: ':id', element: <RoleForm /> },
                ],
            },
            {
                path: '/personal/mitarbeiter',
                element: (
                    <SecureRoute resource="staff.employees">
                        <Staff key="Mitarbeiter" title="Mitarbeiter" />
                    </SecureRoute>
                ),
                meta: {
                    loader: 'getEmployeeFunctions',
                    nav: { label: 'Mitarbeiter' },
                },
            },
            {
                path: '/personal/abwesenheitsplanung',
                element: (
                    <SecureRoute resource="staff.absences">
                        <Absences key="Abwesenheitsplanung" title="Abwesenheitsplanung" />
                    </SecureRoute>
                ),
                meta: {
                    nav: { label: 'Abwesenheitsplanung' },
                },
            },
        ],
    },
    { path: '/account', element: <Account title="Dein Profil" /> },
    {
        path: '/sentry',
        element: (
            <SecureRoute resource="sentry">
                <SentryTesting title="Sentry Testing" />
            </SecureRoute>
        ),
    },
];
