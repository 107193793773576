import React from 'react';
import {
    ListMeasuresQueryVariables,
    ListMeasuresDocument,
    CreateMeasureDocument,
    UpdateMeasureDocument,
    DeleteMeasureDocument,
    CreateResidentialUnitDocument,
    CreateParticipantDocument,
    BeteiligterInput,
} from '~/gql/ucpw/graphql';
import { tables } from '../meta/data/measures.schema';
import { join, withRenderers } from '~/utils';
import { ProjectIds } from '~/pages/projects/types';
import { useResidentialUnits } from './useResidentialUnits';
import { useProjectDetails } from './useProjectDetails';
import { useDeleteWithConfirmation, usePermission, useSnippetId } from '~/hooks';
import { useMutation, useQuery } from '@apollo/client';
import { useModal } from '~/hooks';
import { useToast } from '@chakra-ui/react';
import { usePdfRefetchQueries } from './usePdf';
import log from '~/log';

export type ResidentialUnit = {
    id: number;
    title: string;
    isOverallObject: boolean;
};

type UseMeasureProps = {
    residentialUnitId?: number;
    // dispatch?: React.Dispatch<any>;
    openModal?: () => void;
    limit?: number;
} & ProjectIds;

export const useMeasure = ({
    projectId,
    subprojectId,
    residentialUnitId,
    limit = 250,
}: UseMeasureProps) => {
    const { subproject: subprojectRefetchQueries } = usePdfRefetchQueries({ subprojectId });
    const { canView, canCreate, canEdit, canDelete } = usePermission('project.measures');
    const readOnly = canView && !(canCreate || canEdit);
    const toast = useToast();
    // ===============================================
    // PROJECT DETAILS
    // ===============================================

    const {
        project,
        subproject,
        loading: detailsLoading,
    } = useProjectDetails({ subprojectId, projectId });

    // ===============================================
    // Residential Units
    // ===============================================

    const overallObjectTitle =
        subproject && join([join([project.plz, project.ort], ' '), project.strasse]);

    const { selectSubprojectResidentialUnitsContextAndRefetchQueries, subprojectUnits } =
        useResidentialUnits({
            projectId,
            subprojectId,
            istGesamtobjekt: true,
        });

    const subprojectResidentialUnits = subprojectUnits?.reduce((acc: any, subprojectUnit: any) => {
        const unit = subprojectUnit?.wohneinheit || {};
        const title = unit?.istGesamtobjekt ? overallObjectTitle : unit?.bezeichnung;
        return [
            ...acc,
            {
                id: subprojectUnit?.id,
                // TODO: remove if provided by api
                humanid: subprojectUnit?.humanid
                    ? subprojectUnit?.humanid
                    : join(
                          [
                              subprojectUnit?.subprojekt?.projekt?.lfdNr,
                              subprojectUnit?.subprojekt?.lfdNr,
                              subprojectUnit?.id,
                          ],
                          '-'
                      ),
                wattroProjectExists: subprojectUnit.wattroProjectExists,
                title,
                isOverallObject: unit.istGesamtobjekt,
            },
        ];
    }, []);

    log.debug('useMeasure.subprojectUnits', subprojectUnits);
    log.debug('useMeasure.subprojectResidentialUnits', subprojectResidentialUnits);

    // ===============================================
    // LIST
    // ===============================================

    const context = { clientName: 'ucpw' };
    const variables: ListMeasuresQueryVariables = {
        limit,
        filter: { subprojektWohneinheitId: residentialUnitId },
    };

    const { data: measureData, loading: measureLoading } = useQuery(ListMeasuresDocument, {
        variables,
        context,
        skip: residentialUnitId === undefined,
    });
    const measurements = measureData?.items?.items || [];

    const refetchQueries = [
        { query: ListMeasuresDocument, context, variables },
        subprojectRefetchQueries,
        ...selectSubprojectResidentialUnitsContextAndRefetchQueries.refetchQueries,
    ];
    const contextAndRefetchQueries = { context, refetchQueries };

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateMeasureDocument,
        contextAndRefetchQueries
    );

    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateMeasureDocument,
        contextAndRefetchQueries
    );

    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteMeasureDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const [createResidentialUnitMutation] = useMutation(
        CreateResidentialUnitDocument,
        contextAndRefetchQueries
    );

    const { typSnippetId: participantTypeSnippetId } = useSnippetId({
        name: 'Mieter',
        category: 'Kontakt',
        property: 'Projektrolle',
    });

    console.log('participantTypeSnippetId', { participantTypeSnippetId });

    const [createParticipantMutation] = useMutation(
        CreateParticipantDocument,
        contextAndRefetchQueries
    );

    const createResidentialUnit = React.useCallback(
        async ({
            variables,
            participantTypeSnippetId: controlledParticipantTypeSnippetId,
        }: {
            variables: { data: { description: string; contactId: string } };
            participantTypeSnippetId?: number;
        }) => {
            const { description, contactId } = variables?.data || {};
            const { data: residentialUnitData } = await createResidentialUnitMutation({
                variables: { subprojektId: subprojectId as number, bezeichnung: description },
            });
            const { item: residentialUnit, error: residentialUnitError }: any =
                residentialUnitData?.app?.item || {};

            if (residentialUnitError) {
                return toast({
                    title: 'Error',
                    description: residentialUnitError.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }

            log.debug({ participantTypeSnippetId, contactId });

            const participantInput = {
                subprojektId: subprojectId,
                kontaktId: parseInt(contactId),
                typSnippetId: participantTypeSnippetId || controlledParticipantTypeSnippetId,
                subprojektWohneinheitId: residentialUnit?.subprojektWohneinheit?.[0]?.id,
            };

            const { data: participantData } = await createParticipantMutation({
                variables: { data: participantInput as BeteiligterInput },
                refetchQueries,
            });

            const { error: participantError } = participantData?.item || {};

            if (participantError) {
                return toast({
                    title: 'Error',
                    description: participantError.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        },
        [participantTypeSnippetId]
    );

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // TABLE
    // ===============================================

    const setMeasureModal = (props: any = {}) => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'ResidentialUnitMeasure',
                props: {
                    createMutation,
                    updateMutation,
                    ...props,
                },
            },
        });
    };

    const onEdit = (row: any, props: any = {}) => {
        const unit = row?.original?.subprojektWohneinheit || {};
        setMeasureModal({
            unit: {
                ...unit,
                title: 'Position bearbeiten',
            },
            ...props,
        });
        dispatch?.({ type: 'setRow', data: { row } });
        onOpen?.();
    };

    const controls = [
        {
            title: 'Ansehen',
            props: {
                onClick: onEdit,
            },
            enabled: () => readOnly,
        },
        {
            title: 'Bearbeiten',
            props: {
                onClick: onEdit,
            },
            enabled: () => canEdit,
        },
        canEdit && {
            title: 'Kopieren',
            props: {
                onClick: (row: any) => {
                    const unit = row?.original?.subprojektWohneinheit || {};
                    setMeasureModal({
                        unit: {
                            ...unit,
                            title: 'Position kopieren',
                        },
                    });
                    dispatch?.({ type: 'setRow', data: { row, mode: 'copy' } });
                    onOpen?.();
                },
            },
            enabled: () => canCreate,
        },
        {
            title: 'divider',
            enabled: () => canDelete && (canEdit || canCreate || readOnly),
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => deleteWithConfirmation({ id: row?.original?.id }),
            },
            enabled: () => canDelete,
        },
    ].filter(Boolean);

    const columns = React.useMemo(() => withRenderers(tables.measure.columns, controls), []);
    const hiddenColumns = tables.measure.hiddenColumns;

    const onCreate = (props: any = {}) => {
        setMeasureModal(props);
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    };

    const loading =
        detailsLoading || measureLoading || createLoading || updateLoading || deleteLoading;

    return {
        participantTypeSnippetId,
        subprojectResidentialUnits,
        measurements,
        loading,
        columns,
        project,
        onCreate,
        onClick: onEdit,
        pageSize: limit,
        hiddenColumns,
        createMutation,
        updateMutation,
        deleteMutation,
        deleteWithConfirmation,
        createResidentialUnit,
    };
};
