import React from 'react';
import { Textbaustein, ListTextSnippetDocument, SortOrder } from "~/gql/ucpw/graphql";
import { useQuery, useLazyQuery } from '@apollo/client';
import { StyledSelect } from '../StyledSelect';

interface Props {
    textbaustein?: Textbaustein;
    onChange?: (value: Textbaustein) => void;
}

function createOptions(data?: any[]) {
    return (
        data?.map((item) => ({
            value: item,
            label: item.gruppe,
        })) || []
    );
}

function SelectTextSnippet({ textbaustein, onChange }: Props) {
    const context = { clientName: 'ucpw' };
    const [input, setInput]: any = React.useState('');

    const { data: textsnippets } = useQuery(ListTextSnippetDocument, {
        variables: {
            limit: 100,
            orderBy: [{ gruppe: SortOrder.Asc }]
        },
        context,
        fetchPolicy: 'network-only',
    });



    const [searchTextSnippet, { data }] = useLazyQuery(ListTextSnippetDocument, { context });

    const options = createOptions(input ? data?.items?.items : textsnippets?.items?.items);
    const value = { value: textbaustein, label: textbaustein?.gruppe };

    return (
        <StyledSelect
            options={options}
            value={value}
            placeholder="Textbausteine"
            onInputChange={(e) => {
                setInput(e);
                searchTextSnippet({ variables: { filter: { gruppePrefix: e } } });
            }}
            onChange={({ value }: any) => {
                onChange?.(value);
            }}
        />
    );
}

export { SelectTextSnippet };
