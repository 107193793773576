import { useModal } from '~/hooks';
import { useCalculation } from '~/pages/projects/hooks/useCalculation';
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { FinanceList } from './FinanceList';
import { Modals } from '~/pages/projects/ui/Finance/Finance';
import { HasPermission } from '~/layout/HasPermission';
import { LIMIT } from './hooks/useFinanceServices'

export const FinanceOverview = ({ context, loading, send, ...props }: any) => {
    const { onOpen, dispatch } = useModal();
    const { projectId, subprojectId } = props;
    const { generalAgreementIdIsStandard = false } = context || {}
    const { columns, hiddenColumns } = useCalculation({ subprojectId, send });

    const setModal = (modal: any) => {
        dispatch?.({
            type: 'setModal',
            data: { modal },
        });
        onOpen?.();
    }

    return (
        <>
            <FinanceList
                projectId={projectId}
                subprojectId={subprojectId}
                loading={loading}
                context={context}
                send={send}
                {...props}
            />
            <Card boxShadow="none" data-test-id="calculation-card">
                <CardHeader>
                    <CardHeading>Kalkulation</CardHeading>
                    <HasPermission resource="finance.calculation" permission="create">
                        <CardActions>
                            {!generalAgreementIdIsStandard && (
                                <PlusButton
                                    isDisabled={loading}
                                    variant="outline"
                                    borderColor="blue.500"
                                    color="blue.500"
                                    data-test-id="button-new-standard-general-aggrement-position"
                                    onClick={() => setModal(Modals.SelectStandardGeneralAggrements)}
                                >
                                    Neue Standard-Position
                                </PlusButton>
                            )}
                            <PlusButton
                                isDisabled={loading}
                                variant="outline"
                                borderColor="blue.500"
                                color="blue.500"
                                data-test-id="button-new-free-position"
                                onClick={() => {
                                    send('SET_CACHE')
                                    setModal(Modals.EditFreePosition)
                                }}
                            >
                                Neue freie Position
                            </PlusButton>
                            <PlusButton
                                isDisabled={loading}
                                data-test-id="button-new-general-aggrement-position"
                                onClick={() => setModal(Modals.SelectGeneralAggrements)}
                            >
                                Neue RV-Position
                            </PlusButton>
                        </CardActions>
                    </HasPermission>
                </CardHeader>
                <CardContent>
                    <DataTable
                        pageSize={LIMIT}
                        columns={columns}
                        hiddenColumns={hiddenColumns as string[]}
                        data={context.calculationpositions}
                        loading={loading}
                    />
                </CardContent>
            </Card>
        </>
    );
};
