import { Badge, BadgeProps, Spinner, Tooltip } from "@chakra-ui/react";
import { SelectSubprojectResidentialUnitsDocument, SortOrder } from "~/gql/ucpw/graphql";
import { useWattro } from "~/pages/projects/ui/Wattro/hooks/useWattro";
import { FaExternalLinkAlt } from "react-icons/fa";
import logo from '~/assets/wattro.svg'

type WattroButtonProps = {
    hasLogo?: boolean;
    subprojectId: number | undefined;
    wattroProjectExists: boolean;
    subprojektResidentialUnitId: number;
    humanid?: string;
} & BadgeProps

export const WattroButton = ({ hasLogo = false, subprojectId, wattroProjectExists = false, humanid, subprojektResidentialUnitId }: WattroButtonProps) => {
    const { wattroCreateProjectForSubprojektWohneinheit, loading } = useWattro({
        refetchQueries: [
            {
                query: SelectSubprojectResidentialUnitsDocument,
                context: { clientName: 'ucpw' },
                variables: {
                    limit: 50,
                    orderBy: [
                        { wohneinheit: { istGesamtobjekt: SortOrder.Desc } },
                        { wohneinheit: { bezeichnung: SortOrder.Asc } },
                    ],
                    filter: { subprojektId: subprojectId },
                }
            }
        ]
    })

    return wattroProjectExists ?
        (<Tooltip label="öffne wattro projekt" placement="top" hasArrow>
            <Badge
                ml="1"
                rounded="md"
                bg="#FCED51"
                fontWeight="extrabold"
                size="sm"
                minH={22}
                display="flex"
                _hover={{ bg: '#fae725' }}
                cursor="pointer"
                alignItems="center"
                userSelect="none"
                onClick={() => window.open(`https://dev-poeppinghaus-wenner.wattro.de/ui/project/by_hid/${humanid}`, '_blank')}
            >
                wattro
                <FaExternalLinkAlt style={{ marginLeft: 3, height: 10 }} />
            </Badge>
        </Tooltip>
        )
        :
        (
            <Tooltip
                label="an wattro senden"
                placement="top"
                hasArrow
            >
                <Badge
                    ml="1"
                    rounded="md"
                    bg="gray.100"
                    fontWeight="extrabold"
                    size="sm"
                    cursor="pointer"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minH={22}
                    minW={55}
                    pointerEvents={loading ? 'none' : 'auto'}
                    _hover={{ bg: 'gray.200' }}
                    onClick={
                        async () =>
                            await wattroCreateProjectForSubprojektWohneinheit({
                                variables: { subprojektWohneinheitId: subprojektResidentialUnitId }
                            })}
                >
                    {loading ? <Spinner size='xs' /> : hasLogo ? <img src={logo} width={50} height="auto" alt="wattro logo" /> : 'wattro'}
                </Badge>
            </Tooltip>
        )
}

