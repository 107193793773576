import React from 'react';
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { format } from 'date-fns';
import {
    ListCorrespondencesAndEventsDocument,
    CreateCorrespondenceDocument,
    UpdateCorrespondenceDocument,
    DeleteCorrespondenceDocument,
    DeleteTaskFunctionDocument,
    CreateTaskFunctionDocument,
    UpdateEventDocument,
    DeleteEventDocument,
    CreateTaskDocument,
    ListTasksDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { join, withRenderers, mutationGuard } from '~/utils';
import { useModal, useDeleteWithConfirmation, usePermission, useViewer } from '~/hooks';
import { tables } from '~/pages/projects/meta/data/correspondence.schema';
import { useServices } from '~/pages/projects/ui/Calendar/hooks/useServices';
import { eventsMachine } from '../ui/Calendar/eventsMachine';
import { resolveProjectDetails } from '~/pages/projects/ui/Calendar/hooks/useEventProjectDetails';
import { usePdfRefetchQueries } from './usePdf';

export const useCorrespondence = (props: { subprojectId?: number; projectId?: number } = {}) => {
    const navigate = useNavigate();
    const viewer = useViewer();
    const { subprojectId, projectId } = props;
    const employeeId = viewer?.app?.mitarbeiterId;

    const { coverSheet: coverSheetRefetchQueries } = usePdfRefetchQueries({ subprojectId });

    // ===============================================
    //  LISTING
    // ===============================================

    const variables = {
        ...(projectId && { projektId: projectId }),
        ...(subprojectId && { subprojektId: subprojectId }),
        limit: 500,
    };

    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListCorrespondencesAndEventsDocument,
        variables,
        [coverSheetRefetchQueries]
    );

    const {
        data,
        loading: listCorrespondencesAndEventsLoading,
        networkStatus,
    } = useQuery(ListCorrespondencesAndEventsDocument, {
        variables,
        notifyOnNetworkStatusChange: true,
        context: contextAndRefetchQueries.context,
    });
    const correspondencesAndEvents = data?.app?.items?.items || [];
    const services = useServices({ refetchQueries: contextAndRefetchQueries.refetchQueries });
    const [states, send] = useMachine(eventsMachine, { services });

    // ===============================================
    // (C)R(UD) Correspondence
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateCorrespondenceDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateCorrespondenceDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteCorrespondenceDocument,
        contextAndRefetchQueries
    );
    const { deleteWithConfirmation: deleteCorrespondence } = useDeleteWithConfirmation({
        deleteMutation: async (variables: any) =>
            await mutationGuard(deleteMutation, {
                variables,
                path: 'data.item.error',
                actions: ['addBreadcrumb', 'captureMessage'],
            }),
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    // ===============================================
    // (C)R(U)D Event
    // ===============================================

    const [updateEventMutation, { loading: updateEventLoading }] = useMutation(
        UpdateEventDocument,
        contextAndRefetchQueries
    );
    const [deleteEventMutation, { loading: deleteEventLoading }] = useMutation(
        DeleteEventDocument,
        contextAndRefetchQueries
    );
    const { deleteWithConfirmation: deleteEvent } = useDeleteWithConfirmation({
        deleteMutation: async (variables: any) =>
            await mutationGuard(deleteEventMutation, {
                variables,
                path: 'data.item.error',
                actions: ['addBreadcrumb', 'captureMessage'],
            }),
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteEventMutation, contextAndRefetchQueries.refetchQueries],
    });

    // ===============================================
    // (C)RU(D) TaskFunction
    // ===============================================

    const listTasksContextAndRefetchQueries = useContextAndRefetchQueries(
        ListTasksDocument,
        {
            limit: 500,
            orderBy: [{ angelegt: SortOrder.Desc }],
            filter: { subprojektId: subprojectId },
        },
        [coverSheetRefetchQueries]
    );

    const options = {
        context: { clientName: 'ucpw' },
        refetchQueries: [
            ...contextAndRefetchQueries.refetchQueries,
            ...listTasksContextAndRefetchQueries.refetchQueries,
        ],
    };

    const [createTaskFunction, { loading: createTaskFunctionLoading }] = useMutation(
        CreateTaskFunctionDocument,
        options
    );

    const [deleteTaskFunction, { loading: deleteTaskFunctionLoading }] = useMutation(
        DeleteTaskFunctionDocument,
        options
    );

    // ===============================================
    // (C)RUD Task
    // ===============================================

    const [createTaskMutation, { loading: createTaskLoading }] = useMutation(
        CreateTaskDocument,
        options
    );

    const deleteWithConfirmation = (row: any) => {
        const original = row?.original || {};
        console.log('deleteWithConfirmation', { original });
        const cond = original?.terminId;
        const variables = cond
            ? { id: original?.terminId, version: original?.termin?.version, forceOverwrite: true }
            : {
                  id: original?.korrespondenzId,
                  version: original?.korrespondenz?.version,
                  forceOverwrite: true,
              };
        const deleteMutation = cond ? deleteEvent : deleteCorrespondence;
        return deleteMutation(variables);
    };

    const loading =
        listCorrespondencesAndEventsLoading ||
        createLoading ||
        updateLoading ||
        deleteLoading ||
        updateEventLoading ||
        deleteEventLoading ||
        createTaskLoading ||
        deleteTaskFunctionLoading ||
        createTaskFunctionLoading ||
        networkStatus === NetworkStatus.loading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // TABLE
    // ===============================================

    const setCorrespondenceModal = React.useCallback(
        () =>
            dispatch?.({
                type: 'setModal',
                data: {
                    modal: 'CorrespondenceModal',
                    props: {
                        createMutation,
                        updateMutation: async (variables: any) =>
                            await mutationGuard(updateMutation, {
                                variables,
                                path: 'data.item.error',
                                actions: ['addBreadcrumb', 'captureMessage'],
                            }),
                        loading,
                        subprojectId,
                        createTaskMutation,
                        createTaskFunction,
                        deleteTaskFunction,
                    },
                },
            }),
        [props, loading]
    );

    const onClick = React.useCallback(
        (row: any) => {
            const { terminId, termin: event, korrespondenz: correspondence } = row?.original;
            if (terminId && event) {
                console.log('useCorr.edit.event', { event });
                dispatch?.({
                    type: 'setModal',
                    data: {
                        modal: 'CreateEvent',
                        props: {
                            onCreateEvent: (variables: any) =>
                                send({ type: 'CREATE_EVENT', variables }),
                            onUpdateEvent: (variables: any) =>
                                send({ type: 'UPDATE_EVENT', variables }),
                        },
                    },
                });
                dispatch?.({
                    type: 'formState',
                    data: {
                        title: `Termin bearbeiten`,
                        variables,
                        version: event?.version,
                        id: event.id,
                        employeeId: {
                            value: event?.mitarbeiterIdSachbearbeiter,
                            label: join([
                                event?.sachbearbeiter?.name,
                                event?.sachbearbeiter?.vorname,
                            ]),
                        },
                        planed: format(new Date(event?.geplant), 'yyyy-MM-dd'),
                        time: format(new Date(event?.geplant), 'HH:mm'),
                        typeSnippetId: { value: event?.typSnippetId },
                        duration: event?.dauer,
                        comment: event?.bemerkung,
                        done: Boolean(event?.erledigt),
                        prio: event?.prio > 0,
                        refetchQueries: contextAndRefetchQueries?.refetchQueries,
                        updateEventMutation: async (variables: any) =>
                            await mutationGuard(updateEventMutation, {
                                variables,
                                path: 'data.item.error',
                                actions: ['addBreadcrumb', 'captureMessage'],
                            }),
                        ...resolveProjectDetails(row?.original?.termin?.subprojekt),
                    },
                });
                return onOpen?.();
            }
            setCorrespondenceModal();
            dispatch?.({ type: 'setRow', data: { row: { original: correspondence } } });
            onOpen?.();
        },
        [dispatch, onOpen, setCorrespondenceModal]
    );

    /** Permin */
    const hasEditAllCorrespondence = viewer.hasPermission(
        'additional.editAllCorrespondence',
        'enabled'
    );
    const {
        canView: canViewCorr,
        canEdit: canEditCorr,
        canDelete: canDeleteCorr,
    } = usePermission('project.correspondence');
    const readOnlyCorr = canViewCorr && !canEditCorr;

    const {
        canEdit: canEditEvent,
        canDelete: canDeleteEvent,
        canView: canViewEvent,
    } = usePermission('project.schedule');

    const is = (row: any) => ({
        correspondence: Boolean(row?.original?.korrespondenzId),
        event: Boolean(row?.original?.terminId),
    });

    const isCreator = (row: any) => {
        const creatorId = row?.original?.mitarbeiterIdUrheber;
        return creatorId === null ? true : creatorId === employeeId;
    };

    const controls = [
        /** <CORRESPONDENCE> */
        projectId && {
            title: 'Zum SubProjekt',
            props: {
                onClick: ({ original }: any) => {
                    const subprojekt = original?.korrespondenz?.subprojekt;
                    subprojekt &&
                        navigate(`/projekte/${subprojekt?.projekt?.id}/${subprojekt?.id}/details`);
                },
            },
            enabled: (row: any) => is(row).correspondence,
        },
        {
            title: 'Ansehen',
            props: { onClick },
            enabled: (row: any) => {
                const isCorrespondence = is(row).correspondence;
                if (isCorrespondence && hasEditAllCorrespondence) {
                    return false;
                }
                return isCorrespondence && (readOnlyCorr || (canEditCorr && !isCreator(row)));
            },
        },
        {
            title: 'Bearbeiten',
            props: { onClick },
            enabled: (row: any) => {
                const isCorrespondence = is(row).correspondence;
                if (isCorrespondence && hasEditAllCorrespondence) {
                    return true;
                }
                return isCorrespondence && !readOnlyCorr && canEditCorr && isCreator(row);
            },
        },
        {
            title: 'divider',
            enabled: (row: any) => {
                const isCorrespondence = is(row).correspondence;
                if (isCorrespondence && hasEditAllCorrespondence) {
                    return true;
                }
                return (
                    isCorrespondence &&
                    canDeleteCorr &&
                    isCreator(row) &&
                    (canViewCorr || canEditCorr)
                );
            },
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: deleteWithConfirmation,
            },
            enabled: (row: any) => {
                const isCorrespondence = is(row).correspondence;
                if (isCorrespondence && hasEditAllCorrespondence) {
                    return true;
                }
                return isCorrespondence && canDeleteCorr && isCreator(row);
            },
        },
        /** </CORRESPONDENCE> */

        /** <EVENT> */
        {
            title: 'Ansehen',
            props: { onClick },
            enabled: (row: any) => row?.original?.terminId && canViewEvent && !canEditEvent,
        },
        {
            title: 'Bearbeiten',
            props: { onClick },
            enabled: (row: any) => row?.original?.terminId && canEditEvent,
        },
        {
            title: 'divider',
            enabled: (row: any) => row?.original?.terminId && canEditEvent && canDeleteEvent,
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: deleteWithConfirmation,
            },
            enabled: (row: any) => row?.original?.terminId && canDeleteEvent,
        },
        /** </EVENT> */
    ].filter(Boolean);

    const columns = React.useMemo(() => {
        const cols = projectId
            ? tables?.projectOverviewCorrespondence.columns
            : tables?.correspondence.columns;

        return withRenderers(cols, controls);
    }, [controls, projectId]);
    const hiddenColumns = tables.correspondence.hiddenColumns;

    const onCreate = () => {
        setCorrespondenceModal();
        dispatch?.({ type: 'setRow', data: { row: { original: {} } } });
        onOpen?.();
    };

    return {
        data: !canViewEvent
            ? correspondencesAndEvents.filter((item) => !Boolean(item?.terminId))
            : correspondencesAndEvents,
        loading,
        columns,
        hiddenColumns,
        pageSize: 500,
        onClick,
        onCreate,
    };
};
