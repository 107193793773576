import * as Sentry from '@sentry/react';
import { Button, HStack, useToast } from '@chakra-ui/react';
import { PageHeader } from '~/components/Layout/PageHeader';

export function SentryTesting({ title }: { title: string }) {
    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
        </PageHeader>
        <TestCases />
    </>;
}

function TestCases() {
    const toast = useToast();
    const sentryErrorBoundary = localStorage.getItem('SENTRY_ERROR_BOUNDARY');

    if (sentryErrorBoundary === 'true') {
        throw new Error('Sentry Error Boundary');
    }

    const onError = () => {
        try {
            // @ts-ignore
            doesNotExist();
        } catch (error) {
            Sentry.captureException(error, { tags: { section: 'test tag' } });
            toast({
                title: 'Sentry.captureException',
                status: 'error',
                duration: 1000,
                isClosable: true,
            });
        }
    };

    const onMessage = () => {
        Sentry.captureMessage('Sentry.captureMessage', { tags: { testing: 'captureMessage' } });
        toast({
            title: 'Sentry.captureMessage',
            status: 'info',
            duration: 1000,
            isClosable: true,
        });
    };

    return (
        <HStack spacing="2" data-test-id="sentry-error">
            <Button colorScheme="red" onClick={onError}>
                Sentry.captureException
            </Button>
            <Button colorScheme="teal" onClick={onMessage}>
                Sentry.captureMessage
            </Button>
        </HStack>
    );
}
