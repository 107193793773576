import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { omit } from 'lodash'
import {
    Button,
    Checkbox,
    GridItem,
    HStack,
    ModalBody,
    ModalContent,
    ModalHeader,
    SimpleGrid,
    chakra,
} from '@chakra-ui/react';
import { Form, renderField } from '~/components/Form/Form';
import { ListMeasuresDocument, SubprojektWohneinheit } from '~/gql/ucpw/graphql';
import { useModal } from '~/hooks/useModal';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { fields, forms } from '~/pages/projects/meta/data/finance.schema';
import { resolveFormFields, getCommaDecimals } from '~/utils';
import { FinanceCalculationProps } from './FinanceCalculation';
import { FinanceContext } from './financeMachine';
import { usePermission } from '~/hooks';
import { useQuery } from '@apollo/client';
import log from '~/log';

type GeneralAgreementModalContentProps = FinanceCalculationProps & { id: string, context?: FinanceContext, send?: (args: any) => void }

export const GeneralAgreementModalContent = ({
    projectId,
    subprojectId,
    send,
    ...props
}: GeneralAgreementModalContentProps) => {
    const { onClose, dispatch } = useModal();
    const { type = 'calculation', cache = {}, calculations = {} } = props?.context || {}
    const { row: position = {}, rowId: positionId, invoiceId, offerId } = cache;
    const formFields = resolveFormFields(
        forms.generalAggrementPosition,
        fields.generalAgreementPosition
    );
    const permissions = usePermission(`finance.${type}`)
    const { canEdit, canCreate } = permissions
    const canSave = positionId && canEdit ? true : !positionId && canCreate ? true : false



    const { highestPosition = 0 } = type === 'calculation' ? calculations : cache;
    const positionValues: any = formFields.toForm(position);
    const defaultValues = {
        ...formFields.defaultValues,
        ...positionValues,
        position: positionValues?.position || highestPosition + 1,
    }
    const context = { positionId, projectId, subprojectId };
    const [quantity, setQuantity] = React.useState(positionValues.quantity || 0);
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });
    const watchValues = watch();
    const residentialUnitIdIsLoading = watchValues?.residentialUnitId?.label?.startsWith('loading');
    const isSquareMeters = watchValues?.unit === 'm2';
    const isCubicMeters = watchValues?.unit === 'm3'

    const subprojektWohneinheit = (watchValues?.residentialUnitId?.item || position.subprojektWohneinheit) as SubprojektWohneinheit || {};
    /** Make sure to get the correct amount of measures instead of getting cut off by 20 */
    const { data } = useQuery(ListMeasuresDocument, {
        variables: { filter: { subprojektWohneinheitId: subprojektWohneinheit?.id, }, limit: 50 },
        skip: !subprojektWohneinheit?.id,
        context: { clientName: 'ucpw' }
    })
    const aufmass = data?.items?.items || []

    const measurements = aufmass?.reduce(
        (acc: any, { bezeichnung: description, laenge: length, breite: width, hoehe: height, id, typSnippet }: any) => {
            const area = [length, width, height]?.filter(Boolean)?.reduce((acc, value) => acc * value, 1)?.toFixed(2)
            const isLengthWidthHeight = [length, width, height].every(Boolean)
            const getLabel = (props: any) => props?.isLengthWidthHeight ?
                `${props?.description}${props?.typSnippet ? ` (${props?.typSnippet?.name})` : ''}: ${getCommaDecimals(props?.length)} m x ${getCommaDecimals(props?.width)} m x ${getCommaDecimals(props?.height)} m = ${getCommaDecimals(props?.area)} m³` :
                `${props?.description}${props?.typSnippet ? ` (${props?.typSnippet?.name})` : ''}: ${getCommaDecimals(props?.length)} m x ${getCommaDecimals(props?.width || props?.height)} m = ${getCommaDecimals(props?.area)} m²`;
            const labelProps = { description, length, width, height, area, isLengthWidthHeight }
            const label = getLabel(labelProps)
            const allowance = {
                id,
                label,
                isLengthWidthHeight,
                typSnippet,
                description,
                length,
                width,
                height,
                area,
            }
            const labelExists = acc?.[label]

            if (labelExists && !(typSnippet?.name === labelExists?.typSnippet?.name)) {
                const labelWithTypeSnippet = getLabel({ ...labelProps, typSnippet })
                return {
                    ...omit(acc, label),
                    [getLabel(labelExists)]: { ...labelExists, label: getLabel(labelExists) },
                    [labelWithTypeSnippet]: {
                        ...allowance,
                        label: labelWithTypeSnippet,
                    },
                }
            }

            return {
                ...acc,
                [label]: allowance,
            }
        }, {}
    ) || {};


    React.useEffect(() => {
        reset(defaultValues);
    }, [highestPosition, positionId, cache]);

    const onSubmit: SubmitHandler<any> = async (values) => {
        log.debug('onSubmit.values', values);
        const { unitPrice = 0, unit } = defaultValues;
        const fixedValues = { unitPrice, unit, subprojectId: subprojectId };
        log.debug('onSubmit.fixedValues', fixedValues);
        const data: any = formFields.toGql(omit(values, 'service'), {}, fixedValues);
        log.debug('onSubmit.data', data);
        const einzelpreis = unitPrice || 0
        const submitData =
            type === 'offer'
                ? { ...data, angebotId: offerId, einzelpreis }
                : type === 'invoice'
                    ? { ...data, rechnungId: invoiceId, einzelpreis }
                    : { ...data, subprojektId: subprojectId, einzelpreis }; // calculation
        log.debug('onSubmit.submitData', submitData);
        log.debug('onSubmit.positionId', positionId);

        const response = positionId
            ? send?.({ type: 'UPDATE_POSITION', variables: { id: positionId, data: submitData } })
            : send?.({ type: 'CREATE_POSITION', variables: { data: submitData } });
        log.debug('onSubmit.response', response);
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        send?.({
            type: 'SET_CACHE', cache: ({ cache = {} }: any = {}) => {
                return { ...omit(cache, 'row', 'rowId') }
            }
        })
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    const updateDescription = (e: React.ChangeEvent<HTMLInputElement>, { label, area }: any) => {
        const { checked } = e.target;
        let lines: string[] = watchValues?.description?.split('\n').filter(Boolean);

        if (checked) {
            setQuantity((prev: any) => prev + parseFloat(area));
            lines.push(label + ';');
        } else {
            setQuantity((prev: any) => prev >= 0 && prev - parseFloat(area));
            lines = lines.filter((item) => item !== label + ';');
        }

        setValue('description', lines.join('\n'));
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {positionId ? 'Position bearbeiten' : 'Neue Position'}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {canSave ? 'Abbrechen' : 'Schließen'}
                    </Button>
                    {canSave && <Button
                        data-test-id="button-save"
                        colorScheme="blue"
                        isLoading={residentialUnitIdIsLoading}
                        isDisabled={residentialUnitIdIsLoading}
                        onDoubleClick={(e) => e.preventDefault()}
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>}
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context,
                                    ...(Boolean(quantity) && !(quantity?.toFixed?.(2) === '-0.00') &&
                                        (isSquareMeters || isCubicMeters) &&
                                        field.name === 'quantity' && {
                                        action: (
                                            <chakra.span
                                                data-test-id="use-measure-calculation"
                                                color="gray.400"
                                                cursor="copy"
                                                onClick={() => setValue('quantity', Math.round((quantity + Number.EPSILON) * 100) / 100)}
                                            >
                                                {quantity && `${quantity?.toFixed?.(2)} ${isSquareMeters ? 'm²' : 'm³'}`}
                                            </chakra.span>
                                        ),
                                    }),
                                })}
                            </React.Fragment>
                        ))}
                        {Object.values(measurements)?.map?.(({ label, isLengthWidthHeight, description, length, area, width, id }: any) => {

                            const defaultChecked =
                                typeof watchValues?.description === 'string' ?
                                    watchValues?.description?.toLowerCase?.()?.split(' ').join('')?.includes(label?.toLowerCase()?.split(' ')?.join('')) :
                                    watchValues?.description?.includes(label);

                            const isDisabled = !isLengthWidthHeight && isCubicMeters ? true : isLengthWidthHeight && isSquareMeters ? true : false;

                            log.debug('measurements', { label, defaultChecked, isCubicMeters, isSquareMeters, isDisabled });

                            return (
                                <GridItem colSpan={2} key={label}>
                                    <Checkbox
                                        data-test-id={`measure-${id}`}
                                        onChange={(e) =>
                                            updateDescription(e, {
                                                label,
                                                description,
                                                length,
                                                width,
                                                area,
                                            })
                                        }
                                        isDisabled={isDisabled}
                                        defaultChecked={defaultChecked}
                                    >
                                        {label}
                                    </Checkbox>
                                </GridItem>
                            );
                        })}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent >
    );
};
