import { PropsWithChildren } from 'react';
import {
    Checkbox,
    Input,
    FormControl,
    FormLabel,
    GridItem,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Radio,
    HStack,
} from '@chakra-ui/react';
import { omit } from 'lodash';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { Select } from '~/components/Form/Select';
import { SnippetSelect } from '~/components/Form/SnippetSelect';
import { ContactInput } from '~/components/Form/ContactInput';
import { DatePicker } from '~/components/Form/DatePicker';
import { DateTimeLocal } from '~/components/Form/DateTimeLocal';
import { TimeRange } from '~/components/Form/TimeRange';
import { RichTextEditor } from '~/components/Form/RichTextEditor';
import { Upload } from '~/components/Form/Upload';
import { PasswordField } from './PasswordField';
import { S3Upload } from '~/components/Form/S3Upload';
import { CheckboxControl } from './CheckboxControl';
import { DocumentUpload } from './DocumentUpload';
import { AvatarUpload } from './AvatarUpload';
import { DocumentsFSMUpload } from './DocumentsFSMUpload';
import log from '~/log'

export const Form = ({ children, ...props }: PropsWithChildren<any>) => {
    /**
     * Note: Warning: Invalid DOM property `Form`. Did you mean `form`?
     * {Form: true, onSubmit: ƒ}
     */
    return <form {...omit(props, 'Form')}>{children}</form>
};

export const renderField = ({
    field,
    control,
    register,
    errors,
    watchFields = {},
    context = {},
    renderers = {},
    action,
}: any) => {
    const ui = typeof field?.ui === 'function' ? field?.ui(context) : field?.ui || {};
    const component = ui?.component || 'Input';
    const Components = {
        Checkbox: Checkbox,
        CheckboxControl: CheckboxControl,
        ContactInput: ContactInput,
        DatePicker: DatePicker,
        DateTimeLocal: DateTimeLocal,
        DocumentUpload: DocumentUpload,
        DocumentsFSMUpload: DocumentsFSMUpload,
        Input: Input,
        QuerySelect: QuerySelect,
        Radio: Radio,
        RichTextEditor: RichTextEditor,
        Select: Select,
        SnippetSelect: SnippetSelect,
        Textarea: Textarea,
        Upload: Upload,
        PasswordField: PasswordField,
        S3Upload: S3Upload,
        AvatarUpload: AvatarUpload,
        TimeRange: TimeRange
    };
    const Component = renderers?.[component] || Components?.[component as keyof typeof Components];
    const isInput = component === 'Input';
    const isCheckbox = [
        'Checkbox',
        'CheckboxControl',
        'Radiobox',
        'InputOnDemand',
        'Dependants',
        'Radio',
        'RadioGroup',
    ].includes(component);
    const isControl = [
        'ContactInput',
        'CheckboxControl',
        'DocumentUpload',
        'DocumentsUpload',
        'DocumentsFSMUpload',
        'QuerySelect',
        'RichTextEditor',
        'Select',
        'SnippetSelect',
        'DateTimeLocal',
        'Radiobox',
        'InputOnDemand',
        'Dependants',
        'PressureTest',
        'FollowUp',
        'MeterReadings',
        'PhotoDocumentation',
        'RadioGroup',
        'S3Upload',
        'AvatarUpload',
        'ProjectSearchInput',
        'DurationSelect',
        'TimeRange',
    ].includes(component);
    // bring your own layout
    const isByoL = ['DocumentUpload', 'DocumentsUpload', 'DocumentsFSMUpload'].includes(component);

    const label = ui?.label;
    const isLabel = !ui?.hideLabel === true;
    const isError = errors?.[field?.name];
    const isHidden = field.isHidden?.(watchFields) || false;
    const dynamicProps = field.dynamicProps?.(watchFields);
    const isDisabled = ui?.props?.isDisabled;
    const isRequired = field.isRequired && !isDisabled;
    const ref = (register && register(field?.name)) || {};

    const fieldProps = {
        name: field.name,
        'aria-label': field.name,
        'data-test-id': `field-${field.name}`,
        ...(ui?.props || {}),
        ...(dynamicProps || {}),
        ...(isInput && { type: ui?.props?.type || ui?.type || 'text' }),
        ...(isControl && { control, label }),
        ...(!isControl && ref),
    };

    log.trace('renderField.fieldProps', fieldProps)

    const gridItemProps = { ...(isHidden && { display: 'none' }) };

    if (isByoL) {
        return <Component {...fieldProps} />;
    }

    return (
        <GridItem colSpan={ui?.colSpan || 1} {...gridItemProps} w="full">
            <FormControl isInvalid={isError} isRequired={isRequired}>
                {isCheckbox ? (
                    <Component {...fieldProps}>{label}</Component>
                ) : (
                    <>
                        {isLabel && !action && <FormLabel htmlFor={field?.name}>{label}</FormLabel>}
                        {action && (
                            <HStack justifyContent="space-between">
                                <FormLabel htmlFor={field?.name}>{label}</FormLabel>
                                {action}
                            </HStack>
                        )}
                        <Component {...fieldProps} />
                    </>
                )}
                {field.helperText && !isError && (
                    <FormHelperText>{field?.helperText}</FormHelperText>
                )}
                <FormErrorMessage>{isError?.message}</FormErrorMessage>
            </FormControl>
        </GridItem>
    );
};
