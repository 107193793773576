import React from 'react';
import { Box, BoxProps, Flex, Heading, HeadingProps, HStack, useColorModeValue } from '@chakra-ui/react';

type PropertyCardProps = BoxProps & { title?: string; actions?: React.ReactElement };

function PropertyCard({ actions, children, title, ...props }: PropertyCardProps) {
    return (
        <Card {...props}>
            {(title || actions) && (
                <HStack
                    alignItems="center"
                    p="26px 24px 25px 24px"
                    flex={1}
                    borderBottomWidth={1}
                    borderColor="gray.200"
                >
                    {title && <CardHeading>{title}</CardHeading>}
                    {actions && (
                        <HStack w="full" justify="flex-end" minH={10}>
                            {actions}
                        </HStack>
                    )}
                </HStack>
            )}
            <Flex flexDir="column">{children}</Flex>
        </Card>
    );
}

function Card(props: BoxProps) {
    return <Box bg={useColorModeValue('white', 'gray.700')} shadow="base" w="full" {...props} />;
}

function CardHeading(props: HeadingProps) {
    return (
        <Heading
            display="flex"
            alignItems="center"
            as="h5"
            fontSize={18}
            fontWeight={700}
            lineHeight={7}
            w="full"
            {...props}
        />
    );
}

export { PropertyCard, Card };
