import React from 'react';
import { Checkbox, SimpleGrid, useBoolean, Tooltip } from '@chakra-ui/react';
import { DataTable, Card, CardHeader, CardActions, CardContent, PlusButton } from '@ucc/react/ui';
import { GetContactCsvExportDocument } from '~/gql/ucpw/graphql';
import { PageHeader } from '~/components/Layout/PageHeader';
import { useQuery } from '@apollo/client';
import { useDownload } from '~/hooks/useDownload';
import { DownloadButton } from '~/components/DownloadButton';
import { SearchField } from '~/components/Form/SearchField';
import { useContacts } from '../hooks/useContacts';
import { HasPermission } from '~/layout/HasPermission';

export function Contacts({ title }: { title: string }) {
    const [showAll, setShowAll] = useBoolean(true);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const searchTermMinLength = searchTerm.length > 1 ? searchTerm : '';
    const { onCreate, ...tableProps } = useContacts({ searchTerm: searchTermMinLength });


    React.useEffect(() => {
        searchTermMinLength ? setShowAll.off() : setShowAll.on();
    }, [searchTermMinLength]);

    React.useEffect(() => {
        showAll && setSearchTerm('');
    }, [showAll]);

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
                <PageHeader.Actions>
                    <Tooltip label="Suche nach mind. 2 Zeichen" isOpen={searchTerm.length === 1}>
                        <SimpleGrid>
                            <SearchField
                                value={searchTerm || ''}
                                onChange={(e) => setSearchTerm(e.target.value || '')}
                                placeholder="Suche"
                                maxW="225"
                            />

                        </SimpleGrid>
                    </Tooltip>
                </PageHeader.Actions>
            </PageHeader>
            <Card boxShadow="none">
                <CardHeader>
                    <CardActions spacing={6}>
                        <Checkbox
                            isChecked={showAll}
                            onChange={setShowAll.toggle}
                            data-test-id="checkbox-toggle-all-contacts"
                        >
                            All Kontakte anzeigen
                        </Checkbox>
                        <HasPermission resource="additional.downloadContacts" permission="enabled">
                            <CsvDownload searchTerm={searchTermMinLength} />
                        </HasPermission>
                        <HasPermission resource="masterData.contacts" permission="create">
                            <PlusButton onClick={onCreate}>Neuer Kontakt</PlusButton>
                        </HasPermission>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...tableProps} />
                </CardContent>
            </Card>
        </>
    );
}

export const CsvDownload = ({ searchTerm, fileName = 'kontakte', ...props }: any) => {
    const { data, loading } = useQuery(GetContactCsvExportDocument, {
        skip: !Boolean(searchTerm),
        variables: { searchTerm },
        context: { clientName: 'ucpw' },
    });
    const csv = data?.app?.kontaktCsvExport.item || '';

    const onClick = useDownload({
        fileName,
        data: csv,
    });

    return (
        <DownloadButton
            colorScheme="gray"
            isLoading={loading}
            isDisabled={!Boolean(csv)}
            onClick={onClick}
            {...props}
        />
    );
};
