import React, { useContext } from 'react';
import { ModalContext } from '~/contexts/ModalContext';
import { combineReducers, modalReducer, rowReducer, formStateReducer } from '~/reducers';

// TODO: find correct React type instead of any
type UseModalType =
    | {
          defaultState: { modals: { activeModal: string } };
          component: any;
          reducer: Record<string, any>;
          dependencies?: any[];
      }
    | any;

export const useModal = (config?: UseModalType) => {
    const { dependencies = [] } = config || {};
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    const { setReducer, setComponent } = context;
    const configIsValidElement = React.isValidElement(config);
    const modalName = config?.type?.name;
    const isMultiModal = modalName === 'MultiModal';

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && config) {
            if (configIsValidElement && !isMultiModal) {
                setReducer?.(
                    combineReducers({
                        modals: modalReducer(modalName),
                        row: rowReducer,
                    }),
                    { modals: { activeModal: modalName } }
                );
            } else if (!configIsValidElement) {
                const { reducer, defaultState, component } = config || {};
                const cond = React.isValidElement(component) && !reducer && !defaultState;
                const activeModal = component?.type?.name;
                if (cond && !(activeModal === 'MultiModal')) {
                    console.log('useModal', activeModal);
                    setReducer?.(
                        combineReducers({
                            modals: modalReducer(activeModal),
                            row: rowReducer,
                            formState: formStateReducer,
                        }),
                        { modals: { activeModal } }
                    );
                } else if (!cond) {
                    setReducer?.(reducer, defaultState);
                }
            }
        }

        () => (isMounted = false);
    }, [configIsValidElement, modalName]);

    React.useEffect(() => {
        let isMounted = true;
        if (configIsValidElement) {
            setComponent?.(config);
        } else if (!configIsValidElement) {
            const { component } = config || {};
            if (isMounted && component) {
                setComponent?.(component);
            }
        }
        () => (isMounted = false);
    }, [configIsValidElement, ...dependencies]);

    return context;
};
