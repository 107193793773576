import React from 'react';
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import {
    SelectSubprojectResidentialUnitsDocument,
    ListResidentialUnitsDocument,
    CreateResidentialUnitDocument,
    UpdateResidentialUnitDocument,
    CreateParticipantDocument,
    DeleteSubprojectResidentialUnitDocument,
    Wohneinheit,
    SortOrder,
    UpdateParticipantDocument,
    ListParticipantsDocument,
    CreateContactDocument,
    UpdateContactDocument,
    DeleteParticipantDocument,
} from '~/gql/ucpw/graphql';
import { useWattro } from '~/pages/projects/ui/Wattro/hooks/useWattro';
import { withRenderers } from '~/utils';
import { tables } from '../meta/data/residentialUnits.schema';
import {
    useContextAndRefetchQueries,
    useDeleteWithConfirmation,
    useModal,
    useSnippetId,
    usePermission,
} from '~/hooks';
import log from '~/log';

export const useResidentialUnits = ({
    tableName = 'units',
    subprojectId,
    projectId,
    openModal,
    istGesamtobjekt = false,
    typeSnippetFilter = {
        name: 'Mieter',
        category: 'Kontakt',
        property: 'Projektrolle',
    },
    limit = 50,
    ...props
}: any) => {
    const { canEdit, canCreate, canDelete, canView } = usePermission('project.residentialUnits');
    const readOnly = canView && !(canCreate || canEdit);
    const { canView: canViewContact } = usePermission('masterData.contacts');
    const toast = useToast();

    const variables = {
        limit,
        orderBy: [{ istGesamtobjekt: SortOrder.Desc }, { bezeichnung: SortOrder.Asc }],
        filter: {
            istGesamtobjekt: false,
            subprojektWohneinheit: { subprojektId: subprojectId },
        },
    };
    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListResidentialUnitsDocument,
        variables
    );
    const { wattroCreateProjectForSubprojektWohneinheit } = useWattro({
        refetchQueries: contextAndRefetchQueries.refetchQueries,
    });

    const selectSubprojectResidentialUnitsVariables = {
        limit,
        orderBy: [
            { wohneinheit: { istGesamtobjekt: SortOrder.Desc } },
            { wohneinheit: { bezeichnung: SortOrder.Asc } },
        ],
        filter: { subprojektId: subprojectId },
    };
    const selectSubprojectResidentialUnitsContextAndRefetchQueries = useContextAndRefetchQueries(
        SelectSubprojectResidentialUnitsDocument,
        selectSubprojectResidentialUnitsVariables
    );

    const { refetchQueries: participantsRefetchQueries } = useContextAndRefetchQueries(
        ListParticipantsDocument,
        { filter: { subprojektId: subprojectId } }
    );

    const refetchQueries = [
        ...contextAndRefetchQueries.refetchQueries,
        ...selectSubprojectResidentialUnitsContextAndRefetchQueries.refetchQueries,
        ...participantsRefetchQueries,
        ...(props.refetchQueries || []),
    ];
    const options = {
        context: contextAndRefetchQueries.context,
        refetchQueries,
    };

    // ===============================================
    // get TypSnippetId of "Mieter"
    // ===============================================

    // TODO: load via application settings - @bjoern you mean like this ?

    const { typSnippetId: participantTypeSnippetId } = useSnippetId(typeSnippetFilter);

    // ===============================================
    // LIST
    // ===============================================

    const {
        data,
        loading: listLoading,
        networkStatus,
    } = useQuery(ListResidentialUnitsDocument, {
        variables,
        notifyOnNetworkStatusChange: true,
        context: contextAndRefetchQueries.context,
    });

    const units = data?.items?.items || [];

    const {
        data: selectSubprojectResidentialUnitsData,
        loading: selectSubprojectResidentialUnitsLoading,
    } = useQuery(SelectSubprojectResidentialUnitsDocument, {
        variables: selectSubprojectResidentialUnitsVariables,
        context: selectSubprojectResidentialUnitsContextAndRefetchQueries.context,
    });

    const subprojectUnits = selectSubprojectResidentialUnitsData?.items?.items || [];

    // ===============================================
    // CREATE
    // ===============================================

    const [createResidentialUnitMutation] = useMutation(CreateResidentialUnitDocument, options);

    const [createParticipantMutation] = useMutation(CreateParticipantDocument, options);

    const createMutation = async ({
        variables,
    }: {
        variables: { data: { description: string; contactId: string } };
    }) => {
        const { description, contactId } = variables?.data || {};
        const { data: residentialUnitData } = await createResidentialUnitMutation({
            variables: { subprojektId: subprojectId, bezeichnung: description },
            refetchQueries,
        });
        const { item: residentialUnit, error: residentialUnitError }: any =
            residentialUnitData?.app?.item || {};

        if (residentialUnitError) {
            return toast({
                title: 'Error',
                description: residentialUnitError.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }

        const participantInput = {
            subprojektId: subprojectId,
            kontaktId: parseInt(contactId),
            typSnippetId: participantTypeSnippetId,
            subprojektWohneinheitId: residentialUnit?.subprojektWohneinheit?.[0]?.id,
        };

        const { data: participantData } = await createParticipantMutation({
            variables: { data: participantInput },
            refetchQueries,
        });

        const { error: participantError } = participantData?.item || {};

        if (participantError) {
            return toast({
                title: 'Error',
                description: participantError.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // ===============================================
    // UPDATE
    // ===============================================

    const [updateResidentialUnitMutation] = useMutation(UpdateResidentialUnitDocument, options);
    const [updateParticipantMutation] = useMutation(UpdateParticipantDocument, options);

    const updateMutation = async ({
        variables,
    }: {
        variables: {
            id: number;
            data: { description: string; contactId: string; residentialUnit: Partial<Wohneinheit> };
        };
    }) => {
        const {
            id: subUnitId,
            data: {
                description,
                contactId,
                residentialUnit: {
                    wohneinheit: { id: unitId, nr },
                    beteiligter,
                },
            },
        }: any = variables;

        const { data: residentialUnitData } = await updateResidentialUnitMutation({
            variables: {
                id: unitId,
                data: { nr, projektId: projectId, bezeichnung: description },
            },
        });

        const { error: residentialUnitError } = residentialUnitData?.item || {};

        if (residentialUnitError) {
            return toast({
                title: 'Error',
                description: residentialUnitError.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }

        const contact = beteiligter?.find((p: any) => p.typSnippetId === participantTypeSnippetId);
        if (contact?.kontakt?.id !== parseInt(contactId)) {
            const participantId = contact?.id;
            const variables = {
                id: participantId,
                version: contact?.version,
                data: {
                    kontaktId: parseInt(contactId),
                    subprojektId: subprojectId,
                    typSnippetId: participantTypeSnippetId,
                    subprojektWohneinheitId: subUnitId,
                },
            };
            log.debug(
                `update participant (${participantId}) from id ${contact?.kontakt?.id} to id ${contactId}`,
                contact,
                variables
            );

            const { data: participantData } = contact?.kontakt?.id
                ? await updateParticipantMutation({
                      variables,
                      refetchQueries,
                  })
                : await createParticipantMutation({
                      variables,
                      refetchQueries,
                  });
            const participantError = participantData?.item?.error;

            if (participantError) {
                return toast({
                    title: 'Error',
                    description: participantError.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    // ===============================================
    // DELETE
    // ===============================================

    const [deleteMutation] = useMutation(DeleteSubprojectResidentialUnitDocument, options);

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: options.refetchQueries,
        dependencies: [deleteMutation, options.refetchQueries],
    });

    const [deleteParticipantMutation, { loading: deleteParticipantLoading }] = useMutation(
        DeleteParticipantDocument,
        options
    );

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // TABLE
    // ===============================================

    const onEdit = (row: any) => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'ResidentialUnits',
                props: {
                    createMutation,
                    updateMutation,
                    subprojectId,
                },
            },
        });
        dispatch?.({ type: 'setRow', data: { row } });
        onOpen?.();
    };

    const [createContactMutation, { loading: createLoading }] = useMutation(
        CreateContactDocument,
        contextAndRefetchQueries
    );
    const [updateContactMutation, { loading: updateLoading }] = useMutation(
        UpdateContactDocument,
        contextAndRefetchQueries
    );

    const controls = [
        { title: 'Ansehen', props: { onClick: onEdit }, enabled: () => readOnly },
        { title: 'Bearbeiten', props: { onClick: onEdit }, enabled: () => canEdit },
        {
            title: 'zum Kontakt',
            props: {
                onClick: (row: any) => {
                    console.log('zum Kontakt', row);
                    dispatch?.({
                        type: 'setModal',
                        data: {
                            modal: 'Contact',
                            props: {
                                createMutation: createContactMutation,
                                updateMutation: updateContactMutation,
                            },
                        },
                    });
                    dispatch?.({
                        type: 'setRow',
                        data: {
                            row: {
                                original: row?.original?.beteiligter?.[0]?.kontakt,
                            },
                        },
                    });
                    onOpen?.();
                },
            },
            enabled: () => canViewContact,
        },
        {
            title: 'an wattro senden',
            props: {
                onClick: async (row: any) => {
                    await wattroCreateProjectForSubprojektWohneinheit({
                        variables: { subprojektWohneinheitId: row?.original?.id },
                    });
                },
            },
            enabled: (row: any) =>
                !row?.original?.wattroProjectExists &&
                row?.original?.subprojekt?.projekttyp?.name === 'Trocknung',
        },
        {
            title: 'divider',
            enabled: (row: any) =>
                canDelete &&
                (canEdit || canViewContact || readOnly) &&
                !row?.original?.wattroProjectExists,
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    const { id, beteiligter = [] } = row?.original || {};
                    const [participant] = beteiligter;
                    console.log('Löschen', row.original);
                    deleteWithConfirmation(
                        { id },
                        {
                            callAfter: async () =>
                                await deleteParticipantMutation({
                                    variables: {
                                        version: participant?.version,
                                        forceOverwrite: true,
                                        id: participant?.id,
                                    },
                                }),
                        }
                    );
                },
            },
            enabled: (row: any) => {
                return canDelete && !row?.original?.wattroProjectExists;
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(() => withRenderers(tables.units.columns, controls), [controls]);
    const hiddenColumns = tables.units.hiddenColumns;

    const onCreate = () => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'ResidentialUnits',
                props: {
                    createMutation,
                    updateMutation,
                },
            },
        });
        dispatch?.({ type: 'setRow', data: { row: { original: {} } } });
        onOpen?.();
    };

    const loading =
        listLoading ||
        networkStatus === NetworkStatus.loading ||
        createLoading ||
        updateLoading ||
        selectSubprojectResidentialUnitsLoading ||
        deleteParticipantLoading;

    const withOutGesamtobjekt = (units: any) =>
        units?.filter((unit: any) =>
            unit?.__typename === 'Wohneinheit'
                ? unit?.istGesamtobjekt === false
                : unit?.__typename === 'SubprojektWohneinheit'
                ? unit?.wohneinheit?.istGesamtobjekt === false
                : true
        );

    return {
        refetchQueries,
        data: istGesamtobjekt ? units : withOutGesamtobjekt(units),
        withOutGesamtobjekt,
        loading,
        columns,
        selectSubprojectResidentialUnitsContextAndRefetchQueries,
        subprojectUnits,
        onClick: onEdit,
        pageSize: limit,
        hiddenColumns,
        onCreate,
    };
};
