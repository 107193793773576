import React from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import {
    ListSnippetDocument,
    CreateSnippetDocument,
    UpdateSnippetDocument,
    DeleteSnippetDocument,
} from '~/gql/ucpw/graphql';
import { resolveFormFields, withRenderers } from '~/utils';
import { fields, forms, tables } from '~/pages/master-data/meta/data/snippets.schema';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useDataLoader } from '~/hooks/useDataLoader';
import { useModal } from '~/hooks/useModal';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { Form, renderField } from '~/components/Form/Form';
import { useDebounce } from '~/hooks/useDebounce';
import log from '~/log';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { usePermission } from '~/hooks/usePermission';

const SnippetsModal = (props: any) => {
    const { createMutation, updateMutation, loading: isLoading, category } = props;
    const { state, dispatch, onClose } = useModal();
    const { row: snippet = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.snippets, fields.snippets);
    const id = useDebounce(rowId, 200);

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(snippet),
        ...(category && { category }),
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({ defaultValues });

    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const data = formFields.toGql(values, {}) as any;
            log.debug('onSubmit.data', JSON.stringify(data, null, 2));
            const response = id
                ? await updateMutation({ variables: { id, data } })
                : await createMutation({ variables: { data } });
            log.debug('onSubmit.response', JSON.stringify(response, null, 2));
        },
        [id]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {id ? 'Wert bearbeiten' : 'Neuer Wert'}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        isLoading={isLoading}
                        data-test-id="button-save-insurance"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={6}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

export const useSnippets = (filter: any = {}) => {
    const { canEdit, canDelete } = usePermission('masterData.others');

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const [load, { data, loading: lazyLoading, refetch }] = useLazyQuery(ListSnippetDocument, {
        context: { clientName: 'ucpw' },
    });
    const insurances: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData, variables } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, { filter });
    const contextAndRefetchQueries = useContextAndRefetchQueries(ListSnippetDocument, variables);

    // ===============================================
    // (C)R(U)D
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateSnippetDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateSnippetDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteSnippetDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading = lazyLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal({
        defaultState: { modals: { activeModal: 'InsuranceModal' } },
        component: <SnippetsModal {...{ createMutation, updateMutation, refetch, loading }} />,
        reducer: combineReducers({
            modals: modalReducer('InsuranceModal'),
            row: rowReducer,
        }),
    });

    // ===============================================
    // CONTROLS
    // ===============================================

    const onClick = React.useCallback(
        (row: any) => {
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: {
                onClick,
            },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.snippets.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.snippets.hiddenColumns;

    const onCreate = React.useCallback(() => {
        dispatch?.({
            type: 'setRow',
            data: {
                ...(filter?.kategoriePrefix && {
                    row: { original: { kategorie: filter?.kategoriePrefix } },
                }),
            },
        });
        onOpen?.();
    }, [filter?.kategoriePrefix]);

    return {
        onCreate,
        data: insurances,
        pageCount,
        hiddenColumns,
        columns,
        loading,
        fetchData: fetchDataWithFilters,
        onClick,
        plusButtonProps: {
            onClick: onCreate,
            children: 'Neuer Wert',
        },
    };
};
