import React from 'react';
import { pick } from 'lodash';
import { format, subMinutes, differenceInMinutes } from 'date-fns';
import { toast, } from '@mobiscroll/react';
import { useSecurity } from '@ucc/react/security';
import { Termin, Snippet } from '~/gql/ucpw/graphql';
import { useSnippetId } from '~/hooks/useSnippetId';

export function useEventUpdate(onUpdateEvent: any) {
    const { viewer } = useSecurity();
    const employeeId = viewer?.app?.mitarbeiterId;
    const { data: typSnippets }: any = useSnippetId({
        name: '',
        category: 'Termin',
        property: 'Typ',
    });

    const hasOverlap = (args: any, inst: any) => {
        const ev = args.event;
        const events = inst.getEvents(ev.start, ev.end).filter((e: any) => e.resource == ev.resource);

        if (events.length === 1 && events?.some?.((event: Termin) => event?.id === ev?.id)) {
            return false;
        }

        return events.length > 0;
    };

    const onEventUpdate = React.useCallback(
        async (args: any, inst: any) => {
            const isOverlap = hasOverlap(args, inst)
            const { event, oldEvent } = args;
            if (isOverlap) {
                toast({
                    message: 'Es sind keine Doppelbuchungen möglich.',
                    color: 'danger',
                });
            }

            const typSnippetId = typSnippets?.find(
                (typSnippet: Snippet) => typSnippet?.name === event?.typSnippet?.name
            )?.id;
            const id = event?.id;
            const { _d, _offset } = event?.start?.m;
            const { _d: _dEnd } = event?.end?.m;
            const start = new Date(_d);
            const end = new Date(_dEnd);

            // subprojektId: Int;
            // version: Int!;
            // mitarbeiterIdSachbearbeiter: Int!;
            // mitarbeiterIdUrheber: Int;
            // korrespondenzId: Int;
            // typSnippetId: Int!;
            // bemerkung: String!;
            // geplant: AWSDateTime;
            // erledigt: AWSDateTime;
            // dauer: Int!;
            // prio: Int;
            const data = {
                ...pick(event, 'subprojektId', 'bemerkung', 'korrespondenzId'),
                mitarbeiterIdSachbearbeiter: event?.resource,
                typSnippetId,
                geplant: subMinutes(new Date(format(start, 'yyyy-MM-dd HH:mm')), _offset),
                dauer: differenceInMinutes(end, start),
                ...(isOverlap && {
                    ...pick(oldEvent, 'geplant', 'dauer'),
                    mitarbeiterIdSachbearbeiter: oldEvent?.resource,
                })
            };

            onUpdateEvent({ id, version: event?.version, data });
        },
        [typSnippets, employeeId]
    );

    return onEventUpdate;
}