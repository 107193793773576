import React from 'react';
import { SimpleGrid, GridItem } from '@chakra-ui/react';
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { PageHeader } from '~/components/Layout/PageHeader';
import { useGeneralAgreements } from '../hooks/useGeneralAgreements';
import { useGeneralAgreementPositions } from '../hooks/useGeneralAgreementPositions';
import { useModal } from '~/hooks';
import { MultiModal } from '~/components/MultiModal';
import { GeneralAgreementPositionsModal } from '~/pages/master-data/ui/GeneralAgreementPositionsModal';
import { GeneralAgreementsModal } from '~/pages/master-data/ui/GeneralAgreementsModal';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { HasPermission } from '~/layout/HasPermission';

export function GeneralAgreements({ title }: { title: string }) {
    const [activeGeneralAgreement, setActiveGeneralAgreement] = React.useState<any>(null);
    const {
        plusButtonProps: generalAgreementsPlusButtonProps,
        standard,
        ...generalAgreementsDataTableProps
    } = useGeneralAgreements();
    const {
        plusButtonProps: generalAgreementPositionPlusButtonProps,
        ...generalAgreementPositionsDataTableProps
    } = useGeneralAgreementPositions(activeGeneralAgreement?.id);

    useModal({
        defaultState: { modals: { activeModal: 'GeneralAgreementPositionsModal' } },
        component: (
            <MultiModal>
                <GeneralAgreementsModal id="GeneralAgreementsModal" />
                <GeneralAgreementPositionsModal id="GeneralAgreementPositionsModal" />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('GeneralAgreementPositionsModal'),
            row: rowReducer,
        }),
    });

    React.useEffect(() => {
        console.log('activeGeneralAgreement', activeGeneralAgreement);
        if (!activeGeneralAgreement && standard) {
            setActiveGeneralAgreement(standard);
        }
    }, [activeGeneralAgreement, standard]);

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
            </PageHeader>
            <SimpleGrid columns={6} spacing={5} p={6}>
                <GridItem colSpan={{ base: 6, sm: 6, md: 6, lg: 6, xl: 2 }}>
                    <Card boxShadow="none">
                        <CardHeader>
                            <CardHeading>Rahmenvereinbarungen</CardHeading>
                            <HasPermission resource="masterData.agreements" permission="create">
                                <CardActions>
                                    <PlusButton {...generalAgreementsPlusButtonProps} />
                                </CardActions>
                            </HasPermission>
                        </CardHeader>
                        <CardContent>
                            <DataTable
                                activeRow={'0'}
                                onClick={({ original }) => {
                                    setActiveGeneralAgreement(original);
                                }}
                                {...generalAgreementsDataTableProps}
                            />
                        </CardContent>
                    </Card>
                </GridItem>
                <GridItem colSpan={{ base: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
                    <Card boxShadow="none">
                        <CardHeader>
                            <CardHeading>RV-Positionen</CardHeading>
                            <HasPermission resource="masterData.agreements" permission="create">
                                <CardActions>
                                    <PlusButton {...generalAgreementPositionPlusButtonProps} />
                                </CardActions>
                            </HasPermission>
                        </CardHeader>
                        <CardContent>
                            <DataTable {...generalAgreementPositionsDataTableProps} />
                        </CardContent>
                    </Card>
                </GridItem>
            </SimpleGrid>
        </>

    );
}
