import React from "react";
import * as yup from "yup";
import { useForm, SubmitHandler } from 'react-hook-form';
import { useModal, useDebounce, useYupValidationResolver, usePermission } from "~/hooks";
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import { forms, fields } from '~/pages/projects/meta/data/residentialUnits.schema';
import { Form, renderField } from "~/components/Form/Form";
import { resolveFormFields } from "~/utils";
import { HasPermission } from '~/layout/HasPermission';
import log from '~/log';

export const ResidentialUnitsModalContent = ({ formState = {}, ...props }: any) => {
    const { onClose, state, dispatch } = useModal();
    const { createMutation, updateMutation, subprojectId } = state?.modals?.props || props || {}
    const { row: unit = {}, rowId } = state.rows || {};
    const isSubprojectResidentialUnit = unit?.__typename === 'SubprojektWohneinheit'
    const [description, setDescription] = React.useState(isSubprojectResidentialUnit ? unit?.wohneinheit?.bezeichnung : unit?.bezeichnung || '')
    const formFields = isSubprojectResidentialUnit ? resolveFormFields(forms.subprojectUnits, fields.subprojectUnits) : resolveFormFields(forms.units, fields.units);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(isSubprojectResidentialUnit ? unit : { ...unit, subprojektWohneinheit: unit?.subprojektWohneinheit?.filter((unit: any) => unit?.subprojektId === subprojectId) }),
        ...(state.formState || {}),
    };

    const unitId = useDebounce(rowId, 200)

    const { control, register, handleSubmit, reset, formState: { errors }, watch } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    /** Permit */
    const { canCreate, canEdit, canView } = usePermission('project.residentialUnits');
    const readOnly = rowId && canView && !(canCreate || canEdit);
    const modalTitle = readOnly ? 'Wohneinheit ansehen' : rowId ? 'Wohneinheit bearbeiten' : 'Neue Wohneinheit';
    const permssionType = rowId ? 'update' : 'create';
    const cursor = readOnly ? 'not-allowed' : 'auto';
    const pointerEvents = readOnly ? 'none' : 'auto';

    const { description: desc } = watch()

    React.useEffect(() => {
        /**
        * because ContactSearchModalContent can now be triggered by several Modals inside a MultiModal, 
        * please specify here the modalStateOnSelect
        * */
        dispatch?.({
            type: 'setModalStateOnSelect',
            data: {
                modalStateOnSelect: {
                    activeModal: 'ResidentialUnits',
                    closeModal: false,
                    props: {
                        updateMutation,
                        createMutation
                    },
                },
            }
        });
    }, [])

    React.useEffect(() => {
        setDescription(desc)
    }, [desc])

    React.useEffect(() => {
        dispatch?.({
            type: 'formState',
            data: {
                ...formState,
                ...(description && { description }),
            }
        });
    }, [description]);

    const onSubmit: SubmitHandler<any> = async (values: any) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        log.debug(unitId ? `UPDATE ${unitId}` : `CREATE`);
        const response = unitId
            ? await updateMutation?.({
                variables: { id: unitId, data: { ...values, residentialUnit: unit } },
            })
            : await createMutation({ variables: { data: values }, ...(props?.participantTypeSnippetId && { participantTypeSnippetId: props?.participantTypeSnippetId }) });
        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {readOnly ? 'Schließen' : 'Abbrechen'}
                    </Button>
                    <HasPermission resource='project.residentialUnits' permission={permssionType}>
                        <Button
                            data-test-id="button-save-residential-unit"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmitWithOnClose)}
                        >
                            Speichern
                        </Button>
                    </HasPermission>
                </HStack>
            </ModalHeader>
            <ModalBody cursor={cursor}>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4} pointerEvents={pointerEvents}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({ field, control, register, errors })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};