import React from 'react';
import { Props } from 'react-select';
import { Control, FieldValues, useController } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Box } from '@chakra-ui/react';
import { isEqual } from 'lodash'
import { unflatten } from 'flat';
import { usePage } from '~/hooks/usePage';
import { StyledSelect } from './StyledSelect';



type QuerySelectProps = Props & {
    query: DocumentNode | any;
    limit?: number;
    variables?: any;
    orderBy?: Record<string, 'desc' | 'asc'>[] | Record<string, 'desc' | 'asc'>;
    mapOptions?: (data: any) => Option;
    onChange?: (data: any) => any;
    onSelect?: (data: any) => any;
    defaultValue?: any
    forcedValue?: { value: any; label: string } | [{ value: any; label: string }]
    control: Control<FieldValues>;
    display?: string;
    meta?: Record<string, any>;
};

type Option = { value: any; label: string };

/** default sorting based on OperationDefinition name of DocumentNode */
const orderByCollection = {
    selectBranches: { nummer: 'asc' }, // niederlassung
    selectEmployees: { name: 'asc' }, // mitarbeiter
    selectFunction: { bezeichnung: 'asc' }, // rolle
    selectDeviceType: { bezeichnung: 'asc' }, //geraetetyp
    selectUnits: { bezeichnung: 'asc' }, // leistung
    selectFunctions: { bezeichnung: 'asc' }, // funktion
    selectProjectType: { name: 'asc' }, // projekttyp
    selectTrade: { name: 'asc' }, //gewerk
    selectGeneralAgreements: { bezeichnung: 'asc' }, // rahmenvertrag
    selectInsurances: { bezeichnung: 'asc' }, // versicherung
    selectSubprojectResidentialUnits: { wohneinheit: { bezeichnung: 'asc' } } // subprojektWohneinheit
}

export const QuerySelect = ({
    control,
    query,
    limit = 500,
    variables = {},
    onChange,
    orderBy,
    onSelect,
    mapOptions = (item) => ({ value: item?.value, label: item?.label }),
    forcedValue,
    meta = {},
    ...props
}: QuerySelectProps) => {


    const operationDefinitionName = query?.definitions?.[0]?.name?.value;
    const [isLoading, setIsLoading] = React.useState(false)
    const isSelectEmployees = operationDefinitionName === 'selectEmployees';
    const defaultOrderBy = orderBy ? [unflatten(orderBy)] : operationDefinitionName in orderByCollection ? [orderByCollection?.[operationDefinitionName as keyof typeof orderByCollection]] : [];
    const { pageContext } = usePage();

    const {
        field: { ref, value, ...inputProps }, // eslint-disable-line @typescript-eslint/no-unused-vars
        fieldState: { invalid }, // eslint-disable-line @typescript-eslint/no-unused-vars
    } = useController({
        name: props?.name || '',
        control,
        defaultValue: props?.defaultValue || null,
    });


    const { data, loading } = useQuery(query, {
        variables: {
            limit,
            ...variables,
            // filter for niederslassungId if query is selectEmployees and pageContext has branchId and is not loading (this means employee switched branch)
            ...(isSelectEmployees && pageContext?.branchId && !isLoading && { filter: { niederlassungId: pageContext?.branchId } }),
            orderBy: Array.isArray(orderBy) ?
                orderBy.map(item => unflatten(item)) : defaultOrderBy
        }, // TODO: paging
        context: { clientName: 'ucpw' },
        fetchPolicy: 'network-only', // necessary to get fresh data after creating new branch for example
    });

    React.useEffect(() => onChange?.(data?.items?.items || []), [data]);

    const options = data?.items?.items?.map(mapOptions)?.filter(Boolean) || [];

    const defaultValue = value
        ? Array.isArray(value)
            ? options.filter((option: Option) =>
                value.map(({ value }) => value).includes(option.value)
            )
            : options.find((option: Option) => option.value === value.value)
        : value;

    const { display } = props;
    const boxProps = { display, 'data-test-id': props?.name };
    const selection = defaultValue ? defaultValue : value?.value === undefined ? null : value


    React.useEffect(() => {
        // this is normally the case when employee belongs to a different branch 
        if (selection?.label === 'loading...') {
            setIsLoading(true)
        }

    }, [selection?.label, operationDefinitionName])


    React.useEffect(() => {
        if (!loading && !isEqual(selection, value)) {
            inputProps?.onChange?.(selection)
        }
    }, [loading, selection, value])


    return (
        <Box w="full" {...boxProps}>
            <StyledSelect
                isLoading={loading}
                aria-invalid={invalid}
                // make zMitarbeiter visible again, instead of displaying 'loading...' 
                value={defaultValue === undefined && value?.value && meta?.[props?.name as keyof typeof meta] ? meta?.[props?.name as keyof typeof meta] : selection}
                {...inputProps}
                onChange={(e) => {
                    inputProps?.onChange?.(e);
                    onSelect?.(e);
                }}
                options={options}
                {...props}
            />
        </Box>
    );
};
