import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { HasPermission } from '~/layout/HasPermission';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { useFinance } from '~/pages/projects/hooks/useFinance';
import { ProjectIds } from '~/pages/projects/types';
import { FinanceContext } from './financeMachine';

type FinanceListProps = ProjectIds & { totalPriceNet?: number; calculationPositions?: any[], data?: any[], loading?: boolean, send?: (args?: any) => void, context?: FinanceContext, viewer?: any, permissions?: any }

export function FinanceList({
    projectId,
    subprojectId,
    send,
    loading,
    context,
    viewer,
}: FinanceListProps) {
    const { updateEditable } = useEditable();
    const { totalPriceNet, calculationpositions: calculationPositions, financeList = [] } = context || {}
    const { columns, hiddenColumns } = useFinance({ projectId, subprojectId, totalPriceNet, calculationPositions, send, viewer });

    const onClick = (type: 'invoice' | 'offer') => {
        send?.(`NEW_${type?.toUpperCase()}`)
        updateEditable('new', type);
    }

    return (
        <Card boxShadow="none">
            <CardHeader>
                <CardHeading>Angebote / Rechnungen</CardHeading>
                <CardActions>
                    <HasPermission resource="finance.invoice" permission="create">
                        <PlusButton
                            isDisabled={loading}
                            data-test-id="button-new-invoice"
                            onClick={() => onClick('invoice')}
                        >
                            Neue Rechnung
                        </PlusButton>
                    </HasPermission>
                    <HasPermission resource="finance.offer" permission="create">
                        <PlusButton
                            isDisabled={loading}
                            data-test-id="button-new-offer"
                            onClick={() => onClick('offer')}
                        >
                            Neues Angebot
                        </PlusButton>
                    </HasPermission>
                </CardActions>
            </CardHeader>
            <CardContent>
                <DataTable
                    data={financeList}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    loading={loading}
                />
            </CardContent>
        </Card>
    );
}
