import React from 'react';
import { useController } from 'react-hook-form';
import { useAsset } from '~/hooks/useAsset';
import { Avatar, Center, HStack, VStack, Button, Input } from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';

export const AvatarUpload = ({ control, ...props }: any = {}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [imgSrc, setImgSrc] = React.useState<string>();
    const { download, upload } = useAsset();

    const {
        field: { name, value, onChange },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });

    React.useEffect(() => {
        if (!value) return;
        console.log('AvatarUpload.download', value);
        download(value)
            .then((img) => {
                setImgSrc(img as string);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [value]);

    const open = () => inputRef?.current?.click();

    const handleChange = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target?.files?.[0];
        console.log('AvatarUpload.handleChange', { file });
        if (file instanceof File) {
            const { key } = await upload(file, { prefix: 'avatars' });
            onChange?.(key);
        }
    }, []);

    const removeAvatar = () => {
        setImgSrc(undefined);
        onChange?.(null);
    };

    return (
        <Center h="full">
            <HStack spacing={6}>
                <VStack>
                    <Avatar key={imgSrc} size="xl" src={imgSrc} />
                    {/* <Text fontSize="sm" mt="3" color="gray.500" w="147px" textAlign="center">
                        .jpg, .gif, or .png.
                    </Text>
                    <Text fontSize="sm" mt="3" color="gray.500" w="147px" textAlign="center">
                        Max file size 700K.
                    </Text> */}
                </VStack>
                <VStack spacing="5">
                    <Button colorScheme="blue" onClick={open}>
                        Bild Hochladen
                    </Button>

                    <Input
                        name={name}
                        type="file"
                        display="none"
                        ref={inputRef}
                        onChange={handleChange}
                    />

                    <Button variant="ghost" color="gray.700" onClick={removeAvatar}>
                        Löschen
                    </Button>
                </VStack>
            </HStack>
        </Center>
    );
};
