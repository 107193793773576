import React from 'react';
import { format } from 'date-fns';

interface Download extends Partial<React.MouseEvent> {
    data?: any;
    fileName?: string;
    fileType?: string;
}

export function download({ data, fileName = 'report', fileType: type = 'text/csv' }: Download) {
    const blob = new Blob([data], { type });
    const a = document.createElement('a');
    a.download = [
        fileName,
        '_',
        format(new Date(), 'yyyyMMdd_HHmmss'),
        ['.', type?.split('/')?.[1]]?.join(''),
    ]?.join('');
    a.href = window.URL.createObjectURL(blob);

    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
}

export function useDownload(args?: Download) {
    if (args) {
        return React.useCallback(() => {
            download(args);
        }, [args]);
    }

    return () => download(args as Download | any);
}
