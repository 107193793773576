import { graphql } from '../gql';

export const SecurityRoleFragment = graphql(/*GraphQL */ `
    fragment SecurityRoleFragment on SecurityRole {
        id
        name
        abbreviation
        description
        system
        created
        modified
        permissionGroups {
            id
            name
            type
            permissionSet
            resources {
                id
                name
                permissions
                value
            }
        }
    }
`);

export const createRole = graphql(/*GraphQL */ `
    mutation createRole($data: SecurityRoleCreateInput!) {
        security {
            role: createRole(data: $data) {
                data {
                    ...SecurityRoleFragment
                }
                error {
                    code
                    message
                    data
                }
            }
        }
    }
`);

export const getRole = graphql(/*GraphQL */ `
    query getRole($id: ID!) {
        security {
            role: getRole(where: { id: $id }) {
                data {
                    ...SecurityRoleFragment
                }
                error {
                    code
                    message
                    data
                }
            }
        }
    }
`);

export const updateRole = graphql(/*GraphQL */ `
    mutation updateRole($id: ID!, $data: SecurityRoleUpdateInput!) {
        security {
            role: updateRole(id: $id, data: $data) {
                data {
                    ...SecurityRoleFragment
                }
                error {
                    code
                    message
                    data
                }
            }
        }
    }
`);

export const deleteRole = graphql(/*GraphQL */ `
    mutation deleteRole($id: ID!) {
        security {
            role: deleteRole(id: $id) {
                data
                error {
                    code
                    message
                    data
                }
            }
        }
    }
`);

export const listRoles = graphql(/*GraphQL */ `
    query listRoles {
        security {
            roles: listRoles {
                data {
                    ...SecurityRoleFragment
                }
                error {
                    code
                    message
                    data
                }
            }
        }
    }
`);
