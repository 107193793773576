import { DataTable, Card, CardHeader, CardActions, CardContent, PlusButton } from '@ucc/react/ui'
import { useForm } from 'react-hook-form';
import { Form } from '~/components/Form/Form';
import { useQuery } from "@apollo/client";
import { useStaff } from '../hooks/useStaff';
import { ListEmployeeFunctionsDocument } from '~/gql/ucpw/graphql';
import { PageHeader } from '~/components/Layout/PageHeader';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { selects } from '~/meta/data/selects.gql';
import { HasPermission } from '~/layout/HasPermission';
import { HStack } from '@chakra-ui/react';
import { client } from '~/apollo';

function useEmployeeFunctions() {
    const { data, loading } = useQuery(ListEmployeeFunctionsDocument, { context: { clientName: 'ucpw' }, variables: { limit: 1000, filter: {} } });
    const employeeFunctions = data?.items?.items || [];
    const functionsByEmployee = employeeFunctions.reduce((acc: any, employeeFunction: any) => ({ ...acc, [employeeFunction?.mitarbeiterId]: [...(acc?.[employeeFunction?.mitarbeiterId] || []), employeeFunction] }), {})
    return { employeeFunctions: functionsByEmployee, loading }
}

export async function getEmployeeFunctions() {
    const employeeFunctions = await client
        .query({ query: ListEmployeeFunctionsDocument, context: { clientName: 'ucpw' }, variables: { limit: 1000, filter: {} } })
        .then(({ data }) => data.items?.items);
    return employeeFunctions.reduce((acc: any, employeeFunction: any) => ({ ...acc, [employeeFunction?.mitarbeiterId]: [...(acc?.[employeeFunction?.mitarbeiterId] || []), employeeFunction] }), {})
}

export function Staff({ title }: { title: string }) {
    const { employeeFunctions, loading } = useEmployeeFunctions()
    return <StaffCore title={title} employeeFunctions={employeeFunctions} loading={loading} />
}

export function StaffCore({ title, employeeFunctions, loading = false }: { title: string, employeeFunctions?: any, loading?: boolean }) {
    const { control, watch }: any = useForm({ defaultValues: {} });
    const { branch } = watch();
    const branchId = branch?.value;
    const { onCreate, ...tableProps } = useStaff({ branchId, branch, employeeFunctions })

    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
            <PageHeader.Actions>
                <Form>
                    <HStack w={200}>
                        <QuerySelect
                            control={control}
                            isClearable
                            name="branch"
                            placeholder="Niederlassung"
                            query={selects.branches}
                            mapOptions={(item) => ({
                                value: item?.value,
                                label: `${item?.nummer} - ${item?.bezeichnung}`,
                            })}
                        />
                    </HStack>
                </Form>
            </PageHeader.Actions>
        </PageHeader>
        <Card boxShadow="none">
            <CardHeader>
                <CardActions>
                    <HasPermission resource="staff.employees" permission="create">
                        <PlusButton
                            data-test-id="button-new-staff"
                            onClick={onCreate}
                        >
                            Neuer Mitarbeiter
                        </PlusButton>
                    </HasPermission>
                </CardActions>
            </CardHeader>
            <CardContent>
                <DataTable {...tableProps} loading={tableProps?.loading || loading} />
            </CardContent>
        </Card>
    </>;
}
