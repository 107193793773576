import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Text, Box } from '@chakra-ui/react';
import { isJsonString, richtextToString } from '~/utils/richText';

const renderText = (textNode: any) => {
    // Split text on \n to insert <br> tags
    const parts = textNode.text.split('\n');
    let formattedText = parts.map((part: any, index: number) => (
        <Fragment key={`text-${uuidv4()}`}>
            {part}
            {index < parts.length - 1 && <br />}
        </Fragment>
    ));

    if (textNode.bold) {
        formattedText = <strong>{formattedText}</strong>;
    }
    if (textNode.italic) {
        formattedText = <em>{formattedText}</em>;
    }
    if (textNode.underline) {
        formattedText = <u>{formattedText}</u>;
    }

    return formattedText;
};

export const renderElement = (node: any) => {
    const children = node.children.map((child: any) => (
        <Fragment key={`element-${uuidv4()}`}>
            {'text' in child ? renderText(child) : renderElement(child)}
        </Fragment>
    ));

    switch (node.type) {
        case 'paragraph':
            return <Text>{children}</Text>;
        case 'numbered-list':
            return <ol>{children}</ol>;
        case 'bulleted-list':
            return <ul>{children}</ul>;
        case 'list-item':
            return <li>{children}</li>;
        case 'page-break':
            return <>{children}<hr /></>;
        default:
            return <>{children}</>;
    }
};


export const Description = (info: any) => {
    const value = info.getValue();
    const parsedValue = value?.raw && isJsonString(value.raw) ? JSON.parse(value.raw) : isJsonString(value) ? JSON.parse(value) : value;

    return Array.isArray(parsedValue) ? (
        <Box>
            {parsedValue.map((node: any, idx: number) => (
                <Fragment key={`description-${uuidv4()}`}>
                    {renderElement(node)}
                </Fragment>
            ))}
        </Box>
    ) : parsedValue || null
};

export const OperationalReportDescription = (info: any) => {
    const value = info.getValue();
    const operationalReportDescription = value?.find?.(
        (detail: any) => detail?.einsatzberichtOption?.bezeichnung === 'beschreibung_einsatzbericht'
    );


    if (isJsonString(operationalReportDescription?.text)) {
        let parsedValue = JSON.parse(operationalReportDescription?.text)
        try {
            if (
                parsedValue &&
                typeof parsedValue === 'string' &&
                JSON.parse(JSON.parse(parsedValue))
            ) {
                parsedValue = JSON.parse(parsedValue);
            }
        } catch (err) {
            // it wasn't double encoded, do nothing
        }


        if (Array.isArray(parsedValue)) {
            return (
                <>
                    {parsedValue.map((node: any) => (
                        <Fragment key={`operational-report-description-${uuidv4()}`}>
                            {renderElement(node)}
                        </Fragment>
                    ))}
                </>
            )
        }

        const text = richtextToString(operationalReportDescription?.text);
        return (
            <Text noOfLines={3} maxW={150}>
                {text}
            </Text>
        )
    }

    return null;
};
