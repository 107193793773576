import React, { useCallback, useState } from "react";
import { format, subMonths } from 'date-fns';
import { BsSearch } from "react-icons/bs";
import { ModalContent, ModalBody, Modal, ModalOverlay, HStack, Tooltip, InputGroup, InputLeftElement, Input, Text, VStack, Button, FormLabel, Box, Fade } from "@chakra-ui/react"
import { CustomModalHeader } from "~/components/CustomModalHeader"
import { useDialog } from "~/hooks/useDialog";
import { useDebounce, useFetchDataWithFilter } from "~/hooks";
import { useSearchProject } from "~/pages/projects/ui/SearchProject/SearchProject";
import { tables } from '~/pages/projects/meta/data/projects.schema';
import { useQuery } from '@apollo/client';
import { DataTable } from "@ucc/react/ui";
import { ListNotdienstprojektDocument, SelectSubprojectResidentialUnitsDocument, SortOrder } from "~/gql/ucpw/graphql";
import { StyledSelect } from "~/components/Form/StyledSelect";
import { useWattro } from "~/pages/projects/ui/Wattro/hooks/useWattro";

export function EmergencyProjectsDialog() {
    return (
        <>
            <ProjectSelectionDialog />
            <ResidentialUnitsDialog />
        </>
    )
}

export function ProjectSelectionDialog() {
    const { isOpen, onClose, onOpen, type, data = {} } = useDialog();
    const isModalOpen = isOpen && type === 'selectProject'
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
    const { columns, loading, pageCount, projects, fetchData } = useSearchProject();

    const variables = {
        searchInput: {
            ...(debouncedSearchTerm
                ? { projektLfdNr: parseInt(debouncedSearchTerm, 10) }
                : {
                    anlageBis: format(new Date(), 'yyyy-MM-dd'),
                    anlageAb: format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
                }),
        },
        orderBy: [{ projekt: { anlageDatum: 'desc' } }],
    };
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, variables);
    const onChooseResidentialUnit = (row: any) => onOpen('chooseResidentialUnit', { notdienstprojekt: data, subprojekt: row.original })

    return (
        <Modal isOpen={isModalOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent rounded="none" maxWidth="container.xl">
                <CustomModalHeader
                    infos={<Info {...data} />}
                    title="Projekt auswählen"
                    mb={0}>
                    <HStack>
                        <Tooltip label="Suche nach laufender Nummer" placement="top" hasArrow openDelay={250}>
                            <InputGroup width={400}>
                                <InputLeftElement pointerEvents="none" color="gray.400">
                                    <BsSearch />
                                </InputLeftElement>
                                <Input
                                    type="search"
                                    placeholder="Suche"
                                    value={searchTerm}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setSearchTerm(e.target.value)
                                    }
                                />
                            </InputGroup>
                        </Tooltip>
                    </HStack>
                </CustomModalHeader>
                <ModalBody p={0}>
                    <DataTable
                        columns={columns}
                        hiddenColumns={tables.projectSearch.hiddenColumns}
                        loading={loading}
                        data={projects}
                        pageCount={pageCount}
                        fetchData={fetchDataWithFilters}
                        pageSize={15}
                        onClick={onChooseResidentialUnit}
                    />
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}

export function ResidentialUnitsDialog() {
    const [selectedUnit, setSelectedUnit] = useState<any>(null)
    const { isOpen, onOpen, onClose, type, data = {} } = useDialog();
    const isModalOpen = isOpen && type === 'chooseResidentialUnit'
    const { notdienstprojekt, subprojekt, refetch } = data
    const {
        data: unitsData,
        loading,
    } = useQuery(SelectSubprojectResidentialUnitsDocument, {
        variables: {
            limit: 50,
            orderBy: [{ wohneinheit: { istGesamtobjekt: SortOrder.Asc } }],
            filter: { subprojektId: subprojekt?.id },
        },
        skip: !Boolean(subprojekt?.id),
        context: { clientName: 'ucpw' },
    });
    const units = unitsData?.items?.items || []
    const options = units?.
        filter?.((unit: any) => !Boolean(unit.deleted) && !unit?.wattroProjectExists)?.
        map((unit: any) => ({ value: unit?.id, label: unit?.wohneinheit?.bezeichnung, ...unit }))
    const {
        wattroAssignNotdienstprojektToSubprojektWohneinheit,
        loading: wattroLoading
    } = useWattro({
        refetchQueries: [
            {
                query: ListNotdienstprojektDocument,
                context: { clientName: 'ucpw' },
                variables: { limit: 500, filter: {} }
            }
        ]
    })

    const hint = useCallback(() => {
        const removeDeleted = units?.filter?.((unit: any) => !Boolean(unit.deleted))
        const residentialUnits = removeDeleted?.filter?.((unit: any) => unit?.wohneinheit?.istGesamtobjekt === false)
        const hasUnits = residentialUnits.length > 0
        const someHaveNoWattro = residentialUnits.some?.((unit: any) => !unit?.wattroProjectExists)
        const ending = 'Die Anlage der wattro Projekte könnte unvollständig sein. Bitte prüfen Sie die Wohneinheiten und legen den wattro Termin gegebenenfalls nachträglich aus dem Geräteeinsatz an.'

        if (hasUnits && someHaveNoWattro) {
            return ''
        }

        if (hasUnits && !someHaveNoWattro && options.length > 0) {
            return 'Es wurden bereits alle Wohneinheiten als wattro Projekte angelegt. ' + ending
        }

        if (options.length === 0) {
            return 'Es wurden bereits alle Wohneinheiten sowie auch das Gesamtobjekt als wattro Projekte angelegt. ' + ending
        }

        return 'Es wurden noch keine Wohneinheiten angelegt. ' + ending

    }, [options, units])

    console.log('ResidentialUnitsDialog...', { units, unitsData, options }, options.length === 0 || options?.[0]?.wohneinheit?.istGesamtobjekt)

    return (
        <Modal isOpen={isModalOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent rounded="none" maxWidth="container.md">
                <CustomModalHeader
                    title="Wohneinheit wählen"
                    infos={<Info {...notdienstprojekt} />}
                >
                    <HStack>
                        <Button
                            data-test-id="emergency-projects-cancel-button"
                            variant="outline"
                            onClick={() => onOpen('selectProject', notdienstprojekt)}>
                            Abbrechen
                        </Button>
                        <Button
                            isDisabled={loading || selectedUnit === null}
                            isLoading={loading || wattroLoading}
                            onClick={
                                async () => {
                                    await wattroAssignNotdienstprojektToSubprojektWohneinheit({ variables: { notdienstprojektId: notdienstprojekt.id, subprojektWohneinheitId: selectedUnit?.id } })
                                    refetch?.()
                                    onClose()
                                }}
                            data-test-id="emergency-projects-save-button"
                            colorScheme="blue">
                            Speichern
                        </Button>
                    </HStack>
                </CustomModalHeader>
                <ModalBody pb={6}>
                    <VStack alignItems="start" spacing={2} mb={5} >
                        <FormLabel>Wohneinheit</FormLabel>
                        <Box w="full">
                            <StyledSelect
                                onChange={(value: any) => setSelectedUnit(value)}
                                options={options} />
                        </Box>
                    </VStack>
                    <Fade in={Boolean(hint())} transition={{ enter: { delay: 0.5, duration: 0.1 } }}>
                        <VStack align="flex-start">
                            <Text fontWeight="bold">Hinweis:</Text>
                            <Text>{hint()}</Text>
                        </VStack>
                    </Fade>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

function Info(props: Record<string, string>) {
    const { humanid, name, address, notes } = props

    return (
        <VStack fontSize={12} fontWeight="medium" spacing={0} color="gray.600" align="flex-start">
            {[...new Set([humanid, name, address, notes])].filter(Boolean).map((item: any, idx: number) => <Text key={`emergency-info-${idx + 1}`}>{item}</Text>)}
        </VStack>
    )
}
