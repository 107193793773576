import { join } from '~/utils';

export const contactReducer =
    (startModal: string, selectModal?: string) =>
    (state: any = {}, action: any = {}) => {
        const { type, data = {} } = action;

        switch (type) {
            case 'searchContact': {
                const { fieldName } = data;

                const nextState = {
                    ...state,
                    modals: {
                        ...(state.modals || {}),
                        activeModal: startModal,
                    },
                    contacts: {
                        ...(state.contacts || {}),
                        field: fieldName,
                        values: {},
                    },
                };

                return nextState;
            }
            case 'setDefaultAddress': {
                const { defaultAddress } = data;
                const nextState = { state, defaultAddress };
                return nextState;
            }
            case 'setModalStateOnSelect': {
                const { modalStateOnSelect } = data;
                const nextState = { ...state, modalStateOnSelect };
                return nextState;
            }
            case 'selectContact': {
                console.log('selectContact', state);
                const { field } = state.contacts;
                const contact = data.contact || {};
                const modalStateOnSelect = data.modalStateOnSelect || {
                    activeModal: selectModal || startModal,
                    closeModal: selectModal === undefined,
                };

                const nextState = {
                    ...state,
                    modals: {
                        ...(state.modals || {}),
                        ...modalStateOnSelect,
                        ...(state?.modalStateOnSelect || {}),
                    },
                    contacts: {
                        ...(state.contacts || {}),
                        values: {
                            ...(state.contacts?.values || {}),
                            [field]: {
                                contact: contact,
                                contactId: contact?.id,
                                displayName: join([
                                    contact.firma1,
                                    contact.firma2,
                                    contact.name,
                                    contact.vorname,
                                ]),
                            },
                        },
                    },
                };

                return nextState;
            }
            case 'resetState': {
                const resetState = { ...state, contacts: {} };
                console.log('contactReducer.resetState', resetState);
                return resetState;
            }
        }
    };
