import { HStack, ModalHeader, ModalHeaderProps, VStack, Text } from "@chakra-ui/react"

type Props = ModalHeaderProps & {
    title: string
    children?: React.ReactNode
    infos?: React.ReactNode
}

export const CustomModalHeader = ({ title, children = null, mb = 6, infos = null }: Props) => {
    return (
        <ModalHeader
            borderBottomWidth={1}
            borderColor="gray.200"
            mb={mb}
            p={5}
        >
            <VStack w="full" align="flex-start">
                <HStack justifyContent="space-between" w="full" alignItems="center">
                    <Text>{title}</Text>
                    <HStack>{children}</HStack>
                </HStack>
                {infos}
            </VStack>

        </ModalHeader>
    )
}