import React from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    useBreakpointValue,
    useColorModeValue,
    IconButton,
    InputGroup,
    InputRightElement,
    useDisclosure,
    Center,
    chakra,
    useMergeRefs,
} from '@chakra-ui/react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useForm, SubmitHandler } from 'react-hook-form';
import poeppinghaus from '~/assets/logo_poeppinghaus.png';

type Inputs = {
    username: string;
    password: string;
};

interface SignInProps {
    signIn?: (args: any) => void;
    federatedSignIn?: () => void;
}

export const SignIn = ({ signIn }: SignInProps) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const { register, handleSubmit } = useForm<Inputs>();
    const [status, setStatus] = React.useState('idle');
    const { ref, ...rest } = register('password');
    const mergeRef = useMergeRefs(inputRef, ref);

    const isLoading = status === 'loading';

    const onClickReveal = () => {
        onToggle();
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    };

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        // setStatus('loading');
        signIn?.(data);
    };

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }} h="90vh">
            <Stack spacing="6" justify="space-between" h="full">
                <Center>
                    <chakra.img width={350} src={poeppinghaus} alt="company logo" />
                </Center>
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    <Heading
                        className="signInHeading"
                        size={useBreakpointValue({ base: 'xs', md: 'sm' })}
                    >
                        Sign in to your account
                    </Heading>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing="6">
                                <Stack spacing="5">
                                    <FormControl>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Input type="email" {...register('username')} />
                                    </FormControl>
                                    <FormControl>
                                        <HStack justify="space-between">
                                            <FormLabel htmlFor="password">Password</FormLabel>
                                            <Button variant="link" colorScheme="blue" size="sm">
                                                Forgot password?
                                            </Button>
                                        </HStack>
                                        <InputGroup>
                                            <InputRightElement>
                                                <IconButton
                                                    variant="link"
                                                    aria-label={
                                                        isOpen ? 'Mask password' : 'Reveal password'
                                                    }
                                                    icon={isOpen ? <HiEyeOff /> : <HiEye />}
                                                    onClick={onClickReveal}
                                                />
                                            </InputRightElement>
                                            <Input
                                                {...rest}
                                                ref={mergeRef}
                                                type={isOpen ? 'text' : 'password'}
                                                autoComplete="current-password"
                                                required
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Stack>
                                <Stack spacing="6">
                                    <Button
                                        type="submit"
                                        colorScheme="blue"
                                        isLoading={isLoading}
                                        loadingText="Sign in"
                                    >
                                        Sign in
                                    </Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
                <Box />
            </Stack>
        </Container>
    );
};
