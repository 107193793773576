import { resolveFormFields, tableColumnMapper } from '~/utils';
import { selects } from '~/pages/projects/meta/data/projects.gql';

export const shortkeys = ['/', '*'];

export const ui = {
  state: {
    reducer: (state:any, action:any) => {
      switch (action.type) {
        case 'branches.linkVariables': {
          const filter = action.linkVariables?.filter;
          const newState = { ...state, branches: { ...state.branches, variables: { ...(state.branches?.variables || {}), filter } } };
          console.log('stateReducer', { action, newState, state });
          return newState;
        }
        case 'branches.filter': {
          const { zipCode, filter } = action;
          const newState = {
            ...state,
            branches: {
              ...state.branches,
              variables: {
                ...(state.branches?.variables || {}),
                filter: {
                  ...(!Boolean(zipCode) && filter),
                  plzPrefix: zipCode,
                  ...(shortkeys.includes(zipCode) && { plzPrefix: '', ...filter }),
                },
              },
            },
          };
          console.log('stateReducer', { action, newState, state });
          return newState;
        }
        default: {
          return state;
        }
      }
    },
  },
  tables: [{ id: 'branches', colSpan: 3, snippets: { create: 'Neue Niederlassung' } }],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
  branches: {
    __graphqlType: 'Niederlassung',
    id: { header: 'id', accessor: 'id', width: 40 },
    zipCode: { header: 'Postleitzahl', accessor: 'plz' },
    location: { header: 'Ort', accessor: 'ort' },
    branchId: { header: 'Niederlassung Id', accessor: 'niederlassungId' },
    branch: {
      header: 'Niederlassung',
      accessor: 'niederlassung',
      renderer: { name: 'Branch' } 
    },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  branches: {
    columns: ['id', 'zipCode', 'location', 'branchId', 'branch', 'controls'].map(tableColumnMapper(columns.branches)),
    hiddenColumns: ['id', 'niederlassungId'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  branches: {
    __graphqlInput: 'NiederlassungInput',
    number: { api: { path: 'nummer', required: true } },
    description: { api: { path: 'bezeichnung', required: true } },
  },
  zipCode: {
    __graphqlInput: 'PostleitzahlInput',
    zipCode: { api: { path: 'plz', required: true } },
    location: { api: { path: 'ort', required: true } },
    branch: { api: { path: 'niederlassungId', required: true } },
  },
};

export const forms = {
  branches: [
    {
      path: 'number',
      ui: ({ action = '' } = {}) => ({ label: 'Nummer', type: 'number', props: { isDisabled: action === 'update' } }),
      validation: false,
      include: true,
    },
    { path: 'description', ui: { label: 'Bezeichnung' } },
  ],
  zipCode: [
    { path: 'zipCode', ui: { label: 'Postleitzahl' } },
    { path: 'location', ui: { label: 'Ort' } },
    {
      path: 'branch',
      ui: {
        label: 'Niederlassung',
        component: 'QuerySelect',
        props: {
          query: selects.branches,
          mapOptions: (item:any) => ({
            value: item?.value,
            label: `${item?.nummer} - ${item?.bezeichnung}`,
          }),
        },
      },
    },
  ],
};

export const branches = {
  columns: tables.branches.columns,
  tableProps: { pageSize: 10, hiddenColumns: tables.branches.hiddenColumns },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.branches, fields: fields.branches },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.branches, fields.branches, context),

      snippets: {
        create: 'Neue Niederlassung',
        update: 'Niederlassung bearbeiten',
        displayName: (item: any) => `${item?.nummer} ${item?.bezeichnung}`,
      },

      fixedFields: ['number'],
    };
  },
};

export const branchZipCode = {
  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.zipCode, fields: fields.zipCode },

  getForm: (context = {}) => {
    console.log('branchZipCode"', context, resolveFormFields(forms.zipCode, fields.zipCode, context));
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.zipCode, fields.zipCode, context),

      snippets: {
        update: 'Postleitzahl zuordnen',
        create: 'Neue Postleitzahl',
      },
    };
  },
};
