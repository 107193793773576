import React from 'react';

export function useChunkSize() {
    const [windowInnerWidth, setWindowInnerWidth] = React.useState(window.innerWidth);
    const [size, setSize] = React.useState(10);

    React.useEffect(() => {
        function handleResize() {
            setWindowInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        const size =
            windowInnerWidth <= 1300
                ? 2
                : windowInnerWidth <= 1800
                ? 3
                : windowInnerWidth <= 2200
                ? 4
                : windowInnerWidth <= 2500
                ? 5
                : windowInnerWidth <= 2700
                ? 7
                : windowInnerWidth <= 3000
                ? 9
                : windowInnerWidth <= 3200
                ? 10
                : windowInnerWidth <= 3500
                ? 12
                : 15;

        setSize(size);
    }, [windowInnerWidth]);

    return size;
}
