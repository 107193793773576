import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    CreateDeviceUsageDocument,
    DeleteDeviceUsageDocument,
    Geraeteeinsatz,
    ListDeviceUsageDocument,
    ListDeviceUsageQueryVariables,
    UpdateDeviceUsageDocument,
} from '~/gql/ucpw/graphql';
import {
    useContextAndRefetchQueries,
    useDeleteWithConfirmation,
    useModal,
    usePermission,
} from '~/hooks';
import { tables } from '~/pages/projects/meta/data/device-usage.schema';
import { ProjectIds } from '~/pages/projects/types';
import { withRenderers } from '~/utils';
import { useDeviceUsageSettlement } from './useDeviceUsageSettlement';
import { Modals } from '~/pages/projects/ui/DeviceUsageAndElectricalMeters';

type Props = {
    tableName?: 'deviceUsage';
    residentialUnitId: number;
    dispatch?: React.Dispatch<any>;
    onOpen?: () => void;
    refetchQueries?: [];
    residentialUnit?: any;
    limit?: number;
} & ProjectIds;

export const useDeviceUsage = ({
    tableName = 'deviceUsage',
    residentialUnitId,
    subprojectId,
    projectId,
    residentialUnit,
    limit = 250,
    ...props
}: Props) => {
    const { canEdit, canCreate, canDelete, canView } = usePermission('project.deviceUsage');
    const readOnly = canView && !(canCreate || canEdit)

    // ===============================================
    // LIST
    // ===============================================

    const context = { clientName: 'ucpw' };
    const variables: ListDeviceUsageQueryVariables = {
        limit,
        filter: {
            subprojektWohneinheitId: residentialUnitId,
            subprojektWohneinheit: {
                subprojektId: subprojectId,
                subprojekt: {
                    projektId: projectId,
                },
            },
        },
    };

    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListDeviceUsageDocument,
        variables
    );

    const {
        data,
        loading: listLoading,
        error,
    } = useQuery(ListDeviceUsageDocument, { variables, context });
    const deviceUsage = data?.items?.items || [];

    const refetchQueries = [
        // internal
        ...contextAndRefetchQueries.refetchQueries,
        // usePdf needs refetching as well
        {
            query: ListDeviceUsageDocument,
            context,
            variables: {
                limit,
                filter: {
                    subprojektWohneinheit: {
                        subprojektId: subprojectId,
                        subprojekt: {
                            projektId: projectId,
                        },
                    },
                },
            },
        },
        // external
        ...(props.refetchQueries || []),
    ];

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const options = { context, refetchQueries };
    const [createMutation, { loading: createLoading }] = useMutation(
        CreateDeviceUsageDocument,
        options
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateDeviceUsageDocument,
        options
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteDeviceUsageDocument,
        options
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries,
        dependencies: [deleteMutation, refetchQueries],
    });

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // TABLE
    // ===============================================

    const isNotEmpty = deviceUsage.length > 0;
    const allSettled = deviceUsage.reduce((acc: any, usage: any) => acc && usage.abrechnen, true);
    const allUnsettled: any = deviceUsage.filter((usage: any) => !usage.abrechnen);

    const { updateSettlement } = useDeviceUsageSettlement({ projectId, subprojectId });

    const settleAll = async () => {
        for (const usage of allUnsettled) {
            await updateSettlement({
                id: usage?.id as number,
                deviceUsage: usage as Geraeteeinsatz,
                settle: true,
            });
        }
    };

    const setDeviceUsageModal = () => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.DeviceUsage,
                props: {
                    createMutation,
                    updateMutation,
                    residentialUnit,
                },
            },
        });
    };

    const onClick = (data = {}) => {
        setDeviceUsageModal();
        dispatch?.({ type: 'setRow', data });
        onOpen?.();
    }

    const controls =
        [
            {
                title: 'Ansehen',
                props: {
                    onClick: (row: any) => onClick({ row }),
                },
                enabled: () => readOnly
            },
            {
                title: 'Einfügen',
                props: {
                    onClick: () =>
                        onClick({ row: { original: {} } }),
                },
                enabled: () => canCreate
            },
            {
                title: 'Bearbeiten',
                props: {
                    onClick: (row: any) => onClick({ row }),
                },
                enabled: () => canEdit && isNotEmpty
            },
            {
                title: 'Abrechnen',
                props: (row: { original: Geraeteeinsatz }) => {
                    const { original } = row;

                    return {
                        onClick: () => {
                            updateSettlement({
                                id: row.original?.id,
                                deviceUsage: row.original,
                                settle: true,
                            });
                        },
                        isDisabled: original.abrechnen === true,
                    };
                },
                enabled: () => canEdit && isNotEmpty
            },
            {
                title: 'Abrechnen [Alle]',
                props: {
                    onClick: () => settleAll(),
                    isDisabled: allSettled,
                },
                enabled: () => canEdit
            },
            {
                title: 'divider',
                enabled: () => canDelete && isNotEmpty && (canEdit || canCreate || readOnly)
            },
            {
                title: 'Löschen',
                props: {
                    color: 'red.400',
                    onClick: (row: any) => deleteWithConfirmation({ id: row?.original?.id }),
                },
                enabled: () => canDelete && isNotEmpty
            },
        ].filter(Boolean);


    const columns = React.useMemo(
        () => withRenderers(tables[tableName].columns, controls),
        [allSettled, allUnsettled, setDeviceUsageModal]
    );
    const hiddenColumns = tables[tableName].hiddenColumns;

    const loading = listLoading || createLoading || updateLoading || deleteLoading;

    return {
        data: isNotEmpty ? deviceUsage : [{} as Geraeteeinsatz],
        loading,
        error,
        columns,
        hiddenColumns,
        createMutation,
        updateMutation,
        deleteMutation,
        pageSize: limit,
        deleteWithConfirmation,
    };
};
