import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { omit, pick } from 'lodash';
import { format } from 'date-fns';
import { useSecurity } from '@ucc/react/security';
import { Button, Container, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { Form, renderField } from '~/components/Form/Form';
import { SectionCard } from '~/components/SectionCard';
import { EinsatzberichtDetail } from '~/gql/ucpw/graphql';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { useOperationalReports } from '~/pages/projects/hooks/useOperationalReports';
import { useProjectDetails } from '~/pages/projects/hooks/useProjectDetails';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { forms, fields } from '~/pages/projects/meta/data/operation-reports.schema';
import {
    graphQlToOperationalReportOptions,
    operationalReportOptionsToGraphQL,
} from '~/pages/projects/meta/operationalReport';
import {
    combineFields,
    formFieldsToGraphQl,
    graphQlToFormFields,
    isJsonString,
    resolveFormFields,
} from '~/utils';
import { ProjectIds } from '~/pages/projects/types';
import * as renderers from './renderers';
import { useModal, usePermission } from '~/hooks';
import { MultiModal } from '~/components/MultiModal';
import { ContactSearchModalContent } from '../ContactSearchModalContent';
import { CreateContactModalContent } from '../CreateContactModalContent';
import { combineReducers, contactReducer, formStateReducer, modalReducer } from '~/reducers';
import { HasPermission } from '~/layout/HasPermission';
import { OverrideActions } from '~/components/Layout/PageHeader';
import log from '~/log';

export const OperationalReportForm = ({
    projectId,
    subprojectId,
    send,
}: ProjectIds & { send: (args: any) => void }) => {
    // TODO: EinsatzberichInput has no mitarbeiterIdUrheber yet
    const { viewer } = useSecurity();
    const { readOnly, canView, canCreate, canEdit } = usePermission('project.report')
    const employeeId = viewer.app?.mitarbeiterId;
    const { subproject } = useProjectDetails({ projectId, subprojectId });
    const { updateEditable, editId } = useEditable();
    const formFields = {
        reportMasterData: resolveFormFields(forms.reportMasterData, fields),
        causeOfDamage: resolveFormFields(forms.causeOfDamage, fields),
        workReport: resolveFormFields(forms.workReport, fields),
        measuresTaken: resolveFormFields(forms.measuresTaken, fields),
        evidence: resolveFormFields(forms.evidence, fields), // Leckortung
        customerInformation: resolveFormFields(forms.customerInformation, fields),
        followUpActions: resolveFormFields(forms.followUpActions, fields), // Leckortung
        photoDocumentation: resolveFormFields(forms.photoDocumentation, fields), // Leckortung
        checklist: resolveFormFields(forms.checklist, fields),
        meterReadings: resolveFormFields(forms.meterReadings, fields), // Trocknung
    };
    const { defaultValues, rules } = combineFields(...Object.values(formFields));
    const {
        report,
        isReadLoading,
        loading,
        details,
        options,
        upsertMutation,
        resolvedResetValues,
    } = useOperationalReports({
        projectId,
        subprojectId,
    });
    const allFields: any = Object.values(forms).reduce(
        (acc: any, fields: any) => [...acc, ...fields],
        []
    );

    useModal({
        defaultState: { modals: { activeModal: 'SearchContact' } },
        component: (
            <MultiModal>
                <ContactSearchModalContent id="SearchContact"
                    modalStateOnSelect={{
                        activeModal: 'SearchContact',
                        closeModal: true,
                    }} />
                <CreateContactModalContent id="CreateContact" />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('SearchContact'),
            contacts: contactReducer('SearchContact'),
            formState: formStateReducer,
        }),
    });

    const formToGql = formFieldsToGraphQl(allFields, fields);
    const gqlToForm = graphQlToFormFields(allFields, fields);


    const reportOptionsToGql = operationalReportOptionsToGraphQL(allFields, options);
    const gqlToReportOptions = graphQlToOperationalReportOptions(allFields, options);
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(rules)),
    });

    const setFolgemassnahmenNotwendig = (defaultValues: any): void => {
        const { weitereArbeiten, agHandwerker } = pick(defaultValues, 'weitereArbeiten', 'agHandwerker') || {};
        const folgemassnahmenNotwendig = [weitereArbeiten, agHandwerker].some((item: any) => item?.jaNein ? true : item?.text && isJsonString(item?.text) ? JSON.parse(item?.text)?.length > 0 : false)
        console.log('setFolgemassnahmenNotwendig', { weitereArbeiten, agHandwerker, folgemassnahmenNotwendig, setValue })
        folgemassnahmenNotwendig && setValue('folgemassnahmenNotwendig', folgemassnahmenNotwendig)
    }

    React.useEffect(() => {
        const graphqlToTime = (date: any) => date ? format(new Date(date), 'HH:mm') : '';
        const resetValues = {
            ...gqlToForm(report),
            ...(report?.einsatzberichtSachbearbeiter
                && {
                sachbearbeiterIds: report?.einsatzberichtSachbearbeiter.map((sachbearbeiter: any) => ({ value: sachbearbeiter?.mitarbeiterId }))
            }),
            anfahrt: [report?.anfahrtFrom, report?.anfahrtUntil].map(graphqlToTime),
            einsatz: [report?.einsatzFrom, report?.einsatzUntil].map(graphqlToTime),
            abfahrt: [report?.abfahrtFrom, report?.abfahrtUntil].map(graphqlToTime),
            // agHandwerker: [],
            ...gqlToReportOptions(details as EinsatzberichtDetail[]),
            ...resolvedResetValues,
        };
        log.debug('OperationalReportForm.resetValues', {
            legacy: { ...gqlToReportOptions(details as EinsatzberichtDetail[]) },
            resetValues,
        });
        reset(resetValues);
        setFolgemassnahmenNotwendig(resetValues);
    }, [editId, report.id, details.length, isReadLoading]);

    const projectTypeId = subproject?.projekttyp?.id;
    const isLeakDetection = subproject?.projekttyp?.name === 'Leckortung';
    const isDrying = subproject?.projekttyp?.name === 'Trocknung';
    // TODO: move all hardcoded values to constants file => jens: don't know how to proceed?
    // const isLeakDetection = true;
    // const isDrying = true;
    log.debug('projectType', { editId, projectTypeId, isLeakDetection, isDrying, subproject });

    function toGqlUpsert(data: any) {
        let isTechnicalDrying = data?.find?.(
            (item: any) => item?.bezeichnung === 'technische_trocknung'
        )?.jaNein;
        isTechnicalDrying = true; // revert FollowUp dependent mess but maybe u should keep it!
        const strainer =
            data?.filter(({ anzahl, jaNein, text }: any) =>
                anzahl && typeof anzahl === 'number'
                    ? true
                    : typeof jaNein === 'boolean'
                        ? true
                        : text && typeof text === 'string'
                            ? true
                            : false
            ) || [];

        const exclude = isTechnicalDrying
            ? []
            : data
                ?.filter(({ bezeichnung }: any) =>
                    [
                        'ag_handwerker',
                        'klaerung_handwerker',
                        'weitere_arbeiten',
                        'klaerung_arbeiten',
                    ].includes(bezeichnung)
                )
                .map(({ bezeichnung, einsatzberichtOptionId }: any) => ({
                    einsatzberichtOptionId,
                    jaNein: false,
                    ...(['ag_handwerker', 'weitere_arbeiten'].includes(bezeichnung) && {
                        text: '',
                    }),
                }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const strained = strainer.map(({ bezeichnung, einsatzberichtId, ...props }: any) => props);
        console.log(`onSubmit.toGqlUpsert`, { data, isTechnicalDrying, exclude, strained });
        return [...strained, ...exclude];
    }

    const setFormDocuments = (operationalReportId: any, { fotodokumentation = [] }: any) => {
        const formDocuments = fotodokumentation?.map((photo: any) => ({
            typSnippetId: photo?.fileType?.value,
            name: photo?.attachment?.fileName || ' ',
            pfad: photo?.attachment?.key || ' ',
            beschreibung: photo?.description || ' ',
            einsatzberichtId: operationalReportId,
            subprojektId: subprojectId,
        }));
        send({ type: 'SET_FORM_DOCUMENTS', formDocuments });
    };

    const upsertDocuments = (operationalReportId: any) => {
        send({
            type: 'LOADING',
            variables: {
                filter: {
                    einsatzberichtId: operationalReportId,
                },
            },
        });
    };

    let isSubmitted = false;
    const onSubmit = async ({ sachbearbeiterIds = [], anfahrt = [], einsatz = [], abfahrt = [], folgemassnahmenNotwendig, ...values }: any) => {
        const times = {
            anfahrtFrom: anfahrt?.[0],
            anfahrtUntil: anfahrt?.[1],
            einsatzFrom: einsatz?.[0],
            einsatzUntil: einsatz?.[1],
            abfahrtFrom: abfahrt?.[0],
            abfahrtUntil: abfahrt?.[1],
        }

        console.log('onSubmit.zeiten', {
            ...(Object.entries(times).reduce((acc, [key, value]) => value ? ({ ...acc, [key]: new Date(`${values?.datum}T${value}`) }) : acc, {}))
        })
        console.log('onSubmit.sachbearbeiterIds', sachbearbeiterIds)
        console.log('onSubmit.isSubmitted', isSubmitted)
        log.debug('onSubmit.values', values);
        const data: any = formToGql(omit(values, 'anfahrt', 'einsatz', 'abfahrt'));
        log.debug('onSubmit.data', data);
        const { datum, kontaktId = null, mitarbeiterIdSachbearbeiter, ...detailsData } = data;
        const defaultValues = {};
        log.debug('onSubmit.defaultValues', defaultValues);
        const fixedValues = { reportId: report?.id };
        log.debug('onSubmit.fixedValues', fixedValues);
        const einsatzbericht = {
            subprojektId: subprojectId,
            einsatzberichtTypId: projectTypeId,
            ...(kontaktId && { kontaktId }),
            ...(report?.id & report?.kontaktId && kontaktId === null && { kontaktId: 0 }),
            mitarbeiterIdSachbearbeiter,
            ...(Object.entries(times).reduce((acc, [key, value]) => value ? ({ ...acc, [key]: new Date(`${values?.datum}T${value}`) }) : acc, {})),
            ...(employeeId && !report?.id && { mitarbeiterIdUrheber: employeeId }),
            datum,
        };

        const details = reportOptionsToGql(detailsData, defaultValues, fixedValues);
        console.log('FollowUps.onSubmit.values', values, data, details)
        const einsatzberichtDetails = toGqlUpsert(details);
        log.debug('onSubmit.upsertEinsatzbericht', {
            details,
            einsatzbericht,
            sachbearbeiterIds: sachbearbeiterIds?.map((item: any) => item?.value),
            einsatzberichtDetails,
            ...(report?.id && { id: report?.id }),
        });
        if (!isSubmitted) {
            isSubmitted = true;
            console.log('onSubmit.variables', {
                variables: {
                    einsatzbericht,
                    einsatzberichtDetails,
                    sachbearbeiterIds: sachbearbeiterIds?.map((item: any) => item?.value),
                    ...(report?.id && { id: report?.id }),
                }
            })
            const response: any = await upsertMutation({
                variables: {
                    einsatzbericht,
                    einsatzberichtDetails,
                    sachbearbeiterIds: sachbearbeiterIds?.map((item: any) => item?.value),
                    ...(report?.id && { id: report?.id }),
                },
            });
            const operationalReportId = response?.data?.app?.upsertEinsatzbericht?.einsatzbericht?.id;
            log.debug('onSubmit.response', JSON.stringify(response, null, 2));
            if (operationalReportId) {
                setFormDocuments(operationalReportId, values);
                upsertDocuments(operationalReportId);
            }
            updateEditable();

        }
    };

    const isCollapsible = true;
    // @ts-ignore
    const { technischeTrocknung, folgemassnahmenNotwendig = false, ...watched } = watch();
    const isReadOnly = !(canCreate || canEdit)

    return (
        <>
            <OverrideActions>
                <HStack>
                    <Button data-test-id={`cancel`} size="sm" onClick={() => updateEditable()}>
                        {(readOnly || canView) && !(canEdit || canCreate) ? 'Schließen' : 'Abbrechen'}
                    </Button>
                    <HasPermission resource="project.report" permission={report?.id ? 'update' : 'create'}>
                        <Button
                            data-test-id={`save`}
                            colorScheme="blue"
                            size="sm"
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isReadLoading || loading}
                            isDisabled={isReadLoading || loading || isSubmitted}
                        >
                            Speichern
                        </Button>
                    </HasPermission>

                </HStack>
            </OverrideActions>
            <Container maxW="container.lg" p={3} cursor={isReadOnly ? 'not-allowed' : 'auto'} >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <VStack alignItems="center" spacing={14} pointerEvents={isReadOnly ? 'none' : 'revert'}>
                        <SectionCard title="Berichtsstammdaten*" isCollapsible={isCollapsible}>
                            <SimpleGrid spacing={4} columns={6}>
                                {formFields.reportMasterData.fields.map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            renderers,
                                        })}
                                    </React.Fragment>
                                ))}
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard title="Schadenursache*" isCollapsible={isCollapsible}>
                            <SimpleGrid spacing={4} columns={6}>
                                {formFields.causeOfDamage.fields.map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            renderers,
                                        })}
                                    </React.Fragment>
                                ))}
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard title="Arbeitsbericht*" isCollapsible={isCollapsible}>
                            <SimpleGrid spacing={4} columns={2}>
                                {formFields.workReport.fields.map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            renderers,
                                        })}
                                    </React.Fragment>
                                ))}
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard title="Durchgeführte Maßnahmen*" isCollapsible={isCollapsible}>
                            <SimpleGrid spacing={4} columns={6}>
                                {formFields.measuresTaken.fields.map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            renderers,
                                        })}
                                    </React.Fragment>
                                ))}
                            </SimpleGrid>
                        </SectionCard>
                        {isLeakDetection && (
                            <SectionCard
                                title="Hinweise auf Fremdverschulden*"
                                isCollapsible={isCollapsible}
                            >
                                <SimpleGrid spacing={4} columns={4}>
                                    {formFields.evidence.fields.map((field: any) => (
                                        <React.Fragment key={field.name}>
                                            {renderField({
                                                field,
                                                control,
                                                register,
                                                errors,
                                                renderers,
                                            })}
                                        </React.Fragment>
                                    ))}
                                </SimpleGrid>
                            </SectionCard>
                        )}
                        <SectionCard title="Kundeninformation*" isCollapsible={isCollapsible}>
                            <SimpleGrid spacing={4} columns={6}>
                                {formFields.customerInformation.fields.map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            renderers,
                                        })}
                                    </React.Fragment>
                                ))}
                            </SimpleGrid>
                        </SectionCard>
                        {isLeakDetection && (
                            <SectionCard title="Folgemaßnahmen*" isCollapsible={isCollapsible}>
                                <SimpleGrid spacing={4} columns={6}>
                                    {formFields.followUpActions.fields.map((field: any) => (
                                        <React.Fragment key={field.name}>
                                            {renderField({
                                                field,
                                                control,
                                                register,
                                                errors,
                                                renderers,
                                                context: {
                                                    isActive: folgemassnahmenNotwendig,
                                                    ...watched,
                                                },
                                            })}
                                        </React.Fragment>
                                    ))}
                                </SimpleGrid>
                            </SectionCard>
                        )}
                        {isLeakDetection && (
                            <SectionCard
                                title="Fotodokumentation des Schadens*"
                                isCollapsible={isCollapsible}
                            >
                                <SimpleGrid spacing={4} columns={2}>
                                    {formFields.photoDocumentation.fields.map((field: any) => (
                                        <React.Fragment key={field.name}>
                                            {renderField({
                                                field,
                                                control,
                                                register,
                                                errors,
                                                renderers,
                                            })}
                                        </React.Fragment>
                                    ))}
                                </SimpleGrid>
                            </SectionCard>
                        )}
                        <SectionCard title="Checkliste*" isCollapsible={isCollapsible}>
                            <SimpleGrid spacing={4} columns={6}>
                                {formFields.checklist.fields.map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            renderers,
                                        })}
                                    </React.Fragment>
                                ))}
                            </SimpleGrid>
                        </SectionCard>
                        {isDrying && (
                            <SectionCard
                                title="Zählerstände*"
                                isCollapsible={isCollapsible}
                                body={false}
                            >
                                <SimpleGrid spacing={4} columns={2}>
                                    {formFields.meterReadings.fields.map((field: any) => (
                                        <React.Fragment key={field.name}>
                                            {renderField({
                                                field,
                                                control,
                                                register,
                                                errors,
                                                renderers,
                                            })}
                                        </React.Fragment>
                                    ))}
                                </SimpleGrid>
                            </SectionCard>
                        )}
                    </VStack>
                </Form>
            </Container>
        </>

    );
};
