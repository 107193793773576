import { DocumentNode } from 'graphql';

export const useContextAndRefetchQueries = (
    query?: DocumentNode,
    variables?: any,
    moreRefetchQueries: any[] = []
): any => {
    const context = { clientName: 'ucpw' };
    const refetchQueries = [
        { query, context, ...(variables && { variables }), notifyOnNetworkStatusChange: true },
        ...moreRefetchQueries?.filter(Boolean),
    ];
    return { refetchQueries, context };
};
