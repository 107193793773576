import React from 'react'
import { useController } from 'react-hook-form';
import { Input, SimpleGrid } from '@chakra-ui/react';
import { DatePicker } from './DatePicker';
import { de } from 'date-fns/locale';
import { isDate, format } from 'date-fns'

export const DateTimeLocal = ({ control, children, type, isDisabled = false, ...props }: any) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });
    const [{ date, time }, setState] = React.useState<{ date: string, time: string }>({ date: '', time: '' })

    React.useEffect(() => {
        if (value && isDate(new Date(value))) {
            const d = new Date(value)
            setState({
                date: format(d, 'yyyy-MM-dd', { locale: de }),
                time: format(d, 'HH:mm', { locale: de })
            })
        }
    }, [value])

    React.useEffect(() => {
        if (date) {
            inputProps.onChange(new Date(`${date}T${time}`))
        }
    }, [date, time])

    return (
        <SimpleGrid columns={2} spacing={4}>
            <DatePicker
                type="date"
                data-test-id={`field-${props?.name}-date`}
                isDisabled={isDisabled}
                value={date}
                onChange={(e) => {
                    const date = e?.target?.value
                    setState((prev) => ({ ...prev, date, ...(!prev?.time && { time: '08:00' }) }))
                }}
            />
            <Input
                type="time"
                data-test-id={`field-${props?.name}-time`}
                isDisabled={isDisabled}
                value={time}
                onChange={(e) => {
                    const time = e?.target?.value
                    const isValid = time.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
                    if (isValid) {
                        setState((prev) => ({ ...prev, time }))
                    }
                }}
            />
        </SimpleGrid>
    );
};
