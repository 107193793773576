import { HStack, Icon, Spinner, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { SiReact } from 'react-icons/si';

interface NavItemProps {
    to?: string;
    label: string;
    children?: React.ReactNode;
    groupless?: boolean;
    testId?: string;
    icon?: any;
    isLoading?: boolean;
}

export const NavItem = ({ children, label, to = '/', icon, isLoading = false, ...props }: NavItemProps) => {
    const groupless = 'groupless' in props;
    return (
        <HStack
            as={NavLink}
            to={to}
            px={3}
            py={2}
            cursor="pointer"
            rounded="md"
            align="center"
            color="gray.700"
            transition="all 0.2s"
            _hover={{ bg: 'ucpw-brand', color: 'white' }}
            _activeLink={{
                color: 'ucpw-brand',
                '&:hover': { bg: 'ucpw-brand', color: 'white' }
            }}
        >
            {!groupless && <Icon as={icon ? icon : SiReact} boxSize="4" />}
            <Text fontSize="sm" mb={groupless ? 0 : 2} fontWeight={500}>
                {label}
            </Text>
            {isLoading && <Spinner size="sm" />}
        </HStack>
    );
};
