import { Fragment } from 'react';
import { Box, Flex, Stack, Divider } from '@chakra-ui/react';
import { NavGroup } from '~/components/Layout/NavGroup';
import { NavItem } from '~/components/Layout/NavItem';
import { Logo } from './Logo';
import { UserProfile } from './UserProfile';
import { useNavigation } from '~/hooks/useNavigation';
import { Projecthistory } from '~/pages/projects/components/ProjectHistory';

export const Sidebar = () => {
    const navigation = useNavigation();

    return (
        <Flex
            h="full"
            bg="bg-accent"
            color="on-accent"
            overflowY="auto"
            borderRightWidth={1}
            borderColor="bg-accent-subtle"
        >
            <Stack justify="space-between" spacing="1">
                <Stack
                    data-test-id="sidebar-navigation"
                    spacing={{ base: '5', sm: '6' }}
                    shouldWrapChildren
                    p={4}
                >
                    <Logo />
                    <Stack spacing={7}>
                        {navigation.map((item) => {
                            return item.children ? (
                                <NavGroup key={item.label} label={item.label}>
                                    {item.children.map((child) => (
                                        <NavItem
                                            key={child.label}
                                            label={child.label}
                                            to={child.to}
                                        />
                                    ))}
                                </NavGroup>
                            ) : (
                                <Fragment key={item.label}>
                                    <NavItem label={item.label} to={item.to} groupless />
                                    <Projecthistory />
                                </Fragment>
                            );
                        })}
                    </Stack>
                </Stack>
                <Box>
                    <Divider borderColor="bg-accent-subtle" />
                    <Stack spacing={{ base: '5', sm: '6' }} p={4}>
                        <UserProfile />
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
};
