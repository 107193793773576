import { selects } from '~/meta/data/selects.gql';
import { resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = {
  filters: [],
  state: {
    reducer: (state:any, action:any) => {
      switch (action.type) {
        case 'generalAgreements.loaded': {
          return {
            ...state,
            positions: { ...state.positions, variables: { ...state.positions.variables, filter: { rahmenvertragId: action.items?.[0]?.id } } },
          };
        }
        case 'generalAgreements.select': {
          const rahmenvertragId = action?.row?.original?.id;
          return {
            ...state,
            positions: {
              variables: { ...state.positions.variables, filter: { rahmenvertragId } },
              formState: { ...state.positions.formState, agreementId: rahmenvertragId },
            },
          };
        }
        default: {
          return state;
        }
      }
    },
    defaultState: {
      generalAgreements: {
        variables: { orderBy: [{ isStandard: 'desc' }, { bezeichnung: 'asc' }] },
      },
      positions: { variables: { filter: { rahmenvertragId: undefined } } },
    },
  },
  tables: [
    {
      id: 'generalAgreements',
      title: 'Rahmenvereinbarungen',
      colSpan: 1,
      stateReducer: (row:any) => ({ rahmenvertragId: row.original.id }),
      actions: (dispatch: Function) => ({ select: (row: any) => dispatch({ type: 'generalAgreements.select', row }) }),
      snippets: { create: 'Neue Vereinbarung' },
      buttonProps: { mq: 1650 },
    },
    { id: 'positions', title: 'RV-Positionen', colSpan: 2, snippets: { create: 'RV-Position anlegen' } },
  ],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
  agreements: {
    __graphqlType: 'Rahmenvertrag',
    id: { header: 'ID', accessor: 'id' },
    isStandard: { header: 'Standard', accessor: 'isStandard' },
    description: { header: 'Bezeichnung', accessor: 'bezeichnung', renderer: { name: 'HighlightItem' } },
    validFrom: { header: 'Gültig', accessor: 'gueltigAb', disableSortBy: true, renderer: { name: 'DateFormat' } },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
  positions: {
    __graphqlType: 'Rahmenvertragsposition',
    id: { header: 'ID', accessor: 'id' },
    number: { header: 'Nr', accessor: 'nr', width: 40 },
    description: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'Desc' } },
    price: { header: 'Preis/Einheit', accessor: 'preisProEinheit', renderer: { name: 'PriceFormat' } },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  agreements: {
    columns: ['id', 'isStandard', 'description', 'validFrom', 'controls'].map(tableColumnMapper(columns.agreements)),
    hiddenColumns: ['id', 'isStandard'],
  },
  positions: {
    columns: ['id', 'number', 'description', 'price', 'controls'].map(tableColumnMapper(columns.positions)),
    hiddenColumns: ['id', 'rahmenvertragId'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  agreements: {
    __graphqlInput: 'RahmenvertragInput',
    description: { api: { path: 'bezeichnung', required: true } },
    validFrom: { api: { path: 'gueltigAb' } },
    validUntil: { api: { path: 'gueltigBis' } },
    isStandard: { api: { path: 'isStandard', required: true }, defaultValue: false },
  },
  positions: {
    __graphqlInput: 'RahmenvertragspositionInput',
    agreementId: { api: { path: 'rahmenvertragId', required: true } },
    serviceId: { api: { path: 'leistungId', required: true } },
    number: { api: { path: 'nr', required: true } },
    description: { api: { path: 'beschreibung', required: true } },
    pricePerUnit: { api: { path: 'preisProEinheit', required: true } },
    details: { api: { path: 'details', required: true }, defaultValue: '' },
  },
};

export const forms = {
  agreements: [
    { path: 'description', ui: { label: 'Bezeichnug', colSpan: 2 } },
    { path: 'validFrom', ui: { label: 'Gültig ab', component: 'DatePicker' } },
    { path: 'validUntil', ui: { label: 'Gültig bis', component: 'DatePicker' } },
    { path: 'isStandard', ui: { label: 'Standard', component: 'Checkbox' } },
  ],
  positions: [
    { path: 'number', ui: { label: 'Nummer' } },
    { path: 'description', ui: { label: 'Beschreibung', component: 'Textarea', colSpan: 2 } },
    { path: 'pricePerUnit', ui: { label: 'Preis/Einheit', type: 'number', props: {step: '0.01'} } },
    { path: 'serviceId', ui: { label: 'Leistung/Einheit', component: 'QuerySelect', props: { query: selects.units, placeholder: 'Einheit',  mapOptions: (item:any) => ({ value: item?.value, label: `${item?.label}${item?.einheit ? ` [${item?.einheit}]`:''}` }) } } },
    { path: 'agreementId', ui: { label: 'Rahmenvertrag Id' }, visibility: { hide: () => true } },
    { path: 'details', ui: { label: 'Details' }, visibility: { hide: () => true } },
  ],
};

// ====================================
// (General) agreements
// ====================================

export const generalAgreements = {
  columns: tables.agreements.columns,
  tableProps: { pageSize: 15, hiddenColumns: tables.agreements.hiddenColumns },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.agreements, fields: fields.agreements },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.agreements, fields.agreements, context),

      snippets: {
        create: 'Neuer Rahmenvertrag',
        update: 'Rahmenvertrag bearbeiten',
      },
    };
  },
};

// ====================================
// (General Agreements) positions
// ====================================

export const positions = {
  columns: tables.positions.columns,
  tableProps: { pageSize: 15, hiddenColumns: tables.positions.hiddenColumns },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.positions, fields: fields.positions },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.positions, fields.positions, context),

      snippets: {
        create: 'Neue Rahmenvertragposition',
        update: 'Rahmenvertragposition bearbeiten',
      },
    };
  },
};
