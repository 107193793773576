import React from 'react';
import { RiAddLine } from 'react-icons/ri';
import { AiTwotoneEdit } from 'react-icons/ai';
import { useController, useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Textarea,
    VStack,
} from '@chakra-ui/react';
import { Card, CardContent } from '@ucc/react/ui';
import { Form, renderField } from '~/components/Form/Form';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { ModalProvider } from '~/contexts/ModalContext';
import { useModal } from '~/hooks/useModal';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { resolveFormFields } from '~/utils';
import log from '~/log';
import { useAsset } from '~/hooks/useAsset';

const Modals = { PhotoDocumentation: 'PhotoDocumentation' };

const initialState = {
    modals: { activeModal: Modals.PhotoDocumentation },
    photos: [],
    remote: true,
};

const photosReducer = (state: any = {}, action: any = {}) => {
    const { type, data = {} } = action;

    switch (type) {
        case 'photos': {
            const newState = { ...state, photos: data, remote: true };
            return newState;
        }
        case 'photo.create': {
            log.trace('photo.create', data);
            const { id, values } = data;
            const photos = [...state.photos, { id, ...values }];
            const newState = { ...state, photos, remote: false };
            return newState;
        }
        case 'photo.update': {
            log.debug('photo.update', data);
            const { id, values } = data;
            const photos = state.photos.map((photo: any) =>
                photo.id !== id ? photo : { id, ...values }
            );
            const newState = { ...state, photos, remote: false };
            log.debug('photo.update', newState);
            return newState;
        }
        case 'photo.delete': {
            const id = data.id;
            log.trace('photo.delete', id);
            const photos = state.photos?.filter((photo: any) => photo.id !== id);
            const newState = { ...state, photos, remote: false };
            return newState;
        }
    }
};

const reducer = combineReducers({
    modals: modalReducer(Modals.PhotoDocumentation),
    row: rowReducer,
    photos: photosReducer,
});

export const PhotoDocumentation = (props: any) => {
    return (
        <ModalProvider reducer={reducer} defaultState={initialState}>
            <PhotoDocumentationCard {...props} />
            <PhotoDocumentationModal {...props} />
        </ModalProvider>
    );
};

const PhotoDocumentationCard = ({ control, ...props }: any) => {
    const { onOpen, dispatch, state } = useModal();
    const photos = state?.photos || [];
    const remote = state?.remote;

    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });
    console.log('PhotoDocumentationCard', { photos, remote, value });

    React.useEffect(() => {
        Boolean(value?.length) &&
            dispatch?.({
                type: 'photos',
                data: value,
            });
    }, [value]);

    React.useEffect(() => {
        if (!remote) {
            inputProps.onChange?.(photos);
        }
    }, [photos, remote]);

    return (
        <Card boxShadow="none">
            <CardContent>
                <VStack spacing={6} align="start">
                    {photos?.map?.((photo: any, idx: number) => (
                        <PhotoDocument
                            key={photo?.id || photo?.attachment?.key || idx}
                            photo={photo}
                        />
                    ))}
                    <VStack w="full">
                        <IconButton
                            colorScheme="blue"
                            size="lg"
                            color="white"
                            aria-label="add-photo"
                            onClick={onOpen}
                            icon={<RiAddLine />}
                        />
                    </VStack>
                </VStack>
            </CardContent>
        </Card>
    );
};

const PhotoDocument = ({ photo }: any) => {
    const [rerender, setRerender] = React.useState<boolean>();
    const { onOpen, dispatch } = useModal();
    const { download } = useAsset();
    const [imgSrc, setImgSrc] = React.useState<string>();
    const attachment = photo?.attachment || {};

    React.useEffect(() => {
        let mounted = true;
        if (mounted && photo?.attachment?.key) {
            (async function getImageUrl() {
                const url = await download(attachment?.key);
                const isBrokenUrl = url === 'https://s3.eu-central-1.amazonaws.com/';
                console.log('PhotoDocumentationCard', { url, key: attachment?.key });
                if (!isBrokenUrl && url) {
                    setImgSrc(url);
                } else if (isBrokenUrl) {
                    // FIXME: quite beautiful code smell
                    setRerender(isBrokenUrl);
                    location.reload();
                }
            })();
        }

        return () => {
            mounted = false;
        };
    }, [photo?.attachment?.key, rerender]);

    return (
        <SimpleGrid
            w="full"
            columns={2}
            spacing={6}
            key={[attachment?.key, imgSrc]?.filter(Boolean).join('-')}
        >
            <VStack align="flex-start" justify="space-between" spacing={6}>
                <VStack w="full" spacing={6}>
                    <FormControl>
                        <FormLabel>Titel</FormLabel>
                        <Input value={attachment?.fileName || ''} disabled />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Beschreibung</FormLabel>
                        <Textarea value={photo.description} disabled />
                    </FormControl>
                </VStack>
                <ButtonGroup isAttached variant="outline">
                    <Button
                        mr="-px"
                        onClick={() => dispatch?.({ type: 'photo.delete', data: { id: photo.id } })}
                    >
                        Löschen
                    </Button>
                    <IconButton
                        onClick={() => {
                            dispatch?.({
                                type: 'setRow',
                                data: { row: { original: photo }, rowId: photo.id },
                            });
                            onOpen?.();
                        }}
                        aria-label="Fotodokument bearbeiten"
                        icon={<AiTwotoneEdit />}
                    />
                </ButtonGroup>
            </VStack>
            <Image src={imgSrc} h="auto" w="100%" objectFit="cover" display="block" />
        </SimpleGrid>
    );
};

const PhotoDocumentationModal = () => {
    const { isOpen, onClose, dispatch } = useModal();

    const onOverlayClick = () => {
        dispatch?.({ type: 'resetState' });
    };

    return (
        <Modal isOpen={isOpen!} onClose={onClose!} onOverlayClick={onOverlayClick}>
            <ModalOverlay />
            <PhotoDocumentationModalContent />
        </Modal>
    );
};

const form = [
    { path: 'attachment', ui: { label: 'Anhang', colSpan: 2, component: 'S3Upload', props: { placeholder: 'Unterstützte Bildformate: PNG, JPEG/JPG', imageOnly: true } } },
    {
        path: 'fileType',
        ui: {
            label: 'Dateityp',
            colSpan: 2,
            component: 'SnippetSelect',
            props: { category: 'Dokument', property: 'Typ' },
        },
    },
    { path: 'description', ui: { label: 'Beschreibung', colSpan: 2, component: 'Textarea' } },
];
const fields = form.reduce(
    (acc, field: any) => ({
        ...acc,
        [field?.path]: { api: { path: field?.path, required: field?.required } },
    }),
    {}
);

const PhotoDocumentationModalContent = () => {
    const { onClose, dispatch, state } = useModal();
    const { row: photo, rowId: photoId } = state.rows || {};
    const formFields = resolveFormFields(form, fields);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm({ ...photo, fileType: photo?.fileType?.value }),
    };

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    const onSubmit: SubmitHandler<any> = async (values) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        photoId
            ? dispatch?.({ type: 'photo.update', data: { id: photoId, values } })
            : dispatch?.({ type: 'photo.create', data: { id: Date.now(), values } });
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {photoId ? `Dokument bearbeiten` : `Neues Dokument`}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        data-test-id="button-save"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({ field, control, register, errors })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};
