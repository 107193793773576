import { resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = {
    tables: [
        { id: 'insurances', colSpan: 3, snippets: { create: 'Neue Versicherung hinterlegen' } },
    ],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
    insurances: {
        __graphqlType: 'Versicherung',
        id: { header: 'ID', accessor: 'id' },
        description: { header: 'Bezeichnung', accessor: 'bezeichnung', disableSortBy: false },
        controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
    },
};

export const tables = {
    insurances: {
        columns: ['id', 'description', 'controls'].map(tableColumnMapper(columns.insurances)),
        hiddenColumns: ['id'],
    },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
    insurances: {
        __graphqlInput: 'VersicherungInput',
        description: { api: { path: 'bezeichnung', required: true } },
    },
};

export const forms = {
    insurances: [{ path: 'description', ui: { label: 'Bezeichnung' } }],
};

export const insurances = {
    columns: tables.insurances.columns,
    tableProps: { pageSize: 10 },

    // needed by ~/plugins/projects/meta/utils
    base: { form: forms.insurances, fields: fields.insurances },

    getForm: (context = {}) => {
        return {
            columns: 2,

            // fields, defaultValues(), validationSchema
            ...resolveFormFields(forms.insurances, fields.insurances, context),

            snippets: {
                create: 'Neue Versicherung',
                update: 'Versicherung bearbeiten',
                displayName: (item: any) => item?.bezeichnung,
            },
        };
    },
};
