import React from 'react';
import { format } from 'date-fns';
import { addMonths, subMonths, addYears, subYears } from 'date-fns/esm';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import locale from 'date-fns/locale/de';
import { useNavigate } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { SiTarget } from 'react-icons/si';
import { CalendarNav } from '@mobiscroll/react';
import {
    Box,
    HStack,
    Button,
    VStack,
    Text,
    IconButton,
    chakra,
    Progress,
    Checkbox,
    useMediaQuery,
    Badge,
    keyframes
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useScroll } from '~/hooks/useScroll';
import { useModal } from '~/hooks/useModal';
import { generateDays } from '~/pages/staffing/meta/utils';
import { Modals } from '../EventModal';
import { ProjectIds } from '~/pages/projects/types';
import { useEventProjectdetails } from './useEventProjectDetails';
import { HasPermission } from '~/layout/HasPermission';

function getFormat(date: Date) {
    return format(date, 'yyyy-MM-dd');
}

export function useRenderHeader({
    scheduleType = 'day',
    selectedDate,
    setSelectedDate,
    loading,
    setEmergencyService,
    emergencyService,
    subproject,
    emergencyEvents
}: {
    scheduleType?: string;
    selectedDate?: Date;
    setSelectedDate?: (args: any) => void;
    loading?: boolean;
    projectIds?: ProjectIds;
    subproject?: any;
    setEmergencyService?: any;
    emergencyService?: boolean;
    emergencyEvents?: any[]
} = {}) {
    const navigate = useNavigate();
    const eventProjectDetails = useEventProjectdetails(subproject)
    const currentDate = selectedDate ? new Date(selectedDate) : new Date();
    const props = {
        week: {
            selectedDate: currentDate,
            onForward: () => setSelectedDate?.(subYears(currentDate, 1)),
            onNext: () => setSelectedDate?.(addYears(currentDate, 1)),
            onToday: () => setSelectedDate?.(new Date()),
            onChange: setSelectedDate,
            loading,
            eventProjectDetails,
            setEmergencyService,
            emergencyService,
            emergencyEvents
        },
        day: {
            selectedDate: currentDate,
            onForward: () => {
                setSelectedDate?.(subMonths(currentDate, 1));
                navigate(`/projekte/termine/${getFormat(subMonths(currentDate, 1))}`);
            },
            onNext: () => {
                setSelectedDate?.(addMonths(currentDate, 1));
                navigate(`/projekte/termine/${getFormat(addMonths(currentDate, 1))}`);
            },
            onToday: () => {
                setSelectedDate?.(new Date());
                navigate(`/projekte/termine/${getFormat(new Date())}`);
            },
            onChange: setSelectedDate,
            loading,
            eventProjectDetails,
            setEmergencyService,
            emergencyService,
            emergencyEvents
        },
    };

    return React.useCallback(
        () => (
            <>
                {scheduleType === 'week' && <WeekNav {...props.week} />}
                {scheduleType === 'day' && <DayNav {...props.day} />}
            </>
        ),
        [
            scheduleType,
            currentDate,
            selectedDate,
            eventProjectDetails,
            eventProjectDetails,
            props
        ]
    );
}

export const useFlexWrapDetection = (ref: any) => {
    React.useEffect(() => {
        const assignRows = (cards: any) => {
            let row = 0;
            let odd = true;
            // console.dir(cards.children);
            [...cards.children].forEach((el) => {
                // remove classes
                el.className = 'card';
                // if element is more to the left that it's previous element it means it wrapped to a new line
                if (
                    !el.previousElementSibling ||
                    el.offsetLeft < el.previousElementSibling.offsetLeft
                ) {
                    row++;
                    odd = !odd;
                    console.log(!el.previousElementSibling, el);
                }
                // adds a class of row1, row2, row3
                el.classList.add(`row${row}`, `${odd ? 'wrapped' : 'not-wrapped'}`);
            });
        };

        const observer = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                entries.forEach((entry) => {
                    assignRows(entry.target);
                });
            })
        });

        observer.observe(ref.current);

        return () => observer.unobserve(ref.current);
    }, []);

    return {};
};

export function Nav({
    selectedDate,
    label,
    children,
    onForward,
    onNext,
    onToday,
    loading,
    eventProjectDetails,
    setEmergencyService,
    emergencyService,
    emergencyEvents = []
}: {
    selectedDate: Date;
    label?: string;
    children?: any;
    onForward?: (args: any) => void;
    onNext?: (args: any) => void;
    onToday?: (args: any) => void;
    loading?: boolean;
    eventProjectDetails?: any;
    setEmergencyService?: any;
    emergencyService?: boolean;
    emergencyEvents?: any[];
}) {
    const [isLargerThan1450] = useMediaQuery('(min-width: 1450px)');
    const [isLargerThan1719] = useMediaQuery('(min-width: 1719px)');
    const breakpoint = emergencyService ? isLargerThan1719 : isLargerThan1450;
    const { dispatch, onOpen } = useModal();

    const nav = (
        <HStack {...(!breakpoint && { w: 'full' })}>
            <IconButton
                aria-label="back"
                icon={<FiChevronLeft />}
                onClick={onForward}
                variant="ghost"
                size="sm"
                mr={3}
            />
            <HStack
                overflowX="auto"
                css={{
                    '&::-webkit-scrollbar': {
                        width: 2,
                        height: 4,
                        padding: 5,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(90,90,90,0.5)',
                        borderRadius: 4,
                    },
                }}
                p={0}
                justify={breakpoint ? 'center' : 'space-between'}
                w="full"
            >
                {children}
            </HStack>
            <IconButton
                aria-label="forward"
                icon={<FiChevronRight />}
                onClick={onNext}
                variant="ghost"
                size="sm"
                ml={3}
            />
        </HStack>
    );

    const animation = keyframes`
        0%, 100% {
            transform: translateY(-25%);
            animation-timing-function: cubic-bezier(.8, 0, 1, 1);
        }

        50% {
            transform: none;
            animation-timing-function: cubic-bezier(0, 0, .2, 1);
        }
    `

    return (
        <Box w="full">
            <HStack w="full" justifyContent="space-between" mb={6} flexWrap="wrap">
                <CalendarNavigation selectedDate={selectedDate} {...(label && { label })} />
                {breakpoint && nav}
                <HStack spacing={6}>
                    <Checkbox isChecked={emergencyService} onChange={setEmergencyService}>
                        Notdienst {emergencyEvents?.length > 0 && <Badge {...{ ...(!emergencyService && { animation: `${animation} 1s infinite` }) }} fontWeight="bold" colorScheme="orange" variant="outline" size="md" transform="translate(-1px, -7px)">{emergencyEvents?.length}</Badge>}
                    </Checkbox>
                    <HasPermission resource="project.schedule" permission="create">
                        <Button
                            size="sm"
                            leftIcon={<AddIcon />}
                            colorScheme="blue"
                            onClick={() => {
                                dispatch?.({
                                    type: 'setModal',
                                    data: { modal: Modals.CreateEvent },
                                });
                                dispatch?.({
                                    type: 'formState',
                                    data: {
                                        title: 'Neuer Termin',
                                        duration: 30,
                                        time: '08:00',
                                        planed: format(new Date(selectedDate), 'yyyy-MM-dd'),
                                        ...eventProjectDetails,
                                    },
                                });
                                onOpen?.();
                            }}
                        >
                            Neuer Termin
                        </Button>
                    </HasPermission>
                    <IconButton
                        display="none"
                        onClick={onToday}
                        isDisabled={
                            format(new Date(selectedDate), 'yyyy-MM-dd') ===
                            format(new Date(), 'yyyy-MM-dd')
                        }
                        color="gray.200"
                        _hover={{ color: 'blue.500' }}
                        aria-label="next"
                        icon={<SiTarget />}
                        variant="ghost"
                        px={1}
                    />
                </HStack>
            </HStack>
            {!breakpoint && <Box mb={6}>{nav}</Box>}
            <Box h={1}>{loading && <Progress size="xs" colorScheme="cyan" isIndeterminate w="full" />}</Box>
        </Box>
    );
}

export function WeekNav({ selectedDate, onChange, ...props }: any) {
    const [weeks, setWeeks] = React.useState<any>([]);
    const [currentKW, setCurrentKW] = React.useState<any>({});
    const rangeValue = 7;

    React.useLayoutEffect(() => {
        const year = selectedDate.getFullYear();
        const now = new Date();
        const mondays = eachWeekOfInterval(
            {
                start: new Date(selectedDate.getFullYear(), 0, 1),
                end: new Date(selectedDate.getFullYear(), 11, 31),
            },
            { weekStartsOn: 1, locale }
        )
            .filter((mo) => mo.getFullYear() === year)
            .map((monday, idx) => {
                const year = monday.getFullYear();
                const month = monday.getMonth();
                const date = monday.getDate();
                const kwInt = idx + 1;
                const kw = `${kwInt < 10 ? '0' + kwInt : kwInt}`;
                const sunday = new Date(year, month, date + 6);

                return {
                    kwInt,
                    kw: `${kwInt < 10 ? '0' + kwInt : kwInt}`,
                    monday,
                    sunday,
                    label: `KW ${kw} / ${format(monday, 'dd.MM')}. - ${format(sunday, 'dd.MM')}.`,
                    firstLastDayArray: [
                        date + '-' + (month + 1) + '-' + year,
                        date + 6 + '-' + (month + 1) + '-' + year,
                    ],
                    isCurrentKW: now >= monday && now <= sunday,
                };
            });

        setWeeks(mondays);
    }, [selectedDate]);

    React.useLayoutEffect(() => {
        const currentWeek = weeks.find(({ monday, sunday }: any) => {
            const date = new Date(
                selectedDate?.getFullYear(),
                selectedDate?.getMonth(),
                selectedDate?.getDate()
            );
            return date >= monday && date <= sunday;
        });

        let offsetPrev = 0;
        let offsetNext = 0;

        const template = Array.from({ length: weeks.length }).map((_, idx) => {
            if (idx === 0 || idx === weeks.length - 1) {
                return true;
            }
            if (currentWeek && currentWeek?.kwInt === idx + 1) {
                return 1;
            }
            if (currentWeek && currentWeek?.kwInt - rangeValue <= 0) {
                offsetPrev = rangeValue - currentWeek?.kwInt;
            }
            if (currentWeek?.kwInt + rangeValue >= weeks.length) {
                offsetNext = currentWeek?.kwInt + rangeValue - weeks.length;
            }
            if (
                idx > currentWeek?.kwInt - rangeValue - offsetNext &&
                idx < currentWeek?.kwInt + rangeValue + offsetPrev
            ) {
                return true;
            }
            return null;
        });

        setCurrentKW({ currentWeek, template });
    }, [selectedDate, weeks]);

    return (
        <Nav
            selectedDate={selectedDate}
            label={currentKW?.currentWeek?.label}
            onForward={props?.onForward}
            onNext={props?.onNext}
            onToday={props?.onToday}
            {...props}
        >
            {weeks
                .map(({ kw, kwInt, monday, label, isCurrentKW }: any, idx: number) => {
                    const props = {
                        fontSize: 12,
                        fontWeight: 600,
                        p: 1,
                        color: 'gray.700',
                        rounded: 'md',
                        spacing: 0,
                        onClick: () => {
                            onChange(new Date(monday));
                        },
                        ...(!Boolean(currentKW?.template?.[idx]) && {
                            display: 'none',
                        }),
                        ...(currentKW?.currentWeek?.kwInt === kwInt && {
                            bg: 'gray.200',
                        }),
                        'data-test-id': `calendar-week-${kw}`,
                    };

                    if (!Boolean(currentKW?.template?.[idx])) {
                        return null;
                    }

                    return (
                        <VStack
                            key={label}
                            {...props}
                            _hover={{ bg: 'gray.200' }}
                            position="relative"
                        >
                            {isCurrentKW && (
                                <chakra.span
                                    position="absolute"
                                    top={-6}
                                    color="blue.500"
                                    fontWeight={600}
                                    fontSize={16}
                                >
                                    .
                                </chakra.span>
                            )}

                            <chakra.span>KW</chakra.span>
                            <chakra.span>{kw}</chakra.span>
                        </VStack>
                    );
                })
                .reduce((acc: any, w: any, idx: number) => {
                    if ((!Boolean(w) && idx === 1) || (!Boolean(w) && idx === weeks.length - 2)) {
                        return [
                            ...acc,
                            <VStack key={`${idx}- ...`} align="flex-end" h="full">
                                <chakra.span></chakra.span>
                                <chakra.span color="gray.300">...</chakra.span>
                            </VStack>,
                        ];
                    }

                    if (!Boolean(w)) {
                        return [...acc];
                    }

                    return [...acc, w];
                }, [])}
        </Nav>
    );
}

function DayNav({ selectedDate, onChange, emergencyService, ...props }: any) {
    const navigate = useNavigate();
    const days = generateDays(selectedDate);

    return (
        <>
            <Nav
                selectedDate={selectedDate}
                onForward={props?.onForward}
                onNext={props?.onNext}
                onToday={props?.onToday}
                {...{ emergencyService, ...props }}
            >
                {days.map(({ day, weekday, date }: any) => {
                    const resolvedWeekday =
                        weekday.charAt(0) + weekday.slice(1)?.toLocaleLowerCase();
                    format(new Date(date), 'yyyy-MM-dd');
                    const to = format(new Date(date), 'yyyy-MM-dd');
                    const props = {
                        fontSize: 12,
                        fontWeight: 600,
                        p: 1,
                        rounded: 'md',
                        color: ['Sa', 'So'].includes(resolvedWeekday) ? 'gray.500' : 'gray.700',
                        ...(!emergencyService &&
                            ['Sa', 'So'].includes(resolvedWeekday) && { display: 'none' }),
                        spacing: 0,
                        onClick: () => {
                            onChange?.(new Date(date)), navigate(`/projekte/termine/${to}`);
                        },
                        ...(to === format(selectedDate, 'yyyy-MM-dd') && { bg: 'gray.200' }),
                        'data-test-id': to,
                    };

                    return (
                        <VStack key={date} {...props} _hover={{ bg: 'gray.200' }}>
                            <chakra.span>{day}</chakra.span>
                            <chakra.span>{resolvedWeekday}</chakra.span>
                        </VStack>
                    );
                })}
            </Nav>
        </>
    );
}

function CalendarNavigation({
    selectedDate = new Date(),
    label,
    months = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
}: {
    selectedDate?: Date;
    label?: string;
    months?: string[];
}) {
    const ref: any = React.useRef();
    const { scrollWidth, clientHeight } = useScroll(ref);

    const width = scrollWidth + 'px';
    const height = clientHeight + 'px';

    return (
        <Box position="relative">
            <Box
                position="absolute"
                w={width}
                h={height}
                p={0}
                as={CalendarNav}
                sx={{
                    '& button': {
                        width,
                    },
                    '& span': {
                        width,
                        visibility: 'hidden',
                    },
                }}
            >
                <CalendarNav />
            </Box>

            <Button
                w={width}
                h={height}
                p={0} variant="unstyled">
                <VStack align="flex-start" rounded="md" ref={ref} w="full" spacing={0}>
                    <Text fontWeight={700} color="gray.900" fontSize={18}>
                        {label || months?.[selectedDate?.getMonth?.()]}
                    </Text>
                    <Text fontWeight={400} color="gray.600" fontSize={14}>
                        {selectedDate?.getFullYear?.()}
                    </Text>
                </VStack>
            </Button>
        </Box>
    );
}
