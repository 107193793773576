import { DataTable, Card, CardHeader, CardActions, CardContent, CardHeading, PlusButton } from '@ucc/react/ui';
import { HasPermission } from '~/layout/HasPermission';
import { useResidentialUnits } from '../hooks/useResidentialUnits';

export function ResidentialUnits(props?: any) {
    const { onCreate, withOutGesamtobjekt, subprojectUnits, ...tableProps } = useResidentialUnits(props);
    return (
        <HasPermission resource="project.residentialUnits" permission="view">
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>Wohneinheit</CardHeading>
                    <CardActions>
                        <HasPermission resource="project.residentialUnits" permission="create">
                            <PlusButton onClick={onCreate}>Neue Wohneinheit</PlusButton>
                        </HasPermission>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...tableProps} data={withOutGesamtobjekt(subprojectUnits)} />
                </CardContent>
            </Card>
        </HasPermission>
    );
}
