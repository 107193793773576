import { get } from 'lodash';
import { camelCase } from 'change-case';
import log from '~/log';

// TODO: typescript types
const apiDecorators = [
    // Default values decorator
    (value: any, context: any) => {
        const { defaultValue } = context;
        return value || defaultValue;
    },
    // Values format decorator
    (value: any, context: any) => {
        const { field, defaultValue } = context;
        const ui = typeof field?.ui === 'function' ? field?.ui() : field?.ui;

        /** put in all components that should be excluded */
        if (['InputOnDemand'].includes(ui?.component)) {
            return value;
        }

        if (['QuerySelect', 'Select', 'SnippetSelect'].includes(ui?.component)) {
            return Array.isArray(value)
                ? value.map((option) => option.value)
                : value?.value || defaultValue?.value || defaultValue;
        }

        if (
            ['ContactInput'].includes(ui?.component) ||
            ui?.props?.type === 'number' ||
            ui?.type === 'number'
        ) {
            return ui?.props?.step ? parseFloat(value) : parseInt(value, 10);
        }

        if (['Checkbox', 'CheckboxControl'].includes(ui?.component)) {
            return typeof value === 'string' ? value === 'true' : Boolean(value);
        }

        if (['DatePicker'].includes(ui?.component)) {
            return ui?.props?.type === 'datetime-local' ? new Date(value) : value;
        }

        if (['RichTextEditor'].includes(ui?.component)) {
            return JSON.stringify(value?.text ? value?.text : value);
        }

        return value;
    },
];

export const formFieldsToGraphQl =
    (form = [], fields: any = {}, fallback = true) =>
    (data: any = {}, defaultValues: any = {}, fixedValues: any = {}) => {
        const dataValues = { ...defaultValues, ...data, ...fixedValues };

        return form.filter(Boolean).reduce((acc, field: any) => {
            const { api = {}, defaultValue } = get(fields, field.path) || {};
            const { isDisabled = false } = field;
            let fieldName = api.path?.req || api.path;
            console.log('toGraphQl', { fieldName, isDisabled, fields, field, api, defaultValue });
            if (!fieldName && fallback) {
                fieldName = field.path;
            }
            const formFieldName = camelCase(field.path);
            const formFieldValue = dataValues[formFieldName];

            const isMissingValue = formFieldValue === null || formFieldValue === undefined;
            const isArrayValue = Array.isArray(formFieldValue);
            const isNotIncluded = field.include === false;
            const isIncluded = field.include === true;
            const removeFromRequest =
                isNotIncluded || (!isIncluded && isMissingValue && !isArrayValue);

            if (isDisabled || removeFromRequest) {
                return acc;
            }

            // TODO: either field.decorators?.api or apiDecorators
            const decorators = [...(field.decorators?.api || []), ...apiDecorators];
            const context = { field, formFieldName, formFieldValue, defaultValue, dataValues };
            const fieldValue = decorators.reduce(
                (value, decorate) => decorate(value, context),
                formFieldValue
            );

            console.log('toGraphQl', { [fieldName]: fieldValue });

            const isSpread = field.spread === true;

            if (fieldName === undefined) {
                log.error('formToGql.fieldNameNotFound', field?.path, api);
            }

            return {
                ...acc,
                ...(isSpread && fieldValue),
                ...(!isSpread && {
                    [fieldName]:
                        typeof fieldValue === 'string' && fieldValue === ' ' ? '' : fieldValue,
                }),
            };
        }, {});
    };
