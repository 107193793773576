/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    fragment DocumentUploadFragment on DocumentUpload {\n        id\n        timestamp\n        status\n        s3Object {\n            key\n            versionId\n        }\n        projectNumber\n        metadata {\n            filename\n            docLocation {\n                key\n            }\n        }\n    }\n": types.DocumentUploadFragmentFragmentDoc,
    "\n    subscription documentUpload {\n        documents {\n            ...DocumentUploadFragment\n        }\n    }\n": types.DocumentUploadDocument,
    "\n    mutation deleteUpload($id: ID!) {\n        documents {\n            deleteUpload(id: $id)\n        }\n    }\n": types.DeleteUploadDocument,
    "\n    mutation moveUploads($ids: [ID!], $subprojectId: ID!) {\n        documents {\n            moveUploads(ids: $ids, subprojectId: $subprojectId)\n        }\n    }\n": types.MoveUploadsDocument,
    "\n    query listUploads {\n        documents {\n            uploads {\n                ...DocumentUploadFragment\n            }\n        }\n    }\n": types.ListUploadsDocument,
    "\n    mutation CreatePdf($template: String!, $filename: String!, $data: AWSJSON) {\n        pdf {\n            createPdf(template: $template, filename: $filename, data: $data) {\n                signedUrl\n                versionId\n                error\n            }\n        }\n    }\n": types.CreatePdfDocument,
    "\n    fragment SecurityRoleFragment on SecurityRole {\n        id\n        name\n        abbreviation\n        description\n        system\n        created\n        modified\n        permissionGroups {\n            id\n            name\n            type\n            permissionSet\n            resources {\n                id\n                name\n                permissions\n                value\n            }\n        }\n    }\n": types.SecurityRoleFragmentFragmentDoc,
    "\n    mutation createRole($data: SecurityRoleCreateInput!) {\n        security {\n            role: createRole(data: $data) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.CreateRoleDocument,
    "\n    query getRole($id: ID!) {\n        security {\n            role: getRole(where: { id: $id }) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.GetRoleDocument,
    "\n    mutation updateRole($id: ID!, $data: SecurityRoleUpdateInput!) {\n        security {\n            role: updateRole(id: $id, data: $data) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.UpdateRoleDocument,
    "\n    mutation deleteRole($id: ID!) {\n        security {\n            role: deleteRole(id: $id) {\n                data\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.DeleteRoleDocument,
    "\n    query listRoles {\n        security {\n            roles: listRoles {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.ListRolesDocument,
    "\n    fragment SecurityUserFragment on SecurityUser {\n        id\n        email\n        firstName\n        lastName\n        avatar\n        created\n        modified\n        roleId\n        role {\n            id\n            name\n        }\n        app\n    }\n": types.SecurityUserFragmentFragmentDoc,
    "\n    query getUser($email: ID!) {\n        security {\n            user: getUser(where: { id: $email }) {\n                data {\n                    ...SecurityUserFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.GetUserDocument,
    "\n    query listUsers {\n        security {\n            users: listUsers {\n                data {\n                    ...SecurityUserFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n": types.ListUsersDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment DocumentUploadFragment on DocumentUpload {\n        id\n        timestamp\n        status\n        s3Object {\n            key\n            versionId\n        }\n        projectNumber\n        metadata {\n            filename\n            docLocation {\n                key\n            }\n        }\n    }\n"): (typeof documents)["\n    fragment DocumentUploadFragment on DocumentUpload {\n        id\n        timestamp\n        status\n        s3Object {\n            key\n            versionId\n        }\n        projectNumber\n        metadata {\n            filename\n            docLocation {\n                key\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    subscription documentUpload {\n        documents {\n            ...DocumentUploadFragment\n        }\n    }\n"): (typeof documents)["\n    subscription documentUpload {\n        documents {\n            ...DocumentUploadFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteUpload($id: ID!) {\n        documents {\n            deleteUpload(id: $id)\n        }\n    }\n"): (typeof documents)["\n    mutation deleteUpload($id: ID!) {\n        documents {\n            deleteUpload(id: $id)\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation moveUploads($ids: [ID!], $subprojectId: ID!) {\n        documents {\n            moveUploads(ids: $ids, subprojectId: $subprojectId)\n        }\n    }\n"): (typeof documents)["\n    mutation moveUploads($ids: [ID!], $subprojectId: ID!) {\n        documents {\n            moveUploads(ids: $ids, subprojectId: $subprojectId)\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query listUploads {\n        documents {\n            uploads {\n                ...DocumentUploadFragment\n            }\n        }\n    }\n"): (typeof documents)["\n    query listUploads {\n        documents {\n            uploads {\n                ...DocumentUploadFragment\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreatePdf($template: String!, $filename: String!, $data: AWSJSON) {\n        pdf {\n            createPdf(template: $template, filename: $filename, data: $data) {\n                signedUrl\n                versionId\n                error\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreatePdf($template: String!, $filename: String!, $data: AWSJSON) {\n        pdf {\n            createPdf(template: $template, filename: $filename, data: $data) {\n                signedUrl\n                versionId\n                error\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment SecurityRoleFragment on SecurityRole {\n        id\n        name\n        abbreviation\n        description\n        system\n        created\n        modified\n        permissionGroups {\n            id\n            name\n            type\n            permissionSet\n            resources {\n                id\n                name\n                permissions\n                value\n            }\n        }\n    }\n"): (typeof documents)["\n    fragment SecurityRoleFragment on SecurityRole {\n        id\n        name\n        abbreviation\n        description\n        system\n        created\n        modified\n        permissionGroups {\n            id\n            name\n            type\n            permissionSet\n            resources {\n                id\n                name\n                permissions\n                value\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation createRole($data: SecurityRoleCreateInput!) {\n        security {\n            role: createRole(data: $data) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation createRole($data: SecurityRoleCreateInput!) {\n        security {\n            role: createRole(data: $data) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getRole($id: ID!) {\n        security {\n            role: getRole(where: { id: $id }) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query getRole($id: ID!) {\n        security {\n            role: getRole(where: { id: $id }) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateRole($id: ID!, $data: SecurityRoleUpdateInput!) {\n        security {\n            role: updateRole(id: $id, data: $data) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation updateRole($id: ID!, $data: SecurityRoleUpdateInput!) {\n        security {\n            role: updateRole(id: $id, data: $data) {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteRole($id: ID!) {\n        security {\n            role: deleteRole(id: $id) {\n                data\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation deleteRole($id: ID!) {\n        security {\n            role: deleteRole(id: $id) {\n                data\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query listRoles {\n        security {\n            roles: listRoles {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query listRoles {\n        security {\n            roles: listRoles {\n                data {\n                    ...SecurityRoleFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment SecurityUserFragment on SecurityUser {\n        id\n        email\n        firstName\n        lastName\n        avatar\n        created\n        modified\n        roleId\n        role {\n            id\n            name\n        }\n        app\n    }\n"): (typeof documents)["\n    fragment SecurityUserFragment on SecurityUser {\n        id\n        email\n        firstName\n        lastName\n        avatar\n        created\n        modified\n        roleId\n        role {\n            id\n            name\n        }\n        app\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getUser($email: ID!) {\n        security {\n            user: getUser(where: { id: $email }) {\n                data {\n                    ...SecurityUserFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query getUser($email: ID!) {\n        security {\n            user: getUser(where: { id: $email }) {\n                data {\n                    ...SecurityUserFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query listUsers {\n        security {\n            users: listUsers {\n                data {\n                    ...SecurityUserFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query listUsers {\n        security {\n            users: listUsers {\n                data {\n                    ...SecurityUserFragment\n                }\n                error {\n                    code\n                    message\n                    data\n                }\n            }\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;