import { join, resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = {
  tables: [{ id: 'contacts', colSpan: 3, snippets: { create: 'Neuer Kontakt' } }],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
  contacts: {
    __graphqlType: 'Kontakt',
    id: { header: 'Id', accessor: 'id' },
    name: { header: 'Name', accessor: 'name', cell: ({row}:any) => join([row.original.name, row.original.vorname]) },
    company1: { header: 'Firma', accessor: 'firma1', cell: ({  row }:any) => join([row.original.firma1, row.original.firma2])  },
    address: { header: 'Adresse', accessor: 'plz', cell: ({ row }:any) => join([`${row?.original?.plz} ${row.original.ort}`, row.original.strasse]) },
    details: { header: 'Tel. & Mail', accessor: 'email', renderer: { name: 'ContactDetails' } },
    function: { header: 'Funktion', accessor: 'typSnippet.name' },
    comment: { header: 'Bemerkung', accessor: 'freitext', renderer: { name: 'Description' } },
    controls: { accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  contacts: {
    columns: ['id', 'company1', 'address', 'details', 'comment', 'controls'].map(tableColumnMapper(columns.contacts)),
    hiddenColumns: ['id'],
  },
  contactSearch: {
    columns: ['id', 'name', 'company1', 'address', 'details', 'function', 'comment', 'controls'].map(tableColumnMapper(columns.contacts)),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  contacts: {
    __graphqlInput: 'KontaktInput',
    typeSnippetId: { api: { path: 'typSnippetId' } },
    statusSnippetId: { api: { path: 'statusSnippetId' } },
    company1: { api: { path: 'firma1' }, defaultValue: '' },
    company2: { api: { path: 'firma2' }, defaultValue: '' },
    salutationSnippetId: { api: { path: 'anredeSnippetId' } },
    firstName: { api: { path: 'vorname' }, defaultValue: '' },
    lastName: { api: { path: 'name' }, defaultValue: '' },
    street: { api: { path: 'strasse', required: true }, defaultValue: '' },
    zipCode: { api: { path: 'plz', required: true }, defaultValue: '' },
    location: { api: { path: 'ort', required: true }, defaultValue: '' },
    postOfficeBox: { api: { path: 'postfach', required: true }, defaultValue: '' },
    countrySnippetId: { api: { path: 'landSnippetId' } },
    email: { api: { path: 'email' }, defaultValue: '' },
    phone: { api: { path: 'telefon' }, defaultValue: '' },
    phoneDirect: { api: { path: 'telefonDirekt' }, defaultValue: '' },
    phoneMobile: { api: { path: 'telefonMobil' }, defaultValue: '' },
    phonePrivate: { api: { path: 'telefonPrivat' }, defaultValue: '' },
    fax: { api: { path: 'fax' }, defaultValue: '' },
    deliveryStreet: { api: { path: 'zustellStrasse', required: true }, defaultValue: '' },
    deliveryZipCode: { api: { path: 'zustellPlz', required: true }, defaultValue: '' },
    deliveryLocation: { api: { path: 'zustellOrt', required: true }, defaultValue: '' },
    comment: { api: { path: 'freitext' }, defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }]  }, // AWSJSON!
  },
};

export const forms = {
  contacts: [
    { path: 'company1', ui: { label: 'Firma' } },
    { path: 'company2', ui: { label: 'Firma (Zusatz)' } },
    { path: 'lastName', ui: { label: 'Name' } },
    { path: 'firstName', ui: { label: 'Vorname' } },
    { path: 'salutationSnippetId', ui: { label: 'Anrede', component: 'SnippetSelect', props: { category: 'Kontakt', property: 'Anrede' } } },
    { path: 'street', ui: { label: 'Strasse' } },
    { path: 'zipCode', ui: { label: 'PLZ' } },
    { path: 'location', ui: { label: 'Ort' } },
    { path: 'countrySnippetId', ui: { label: 'Land', component: 'SnippetSelect', props: { category: 'Land', property: '' } } },
    { path: 'phone', ui: { label: 'Tel (Geschäft)' } },
    { path: 'phoneDirect',  ui: { label: 'Tel (Direkt)' } },
    { path: 'phoneMobile', ui: { label: 'Tel (Mobil)' } },
    { path: 'phonePrivate', ui: { label: 'Tel (Privat)' } },
    { path: 'fax', ui: { label: 'Fax' } },
    { path: 'email', ui: { label: 'Email' } },
    { path: 'typeSnippetId', ui: { label: 'Funktion', component: 'SnippetSelect', props: { category: 'Kontakt', property: 'Kontaktrolle' } } },
    { path: 'comment', ui: { label: 'Bemerkung', component: 'RichTextEditor', props: { hasSnippets: false }, colSpan: 2 } },
    { path: 'postOfficeBox', visibility: { hide: () => true }, validation: false },
    { path: 'deliveryStreet', visibility: { hide: () => true }, validation: false },
    { path: 'deliveryZipCode', visibility: { hide: () => true }, validation: false },
    { path: 'deliveryLocation', visibility: { hide: () => true }, validation: false },
  ],
};

export const contacts = {
  columns: tables.contacts.columns,
  tableProps: { pageSize: 15, hiddenColumns: tables.contacts.hiddenColumns },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.contacts, fields.contacts, context),

      snippets: {
        create: 'Neuer Kontakt',
        update: 'Kontakt bearbeiten',
        displayName: (item: any) => join([item?.firma1, item?.firma2, item?.name]),
      },
    };
  },
};
