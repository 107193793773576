import { Container, VStack } from '@chakra-ui/react';
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { PageHeader } from '~/components/Layout/PageHeader';
import { HasPermission } from '~/layout/HasPermission';
import { useAuth } from '../hooks/useAuth';

export const Roles = () => {
    const { columns, roles, hiddenColumns, isReadLoading, pageSize } = useAuth();


    return (
        <>
            <PageHeader>
                <PageHeader.Title>Berechtigungsprofile</PageHeader.Title>
            </PageHeader>
            <Container maxW={800}>
                <VStack alignItems="center">
                    <Card boxShadow="none">
                        <CardHeader>
                            <CardHeading>Berechtigungsprofile</CardHeading>
                            <CardActions>
                                <HasPermission resource="staff.permissions" permission="create">
                                    <PlusButton
                                        data-test-id="button-new-role"
                                        to="/personal/berechtigungen/neu"
                                    >
                                        Neues Berechtigungsprofil
                                    </PlusButton>
                                </HasPermission>
                            </CardActions>
                        </CardHeader>
                        <CardContent>
                            <DataTable
                                columns={columns}
                                hiddenColumns={hiddenColumns}
                                data={roles}
                                loading={isReadLoading}
                                pageSize={pageSize}
                            />
                        </CardContent>
                    </Card>
                </VStack>
            </Container>
        </>
    );
};
