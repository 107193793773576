import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Subprojekt, GetSubprojectDocument, Beteiligter, ListSubprojectsDocument, SortOrder, CreateSubprojektToProjektDocument, CreateCorrespondenceDocument, CreateSubprojektToProjektInput, CreateSubprojectResidentialUnitDocument } from '~/gql/ucpw/graphql';
import { ProjectIds } from '~/pages/projects/types';
import { useRedirectToProjectSearch } from '~/pages/projects/hooks/useRedirectToProjectSearch';
import { useContextAndRefetchQueries } from "~/hooks/useContextAndRefetchQueries";
import { useTitle } from './useTitle';
import { useSecurity } from '@ucc/react/security';
import { useSnippetId } from '~/hooks/useSnippetId';
import { usePdfRefetchQueries } from './usePdf';

export const useSubproject = ({ subprojectId, projectId }: ProjectIds) => {
    const { subproject: subprojectsRefetchQueries } = usePdfRefetchQueries({ subprojectId })
    const onCompleted = useRedirectToProjectSearch();
    const { viewer } = useSecurity()
    const employeeId = viewer?.app?.mitarbeiterId;
    const { typSnippetId } = useSnippetId({ name: 'Projektanlage', category: 'Korrespondenz' });
    const context = { clientName: 'ucpw' };

    // ===============================================
    // GET Subproject
    // ===============================================

    const { data: subprojectData, loading: subprojectLoading } = useQuery(GetSubprojectDocument, {
        variables: { id: subprojectId as number },
        context,
        skip: !subprojectId,
        onCompleted,
        fetchPolicy: 'network-only',
    });
    const data = (subprojectData?.item?.item as Subprojekt || {} as Subprojekt);
    const participants = data?.beteiligte?.reduce((acc: any, beteiligter: any) => {
        // TODO: Use participants IDs for Mapping (useProjectDetails)
        const map = {
            Handwerker: 'handwerkerId',
            'Beauftragter Handwerker': 'handwerkerId',
            HW: 'handwerkerId',
            Vermittler: 'vermittlerId',
            Eigentuemer: 'eigentuemerId',
            Eigentümer: 'eigentuemerId',
            Auftraggeber: 'auftraggeberId',
            Versicherungsnehmer: 'versicherungsnehmerId',
        };
        const key = map[beteiligter?.typSnippet?.name as keyof typeof map];
        if (!key || acc[key as keyof typeof acc]) {
            return acc;
        }
        return { ...acc, [key]: beteiligter.kontaktId };
    }, {}) as Beteiligter[];

    const contextAndRefetchQueries = useContextAndRefetchQueries(ListSubprojectsDocument, {
        filter: { projektId: projectId },
        orderBy: [{ lfdNr: SortOrder.Asc }, { anlageDatum: SortOrder.Desc }],
    }, [subprojectsRefetchQueries])

    // ===============================================
    // TITLE
    // ===============================================

    const { title, subprojectNo, loading: titleIsLoading, subproject } = useTitle(data);

    // ===============================================
    // CREATE
    // ===============================================

    const [create, { loading: createLoading }] = useMutation(CreateSubprojektToProjektDocument, contextAndRefetchQueries);
    const [createCorrespondence, { loading: createCorrespondenceLoading },] = useMutation(CreateCorrespondenceDocument, contextAndRefetchQueries);

    const [createSubprojectResidentialUnit, { loading: createSubprojectResidentialUnitLoading }] = useMutation(CreateSubprojectResidentialUnitDocument, contextAndRefetchQueries);
    const getOverallBuilding = (subproject?: any) => subproject?.subprojektWohneinheit?.find((unit?: any) => unit?.wohneinheit?.istGesamtobjekt)
    const overallBuilding = getOverallBuilding(subproject)

    const createMutation = React.useCallback(async (input: CreateSubprojektToProjektInput) => {
        const response = await create({ variables: { input } });
        const subproject = response?.data?.app?.subproject?.item as Subprojekt;
        const newSubprojectOverallBuilding = getOverallBuilding(subproject)
        const subprojectId = subproject?.id;

        if (subprojectId) {
            if (overallBuilding && !newSubprojectOverallBuilding) {
                await createSubprojectResidentialUnit({
                    variables: {
                        data: {
                            subprojektId: subprojectId,
                            projektId: subproject?.projektId,
                            wohneinheitId: overallBuilding?.wohneinheitId,
                            wattroProjectExists: false
                        }
                    },
                    context,
                })
            }

            await createCorrespondence({
                variables: {
                    data: {
                        typSnippetId,
                        subprojektId: subprojectId,
                        freitext: JSON.stringify([
                            { type: 'paragraph', children: [{ text: '...Bitte ausfüllen!' }] },
                        ]),
                        zeit: new Date(),
                        beteiligter: '',
                        ...(employeeId && { mitarbeiterIdSachbearbeiter: employeeId }),
                    },
                },
                context,
            });
        }
        return response;
    }, [overallBuilding, subproject, typSnippetId]);

    const loading = subprojectLoading || createLoading || createCorrespondenceLoading || createSubprojectResidentialUnitLoading;

    return {
        data: subproject,
        titleIsLoading,
        title,
        subprojectNo,
        participants,
        loading,
        createMutation,
    }
}