import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { themes } from '@ucc/react/ui';
import { RouterProvider } from 'react-router-dom';
import { Connectivity } from '~/components/Connectivity';
import { client } from './apollo';
import { router } from './Router';

function App() {
    return (
        <ChakraProvider theme={themes.ucpwTheme}>
            <Connectivity />
            <ApolloProvider client={client}>
                <RouterProvider router={router} />
            </ApolloProvider>
        </ChakraProvider>
    );
}

export default App;
