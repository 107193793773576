import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { GridItem, FormControl, FormLabel, Box } from '@chakra-ui/react';
import { useQuery, useMutation } from '@apollo/client';
import { DokumentInput, ListDocumentsDocument, CreateDocumentDocument, GetDocumentDocument, UpdateDocumentDocument } from "~/gql/ucpw/graphql";
import { useContextAndRefetchQueries } from "~/hooks/useContextAndRefetchQueries"
import { SnippetSelect } from './SnippetSelect';
import { S3Upload } from './S3Upload';
import { DocumentDownload } from './DocumentDownload';
import log from '~/log';

export const DocumentUpload = ({ placeholder, isDisabled = false, ...props }: any) => {
    const {
        field: { name, value, ...inputProps },
    } = useController({
        name: props.name,
        control: props.control,
        defaultValue: null,
    });
    const { data } = useQuery(GetDocumentDocument, {
        variables: { id: value },
        context: { clientName: 'ucpw' },
        skip: !value,
        fetchPolicy: 'network-only',
    });
    const document = data?.item?.item as any || ({} as any);
    const subprojectId = props.subprojectId;
    const defaultValues = {
        [name]: {
            documentType: { value: document?.typSnippet?.id },
            documentFile: { fileName: document?.name, key: document?.pfad },
        },
    } as any;
    const { control, watch, reset } = useForm({ defaultValues });

    React.useEffect(() => {
        reset(defaultValues);
    }, [document?.id]);

    const { refetchQueries } = useContextAndRefetchQueries(ListDocumentsDocument, { filter: { subprojektId: subprojectId } });
    const [createMutation] = useMutation(CreateDocumentDocument, {
        context: { clientName: 'ucpw' },
        refetchQueries,
    });
    const [updateMutation] = useMutation(UpdateDocumentDocument, {
        context: { clientName: 'ucpw' },
        refetchQueries,
    });

    const watchFields = watch();
    const documentFields = watchFields[name];
    const documentType = documentFields?.documentType || {};
    const documentFile = documentFields?.documentFile || {};
    const documentDescription = document?.beschreibung || '';

    React.useEffect(() => {
        const saveDocument = async () => {
            const data: DokumentInput = {
                name: documentFile?.fileName,
                pfad: documentFile?.key,
                beschreibung: documentDescription,
                subprojektId: subprojectId,
                typSnippetId: documentType?.value,
            };
            console.log('DocumentUpload.data', data);
            const response: any = document.id
                ? await updateMutation({
                    variables: { id: document?.id, data },
                })
                : await createMutation({ variables: { data } });
            const documentId = response?.data?.item?.item?.id;
            props?.onChange?.(response?.data?.item?.item);
            log.debug('DocumentUpload.saveDocument', documentId, response);
            return documentId;
        };

        if (documentFile.key) {
            saveDocument().then((documentId) => inputProps.onChange?.(documentId));
        }
    }, [documentType.value, documentFile.key, documentDescription]);

    const documentTypeProps = {
        category: 'Dokument',
        property: 'Typ',
        ...(props.documentTypeProps || {}),
    };

    const hasFileType = props.showFileType !== false;

    return (
        <>
            <GridItem colSpan={2} w="full">
                <FormControl>
                    <FormLabel htmlFor={`${name}.documentFile`}>{props.label}</FormLabel>
                    <S3Upload
                        isDisabled={isDisabled}
                        control={control}
                        name={`${name}.documentFile`}
                        placeholder={placeholder}
                    />
                </FormControl>
            </GridItem>
            {hasFileType && (
                <>
                    <GridItem colSpan={1} w="full">
                        <FormLabel htmlFor={`${name}.documentType`}>Dateityp</FormLabel>
                        <SnippetSelect
                            limit={100}
                            isDisabled={isDisabled}
                            control={control}
                            name={`${name}.documentType`}
                            {...documentTypeProps}
                        />
                    </GridItem>
                    {document?.id && (
                        <GridItem colSpan={1}>
                            <Box px={4} py={8}>
                                <DocumentDownload
                                    label={`${document.name} ${document?.typSnippet?.name
                                        ? `(${document?.typSnippet?.name})`
                                        : ''
                                        }`}
                                    path={document.pfad}
                                />
                            </Box>
                        </GridItem>
                    )}
                </>
            )}
        </>
    );
};
