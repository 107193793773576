import gql from 'graphql-tag';

const eventFields = gql`
  fragment EventFields on Termin {
    id
    version
    angelegt
    bemerkung
    dauer
    erledigt
    geplant
    mitarbeiterIdSachbearbeiter
    mitarbeiterIdUrheber
    prio
    sachbearbeiter {
      id
      kennung
      name
      vorname
      farbe
    }
    subprojektId
    subprojekt {
      id
      version
      lfdNr
      beteiligte {
        id
        kontakt {
          id
          firma1
          firma2
          name
          vorname
        }
        typSnippet {
          id
          name
        }
      }
      projekt {
        lfdNr
        id
        version
        plz
        ort
        anlageDatum
        strasse
        lfdNr
        niederlassung {
          id
          nummer
        }
      }
      projekttyp {
        id
        name
      }
    }
    typSnippetId
    typSnippet {
      id
      kategorie
      kuerzel
      eigenschaft
      name
    }
    urheber {
      id
      kennung
      name
      vorname
    }
  }
`;

export const events = {
  createMutation: gql`
    mutation createEvent($data: TerminCreateInput!) {
      item: createTermin(data: $data) {
        item {
          ...EventFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${eventFields}
  `,
  updateMutation: gql`
    mutation updateEvent($id: Int!, $version: Int!, $forceOverwrite: Boolean, $data: TerminUpdateInput!) {
      item: updateTermin(id: $id, version: $version, forceOverwrite: $forceOverwrite, data: $data) {
        item {
          ...EventFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${eventFields}
  `,
  deleteMutation: gql`
    mutation deleteEvent($id: Int!, $version: Int!, $forceOverwrite: Boolean) {
      item: deleteTermin(id: $id, version: $version, forceOverwrite: $forceOverwrite) {
        item {
          ...EventFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${eventFields}
  `,
  listQuery: gql`
    query listEvents($offset: Int, $limit: Int, $orderBy: [TerminOrderByInput], $filter: TerminFilterInput!) {
      items: termin(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          ...EventFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${eventFields}
  `,
  getQuery: gql`
    query getEvent($id: Int!) {
      item: terminById(id: $id) {
        item {
          ...EventFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${eventFields}
  `,
};
