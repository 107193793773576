import { assign, createMachine } from 'xstate';

export type ModalMachine = ReturnType<typeof createModalMachine>;

export type ModalMachineEvents =
    | {
        type: 'SET_CONTEXT';
        [key: string]: any;

    };

export type ModalMachineContext = {
    [key: string]: any;
};

export const context = {};

export const createModalMachine = () =>
    createMachine(
        {
            id: 'modal',
            predictableActionArguments: true,
            tsTypes: {} as import("./modalMachine.typegen").Typegen0,
            schema: {
                context: {} as ModalMachineContext,
                events: {} as ModalMachineEvents,
            },
            context,
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SET_CONTEXT: { actions: 'setContext' },
                    },
                },
            },
        },
        {
            actions: {
                setContext: assign((ctx, event) => (event)),
            },
        }
    );
