import React from 'react';
import { useController } from 'react-hook-form';
import { Input, Text, Center, Grid } from '@chakra-ui/react';

export const TimeRange = ({
    control,
    name,
}: {
    control: any;
    name: string;
}) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name,
        control,
        defaultValue: ['', ''],
    });
    const [values, setValues] = React.useState<any>(() => ({ von: '', bis: '' }));

    React.useEffect(() => {
        setValues({ von: value[0] || '', bis: value[1] || '' })
    }, [value])



    return (
        <Grid templateColumns='1fr 20px 1fr' gap={4} onMouseLeave={() => inputProps.onChange(Object.values(values))}>
            <Input type="time" value={values?.von || ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValues(({ bis }: any) => ({ von: e?.target?.value, bis }))} />
            <Center><Text>bis</Text></Center>
            <Input type="time" value={values?.bis || ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValues(({ von }: any) => ({ von, bis: e?.target?.value }))} />
        </Grid >
    );
};
