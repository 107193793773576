import { useMemo } from 'react';
import { Resource, Permission, useViewer } from './useViewer';

export type { Resource, Permission };

export function usePermission(resource: Resource) {
    const { email, hasPermission, isReadOnly } = useViewer();

    const canView = useMemo((): boolean => {
        return hasPermission(resource, 'view');
    }, [email, resource]);

    const canCreate = useMemo((): boolean => {
        return hasPermission(resource, 'create');
    }, [email, resource]);

    const canEdit = useMemo((): boolean => {
        return hasPermission(resource, 'update');
    }, [email, resource]);

    const canDelete = useMemo((): boolean => {
        return hasPermission(resource, 'delete');
    }, [email, resource]);

    const readOnly = useMemo((): boolean => isReadOnly(resource), [isReadOnly, resource]);

    return {
        canView,
        canCreate,
        canEdit,
        canDelete,
        readOnly,
    };
}
