import { selects } from '~/meta/data/selects.gql';
import { join } from '~/utils';
export const TYP_MAP = {
  Trocknung: '#F08330',
  Leckortung: '#B6C5E0',
  Vorarbeiten: '#D4C360',
  Nacharbeiten: '#545557',
};

// Forms
export const fields = {
  events: {
    __graphqlInput: 'TerminCreateInput' || 'TerminUpdateInput',
    // subprojektId: Int
    subprojectId: { api: { path: { req: 'subprojektId', res: 'subprojectId' }, required: false } },
    object: { api: { path: { req: 'objekt', res: 'object' }, required: true } },
    client: { api: { path: { req: 'auftraggeber', res: 'client' }, required: true }, required: true },

    // mitarbeiterIdSachbearbeiter: Int!
    employeeId: { api: { path: { req: 'mitarbeiterIdSachbearbeiter', res: 'employeeId' }, required: true } },
    // geplant: AWSDateTime
    planed: { api: { path: { req: 'geplant', res: 'planed' } } },
    time: { api: { path: { req: 'uhrzeit', res: 'time' } } },
    // dauer: Int!
    duration: { api: { path: { req: 'dauer', res: 'duration' } } },
    // typSnippetId: Int
    typeSnippetId: { api: { path: { req: 'typSnippetId', res: 'typeSnippetId' }, required: true } },
    // bemerkung: String!
    comment: { api: { path: { req: 'bemerkung', res: 'comment' } } },
    // erledigt: AWSDateTime
    done: { api: { path: { req: 'erledigt', res: 'done' } } },
    // prio: Int
    prio: { api: { path: { req: 'prio', res: 'prio' } } },
    // mitarbeiterIdUrheber: Int
    editorId: { api: { path: { req: 'mitarbeiterIdUrheber', res: 'editorId' } } },
  },
};

export const forms = {
  events: [
    {
      path: 'subprojectId',
      ui: ({ onSetProjectSearchModal: onClick }:any = {}) => ({ label: 'Projekt-Nr.', component: 'ProjectSearchInput', props: { onClick } }),
    },
    { path: 'object', ui: { label: 'Objekt', props: { isDisabled: true } } },
    { path: 'client', ui: { label: 'Auftraggeber', props: { isDisabled: true } } },
    {
      path: 'employeeId',
      ui: (ctx:any) => {
        return {
          label: 'Termin für',
          component: 'QuerySelect',
          props: {
            limit: 1000,
            query: selects.employees,
            ...(ctx?.branchId && { variables: { filter: { niederlassungId: ctx?.branchId } } }),
            mapOptions: (item:any) => ({
              value: item?.value,
              label: join([item?.name, item?.vorname]),
            }),
          },
        };
      },
    },
    { path: 'planed', ui: { label: 'Datum', component: 'DatePicker' } },
    { path: 'time', ui: { label: 'Uhrzeit', type: 'time' } },
    { path: 'duration', ui: { label: 'Dauer', component: 'DurationSelect' } },
    { path: 'typeSnippetId', ui: (ctx:any) => ({ label: 'Typ', component: 'SnippetSelect', props: { category: 'Termin', property: 'Typ', placeholder: 'Typ', onSelect: ctx?.onSelectType } }) },
    { path: 'comment', ui: { label: 'Bezeichnung', component: 'Textarea', colSpan: 2 } },
    { path: 'done', ui: { label: 'Erledigt', component: 'Checkbox', colSpan: 2 } },
    { path: 'prio', ui: { label: 'Prio Hoch', component: 'Checkbox', colSpan: 2 } },
  ],
};
