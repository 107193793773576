import React, { useState } from 'react';

export const useScroll = (ref: any) => {
    const [
        {
            overflowX,
            overflowY,
            offsetWidth,
            scrollWidth,
            offsetHeight,
            scrollHeight,
            clientWidth,
            clientHeight,
        },
        setHasScrollbar,
    ] = useState<{ [key: string]: null | number | boolean }>({
        overflowX: null,
        overflowY: null,
        offsetWidth: null,
        scrollWidth: null,
        offsetHeight: null,
        scrollHeight: null,
        clientWidth: null,
        clientHeight: null,
    });

    React.useEffect(() => {
        let isMounted = true;

        const handleResize = () =>
            setHasScrollbar({
                overflowX: ref?.current?.offsetWidth !== ref?.current?.scrollWidth,
                overflowY: ref?.current?.offsetHeight !== ref?.current?.scrollHeight,
                offsetWidth: ref?.current?.offsetWidth,
                scrollWidth: ref?.current?.scrollWidth,
                offsetHeight: ref?.current?.offsetHeight,
                scrollHeight: ref?.current?.scrollHeight,
                clientWidth: ref?.current?.clientWidth,
                clientHeight: ref?.current?.clientHeight,
            });

        const resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                handleResize();
            });
        });

        if (isMounted) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
            isMounted = false;
        };
    }, [ref.current]);

    return {
        hasScrollbarX: !!overflowX,
        hasScrollbarY: !!overflowY,
        offsetWidth,
        scrollWidth,
        offsetHeight,
        scrollHeight,
        clientWidth,
        clientHeight,
    };
};
