import { Auth, Nav, Route } from './routes';

export { routes } from './routes';

const navFilter = (route: Route) => route.meta?.nav !== undefined;

const routeTo = (route: Route, parent?: Route) => {
    if (route.meta?.nav?.to) {
        return route.meta.nav.to;
    }

    return route.path?.startsWith('/')
        ? route.path
        : parent?.path && route?.path
        ? `${parent?.path}/${route.path}`
        : undefined;
};

export type NavAuth = Nav & {
    auth?: Auth;
};

const navAuth = (element?: React.ReactNode) => {
    if (!element) {
        return { auth: undefined };
    }
    const { props } = element as React.ReactElement<{ resource: string; permission?: string }, any>;

    if (!props?.resource) {
        return { auth: undefined, permission: undefined };
    }

    return { auth: { resource: props.resource, permission: props.permission } };
};

export const getNavigation = (routes: Route[]): NonNullable<NavAuth>[] => {
    return routes.filter(navFilter).map((level1) => {
        return {
            ...level1.meta?.nav,
            // auth: level1.meta?.auth,
            ...navAuth(level1.element),
            to: routeTo(level1),
            children: level1.children?.flatMap((level2) => {
                return [
                    level2.meta?.nav
                        ? {
                              ...level2.meta?.nav,
                              //   auth: level2.meta?.auth,
                              ...navAuth(level2.element),
                              to: routeTo(level2, level1),
                          }
                        : undefined,
                    ...(level2.children?.filter(navFilter).map((level3) => ({
                        ...level3.meta?.nav,
                        // auth: level3.meta?.auth,
                        ...navAuth(level3.element),
                        to: routeTo(level3, level2),
                    })) || []),
                ].filter(Boolean);
            }),
        } as NavAuth;
    });
};
