/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, GridItem, SimpleGrid, HStack, FormLabel } from '@chakra-ui/react';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { selects } from '~/pages/projects/meta/data/projects.gql';
import { isJsonString } from '~/utils';

export const FollowUp = ({
    name,
    label,
    control,
    isActive = false,
}: any) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name,
        control,
        defaultValue: null,
    });
    const { jaNein = false, isClarification = {}, text } = value || {};
    const trade = isJsonString(text) ? JSON.parse(text) : null;

    const onChange = React.useCallback(
        (props: any) => {
            inputProps.onChange({
                isClarification,
                ...props,
            });
        },
        [isClarification]
    );

    return isActive ? (
        <>
            {label === "in Klärung" && <FormLabel>Welche Folgemaßnahmen sind notwendig?</FormLabel>}
            <SimpleGrid spacing={4} columns={2}>
                <GridItem>
                    <QuerySelect
                        isClearable={true}
                        isMulti
                        data-test-id={`${name}.trade`}
                        name={`${name}.trade`}
                        control={control}
                        value={trade}
                        onSelect={(option: any) => {
                            const isEmptyArray = Array.isArray(option) && option.length === 0;
                            onChange({
                                ...(isEmptyArray ? { isChecked: false, jaNein: false } : { isChecked: true, jaNein: true }),
                                text: JSON.stringify(option),
                                trade: option,
                            });
                        }}
                        query={selects.trade}
                    />
                </GridItem>
                <GridItem alignSelf="center">
                    <HStack justify="space-between" wrap="wrap" spacing={0} align="center">
                        <Checkbox
                            data-test-id={name}
                            name={name}
                            isChecked={jaNein}
                            whiteSpace="nowrap"
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                onChange({
                                    jaNein: isChecked,
                                    isChecked,
                                    text,
                                    trade,
                                });
                            }}
                        >
                            {label}
                        </Checkbox>
                    </HStack>

                </GridItem>
            </SimpleGrid>
        </>
    ) : null
};
