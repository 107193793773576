import { assign, createMachine } from 'xstate';
import log from '~/log'

export type DialogMachine = ReturnType<typeof createDialogMachine>;

export type DialogMachineEvents =
    | {
        type: 'SET_CONFIG';
        title?: string;
        message?: string;
        acceptLabel?: string;
        cancelLabel?: string;
    }
    | { type: 'CANCEL' }
    | { type: 'CONFIRM' }
    | { type: 'SET_VARIABLES', variables: { id?: number } }
    | { type: 'SET_IS_OPEN'; isOpen: boolean }


export type DialogMachineContext = {
    title?: string;
    message?: string;
    acceptLabel?: string;
    cancelLabel?: string;
    isOpen?: boolean;
    variables?: { id?: number };
};

export const context = {
    title: 'Änderungen verwerfen!',
    message: 'Möchten Sie mit dem Löschen fortfahren?',
    acceptLabel: 'Ja',
    cancelLabel: 'Nein',
    isOpen: false,
    variables: {},
};


export async function confirm({
    variables: { },
    refetchQueries = [],
}): Promise<Record<string, any>> {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    return {
        data: {
            item: {
                item: {},
                error: null,
            },
        },
    }
}

export const createDialogMachine = () =>
    createMachine(
        {
            id: 'dialog',
            predictableActionArguments: true,
            tsTypes: {} as import("./dialogMachine.typegen").Typegen0,
            schema: {
                context: {} as DialogMachineContext,
                events: {} as DialogMachineEvents,
            },
            context,
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SET_CONFIG: { actions: 'setConfig' },
                        CANCEL: { actions: 'closeDialog' },
                        CONFIRM: 'confirm',
                        SET_IS_OPEN: { actions: 'setIsOpen' },
                        SET_VARIABLES: { actions: 'setVariables' }
                    },
                },
                confirm: {
                    entry: 'onConfirmEntry',
                    exit: 'onConfirmExit',
                    invoke: {
                        id: 'confirm',
                        src: 'onConfirm',
                        onDone: {
                            target: 'idle',
                            actions: 'closeDialog'
                        }
                    }
                }
            },
        },
        {
            services: {
                onConfirm: async (ctx, event) => {
                    log.debug('invoke.onConfirm', ctx, event);
                    return await confirm({ variables: {} })
                }
            },
            actions: {
                closeDialog: assign({ isOpen: false }),
                onConfirmEntry: (ctx, event) => log.debug('dialog.onConfirmEntry'),
                onConfirmExit: (ctx, event) => log.debug('dialog.onConfirmExit'),
                setVariables: assign((ctx, { variables }) => ({ variables, isOpen: true })),
                setIsOpen: assign((ctx, { isOpen }) => ({ isOpen })),
                setConfig: assign((ctx, { title, message, acceptLabel, cancelLabel }) =>
                ({
                    title: title || ctx?.title,
                    message: message || ctx?.message,
                    acceptLabel: acceptLabel || ctx.acceptLabel,
                    cancelLabel: cancelLabel || ctx.cancelLabel
                }))
            },
        }
    );
