import React from 'react';
import {
    Input,
    InputGroup,
    InputGroupProps,
    InputRightAddon,
    chakra,
    useMergeRefs,
} from '@chakra-ui/react';

interface UploadProps extends InputGroupProps {
    name?: string;
    value?: any;
    onChange?: (p?: any) => void;
    placeholder?: string;
}

export const Upload = React.forwardRef((props: UploadProps, ref) => {
    const { name, onChange, value: controlledValue, placeholder } = props;
    const [value, setValue] = React.useState(controlledValue);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const mergeRef = useMergeRefs(ref, inputRef);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (file) {
            setValue(file);
            onChange?.(file);
        }
    };

    const open = () => inputRef?.current?.click();

    return (
        <InputGroup>
            <chakra.input
                name={name}
                ref={mergeRef}
                onChange={handleChange}
                type="file"
                display="none"
            />
            <Input
                key={value?.name || ''}
                defaultValue={value?.name}
                onClick={open}
                placeholder={value?.name || placeholder || 'keine Datei ausgewählt'}
            />
            <InputRightAddon cursor="pointer" onClick={open}>
                Datei auswählen
            </InputRightAddon>
        </InputGroup>
    );
});
