import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GridItem, Progress, SimpleGrid, VStack } from '@chakra-ui/react';
import { DataTable, Card, CardHeader, CardActions, CardContent, CardHeading, PlusButton } from '@ucc/react/ui';
import { ListDocumentsDocument } from '~/gql/ucpw/graphql';
import { combineReducers, formStateReducer, modalReducer, rowReducer } from '~/reducers';
import { DocumentsProjectSearchModal } from '~/pages/projects/ui/DocumentsProjectSearchModal';
import { DownloadPowerConsumption } from '~/pages/projects/components/DownloadPowerConsumption';
import { useOperationalReports } from '~/pages/projects/hooks/useOperationalReports';
import { CoverLetterModal } from '~/pages/projects/ui/CoverLetterModal';
import { Download } from '~/pages/projects/components/Download';
import { useDocuments } from '~/pages/projects/hooks/useDocuments';
import { DocumentsModal } from '~/pages/projects/ui/DocumentsModal';
import { usePdf } from '~/pages/projects/hooks/usePdf';
import { MultiModal } from '~/components/MultiModal';
import { useModal } from '~/hooks';
import { client } from '~/apollo';
import { HasPermission } from '~/layout/HasPermission';

export const Modals = {
    Documents: 'Documents',
    ProjectSearch: 'ProjectSearch',
    CreateCoverLetter: 'CoverLetter',
};

export function Documents() {
    const navigate = useNavigate();
    const params = useParams();
    const projectId = parseInt(params?.projectId || '', 10);
    const subprojectId = parseInt(params?.subprojectId || '', 10);
    const { details, document } = useOperationalReports({ projectId, subprojectId });
    const [templateIsLoading, setTemplateIsLoading] = React.useState<string>('');
    const blurHandler = () => setTemplateIsLoading('');
    const { loading, ...pdf }: any = usePdf({ projectId, subprojectId }, blurHandler);
    const projectType = pdf?.subproject?.projekttyp?.name;
    const { onCreate, ...documents } = useDocuments({ projectId, subprojectId });

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            window.addEventListener('blur', blurHandler);
        }
        return () => {
            window.removeEventListener('blur', blurHandler);
            isMounted = false;
        };
    }, []);

    const { dispatch, onOpen } = useModal({
        defaultState: { modals: { activeModal: 'DocumentsModal' } },
        component: (
            <MultiModal>
                <DocumentsModal id="Documents" />
                <DocumentsProjectSearchModal id="ProjectSearch" />
                <CoverLetterModal id="CoverLetter" />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('DocumentsModal'),
            formState: formStateReducer,
            row: rowReducer,
        }),
    });

    const titles = {
        coverSheet: 'Deckblatt',
        offer: 'Angebot',
        allowance: 'Aufmaß',
        invoice: 'Rechnung',
        assignment: 'Auftragserteilung',
        operationalReport: 'Einsatzbericht',
        customizedOperationalReport: 'Einsatzbericht VA/WHST',
        measurementLog: 'Messprotokoll',
        powerConsumptionNotice: 'Stromverbrauchsmitteilung',
        leakDetectionReport: 'Ortungsbericht',
    };

    const fetchRelatedDocuments = async (filter: any, limit = 50) => {
        const response = await client.query({
            query: ListDocumentsDocument,
            context: { clientName: 'ucpw' },
            fetchPolicy: 'network-only',
            variables: { filter, limit },
        });
        const documents = response?.data?.items?.items || [];
        return documents;
    };

    const getMultipleDownloadProps = React.useCallback(
        (template: any) => {
            const title = titles?.[template as keyof typeof titles];

            const keyMap = {
                leakDetectionReport: {
                    property: 'leakDetectionReports',
                    endpoint: 'einsatzberichte?edit=new',
                    label: 'LO',
                    getFilter: (einsatzberichtId: number) => ({ einsatzberichtId }),
                },
                offer: {
                    property: 'offers',
                    endpoint: 'kalkulation?edit=new&type=offer',
                    label: 'Angebot',
                    getFilter: (angebotId: number) => ({ angebotId }),
                },
                invoice: {
                    property: 'invoices',
                    endpoint: 'kalkulation?edit=new&type=invoice',
                    label: 'Rechnung',
                    getFilter: (rechnungId: number) => ({ rechnungId }),
                },
            };
            const config = keyMap?.[template as keyof typeof keyMap];
            const data = pdf?.[config?.property] || [];
            const hasData = Boolean(data.length);
            const isReport = template === 'leakDetectionReport';

            const getPdfProps = (data: any) =>
                isReport ? { report: data, details, documentRaw: document } : data;

            const setInProgressPositions = (data: any) => {
                switch (data?.__typename) {
                    case 'Angebot':
                        return {
                            ...(data?.statusSnippet?.name === 'in Bearbeitung' && {
                                angebotspositionen: pdf.calculationPositions,
                            }),
                        };
                    case 'Rechnung':
                        return {
                            ...(data?.statusSnippet?.name === 'Offen' && {
                                rechnungspositionen: pdf.calculationPositions,
                            }),
                        };
                    default:
                        return {};
                }
            };

            return Object.keys(keyMap).includes(template)
                ? {
                    ...(!hasData && { variant: 'create' }),
                    ...(data.length > 1 && {
                        menuItems: data.map((item: any) => ({
                            key: item.id,
                            label: [config?.label, item.nummer || item.datum]
                                .filter(Boolean)
                                .join(' '),
                            onClick: async () => {
                                const dokument = await fetchRelatedDocuments(
                                    config?.getFilter(item?.id)
                                );
                                setTemplateIsLoading(title);
                                pdf?.[template]?.(
                                    getPdfProps({
                                        ...item,
                                        dokument,
                                        ...setInProgressPositions(item),
                                    })
                                );
                            },
                        })),
                    }),
                    onClick: async () => {
                        if (!hasData) {
                            return navigate(
                                `/projekte/${projectId}/${subprojectId}/${config?.endpoint}`
                            );
                        }

                        if (data.length === 1) {
                            const dokument = await fetchRelatedDocuments(
                                config?.getFilter(data?.[0]?.id)
                            );
                            setTemplateIsLoading(title);
                            pdf?.[template]?.(
                                getPdfProps({
                                    ...data?.[0],
                                    dokument,
                                    ...setInProgressPositions(data?.[0]),
                                })
                            );
                        }
                    },
                }
                : {};
        },
        [pdf, details, document, fetchRelatedDocuments]
    );

    const getDownloadProps = React.useCallback(
        (template: keyof typeof titles) => {
            const title = titles?.[template as keyof typeof titles];
            const isMatching = templateIsLoading === title;
            return {
                title,
                isLoading: isMatching,
                isDisabled: isMatching || loading,
                onClick: () => {
                    setTemplateIsLoading(title);
                    pdf?.[template]?.();
                },
                ...getMultipleDownloadProps(template),
                'data-test-id': `button-${template}`,
            };
        },
        [templateIsLoading, pdf, details, document, getMultipleDownloadProps, loading]
    );

    return (
        <SimpleGrid columns={3} spacing={5} p={4} w="full">
            <GridItem colSpan={{ base: 3, md: 2 }}>
                <Card boxShadow="none">
                    <CardHeader>
                        <CardHeading>Dokumente</CardHeading>
                        <CardActions>
                            <HasPermission resource="project.documents" permission="create">
                                <PlusButton data-test-id="button-new-document" onClick={onCreate}>
                                    Neues Dokument
                                </PlusButton>
                            </HasPermission>
                        </CardActions>
                    </CardHeader>
                    <CardContent>
                        <DataTable
                            columns={documents.columns}
                            hiddenColumns={documents.hiddenColumns}
                            data={documents.data}
                            pageSize={documents.pageSize}
                            loading={documents.loading}
                        />
                    </CardContent>
                </Card>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 1 }}>
                <Card>
                    <CardHeader borderBottomWidth={1} borderColor="gray.200">
                        <CardHeading>Vorlagen</CardHeading>
                    </CardHeader>
                    <VStack align="stretch" spacing={0}>
                        {loading && <Progress size="xs" colorScheme="cyan" isIndeterminate w="full" />}
                        <Download {...getDownloadProps('coverSheet')} />
                        <Download {...getDownloadProps('offer')} />
                        <Download
                            isDisabled={loading}
                            title="Anschreiben"
                            variant="create"
                            onClick={() => {
                                dispatch?.({
                                    type: 'setModal',
                                    data: {
                                        modal: Modals.CreateCoverLetter,
                                        props: {
                                            pdf,
                                            subprojectId,
                                            createDocumentFromSignedUrl:
                                                documents?.createDocumentFromSignedUrl,
                                        },
                                    },
                                });
                                onOpen?.();
                            }}
                        />
                        <Download {...getDownloadProps('allowance')} />
                        <Download {...getDownloadProps('invoice')} />
                        <Download {...getDownloadProps('assignment')} />
                        <Download {...getDownloadProps('operationalReport')} />
                        {['Vorarbeiten', 'Nacharbeiten'].includes(projectType) && <Download {...getDownloadProps('customizedOperationalReport')} />}
                        {'Leckortung' === projectType && (
                            <Download {...getDownloadProps('leakDetectionReport')} />
                        )}
                        <Download {...getDownloadProps('measurementLog')} />
                        <DownloadPowerConsumption
                            onClick={pdf.powerConsumptionNotice}
                            meterReadings={pdf.meterReadings}
                            title="Stromverbrauchsmitteilung"
                            isDisabled={loading}
                            isLoading={'Stromverbrauchsmitteilung' === templateIsLoading}
                        />
                    </VStack>
                </Card>
            </GridItem>
        </SimpleGrid>
    );
}
