import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { Input, InputProps, InputGroup, InputRightElement } from '@chakra-ui/react';

export const DatePicker = React.forwardRef(
    (props: InputProps, ref: any) => {
        return (
            <InputGroup
                onFocus={(e) => e.target.focus()}
                sx={{
                    'input[type=date]::-webkit-calendar-picker-indicator': {
                        background: 'transparent',
                        color: 'transparent',
                        position: 'absolute',
                        cursor: 'pointer',
                        height: 'auto',
                        width: 'auto',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        top: 0,
                    },
                    'input[type=datetime-local]::-webkit-calendar-picker-indicator': {
                        background: 'transparent',
                        color: 'transparent',
                        position: 'absolute',
                        cursor: 'pointer',
                        height: 'auto',
                        width: 'auto',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        top: 0,
                    },
                }}
            >
                {/** TODO: get rid off safari prefilled today eg: 28/03/2022 */}
                <Input ref={ref} type="date" {...props} />
                <InputRightElement zIndex="0">
                    <AiOutlineCalendar fontSize={20} />
                </InputRightElement>
            </InputGroup>
        );
    }
);
