import { isEqual } from 'lodash';
import { useState } from 'react';
import { unflatten } from 'flat';

export function useOrderBy() {
    const [orderBy, setOrderBy] = useState([]);

    const onSortChange = (args: any) => {
        const newOrderBy = getOrderBy(args);
        !isEqual(orderBy, newOrderBy) && setOrderBy(newOrderBy);
    };

    return [orderBy, onSortChange];
}

export function getOrderBy(args: any, defaultValue?: any[]) {
    return args?.sortBy && args?.sortBy.length
        ? args?.sortBy?.map((item: any) =>
              unflatten({
                  [item.id]: item.desc ? 'desc' : 'asc',
              })
          )
        : defaultValue;
}
