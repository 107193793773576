import { join, generateProjectNumber, getClient } from '~/utils';

export function useEventProjectdetails(subproject: any) {
    return resolveProjectDetails(subproject)
}

export function resolveProjectDetails(subproject: any) {
    if (subproject) {
        const { anlageDatum, niederlassung, lfdNr, strasse, plz, ort } = subproject?.projekt || {}
        const { firma1, firma2, name, vorname } = getClient(subproject?.beteiligte) || {};
        return {
            subprojekt: {
                id: subproject?.id,
            },
            client: join([firma1, firma2, name, vorname]),
            object: join([strasse, join([plz, ort], ' ')]),
            subprojectId:
                generateProjectNumber(
                    anlageDatum,
                    niederlassung?.nummer,
                    lfdNr,
                    subproject?.lfdNr
                )
            ,
        }
    }
    return {}
}
