import { DataTable, Card, CardContent, PlusButton } from '@ucc/react/ui';
import { useOperationalReports } from '~/pages/projects/hooks/useOperationalReports';
import { ProjectIds } from '~/pages/projects/types';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { HasPermission } from '~/layout/HasPermission';
import { OverrideActions } from '~/components/Layout/PageHeader';
import log from '~/log';

export const OperationalReportList = ({ projectId, subprojectId }: ProjectIds) => {
    const reports = useOperationalReports({ projectId, subprojectId });
    const { updateEditable } = useEditable();
    log.debug('OperationalReportList', reports);

    return (
        <>
            <OverrideActions>
                <HasPermission resource="project.report" permission="create">
                    <PlusButton onClick={() => updateEditable('new')}>Neuer Bericht</PlusButton>
                </HasPermission>
            </OverrideActions>
            <Card boxShadow="none">
                <CardContent>
                    <DataTable
                        columns={reports.columns}
                        hiddenColumns={reports.hiddenColumns}
                        data={reports.data}
                        loading={reports.isReadLoading}
                    />
                </CardContent>
            </Card>
        </>
    );
};
