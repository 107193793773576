import {
    Input,
    InputGroup,
    InputProps,
    InputLeftElement,
    InputRightElement,
    useColorModeValue as mode,
} from '@chakra-ui/react';
import { BsSearch } from 'react-icons/bs';

export const SearchField = ({ w, ...props }: InputProps) => {
    return (
        <InputGroup size="md" w={w}>
            <InputLeftElement pointerEvents="none">
                <BsSearch opacity={0.5} />
            </InputLeftElement>
            <InputRightElement></InputRightElement>
            <Input
                type="search"
                rounded="md"
                placeholder="Search"
                bg={mode('white', 'gray.900')}
                _placeholder={{
                    opacity: 1,
                    color: mode('gray.400', 'gray.500'),
                }}
                {...props}
            />
        </InputGroup>
    );
};