import React from 'react';
import { isEqual } from 'lodash';
import { unflatten } from 'flat';

export function useDataLoader(loadData: any, controlledVariables: any = {}) {
    const [variables, setVariables] = React.useState({});
    const [filter, setFilter] = React.useState(() => ({}));
    const fetchIdRef = React.useRef(0);
    const variablesRef = React.useRef<{
        limit?: number;
        offset?: number;
        orderBy?: any[];
        filter?: Record<string, any>;
    }>({});
    const fetchData = React.useCallback(
        ({ pageSize = 25, pageIndex = 0, sortBy = [], filter = {}, ...args }) => {
            const fetchId = ++fetchIdRef.current;

            const orderBy = sortBy?.map((item: any) => {
                return unflatten({
                    [item?.id]: item?.desc ? 'desc' : 'asc',
                });
            });

            const variables = {
                limit: pageSize,
                offset: pageSize * pageIndex,
                orderBy: orderBy.length ? orderBy : controlledVariables?.orderBy || [],
                filter,
                ...args,
            };

            if (fetchId === fetchIdRef.current && !isEqual(variablesRef.current, variables)) {
                loadData({
                    variables: {
                        ...variables,
                        ...(!isEqual(variablesRef.current?.filter, filter) && { offset: 0 }),
                    },
                });
                setVariables(variables);
            }
            variablesRef.current = variables;

            return variables;
        },
        [filter, loadData, variablesRef.current, fetchIdRef.current]
    );

    return { fetchData, setFilter, variables };
}
