import { format } from 'date-fns';
import { tableColumnMapper } from '~/utils/tableColumnMapper';

// Tables
const columns = {
    openDocuments: {
        __graphqlType: 'DocumentsQuery',
        select: {
            header: '',
            accessor: 'select',
            disableSortBy: true,
            width: 15,
            renderer: { name: 'OpenDocumentSelect' },
        },
        timestamp: {
            header: 'Timestamp',
            accessor: 'timestamp',
            width: 100,
            disableSortBy: true,
            cell: (info: any) => {
                const value = info.getValue();
                return value ? format(new Date(value), 'dd.MM.yyyy HH:mm:ss') : null;
            },
        },
        document: {
            header: 'Dokument',
            accessor: 'metadata.filename',
            renderer: {
                name: 'DownloadLink',
                props: {
                    getPath: (row: any, value: string) => row?.original?.metadata?.docLocation?.key,
                },
            },
            disableSortBy: true,
        },
        projectNumber: { header: 'Projekt Nr.', accessor: 'projectNumber', disableSortBy: true },
        status: { header: 'Status', accessor: 'status', width: 60, disableSortBy: true },
        controls: {
            accessor: 'controls',
            width: 60,
            renderer: { name: 'Controls' },
            disableSortBy: true,
        },
    },
};

export const tables = {
    openDocuments: {
        columns: ['select', 'document', 'timestamp', 'projectNumber', 'status', 'controls'].map(
            tableColumnMapper(columns.openDocuments)
        ),
    },
};
