import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { omit, isEqual } from 'lodash';
import * as yup from 'yup';
import { Button, Container, HStack, SimpleGrid, VStack, useToast } from '@chakra-ui/react';
import { Card } from '@ucc/react/ui';
import { Form, renderField } from '~/components/Form/Form';
import { SectionCard } from '~/components/SectionCard';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { fields, forms } from '~/pages/projects/meta/data/finance.schema';
import { resolveFormFields } from '~/utils';
import { ProjectIds } from '~/pages/projects/types';
import { RechnungInput } from '~/gql/ucpw/graphql';
import { FinanceCalculation } from './FinanceCalculation';
import { OPEN_INVOICE_STATUS_KEY } from './constants';
import { FinanceContext } from './financeMachine';
import { useFinanceInvoiceCalculation } from '../../hooks/useFinanceInvoiceCalculation';
import { OverrideActions } from '~/components/Layout/PageHeader';
import { usePermission } from '~/hooks';
import log from '~/log';

type FinanceInvoiceFormProps = ProjectIds & { generalAgreementIdIsStandard?: boolean, context?: FinanceContext, send?: (args?: any) => void, loading?: boolean, viewer?: any, }

export const FinanceInvoiceForm = ({ projectId, subprojectId, send, loading = false, viewer, ...props }: FinanceInvoiceFormProps) => {
    const permissions = usePermission('finance.invoice')
    const releaseInvoiceEnabled = viewer?.hasPermission('additional.releaseInvoice', 'enabled')
    console.log('usePermission', permissions)
    const { canEdit, canCreate, canView } = permissions

    const toast = useToast()
    const { cache = {}, branchId, scopes } = props?.context || {}
    const { updateEditable, editBase }: any = useEditable();
    const offerId = parseInt(editBase);
    const { invoiceId, invoice = {}, hasRechnungspositionenAndIsOpen } = cache;
    const relatedOffer = scopes?.[offerId] || {}
    const offer = relatedOffer?.__typename === 'Angebot' ? relatedOffer : {};
    const formFields = resolveFormFields(forms.invoice, fields.invoice);

    const { mwstSatz, rabatt, freitext, freitextFooter } = offer || {};
    const vatRate = offer?.mwstSatz === 0 || invoice?.mwstSatz === 0 ? { vatRate: { value: 0, label: '0%' } } : {}
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm({ mwstSatz, rabatt, freitext, freitextFooter }),
        ...formFields.toForm(invoice),
        ...vatRate,
        subprojectId,
    };
    const defaultValuesRef = React.useRef(defaultValues);
    const canSave = invoiceId && canEdit ? true : !invoiceId && canCreate ? true : false

    const { control, register, handleSubmit, formState: { errors }, reset, watch } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });


    const { statusSnippet } = watch();
    const isOpenInvoice = !invoiceId || invoice?.statusSnippet?.kuerzel === OPEN_INVOICE_STATUS_KEY;

    const isOpenStatus = statusSnippet?.key === OPEN_INVOICE_STATUS_KEY;


    const context = {
        subprojectId,
        isReadLoading: false,
        isOpenInvoice,
        invoiceId,
        branchId,
        releaseInvoiceEnabled // "Rechnung freigeben" only with enabled additional.releaseInvoice and role "Sachbearbeiter"
    };

    React.useEffect(() => {
        log.debug('FinanceInvoiceForm.reset', defaultValues);
        reset(defaultValues);
    }, [subprojectId, invoice?.id, editBase, offer?.id]);

    React.useEffect(() => {
        if (hasRechnungspositionenAndIsOpen) {
            toast({
                title: 'Rechnung aus Angebot.',
                description: "Die Rechnungspositionen können von den allgemeinen Kalkulationspositionen abweichen.",
                status: 'info',
                position: 'bottom-right',
                variant: 'left-accent',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [hasRechnungspositionenAndIsOpen])

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (values) => {
            log.debug('onSubmit.values', values);
            const vatRate = values?.vatRate?.value === 0 ? { mwstSatz: 0.0 } : {}
            const fixedValues = { subprojectId: subprojectId };
            log.debug('onSubmit.defaultValues', defaultValues);
            log.debug('onSubmit.fixedValues', fixedValues);
            const data = omit(
                formFields.toGql(values, defaultValues, fixedValues),
                'attachmentId',
                'dokument',
                'receiptDate'
            ) as RechnungInput;
            log.debug('onSubmit.data', data);

            invoiceId ?
                send?.({ type: 'SUBMIT', variables: { id: invoiceId, data: { ...data, ...vatRate, nummer: invoiceId.toString() } } }) :
                send?.({ type: 'SUBMIT', offerId, variables: { data: { ...data, ...vatRate, nummer: ' ' } } })

            updateEditable();
        },
        [subprojectId, invoiceId]
    );

    React.useEffect(() => {
        if (!isEqual(defaultValues, defaultValuesRef.current)) {
            reset(defaultValues)
            defaultValuesRef.current = defaultValues
        }
    }, [defaultValues, defaultValuesRef.current])

    const fixed = !isOpenStatus || !canSave

    const { columns = [], hiddenColumns = [] } = useFinanceInvoiceCalculation({ send, fixed: canView ? !canView : fixed, permissions: { ...permissions, canDelete: canSave } });

    return (
        <>
            <OverrideActions>
                <HStack>
                    <Button
                        size="sm"
                        isDisabled={loading}
                        data-test-id={`cancel`}
                        onClick={() => {
                            send?.('IDLE')
                            updateEditable()
                        }}>
                        {canSave ? 'Abbrechen' : 'Schließen'}
                    </Button>
                    {canSave && <Button
                        data-test-id={`save`}
                        colorScheme="blue"
                        size="sm"
                        onClick={handleSubmit(onSubmit)}
                        isDisabled={loading || viewer?.loading}
                        isLoading={loading || viewer?.loading}
                    >
                        Speichern
                    </Button>}
                </HStack>
            </OverrideActions>
            <Container maxW="container.lg" p={3}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <VStack alignItems="center" spacing={14}>
                        <SectionCard title="Rechnungsstammdaten">
                            <SimpleGrid spacing={4} columns={2} mb={4}>
                                {formFields.fields
                                    .filter(
                                        (field: any) => !['description', 'footer'].includes(field.name)
                                    )
                                    .map((field: any) => (
                                        <React.Fragment key={field.name}>
                                            {renderField({
                                                field,
                                                control,
                                                register,
                                                errors,
                                                context,
                                            })}
                                        </React.Fragment>
                                    ))}
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard title="Kopftext">
                            {formFields.fields
                                .filter((field: any) => field.name === 'description')
                                .map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            context,
                                        })}
                                    </React.Fragment>
                                ))}
                        </SectionCard>
                        <SectionCard title="Fußtext">
                            {formFields.fields
                                .filter((field: any) => field.name === 'footer')
                                .map((field: any) => (
                                    <React.Fragment key={field.name}>
                                        {renderField({
                                            field,
                                            control,
                                            register,
                                            errors,
                                            context,
                                        })}
                                    </React.Fragment>
                                ))}
                        </SectionCard>
                    </VStack>
                </Form>
            </Container>
            <Card boxShadow="none">
                <FinanceCalculation
                    context={{ ...props?.context, ...context }}
                    fixed={fixed}
                    columns={columns}
                    hiddenColumns={hiddenColumns as string[]}
                />
            </Card>
        </>
    );
};
