import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteProjectDocument, GetProjectDocument, Projekt } from '~/gql/ucpw/graphql';
import { generateProjectNumber, withRenderers } from '~/utils';
import { useContextAndRefetchQueries, useDeleteWithConfirmation, usePermission } from '~/hooks';
import { tables } from '../meta/data/projects.schema';
import { useRedirectToProjectSearch } from './useRedirectToProjectSearch';

export const useProject = ({ projectId }: any) => {
    const { canEdit, canCreate, canDelete } = usePermission('project.project');
    const onCompleted = useRedirectToProjectSearch();
    const navigate = useNavigate();
    const variables = { id: projectId };

    // ===============================================
    // GET Project
    // ===============================================

    const contextAndRefetchQueries = useContextAndRefetchQueries(GetProjectDocument, variables);
    const { data: projectData, loading: getLoading } = useQuery(GetProjectDocument, {
        variables,
        context: contextAndRefetchQueries.context,
        skip: !projectId,
        onCompleted,
    });
    const project = (projectData?.item?.item || {}) as Projekt;

    // ===============================================
    // DELETE Project
    // ===============================================

    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteProjectDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries],
    });

    // ===============================================
    // TITLE
    // ===============================================

    const title = project?.id
        ? `Projekt - ${generateProjectNumber(
              project.anlageDatum,
              project?.niederlassung?.nummer as number,
              project?.lfdNr,
              project?.subprojekt?.[0]?.lfdNr as number
          )}`
        : 'Projekt';

    // ===============================================
    // TABLE
    // ===============================================

    const controls = React.useMemo(
        () =>
            [
                {
                    title: 'Bearbeiten',
                    props: {
                        onClick: (row: any) =>
                            navigate(
                                `/projekt/${row?.original?.id}/${project?.subprojekt?.[0]?.id}/bearbeiten`,
                                {
                                    state: 'EDIT_PROJECT',
                                }
                            ),
                    },
                    enabled: () => canEdit,
                },
                {
                    title: 'Kopieren',
                    props: {
                        onClick: (row: any) =>
                            navigate(`/projekt/${row?.original?.id}/kopieren`, {
                                state: row?.original,
                            }),
                    },
                    enabled: () => canCreate,
                },
                {
                    title: 'divider',
                    enabled: () => canDelete && (canCreate || canEdit),
                },
                {
                    title: 'Löschen',
                    props: {
                        color: 'red.400',
                        onClick: (row: any) =>
                            deleteWithConfirmation(
                                {
                                    id: row?.original?.id,
                                    version: row?.original?.version,
                                    forceOverwrite: true,
                                },
                                { callAfter: () => navigate('/projekt/suchen') }
                            ),
                    },
                    enabled: () => canDelete,
                },
            ].filter(Boolean),
        [project]
    );

    const columns = React.useMemo(
        () => withRenderers(tables.subprojects.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.subprojects.hiddenColumns;

    return {
        data: project,
        title,
        loading: getLoading || deleteLoading,
        columns,
        hiddenColumns,
    };
};
