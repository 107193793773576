import { graphql } from '../gql';

export const DocumentUploadFragment = graphql(/*GraphQL */ `
    fragment DocumentUploadFragment on DocumentUpload {
        id
        timestamp
        status
        s3Object {
            key
            versionId
        }
        projectNumber
        metadata {
            filename
            docLocation {
                key
            }
        }
    }
`);

export const documentUploadsSubscription = graphql(/*GraphQL */ `
    subscription documentUpload {
        documents {
            ...DocumentUploadFragment
        }
    }
`);

export const deleteUpload = graphql(/*GraphQL */ `
    mutation deleteUpload($id: ID!) {
        documents {
            deleteUpload(id: $id)
        }
    }
`);

export const moveUploads = graphql(/*GraphQL */ `
    mutation moveUploads($ids: [ID!], $subprojectId: ID!) {
        documents {
            moveUploads(ids: $ids, subprojectId: $subprojectId)
        }
    }
`);

export const listUploads = graphql(/*GraphQL */ `
    query listUploads {
        documents {
            uploads {
                ...DocumentUploadFragment
            }
        }
    }
`);
