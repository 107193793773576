import log from 'loglevel';

// FIXME: Converting circular structure to JSON
const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

// interface LogLevel {
//     TRACE: 0;
//     DEBUG: 1;
//     INFO: 2;
//     WARN: 3;
//     ERROR: 4;
//     SILENT: 5;
// }
const logLevel = (window as any).LOG_LEVEL || localStorage.getItem('LOG_LEVEL') || 'DEBUG';

if (logLevel) {
    log.setLevel(logLevel);
}

console.log('LOG_LEVEL', log.getLevel());

if (localStorage.getItem('LOG_STRINGIFY') === 'true') {
    const originalFactory = log.methodFactory;
    log.methodFactory = function (methodName, logLevel, loggerName) {
        const rawMethod = originalFactory(methodName, logLevel, loggerName);

        return function (message, ...optionalParams) {
            const params = optionalParams.map((param) =>
                typeof param === 'object' ? JSON.stringify(param, getCircularReplacer(), 2) : param
            );
            rawMethod(message, ...params);
        };
    };
    log.setLevel(log.getLevel());
}

export { default } from 'loglevel';
