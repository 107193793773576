import { VStack, Checkbox, SimpleGrid, useBoolean } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { DataTable, Card, CardActions, CardContent, CardHeader } from '@ucc/react/ui';
import { Form } from '~/components/Form/Form';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { useSecurity } from '@ucc/react/security';
import { useOpenTasks } from '../hooks/useOpenTasks';
import { selects } from '~/meta/data/selects.gql';
import { useModal } from '~/hooks';
import { TaskModal } from './Task/TaskModal';
import { PageHeader } from '~/components/Layout/PageHeader';


export function OpenTasks({ title }: { title: string }) {
    useModal(<TaskModal />);
    const { viewer } = useSecurity();
    const [ownTasks, setOwnTasks] = useBoolean();

    const { control, watch } = useForm();

    const { branch, role, taskFunction } = watch();
    const branchId = branch?.value;
    const roleId = role?.value;
    const functionId = taskFunction?.value
    const employeeId = viewer?.app?.mitarbeiterId;

    const isOwnTasks = employeeId && ownTasks;

    const filter = {
        erledigtExists: false,
        zuErledigenNotInFuture: true,
        ...(isOwnTasks && { mitarbeiterIdSachbearbeiter: employeeId }),
        ...(functionId && { funktionId: functionId }),
        ...(roleId && { rolleId: roleId }),
        ...(branchId && {
            subprojekt: {
                projekt: { niederlassungId: branchId },
            },
        }),
    }

    const dataTableProps = useOpenTasks({ filter })

    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
            <PageHeader.Actions>
                <Form>
                    <SimpleGrid columns={2} spacing={6} w="450px">
                        <QuerySelect
                            control={control}
                            isClearable
                            name="branch"
                            placeholder="Niederlassung"
                            query={selects.branches}
                            mapOptions={(item) => ({
                                value: item?.value,
                                label: `${item?.nummer} - ${item?.bezeichnung}`,
                            })}
                        />
                        <QuerySelect
                            control={control}
                            isClearable
                            name="taskFunction"
                            placeholder="Funktion"
                            query={selects.functions}
                        />
                        {/* <QuerySelect
                        control={control}
                        isClearable
                        name="role"
                        placeholder="Rolle"
                        query={selects.function}
                    /> */}
                    </SimpleGrid>
                </Form>
            </PageHeader.Actions>
        </PageHeader>
        <VStack p={6} align="flex-start">
            <Card boxShadow="none">
                <CardHeader>
                    <CardActions>
                        <Checkbox isChecked={ownTasks} onChange={setOwnTasks.toggle}>
                            Meine Aufgaben
                        </Checkbox>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </VStack>
    </>;
}
