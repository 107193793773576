import { ListSnippetDocument, SnippetFilterInput, Snippet } from '~/gql/ucpw/graphql';
import { useQuery } from '@apollo/client';

type Props = {
    name: string;
    category?: string;
    property?: string;
    short?: string;
};

export function useSnippetId({ name, category, property, ...props }: Props & SnippetFilterInput) {
    const { data } = useQuery(ListSnippetDocument, {
        variables: {
            filter: {
                ...(name && { namePrefix: name }),
                ...(category && { kategoriePrefix: category }),
                ...(property && { eigenschaftPrefix: property }),
                ...props,
            },
        },
        context: { clientName: 'ucpw' },
        fetchPolicy: 'network-only',
    });
    const snippetMatch = data?.items?.items?.find?.(
        (snippet: any) => snippet?.name === name
    ) as Snippet;
    const typSnippetId = snippetMatch?.id || 0;

    return { typSnippetId, data: data?.items?.items || [] };
}
