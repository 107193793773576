import { DownloadButton } from '~/components/DownloadButton';
import { VacantPositionCsvExportDocument } from "~/gql/ucpw/graphql";
import { useDownload } from '~/hooks/useDownload';
import { ControllingStates } from '~/pages/master-data/types';
import { useQuery } from '@apollo/client';

export const CsvDownload = ({
    branchId,
    employeeId,
    only28DaysAndOlder,
    fileName = 'offene_posten',
}: ControllingStates & { fileName?: string }) => {
    const { data, loading } = useQuery(VacantPositionCsvExportDocument, {
        variables: {
            niederlassungId: branchId as number,
            mitarbeiterId: employeeId,
            only28DaysAndOlder,
        },
        context: { clientName: 'ucpw' },
    });
    const csv = data?.app?.item?.item || '';

    const onClick = useDownload({
        fileName,
        data: csv,
    });

    return (
        <DownloadButton
            colorScheme="gray"
            isLoading={loading}
            isDisabled={!Boolean(csv)}
            onClick={onClick}
        />
    );
};
