import {
    DataTable,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    CardHeading,
    PlusButton,
} from '@ucc/react/ui';
import { usePermission, useViewer } from '~/hooks';
import { HasPermission } from '~/layout/HasPermission';
import { GoToScheduleButton } from '~/pages/projects/components/GoToScheduleButton';
import { useCorrespondence } from '../hooks/useCorrespondence';

export function Correspondence({ disableScheduleButton, ...props }: any) {
    const { onCreate, ...dataTableProps } = useCorrespondence({ subprojectId: props.subprojectId });
    const viewer = useViewer();
    const hasEditAllCorrespondence = viewer.hasPermission(
        'additional.editAllCorrespondence',
        'enabled'
    );
    const { canCreate } = usePermission('project.correspondence');
    const canEditCorrespondence = canCreate || hasEditAllCorrespondence;

    return (
        <HasPermission resource="project.correspondence" permission="view">
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>Korrespondenz</CardHeading>
                    <CardActions>
                        <HasPermission resource='project.schedule' permission='view'>
                            <GoToScheduleButton state={props} isDisabled={disableScheduleButton} />
                        </HasPermission>
                        {canEditCorrespondence && (
                            <PlusButton onClick={onCreate}>Neue Korrespondenz</PlusButton>
                        )}
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </HasPermission>
    );
}
