import { orderBy, max, pick } from 'lodash';
import { assign, createMachine } from 'xstate';
import {
    Angebot,
    Rechnung,
    Kalkulationsposition,
    Subprojekt,
    Angebotsposition,
    Rechnungsposition,
} from '~/gql/ucpw/graphql';
import { join, getClient } from '~/utils';
import { OPEN_INVOICE_STATUS_KEY, VALID_OFFER_STATUS_KEY } from './constants';
import { faker } from '@faker-js/faker';

type RechnungOrAngebot = Rechnung | Angebot;

export type FinanceContext = {
    projectId?: number;
    subprojectId?: number;
    offers?: Angebot[];
    invoices?: Rechnung[];
    financeList?: RechnungOrAngebot[];
    generalAgreementId?: number;
    client?: any;
    calculations?: {
        byAgreement?: Record<string, any>;
        generalAgreementPositionIds?: number[];
        highestPosition?: number | undefined;
    };
    scopeId?: number | undefined;
    branchId?: number;
    scopes?: Record<string, any>;
    cache?: Record<string, any>;
    calculationpositions?: Kalkulationsposition[];
    editType?: string | null;
    isOffer?: boolean;
    isInvoice?: boolean;
    subproject?: Subprojekt;
    isStandard?: number;
    generalAgreementIdIsStandard?: boolean;
    totalPriceNet?: number;
    type?: 'calculation' | 'offer' | 'invoice';
    created?: any[];
    updated?: any[];
    deleted?: any[];
    __typename?: 'Angebot' | 'Rechnung' | undefined;
};

export const context: FinanceContext = {
    offers: [],
    invoices: [],
    financeList: [],
    calculationpositions: [],
    generalAgreementIdIsStandard: false,
    totalPriceNet: 0,
    type: 'calculation',
    scopes: {},
    __typename: undefined,
    created: [],
    updated: [],
    deleted: [],
    calculations: {
        generalAgreementPositionIds: [],
        byAgreement: {},
    },
    cache: {},
};

export type FinancePosition = Kalkulationsposition | Angebotsposition | Rechnungsposition;

export const financeMachine = createMachine<FinanceContext>(
    {
        id: 'finance',
        initial: 'init',
        context,
        states: {
            init: {
                type: 'parallel',
                onDone: 'idle',
                states: {
                    subprojectFinance: {
                        initial: 'getSubproject',
                        states: {
                            getSubproject: {
                                invoke: {
                                    src: 'getSubproject',
                                    onDone: {
                                        target: 'getCalculationpositions',
                                        actions: assign(
                                            ({ isStandard = 3716 }, { data: subproject = {} }) => {
                                                const client = getClient(subproject?.beteiligte);
                                                const clientDisplayName = join(
                                                    Object.values(
                                                        pick(
                                                            client,
                                                            'firma1',
                                                            'firma2',
                                                            'name',
                                                            'vorname'
                                                        )
                                                    )
                                                );
                                                return {
                                                    subproject,
                                                    branchId: subproject?.projekt?.niederlassungId,
                                                    generalAgreementIdIsStandard:
                                                        subproject?.rahmenvertragId === isStandard,
                                                    generalAgreementId: subproject?.rahmenvertragId,
                                                    client: clientDisplayName,
                                                };
                                            }
                                        ),
                                    },
                                },
                            },
                            getCalculationpositions: {
                                invoke: {
                                    src: 'getCalculationpositions',
                                    onDone: {
                                        target: 'getOffers',
                                        actions: [
                                            assign((ctx, { data: calculationpositions = [] }) => ({
                                                calculationpositions,
                                            })),
                                            'getCalculations',
                                        ],
                                    },
                                },
                            },
                            getOffers: {
                                invoke: {
                                    src: 'getOffers',
                                    onDone: {
                                        target: 'getInvoices',
                                        actions: assign(
                                            ({ scopes = {} }, { data: offers = [] }) => ({
                                                offers,
                                                scopes: {
                                                    ...scopes,
                                                    ...offers.reduce(
                                                        (acc: any, offer: any) => ({
                                                            ...acc,
                                                            [offer?.id]: offer,
                                                        }),
                                                        {}
                                                    ),
                                                },
                                            })
                                        ),
                                    },
                                },
                            },
                            getInvoices: {
                                invoke: {
                                    src: 'getInvoices',
                                    onDone: {
                                        target: 'success',
                                        actions: [
                                            assign(({ scopes = {} }, { data: invoices = [] }) => ({
                                                invoices,
                                                scopes: {
                                                    ...scopes,
                                                    ...invoices.reduce(
                                                        (acc: any, invoice: any) => ({
                                                            ...acc,
                                                            [invoice?.id]: invoice,
                                                        }),
                                                        {}
                                                    ),
                                                },
                                            })),
                                            'getFinanceList',
                                        ],
                                    },
                                },
                            },
                            success: {
                                type: 'final',
                            },
                        },
                    },
                },
            },
            getOffers: {
                invoke: {
                    src: 'getOffers',
                    onDone: {
                        target: 'getInvoices',
                        actions: assign(({ scopes = {} }, { data: offers = [] }) => ({
                            offers,
                            scopes: {
                                ...scopes,
                                ...offers.reduce(
                                    (acc: any, offer: any) => ({
                                        ...acc,
                                        [offer?.id]: offer,
                                    }),
                                    {}
                                ),
                            },
                        })),
                    },
                },
            },
            getInvoices: {
                invoke: {
                    src: 'getInvoices',
                    onDone: {
                        target: 'idle',
                        actions: [
                            assign(({ scopes = {} }, { data: invoices = [] }) => ({
                                invoices,
                                scopes: {
                                    ...scopes,
                                    ...invoices.reduce(
                                        (acc: any, invoice: any) => ({
                                            ...acc,
                                            [invoice?.id]: invoice,
                                        }),
                                        {}
                                    ),
                                },
                            })),
                            'getFinanceList',
                        ],
                    },
                },
            },
            getCalculationpositions: {
                invoke: {
                    src: 'getCalculationpositions',
                    onDone: {
                        target: 'getOffers',
                        actions: [
                            assign((ctx, { data: calculationpositions = [] }) => ({
                                calculationpositions,
                            })),
                            'getCalculations',
                        ],
                    },
                },
            },
            idle: {
                entry: assign({
                    type: 'calculation',
                    scopeId: undefined,
                    cache: { type: 'calculation' },
                }),
            },
            // NEW OFFER
            newOffer: {
                entry: assign((ctx) => {
                    const data = ctx.calculationpositions || [];
                    const highestPosition = max(data.map((p: any) => parseInt(p.lfdNr, 10)));
                    const generalAgreementPositionIds = data
                        .map((p: any) => p.rahmenvertragspositionId)
                        .filter(Boolean);
                    return {
                        type: 'offer',
                        __typename: 'Angebot',
                        scopeId: undefined,
                        cache: {
                            highestPosition,
                            generalAgreementPositionIds,
                            type: 'offer',
                            data,
                        },
                    };
                }),
                on: {
                    SUBMIT: 'createOffer',
                    CANCEL: 'idle',
                    CREATE_POSITION: {
                        actions: [
                            'createDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    UPDATE_POSITION: {
                        actions: [
                            'updateDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    DELETE_POSITION: {
                        actions: [
                            'deleteDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                },
            },
            createOffer: {
                invoke: {
                    src: 'createOffer',
                    onDone: {
                        target: 'getCalculationpositions',
                        actions: [
                            assign(({ created = [] }, { data }) => ({
                                created: [...created, data],
                            })),
                            // 'getCalculations',
                        ],
                    },
                    onError: {
                        target: 'idle',
                    },
                },
            },
            // NEW INVOICE
            newInvoice: {
                entry: assign((ctx, { fromOfferId }) => {
                    const offer = ctx?.scopes?.[fromOfferId];
                    const type = 'invoice';
                    const data = offer ? offer?.angebotspositionen : ctx.calculationpositions || [];
                    const highestPosition = max(data.map((p: any) => parseInt(p.lfdNr, 10)));
                    const generalAgreementPositionIds = data
                        .map((p: any) => p.rahmenvertragspositionId)
                        .filter(Boolean);
                    return {
                        type,
                        __typename: 'Rechnung',
                        scopeId: undefined,
                        cache: {
                            fromOfferId,
                            highestPosition,
                            generalAgreementPositionIds,
                            type,
                            data,
                        },
                    };
                }),
                on: {
                    SUBMIT: 'createInvoice',
                    CANCEL: 'idle',
                    CREATE_POSITION: {
                        actions: [
                            'createDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    UPDATE_POSITION: {
                        actions: [
                            'updateDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    DELETE_POSITION: {
                        actions: [
                            'deleteDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                },
            },
            createInvoice: {
                invoke: {
                    src: 'createInvoice',
                    onDone: {
                        target: 'getCalculationpositions',
                        actions: [
                            assign(({ created = [] }, { data }) => ({
                                created: [...created, data],
                            })),
                        ],
                    },
                    onError: {
                        target: 'idle',
                    },
                },
            },
            createPosition: {
                invoke: {
                    src: 'createCalculationPosition',
                    onDone: 'getCalculationpositions',
                },
            },
            // UPDATE OFFER
            updateOffer: {
                invoke: {
                    src: 'updateOffer',
                    onDone: {
                        target: 'getCalculationpositions',
                        actions: [
                            assign(({ updated = [] }, { data }) => ({
                                updated: [...updated, data],
                            })),
                        ],
                    },
                    onError: {
                        target: 'idle',
                    },
                },
            },
            getOfferPositions: {
                entry: assign((ctx, { id: scopeId }) => ({
                    scopeId,
                })),
                invoke: {
                    src: 'getOfferPositions',
                    onDone: {
                        target: 'editOffer',
                        actions: [
                            assign(({ scopes }, { data = {} }) => {
                                return {
                                    scopes: {
                                        ...scopes,
                                        ...data,
                                    },
                                };
                            }),
                        ],
                    },
                    onError: {
                        target: 'editOffer',
                    },
                },
            },
            editOffer: {
                entry: assign(({ scopes, scopeId, calculationpositions = [] }, { id }) => {
                    const offerId = scopeId || id;
                    const offer = scopes?.[offerId as number];
                    const hasOfferPositions = offer?.angebotspositionen?.length > 0;
                    const isValidOffer = offer?.statusSnippet?.kuerzel === VALID_OFFER_STATUS_KEY;
                    const data =
                        hasOfferPositions || isValidOffer
                            ? offer?.angebotspositionen
                            : calculationpositions;
                    const highestPosition = max(data.map((p: any) => parseInt(p.lfdNr, 10)));
                    const generalAgreementPositionIds = data
                        .map((p: any) => p.rahmenvertragspositionId)
                        .filter(Boolean);
                    return {
                        type: 'offer',
                        __typename: 'Angebot',
                        scopeId: offerId,
                        cache: {
                            hasOfferPositions,
                            highestPosition,
                            generalAgreementPositionIds,
                            type: 'offer',
                            offerId,
                            offer,
                            isValidOffer,
                            data,
                        },
                    };
                }),
                on: {
                    SUBMIT: 'updateOffer',
                    CANCEL: 'idle',
                    CREATE_POSITION: {
                        actions: [
                            'createDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    UPDATE_POSITION: {
                        actions: [
                            'updateDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    DELETE_POSITION: {
                        actions: [
                            'deleteDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                },
            },
            updateInvoice: {
                invoke: {
                    src: 'updateInvoice',
                    onDone: {
                        target: 'getCalculationpositions',
                        actions: [
                            assign(({ updated = [] }, { data }) => ({
                                updated: [...updated, data],
                            })),
                        ],
                    },
                    onError: {
                        target: 'idle',
                    },
                },
            },
            getInvoicePositions: {
                entry: assign((ctx, { id: scopeId }) => ({
                    scopeId,
                })),
                invoke: {
                    src: 'getInvoicePositions',
                    onDone: {
                        target: 'editInvoice',
                        actions: [
                            assign(({ scopes }, { data = {} }) => {
                                return {
                                    scopes: {
                                        ...scopes,
                                        ...data,
                                    },
                                };
                            }),
                        ],
                    },
                    onError: {
                        target: 'editInvoice',
                    },
                },
            },
            editInvoice: {
                entry: assign(({ scopes, scopeId, calculationpositions = [] }, { id }) => {
                    const invoiceId = scopeId || id;
                    const type = 'invoice';
                    const invoice = scopes?.[invoiceId as number];
                    const hasInvoicePositions = invoice?.rechnungspositionen?.length > 0;
                    const isOpenInvoice =
                        !invoiceId || invoice?.statusSnippet?.kuerzel === OPEN_INVOICE_STATUS_KEY;
                    const data =
                        hasInvoicePositions || !isOpenInvoice
                            ? invoice?.rechnungspositionen
                            : calculationpositions;

                    const highestPosition = max(data.map((p: any) => parseInt(p.lfdNr, 10)));
                    const generalAgreementPositionIds = data
                        .map((p: any) => p.rahmenvertragspositionId)
                        .filter(Boolean);
                    return {
                        type,
                        __typename: 'Rechnung',
                        scopeId: invoiceId,
                        cache: {
                            highestPosition,
                            hasInvoicePositions,
                            hasRechnungspositionenAndIsOpen:
                                !!invoice?.rechnungspositionen?.length && isOpenInvoice,
                            generalAgreementPositionIds,
                            type,
                            invoiceId,
                            invoice,
                            isOpenInvoice,
                            data,
                        },
                    };
                }),
                on: {
                    SUBMIT: 'updateInvoice',
                    CANCEL: 'idle',
                    CREATE_POSITION: {
                        actions: [
                            'createDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    UPDATE_POSITION: {
                        actions: [
                            'updateDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                    DELETE_POSITION: {
                        actions: [
                            'deleteDraftPosition',
                            'getCachedGeneralAgreementPositionIds',
                            'sortCachedData',
                        ],
                    },
                },
            },
            updatePosition: {
                invoke: {
                    src: 'updateCalculationPosition',
                    onDone: 'getCalculationpositions',
                },
            },
        },
        on: {
            INIT: 'init',
            IDLE: 'idle',
            SET_CACHE: {
                actions: 'setCache',
            },
            CHANGE_TYPE: {
                actions: 'changeType',
            },
            SET_SCOPE_ID: {
                cond: (ctx, { scopeId }) => ctx.scopeId !== scopeId,
                actions: 'changeScopeId',
            },
            // list
            GET_CALCULATIONPOSITIONS: 'getCalculationpositions',
            GET_OFFERS_AND_INVOICES: 'getOffers',
            // create
            NEW_OFFER: 'newOffer',
            CREATE_OFFER: 'createOffer',
            NEW_INVOICE: 'newInvoice',
            CREATE_INVOICE: 'createInvoice',
            CREATE_POSITION: 'createPosition',
            // update
            EDIT_OFFER: 'getOfferPositions',
            UPDATE_OFFER: 'updateOffer',
            EDIT_INVOICE: 'getInvoicePositions',
            UPDATE_INVOICE: 'updateInvoice',
            UPDATE_POSITION: 'updatePosition',
        },
    },
    {
        actions: {
            createDraftPosition: assign(({ cache = {} }, { variables = {} }) => {
                const { created = [] } = cache;
                const rahmenvertragsposition =
                    cache?.rahmenvertragsposition || cache?.row?.rahmenvertragsposition || {};
                const id = `draft_${faker.datatype.uuid()}`;
                const data = [
                    ...(cache?.data || []),
                    {
                        ...(variables?.data || {}),
                        rahmenvertragsposition,
                        id,
                    },
                ];

                return {
                    cache: {
                        ...cache,
                        created: [...created, { id, ...variables }],
                        data,
                    },
                };
            }),
            updateDraftPosition: assign(({ cache = {} }, { variables = {} }) => {
                const { updated = [], created = [] } = cache;
                const isUpdateOfDraftCreation = created?.find((c: any) => c?.id === variables?.id);
                const data = cache?.data?.map((d: any) =>
                    d.id === variables?.id ? { ...d, ...variables.data, id: variables?.id } : d
                );
                return {
                    cache: {
                        ...cache,
                        data,
                        ...(isUpdateOfDraftCreation && {
                            created: created?.map((c: any) =>
                                c?.id === variables?.id ? variables : c
                            ),
                        }),
                        updated: isUpdateOfDraftCreation ? updated : [...updated, variables],
                    },
                };
            }),
            deleteDraftPosition: assign(({ cache = {} }, { variables = {} }) => {
                const { deleted = [], created = [], updated = [] } = cache;
                const isDeleteOfDraftCreation = created?.find((c: any) => c?.id === variables?.id);
                const isDeleteOfDraftUpdated = updated?.find((u: any) => u?.id === variables?.id);
                const data = cache?.data?.filter((d: any) => d.id !== variables?.id);

                return {
                    cache: {
                        ...cache,
                        data,
                        ...(isDeleteOfDraftCreation && {
                            created: created?.filter((c: any) => c?.id !== variables?.id),
                        }),
                        ...(isDeleteOfDraftUpdated && {
                            updated: updated?.filter((u: any) => u.id !== variables?.id),
                        }),
                        deleted: isDeleteOfDraftCreation ? deleted : [...deleted, variables],
                    },
                };
            }),
            sortCachedData: assign(({ cache = {} }) => {
                const { data = [] } = cache;
                return {
                    cache: {
                        ...cache,
                        data: data.sort((a: any, b: any) => {
                            const lfdNrA = a.lfdNr;
                            const lfdNrB = b.lfdNr;
                            if (lfdNrA < lfdNrB) {
                                return -1;
                            }
                            if (lfdNrA > lfdNrB) {
                                return 1;
                            }

                            return 0;
                        }),
                    },
                };
            }),
            getCachedGeneralAgreementPositionIds: assign(({ cache = {} }) => {
                const { data = [] } = cache;
                const generalAgreementPositionIds = data
                    .map((p: any) => p.rahmenvertragspositionId)
                    .filter(Boolean);
                const highestPosition = max(data.map((p: any) => parseInt(p.lfdNr, 10)));
                return { cache: { ...cache, generalAgreementPositionIds, highestPosition } };
            }),
            setCache: assign((ctx, { cache = {} }) => {
                const type = ctx?.type;
                return {
                    cache:
                        typeof cache === 'function'
                            ? { type, ...cache?.(ctx) }
                            : { type, ...cache },
                };
            }),
            changeScopeId: assign(({ scopes }, { scopeId }: any) => ({
                scopeId,
                __typename: scopes?.[scopeId],
            })),
            changeType: assign(({ scopes }, { data: { type, scopeId } }: any) => ({
                type,
                scopeId,
                __typename: scopes?.[scopeId],
                editType: scopeId ? 'update' : 'create',
            })),
            getFinanceList: assign(({ offers = [], invoices = [], totalPriceNet }) => ({
                financeList: orderBy(
                    [...offers, ...invoices].map((row: any) => {
                        let gesamtpreisNetto = 0;
                        let hasPositons = false;

                        if (row.__typename === 'Angebot') {
                            hasPositons = row?.angebotspositionen?.length > 0;
                            gesamtpreisNetto =
                                hasPositons || !(row.statusSnippet.name === 'in Bearbeitung')
                                    ? row.gesamtpreisNetto
                                    : totalPriceNet;
                        }

                        if (row.__typename === 'Rechnung') {
                            hasPositons = row?.rechnungspositionen?.length > 0;
                            gesamtpreisNetto = hasPositons
                                ? row.gesamtpreisNetto
                                : row.statusSnippet.name === 'Offen' &&
                                  row?.rechnungspositionen?.length === 0
                                ? totalPriceNet
                                : row.gesamtpreisNetto;
                        }

                        return { ...row, gesamtpreisNetto, hasPositons };
                    }),
                    ['id'],
                    ['asc']
                ),
            })),
            getCalculations: assign(
                ({ calculationpositions = [], isStandard = '', generalAgreementId = '' }: any) => {
                    const byAgreement = [isStandard, generalAgreementId]
                        ?.filter?.(Boolean)
                        ?.reduce?.((acc: any, id: number | string) => {
                            const positions = calculationpositions?.filter?.(
                                (pos: any) => pos?.rahmenvertragsposition?.rahmenvertragId === id
                            );
                            return {
                                ...acc,
                                [id]: {
                                    positions,
                                    generalAgreementPositionIds: positions
                                        .map((p: any) => p.rahmenvertragspositionId)
                                        .filter(Boolean),
                                    highestPosition: max(
                                        positions.map((p: any) => parseInt(p.lfdNr, 10))
                                    ),
                                },
                            };
                        }, {});

                    const totalPriceNet = calculationpositions.reduce(
                        (acc: number, pos: FinancePosition) => acc + pos?.menge * pos?.einzelpreis,
                        0
                    );
                    return {
                        totalPriceNet,
                        calculations: {
                            byAgreement,
                            generalAgreementPositionIds: calculationpositions
                                .map((p: any) => p.rahmenvertragspositionId)
                                .filter(Boolean),
                            highestPosition: max(
                                calculationpositions.map((p: any) => parseInt(p.lfdNr, 10))
                            ),
                        },
                    };
                }
            ),
        },
    }
);
