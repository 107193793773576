import { Stack, useBreakpointValue } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { PageProvider } from '~/contexts/PageContext';
import { DialogProvider } from '~/contexts/DialogContext';
import { ModalProvider } from '~/contexts/ModalContext';
import { Sidebar } from '~/components/Layout/Sidebar';
import { NavBar } from '~/components/Layout/NavBar';
import { Dialog } from '~/components/Dialog';
import { GlobalModal } from '~/components/GlobalModal';

export function Layout({ children }: PropsWithChildren) {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, 'lg');
    return (
        <PageProvider>
            <ModalProvider>
                <DialogProvider>
                    <Stack
                        as="section"
                        direction={{ base: 'column', lg: 'row' }}
                        height="100vh"
                        bg="bg-canvas"
                        overflow="hidden"
                        spacing={0}
                    >
                        {isDesktop ? <Sidebar /> : <NavBar />}
                        {children}
                    </Stack>
                    <Dialog />
                    <GlobalModal />

                </DialogProvider>
            </ModalProvider>
        </PageProvider>
    );
}
