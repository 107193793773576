import { resolveFormFields, tableColumnMapper } from '~/utils';
import { selects } from '~/pages/projects/meta/data/projects.gql';

export const ui = { tables: [{ id: 'trade', colSpan: 3, snippets: { create: 'Neues Gewerk hinzufügen' } }] };

// ===============================================
// TABLES
// ===============================================

const columns = {
  trade: {
    __graphqlType: 'Gewerk',
    id: { header: 'ID', accessor: 'id' },
    name: { header: 'Gewerk', accessor: 'name', disableSortBy: true },
    tradeProjectType: {
      header: 'Projekttyp',
      accessor: 'gewerkProjekttyp',
      renderer: { name: 'ProjectTypes' } 
    },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  trade: {
    columns: ['id', 'name', 'tradeProjectType', 'controls'].map(tableColumnMapper(columns.trade)),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  trade: {
    __graphqlInput: 'GewerkInput',
    publicId: { api: { path: 'publicId', required: true } },
    name: { api: { path: 'name', required: true } },
    projectType: { api: { path: 'gewerkProjekttyp' } },
    emergencyService: { api: { path: 'notdienstFaehig', required: true } },
  },
};

export const forms = {
  trade: [
    {
      // TODO: async (remote) validation
      path: 'publicId',
      ui: { label: 'Public Id', type: 'number' },
      helperText: 'Freie PublicId eintragen',
    },
    { path: 'name', ui: { label: 'Name' } },
    {
      path: 'projectType',
      ui: {
        label: 'Typ',
        component: 'QuerySelect',
        props: { query: selects.projectType, isMulti: true, isClearable: true },
      },
      decorators: {
        form: [
          (value = []) => {
            return value.map((item:any) => item?.projekttyp?.id);
          },
        ],
      },
    },
    { path: 'emergencyService', ui: { label: 'notdienstfähig', component: 'CheckboxControl' } },
  ],
};

export const trade = {
  columns: tables.trade.columns,
  tableProps: { pageSize: 10 },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.trade, fields: fields.trade },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.trade, fields.trade, context),

      snippets: {
        create: 'Neues Gewerk',
        update: 'Gewerk bearbeiten',
        displayName: (item: any) => item?.name,
      },

      getVariables: ({ id, data = {} }:any) => {
        const { gewerkProjekttyp, ...rest } = data as any;
        return { ...(id && { id }), data: rest, gewerkProjekttyp };
      },

      getResponse: (response: any) => {
        console.log('getResponse', response);
        return response?.data?.app?.item || {};
      },
    };
  },
};
