import { resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = { tables: [{ id: 'textSnippets', colSpan: 3, snippets: { create: 'Neuer Textbaustein' } }] };

// ===============================================
// TABLES
// ===============================================

const columns = {
  textSnippets: {
    __graphqlType: 'Textbaustein',
    id: { header: 'Id', accessor: 'id' },
    group: { header: 'Gruppe', accessor: 'gruppe' },
    desription: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'Description' } },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  textSnippets: {
    columns: ['id', 'group', 'desription', 'controls'].map(tableColumnMapper(columns.textSnippets)),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  textSnippets: {
    __graphqlInput: 'TextbausteinInput',
    group: { api: { path: 'gruppe', required: true } },
    description: { api: { path: 'beschreibung' } },
  },
};

export const forms = {
  textSnippets: [
    { path: 'group', ui: { label: 'Kürzel' } },
    { path: 'description', ui: { label: 'Textbaustein', hideLabel: true, component: 'RichTextEditor', colSpan: 2 } },
  ],
};

export const textSnippets = {
  columns: tables.textSnippets.columns,
  tableProps: { hiddenColumns: ['id'], pageSize: 10 },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.textSnippets, fields: fields.textSnippets },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.textSnippets, fields.textSnippets, context),

      snippets: {
        create: 'Neuer Textbaustein',
        update: 'Textbaustein bearbeiten',
        displayName: (item: any) => item?.name,
      },
    };
  },
};
