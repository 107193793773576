import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, SimpleGrid, GridItem } from '@chakra-ui/react';

export const Radiobox = ({
    control,
    options = [],
    ...props
}: {
    control: any;
    options: any[];
    name: string;
}) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });
    console.log('🚀 ~ file: Radiobox.tsx ~ line 13 ~ Radiobox ~ value', value);

    const initialValues = options.reduce((acc, { name }) => ({ ...acc, [name]: false }), {});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        inputProps.onChange({ ...initialValues, [name]: checked });
    };

    return (
        <SimpleGrid spacing={4} columns={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
            {options.map(({ name, label }) => (
                <GridItem key={name}>
                    <Checkbox
                        name={name}
                        onChange={onChange}
                        isChecked={!!value?.[name]}
                        data-test-id={`field-${name}`}
                    >
                        {label}
                    </Checkbox>
                </GridItem>
            ))}
        </SimpleGrid>
    );
};
