// Forms
export const fields = {
  profile: {
    firstName: { api: { path: 'vorname' } },
    lastName: { api: { path: 'name' } },
    email: { api: { path: 'email' } },
    avatar: { api: { path: 'avatar' } },
  },
};

export const forms = {
  profile: [
    { path: 'email', ui: { label: 'E-Mail-Adresse', colSpan: 2, props: { disabled: true } } },
    { path: 'firstName', ui: { label: 'Vorname', colSpan: 2, props: { disabled: true } } },
    { path: 'lastName', ui: { label: 'Nachname', colSpan: 2, props: { disabled: true } } },
    { path: 'avatar', ui: { component: 'AvatarUpload', hideLabel: true } },
  ],
};
