import * as yup from 'yup';
import { format } from 'date-fns';
import { selects } from './projects.gql';
import { tableColumnMapper } from "~/utils/tableColumnMapper";
import { join } from '~/utils';

// ===============================================
// TABLES
// ===============================================

const columns = {
  projects: {
    id: { header: 'Id', accessor: 'id' },
    projectNo: { header: 'Projekt-Nr (SubAuftrag)', accessor: 'projekt.lfdNr', renderer: { name: 'ProjectNumberOutOfSubproject', props: {path:'original'} } },
    projectType: { header: 'Projekt-Typ (SubAuftrag)', accessor: 'projekttyp.name', renderer: { name: 'ProjectType' } },
    object: { header: 'Objekt', accessor: 'projekt.plz', enableSorting: false, renderer: { name: 'ProjectObject' } },
    createdAt: { header: 'Angelegt am', accessor: 'anlageDatum', renderer: { name: 'DateTimeFormat' } },
    status: { header: 'Status', accessor: 'statusSnippet.name' },
    branch: { header: 'Niederlassung', accessor: 'projekt.niederlassung.nummer', disableSortBy: false, renderer: { name: 'ProjectBranch' } },
    client: { header: 'Auftraggeber', accessor: 'beteiligte', enableSorting: false, renderer: { name: 'ProjectParticipants' } },
  },
  subprojects: {
    id: { header: 'Id', accessor: 'id' },
    projectNo: { header: 'Projektnummer', accessor: 'lfdNr', renderer: { name: 'SubprojectNumber' } },
    status: { header: 'Status', accessor: 'statusSnippet.name', enableSorting: false },
    createdAt: { header: 'Erfassungsdatum', accessor: 'anlageDatum', renderer: { name: 'DateFormat' } },
    branch: { header: 'Niederlassung', accessor: 'niederlassung', enableSorting: false, renderer: { name: 'ProjectBranch' } },
    damageDay: { header: 'Schaden Tag', accessor: 'schadenTag', renderer: { name: 'DateFormat' } },
    object: { header: 'Objekt', accessor: 'plz', enableSorting: false, renderer: { name: 'ProjectObject' } },
    controls: { accessor: 'controls', width: 60, enableSorting: false,renderer: { name: 'Controls' } },
  },
  relatedProjects: {
    id: { header: 'Id', accessor: 'id' },
    subprojectNo: { header: 'SubProjekt-Nr', accessor: 'lfdNr', cell: ({  row }:any) => join([row?.original?.projekt?.lfdNr, row?.original?.lfdNr],'-') },
    createdAt: { header: 'Anlagedatum', accessor: 'anlageDatum', renderer: { name: 'DateTimeFormat' } },
    branch: { header: 'Niederlassung', accessor: 'projekt.niederlassung', enableSorting: false, renderer: {name: 'BranchName'} },
    type: { header: 'Typ', accessor: 'projekttyp.name', renderer: { name: 'ProjectType' } },
    client: {
      header: 'Auftraggeber',
      accessor: 'client',
      enableSorting: false,
      cell: ({ row: { original } }:any) => {
        const client = original?.beteiligte?.find((participant:any) => participant?.typSnippet?.name === 'Auftraggeber');

        if (client) {
          const { firma1, firma2, name, vorname } = client?.kontakt;
          return join([firma1, firma2, name, vorname]);
        }

        return null;
      },
    },
    trade: { header: 'Gewerk', accessor: 'subprojektGewerk', enableSorting: false, renderer: { name: 'ProjectTrade' } },
    status: { header: 'Status', accessor: 'statusSnippet.name' },
    intern: { header: 'Intern', accessor: 'intern', renderer: { name: 'Checkbox' } },
    participant: {
      header: 'Beauftragter Handwerker',
      accessor: 'beteiligte',
      enableSorting: false,
      cell: ({ row:{original} }:any) => {
        const handwerker = original?.beteiligte?.find((b:any) => b?.typSnippet?.name === 'Beauftragter Handwerker');
        if (handwerker) {
          const { firma1, firma2, name, vorname } = handwerker?.kontakt;
          return join([firma1, firma2, name, vorname]);
        }
        return null;
      },
    },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  projectSearch: {
    columns: ['id', 'projectNo', 'projectType', 'status','object', 'createdAt', 'branch', 'client'].map(tableColumnMapper(columns.projects)),
    hiddenColumns: ['id'],
  },
  subprojects: {
    columns: ['id', 'projectNo',  'createdAt', 'branch', 'damageDay', 'object', 'controls'].map(tableColumnMapper(columns.subprojects)),
    hiddenColumns: ['id'],
  },
  relatedProjects: {
    columns: ['id', 'subprojectNo', 'createdAt', 'client', 'branch','intern', 'type', 'trade', 'status', 'participant', 'controls'].map(
      tableColumnMapper(columns.relatedProjects)
    ),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

// API fields
export const fields = {
  project: {
    __graphqlInput: 'CreateSubprojektWithProjektInput',
    object: {
      street: { api: { path: { req: 'objektStrasse', res: 'projekt.strasse' }, required: true }, defaultValue: '' },
      zipCode: { api: { path: { req: 'objektPlz', res: 'projekt.plz' }, required: true }, defaultValue: '' },
      location: { api: { path: { req: 'objektOrt', res: 'projekt.ort' }, required: true }, defaultValue: '' },
    },
    project: {
      currentNumber: { api: { path: { req: 'projektLfdNr', res: 'projekt.lfdNr' } }, defaultValue: '' },
      createdAt: { api: { path: { req: 'projektAnlageDatum', res: 'projekt.anlageDatum' } }, defaultValue: '' },
      branchId: { api: { path: { req: 'projektNiederlassungId', res: 'projekt.niederlassungId' }, required: true }, defaultValue: null },
      damageday: { api: { path: { req: 'projektSchadenTag', res: 'projekt.schadenTag' } } },
      statusId: { api: { path: { req: 'statusSnippetId', res: 'projekt.statusSnippetId' }, required: true  } },
      forecast: { api: { path: { req: 'umsatzPrognose', res: 'subprojekt.umsatzPrognose' } } },
      billingDate: { api: { path: { req: 'subprojektAbrechnungsDatum', res: 'subprojekt.abrechnungsDatum' } } },
    },
    subproject: {
      id: { api: { path: 'subprojektId' } }, // UpdateSubprojektWithProjektInput
      currentNumber: { api: { path: { req: 'subprojektLfdNr', res: 'subprojekt.lfdNr' } }, defaultValue: '' },
      comment: { api: { path: { req: 'subprojektBemerkung', res: 'subprojekt.bemerkung' } }, defaultValue:  [{ type: 'paragraph', children: [{ text: '' }] }] },  // AWSJSON!
      projectTypeId: { api: { path: { req: 'subprojektProjekttypId', res: 'subprojekt.projekttypId' }, required: true }, defaultValue: null },
      emergency: { api: { path: { req: 'subprojektNoteinsatz', res: 'subprojekt.notdienst' } }, defaultValue: false },
      tradeIds: { api: { path: { req: 'subprojektGewerkIds', res: 'subprojekt.subprojektGewerk' }, required: true }, defaultValue: [] },
      intern: { api: { path: { req: 'subprojektIntern', res: 'subprojekt.intern' } }, defaultValue: false },
      weg: { api: { path: { req: 'subprojektWeg', res: 'subprojekt.weg' } }, defaultValue: '' },
      generalAgreementId: { api: { path: { req: 'subprojektRahmenvertragId', res: 'subprojekt.rahmenvertragId' } }, defaultValue: null },
      yourSign: { api: { path: { req: 'subprojektIhrZeichen', res: 'subprojekt.ihrZeichen' } }, defaultValue: '' },
      creditStandingSnippetId: { api: { required: true , path: { req: 'subprojektBonitaetSnippetId', res: 'subprojekt.bonitaetSnippetId' } }, /** defaultValue: { label: 'ungeklärt'}*/ },
      guarantee: { api: { path: { req: 'subprojektBuergschaft', res: 'subprojekt.buergschaft' } }, defaultValue: '' },
      insuranceId: { api: { path: { req: 'subprojektVersicherungId', res: 'subprojekt.versicherungId' } }, defaultValue: null },
      insuranceTypeSnippetId: {
        api: { path: { req: 'subprojektVersicherungsArtSnippetId', res: 'subprojekt.versicherungsArtSnippetId' } },
        defaultValue: null,
      },
      damageNumber: { api: { path: { req: 'subprojektSchadenNr', res: 'subprojekt.schadenNr' } }, defaultValue: '' },
      contractNumber: { api: { path: { req: 'subprojektVertragsNr', res: 'subprojekt.scheinNr' } }, defaultValue: '' },
    },
    participant: {
      craftsmanId: { api: { path: { req: 'beteiligterHandwerkerId', res: 'beteiligte.handwerkerId' } }, defaultValue: null },
      brokerId: { api: { path: { req: 'beteiligterVermittlerId', res: 'beteiligte.vermittlerId' } }, defaultValue: null },
      ownerId: { api: { path: { req: 'beteiligterEigentuemerId', res: 'beteiligte.eigentuemerId' } }, defaultValue: null },
      clientId: { api: { path: { req: 'beteiligterAuftraggeberId', res: 'beteiligte.auftraggeberId' }, required: true }, defaultValue: null },
      policyholderId: { api: { path: { req: 'beteiligterVersicherungsnehmerId', res: 'beteiligte.versicherungsnehmerId' } }, defaultValue: null },
    },
  },
  createSubProject: {
    __graphqlInput: 'CreateSubprojektWithProjektInput',
    project: {
      id: { api: { path: 'projektId' }, required: true },
    },
    subproject: {
      comment: { api: { path: 'subprojektBemerkung' }, defaultValue:  [{ type: 'paragraph', children: [{ text: '' }] }] }, // AWSJSON!
      projectTypeId: { api: { path: 'subprojektProjekttypId', required: true }, defaultValue: null },
      emergency: { api: { path: 'subprojektNoteinsatz' }, defaultValue: false },
      tradeIds: { api: { path: 'subprojektGewerkIds', required: true }, defaultValue: [] },
      intern: { api: { path: 'subprojektIntern' }, defaultValue: false },
      weg: { api: { path: 'subprojektWeg' }, defaultValue: '' },
      generalAgreementId: { api: { path: 'subprojektRahmenvertragId' }, defaultValue: null },
      yourSign: { api: { path: 'subprojektIhrZeichen' }, defaultValue: '' },
      creditStandingSnippetId: { api: { path: 'subprojektBonitaetSnippetId' }, defaultValue: null },
      guarantee: { api: { path: 'subprojektBuergschaft' }, defaultValue: '' },
      insuranceId: { api: { path: 'subprojektVersicherungId' }, defaultValue: null },
      insuranceTypeSnippetId: { api: { path: 'subprojektVersicherungsArtSnippetId' }, defaultValue: null },
      damageNumber: { api: { path: 'subprojektSchadenNr' }, defaultValue: '' },
      contractNumber: { api: { path: 'subprojektVertragsNr' }, defaultValue: '' },
    },
    participant: {
      craftsmanId: { api: { path: 'beteiligterHandwerkerId' }, defaultValue: null },
      brokerId: { api: { path: 'beteiligterVermittlerId' }, defaultValue: null },
      ownerId: { api: { path: 'beteiligterEigentuemerId' }, defaultValue: null },
      clientId: { api: { path: 'beteiligterAuftraggeberId', required: true }, defaultValue: null },
      policyholderId: { api: { path: 'beteiligterVersicherungsnehmerId' }, defaultValue: null },
    },
  },
  projectSearch: {
    __graphqlInput: 'SubprojektSearch',
    searchInput: {
      projectNo: { api: { path: 'projektLfdNr' } },
      objectStreet: { api: { path: 'objektStrasse' } },
      objectZipcode: { api: { path: 'objektPlz' } },
      objectLocation: { api: { path: 'objektOrt' } },
      object: { api: { path: 'objekt' } },
      participant: { api: { path: 'beteiligter' } },
      referenceNumber: { api: { path: 'referenznummern' } },
      createdRangeStart: { api: { path: 'anlageAb' } },
      createdRangeEnd: { api: { path: 'anlageBis' } },
      statusSnippetId: { api: { path: 'statusSnippetId' }, defaultValue: null },
      projectTypeId: { api: { path: 'projekttypId' }, defaultValue: null },
    },
  },
};

const ProjectTypes = { Leckortung: 1, Trocknung: 2, Vorarbeiten: 3, Nacharbeiten: 4 };

// FORM fields
const formFields = {
  // Objekt
  'object.street': { path: 'object.street', ui: { label: 'Straße' }, rule: yup.string().min(1, 'Straße ist erforderlich') },
  'object.zipCode': {
    path: 'object.zipCode',
    ui: ({ changeZipCodeRelations }: any = {}) => ({ label: 'PLZ', props: { onBlurCapture: changeZipCodeRelations } }),
    rule: yup.string().min(1, 'PLZ ist erforderlich'),
  },
  'object.location': { path: 'object.location', ui: { label: 'Ort' }, rule: yup.string().min(1, 'Ort ist erforderlich') },
  'subproject.comment': { path: 'subproject.comment', ui: { label: 'Bemerkung', component: 'RichTextEditor', props: { hasSnippets: false }, colSpan: 2 } },
  // Projekt
  'project.currentNumber': { path: 'project.currentNumber', ui: { label: 'Projekt-Nr (Schaden)', props: { disabled: true } } },
  'subproject.currentNumber': {
    path: 'subproject.currentNumber',
    ui: { label: 'Projekt-Nr (SubAuftrag)', props: { disabled: true } },
    decorators: {
      form: [
        (subprojectNumber: any, context: any) => {
          const projectNumber = context?.data?.projekt?.lfdNr;
          return `${projectNumber}-${subprojectNumber}`;
        },
      ],
    },
  },
  'project.createdAt': { path: 'project.createdAt', ui: { label: 'Erfassungsdatum', component: 'DatePicker', props: { disabled: true } } },
  'subproject.projectTypeId': {
    path: 'subproject.projectTypeId',
    ui: { label: 'Typ', component: 'QuerySelect', props: { query: selects.projectType, isClearable: true } },
    rule: yup.object().nullable().required('Projekttyp ist erforderlich'),
  },
  'project.branchId': {
    path: 'project.branchId',
    ui: {
      label: 'Niederlassung',
      component: 'QuerySelect',
      props: {
        query: selects.branches,
        mapOptions: (item:any) => ({
          value: item?.value,
          label: `${item?.nummer} - ${item?.bezeichnung}`,
        }),
      },
    },
    rule: yup.object().nullable().required('Niederlassung ist erforderlich'),
  },
  'subproject.emergency': {
    path: 'subproject.emergency',
    ui: { label: 'Noteinsatz', component: 'CheckboxControl', colSpan: 2 },
    visibility: {
      watch: ['subprojectProjectTypeId'],
      hide: ({ subprojectProjectTypeId }: any = {}) => ![ProjectTypes.Leckortung, ProjectTypes.Trocknung].includes(subprojectProjectTypeId?.value),
      value: ({ subprojectProjectTypeId }:any) => ({
        enabled: ![ProjectTypes.Leckortung, ProjectTypes.Trocknung].includes(subprojectProjectTypeId?.value),
        getValue: () => false,
      }),
    },
  },
  'subproject.intern': {
    path: 'subproject.intern',
    ui: { label: 'Intern', component: 'Checkbox' },
    visibility: {
      watch: ['subprojectProjectTypeId'],
      hide: ({ subprojectProjectTypeId }: any = {}) => ![ProjectTypes.Vorarbeiten, ProjectTypes.Nacharbeiten].includes(subprojectProjectTypeId?.value),
    },
  },
  'participant.craftsmanId': {
    path: 'participant.craftsmanId',
    ui: { label: 'Beauftragter Handwerker', component: 'ContactInput' },
    visibility: {
      watch: ['subprojectProjectTypeId', 'subprojectIntern'],
      hide: ({ subprojectProjectTypeId }: any = {}) => ![ProjectTypes.Vorarbeiten, ProjectTypes.Nacharbeiten].includes(subprojectProjectTypeId?.value),
      props: ({ subprojectIntern }:any) => (subprojectIntern === true ? { isDisabled: true } : {}),
      value: ({ subprojectIntern }:any) => ({ enabled: subprojectIntern === true, getValue: () => null }),
    },
  },
  'subproject.tradeIds': {
    path: 'subproject.tradeIds',
    ui: {
      label: 'Gewerk',
      component: 'QuerySelect',
      props: { query: selects.trade, isMulti: true, isClearable: true },
    },
    visibility: {
      props: ({ subprojectProjectTypeId }:any) => ({ variables: { filter: { gewerkProjekttyp: { projekttypId: subprojectProjectTypeId?.value } } } }),
    },
    decorators: {
      form: [(value: any[]) => value?.map?.(({ gewerkId }) => gewerkId)],
    },
    rule: yup.array().nullable().required('Gewerk ist erforderlich').min(1, 'Gewerk ist erforderlich'),
    // TODO: figure out why this hat to be disabled
    // validation: false, 
  },
  'project.damageday': { path: 'project.damageday', ui: { label: 'Schaden Tag', component: 'DatePicker' } },
  'project.statusId': { path: 'project.statusId', ui: ({ category = 'Projekt' }:any = {}) => ({ label: 'Status', component: 'SnippetSelect', props: { category, property: 'Status' } }) },
  'project.forecast': { path: 'project.forecast', ui: { label: 'Umsatz (Prognose)', props: { disabled: true } } },
  'project.billingDate': { path: 'project.billingDate', ui: { label: 'Abrechnungsdatum', component: 'DatePicker', props: { disabled: true } } },
  // Auftraggeber
  'participant.brokerId': { path: 'participant.brokerId', ui: { label: 'Vermittler', component: 'ContactInput' } },
  'participant.clientId': {
    path: 'participant.clientId',
    ui: { label: 'Auftraggeber', component: 'ContactInput' },
    rule: yup.number().nullable().required('Auftraggeber ist erforderlich'),
  },
  'participant.ownerId': { path: 'participant.ownerId', ui: { label: 'Eigentümer', component: 'ContactInput' } },
  'subproject.weg': { path: 'subproject.weg', ui: { label: 'WEG' } },
  'subproject.generalAgreementId': {
    path: 'subproject.generalAgreementId',
    ui: { label: 'Rahmenvereinbarung', component: 'QuerySelect', props: { query: selects.generalAgreements,  isClearable: true } },
  },
  'subproject.yourSign': { path: 'subproject.yourSign', ui: { label: 'Ihr Zeichen' } },
  'subproject.creditStandingSnippetId': {
    path: 'subproject.creditStandingSnippetId',
    ui: { label: 'Bonität', component: 'SnippetSelect', props: { category: 'Subprojekt', property: 'Bonitaet'} },
  },
  // Versicherung
  'subproject.insuranceId': { path: 'subproject.insuranceId', ui: { label: 'Versicherung', component: 'QuerySelect', props: { query: selects.insurances, limit: 1000, isClearable: true } } },
  'participant.policyholderId': { path: 'participant.policyholderId', ui: { label: 'Vers. Nehmer', component: 'ContactInput' } },
  'subproject.insuranceTypeSnippetId': {
    path: 'subproject.insuranceTypeSnippetId',
    ui: { label: 'Vers. Art', component: 'SnippetSelect', props: { category: 'Subprojekt', property: 'Versicherungs-Art', isClearable: true  } },
  },
  'subproject.damageNumber': { path: 'subproject.damageNumber', ui: { label: 'Schaden-Nr' } },
  'subproject.contractNumber': { path: 'subproject.contractNumber', ui: { label: 'Vertrags-Nr' } },
};

const disableField = (formField = {} as any, include = true) => {
  const formFieldUi = typeof formField.ui === 'function' ? formField.ui() : formField.ui;
  const ui = { ...formFieldUi, props: { ...(formFieldUi?.props || {}), isDisabled: true } };
  return { ...formField, ui, include };
};

export const forms = {
  // Objekt
  object: [
    // CreateProject / UpdateProject
    formFields['object.street'],
    formFields['object.zipCode'],
    formFields['object.location'],
    formFields['subproject.comment'],
  ],
  objectCreateSubproject: [
    // CreateSubprojekt
    disableField(formFields['object.street']),
    disableField(formFields['object.zipCode']),
    disableField(formFields['object.location']),
    formFields['subproject.comment'],
  ],
  // Projekt
  project: [
    formFields['subproject.projectTypeId'],
    formFields['project.branchId'],
    formFields['subproject.emergency'],
    formFields['subproject.intern'],
    formFields['participant.craftsmanId'],
    formFields['subproject.tradeIds'],
    formFields['project.damageday'],
  ],
  projectEdit: [
    { path: 'subproject.id', ui: { label: 'Subprojekt Id', type: 'number', colSpan: 2, props: { isDisabled: true } }, visibility: { hide: () => true } },
    formFields['project.currentNumber'], // disabled
    formFields['subproject.currentNumber'], // disabled
    formFields['project.createdAt'], // disabled
    formFields['project.branchId'],
    formFields['subproject.projectTypeId'],
    formFields['subproject.emergency'],
    formFields['subproject.intern'],
    formFields['participant.craftsmanId'],
    formFields['subproject.tradeIds'],
    formFields['project.damageday'],
    formFields['project.statusId'],
    formFields['project.forecast'],
    formFields['project.billingDate'],
  ],
  projectCreateSubproject: [
    { path: 'project.id', ui: { label: 'Projekt Id', type: 'number', colSpan: 2, props: { isDisabled: true } }, visibility: { hide: () => true } },
    disableField(formFields['project.branchId'], false),
    formFields['subproject.projectTypeId'],
    formFields['subproject.emergency'],
    formFields['subproject.intern'],
    formFields['participant.craftsmanId'],
    formFields['subproject.tradeIds'],
    disableField(formFields['project.damageday'], false), // does not exist on CreateSubprojektToProjektInput
    formFields['project.forecast'],
    // formFields['project.billingDate'],
  ],
  // Auftraggeber
  client: [
    formFields['participant.brokerId'],
    formFields['participant.clientId'],
    formFields['participant.ownerId'],
    formFields['subproject.weg'],
    formFields['subproject.generalAgreementId'],
    formFields['subproject.yourSign'],
    formFields['subproject.creditStandingSnippetId'],
  ],
  // Versicherung
  insurance: [
    formFields['subproject.insuranceId'],
    formFields['participant.policyholderId'],
    formFields['subproject.insuranceTypeSnippetId'],
    formFields['subproject.damageNumber'],
    formFields['subproject.contractNumber'],
  ],
  projectSearchProject: [
    {
      path: 'searchInput.projectNo',
      ui: { label: 'Projekt-Nr.', type: 'number', colSpan: 2 },
      decorators: {
        api: [(value: any) => parseInt(value, 10)],
        form: [],
      },
    },
    { path: 'searchInput.createdRangeStart', ui: { label: 'Anlage ab', component: 'DatePicker', colSpan: { sm: 2, md: 2,  lg:2, xl: 2, '2xl': 1 } } },
    { path: 'searchInput.createdRangeEnd', ui: { label: 'Anlage bis', component: 'DatePicker', colSpan: { sm: 2, md: 2,  lg:2, xl: 2, '2xl': 1 } } },
    {
      path: 'searchInput.statusSnippetId',
      ui: { label: 'Status', component: 'SnippetSelect', colSpan: 2, props: { category: 'Subprojekt', property: 'Status' } },
    },
    {
      path: 'searchInput.projectTypeId',
      ui: {
        label: 'Projekttyp',
        component: 'QuerySelect',
        colSpan: 2,
        props: { query: selects.projectType },
      },
    },
  ],
  projectSearchObject: [
    { path: 'searchInput.objectStreet', ui: { label: 'Strasse', colSpan: 2 } },
    { path: 'searchInput.objectZipcode', ui: { label: 'Postleitzahl', colSpan: {  sm: 2, md: 2,  lg:2, xl: 2, '2xl': 1 } } },
    { path: 'searchInput.objectLocation', ui: { label: 'Ort', colSpan: {  sm: 2, md: 2,  lg:2, xl: 2, '2xl': 1 } } },
  ],
  projectSearchParticipant: [
    { path: 'searchInput.participant', ui: { label: 'Name, Firma, Adresse oder Telefon', hideLabel: true,  colSpan: 2 } },
  ],
  projectSearchInsurance: [{ path: 'searchInput.referenceNumber', ui: { label: 'Vers.-/Schaden-Nr./Ihr Zeichen', hideLabel: true, colSpan: 2 } }],
};
