import { uniqBy } from 'lodash';
import { FiDownload } from 'react-icons/fi';
import { MenuButton, Menu, MenuList, MenuItem, IconButton, Text, Stack } from '@chakra-ui/react';

export const DownloadPowerConsumption = ({ onClick, isLoading = false, title, isDisabled, meterReadings = [] }: any) => {
    const residentalUnits: any = uniqBy(meterReadings, 'subprojektWohneinheitId')
    const hasHandler = Boolean(onClick);
    const buttonProps = {
        isLoading,
        ...(hasHandler && { colorScheme: 'blue' }),
        isDisabled: isDisabled || !hasHandler || isLoading,
        'aria-label': 'download-icon-button', icon: <FiDownload />
    }

    return <Stack
        flexDir={{
            md: 'column', lg: 'column', xl: 'row', '2xl': 'row'
        }}
        alignItems="center"
        justify="space-between"
        borderBottomWidth={1}
        borderColor="gray.200"
        _last={{ borderBottomColor: 'transparent' }}
        px={6}
        py={6}
    >
        <Text fontSize={16} fontWeight={600}>
            {title}
        </Text>
        {
            residentalUnits.length > 1 ?
                <Menu>
                    <MenuButton as={IconButton} {...buttonProps} />
                    <MenuList>{residentalUnits.map(({ subprojektWohneinheitId, subprojektWohneinheit }: any) =>
                        <MenuItem key={subprojektWohneinheitId} onClick={() => onClick({
                            stromzaehler:
                                meterReadings.filter((meter: any) => meter?.subprojektWohneinheitId === subprojektWohneinheitId),
                            client: subprojektWohneinheit?.beteiligter?.[0]?.kontakt
                        })}>{subprojektWohneinheit?.wohneinheit?.bezeichnung}</MenuItem>)}
                        <MenuItem onClick={onClick}>Alle Wohneinheiten</MenuItem>
                    </MenuList>
                </Menu> :
                <IconButton
                    {...buttonProps}
                    onClick={() => {
                        const client = residentalUnits?.[0]?.subprojektWohneinheit?.beteiligter?.[0]?.kontakt
                        onClick({ client })
                    }}
                />
        }
    </Stack >
};
