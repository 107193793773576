import { format } from 'date-fns';
import isWithinInterval from 'date-fns/isWithinInterval';
import { ListAbsencesDocument, SortOrder } from '~/gql/ucpw/graphql';
import { client } from '~/apollo';
import log from '~/log';

export const useAbsenceOverlap = () => {
    return async (
        { dateFrom, dateUntil, employeeId, ...props }: any,
        setOverlap?: (args: any) => void
    ) => {
        if (!employeeId) {
            return { hasOverlap: false, regardingFields: [] };
        }
        const datumUntilFrom = new Date(dateFrom);
        const datumFromTo = new Date(dateUntil);
        const response: any = await client.query({
            query: ListAbsencesDocument,
            context: { clientName: 'ucpw' },
            fetchPolicy: 'network-only',
            variables: {
                orderBy: [{ datumFrom: SortOrder.Asc }],
                filter: {
                    datumUntilFrom: format(datumUntilFrom, 'yyyy-MM-dd'),
                    datumFromTo: format(datumFromTo, 'yyyy-MM-dd'),
                    mitarbeiterId: employeeId,
                },
            },
        });
        const absences = response?.data?.items?.items || [];

        let hasOverlap = false;
        const fields: any = [];

        for (const absence of absences) {
            const { datumFrom, datumUntil } = absence || {};
            const start = new Date(datumFrom);
            const end = new Date(datumUntil);
            const within = {
                dateForm: isWithinInterval(datumUntilFrom, { start, end }),
                dateUntil: isWithinInterval(datumFromTo, { start, end }),
            };
            const data = Object.entries(within);
            const overlap = data.some(([, value]) => Boolean(value));
            log.debug('overlap', { overlap, id: props?.id, absence });
            if (overlap && !(props?.id === absence?.id)) {
                data.forEach(([k, v]) => v && fields.push(k));
                if (!hasOverlap) {
                    hasOverlap = true;
                }
            }
        }

        if (setOverlap) {
            setOverlap?.(hasOverlap);
        }

        if (!employeeId) {
            setOverlap?.(false);
        }

        return { hasOverlap, regardingFields: [...new Set(fields)] };
    };
};
