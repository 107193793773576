import { useMutation } from '@apollo/client';
import {
    WattroAssignNotdienstprojektToSubprojektWohneinheitDocument,
    WattroCreateProjectForSubprojektWohneinheitDocument,
    WattroUpdateDeploymentsToProjektDocument,
} from '~/gql/ucpw/graphql';

type Props = {
    refetchQueries?: any[];
};

export const useWattro = ({ refetchQueries = [] }: Props = {}) => {
    const config = { context: { clientName: 'ucpw' }, refetchQueries };
    const [
        wattroCreateProjectForSubprojektWohneinheit,
        { loading: wattroCreateProjectForSubprojektWohneinheitLoading },
    ] = useMutation(WattroCreateProjectForSubprojektWohneinheitDocument, config);

    const [wattroUpdateDeploymentsToProjekt, { loading: wattroUpdateDeploymentsToProjektLoading }] =
        useMutation(WattroUpdateDeploymentsToProjektDocument, config);

    const [
        wattroAssignNotdienstprojektToSubprojektWohneinheit,
        { loading: wattroAssignNotdienstprojektToSubprojektWohneinheitLoading },
    ] = useMutation(WattroAssignNotdienstprojektToSubprojektWohneinheitDocument, config);

    const loading =
        wattroCreateProjectForSubprojektWohneinheitLoading ||
        wattroUpdateDeploymentsToProjektLoading ||
        wattroAssignNotdienstprojektToSubprojektWohneinheitLoading;

    return {
        wattroCreateProjectForSubprojektWohneinheit,
        wattroUpdateDeploymentsToProjekt,
        wattroAssignNotdienstprojektToSubprojektWohneinheit,
        loading,
    };
};
