import React from 'react';
import { useMutation } from '@apollo/client';
import {
    DeleteCalculationPositionDocument,
    ListCalculationPositionsDocument,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/projects/meta/data/finance.schema';
import { useDeleteWithConfirmation, useModal, usePermission } from '~/hooks';
import { FinancePosition, getOriginalAndModalFromPosition } from './useFinance';
import { UseFinanceCalculation } from '~/pages/projects/types';

export const useCalculation = ({ subprojectId, fixed = false, send }: UseFinanceCalculation) => {
    const { canEdit, canDelete, canView, canCreate } = usePermission('finance.calculation');

    // ===============================================
    // Modal
    // ===============================================

    const { dispatch, onOpen } = useModal();

    // ===============================================
    // LIST
    // ===============================================

    const context = { clientName: 'ucpw' };
    const refetchQueries = [
        {
            query: ListCalculationPositionsDocument,
            context,
            variables: {
                filter: {
                    subprojektId: subprojectId,
                },
                limit: 1000,
            },
        },
    ];

    // ===============================================
    // DELETE
    // ===============================================

    const [deleteMutation] = useMutation(DeleteCalculationPositionDocument, {
        context,
        refetchQueries,
    });

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
    });

    // ===============================================
    // TABLE
    // ===============================================

    const controls = !fixed
        ? [
              {
                  title: canView && !canEdit ? 'Ansehen' : 'Bearbeiten',
                  props: {
                      onClick: (row: any) => {
                          const position = (row?.original || {}) as FinancePosition;
                          const { original, modal } = getOriginalAndModalFromPosition(position);
                          dispatch?.({ type: 'setModal', data: { modal } });
                          send?.({
                              type: 'SET_CACHE',
                              cache: ({ cache = {} }: any = {}) => ({
                                  ...cache,
                                  row: original,
                                  rowId: original?.id,
                              }),
                          });
                          onOpen?.();
                      },
                  },
                  enabled: () => canEdit || canView,
              },
              { title: 'divider', enabled: () => canDelete },
              {
                  title: 'Löschen',
                  props: {
                      color: 'red.400',
                      onClick: (row: any) =>
                          deleteWithConfirmation(
                              { id: row?.original?.id },
                              { callAfter: () => send?.('GET_CALCULATIONPOSITIONS') }
                          ),
                  },
                  enabled: () => canDelete,
              },
          ].filter(Boolean)
        : [canDelete, canEdit, canView, canCreate];

    const columns = React.useMemo(() => withRenderers(tables.positions.columns, controls), [fixed]);
    const hiddenColumns = tables?.positions?.hiddenColumns;

    return {
        columns,
        hiddenColumns,
    };
};
