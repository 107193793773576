import React from 'react';
import { useController } from 'react-hook-form';
import { createEditor, Descendant, Editor, Transforms, Element as SlateElement } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { Box } from '@chakra-ui/react';
import { Toolbar } from './Toolbar';
import { useDebounce } from '~/hooks/useDebounce';
import { replaceHtml, serialize } from '~/utils/richText';
import { isEqual } from 'lodash';

const Element = ({ attributes = {}, children, element }: any) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'page-break':
            return <div {...attributes}>{children}<hr contentEditable={false} style={{ userSelect: "none" }} /></div>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({ attributes = {}, children, leaf }: any) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};


export function isJsonString(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const defaultValue = (controlledValue?: any) => {
    const emptyParagraph = [{ type: 'paragraph', children: [{ text: '' }] }];
    let value = controlledValue?.text || controlledValue;

    /** fixes: [Slate] value is invalid! Expected a list of elements but got: "[]" @/einsatzberichte */
    if (value === '[]') {
        return emptyParagraph
    }

    if (isJsonString(value)) {
        /** fixes: [Slate] value is invalid! Expected a list of elements but got: {"text":"[{\"type\":\"paragraph\"... */
        if (JSON.parse(value)?.text) {
            value = JSON.parse(value).text
        }

        try {
            if (
                value &&
                typeof JSON.parse(value) === 'string' &&
                JSON.parse(JSON.parse(value))
            ) {
                value = JSON.parse(value);
            }
        } catch (err) {
            // it wasn't double encoded, do nothing
        }

        if (JSON.parse(value)?.length) {
            const parsed = JSON.parse(value);
            if (
                parsed?.[0]?.children?.every(({ text = '' }: any = {}) =>
                    ['<br>', '&nbsp;']?.some((html) => text.includes(html))
                )
            ) {
                return [{ type: 'paragraph', children: [{ text: replaceHtml(serialize(parsed)) }] }];
            }
            return parsed;
        }
    }

    return value && value.length ? value : emptyParagraph;
};

function RichTextEditor({ control, ...props }: any) {
    const isValid = props?.errors?.[props?.name];
    const {
        field: { onChange, value: remoteValue },
    } = useController({ name: props.name, control, defaultValue: props?.defaultValue || null });
    const [key, setKey] = React.useState(() => Date.now());
    const [value, setValue] = React.useState<Descendant[]>([{ type: 'paragraph', children: [{ text: '' }] }]);
    const initRef = React.useRef(0);

    React.useEffect(() => {
        if (!(remoteValue === null || (Array.isArray(remoteValue) && remoteValue.length === 0))) {
            if (initRef.current === 0) {
                if (!isEqual(remoteValue, [{ type: 'paragraph', children: [{ text: '' }] }])) {
                    initRef.current = 1;
                    setValue(Array.isArray(remoteValue) ? remoteValue : defaultValue(remoteValue));
                    setKey(Date.now());
                }
            }
        }
    }, [value, remoteValue, initRef.current])

    return <RichTextEditorCore
        isValid={isValid}
        onChange={onChange}
        value={value}
        key={key}
        {...props} />
}


function RichTextEditorCore({ onChange, hasSnippets, isDisabled = false, canBeCopied = true, value: remoteValue, blurSelection, isValid, ...props }: any) {
    const [value, setValue] = React.useState<Descendant[]>(() => remoteValue);
    const debouncedvalue = useDebounce(value, 50);

    // const editorRef = React.useRef<Editor>();
    // if (!editorRef.current) {
    //     editorRef.current = withHistory(withReact(createEditor()));
    // }
    // const editor = editorRef.current;

    const editor = React.useMemo(() => withHistory(withReact(createEditor())), []);
    const renderElement = React.useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, []);

    const divRef = React.useRef<HTMLDivElement>(null);
    const [, setFocused] = React.useState(false);
    const savedSelection = React.useRef(editor.selection);


    React.useEffect(() => {
        onChange?.(debouncedvalue);
    }, [debouncedvalue]);

    const onFocus = React.useCallback(() => {
        setFocused(true);
        if (!editor.selection && value?.length) {
            Transforms.select(
                editor,
                savedSelection.current ?? Editor.end(editor, [])
            );
        }
    }, [editor]);

    const onBlur = React.useCallback(() => {
        setFocused(false);
        savedSelection.current = editor.selection;
    }, [editor]);



    const focusEditor = React.useCallback(
        (e: React.MouseEvent) => {
            if (e.target === divRef.current) {
                ReactEditor.focus(editor);
                e.preventDefault();
            }
        },
        [editor]
    );



    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const keys = {
            Enter: () => {
                const options: Partial<SlateElement> = { type: 'paragraph', children: [{ text: '' }] };
                const [idx] = editor?.selection?.anchor?.path || []
                const prevChild: any = editor?.children?.[idx === 0 ? idx : idx - 1]
                console.log('onKeyUp', editor)
                if (prevChild?.type === 'page-break') {
                    Transforms.setNodes(editor, options)
                }
            }
        };
        keys?.[event?.key as keyof typeof keys]?.();
    };
    const insertNode = React.useCallback((node: any) => {
        if (editor.selection) {
            Transforms.select(editor, editor.selection);
        }
        Transforms.insertNodes(editor, node);
    }, [editor, Transforms])

    return (
        <Box ref={divRef} onMouseDown={focusEditor}>
            <Slate
                editor={editor}
                value={value}
                onChange={(newValue) => setValue(newValue)}
            >
                <Toolbar
                    isDisabled={isDisabled}
                    hasSnippets={hasSnippets}
                    insertNode={insertNode} />
                <Box cursor={isDisabled ? 'not-allowed' : 'revert'} >
                    <Box
                        sx={{
                            ul: { marginLeft: '20px' },
                            ol: { marginLeft: '20px' },
                        }}
                        borderWidth={isValid ? 2 : 1}
                        borderRadius={6}
                        p={2}
                        mt={2}
                        minH={100}
                        {...{
                            ...(isDisabled ? {
                                borderColor: '#F1F4F6',
                                pointerEvents: canBeCopied ? 'auto' : 'none',
                                color: 'blackAlpha.700',
                                cursor: canBeCopied ? 'auto' : 'not-allowed',
                                bg: '#F2F2F2',
                            } : {
                                borderColor: isValid ? 'red.500' : '#ccc',
                                pointerEvents: 'auto',
                                color: 'revert',
                                bg: 'revert'
                            })
                        }}
                        {...props}
                    >
                        <Editable
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onFocus={onFocus}
                            placeholder="Enter some rich text…"
                            onBlur={onBlur}

                            onKeyUp={onKeyUp}
                            spellCheck
                            autoFocus
                        />
                    </Box>
                </Box>
            </Slate>
        </Box>
    );
}

export { RichTextEditor };
