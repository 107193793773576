import React from "react";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import { forms, fields } from '~/pages/projects/meta/data/correspondence.schema';
import { Form, renderField } from "~/components/Form/Form";
import { useModal, useDebounce, useYupValidationResolver, useViewer, usePermission } from "~/hooks";
import { Korrespondenz } from "~/gql/ucpw/graphql";
import { join, resolveFormFields } from "~/utils";
import { HasPermission } from "~/layout/HasPermission";
import { isEqual } from "lodash";
import log from "~/log";

export const CorrespondenceModal = (props?: any) => {
    const [submitting, setSubmitting] = React.useState('');
    const { state, dispatch, onClose } = useModal();
    const { createMutation, updateMutation, createTaskMutation, createTaskFunction, deleteTaskFunction } = state?.modals?.props || props || {}
    const subprojectId = state?.modals?.props?.subprojectId || props?.subprojectId || state?.row?.subprojekt?.id || state?.rows?.row?.subprojekt?.id

    const viewer = useViewer()

    const editorEmployeeId = viewer.app?.mitarbeiterId;

    const { row: correspondence = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.correspondence, fields.correspondence);
    const id = useDebounce(rowId, 200)

    const defaultValues = {
        ...formFields.defaultValues,
        time: new Date(),
        ...formFields.toForm({ ...correspondence, ...(!rowId && editorEmployeeId && { mitarbeiterIdSachbearbeiter: editorEmployeeId }) }),

    };
    log.debug('useCorrespondence.CorrespondenceModal', { id, rowId, correspondence, subprojectId, state, defaultValues, formFields, })
    log.debug('yup', { formFields })

    const { handleSubmit, control, register, formState: { errors } } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object({
            typeSnippetId: yup.object().nullable().required('Typ ist erforderlich'),
            comment: yup
                .array()
                .test(
                    'comment',
                    'Bemerkung ist erforderlich',
                    (value) => {
                        console.log('yup', value)
                        return !isEqual(value, [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: ""
                                    }
                                ]
                            }
                        ])
                    }
                ),
        })),
    });

    /** Permit */
    const hasEditAllCorrespondence = viewer.hasPermission('additional.editAllCorrespondence', 'enabled');
    const { canCreate, canEdit, canView } = usePermission('project.correspondence');
    const readOnly = rowId && canView && !(canCreate || canEdit || hasEditAllCorrespondence);
    let canEditCorrespondence = false;
    if (!rowId) {
        canEditCorrespondence = canCreate || hasEditAllCorrespondence;
    } else if (rowId) {

        if (hasEditAllCorrespondence) {
            canEditCorrespondence = true;
        }

        if (canEdit && (correspondence?.mitarbeiterIdUrheber === editorEmployeeId || correspondence?.mitarbeiterIdUrheber === null)) {
            canEditCorrespondence = true;
        }
    }
    const modalTitle = readOnly || !canEditCorrespondence ? 'Korrespondenz ansehen' : rowId && canEditCorrespondence ? 'Korrespondenz bearbeiten' : 'Neue Korrespondenz';
    const cancelButtonName = modalTitle === 'Korrespondenz ansehen' ? 'Schließen' : 'Abbrechen';
    const isCloseButton = cancelButtonName === 'Schließen';
    const cursor = isCloseButton ? 'not-allowed' : 'auto';



    const onSubmit = React.useCallback(async (values: any) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const addValues = {
            subprojectId: subprojectId || (correspondence as Korrespondenz).subprojektId,
        };
        log.debug('onSubmit.addValues', addValues);
        const data = formFields.toGql(values, addValues) as any;
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));
        const response = id
            ? await updateMutation({
                variables: {
                    id,
                    version: correspondence?.version,
                    forceOverwrite: true,
                    data: {
                        ...data,
                        subprojektId: subprojectId
                    },
                },
            }) : await createMutation({ variables: { data: { ...data, mitarbeiterIdUrheber: editorEmployeeId, subprojektId: subprojectId } } });
        log.debug('onSubmit.response', response);
        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    }, [id, editorEmployeeId, subprojectId, correspondence])

    const onSubmitWithOnClose = async (values: any) => {
        setSubmitting('submit')
        await onSubmit(values);
        onReset();
    };


    const onSubmitWithRedirect = async (values: any) => {
        setSubmitting('submit-and-task')
        await onSubmit(values);
        dispatch?.({ type: 'resetState' });
        dispatch?.({ type: 'setModal', data: { modal: 'TaskModal', props: { createMutation: createTaskMutation, createTaskFunction, deleteTaskFunction } } });
        dispatch?.({ type: 'setRow', data: { row: { original: { subprojekt: correspondence?.subprojekt || {} } } } });
        dispatch?.({ type: 'formState', data: values });
        setSubmitting('')
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
        setSubmitting('')
    };


    return <ModalContent maxWidth="container.md" rounded="none" >
        <ModalHeader
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            borderBottomWidth={1}
            borderColor="gray.200"
            mb={6}
            p={5}>
            {modalTitle}
            <HStack>
                <Button data-test-id="button-cancel" isDisabled={!!submitting} variant="outline" onClick={onReset}>
                    {cancelButtonName}
                </Button>
                {canEditCorrespondence &&
                    <>
                        <HasPermission resource="project.tasks" permission="create">
                            <Button
                                isLoading={submitting === 'submit-and-task'}
                                isDisabled={submitting === 'submit'}
                                data-test-id="button-save-correspondence-task-next"
                                variant="outline"
                                onClick={handleSubmit(onSubmitWithRedirect)}
                            >
                                Speichern + Aufgabe
                            </Button>
                        </HasPermission>
                        <Button
                            isLoading={submitting === 'submit'}
                            isDisabled={submitting === 'submit-and-task'}
                            data-test-id="button-save-correspondence"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmitWithOnClose)}
                        >
                            Speichern
                        </Button>
                    </>}
            </HStack>
        </ModalHeader>
        <ModalBody cursor={cursor}>
            <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                <SimpleGrid spacing={4} columns={1} mb={6}>
                    {formFields.fields.map((field: any) => (
                        <React.Fragment key={field.name}>
                            {renderField({
                                field,
                                control,
                                register,
                                errors,
                                context: {
                                    errors,
                                    subprojectId,
                                    canEditCorrespondence,
                                    meta: {
                                        employeeId: {
                                            value: correspondence?.sachbearbeiter?.id,
                                            label: join([correspondence?.sachbearbeiter?.name, correspondence?.sachbearbeiter?.vorname])
                                        }
                                    },
                                },
                            })}
                        </React.Fragment>
                    ))}
                </SimpleGrid>
            </Form>
        </ModalBody>
    </ModalContent>
}