import * as Sentry from '@sentry/react';
import { Breadcrumb, CaptureContext, SeverityLevel } from '@sentry/types';
import { get, pick } from 'lodash';
import { format } from 'date-fns';
import { isJsonString } from './richText';
import log from '~/log';

const actions = {
    addBreadcrumb: ({ code, message, data, level }: any) => {
        const breadcrumb = { category: code, message, data, level };
        console.log('Sentry.actions.addBreadcrumb', breadcrumb);
        return breadcrumb;
    },
    captureMessage: ({ message, code }: any) => {
        const msg = [
            code,
            [message, `[${format(new Date(), 'dd.MM.yyyy HH:mm:ss')}]`].filter(Boolean).join(' '),
        ]
            .filter(Boolean)
            .join(': ');
        console.log('Sentry.actions.captureMessage', msg);
        return msg;
    },
};

type Actions = 'addBreadcrumb' | 'captureMessage';

type ContextType = {
    [key: string]: any;
    captureMessage?: (message: string, captureContext?: CaptureContext | SeverityLevel) => string;
    captureException?: (exception: any, captureContext?: CaptureContext) => string;
    addBreadcrumb?: (breadcrumb: Breadcrumb) => void;
    onCompleted?: (args?: any) => void;
    onError?: (args?: any) => void;
};

export async function mutationGuard(
    mutation: (args?: any) => any,
    context: ContextType = {}
): Promise<any> {
    const { variables } = context;
    log.debug('Sentry.mutationGuard', { mutation, context, variables });
    let response;
    try {
        response = await mutation(variables?.variables ? variables : { variables });
        log.debug('Sentry.mutationGuard.response', response);
        const error = get(response, context?.path);
        const item = get(response, context?.path?.replace('error', 'item'));
        if (error) {
            throw new Error(JSON.stringify({ error, item }));
        }
        context?.onCompleted?.();
    } catch (err) {
        const { message } = (err as Error) || {};
        const { error, item = null } = isJsonString(message)
            ? JSON.parse(message)
            : { error: { message } };

        const data = {
            ...error,
            ...(item && { data: pick(item, '__typename', 'id', 'version') }),
            level: 'error',
        };
        log.debug('Sentry.mutationGuard.catch.error', data);

        if (
            context?.actions &&
            Array.isArray(context?.actions) &&
            context?.actions?.every((action) => typeof action === 'string')
        ) {
            context?.actions?.forEach(
                (action: Actions) =>
                    action in actions &&
                    action in Sentry &&
                    Sentry?.[action as Actions]?.(actions?.[action]?.(data) as any)
            );
        } else {
            Object.entries(context)?.forEach(([k, v]: any) => {
                if (k in Sentry) {
                    if (typeof v === 'function') {
                        return Sentry?.[k as 'addBreadcrumb' | 'captureMessage']?.(v?.(data));
                    }
                    return Sentry?.[k as 'addBreadcrumb' | 'captureMessage']?.(v);
                }
            });
        }
        context?.onError?.();
    } finally {
        // TODO: remember what this was for!
        // if (variables?.forceOverwrite || variables?.input?.forceOverwrite) {
        //     context?.onCompleted?.();
        // } else {
        //     context?.onError?.();
        // }
        return response;
    }
}

export function addBreadcrumb({ code, message, data, ...overrides }: any) {
    const breadcrumb = { category: code, message, data, level: 'info', ...overrides };
    console.log('Sentry.addBreadcrumb', breadcrumb);
    Sentry.addBreadcrumb(breadcrumb);
}

export function captureMessage({ message, code }: any) {
    const msg = [
        code,
        [message, `[${format(new Date(), 'dd.MM.yyyy HH:mm:ss')}]`].filter(Boolean).join(' '),
    ]
        .filter(Boolean)
        .join(': ');
    console.log('Sentry.captureMessage', msg);
    Sentry.captureMessage(msg);
}
