import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useModal } from '~/hooks/useModal';

export const GlobalModal = () => {
    const { isOpen = false, onClose, dispatch, component, state } = useModal();
    const { scrollBehavior, initialFocusRef } = state?.modals?.props || {}
    return (
        <Modal
            initialFocusRef={initialFocusRef && initialFocusRef}
            scrollBehavior={scrollBehavior && scrollBehavior}
            isOpen={isOpen}
            onClose={onClose as any}
            data-test-id='global-modal'
            motionPreset='none'
            // motionPreset='slideInBottom'
            onOverlayClick={() => {
                dispatch?.({ type: 'resetState' });
                onClose?.()
            }}
        >
            {isOpen && <ModalOverlay />}
            {component || null}
        </Modal >
    );
};


