import { Node } from 'slate';
import { flatten } from 'flat';

export function isJsonString(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function serialize(nodes = []) {
    return nodes?.map?.((n) => Node.string(n)).join('\n');
}

export function replaceHtml(str: string) {
    return str
        ?.replace(/<br>/g, '\n')
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, ' ');
}

export function richtextToString(richtext: string) {
    return isJsonString(richtext)
        ? Object?.entries?.(flatten?.(JSON.parse(richtext)) || {})
              .reduce(
                  (acc: any[], [k, v]: any) => (k.includes('text') ? [...acc, v ? v : '\n'] : acc),
                  []
              )
              .join('\n')
        : null;
}
