import { useSecurity } from '@ucc/react/security';
import { SignIn } from '.';
import { SsoSignIn } from './SsoSignIn';

export function SignInMethods() {
    const { authService } = useSecurity();

    const signIn = (data: any) => {
        authService.send({
            type: 'LOGIN',
            data: { email: data.username, password: data.password },
        });
    };

    const ssoSignIn = () => {
        console.log('SSO_LOGIN');
        authService.send({ type: 'SSO_LOGIN' });
    };

    return localStorage.getItem('PASSWORD_LOGIN') ? (
        <SignIn signIn={signIn} />
    ) : (
        <SsoSignIn federatedSignIn={ssoSignIn} />
    );
}
