import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ListVacantPositionsDocument, GetProjectByLfdNrDocument } from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables, controlling } from '~/pages/master-data/meta/data/controlling.schema';
import { useDataLoader } from '~/hooks/useDataLoader';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { ControllingStates } from '~/pages/master-data/types';
import log from '~/log';

export const useControlling = ({
    branchId,
    employeeId,
    only28DaysAndOlder,
}: ControllingStates = {}) => {
    log.debug('useControlling', { branchId, employeeId, only28DaysAndOlder });
    const navigate = useNavigate();
    const [lfdNr, setLfdNr] = React.useState<number | null>(null);

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const context = { clientName: 'ucpw' };

    const [load, { data, loading: vacantPositionslazyLoading }] = useLazyQuery(
        ListVacantPositionsDocument,
        { context }
    );
    const vacantPositions: any = data?.app?.item?.items || [];
    const totalSalesVolume = data?.app?.item.gesamtUmsatz || 0;
    const { pageCount = 0, totalCount: searchCount = 0 } = data?.app?.item?.pageInfo || {};

    const { fetchData } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, {
        niederlassungId: branchId,
        mitarbeiterId: employeeId,
        only28DaysAndOlder,
    });

    // ===============================================
    // Redirect to project
    // ===============================================

    const { data: projectData } = useQuery(GetProjectByLfdNrDocument, {
        skip: !Boolean(lfdNr),
        variables: { lfdNr: lfdNr as number },
        context,
    });

    const projectId = projectData?.app?.item?.item?.id;
    React.useEffect(() => {
        if (projectId) {
            navigate(`/projekte/${projectId}`);
        }
    }, [projectId]);

    const columns = React.useMemo(() => withRenderers(tables.controlling.columns), []);
    const pageSize = controlling?.tableProps?.pageSize;
    const loading = vacantPositionslazyLoading;

    const onClick = ({ original }: any) => {
        const [lfdNr] = original?.projekt?.split('-')?.reverse();
        lfdNr && setLfdNr(parseInt(lfdNr, 10));
    };

    return {
        totalSalesVolume,
        searchCount,
        columns,
        data: vacantPositions,
        loading,
        fetchData: fetchDataWithFilters,
        pageCount,
        pageSize,
        onClick,
    };
};
