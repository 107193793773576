import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import * as graphql from '@ucc/graphql/documents/roles';
import { useNavigate } from 'react-router-dom';
import { tables } from '~/pages/auth/meta/data/auth.schema';
import { withRenderers } from '~/utils/withRenderers';
import { permissionGroups as permissionGroupsConfig } from '../config/permissionGroups';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { usePermission } from '~/hooks';

export const useAuth = ({ id }: { id?: string } = {}) => {
    const navigate = useNavigate();
    const { canEdit, canDelete } = usePermission('staff.permissions');

    // ===============================================
    // LIST
    // ===============================================

    const {
        data: listData,
        loading: listLoading,
        error: listError,
    } = useQuery(graphql.listRoles, { skip: !!id });
    const roles = listData?.security?.roles?.data?.filter((role: any) => !role.system) || [];

    // ===============================================
    // GET
    // ===============================================

    const isNew = !id || id === 'neu';

    const {
        data: getData,
        loading: getLoading,
        error: getError,
    } = useQuery(graphql.getRole, {
        variables: { id: id! },
        skip: isNew,
    });
    const role = getData?.security?.role?.data;
    const rolePermissionGroups = role?.permissionGroups || [];

    const mergePersmissionGroups = (config: any = [], remote: any[] = []) =>
        Object.values(
            config?.reduce((acc: any, group: any) => {
                const groupMatch = remote.find((remoteGroup: any) => remoteGroup.id === group.id);
                const groupMatchResources = groupMatch?.resources || [];
                const resources = group.resources?.reduce((acc: any, resource: any) => {
                    const resourceMatch = groupMatchResources.find(
                        (remoteResource: any) => remoteResource.id === resource.id
                    );

                    return {
                        ...acc,
                        [resource.id]: resourceMatch || resource,
                    };
                }, {});

                return {
                    ...acc,
                    [group.id]: groupMatch
                        ? {
                              ...groupMatch,
                              resources: Object.values(resources),
                          }
                        : group,
                };
            }, {})
        );

    const permissionGroups = React.useMemo(
        () =>
            rolePermissionGroups.length
                ? mergePersmissionGroups(permissionGroupsConfig, rolePermissionGroups)
                : permissionGroupsConfig,
        [mergePersmissionGroups, permissionGroupsConfig, rolePermissionGroups]
    );

    const refetchQueries = [
        { query: graphql.listRoles },
        { query: graphql.getRole, variables: { id } },
    ];

    // ===============================================
    // CREATE
    // ===============================================

    const [createMutation] = useMutation(graphql.createRole, {
        refetchQueries,
    });

    // ===============================================
    // UPDATE
    // ===============================================

    const [updateMutation] = useMutation(graphql.updateRole, {
        refetchQueries,
    });

    // ===============================================
    // DELETE
    // ===============================================

    const [deleteMutation] = useMutation(graphql.deleteRole, {
        refetchQueries,
    });

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries,
    });

    // ===============================================
    // TABLE
    // ===============================================

    const controls = [
        {
            title: canEdit ? 'Bearbeiten' : 'Ansehen',
            props: {
                onClick: (row: any) => {
                    navigate(`/personal/berechtigungen/${row?.original?.id}`);
                },
            },
        },
        canEdit && {
            title: 'Kopieren',
            props: {
                onClick: (row: any) => {
                    navigate(`/personal/berechtigungen/${row?.original?.id}`, {
                        state: {
                            isCopy: true,
                        },
                    });
                },
            },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(() => withRenderers(tables.roles.columns, controls), []);
    const hiddenColumns = tables.roles.hiddenColumns;
    const pageSize = tables?.roles?.pageSize || 50;

    const isReadLoading = listLoading || getLoading;
    const isReadError = listError || getError;

    return {
        roles,
        role: { ...role, permissionGroups },
        isReadLoading,
        isReadError,
        columns,
        hiddenColumns,
        pageSize,
        createMutation,
        updateMutation,
        deleteMutation,
        deleteWithConfirmation,
    };
};
