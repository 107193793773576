export function generateProjectNumberOutOfSubprojectData(subproject: Partial<any>) {
    const projectNumber = generateProjectNumber(
        subproject?.projekt?.anlageDatum,
        subproject?.projekt?.niederlassung?.nummer,
        subproject?.projekt?.lfdNr,
        subproject?.lfdNr
    );
    return projectNumber.includes('NaN') ? 'loading ...' : projectNumber;
}

export function generateProjectNumber(
    date: string,
    niederlassung: number,
    lfdNr: string | number,
    ...args: (string | number)[]
) {
    const datePrefix = new Date(date).getFullYear().toString().slice(2, 4);

    const ndlgDividedByHundredth = niederlassung / 100;
    const leftPadNdlgDividedByHundredth =
        ndlgDividedByHundredth >= 10
            ? ndlgDividedByHundredth.toString()
            : '0' + ndlgDividedByHundredth;

    return [date, niederlassung, lfdNr].every(Boolean)
        ? [datePrefix, `${leftPadNdlgDividedByHundredth}`.replace('.', ''), lfdNr, ...args]
              ?.filter(Boolean)
              ?.join('-')
        : '';
}
