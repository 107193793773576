import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useSnippetId, useYupValidationResolver } from '~/hooks';
import {
    resolveFormFields,
    combineFields,
    formFieldsToGraphQl,
    graphQlToFormFields,
} from '~/utils';
import { fields, forms } from '~/pages/projects/meta/data/projects.schema';
import { pick } from 'lodash';

export const useSubProjectForm = () => {
    // form Fields
    const formFields = {
        object: resolveFormFields(forms.objectCreateSubproject, fields.project),
        project: resolveFormFields(forms.projectCreateSubproject, fields.project),
        client: resolveFormFields(forms.client, fields.project),
        insurance: resolveFormFields(forms.insurance, fields.project),
    };

    // merge multiple sections
    const form: any = [
        ...forms.objectCreateSubproject,
        ...forms.projectCreateSubproject,
        ...forms.client,
        ...forms.insurance,
    ];
    const formToGql = formFieldsToGraphQl(form, fields.createSubProject, false); // TODO: docs (false)
    const gqlToForm = graphQlToFormFields(form, fields.project);

    // Default (remote) values & Validation
    const {
        defaultValues,
        rules,
        watchFields: watchFieldsArray,
        watchFieldValues,
    } = combineFields(...Object.values(formFields));

    const { typSnippetId: subprojectCreditStandingSnippetId } = useSnippetId({
        name: 'ungeklärt',
        category: 'Subprojekt',
        property: 'Bonitaet',
    });

    // react-hook-form
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(rules)),
    });

    // watch
    const watched = watch();
    const watchFields = pick(watched, ...watchFieldsArray);
    // const watchFields = watch(watchFieldsArray); // this suddenly gives back an array instead of an object
    React.useEffect(() => {
        watchFieldValues.map(({ name, resolveValue }: any) => {
            const { enabled, getValue } = resolveValue(watchFields);
            if (enabled) {
                !(watched?.[name] === getValue(watchFields)) &&
                    setValue(name, getValue?.(watchFields));
            }
        });
    }, [watchFields, watched]);

    return {
        formToGql,
        gqlToForm,
        formFields,
        defaultValues,
        subprojectCreditStandingSnippetId,
        watchFields,
        watch,
        setValue,
        control,
        register,
        handleSubmit,
        errors,
        reset,
    };
};
