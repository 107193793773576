import React from 'react';
import { format } from 'date-fns';
import { useModal, usePermission } from '~/hooks';
import { useEventProjectdetails } from './useEventProjectDetails';

export const Modals = {
    ProjectSearch: 'ProjectSearch',
    CreateEvent: 'CreateEvent',
};

export function useAddEvent({ subproject, employeeId }: any) {
    const { canCreate } = usePermission('project.schedule')

    const { dispatch, onOpen } = useModal();
    const eventProjectDetails = useEventProjectdetails(subproject)

    return React.useCallback(
        (event: any) => {
            if (canCreate) {
                const currentDate = new Date(event?.date);
                const exlude = !['mbsc-def'].includes(event.resource);
                dispatch?.({
                    type: 'setModal',
                    data: { modal: Modals.CreateEvent },
                });
                dispatch?.({
                    type: 'formState',
                    data: {
                        ...eventProjectDetails,
                        title: 'Neuer Termin',
                        employeeId: { value: (exlude && event.resource) || employeeId },
                        time: format(currentDate, 'HH:mm'),
                        planed: format(currentDate, 'yyyy-MM-dd'),
                        duration: 30,
                    },
                });
                onOpen?.();
            }
        },
        [employeeId, eventProjectDetails, canCreate]
    );
}