import React, { ReactElement, cloneElement, PropsWithChildren } from 'react';
import { HiChevronUp, HiChevronDown } from 'react-icons/hi';
import styled from '@emotion/styled';
import {
    Flex,
    BoxProps,
    Box,
    Heading,
    HeadingProps,
    useColorModeValue,
    HStack,
    chakra,
    Collapse,
} from '@chakra-ui/react';

export interface SectionCardProps extends BoxProps {
    title?: string;
    body?: boolean;
    icon?: ReactElement;
    action?: boolean;
    actions?: ReactElement[];
    buttonText?: string;
    onClick?: (args?: any) => void;
    isRounded?: boolean;
    hasBorderTop?: boolean | string;
    hasDivider?: boolean;
    isCollapsible?: boolean;
}

/** This is needed when isCollapsible is enabled and Select Menu gets cutted by overflow hidden*/
export const CollapseTransition = styled(Collapse)`
  overflow: ${({ in: isEnabled }) => (isEnabled ? 'initial !important' : 'hidden')};
`

export const SectionCard = React.forwardRef(
    (
        {
            children,
            body = true,
            title,
            actions = [],
            isRounded,
            hasBorderTop: borderTopColor = true,
            hasDivider = true,
            isCollapsible = false,
            ...props
        }: PropsWithChildren<SectionCardProps>,
        ref: any
    ) => {
        const p = body ? 5 : 0;
        const [isOpen, setIsOpen] = React.useState(true);
        return (
            <Box
                {...(title && { 'data-test-id': [title, 'card'].join('-') })}
                {...(isRounded && { rounded: 3 })}
                {...(typeof borderTopColor === 'string'
                    ? { borderTopWidth: 2, borderTopColor }
                    : borderTopColor
                        ? { borderTopWidth: 2, borderTopColor: 'blue.500' }
                        : {})}
                bg={useColorModeValue('white', 'gray.700')}
                shadow="base"
                w="full"
                maxW="768px"
                {...props}
            >
                {(title || Boolean(actions.length)) && (
                    <HStack
                        {...(hasDivider &&
                            children && { borderBottomWidth: 1, borderColor: 'gray.200' })}
                        alignItems="center"
                        p="26px 24px 25px 24px"
                        flex={1}
                        {...(isCollapsible && {
                            onClick: () => setIsOpen((prev) => !prev),
                            cursor: 'pointer',
                            userSelect: 'none',
                        })}
                    >
                        <SectionCardHeader ref={ref}>{title} </SectionCardHeader>
                        <HStack w="full" justify="flex-end" minH={10}>
                            {isCollapsible && (
                                <chakra.span px={3}>
                                    {isOpen ? <HiChevronUp /> : <HiChevronDown />}
                                </chakra.span>
                            )}
                            {Boolean(actions.length) &&
                                actions.map((action, key) =>
                                    cloneElement(action, { key: action?.props?.children || key })
                                )}
                        </HStack>
                    </HStack>
                )}
                <CollapseTransition animateOpacity in={isOpen} >
                    <Flex p={p} flexDir="column">
                        {children}
                    </Flex>
                </CollapseTransition>
            </Box>
        );
    }
);

export const SectionCardHeader = React.forwardRef((props: HeadingProps, ref: any) => (
    <Heading
        ref={ref}
        display="flex"
        alignItems="center"
        as="h5"
        fontSize={18}
        fontWeight={700}
        lineHeight={7}
        w="full"
        {...props}
    />
));
