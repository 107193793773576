import React from 'react';
import { pick } from 'lodash';
import { useInterpret, useActor } from '@xstate/react';
import { interpret } from 'xstate';
import { Aufgabe } from '~/gql/ucpw/graphql';
import { createTaskPermissionsMachine } from './taskPermissionsMachine';
import { useViewer } from '~/hooks';
import log from '~/log';

const permissions = [
    {
        id: 'project.tasks',
        permissions: ['view', 'create', 'update', 'delete', 'all'],
    },
    {
        id: 'additional.closeTask',
        // permissions: ['enabled'],
        permissions: [],
    },
    {
        id: 'additional.reopenTask',
        permissions: ['enabled'],
    },
];

const mitarbeiterFunktionen = [
    {
        funktion: {
            bezeichnung: 'Thermographie',
        },
    },
    {
        funktion: {
            bezeichnung: 'Niederlassungsleiter',
        },
    },
    // {
    //     funktion: {
    //         bezeichnung: 'Goll',
    //     },
    // },
    {
        funktion: {
            id: 3,
            bezeichnung: 'I-Nadis',
        },
    },
];

export const useTaskPermissions = (task?: Aufgabe) => {
    const viewer = useViewer();
    const demoViewer = {
        ...viewer,
        employee: { ...viewer?.employee, id: 6267481, mitarbeiterFunktionen },
        permissions,
    };
    const permissionIds = [
        'project.tasks',
        'additional.closeTask',
        'additional.reopenTask',
        'additional.editAllTasks',
    ];
    const taskPermissionService = useInterpret(createTaskPermissionsMachine, {
        context: {
            task,
            viewer,
            // viewer: demoViewer,
            permissionIds,
        },
    });
    const [state] = useActor(taskPermissionService);
    const context = state?.context;

    React.useEffect(() => {
        const subscription = taskPermissionService.subscribe((state: any) => {
            const { value, context } = state;
            log.debug('useTaskPermissions.subscription', {
                value,
                context,
            });
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [taskPermissionService]);

    const getTaskPermission = (task: Aufgabe): any => {
        let res = {};
        const service = interpret(
            createTaskPermissionsMachine().withContext({
                task,
                viewer,
                // viewer: demoViewer,
                permissionIds,
            })
        ).onTransition((state) => {
            res = pick(state, 'value', 'context');
        });
        service.start();

        return res;
    };

    return { context, getTaskPermission };
};
