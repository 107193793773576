import { isJsonString } from './richText';

export const readSession = (key: string, initialValue: any) => () => {
    console.log('readSession', { key, initialValue });
    if (typeof window === 'undefined') {
        return initialValue;
    }
    try {
        const item = sessionStorage.getItem(key);
        return item && isJsonString(item) ? JSON.parse(item) : initialValue;
    } catch (error) {
        console.warn(`Error reading sessionStorage key “${key}”:`, error);
        return initialValue;
    }
};

export const setSession = (key: string, value: any) => {
    let status = true;
    if (typeof window == 'undefined') {
        console.warn(
            `Tried setting sessionStorage key “${key}” even though environment is not a client`
        );
    }

    try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.warn(`Error setting sessionStorage key “${key}”:`, error);
        status = false;
    }

    return status;
};

export const parseJSON = <T>(value: string | null): T | undefined => {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '');
    } catch {
        console.log('parsing error on', { value });
        return undefined;
    }
};

export const getSizeInBytes = (obj: any) => {
    let str = null;
    if (typeof obj === 'string') {
        str = obj;
    } else {
        str = JSON.stringify(obj);
    }
    const bytes = new TextEncoder().encode(str).length;
    return bytes;
};

export function logSizeInBytes(description: any, obj: any) {
    const bytes = getSizeInBytes(obj);
    console.log(`${description} is approximately ${bytes} B`);
}
