import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataTable, Card, CardHeader, CardActions, CardContent, CardHeading, PlusButton } from '@ucc/react/ui';
import { VStack, Menu, MenuButton, MenuList, MenuItem, Button, Box } from '@chakra-ui/react';
import { useRelatedProjects } from '~/pages/projects/hooks/useRelatedProjects';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useProject } from '../hooks/useProjekt';
import { join } from '~/utils';
import { useOpenTasks } from '../hooks/useOpenTasks';
import { useCorrespondence } from '../hooks/useCorrespondence';
import { useModal } from '~/hooks';
import { MultiModal } from '~/components/MultiModal';
import { CorrespondenceModal } from './CorrespondenceModal';
import { TaskModal } from './Task/TaskModal';
import { CreateEventModalContent, ProjectSearchModalContent } from './Calendar/EventModal';
import { combineReducers, formStateReducer, modalReducer, rowReducer } from '~/reducers';
import { GoToScheduleButton } from '~/pages/projects/components/GoToScheduleButton';
import { HasPermission } from '~/layout/HasPermission';

export function ProjectOverview(props: any) {
    const navigate = useNavigate();
    const params = useParams();
    const projectId = props?.projectId ? props?.projectId : params?.projectId ? parseInt(params?.projectId) : params?.projectId;
    const relatedProjects = useRelatedProjects({ projectId });
    const project = useProject({ projectId });
    const nth0Subproject: any = relatedProjects.data[0];
    const [activeSubproject, setActiveSubproject] = React.useState(nth0Subproject);
    const openTasks = useOpenTasks({
        tableName: 'task',
        filter: { erledigtExists: false, zuErledigenNotInFuture: true, subprojekt: { projektId: projectId } },
    });
    const correspondence = useCorrespondence({ projectId });

    useModal({
        defaultState: { modals: { activeModal: 'CorrespondenceModal' } },
        component: (
            <MultiModal>
                <CorrespondenceModal id="CorrespondenceModal" />
                <TaskModal id="TaskModal" />
                <CreateEventModalContent id="CreateEvent" />
                <ProjectSearchModalContent id="ProjectSearch" />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('CorrespondenceModal'),
            formState: formStateReducer,
            row: rowReducer,
        }),
    });

    React.useEffect(() => {
        setActiveSubproject(nth0Subproject);
    }, [nth0Subproject]);

    const getCopyPath = (subprojectId: number) => `/projekt/${projectId}/${subprojectId}/kopieren`;

    return (
        <VStack spacing={6} p={6}>
            <Card boxShadow="none">
                <DataTable
                    enableSorting={false}
                    columns={project.columns}
                    hiddenColumns={project.hiddenColumns}
                    data={[project.data]}
                    loading={project.loading}
                />
            </Card>
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>Verbundene Projekte</CardHeading>
                    <HasPermission resource="project.project" permission="create">
                        <CardActions>
                            {relatedProjects.data.length > 1 ? (
                                <Menu>
                                    {({ isOpen }) => (
                                        <>
                                            <MenuButton
                                                as={Button}
                                                isActive={isOpen}
                                                rightIcon={
                                                    isOpen ? <FiChevronUp /> : <FiChevronDown />
                                                }
                                                colorScheme="blue"
                                            >
                                                Subprojekt kopieren
                                            </MenuButton>
                                            <Box>
                                                <MenuList>
                                                    {relatedProjects.data.map((subproject: any) => (
                                                        <MenuItem
                                                            key={subproject?.id}
                                                            onClick={() =>
                                                                navigate(
                                                                    getCopyPath(subproject?.id)
                                                                )
                                                            }
                                                        >
                                                            {join(
                                                                [
                                                                    project.data.lfdNr,
                                                                    subproject.lfdNr,
                                                                ],
                                                                '-'
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Box>
                                        </>
                                    )}
                                </Menu>
                            ) : (
                                <PlusButton to={getCopyPath(nth0Subproject?.id as any)}>
                                    Subprojekt kopieren
                                </PlusButton>
                            )}
                        </CardActions>
                    </HasPermission>
                </CardHeader>
                <CardContent>
                    <DataTable
                        // onMouseEnter={(data) => setActiveSubproject(data?.original)}
                        onSortChange={relatedProjects?.onSortChange}
                        columns={relatedProjects.columns}
                        hiddenColumns={relatedProjects.hiddenColumns}
                        data={relatedProjects.data}
                        loading={relatedProjects.loading}
                        onClick={({ original }) =>
                            navigate(`/projekte/${projectId}/${original.id}/details`)
                        }
                    />
                </CardContent>
            </Card>
            <HasPermission resource="project.tasks" permission="view">
                <Card boxShadow="none">
                    <CardHeader>
                        <CardHeading>Offene Aufgaben - alle Gewerke</CardHeading>
                    </CardHeader>
                    <CardContent>
                        <DataTable {...openTasks} />
                    </CardContent>
                </Card>
            </HasPermission>
            <HasPermission resource="project.correspondence" permission="view">
                <Card boxShadow="none">
                    <CardHeader>
                        <CardHeading>Korrespondenzen</CardHeading>
                        <HasPermission resource="project.schedule" permission="view">
                            <CardActions>
                                <GoToScheduleButton
                                    state={{
                                        projectId: projectId,
                                    }}
                                    isDisabled={[600, 700].includes(
                                        project?.data?.niederlassung?.nummer as number
                                    )}
                                />
                            </CardActions>
                        </HasPermission>
                    </CardHeader>
                    <DataTable {...correspondence} />
                </Card>
            </HasPermission>
        </VStack>
    );
}
