import React from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollIntoViewAnchorRefs(...args: string[]): React.RefObject<unknown>[] {
    const location = useLocation();
    const refs = React.useRef<any[]>(
        [...args]?.map?.((name) => ({ name, hash: '#' + name, ref: React.createRef() }))
    );

    React.useEffect(() => {
        if (refs?.current) {
            const reference = refs?.current?.find((item) => item?.hash === location?.hash);
            if (reference) {
                reference.ref.current?.scrollIntoView();
            }
        }
    }, [refs, history]);

    return refs.current.map(({ ref }) => ref);
}
