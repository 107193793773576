import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useConfirmationDialog } from './useConfirmationDialog';

export async function deleteMutation({
    variables: {},
    refetchQueries = [],
}): Promise<Record<string, any>> {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    return {
        data: {
            item: {
                item: {},
                error: null,
            },
        },
    };
}

type DeleteWithConfirmationHookProps = {
    deleteMutation?: any;
    refetchQueries?: any[];
    onSuccessMessage?: (args?: any) => string;
    dependencies?: any[];
    callBefore?: (args?: any) => void;
    callAfter?: (args?: any) => void;
};

export const useDeleteWithConfirmation = (config?: DeleteWithConfirmationHookProps) => {
    const {
        deleteMutation: controlledDeleteMutation = deleteMutation,
        refetchQueries = [],
        dependencies = [],
        onSuccessMessage,
        ...props
    } = config || {};
    const toast = useToast();
    const context = useConfirmationDialog();
    const { setInterpreterOptions, dialogContext, dialogService } = context;

    const deleteWithConfirmation = React.useCallback(
        (
            variables = {},
            context?: {
                callBefore?: (args?: any) => void;
                callAfter?: (args?: any) => void;
            }
        ) => {
            const mergeActions = { ...props, ...context };
            dialogService.send({ type: 'SET_VARIABLES', variables });
            setInterpreterOptions?.({
                actions: {
                    ...(mergeActions?.callBefore && { onConfirmEntry: mergeActions?.callBefore }),
                    ...(mergeActions?.callAfter && { onConfirmExit: mergeActions?.callAfter }),
                },
                services: {
                    onConfirm: async (ctx: any, event: any) => {
                        const { data } = await controlledDeleteMutation({
                            variables: ctx?.variables || variables || {},
                            refetchQueries,
                        });
                        const { item, error } = data?.item || {};

                        if (error && !(error?.code === 'OutdatedVersion')) {
                            toast({
                                title: 'Error',
                                description: error?.message || '',
                                status: 'error',
                                duration: 5000,
                                isClosable: true,
                            });
                            return { item, error };
                        }

                        toast({
                            title: 'Löschvorgang',
                            description: onSuccessMessage
                                ? onSuccessMessage(item)
                                : `${item?.id || ctx?.variables?.id || 'Datensatz'} wurde gelöscht`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });

                        return { item, error };
                    },
                },
            });
        },
        [dialogContext, ...dependencies]
    );

    return {
        deleteWithConfirmation,
        ...context,
    };
};
