import React from 'react';
import { useMachine } from '@xstate/react';
import { Button, HStack, ModalBody, ModalContent, ModalHeader, Badge, Text } from '@chakra-ui/react';
import { infiniteDataTableMachine, InfiniteDataTable } from '@ucc/react/ui';
import { SearchField } from '~/components/Form/SearchField';
import { useModal } from '~/hooks/useModal';
import { useCalculationAgreements } from '~/pages/projects/hooks/useCalculationAgreements';
import { ProjectIds } from '~/pages/projects/types';
import { Modals } from '~/pages/projects/ui/Finance/Finance';
import { ListGeneralAgreementPostionDocument, Rahmenvertragsposition, SortOrder } from '~/gql/ucpw/graphql';
import { FinanceContext } from './financeMachine';
import { client } from '~/apollo'
import log from '~/log'


export const SelectGeneralAggrementsModalContent = ({
    projectId,
    subprojectId,
    generalAgreementId,
    context,
    limit = 30,
    send: sendToFinanceMachine,
}: ProjectIds & {
    id: string;
    generalAgreementId: number;
    generalAgreementPositionIds?: number[];
    limit: number;
    context?: FinanceContext;
    send?: (args: any) => void
}) => {
    const { onClose, dispatch, ...modal } = useModal();
    const props = modal?.state?.modals?.props || {};

    const { cache = {} } = context || {}
    const { byAgreement = {} } = context?.calculations || {}
    const generalAgreementPositionIds = cache?.generalAgreementPositionIds || byAgreement?.[generalAgreementId]?.generalAgreementPositionIds || []
    const [nrPrefix, setNrPrefix] = React.useState('');
    const [orderBy, setOrderBy] = React.useState([])


    const fetchData = React.useCallback(async ({ currentPage = 0, limit = 30 }: any, { variables: eventVariables = {} }: any) => {
        const context = { clientName: 'ucpw' }
        const variables = {
            limit,
            offset: currentPage * limit,
            filter: { rahmenvertragId: generalAgreementId, nrPrefix },
            orderBy: [{ nr: SortOrder.Asc }],
            ...(orderBy.length && { orderBy }),
            ...eventVariables

        }

        const response = await client.query({
            query: ListGeneralAgreementPostionDocument,
            context,
            variables,
            fetchPolicy: 'network-only',
        })
        return Promise.resolve({ variables, ...response })
    }, [generalAgreementId, nrPrefix, orderBy])

    const [state, send] = useMachine(infiniteDataTableMachine, {
        services: { fetchData }
    });

    const next = React.useCallback(() => {
        send({
            type: 'FETCH_MORE',
            variables: {
                filter: { rahmenvertragId: generalAgreementId, nrPrefix },
                ...(orderBy.length && { orderBy })
            }
        })
    }, [nrPrefix, orderBy, generalAgreementId])


    log.debug('SelectGeneralAggrementsModalContent.modals', modal.state.modals.activeModal === "SelectStandardGeneralAggrements")

    React.useEffect(() => {
        log.debug('infiniteDataTableMachine.context', state.context)
        log.debug('infiniteDataTableMachine.variables', JSON.stringify(state?.context?.variables, null, 2))
    }, [state])

    React.useEffect(() => {
        send({
            type: 'FETCH_INITIAL',
            variables: {
                filter: { rahmenvertragId: generalAgreementId, nrPrefix },
                ...(orderBy.length && { orderBy })
            }
        })
    }, [nrPrefix, orderBy])

    const {
        columns,
        hiddenColumns,
    } = useCalculationAgreements({
        projectId,
        subprojectId,
        generalAgreementPositionIds: generalAgreementPositionIds,
    });

    const onSortChange = (sort: any) => setOrderBy((prev: any) => sort?.length ? sort?.map((item: any) => ({ [item?.id]: item?.desc ? 'desc' : 'asc', })) : [...prev])

    const selectAgreement = (row: any) => {
        const original = row?.original as Rahmenvertragsposition;
        const position = {
            rahmenvertragspositionId: original?.id,
            preisProEinheit: original?.preisProEinheit,
            rahmenvertragsposition: {
                leistung: original?.leistung,
                beschreibung: original?.beschreibung,
            },
        };
        sendToFinanceMachine?.({ type: 'SET_CACHE', cache: ({ cache = {} }: any = {}) => ({ ...cache, row: position }) })
        // dispatch?.({ type: 'setAgreement', data: { row } });
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.EditGeneralAggrementPosition,
                props
            }
        });
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    const data = React.useMemo(() => state.context.data, [state.context.data])

    return (
        <ModalContent rounded="none" maxWidth="container.xl">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                p={5}
            >
                {modal?.state?.modals?.activeModal === "SelectStandardGeneralAggrements" ? 'Standard-Position auswählen' : 'RV-Position auswählen'}
                <HStack>
                    <SearchField
                        w={200}
                        placeholder="Suche Nr"
                        onChange={(e) => {
                            const prefix = e?.target?.value;
                            setNrPrefix(prefix);
                        }}
                    />
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody p={0}>
                <InfiniteDataTable
                    next={next}
                    totalCount={state.context.totalCount}
                    hasMore={state.context.pageCount === 1 ? false : !(state.context.currentPage === state.context.pageCount)}
                    onClick={selectAgreement}
                    pageSize={limit}
                    pageCount={state.context.pageCount}
                    currentPage={state.context.currentPage}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    data={data}
                    onSortChange={onSortChange}
                    loading={!state.matches('wait')}
                />
            </ModalBody>
        </ModalContent>
    );
};
