import React from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import {
    ListInsurancesDocument,
    CreateInsuranceDocument,
    UpdateInsuranceDocument,
    DeleteInsuranceDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';
import { resolveFormFields, withRenderers } from '~/utils';
import { fields, forms } from '~/pages/master-data/meta/data/insurances.schema';
import { tables } from '~/pages/master-data/meta/data/insurances.schema';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useDataLoader } from '~/hooks/useDataLoader';
import { useModal } from '~/hooks/useModal';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { Form, renderField } from '~/components/Form/Form';
import { useDebounce } from '~/hooks/useDebounce';
import log from '~/log';
import { usePermission } from '~/hooks/usePermission';
import { useFetchDataWithFilter } from '~/hooks';

const InsuranceModal = (props: any) => {
    const { createMutation, updateMutation, loading: isLoading } = props;
    const { state, dispatch, onClose } = useModal();
    const { row: insurance = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.insurances, fields.insurances);
    const insuranceId = useDebounce(rowId, 200);

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(insurance),
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({ defaultValues });

    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const data = formFields.toGql(values, {}) as any;
            log.debug('onSubmit.data', JSON.stringify(data, null, 2));
            const response = insuranceId
                ? await updateMutation({ variables: { id: insuranceId, data } })
                : await createMutation({ variables: { data } });
            log.debug('onSubmit.response', JSON.stringify(response, null, 2));
        },
        [insuranceId]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {insuranceId ? 'Versicherung bearbeiten' : 'Neue Versicherung'}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        isLoading={isLoading}
                        data-test-id="button-save-insurance"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={1} mb={6}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

export const useInsurances = () => {
    const { canEdit, canDelete } = usePermission('masterData.insurances');

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const [load, { data, loading: lazyLoading, refetch }] = useLazyQuery(ListInsurancesDocument, {
        context: { clientName: 'ucpw' },
    });
    const insurances: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData, variables } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, { orderBy: [{ bezeichnung: SortOrder.Asc }] });
    const contextAndRefetchQueries = useContextAndRefetchQueries(ListInsurancesDocument, variables);

    // ===============================================
    // (C)R(U)D
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateInsuranceDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateInsuranceDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteInsuranceDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading = lazyLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal({
        defaultState: { modals: { activeModal: 'InsuranceModal' } },
        component: <InsuranceModal {...{ createMutation, updateMutation, refetch, loading }} />,
        reducer: combineReducers({
            modals: modalReducer('InsuranceModal'),
            row: rowReducer,
        }),
    });

    // ===============================================
    // CONTROLS
    // ===============================================

    const onClick = React.useCallback(
        (row: any) => {
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: {
                onClick,
            },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.insurances.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.insurances.hiddenColumns;

    const onCreate = () => {
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    };

    return {
        onCreate,
        data: insurances,
        pageCount,
        hiddenColumns,
        columns,
        loading,
        fetchData: fetchDataWithFilters,
        onClick,
    };
};
