import {
    Box,
    Button,
    Container,
    Heading,
    Stack,
    useBreakpointValue,
    useColorModeValue,
    Center,
    chakra,
} from '@chakra-ui/react';
import poeppinghaus from '~/assets/logo_poeppinghaus.png';

interface SsoSignInProps {
    federatedSignIn?: () => void;
}

export const SsoSignIn = ({ federatedSignIn }: SsoSignInProps) => {
    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }} h="90vh">
            <Stack spacing="6" justify="space-between" h="full">
                <Center>
                    <chakra.img width={350} src={poeppinghaus} alt="company logo" />
                </Center>
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    <Heading className="signInHeading" size={useBreakpointValue({ base: 'xs' })}>
                        Willkommen im <br />
                        Pöppinghaus-Wenner Portal!
                    </Heading>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Button
                            size="lg"
                            w="full"
                            colorScheme="teal"
                            fontSize="md"
                            onClick={federatedSignIn}
                        >
                            Mit Microsoft-Konto anmelden
                        </Button>
                    </Box>
                </Stack>
                <Box />
            </Stack>
        </Container>
    );
};
