import { ModalContent, Button, ModalBody, Modal, ModalOverlay, VStack, FormLabel, Checkbox, Text, HStack } from "@chakra-ui/react"
import { CustomModalHeader } from "~/components/CustomModalHeader"
import { Wattro } from '~/components/Wattro';
import { useWattroDialog } from '~/pages/projects/ui/Wattro/hooks/useWattroDialog';

export const WattroDialog = () => {
    const { isOpen, onClose, loading, onSubmit, units, onCheck, hint } = useWattroDialog()

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent rounded="none" maxWidth="container.md">
                <CustomModalHeader title="Wohneinheit für wattro-Aufbau wählen">
                    <HStack>
                        <Button data-test-id="wattro-cancel-button" variant="outline" onClick={onClose}>
                            Abbrechen
                        </Button>
                        <Button
                            isLoading={loading}
                            onClick={onSubmit}
                            isDisabled={units.every((unit: any) => unit?.wattroProjectExists)}
                            data-test-id="wattro-save-button"
                            colorScheme="blue">
                            Speichern
                        </Button>
                    </HStack>
                </CustomModalHeader>
                <ModalBody pb={6} data-test-id="wattro-modal-body">
                    <VStack alignItems="start" spacing={2} mb={5}>
                        <FormLabel>Wohneinheit</FormLabel>
                        {units?.map(({ id, isChecked, wattroProjectExists, wohneinheit }: any) => (
                            <HStack key={id}>
                                <Checkbox
                                    isChecked={isChecked}
                                    onChange={(e) => onCheck(e, id)}
                                    isDisabled={wattroProjectExists}
                                >
                                    {wohneinheit?.bezeichnung || ''}
                                </Checkbox>
                                {wattroProjectExists && <Wattro />}
                            </HStack>

                        ))}
                    </VStack>
                    <Text fontWeight="bold">Hinweis:</Text>
                    <Text>{hint}</Text>
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}