import { tableColumnMapper } from '~/utils';

// Tables
const columns = {
  function: {
    __graphqlType: 'Funktion',
    id: { header: 'Id', accessor: 'id' },
    description: { header: 'Bezeichnung', accessor: 'bezeichnung' },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  function: {
    columns: ['id', 'description', 'controls'].map(tableColumnMapper(columns.function)),
    hiddenColumns: ['id'],
    tableProps: { pageSize: 15 },
  },
};

// Forms
export const fields = {
  function: {
    __graphqlInput: 'FunktionInput',
    description: { api: { path: 'bezeichnung', required: true } },
  },
};

export const forms = {
  function: [
    { path: 'description', ui: { label: 'Bezeichnung' } },
  ],
};
