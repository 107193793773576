import React from "react";
import { useForm } from 'react-hook-form';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import { resolveFormFields } from "~/utils";
import { fields, forms } from '~/pages/master-data/meta/data/generalAgreements.schema';
import { useModal } from "~/hooks/useModal";
import { Form, renderField } from "~/components/Form/Form";
import { useDebounce } from '~/hooks/useDebounce';
import log from "~/log";


export const GeneralAgreementPositionsModal = (props: any) => {
    const { state, dispatch, onClose } = useModal();
    const { createMutation, updateMutation, loading: isLoading, agreementId } = state?.modals?.props || props || {}
    const { row: position = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.positions, fields.positions);
    const id = useDebounce(rowId, 200)

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(position),
    };

    const { handleSubmit, control, register, formState: { errors } } = useForm({ defaultValues });

    const onSubmit = React.useCallback(async (values: any) => {
        log.debug('onSubmit.agreementId', agreementId);
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const data = formFields.toGql(values, {}, { agreementId }) as any;
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));
        const response = id ? await updateMutation({ variables: { id, data } }) : await createMutation({ variables: { data } });
        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    }, [id, agreementId])

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return <ModalContent maxWidth="container.md" rounded="none" >
        <ModalHeader
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            borderBottomWidth={1}
            borderColor="gray.200"
            mb={6}
            p={5}>

            {id ? 'Rahmenvertragposition bearbeiten' : 'Neue Rahmenvertragposition'}
            <HStack>
                <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                    Abbrechen
                </Button>
                <Button
                    isLoading={isLoading}
                    data-test-id="button-save-position"
                    colorScheme="blue"
                    onClick={handleSubmit(onSubmitWithOnClose)}
                >
                    Speichern
                </Button>
            </HStack></ModalHeader>
        <ModalBody>
            <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                <SimpleGrid spacing={4} columns={2} mb={6}>
                    {formFields.fields.map((field: any) => (
                        <React.Fragment key={field.name}>
                            {renderField({
                                field,
                                control,
                                register,
                                errors,
                                context: {},
                            })}
                        </React.Fragment>
                    ))}
                </SimpleGrid>
            </Form>
        </ModalBody>
    </ModalContent>
}
