import { tableColumnMapper } from '~/utils';

export const ui = {
  tables: [{ id: 'controlling', colSpan: 3, snippets: { create: 'Download' } }],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
  controlling: {
    __graphqlType: 'OffenePosten',
    setup: { header: 'Aufbau', accessor: 'aufbau' },
    lastAppointment: { header: 'Letzter Termin', accessor: 'letzterTermin' },
    branch: { header: 'Niederlassung', accessor: 'niederlassung' },
    project: { header: 'Projekt', accessor: 'projekt' },
    object: { header: 'Objekt', accessor: 'objekt' },
    salesVolume: { header: 'Umsatz', accessor: 'umsatz', props: (original:any) => ({ ...(original?.offenerTermin && { borderRightWidth: 2, borderRightColor: 'red.400'})}), renderer: { name: 'PriceFormat' } },
  },
};

export const tables = {
  controlling: {
    columns: ['setup', 'lastAppointment', 'branch', 'project', 'object', 'salesVolume'].map(tableColumnMapper(columns.controlling)),
  },
};

export const controlling = {
  columns: tables.controlling.columns,
  tableProps: { pageSize: 10 },
};
