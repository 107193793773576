import { useMutation } from '@apollo/client';
import {
    ListElectricityMeterDocument,
    Stromzaehler,
    UpdateElectricityMeterDocument,
} from '~/gql/ucpw/graphql';
import { ProjectIds } from '../types';

type Props = { id: number; electricityMeter: Stromzaehler; settle: boolean };

export const useElectricityMeterSettlement = ({ subprojectId, projectId }: ProjectIds = {}) => {
    const [updateMutation, { loading }] = useMutation(UpdateElectricityMeterDocument, {
        context: { clientName: 'ucpw' },
    });

    // TODO: single value updates or special mutation
    const updateSettlement = async ({ id, electricityMeter, settle }: Props) => {
        const {
            subprojektWohneinheitId,
            typSnippetId,
            beginnTag,
            endeTag,
            beginnWert,
            endeWert,
            nummer,
        } = electricityMeter;
        const variables = {
            id,
            data: {
                subprojektWohneinheitId,
                typSnippetId,
                beginnTag,
                endeTag,
                beginnWert,
                endeWert,
                nummer,
                abrechnen: settle,
            },
        };

        await updateMutation({
            variables,
            refetchQueries: [
                {
                    query: ListElectricityMeterDocument,
                    context: { clientName: 'ucpw' },
                    variables: {
                        limit: 250,
                        filter: {
                            subprojektWohneinheit: {
                                subprojektId: subprojectId,
                                subprojekt: {
                                    projektId: projectId,
                                },
                            },
                        },
                    },
                },
            ],
        });
    };

    return {
        updateSettlement,
        loading,
    };
};
