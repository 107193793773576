import React from 'react';
import log from '~/log';

export const useYupValidationResolver = (validationSchema: any = {}) =>
    React.useCallback(
        async (data: any) => {
            log.debug('form:validate', data);

            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                log.error('form:validation', errors);

                return {
                    values: {},
                    errors: errors?.inner?.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? 'validation',
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );
