import { Auth } from 'aws-amplify';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

const getJwtToken = async () => (await Auth.currentSession()).getIdToken().getJwtToken();

const authHeaderLink = setContext(async (_, previousContext) => ({
    ...previousContext,
    headers: {
        ...previousContext.headers,
        Authorization: await getJwtToken(),
    },
}));

export function createApolloClient() {
    const env = import.meta.env;
    const url = env.VITE_API_URL;
    const region = env.VITE_REGION;

    const httpLink = new HttpLink({ uri: url });
    const auth = { type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS, jwtToken: getJwtToken } as any;

    const apiLink = ApolloLink.from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
    ]);

    const clientApiLink = new HttpLink({ uri: env.VITE_CLIENT_API_URL });

    return new ApolloClient({
        connectToDevTools: import.meta.env.DEV,
        link: ApolloLink.from([
            authHeaderLink,
            ApolloLink.split(
                (operation) => operation.getContext().clientName === 'ucpw',
                clientApiLink,
                apiLink
            ),
        ]),
        cache: new InMemoryCache({
            addTypename: true,
            typePolicies: {
                SecurityQuery: { keyFields: [] },
                SecurityMutation: { keyFields: [] },
                SecurityRole: { keyFields: ['id'] },
                PermissionGroup: { keyFields: false },
                PermissionResource: { keyFields: false },
            },
        }),
    });
}

export const client = createApolloClient();
