import { useController } from 'react-hook-form';
import { Input, InputProps } from '@chakra-ui/react';
import { StyledSelect } from '~/components/Form/StyledSelect';

export const ProjectSearchInput = ({ control, name, ...props }: InputProps & any) => {
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: null,
    });

    return (
        <Input
            cursor="pointer"
            onChange={(event) => onChange?.(event.target.value)}
            value={value || ''}
            {...props}
        />
    );
};

export function DurationSelect({
    control,
    name,
    defaultValue = 30,
    options = Array.from({ length: 8 * 4 }).map((_, idx) => {
        const value = (idx + 1) * 15;
        const label = `${value / 60}`?.split('.');

        const minutes = {
            '25': '15',
            '5': '30',
            '75': '45',
        };

        type Minutes = keyof typeof minutes;

        return {
            value,
            label:
                label?.length === 1
                    ? `${label?.[0]}h`
                    : label?.[0] === '0'
                        ? `${minutes?.[label?.[1] as Minutes]}m`
                        : `${label[0]}h ${minutes?.[label?.[1] as Minutes]}m`,
        };
    }),
    ...props
}: {
    value?: number;
    onChange?: (args?: any) => void;
    defaultValue?: number;
    options?: Array<{ value?: number; label?: string }>;
    dragTimeStep?: 15 | 30;
} & { [key: string]: any }) {
    // TODO: not 100%, doesnt accepts defaultValues via schema, and delivered once somehow a string value in retour???
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: { [name]: defaultValue },
    });

    // const options = (function)

    const selectedValue = options.filter((option) => option?.value === value);

    return (
        <StyledSelect
            options={options}
            value={selectedValue}
            placeholder="Dauer"
            onChange={(option: any) => {
                onChange?.(option?.value);
            }}
            {...props}
        />
    );
}
