import * as yup from 'yup';
import { get } from 'lodash';
import { camelCase } from 'change-case';
import { graphQlToFormFields } from './graphQlToFormFields';
import { formFieldsToGraphQl } from './formFieldsToGraphQl';
import log from '~/log';

export const resolveFormFields = (form: any = [], fields: any = {}, context: any = {}) => {
    const isFormUpdate = context?.action === 'update';
    log.trace('resolveFormFields', { form, fields, isFormUpdate, context });

    const formFields = form.filter(Boolean).map((field: any) => {
        const { api } = get(fields, field?.path || '') || {};
        const { ui } = field;
        const isRequired = field?.required !== undefined ? field?.required : api?.required || false;
        const name = camelCase(field.path);

        return {
            name,
            isRequired,
            ui,
            isHidden: (watchFields: any = {}) => field.visibility?.hide?.(watchFields),
            dynamicProps: (watchFields: any = {}) => field.visibility?.props?.(watchFields),
        };
    });

    const { rules, defaultValues, watchFields, watchFieldValues } =
        form.filter(Boolean).reduce((acc: any, field: any) => {
            const { api, defaultValue } = get(fields, field.path) || {};

            const ui = typeof field?.ui === 'function' ? field?.ui() : field?.ui;
            const name = camelCase(field.path);
            const isRequired =
                field?.required !== undefined ? field?.required : api?.required || false;
            const isValdiation = field?.validation === false ? false : true;
            const isDisabled = ui?.props?.isDisabled;
            const hasDefaultValue = defaultValue !== undefined;
            const { rule, visibility } = field;

            if (context.debug && isRequired && !rule) {
                console.warn(
                    `Field ${name} is required on api side, but there is no validation rule!`
                );
            }

            // rules
            const requiredMessage = `${ui?.label} ist erforderlich`;
            const rules = {
                ...(isRequired &&
                    isValdiation &&
                    !isDisabled && {
                        [name]:
                            ui?.component === 'QuerySelect' && ui?.props?.isMulti
                                ? yup.array().nullable().required(requiredMessage)
                                : [
                                      'QuerySelect',
                                      'Select',
                                      'SnippetSelect',
                                      'RichTextEditor',
                                  ].includes(ui?.component)
                                ? yup.object().nullable().required(requiredMessage)
                                : ui?.type === 'number' || ui?.props?.type === 'number'
                                ? yup.string().required(requiredMessage)
                                : yup.string().required(requiredMessage),
                    }),
                ...(acc.rules || {}),
                ...(rule && { [name]: rule }),
            };

            // defaultValues
            const defaultValues = {
                ...(acc.defaultValues || {}),
                ...(hasDefaultValue && { [name]: defaultValue }),
            };

            // watchFields
            const watchFields = [...(acc.watchFields || []), ...(visibility?.watch || [])];
            const watchFieldValues = [
                ...(acc.watchFieldValues || []),
                ...(visibility?.value ? [{ name, resolveValue: visibility?.value }] : []),
            ];

            return {
                ...acc,
                rules,
                defaultValues,
                watchFields,
                watchFieldValues,
            };
        }, {}) || {};

    return {
        fields: formFields,
        rules,
        defaultValues,
        watchFields: Array.from(new Set(watchFields)) as string[],
        watchFieldValues,
        toGql: formFieldsToGraphQl(form, fields),
        toForm: graphQlToFormFields(form, fields),
    };
};
