import React from 'react';
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import {
    CreateParticipantDocument,
    UpdateParticipantDocument,
    ListParticipantsDocument,
    ListResidentialUnitsDocument,
    DeleteParticipantDocument,
    CreateContactDocument,
    UpdateContactDocument,
    SortOrder,
    SelectSubprojectResidentialUnitsDocument
} from '~/gql/ucpw/graphql';
import { tables } from '../meta/data/participants.schema';
import { withRenderers, mutationGuard } from '~/utils';
import {
    useContextAndRefetchQueries,
    useDeleteWithConfirmation,
    useModal,
    usePermission,
} from '~/hooks';

export const useParticipants = ({ subprojectId }: any) => {
    const variables = { filter: { subprojektId: subprojectId } };
    const { context, refetchQueries: ListParticipantsRefetchQueries } = useContextAndRefetchQueries(
        ListParticipantsDocument,
        variables
    );
    const { refetchQueries: ListResidentialUnitsRefetchQueries } = useContextAndRefetchQueries(
        ListResidentialUnitsDocument,
        {
            limit: 50,
            orderBy: [{ istGesamtobjekt: SortOrder.Desc }, { bezeichnung: SortOrder.Asc }],
            filter: { istGesamtobjekt: false, subprojektWohneinheit: { subprojektId: subprojectId } },
        }
    );

    const selectSubprojectResidentialUnitsVariables = {
        limit: 50,
        orderBy: [
            { wohneinheit: { istGesamtobjekt: SortOrder.Desc } },
            { wohneinheit: { bezeichnung: SortOrder.Asc } },
        ],
        filter: { subprojektId: subprojectId, wohneinheit: { deletedExists: false } },
    };
    const selectSubprojectResidentialUnitsContextAndRefetchQueries = useContextAndRefetchQueries(
        SelectSubprojectResidentialUnitsDocument,
        selectSubprojectResidentialUnitsVariables
    );

    const refetchQueries = [
        ...ListParticipantsRefetchQueries,
        ...ListResidentialUnitsRefetchQueries,
        ...selectSubprojectResidentialUnitsContextAndRefetchQueries.refetchQueries,
    ];
    const contextAndRefetchQueries = { context, refetchQueries };

    // ===============================================
    // LIST
    // ===============================================

    const {
        data,
        loading: listLoading,
        networkStatus,
    } = useQuery(ListParticipantsDocument, {
        variables,
        notifyOnNetworkStatusChange: true,
        context: contextAndRefetchQueries.context,
    });

    const participants = data?.items?.items || [];

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateParticipantDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateParticipantDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteParticipantDocument,
        contextAndRefetchQueries
    );

    const [createContactMutation, { loading: createContactLoading }] = useMutation(
        CreateContactDocument,
        contextAndRefetchQueries
    );
    const [updateContactMutation, { loading: updateContactLoading }] = useMutation(
        UpdateContactDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading =
        listLoading ||
        createLoading ||
        updateLoading ||
        deleteLoading ||
        networkStatus === NetworkStatus.loading ||
        createContactLoading ||
        updateContactLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // TABLE
    // ===============================================

    const setParticipantsModal = React.useCallback(
        () =>
            dispatch?.({
                type: 'setModal',
                data: {
                    modal: 'Participants',
                    props: {
                        createMutation,
                        updateMutation: async (variables: any) =>
                            await mutationGuard(updateMutation, {
                                variables,
                                path: 'data.item.error',
                                actions: ['addBreadcrumb', 'captureMessage'],
                            }),
                        data: participants,
                        subprojectId,
                    },
                },
            }),
        [dispatch, createMutation, updateMutation, participants, subprojectId]
    );

    const onClick = (row: any) => {
        setParticipantsModal();
        dispatch?.({ type: 'setRow', data: { row } });
        onOpen?.();
    };

    /** Permit */
    const { canEdit, canDelete, canView } = usePermission('project.participants');
    const { canView: canViewContact } = usePermission('masterData.contacts');
    const readOnly = canView && !canEdit;

    const controls = [
        {
            title: 'Ansehen',
            props: { onClick },
            enabled: () => readOnly
        },
        {
            title: 'Bearbeiten',
            props: { onClick },
            enabled: () => canEdit
        },
        {
            title: 'zum Kontakt',
            props: {
                onClick: (row: any) => {
                    dispatch?.({
                        type: 'setModal',
                        data: {
                            modal: 'Contact',
                            props: {
                                createMutation: createContactMutation,
                                updateMutation: updateContactMutation,
                            },
                        },
                    });
                    dispatch?.({
                        type: 'setRow',
                        data: {
                            row: {
                                original:
                                    row?.original?.kontakt,
                            },
                        },
                    });
                    onOpen?.();
                },
            },
            enabled: () => canViewContact,
        },
        {
            title: 'divider', enabled: () => canDelete && (readOnly || canEdit || canViewContact)
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) =>
                    deleteWithConfirmation({
                        id: row?.original?.id,
                        version: row?.original?.version,
                        forceOverwrite: true,
                    }),
            },
            enabled: () => canDelete
        },
    ].filter(Boolean);

    const columns = React.useMemo(() => withRenderers(tables?.participants?.columns, controls), [controls, setParticipantsModal]);
    const hiddenColumns = tables?.participants?.hiddenColumns;

    const onCreate = () => {
        setParticipantsModal();
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    };

    return {
        data: participants,
        columns,
        loading,
        hiddenColumns,
        onClick,
        onCreate,
    };
};
