import { useParams } from 'react-router-dom';
import { ProjectIds } from '~/pages/projects/types';

export const useProjectParams = (): ProjectIds => {
    const params = useParams();
    const projectId = parseInt(params?.projectId || '', 10);
    const subprojectId = parseInt(params?.subprojectId || '', 10);

    return { projectId, subprojectId };
};
