import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { useSubProjectForm } from '~/pages/projects/hooks/useSubProjectForm';
import { useCreateContactDefaultValues } from '~/pages/projects/hooks/useCreateContactDefaultValues';
import { combineReducers, contactReducer, formStateReducer, modalReducer } from '~/reducers';
import { CreateSubprojektToProjektInput, ListSubprojectResidentialUnitsDocument } from '~/gql/ucpw/graphql';
import { ContactSearchModalContent } from '~/pages/projects/ui/ContactSearchModalContent';
import { CreateContactModalContent } from '~/pages/projects/ui/CreateContactModalContent';
import { useSubproject } from '~/pages/projects/hooks/useSubproject';
import { ProjectIds } from '~/pages/projects/types';
import { useProject } from '~/pages/projects/hooks/useProjekt';
import { Question } from '~/pages/projects/components/Question';
import { Form, renderField } from '~/components/Form/Form';
import { SectionCard } from '~/components/SectionCard';
import { MultiModal } from '~/components/MultiModal';
import { PageHeader } from '~/components/Layout/PageHeader';
import { getParticipant } from '~/utils';
import { useModal } from '~/hooks';
import { client } from '~/apollo';
import { omit } from 'lodash'
import log from '~/log';

export function CreateSubProject({ title, ...props }: { title: string } & ProjectIds) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams();
    const projectId: any = params?.projectId || props?.projectId
    const subprojectId: any = params?.subprojectId || location?.state?.subprojectId || props?.subprojectId
    const {
        formToGql,
        gqlToForm,
        formFields,
        defaultValues,
        control,
        register,
        watch,
        setValue,
        reset,
        handleSubmit,
        subprojectCreditStandingSnippetId,
        errors,
        watchFields,
    } = useSubProjectForm();

    useModal({
        defaultState: { modals: { activeModal: 'SearchContact' } },
        component: <MultiModal>
            <ContactSearchModalContent id="SearchContact" />
            <CreateContactModalContent id="CreateContact" />
        </MultiModal>,
        reducer: combineReducers({
            modals: modalReducer('SearchContact'),
            contacts: contactReducer('SearchContact'),
            formState: formStateReducer,
        }),
    });

    const { objectStreet, objectLocation, objectZipCode, participantClientId, participantPolicyholderId } = watch();
    useCreateContactDefaultValues({ objectStreet, objectLocation, objectZipCode })

    const { data: project } = useProject({ projectId });
    const { data: subproject, participants, createMutation, loading } = useSubproject({
        projectId,
        subprojectId,
    });
    log.debug('CREATE SUB_PROJECT', { projectId, subprojectId, project, subproject, participants });

    React.useEffect(() => {
        const formData: any = gqlToForm({ projekt: project, subprojekt: subproject, beteiligte: participants }) || {}
        const { objectLocation, objectStreet, objectZipCode, projectBranchId, projectId } = formData
        const resetValues = {
            ...defaultValues,
            ...(location?.state?.type === 'NEW_SUBPROJECT' ? { objectLocation, objectStreet, objectZipCode, projectBranchId, projectId } : formData),
            subprojectCreditStandingSnippetId: { value: subprojectCreditStandingSnippetId },
        };
        log.debug('CreateSubProject.reset', resetValues);
        reset(resetValues);
    }, [project?.id, subproject?.id, location.state, subprojectCreditStandingSnippetId]);

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (formData) => {
            log.debug('onSubmit.formData', formData);
            const fixedValues = { projectId };
            log.debug('onSubmit.fixedValues', fixedValues);
            const data = formToGql(formData, {}, fixedValues);
            log.debug('onSubmit.data', data);
            const beteiligte = subproject?.beteiligte
                ?.filter(
                    ({ typSnippet, typSnippetId, kontaktId }: any, index: number, self: any) =>
                        !['Mieter', 'Auftraggeber', 'Eigentümer', 'Vermittler', 'Versicherungsnehmer', 'Beauftragter Handwerker'].includes(
                            typSnippet?.name
                        ) && index === self.findIndex((t: any) => t.typSnippetId === typSnippetId && t.kontaktId === kontaktId)
                )
                ?.map(({ kontaktId, typSnippetId: typId }: any) => ({
                    kontaktId,
                    typId,
                }));

            const { data: subprojectResidentialUnitsData } = await client.query({
                query: ListSubprojectResidentialUnitsDocument,
                context: { clientName: 'ucpw' },
                variables: {
                    filter: { subprojektId: subproject?.id },
                    limit: 50
                }
            })
            const subprojectResidentialUnits = subprojectResidentialUnitsData?.items?.items || []

            const subprojektWohneinheit = subprojectResidentialUnits
                ?.map?.(({ wohneinheitId, beteiligter }: any) => {
                    const tenant = getParticipant(beteiligter);
                    return tenant ? { wohneinheitId, mieterId: tenant?.id } : null;
                })
                ?.filter(Boolean);

            log.debug('onSubmit.subproject', { beteiligte, subprojektWohneinheit });
            // FIXME: formToGql provides field of undefined, can't figure out what's happening here - fyi @bjoern  
            const response: any = await createMutation({ ...omit(data, 'undefined'), subprojektWohneinheit, beteiligte } as CreateSubprojektToProjektInput);
            log.debug('onSubmit.response', response);
            const { id: subprojectId, projektId } = response.data?.app?.subproject?.item;

            navigate(`/projekte/${projektId}/${subprojectId}/details`);
        },
        [subproject, createMutation]
    );

    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
            <PageHeader.Actions>
                <HStack>
                    <Button
                        data-test-id={`cancel`}
                        size="sm"
                        onClick={() => navigate(`/projekte/${projectId}`)}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        data-test-id={`save`}
                        colorScheme="blue"
                        size="sm"
                        isDisabled={loading}
                        isLoading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Speichern
                    </Button>
                </HStack></PageHeader.Actions>
        </PageHeader>
        <Container maxW="container.lg" p={3}>
            <Question
                listenTo={participantPolicyholderId ? false : participantClientId}
                onConfirmation={() => setValue('participantPolicyholderId', participantClientId)}
                question="Als Versicherungsnehmer übernehmen"
                description="Handelt es sich bei dem Auftraggeber ebenfalls um den
            Versicherungsnehmer?"
            />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <VStack alignItems="center" spacing={14}>
                    <SectionCard title="Objekt">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.object.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard title="Projekt">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.project.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard title="Auftraggeber">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.client.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard title="Versicherung">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.insurance.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                </VStack>
            </Form>
        </Container >
    </>
}
