import { Link } from 'react-router-dom';
import { GrSchedule } from 'react-icons/gr';
import { Button } from '@chakra-ui/react';

type GoToScheduleType = {
    isDisabled: boolean;
    state?: { projectId?: number, subprojectId?: number, branchId?: number };
}

export const GoToScheduleButton = ({ isDisabled, state = {} }: GoToScheduleType) => {
    return <Button
        isDisabled={isDisabled}
        data-test-id="button-to-schedule"
        as={Link}
        state={state}
        to={'/projekte/termine'}
        variant="outline"
        rightIcon={<GrSchedule />}
    >
        Zum Terminplan
    </Button>
}