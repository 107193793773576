/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useMachine } from '@xstate/react';
import { assign, createMachine } from 'xstate';
import { pick } from 'lodash';
import { FiFile, FiFileMinus } from 'react-icons/fi';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useController, useForm } from 'react-hook-form';
import { useAsset } from '~/hooks/useAsset';
import { GridItem, FormControl, FormLabel, VStack, Flex, Icon, Stack, Center, ButtonGroup, Text, Button, Spinner, } from '@chakra-ui/react';
import { Dokument, ListDocumentsDocument, DeleteDocumentDocument, CreateDocumentDocument } from '~/gql/ucpw/graphql';
import { documents as hmm } from '~/pages/projects/meta/data/documents.gql'
import { useContextAndRefetchQueries, useSnippetId } from '~/hooks';
import { S3Upload } from './S3Upload';
import { client } from '~/apollo'
import { useMutation } from '@apollo/client';
import log from '~/log';

type DocumentsUploadContext = {
    get: Dokument[];
    created: Dokument[];
    updated: Dokument[];
    deleted: Dokument[];
    isLoading: boolean
};

const context = {
    get: [],
    created: [],
    updated: [],
    deleted: [],
    isLoading: false
}

const documentsUploadMachine = createMachine<DocumentsUploadContext>({
    id: 'documentsUpload',
    context,
    initial: 'idle',
    states: {
        idle: {
            on: {
                GET: 'init'
            }
        },
        init: {
            entry: assign({ isLoading: true }),
            invoke: {
                id: 'get',
                src: 'get',
                onDone: {
                    target: 'wait',
                    actions: assign((_, event) => ({
                        get: event.data
                    }))
                },
                onError: {
                    target: 'init'
                }
            }
        },
        reset: {
            always: {
                actions: 'reset'
            }
        },
        createMany: {
            invoke: {
                id: 'createMany',
                src: 'createMany',
                onDone: {
                    target: 'init',
                    actions: assign((context, event) => ({
                        created: context.created.concat(event?.data)
                    }))
                }
            }
        },
        create: {
            invoke: {
                id: 'create',
                src: 'create',
                onDone: {
                    target: 'init',
                    actions: assign((context, event) => ({
                        created: context.created.concat(event?.data)
                    }))
                }
            }
        },
        delete: {
            invoke: {
                id: 'delete',
                src: 'delete',
                onDone: {
                    target: 'init',
                    actions: assign((context, event) => ({
                        deleted: context.deleted.concat(event?.data)
                    }))
                }
            }
        },
        wait: {
            entry: assign({ isLoading: (ctx) => !ctx.isLoading }),
            on: {
                CREATE: 'create',
                CREATE_MANY: 'createMany',
                DELETE: 'delete'
            }
        }
    }
}, {
    actions: {
        reset: assign(context)
    }
})

function getFilter(props: any) {
    const IDs = {
        invoiceId: 'rechnungId',
        offerId: 'angebotId',
        subprojectId: 'subprojektId'
    };
    return Object.entries(pick(props, Object.keys(IDs))).reduce(
        (acc, [property, value]) => ({ ...acc, [IDs[property as keyof typeof IDs]]: value }),
        {})
}

export const DocumentsFSMUpload = ({ placeholder, ...props }: any) => {
    useContextAndRefetchQueries(ListDocumentsDocument, {})
    const type = props?.invoiceId ? 'Rechnung' : props?.offerId ? 'Angebot' : ''
    const { typSnippetId } = useSnippetId({ name: type, category: 'Dokument', property: 'Typ', });
    const {
        field: { name },
    } = useController({
        name: props.name,
        control: props.control,
        defaultValue: null,
    });
    const { control } = useForm({ defaultValues: { [name]: [] } });

    const subprojectId = props.subprojectId;
    const filter = getFilter(props)
    const { refetchQueries: internalRefetchQueries } = useContextAndRefetchQueries(ListDocumentsDocument, { filter: { subprojektId: subprojectId }, limit: 100 })
    const { refetchQueries: documentsRefetchQueries } = useContextAndRefetchQueries(ListDocumentsDocument, { filter: { subprojektId: subprojectId }, })

    const refetchQueries = internalRefetchQueries.concat(documentsRefetchQueries)

    const [deleteMutation] = useMutation(DeleteDocumentDocument, {
        context: { clientName: 'ucpw' },
        refetchQueries,
    });

    const [createMutation] = useMutation(CreateDocumentDocument, {
        context: { clientName: 'ucpw' },
        refetchQueries,
    });


    const get = React.useCallback(async () => {
        const response = await client.query({
            query: hmm.listQuery,
            context: { clientName: 'ucpw' },
            variables: { filter } || {},
            fetchPolicy: 'network-only',
        })
        const data = response?.data?.items?.items || []
        return Promise.resolve(data)
    }, [filter])

    const create = async (_: any, event: any) => {
        const response = await createMutation({ variables: { data: event.data } })
        const item = response?.data?.item?.item || {}
        return item
    }


    const createMany = React.useCallback(async (_: any, { data = [] }: any = {}) => {
        const response = await Promise.all(data?.map(async (file: any) => {
            const response = await createMutation({
                variables: {
                    data: {
                        name: file.name,
                        pfad: file.key,
                        beschreibung: type ? `${type}s Anhang` : ' ',
                        ...(typSnippetId && { typSnippetId }),
                        ...filter
                    }
                }
            })
            const item = response?.data?.item?.item || {}
            return item
        }))
        return response
    }, [typSnippetId, filter])

    const [state, send] = useMachine(documentsUploadMachine, {
        services: {
            get,
            create,
            createMany,
            delete: async (_, event) => {
                const response = await deleteMutation({ variables: { id: event.id } })
                const item = response?.data?.item?.item || {}
                return item
            }
        }
    })

    React.useEffect(() => {
        if (Object.values(filter)?.every(Boolean)) {
            send('GET')
        }
    }, [filter])


    const onS3Change = React.useCallback((s3: any) => {

        if (Array.isArray(s3) && s3.length > 1) {
            send({ type: 'CREATE_MANY', data: s3 })
        } else {
            const [file] = s3
            const data = {
                name: file.name,
                pfad: file.key,
                beschreibung: type ? `${type}s Anhang` : ' ',
                ...(typSnippetId && { typSnippetId }),
                ...filter
            }
            log.debug('DocumentsUpload.onChange', { s3, file, data });
            send({ type: 'CREATE', data })
        }



    }, [typSnippetId, type, filter])

    return (
        <GridItem colSpan={props?.colSpan || 2} w="full">
            <VStack align="start" spacing={4} w="full">
                <FormControl>
                    <FormLabel htmlFor={name}>{props.label}</FormLabel>
                    <S3Upload
                        control={control}
                        name={name}
                        placeholder={placeholder}
                        fileName={state.context.get?.map(item => item?.name)?.filter(Boolean)?.join(', ')}
                        onChange={onS3Change}
                        multiple
                    />
                </FormControl>
                {state.context.isLoading && (
                    <Center w="full">
                        <Spinner />
                    </Center>
                )}
                {state.context?.get?.length &&
                    state.context?.get?.map(({ pfad, name, id }) => (
                        <Item
                            key={pfad}
                            path={pfad}
                            onDelete={() => { send({ type: 'DELETE', id }) }}>
                            {name}
                        </Item>
                    ))}
            </VStack>
        </GridItem>
    );
};

function Item({ children, path, onDelete, ...props }: any) {
    const { download } = useAsset();
    const [icon, setIcon] = React.useState('file');

    const downloadAsset = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const url = await download(path);
        const isBrokenUrl = url === 'https://s3.eu-central-1.amazonaws.com/';
        log.debug('DownloadLink.url', { path, url });
        if (!isBrokenUrl && url) {
            window.open(url);
        } else if (isBrokenUrl) {
            location.reload();
        }
    };

    return (
        <Flex
            direction="row"
            w="full"
            boxShadow="sm"
            bg="bg-surface"
            borderRadius="lg"
            overflow="hidden"
            {...props}
        >
            <Center display={{ base: 'none', sm: 'flex' }} bg="bg-accent" px="5">
                <Icon
                    as={
                        icon === 'file'
                            ? FiFile
                            : icon === 'show'
                                ? MdOutlineOpenInNew
                                : FiFileMinus
                    }
                    boxSize="8"
                    color="on-accent"
                />
            </Center>
            <VStack align="start" p="4" spacing="3" flex="1">
                <Stack spacing="1">
                    <Text fontWeight="semibold">{children}</Text>
                </Stack>
                <ButtonGroup variant="link" size="sm" spacing="3">
                    <Button
                        onMouseEnter={() => setIcon('show')}
                        onMouseLeave={() => setIcon('file')}
                        onClick={downloadAsset}
                    >
                        Anzeigen
                    </Button>
                    <Button
                        _hover={{ color: 'red.400' }}
                        onMouseEnter={() => setIcon('minus')}
                        onMouseLeave={() => setIcon('file')}
                        onClick={onDelete}
                    >
                        Löschen
                    </Button>
                </ButtonGroup>
            </VStack>
        </Flex>
    );
}
