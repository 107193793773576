import React from 'react';
import moment from 'moment-timezone';
import { format } from 'date-fns';
import {
    Eventcalendar,
    setOptions,
    localeDe,
    MbscCalendarEvent,
    MbscEventcalendarOptions,
    momentTimezone,
    formatDate,
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Box } from '@chakra-ui/react';
import { ProjectIds } from '~/pages/projects/types';
import { useRenderHeader, useResources, useAddEvent, useEventUpdate } from './hooks';
import { CalendarContext } from './calendarMachine'
import { ScheduleEvent } from './ScheduleEvent';
import { CalendarProps } from './Calendar';
import { useViewer } from '~/hooks';

momentTimezone.moment = moment;

setOptions({
    locale: localeDe,
    theme: 'ios',
    themeVariant: 'light',
});

export interface CalendaCardProps extends MbscEventcalendarOptions {
    [key: string]: any;
    events?: MbscCalendarEvent[];
    date?: string;
    scheduleType?: 'day' | 'week';
    states?: CalendarContext & ProjectIds;
}

export const CalendarCard = ({
    setSelectedDate,
    selectedDate,
    onEmergencyServiceChange,
    onAutoEmergencyServiceChange,
    onDeleteEvent,
    onUpdateEvent,
    isLoading,
    onSetSubprojectId,
    onSetResourceIndex,
    date,
    ...props }: CalendarProps) => {
    const { employeeId, employees, isEmergencyService, emergencyEvents, scheduleType, subproject, mbsc: events, locationState, resourceIndex, branchId, branches = [] } = props?.states || {};
    const onAddEvent = useAddEvent({ subproject, employeeId })

    const viewer = useViewer()
    const enabledBranches = viewer.permissions?.reduce?.((acc: string, { id, permissions = [] }: any) => id.startsWith('branch.') && permissions.length ? acc + id : acc, '');
    const currentBranch = branches?.find?.((branch: any) => branch.id === branchId);
    const currentBranchIsEnabled = enabledBranches?.includes?.(currentBranch?.bezeichnung?.toLowerCase?.());


    const onEventUpdate = useEventUpdate(onUpdateEvent);

    const renderHeader = useRenderHeader({
        scheduleType,
        selectedDate,
        setSelectedDate,
        setEmergencyService: onEmergencyServiceChange,
        emergencyService: isEmergencyService,
        subproject,
        loading: isLoading,
        emergencyEvents
    });


    const { resourceProps, wrapperProps } = useResources({
        branchId,
        resourceIndex,
        onSetResourceIndex,
        employees,
        locationState,
        onSetSubprojectId,
        onAutoEmergencyServiceChange,
        isEmergencyService,
        scheduleType,
        isLoading,
        events,
        employeeId,
        date
    }) as any;

    const renderScheduleEvent = React.useCallback(
        (data: any) =>
            <ScheduleEvent data={data} onDelete={onDeleteEvent} scheduleType={scheduleType} locationState={locationState} currentBranchIsEnabled={currentBranchIsEnabled} />,
        [onDeleteEvent, scheduleType, locationState, currentBranchIsEnabled]
    );


    return (
        <Box h="full" {...wrapperProps} >
            <Eventcalendar
                dragTimeStep={15}
                dragToMove={true}
                dragToResize={true}
                selectedDate={selectedDate}
                onSelectedDateChange={(event) => setSelectedDate(event.date)}
                renderHeader={renderHeader}
                renderDay={({ date }) =>
                    <Box fontWeight={600} fontSize={16} color={format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd') ? 'blue.500' : 'gray.600'}>
                        {`${formatDate('DDD', date)?.toUpperCase()} ${formatDate('DD.MM.', date)}`}
                    </Box>
                }
                showEventTooltip={false}
                onEventUpdate={onEventUpdate}
                onCellClick={onAddEvent}
                renderScheduleEvent={renderScheduleEvent}
                timezonePlugin={momentTimezone}
                {...resourceProps}
                view={{
                    schedule: {
                        type: scheduleType,
                        allDay: false,
                        timeCellStep: 30,
                        timeLabelStep: 30,
                        ...(isEmergencyService
                            ? { startTime: '00:00', endTime: '24:00', startDay: 1, endDay: 0 }
                            : { startTime: '08:00', endTime: '20:00', startDay: 1, endDay: 5 })
                    },
                }}
            />
        </Box>
    );
};
