import React from 'react';
import { useMachine } from '@xstate/react';
import { setSession, getSizeInBytes } from '~/utils';
import log from '~/log';

type UsePersistedMachineType = {
    sessionStorageKey: string;
    services?: any;
    guards?: any;
    actions?: any;
    context?: any;
    failOnPurpose?: boolean;
};

export function usePersistedMachine(machine: any, options: UsePersistedMachineType) {
    const { sessionStorageKey, failOnPurpose = false } = options || {};
    let persistedState = sessionStorage.getItem(sessionStorageKey);
    const [state, send, service] = useMachine(machine, {
        ...(persistedState && { state: JSON.parse(persistedState) || machine.initialState }),
        ...options,
    });

    React.useEffect(() => {
        const subscription = service.subscribe((state: any) => {
            const hasSession = setSession(
                sessionStorageKey,
                failOnPurpose ? 'x'.repeat(5 * 1024 * 1024) : state
            );
            if (!hasSession) {
                persistedState = JSON.stringify(state);
            }
            const { value, context } = state;
            const item = sessionStorage.getItem(sessionStorageKey);
            log.debug(`usePersistedMachine.${sessionStorageKey}`, {
                value,
                context,
                state,
                ...(item && { sessionStorage: JSON.parse(item) }),
                sessionStorageSize: getSizeInBytes(sessionStorage) / 1000000,
                stateSize: getSizeInBytes(state) / 1000000,
                hasSession,
                options,
            });
        });
        return () => {
            subscription.unsubscribe();
            sessionStorage.removeItem(sessionStorageKey);
        };
    }, [service, sessionStorageKey]);

    return [state, send, service];
}
