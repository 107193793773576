import { Props } from 'react-select';
import { Mitarbeiter, ListVacantPositionEmployeesDocument } from "~/gql/ucpw/graphql";
import { StyledSelect } from '~/components/Form/StyledSelect';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

const getName = (mitarbeiter: Partial<Mitarbeiter> = {}) =>
    [mitarbeiter.vorname, mitarbeiter.name].filter(Boolean).join(', ');

type Option = { value: number; label: string };

type SelectMitarbeiterProps = {
    value?: number;
    onChange?: (value: number, ctx?: any) => void;
    branchId?: number;
} & Partial<Props<Option, false, any>>;

function SelectVacantPositionEmployees({
    onChange,
    branchId,
    value,
    ...props
}: SelectMitarbeiterProps | any) {
    const { data, loading } = useQuery(ListVacantPositionEmployeesDocument, {
        skip: !Boolean(branchId),
        variables: { niederlassungId: branchId as number },
        context: { clientName: 'ucpw' },
    });

    const options =
        data?.app?.item?.items?.map((item) => ({
            value: item.id,
            label: getName(item),
        })) || [];

    const selectedValue = options.find((option) => option.value === value);

    return (
        <Box w="full" key={branchId}>
            <StyledSelect
                isLoading={loading}
                placeholder="Mitarbeiter"
                value={selectedValue}
                options={options}
                onChange={(option: null | { value: number; label: string }, ctx) => {
                    onChange?.(option?.value, ctx);
                }}
                {...props}
            />
        </Box>
    );
}

export { SelectVacantPositionEmployees };
