import { GridItem, SimpleGrid, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as Properties from '~/pages/projects/components/Properties';
import { useEditable } from '~/pages/projects/hooks/useEditable';
import { useProjectDetails } from '~/pages/projects/hooks/useProjectDetails';
import { OperationalReportForm } from './OperationalReportForm';
import { OperationalReportList } from './OperationalReportList';

export const OperationalReports = () => {
    const params = useParams();
    const projectId = parseInt(params?.projectId || '', 10);
    const subprojectId = parseInt(params?.subprojectId || '', 10);
    const { editable } = useEditable();
    const leftColSpan = useBreakpointValue({ base: 4, md: 1 });
    const rightColSpan = useBreakpointValue({ base: 4, md: 2 });
    const { project, subproject, loading } = useProjectDetails({ projectId, subprojectId });

    return (
        <SimpleGrid columns={3} spacing={5} p={4} w="full">
            <GridItem colSpan={leftColSpan}>
                <VStack spacing={6}>
                    <Properties.OperationalReportProperties
                        isLoading={loading}
                        project={project}
                        subproject={subproject}
                    />
                </VStack>
            </GridItem>
            <GridItem colSpan={rightColSpan}>
                {editable ? (
                    <OperationalReportForm
                        projectId={projectId}
                        subprojectId={subprojectId}
                        send={() => console.log('send')}
                    />
                ) : (
                    <OperationalReportList projectId={projectId} subprojectId={subprojectId} />
                )}
            </GridItem>
        </SimpleGrid>
    );
};
