import {
    Alert,
    AlertIcon,
    AlertTitle,
    Button,
    Center,
    Container,
    Stack,
    VStack,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useSecurity } from '@ucc/react/security';
import log from '~/log';

// https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
export function SentryErrorBoundary(props: React.PropsWithChildren<{}>) {
    const { viewer = {} } = useSecurity();
    const { firstName, lastName, email } = viewer;
    Sentry.setUser({ firstName, lastName, email });
    log.debug('SentryErrorBoundary', { viewer });

    return (
        <Sentry.ErrorBoundary
            {...props}
            fallback={ErrorFallback}
            showDialog={true}
            dialogOptions={{ user: { name: `${firstName} ${lastName}`, email } }}
            beforeCapture={(scope) => {
                log.debug('SentryErrorBoundary.beforeCapture', { scope });
            }}
            onReset={() => {
                log.debug('SentryErrorBoundary.onReset');
                localStorage.setItem('SENTRY_ERROR_BOUNDARY', 'false');
            }}
        />
    );
}

const ErrorFallback = ({ resetError }: { resetError(): void }) => {
    return (
        <Stack pt={32} w="100vh">
            <Container>
                <Center>
                    <Stack spacing={6}>
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertTitle>
                                Während ihrer Anfrage ist ein Fehler aufgetreten!
                            </AlertTitle>
                        </Alert>
                        <Button variant="outline" onClick={resetError}>
                            Seite neu laden
                        </Button>
                    </Stack>
                </Center>
            </Container>
        </Stack>
    );
};
