import { z } from 'zod'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSJSON: any;
  AWSPhone: any;
};

export type App = {
  __typename?: 'App';
  mitarbeiterId?: Maybe<Scalars['Int']>;
};

export type AssetsError = {
  __typename?: 'AssetsError';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['AWSJSON']>;
  message?: Maybe<Scalars['String']>;
};

export type AssetsGetSignedUrlGetResponse = {
  __typename?: 'AssetsGetSignedUrlGetResponse';
  data?: Maybe<Scalars['String']>;
  error?: Maybe<AssetsError>;
};

export type AssetsGetSignedUrlPutData = {
  __typename?: 'AssetsGetSignedUrlPutData';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AssetsGetSignedUrlPutInput = {
  name: Scalars['String'];
  prefix?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type AssetsGetSignedUrlPutResponse = {
  __typename?: 'AssetsGetSignedUrlPutResponse';
  data?: Maybe<AssetsGetSignedUrlPutData>;
  error?: Maybe<AssetsError>;
};

export type AssetsQuery = {
  __typename?: 'AssetsQuery';
  getSignedUrlGet?: Maybe<AssetsGetSignedUrlGetResponse>;
  getSignedUrlPut?: Maybe<AssetsGetSignedUrlPutResponse>;
};


export type AssetsQueryGetSignedUrlGetArgs = {
  key: Scalars['String'];
};


export type AssetsQueryGetSignedUrlPutArgs = {
  input: AssetsGetSignedUrlPutInput;
};

export type CreatePdfResponse = {
  __typename?: 'CreatePdfResponse';
  error?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
  versionId?: Maybe<Scalars['String']>;
};

export type DocumentUpload = {
  __typename?: 'DocumentUpload';
  analysis?: Maybe<Scalars['AWSJSON']>;
  id?: Maybe<Scalars['ID']>;
  metadata?: Maybe<UploadMetadata>;
  pdfDoc?: Maybe<Scalars['AWSJSON']>;
  projectNumber?: Maybe<Scalars['String']>;
  s3Object?: Maybe<S3Object>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type DocumentsMutation = {
  __typename?: 'DocumentsMutation';
  deleteUpload: Scalars['Boolean'];
  moveUploads: Array<Scalars['Boolean']>;
};


export type DocumentsMutationDeleteUploadArgs = {
  id: Scalars['ID'];
};


export type DocumentsMutationMoveUploadsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  subprojectId: Scalars['ID'];
};

export type DocumentsQuery = {
  __typename?: 'DocumentsQuery';
  uploads: Array<Maybe<DocumentUpload>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  documents?: Maybe<DocumentsMutation>;
  pdf?: Maybe<PdfMutation>;
  security?: Maybe<SecurityMutation>;
  uploadNotification?: Maybe<DocumentUpload>;
  viewer?: Maybe<ViewerMutation>;
};


export type MutationUploadNotificationArgs = {
  data: UploadNotificationInput;
};

export type PdfMutation = {
  __typename?: 'PdfMutation';
  createPdf?: Maybe<CreatePdfResponse>;
};


export type PdfMutationCreatePdfArgs = {
  data?: InputMaybe<Scalars['AWSJSON']>;
  filename: Scalars['String'];
  template: Scalars['String'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  permissionSet?: Maybe<Array<Scalars['String']>>;
  resources: Array<PermissionResource>;
  type: PermissionGroupType;
};

export type PermissionGroupInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  permissionSet?: InputMaybe<Array<Scalars['String']>>;
  resources: Array<PermissionResourceInput>;
  type: PermissionGroupType;
};

export enum PermissionGroupType {
  Crudl = 'CRUDL',
  Flags = 'FLAGS'
}

export type PermissionResource = {
  __typename?: 'PermissionResource';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  permissions: Array<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type PermissionResourceInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  assets?: Maybe<AssetsQuery>;
  documents?: Maybe<DocumentsQuery>;
  scratch?: Maybe<Scratch>;
  security?: Maybe<SecurityQuery>;
  viewer: Viewer;
};

export type S3Object = {
  __typename?: 'S3Object';
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  versionId?: Maybe<Scalars['String']>;
};

export type S3ObjectInput = {
  key?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};

export type Schema = {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
  subscription?: Maybe<Subscription>;
};

export type Scratch = {
  __typename?: 'Scratch';
  message?: Maybe<Scalars['String']>;
};

export type SecurityBooleanResponse = {
  __typename?: 'SecurityBooleanResponse';
  data?: Maybe<Scalars['Boolean']>;
  error?: Maybe<SecurityError>;
};

export type SecurityError = {
  __typename?: 'SecurityError';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['AWSJSON']>;
  message?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['String']>;
};

export type SecurityGetRoleWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SecurityGetUserWhereInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type SecurityMutation = {
  __typename?: 'SecurityMutation';
  createRole?: Maybe<SecurityRoleResponse>;
  createUser?: Maybe<SecurityUserResponse>;
  deleteRole?: Maybe<SecurityBooleanResponse>;
  deleteUser?: Maybe<SecurityBooleanResponse>;
  updateRole?: Maybe<SecurityRoleResponse>;
  updateUser?: Maybe<SecurityUserResponse>;
};


export type SecurityMutationCreateRoleArgs = {
  data: SecurityRoleCreateInput;
};


export type SecurityMutationCreateUserArgs = {
  data: SecurityUserCreateInput;
};


export type SecurityMutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type SecurityMutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type SecurityMutationUpdateRoleArgs = {
  data: SecurityRoleUpdateInput;
  id: Scalars['ID'];
};


export type SecurityMutationUpdateUserArgs = {
  data: SecurityUserUpdateInput;
  id: Scalars['ID'];
};

export type SecurityQuery = {
  __typename?: 'SecurityQuery';
  getRole?: Maybe<SecurityRoleResponse>;
  getUser?: Maybe<SecurityUserResponse>;
  listRoles?: Maybe<SecurityRoleListResponse>;
  listUsers?: Maybe<SecurityUserListResponse>;
};


export type SecurityQueryGetRoleArgs = {
  where: SecurityGetRoleWhereInput;
};


export type SecurityQueryGetUserArgs = {
  where: SecurityGetUserWhereInput;
};

export type SecurityRole = {
  __typename?: 'SecurityRole';
  abbreviation?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  permissionGroups: Array<PermissionGroup>;
  system?: Maybe<Scalars['Boolean']>;
};

export type SecurityRoleCreateInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissionGroups: Array<PermissionGroupInput>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type SecurityRoleListResponse = {
  __typename?: 'SecurityRoleListResponse';
  data?: Maybe<Array<SecurityRole>>;
  error?: Maybe<SecurityError>;
};

export type SecurityRoleResponse = {
  __typename?: 'SecurityRoleResponse';
  data?: Maybe<SecurityRole>;
  error?: Maybe<SecurityError>;
};

export type SecurityRoleUpdateInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissionGroups: Array<PermissionGroupInput>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type SecurityUser = {
  __typename?: 'SecurityUser';
  app?: Maybe<Scalars['AWSJSON']>;
  avatar?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['AWSDateTime']>;
  role?: Maybe<SecurityRole>;
  roleId?: Maybe<Scalars['String']>;
};

export type SecurityUserCreateInput = {
  app?: InputMaybe<Scalars['AWSJSON']>;
  avatar?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  sendInvite?: InputMaybe<Scalars['Boolean']>;
};

export type SecurityUserListResponse = {
  __typename?: 'SecurityUserListResponse';
  data?: Maybe<Array<SecurityUser>>;
  error?: Maybe<SecurityError>;
};

export type SecurityUserResponse = {
  __typename?: 'SecurityUserResponse';
  data?: Maybe<SecurityUser>;
  error?: Maybe<SecurityError>;
};

export type SecurityUserUpdateInput = {
  app?: InputMaybe<Scalars['AWSJSON']>;
  avatar?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  documents?: Maybe<DocumentUpload>;
};

export type UploadMetadata = {
  __typename?: 'UploadMetadata';
  docLocation?: Maybe<S3Object>;
  filename?: Maybe<Scalars['String']>;
};

export type UploadNotificationInput = {
  analysis?: InputMaybe<Scalars['AWSJSON']>;
  id?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  pdfDoc?: InputMaybe<Scalars['AWSJSON']>;
  projectNumber?: InputMaybe<Scalars['String']>;
  s3Object?: InputMaybe<S3ObjectInput>;
  status?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['String']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  app: App;
  avatar?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  permissions: Array<Permission>;
};

export type ViewerMutation = {
  __typename?: 'ViewerMutation';
  resetAvatar?: Maybe<Scalars['Boolean']>;
  updateAvatar?: Maybe<Scalars['Boolean']>;
};


export type ViewerMutationUpdateAvatarArgs = {
  avatar: Scalars['String'];
};


type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export function AssetsGetSignedUrlPutInputSchema(): z.ZodObject<Properties<AssetsGetSignedUrlPutInput>> {
  return z.object<Properties<AssetsGetSignedUrlPutInput>>({
    name: z.string(),
    prefix: z.string().nullish(),
    size: z.number().nullish(),
    type: z.string()
  })
}

export function PermissionGroupInputSchema(): z.ZodObject<Properties<PermissionGroupInput>> {
  return z.object<Properties<PermissionGroupInput>>({
    id: z.string(),
    name: z.string(),
    permissionSet: z.array(z.string()).nullish(),
    resources: z.array(z.lazy(() => PermissionResourceInputSchema())),
    type: PermissionGroupTypeSchema
  })
}

export const PermissionGroupTypeSchema = z.nativeEnum(PermissionGroupType);

export function PermissionResourceInputSchema(): z.ZodObject<Properties<PermissionResourceInput>> {
  return z.object<Properties<PermissionResourceInput>>({
    id: z.string(),
    name: z.string(),
    permissions: z.array(z.string()),
    value: z.number().nullish()
  })
}

export function S3ObjectInputSchema(): z.ZodObject<Properties<S3ObjectInput>> {
  return z.object<Properties<S3ObjectInput>>({
    key: z.string().nullish(),
    versionId: z.string().nullish()
  })
}

export function SecurityGetRoleWhereInputSchema(): z.ZodObject<Properties<SecurityGetRoleWhereInput>> {
  return z.object<Properties<SecurityGetRoleWhereInput>>({
    id: z.string().nullish()
  })
}

export function SecurityGetUserWhereInputSchema(): z.ZodObject<Properties<SecurityGetUserWhereInput>> {
  return z.object<Properties<SecurityGetUserWhereInput>>({
    email: z.string().nullish(),
    id: z.string().nullish()
  })
}

export function SecurityRoleCreateInputSchema(): z.ZodObject<Properties<SecurityRoleCreateInput>> {
  return z.object<Properties<SecurityRoleCreateInput>>({
    abbreviation: z.string().nullish(),
    description: z.string().nullish(),
    name: z.string(),
    permissionGroups: z.array(z.lazy(() => PermissionGroupInputSchema())),
    system: z.boolean().nullish()
  })
}

export function SecurityRoleUpdateInputSchema(): z.ZodObject<Properties<SecurityRoleUpdateInput>> {
  return z.object<Properties<SecurityRoleUpdateInput>>({
    abbreviation: z.string().nullish(),
    description: z.string().nullish(),
    name: z.string(),
    permissionGroups: z.array(z.lazy(() => PermissionGroupInputSchema())),
    system: z.boolean().nullish()
  })
}

export function SecurityUserCreateInputSchema(): z.ZodObject<Properties<SecurityUserCreateInput>> {
  return z.object<Properties<SecurityUserCreateInput>>({
    app: definedNonNullAnySchema.nullish(),
    avatar: z.string().nullish(),
    email: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    password: z.string().nullish(),
    roleId: z.string().nullish(),
    sendInvite: z.boolean().nullish()
  })
}

export function SecurityUserUpdateInputSchema(): z.ZodObject<Properties<SecurityUserUpdateInput>> {
  return z.object<Properties<SecurityUserUpdateInput>>({
    app: definedNonNullAnySchema.nullish(),
    avatar: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    roleId: z.string().nullish()
  })
}

export function UploadNotificationInputSchema(): z.ZodObject<Properties<UploadNotificationInput>> {
  return z.object<Properties<UploadNotificationInput>>({
    analysis: definedNonNullAnySchema.nullish(),
    id: z.string().nullish(),
    metadata: definedNonNullAnySchema.nullish(),
    pdfDoc: definedNonNullAnySchema.nullish(),
    projectNumber: z.string().nullish(),
    s3Object: z.lazy(() => S3ObjectInputSchema().nullish()),
    status: z.string().nullish(),
    timestamp: z.string().nullish()
  })
}
