import { Box, BoxProps } from '@chakra-ui/react';

const backgrounds = {
    Trocknung: '#F08330',
    Leckortung: '#B6C5E0',
    Vorarbeiten: '#D4C360',
    Nacharbeiten: '#545557',
};

export const ProjectType = ({ children, ...props }: BoxProps) => {
    return (
        <Box
            rounded="sm"
            width="fit-content"
            px={2}
            color="white"
            fontSize={14}
            fontWeight="semibold"
            lineHeight={5}
            {...(typeof children === 'string' &&
                children in backgrounds && { bg: backgrounds[children as keyof typeof backgrounds] })}
            {...props}
        >
            {children}
        </Box>
    );
};