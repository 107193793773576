import { tableColumnMapper } from '~/utils';

// Tables
const columns = {
  electricityMeter: {
    __graphqlType: 'Stromzaehler',
    id: { header: 'Id', accessor: 'id' },
    description: { header: 'Bezeichnung', accessor: 'nummer',  renderer: { name: 'DeviceUsageAndElectricalMeterDescription' }  },
    startingDay: { header: 'Anfang', accessor: 'beginnTag', renderer:{name:'DateTimeFormat'} },
    endingDay: { header: 'Ende', accessor: 'endeTag', renderer:{name:'DateTimeFormat'} },
    serviceLife: {
      header: 'Standzeit',
      id: 'standzeit',
      cell: ({ row }:any) => {
        const { beginnTag: startingDay, endeTag: endingDay } = row?.original;
        return startingDay && endingDay
          ? `${new Intl.NumberFormat('de-DE').format((new Date(endingDay)?.getTime() - new Date(startingDay)?.getTime()) / (1000 * 3600 * 24))} Tage`
          : null;
      },
    },
    startingValue: { header: 'Startwert', accessor: 'beginnWert', cell: (info:any) => {
      const value = info.getValue()
      return value ? new Intl.NumberFormat('de-DE').format(value) : null} 
    },
    endingValue: { header: 'Endwert', accessor: 'endeWert', cell: (info:any) => { 
      const value = info.getValue()
      return value ? new Intl.NumberFormat('de-DE').format(value) : null} },
    consumption: {
      header: 'Verbrauch',
      id: 'verbrauch',
      cell: ({ row }:any) => {
        const { beginnWert: startingValue, endeWert: endingValue, beginnTag: startingDay, endeTag: endingDay } = row?.original;
        return startingValue && endingValue
          ? new Intl.NumberFormat('de-DE').format(endingValue - startingValue) +
              ' kWh' +
              ` ${
                startingDay && endingDay
                  ? '(' + new Intl.NumberFormat('de-DE').format((new Date(endingDay)?.getTime() - new Date(startingDay)?.getTime()) / (1000 * 3600)) + ' Std)'
                  : ''
              }`
          : null;
      },
    },
    settle: { header: 'J/N', accessor: 'abrechnen', renderer: { name: 'ElectricityMeterSettlement' } },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  electricityMeter: {
    columns: ['id', 'description', 'startingDay', 'endingDay', 'serviceLife', 'startingValue', 'endingValue', 'consumption', 'settle', 'controls'].map(
      tableColumnMapper(columns.electricityMeter)
    ),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  electricityMeter: {
    __graphqlInput: 'StromzaehlerInput',
    residentialUnitId: { api: { path: 'subprojektWohneinheitId', required: true } },
    typeSnippetId: { api: { path: 'typSnippetId', required: true } },
    startingDay: { api: { path: 'beginnTag' } }, // AWSDateTime
    endingDay: { api: { path: 'endeTag' } }, // AWSDateTime
    startingValue: { api: { path: 'beginnWert', required: true } },
    endingValue: { api: { path: 'endeWert' } },
    number: { api: { path: 'nummer', required: true } },
    settle: { api: { path: 'abrechnen', required: true } },
  },
};

export const forms = {
  electricityMeter: [
    { path: 'residentialUnitId', ui: { label: 'Wohneinheit', colSpan: 2, props: { isDisabled: true } } },
    { path: 'number', ui: { label: 'Bezeichnung' } },
    { path: 'typeSnippetId', ui: { label: 'Typ', component: 'SnippetSelect', props: { category: 'Stromzaehler', property: 'Typ' } } },
    { path: 'startingDay', ui: { label: 'Anfang', component: 'DateTimeLocal'} },
    { path: 'startingValue', ui: { label: 'Startwert', type: 'number', props: { step: '0.01', type: 'number' }  } },
    { path: 'endingDay', ui: { label: 'Ende', component: 'DateTimeLocal' } },
    { path: 'endingValue', ui: { label: 'Endwert', type: 'number', props: { step: '0.01', type: 'number' }  } },
    { path: 'settle', ui: { label: 'abrechnen', component: 'Checkbox' } },
  ],
};
