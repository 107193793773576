import React from 'react';
import { Stack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { PageHeader, PageHeaderLink } from '~/components/Layout/PageHeader';
import { ProjectIds } from '../types';
import { dateIsValid, } from '../utils';
import { calendarMachine } from './Calendar/calendarMachine';
import { CalendarFilter } from './Calendar/CalendarFilter';
import { useServices } from './Calendar/hooks/useServices';
import { usePersistedMachine, useViewer } from '~/hooks';
// import { StickyNote } from './Calendar/StickyNote';
import log from '~/log';

const SESSION_STORAGE_KEY = 'ucpw-schedule'

export function Schedule() {
    const viewer = useViewer();
    const employeeId = viewer?.app?.mitarbeiterId;
    const { date: paramDate } = useParams<{ date: string }>();
    const today = format(new Date(), 'yyyy-MM-dd');
    const location = useLocation();
    const { subprojectId, branchId, mitarbeiterIdSachbearbeiter } = location?.state as ProjectIds & { branchId?: number, subprojectId?: number, mitarbeiterIdSachbearbeiter?: number } || {}
    const services = useServices()
    const [state, send] = usePersistedMachine(calendarMachine, {
        sessionStorageKey: SESSION_STORAGE_KEY,
        services,
        context: { viewerEmployeeId: employeeId }
    });
    const date = paramDate && dateIsValid(paramDate) ? paramDate : state?.context?.selectedDate ? format(new Date(state?.context?.selectedDate), 'yyyy-MM-dd') : today;
    const isLoading = state.matches('init') || state.matches('subproject')

    React.useEffect(() => {
        const scheduleTypeCond = paramDate === 'person' || !Boolean(paramDate)
        if (scheduleTypeCond) {
            send({ type: 'CHANGE_SCHEDULE_TYPE', scheduleType: 'week' })
        } else if (!scheduleTypeCond) {
            send({ type: 'CHANGE_SCHEDULE_TYPE', scheduleType: 'day' })
        }

        if (date) {
            log.debug('calendarMachine.CHANGE_SELECTED_DATE', { date })
            send({ type: 'CHANGE_SELECTED_DATE', value: date })
        }

        if (subprojectId) {
            log.debug('calendarMachine.SET_SUBPROJECT_ID', { subprojectId })
            send({ type: 'SET_SUBPROJECT_ID', value: subprojectId })
        }

        if (mitarbeiterIdSachbearbeiter) {
            log.debug('calendarMachine.SET_LOCATION_STATE', { locationState: location?.state })
            send({ type: 'SET_LOCATION_STATE', locationState: location?.state })
        }

    }, [date, subprojectId, paramDate, location.state, mitarbeiterIdSachbearbeiter])

    return <>
        <PageHeader>
            <PageHeader.Title>Terminplan</PageHeader.Title>
            <PageHeader.Actions>
                <CalendarFilter
                    onSelectEmployee={(context: Record<string, any>) => send({ type: 'CHANGE_EMPLOYEE', context })}
                    onSelectBranch={(id: number) => send({ type: 'CHANGE_BRANCH', context: { branchId: id, subproject: undefined, subprojectId: undefined, resourceIndex: 0, locationState: undefined } })}
                    onSelectRole={(id: number) => send({ type: 'CHANGE_ROLE', context: { roleId: id } })}
                    onSelectFunction={(id: number) => send({ type: 'CHANGE_FUNCTION', context: { functionId: id } })}
                    isLoading={isLoading}
                    {...state.context}
                />
            </PageHeader.Actions>
            <PageHeader.Navigation>
                <Navigation
                    date={date}
                    onClick={(scheduleType) => send({ type: 'CHANGE_SCHEDULE_TYPE', scheduleType })}
                />
            </PageHeader.Navigation>
        </PageHeader>
        <Outlet context={{
            date,
            states: state.context,
            scheduleType: paramDate ? 'day' : 'week',
            onEmergencyServiceChange: () => send({ type: 'TOGGLE_EMERGENCY' }),
            onAutoEmergencyServiceChange: ({ emergencyEvents }: { emergencyEvents?: any[] } = { emergencyEvents: [] }) => send({ type: 'SET_EMERGENCY_EVENTS', emergencyEvents }),
            onSelectedDateChange: (value: any) => send({ type: 'CHANGE_SELECTED_DATE', value }),
            onSetResourceIndex: (resourceIndex: number = 0) => send({ type: 'SET_RESOURCE_INDEX', context: { resourceIndex } }),
            onSetSubprojectId: (value: any) => send({ type: 'SET_SUBPROJECT_ID', value }),
            onCreateEvent: (variables: any) => send({ type: 'CREATE_EVENT', variables }),
            onUpdateEvent: (variables: any) => send({ type: 'UPDATE_EVENT', variables }),
            onDeleteEvent: (variables: any) => send({ type: 'DELETE_EVENT', variables }),
            locationState: location?.state,
            isLoading: !(state.value === 'wait'),
        }} />
    </>;
}

function Navigation({ date, onClick }: { date: string; onClick: (to: string) => void }) {
    const navigate = useNavigate();
    const today = format(new Date(), 'yyyy-MM-dd');
    const pathname = `/projekte/termine/${dateIsValid(date) ? date : today}`;

    // TODO: Check if navigate(pathname) in place of history.push(pathname) still works
    React.useEffect(() => {
        !(date === 'person') && date === ':date' && navigate(pathname);
    }, [date]);

    return (
        <Stack direction="row" data-test-id="page-header-links">
            {/* <StickyNote /> */}
            <PageHeaderLink
                onClick={() => onClick('day')}
                data-test-id="eventsByDay"
                to={{ pathname }}
                end
            >
                Nach Tag
            </PageHeaderLink>
            <PageHeaderLink
                onClick={() => onClick('week')}
                data-test-id="eventsByPerson"
                to={{ pathname: `/projekte/termine/person` }}
                end
            >
                Nach Person
            </PageHeaderLink>
        </Stack>
    );
}
