import React from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineSave } from 'react-icons/ai';
import { HStack, Textarea, TextareaProps, ButtonGroup, IconButton } from '@chakra-ui/react';

interface RemarkProps extends TextareaProps {
    onDelete?: (args?: any) => void;
    disabled?: boolean;
    onSave?: (args?: any) => void;
}

export const Remark = ({
    disabled: controlledDisabled = true,
    defaultValue,
    onChange,
    onDelete,
    onSave,
    ...props
}: RemarkProps) => {
    const [disabled, setDisabled] = React.useState(!defaultValue ? false : controlledDisabled);
    const [value, setValue] = React.useState(defaultValue);

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (e: any) => {
        onChange?.(e);
        setValue(e.target.value);
    };

    const handleSave = React.useCallback(() => {
        setDisabled((prev) => !prev);
        onSave?.(value);
    }, [value]);

    return (
        <HStack align="flex-start">
            <Textarea
                h="250px"
                boxSizing="border-box"
                borderColor="transparent"
                disabled={disabled}
                value={value}
                onChange={handleChange}
                boxShadow={disabled ? 'none' : 'outline'}
                {...props}
            />
            <ButtonGroup size="md" isAttached variant="outline">
                <IconButton
                    aria-label={disabled ? 'edit' : 'save'}
                    onClick={() => (disabled ? setDisabled((prev) => !prev) : handleSave())}
                    icon={disabled ? <AiFillEdit /> : <AiOutlineSave />}
                />
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        onDelete?.('');
                    }}
                    icon={<AiFillDelete />}
                />
            </ButtonGroup>
        </HStack>
    );
};
