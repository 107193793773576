import React from "react";
import * as yup from "yup";
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid, useOutsideClick } from '@chakra-ui/react';
import { Form, renderField } from "~/components/Form/Form";
import { useModal, useViewer, useYupValidationResolver, usePermission } from "~/hooks";
import { HasPermission } from '~/layout/HasPermission';
import { ProjectIds } from "../types";
import { Dokument } from "~/gql/ucpw/graphql";
import { resolveFormFields } from "~/utils";
import { forms, fields } from "~/pages/projects/meta/data/documents.schema";
import log from "~/log";

export const DocumentsModal = (props: ProjectIds & { id?: string }) => {
    const viewer = useViewer()
    const editorId = viewer?.app?.mitarbeiterId;
    const [changedDocument, setChangedDocument] = React.useState<Dokument>();
    const { onClose, dispatch, state } = useModal();
    const { createMutation, updateMutation, deleteMutation, subprojectId } = state?.modals?.props || props || {}
    const { row: document = {}, context, rowId } = state.rows || {};
    const documentId = rowId || changedDocument?.id;
    const formFields = resolveFormFields(forms.documents, fields.documents);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(document),
    };
    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    /** Permit */
    const hasCorrespondence = document?.korrespondenz?.length > 0;
    const isDisabled = !hasCorrespondence ? false : document?.id && !(hasCorrespondence && document?.korrespondenz?.find((corr: any) => {
        const creatorId = corr?.mitarbeiterIdUrheber;
        return creatorId === editorId || creatorId === null;
    }))
    const { canCreate, canEdit, canView } = usePermission('project.documents');
    const readOnly = documentId && canView && !(canCreate || canEdit);
    const viewOnly = documentId && (isDisabled || readOnly);
    const modalTitle = !documentId && canCreate ? 'Neues Dokument' : viewOnly ? 'Dokument ansehen' : 'Dokument bearbeiten';
    const permssionType = documentId ? 'update' : 'create';
    const resource = hasCorrespondence ? 'project.correspondence' : 'project.documents';
    const cursor = viewOnly ? 'not-allowed' : 'auto';

    log.trace('DocumentsModal', { viewOnly, documentId, isDisabled, readOnly, canCreate, canEdit })

    const deleteDocumentDraft = React.useCallback(async () => {
        if (document?.id) {
            return Promise.resolve()
        }
        if (changedDocument?.id) {
            return await deleteMutation({ variables: { id: changedDocument?.id } })
        }
        return Promise.resolve()
    }, [document?.id, changedDocument?.id, deleteMutation])


    const ref: any = React.useRef();
    useOutsideClick({
        ref,
        handler: async () => await deleteDocumentDraft()
    });

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (values) => {
            log.debug('onSubmit.values', JSON.stringify({ values, changedDocument, documentId }, null, 2));
            const fixedValues = {
                subprojectId,
                name: changedDocument?.name,
                path: changedDocument?.pfad,
                typeSnippetId: changedDocument?.typSnippetId,
            };
            log.debug('onSubmit.fixedValues', JSON.stringify(fixedValues, null, 2));
            const data = formFields.toGql(values, {}, fixedValues);
            log.debug('onSubmit.data', JSON.stringify(data, null, 2));
            const response = documentId
                ? await updateMutation({
                    variables: {
                        id: documentId,
                        data,
                    },
                })
                : await createMutation({ variables: { data } });
            log.debug('onSubmit.response', response);

            if (context?.upsertOperationalDetails && response && response?.data?.item?.item?.einsatzberichtId) {
                const doc = response?.data?.item?.item || {}
                const upsert = await context?.upsertOperationalDetails?.(
                    doc,
                    'update'
                );
                const upsertResponse = await upsert();
                log.debug('onSubmit.upsert', { upsertResponse });
            }


        },
        [documentId, changedDocument, context]
    );

    React.useEffect(() => {
        reset({
            // FIXME: empty object
            ...defaultValues,
            // TODO: remove after fix
            documentsDescription: document.beschreibung,
            documents: documentId,
        });
    }, [documentId]);

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md" ref={ref}>
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={async () => {
                        await deleteDocumentDraft();
                        onReset();
                    }}>
                        {viewOnly ? 'Schließen' : 'Abbrechen'}
                    </Button>
                    {!isDisabled &&
                        <HasPermission
                            resource={resource}
                            permission={permssionType}>
                            <Button
                                data-test-id="button-save"
                                colorScheme="blue"
                                onClick={handleSubmit(onSubmitWithOnClose)}
                            >
                                Speichern
                            </Button>
                        </HasPermission>
                    }
                </HStack>
            </ModalHeader>
            <ModalBody cursor={cursor}>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {
                                        onChange: (doc: any) => setChangedDocument(doc),
                                        isDisabled
                                    },
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    )
};