import { format } from 'date-fns';
import { localeDateString } from '~/pages/projects/utils';
import { tableColumnMapper, join } from '~/utils';
import { selects } from '~/meta/data/selects.gql';
import { selects as financeSelects } from './finance-calculations.gql';

// Tables
const columns = {
  finance: {
    __graphqlType: 'Angebot | Rechnung',
    id: { header: 'Id', accessor: 'id' },
    number: { header: 'Nummer', accessor: 'nummer' },
    type: { header: 'Typ', accessor: '__typename' },
    status: { header: 'Status', accessor: 'statusSnippet.name' },
    date: { header: 'Datum', accessor: 'datum', cell: (info:any) => { 
      const value = info.getValue()
      return value ? localeDateString(value) : null} 
    },
    price: {
      header: 'Gesamtpreis',
      accessor: 'gesamtpreisNetto',
      cell: (info:any) => {
        const value = info.getValue()
        return value ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value) : null
      },
    },
    description: { header: 'Beschreibung', accessor: 'freitext.raw', renderer: { name: 'Description' } },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
  positions: {
    __graphqlType: 'Kalkulationsposition | Angebotsposition | Rechnungsposition',
    id: { header: 'Id', accessor: 'id' },
    type: { header: 'Typ', accessor: '__typename' },
    subprojectId: { header: 'Subprojekt Id', accessor: 'subprojektId' },
    subprojectResidentialUnitId: { header: 'Subprojektwohneinheit Id', accessor: 'subprojektWohneinheitId' },
    generalAgreementPositionId: { header: 'Rahmenvertragsposition Id', accessor: 'rahmenvertragspositionId' },
    position: { header: 'Position', accessor: 'lfdNr', width: 60 },
    description: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'CalculationDescription' } },
    unitPrice: {
      header: 'Einzelpreis',
      accessor: 'einzelpreis',
      cell: (info:any) => { 
        const value = info.getValue()
        return value ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value) : null},
    },
    quantity: { header: 'Menge', accessor: 'menge', renderer: { name: 'CommaDecimals' } },
    unit: { header: 'Einheit', accessor: 'einheit' },
    totalPrice: {
      header: 'Gesamtpreis',
      id: 'gesamtpreis',
      cell: ({ row: { original } }:any) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original?.menge * original?.einzelpreis),
    },
    controls: { accessor: 'controls', width: 60, enableSorting: false,  renderer: { name: 'Controls' } },
  },
  searchGeneralAggrements: {
    __graphqlType: 'Rahmenvertragsposition',
    id: { header: 'Id', accessor: 'id' },
    controls: { accessor: 'controls', renderer: { name: 'MultiSelect' } },
    number: { header: 'Nr', accessor: 'nr' },
    description: { header: 'Beschreibung', accessor: 'beschreibung' },
    unitPrice: { header: 'Preis/Einheit', accessor: 'preisProEinheit', renderer: { name: 'PriceFormat' } },
    unit: { header: 'Einheit', accessor: 'leistung.einheit', enableSorting: false, },
  },
};

const FEATURE_SHOW_CALCULATION_POSITION_TYPE = localStorage.getItem('FEATURE_SHOW_CALCULATION_POSITION_TYPE') === 'true';

export const tables = {
  finance: {
    columns: ['id', 'type', 'number', 'status', 'date', 'price', 'description', 'controls'].map(tableColumnMapper(columns.finance)),
    hiddenColumns: ['id'],
  },
  positions: {
    columns: ['id', 'type', 'position', 'description', 'unitPrice', 'quantity', 'totalPrice', 'controls'].map(tableColumnMapper(columns.positions)),
    hiddenColumns: ['id', !FEATURE_SHOW_CALCULATION_POSITION_TYPE && '__typename'].filter(Boolean),
  },
  searchGeneralAggrements: {
    columns: ['id', 'controls', 'number', 'description', 'unitPrice', 'unit'].map(tableColumnMapper(columns.searchGeneralAggrements)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  // AngebotInput
  // subprojektId: Int!
  // nummer: String!
  // freitext: AWSJSON!
  // datum: AWSDate
  // statusSnippetId: Int!
  // mwstSatz: Float!
  // gesamtpreisNetto: Float!
  // freitextFooter: AWSJSON!
  // mitarbeiterIdProjektleitung: Int
  // mitarbeiterIdSachbearbeiter: Int
  // rabatt: String!
  offer: {
    __graphqlInput: 'AngebotInput',
    subprojectId: { api: { path: 'subprojektId', required: true } }, // Int!
    number: { api: { path: 'nummer', required: true }, defaultValue: '' }, // String!
    description: { api: { path: 'freitext' }, defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }] }, // AWSJSON!
    date: { api: { path: 'datum' }, defaultValue: format(new Date(), 'yyyy-MM-dd') }, // AWSDate
    statusSnippet: { api: { path: 'statusSnippetId', required: true }, defaultValue: { key: 'S' } }, // Int! (S: in Bearbeitung - D: gültig)
    vatRate: { api: { path: 'mwstSatz', required: true } }, // Float!
    totalPriceNet: { api: { path: 'gesamtpreisNetto', required: true }, defaultValue: 0 }, // Float!
    footer: { api: { path: 'freitextFooter' } , defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }] }, // AWSJSON!
    projectManagerId: { api: { path: 'mitarbeiterIdProjektleitung' } }, // Int
    employeeId: { api: { path: 'mitarbeiterIdSachbearbeiter' } }, // Int
    discount: { api: { path: 'rabatt', required: true }, defaultValue: '/' }, // String!
    attachmentId: { api: { path: 'dokument', required: true } }, // Dokument[]
  },
  // RechnungInput
  // subprojektId: Int!
  // nummer: String!
  // freitext: AWSJSON!
  // datum: AWSDate!
  // statusSnippetId: Int!
  // mwstSatz: Float!
  // gesamtpreisNetto: Float!
  // freitextFooter: AWSJSON!
  // mitarbeiterIdProjektleitung: Int
  // mitarbeiterIdSachbearbeiter: Int
  // rabatt: String!
  invoice: {
    __graphqlInput: 'RechnungInput',
    subprojectId: { api: { path: 'subprojektId', required: true } }, // Int!
    number: { api: { path: 'nummer', required: true } }, // String!
    description: { api: { path: 'freitext', required: true }, defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }] }, // AWSJSON!
    date: { api: { path: 'datum', required: true }, defaultValue: format(new Date(), 'yyyy-MM-dd') }, // AWSDate!
    statusSnippet: { api: { path: 'statusSnippetId', required: true }, defaultValue: { key: 'O' } }, // Int! (O: Offen - S - D)
    vatRate: { api: { path: 'mwstSatz', required: true } }, // Float!
    totalPriceNet: { api: { path: 'gesamtpreisNetto', required: true }, defaultValue: 0 }, // Float!
    footer: { api: { path: 'freitextFooter', required: true }, defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }] }, //  AWSJSON!
    projectManagerId: { api: { path: 'mitarbeiterIdProjektleitung' } }, // Int
    employeeId: { api: { path: 'mitarbeiterIdSachbearbeiter' } }, // Int
    discount: { api: { path: 'rabatt', required: true }, defaultValue: '/' }, // String!
    attachmentId: { api: { path: 'dokument', required: true } }, // Dokument[]
  },
  position: {
    __graphqlInput: 'KalkulationspositionInput | AngebotspositionInput | RechnungspositionInput',
    subprojectId: { api: { path: 'subprojektId', required: true } },
    residentialUnitId: { api: { path: 'subprojektWohneinheitId', required: true } },
    generalAgreementPositionId: { api: { path: 'rahmenvertragspositionId' } },
    position: { api: { path: 'lfdNr', required: true } },
    description: { api: { path: 'beschreibung', required: true } },
    unitPrice: { api: { path: 'einzelpreis', required: true } },
    quantity: { api: { path: 'menge', required: true } },
    unit: { api: { path: 'einheit', required: true }, defaultValue: '' },
  },
  generalAgreementPosition: {
    __graphqlInput: 'KalkulationspositionInput | AngebotspositionInput | RechnungspositionInput',
    subprojectId: { api: { path: 'subprojektId', required: true } },
    residentialUnitId: { api: { path: 'subprojektWohneinheitId', required: true } },
    generalAgreementPositionId: { api: { path: 'rahmenvertragspositionId' } },
    position: { api: { path: 'lfdNr', required: true } },
    description: { api: { path: 'beschreibung', required: true } },
    unitPrice: { api: { path: { req: 'einzelpreis', res: 'preisProEinheit' }, required: true } },
    quantity: { api: { path: 'menge', required: true } },
    unit: { api: { path: { req: 'einheit', res: 'rahmenvertragsposition.leistung.einheit' }, required: true, defaultValue: '' } },
    service: { api: { path: { req: '', res: 'rahmenvertragsposition.beschreibung' } } },
  },
};

const formFields = {
  finance: {
    subprojectId: { path: 'subprojectId', ui: { label: 'Subprojekt Id' }, visibility: { hide: () => true }, validation: false },
    employeeId: {
      path: 'employeeId',
      ui: (ctx:any) => ({
        label: 'Sachbearbeiter',
        component: 'QuerySelect',
        props: {
          query: selects.employees,
          isClearable: true,
          ...(ctx?.branchId && { variables: { filter: { niederlassungId: ctx?.branchId } } }),
          mapOptions: (item:any) => ({
            value: item?.value,
            label: join([item?.name, item?.vorname]),
          }),
        },
      }),
    },
    projectManagerId: {
      path: 'projectManagerId',
      ui: (ctx:any) => ({
        label: 'Projektleitung',
        component: 'QuerySelect',
        props: {
          query: selects.employees,
          isClearable: true,
             ...(ctx?.branchId && { variables: { filter: { niederlassungId: ctx?.branchId } } }),
          mapOptions: (item:any) => ({
            value: item?.value,
            label: join([item?.name, item?.vorname]),
          }),
        },
      }),
    },
    date: { path: 'date', ui: { label: 'Datum', component: 'DatePicker' } },
    number: {
      path: 'number',
      ui: { label: 'Angebot-Nr.', props: { isDisabled: true, placeholder: 'Wird autogeneriert...' } },
      required: false,
      validation: false,
    },
    client: { path: 'client', ui: { label: 'Empfänger/Auftraggeber', props: { isDisabled: true } }, validation: false },
    totalPriceNet: {
      path: 'totalPriceNet',
      ui: { label: 'Gesamtpreis (netto)', type: 'number', props: { step: 0.01 } },
      visibility: { hide: () => true },
      validation: false,
    },
    vatRate: {
      path: 'vatRate',
      ui: {
        label: 'MwSt-Satz',
        component: 'Select',
        props: {
          options: [
            { value: 0, label: '0%'},
            { value: 7, label: '7%' },
            { value: 19, label: '19%' },
          ],
        },
      },
    },
    discount: { path: 'discount', ui: { label: 'Rabatt' } },
    attachmentId: {
      path: 'attachmentId',
      ui: (context = {} as any) => ({ label: 'Anhänge', component: 'DocumentsFSMUpload', props: { isDisabled: true, colSpan: 2, ...context } }),
    },
    description: {
      path: 'description',
      ui: { label: 'Kopftext', hideLabel: true, component: 'RichTextEditor', colSpan: 2 },
      validation: false,
    },
    footer: { path: 'footer', ui: { label: 'Fußtext', hideLabel: true, component: 'RichTextEditor', colSpan: 2 }, validation: false },
  },
  positions: {
    subprojectId: { path: 'subprojectId', ui: { label: 'Subprojekt Id' }, visibility: { hide: () => true }, validation: false },
    position: { path: 'position', ui: { label: 'Position' } },
    unitPrice: { path: 'unitPrice', ui: { label: 'Einzelpreis', type: 'number', props: { step: 0.01 } } },
    residentialUnitId: ({ colSpan = 2 }) => ({
      path: 'residentialUnitId',
      ui: (ctx: any) => {
        return {
          label: 'Wohneinheit',
          colSpan,
          component: 'QuerySelect',
          props: {
            query: financeSelects.residentialUnits,
            variables: { filter: {  subprojektId: ctx?.subprojectId, wohneinheit: {deletedExists: false }  } },
            mapOptions: (subprojektWohneinheit: any) => {
              const {id: value, wohneinheit ={}} = subprojektWohneinheit || {};
              const isDeleted = wohneinheit?.deleted;
              const { plz, ort, strasse } = wohneinheit?.projekt || {};
              const label = wohneinheit?.istGesamtobjekt ? [`${plz} ${ort} `, strasse].join(', ') : wohneinheit?.bezeichnung;
              return isDeleted ? null : { value, label, item: subprojektWohneinheit };
            },
          },
        };
      },
    }),
    unit: {
      path: 'unit',
      ui: {
        label: 'Einheit',
        component: 'Select',
        props: {
          options: [
            { value: 'm', label: 'm' },
            { value: 'm2', label: 'm²' },
            { value: 'm3', label: 'm³' },
            { value: 'Stck', label: 'Stck' },
            { value: 'Pauschal', label: '✔' },
            { value: 'Tag', label: 'Tage' },
            { value: 'kW', label: 'kW' },
            { value: 'kWh', label: 'kWh' },
            { value: 'h', label: 'h' },
            { value: 'min', label: 'min' },
            { value: 'EUR', label: '€' },
            { value: '%', label: '%' },
          ],
          isClearable: true,
        },
      },
      include: true,
      required: false,
      validation: false,
    },
    quantity: { path: 'quantity', ui: { label: 'Menge', type: 'number', props: { step: 0.01 } } },
    description: { path: 'description', ui: { label: 'Bemerkung', colSpan: 2, component: 'Textarea' } },
    generalAgreementPositionId: {
      path: 'generalAgreementPositionId',
      ui: { label: 'Rahmenvertrag Position Id', type: 'number' },
      visibility: { hide: () => true }, // TODO: docs
      validation: false, // TODO: docs
    },
  },
};

export const forms = {
  offer: [
    formFields.finance['subprojectId'], // hidden
    formFields.finance['totalPriceNet'], // hidden
    formFields.finance['number'],
    formFields.finance['projectManagerId'],
    formFields.finance['employeeId'],
    formFields.finance['client'],
    formFields.finance['date'],
    formFields.finance['vatRate'],
    formFields.finance['discount'],
    {
      path: 'statusSnippet',
      ui: ({ isReadLoading, isFixedCalculation, releaseOfferEnabled } = {} as any) => {
        return {
          label: 'Status',
          component: 'SnippetSelect',
          props: { 
            category: 'Angebot', 
            property: 'Status', 
            isDisabled: isReadLoading || isFixedCalculation,
            filterOptions: ({ label }:any) => isFixedCalculation ? true : !releaseOfferEnabled ? label !== 'gültig': true,
           },
        };
      },
    },
    formFields.finance['attachmentId'],
    formFields.finance['description'],
    formFields.finance['footer'],
  ],
  invoice: [
    formFields.finance['subprojectId'], // hidden
    formFields.finance['totalPriceNet'], // hidden
    { path: 'number', ui: { label: 'Rechnung-Nr.', props: { isDisabled: true, placeholder: 'Wird autogeneriert...' }, colSpan: 2 } },
    formFields?.['employeeId' as keyof typeof formFields],
    formFields?.['projectManagerId'as keyof typeof formFields],
    formFields.finance['date'],
    { path: 'receiptDate', ui: { label: 'Belegdatum (todo)', component: 'DatePicker' } },
    formFields.finance['discount'],
    formFields.finance['vatRate'],
    {
      path: 'statusSnippet',
      ui: ({ isOpenInvoice, releaseInvoiceEnabled } = {} as any) => { 
        return {
        label: 'Status',
        component: 'SnippetSelect',
        props: {
          category: 'Rechnung',
          property: 'Status',
          isDisabled: !isOpenInvoice && !releaseInvoiceEnabled,
          filterOptions: ({ key }:any) => ( (isOpenInvoice && releaseInvoiceEnabled ) ? true : !isOpenInvoice ? true : key === 'O'),
        },
      }},
    },
    formFields.finance['attachmentId'],
    formFields.finance['description'],
    formFields.finance['footer'],
  ],
  freePosition: [
    formFields.positions['subprojectId'],
    formFields.positions['position'],
    formFields.positions['unitPrice'],
    formFields.positions['residentialUnitId']({ colSpan: 2 }),
    formFields.positions['unit'],
    formFields.positions['quantity'],
    formFields.positions['description'],
    formFields.positions['generalAgreementPositionId'],
  ],
  generalAggrementPosition: [
    formFields.positions['subprojectId'],
    formFields.positions['position'],
    formFields.positions['residentialUnitId']({ colSpan: 1 }),
    { path: 'unitPrice', ui: { label: 'Einzelpreis', type: 'number', props: { isDisabled: true, step: '0.01' } } },
    { path: 'unit', ui: { label: 'Einheit', props: { isDisabled: true } } },
    { path: 'service', ui: { label: 'Leistung', colSpan: 2, component: 'Textarea', props: { isDisabled: true } } },
    formFields.positions['quantity'],
    formFields.positions['description'],
    formFields.positions['generalAgreementPositionId'],
  ],
};
