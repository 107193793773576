import * as Sentry from '@sentry/react';
import React from 'react';
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';

const { VITE_SENTRY_DSN, VITE_STAGE } = import.meta.env;
console.log('sentry', VITE_SENTRY_DSN, VITE_STAGE);
const enableSentry = VITE_SENTRY_DSN && ['test', 'staging', 'prod'].includes(VITE_STAGE);

// https://docs.sentry.io/platforms/javascript/guides/react/
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
const config = {
    dsn: VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
    debug: true,
    intregrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],
    environment: VITE_STAGE,
} as Sentry.BrowserOptions;
// console.log('sentry', config);

if (enableSentry) {
    Sentry.init(config);
}
