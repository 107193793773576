import { tableColumnMapper } from '~/utils';
import { selects } from '~/meta/data/selects.gql';
import { selects as projectSelects } from '~/pages/projects/meta/data/projects.gql';

// Tables
const columns = {
  employees: {
    __graphqlType: 'Mitarbeiter',
    id: { header: 'Id', accessor: 'id' },
    username: { header: 'Benutzername', accessor: 'kennung' },
    firstName: { header: 'Vorname', accessor: 'vorname' },
    lastName: { header: 'Nachname', accessor: 'name' },
    role: { header: 'Rolle', accessor: 'rolle.bezeichnung' },
    functions: { header: 'Funktionen', accessor: 'mitarbeiterFunktion.funktion.bezeichnung', enableSorting: false, renderer: { name: 'EmployeeFunctions' } }, // sorting makes no sense because of invalid findMany (PrismaClientValidationError)
    branch: { header: 'Niederlassung', accessor: 'niederlassung.nummer', renderer: { name: 'BranchName' } },
    email: { header: 'E-Mail-Adresse', accessor: 'email' },
    phone: { header: 'Telefon', accessor: 'telefon' },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  employees: {
    columns: ['id', 'username', 'lastName','firstName', 'role', 'functions', 'branch', 'email', 'phone', 'controls'].map(tableColumnMapper(columns.employees)),
    hiddenColumns: ['id'],
    tableProps: { pageSize: 15 },
  },
};

// Forms
export const fields = {
  employee: {
    __graphqlInput: 'MitarbeiterInput',
    firstName: { api: { path: 'vorname', required: true } },
    lastName: { api: { path: 'name', required: true } },
    email: { api: { path: 'email', required: true } },
    username: { api: { path: 'kennung', required: true } },
    phone: { api: { path: 'telefon' }, defaultValue: '' },
    password: { api: { path: 'passwort' }, defaultValue: '' },
    functions: { api: { path: 'mitarbeiterFunktion' } },
    passwordRepeat: { api: { path: 'passwortWiederholung' }, defaultValue: '' },
    role: { api: { path: 'rolleId', required: true } },
    branch: { api: { path: 'niederlassungId', required: true } },
    holidays: { api: { path: 'urlaubstage', required: true }, defaultValue: 0 },
    holidayGroup: { api: { path: 'urlaubsgruppe', required: true }, defaultValue: '' },
    color: { api: { path: 'farbe', required: true }, defaultValue: '' },
    accessIp: { api: { path: 'accessIp', required: true }, defaultValue: '' },
  },
};

export const forms = {
  employee: [
    { path: 'firstName', ui: { label: 'Vorname' } },
    { path: 'lastName', ui: { label: 'Nachname' } },
    { path: 'username', ui: { label: 'Benutzername' } },
    { path: 'phone', ui: { label: 'Telefonnummer' } },
    { path: 'email', ui: { label: 'E-Mail', colSpan: 2 } },

    {
      path: 'branch',
      ui: {
        label: 'Niederlassung',
        component: 'QuerySelect',
        props: {
          query: projectSelects.branches,
          mapOptions: (item:any) => ({
            value: item?.value,
            label: `${item?.nummer} - ${item?.bezeichnung}`,
          }),
        },
      },
    },
    { path: 'role', ui: { label: 'Rolle', component: 'QuerySelect', props: { query: selects.function } } },
    { path: 'functions', ui: { label: 'Funktionen', colSpan: 2, component: 'QuerySelect', props: { query: selects.functions, isMulti: true, placeholder: 'Funktionen' } } },
    // Password is not needed anymore because azure is responsible for that!
    { path: 'password',visibility: { hide: () => true },  ui: { label: 'Passwort', component: 'PasswordField', props: { placeholder: 'Neues Passwort' } } },
    // { path: 'passwordRepeat', ui: { label: 'Passwort bestätigen', component: 'PasswordField', props: { placeholder: 'Passwort wiederholen' } } },
    { path: 'holidays', visibility: { hide: () => true }, validation: false },
    { path: 'holidayGroup', visibility: { hide: () => true }, validation: false },
    { path: 'color', visibility: { hide: () => true }, validation: false },
    { path: 'accessIp', visibility: { hide: () => true }, validation: false },
  ],
};
