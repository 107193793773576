export const combineFields = (...fields: any[]) =>
    fields.reduce(
        (acc, field) => ({
            ...acc,
            defaultValues: { ...acc.defaultValues, ...(field.defaultValues || {}) },
            rules: { ...acc.rules, ...(field.rules || {}) },
            watchFields: [...acc.watchFields, ...(field.watchFields || [])],
            watchFieldValues: [...acc.watchFieldValues, ...(field.watchFieldValues || [])],
        }),
        { defaultValues: {}, rules: {}, watchFields: [], watchFieldValues: [] }
    );
