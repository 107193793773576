import React, { PropsWithChildren } from 'react';
import {
    Box,
    Flex,
    FlexProps,
    useMediaQuery,
    useColorModeValue,
    Skeleton,
    chakra,
    useClipboard,
    useDisclosure,
} from '@chakra-ui/react';
import { ShowContactPopover } from '~/components/ShowContactPopover';

interface Props extends FlexProps {
    label: string;
    value?: string | number | React.ReactElement;
    isLoading?: boolean;
    metadata?: { [key: string]: any };
}

export const Property = ({ isLoading = false, metadata = {}, ...props }: Props) => {
    const [isLargerThan1402] = useMediaQuery('(min-width: 1402px)');
    const { label, value, ...flexProps } = props;
    const hasMetadata = metadata?.[label];

    return (
        <Flex
            direction={{ base: 'column', sm: 'row' }}
            flexWrap="wrap"
            overflow="hidden"
            _even={{ bg: useColorModeValue('gray.50', 'gray.600') }}
            px={5}
            {...flexProps}
        >
            <Box as="p" minWidth="180px" fontSize={14} py={2} textOverflow="ellipsis">
                {label}
            </Box>
            <Box
                flex="1"
                ml={isLargerThan1402 ? 4 : 0}
                fontSize={14}
                py={2}
                minW="122px"
                fontWeight={600}
            >
                <Skeleton isLoaded={!isLoading}>
                    <chakra.span data-test-id={`property-${label?.toLowerCase()}`} whiteSpace="pre-line">
                        {hasMetadata ? <ShowContact {...hasMetadata}>{value}</ShowContact> : value}
                    </chakra.span>
                </Skeleton>
            </Box>
        </Flex>
    );
};

function ShowContact({ children, ...props }: PropsWithChildren<any>) {
    const { kontakt: contact = {} } = props;
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { onCopy, setValue, value: selectedEntry, hasCopied } = useClipboard('');
    const [isHovered, setIsHovered] = React.useState<string>('');

    React.useEffect(() => {
        selectedEntry && onCopy();
    }, [selectedEntry]);

    const close = () => {
        setValue('');
        onClose();
    };

    return (
        <>
            <ShowContactPopover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={close}
                label={children}
                contact={contact}
                setValue={setValue}
                hasCopied={hasCopied}
                isHovered={isHovered}
                selectedEntry={selectedEntry}
                setIsHovered={setIsHovered}
            />
        </>
    );
}
