import React from 'react';
import { format } from 'date-fns'
import isWeekend from 'date-fns/isWeekend';
import isSameDay from 'date-fns/isSameDay';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Center, Text, IconButton, Tooltip, useToast } from '@chakra-ui/react';
import { chunk, isEqual } from 'lodash';
import { join } from '~/utils';
import { useChunkSize } from './useChunkSize';
import { usePermission } from '~/hooks';
import log from '~/log'


export const useResources = ({
    scheduleType,
    employees,
    events: data = [],
    locationState,
    onSetSubprojectId,
    onSetResourceIndex,
    onAutoEmergencyServiceChange,
    isEmergencyService,
    isLoading,
    resourceIndex: controlledResourceIndex = 0,
    branchId = 1,
    employeeId,
    date
}: any) => {
    const toast = useToast()
    const branchIdRef = React.useRef(branchId)
    const { canCreate } = usePermission('project.schedule')
    const chunkSize = useChunkSize();
    const [resourceIndex, setResourceIndex] = React.useState(controlledResourceIndex);
    const [resources, setResources] = React.useState<any>([]);
    // this stupid mapping avoids mobiscroll error 
    const currentResource = React.useMemo(() => resources?.[resourceIndex]?.map((item: any) => ({ ...item })) || [], [resources, resourceIndex]);

    log.debug(`useResources.${scheduleType}`, { branchId, branchIdRef, chunkSize, resources, employees, currentResource, data, employeeId, resourcesFindIndex: resources.findIndex((item: any) => item.id === employeeId), resourceIndex, controlledResourceIndex })

    React.useEffect(() => {
        setResources(chunk(employees, chunkSize));
    }, [employees, chunkSize]);

    React.useEffect(() => {
        if (!(branchIdRef.current === branchId)) {
            setResourceIndex(0)
            branchIdRef.current = branchId
        }
    }, [branchId, branchIdRef.current])

    React.useEffect(() => {
        if (!(controlledResourceIndex === resourceIndex)) {
            onSetResourceIndex(resourceIndex)
        }
    }, [resourceIndex, controlledResourceIndex, branchId, branchIdRef])

    React.useEffect(() => {
        const foundIndex = resources.findIndex((res: any) => res.some((item: any) => item.id === locationState?.mitarbeiterIdSachbearbeiter))
        if (foundIndex > -1) {
            setResourceIndex(foundIndex)
            onSetSubprojectId(locationState?.termin?.subprojektId)
        }
    }, [locationState, employeeId, resources])

    const resourceProps = React.useMemo(
        () =>
            scheduleType === 'day'
                ? {
                    ...(resources[resourceIndex] && { data }),
                    resources: currentResource,
                    resourcesIds: currentResource.map((item: any) => item.id),
                    renderResource: (resource: any) => {
                        const resourcesLength = resources?.length;
                        const currentResource = resources?.[resourceIndex];

                        const isFirst = currentResource?.[0]?.value === resource?.value;
                        const isLast =
                            currentResource?.[currentResource?.length - 1]?.value ===
                            resource?.value;

                        const name = join([resource.name, resource.vorname]);

                        return (
                            <Center h="50px">
                                {isFirst && resourcesLength > 1 && (
                                    <Tooltip
                                        label={`${resourceIndex === 0 ? resourcesLength : resourceIndex
                                            }/${resourcesLength}`}
                                        placement="top"
                                    >
                                        <IconButton
                                            position="absolute"
                                            left={1}
                                            aria-label="back"
                                            icon={<FiChevronLeft />}
                                            onClick={() =>
                                                setResourceIndex?.((prev: any) =>
                                                    prev === 0 ? resourcesLength - 1 : prev - 1
                                                )
                                            }
                                            variant="ghost"
                                            size="sm"
                                            mr={3}
                                        />
                                    </Tooltip>
                                )}
                                <Text
                                    fontSize={12}
                                    color="gray.600"
                                    data-test-id={`resource-${name}`}
                                    fontWeight={700}
                                    {...(currentResource?.length === 1 && {
                                        paddingLeft: '70px',
                                    })}
                                >
                                    {name}
                                </Text>
                                {isLast && resourcesLength > 1 && (
                                    <Tooltip
                                        label={`${resourceIndex + 1 === resourcesLength
                                            ? 1
                                            : resourceIndex + 2
                                            }/${resourcesLength}`}
                                        placement="top"
                                    >
                                        <IconButton
                                            position="absolute"
                                            right={5}
                                            aria-label="forward"
                                            icon={<FiChevronRight />}
                                            onClick={() =>
                                                setResourceIndex?.((prev: any) =>
                                                    resourcesLength - 1 === prev ? 0 : prev + 1
                                                )
                                            }
                                            variant="ghost"
                                            size="sm"
                                            ml={3}
                                        />
                                    </Tooltip>
                                )}
                            </Center>
                        );
                    },
                }
                : { data: isLoading ? [] : data },
        [resources, resourceIndex, data, scheduleType, isLoading, employeeId, controlledResourceIndex, currentResource]
    )

    const cursorDefault = canCreate ? {} : {
        '.mbsc-schedule-grid-scroll': {
            '& .mbsc-schedule-grid': {
                cursor: 'default'
            },

        }
    }

    const wrapperProps = currentResource.length === 1 ? {
        sx: {
            '.mbsc-schedule-resource-group': {
                '& .mbsc-schedule-header-item': {
                    display: 'none',
                },

            },
            ...cursorDefault,
        },
    } : {
        sx: { ...cursorDefault }
    }

    const eventsByVisibleResources = resourceProps?.data?.filter((item: any) => item.__typename === 'Termin' && (resourceProps?.resourcesIds?.includes(item?.resource) || scheduleType === 'week') && isSameDay(new Date(date), new Date(item?.start)));
    const emergencyEvents = eventsByVisibleResources?.filter?.(({ start, end }: any) => [parseInt(format(new Date(start), 'HH00')) < 800 || isWeekend(new Date(start)), parseInt(format(new Date(end), 'HH00')) > 2000 || isWeekend(new Date(end))].some(Boolean));
    const hasEmergency = Boolean(emergencyEvents?.length);
    const cond = React.useMemo(() => !isEmergencyService && hasEmergency, [isEmergencyService, hasEmergency])

    const condRef = React.useRef({ emergencyEvents, resourceIndex })
    React.useEffect(() => {
        const cond = !isEqual(condRef?.current, { emergencyEvents, resourceIndex });
        if (cond) {
            onAutoEmergencyServiceChange({ emergencyEvents })
            if (emergencyEvents?.length) {
                toast({
                    title: 'Notdienst',
                    description: 'Häkchen für Notdienst wurde automatisch gesetzt.',
                    status: 'info',
                    duration: 4000,
                    isClosable: true,
                });
            }
        }
        condRef.current = { emergencyEvents, resourceIndex }
    }, [emergencyEvents, condRef.current])


    return {
        resourceProps,
        wrapperProps,
        hasEmergency: cond
    }
};
