import React from "react";
import { pick } from "lodash";
import { BsSearch } from "react-icons/bs";
import { format, subMonths } from 'date-fns'
import { useNavigate } from "react-router-dom";
import { ModalContent, ModalHeader, ModalBody, HStack, Tooltip, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { DataTable } from '@ucc/react/ui'
import { useSearchProject } from "~/pages/projects/ui/SearchProject/SearchProject";
import { tables } from '~/pages/projects/meta/data/projects.schema'
import { useModal, useDebounce, useFetchDataWithFilter } from "~/hooks";
import log from "~/log";

export const DocumentsProjectSearchModal = ({
    searchEntity = 'projektLfdNr',
}: {
    id: string;
    searchEntity?: string;
    modalStateOnSelect?: any;
} & any) => {
    const navigate = useNavigate();
    const { state, onClose } = useModal();
    const { updateMutation } = state?.modals?.props || {}
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
    const { columns, loading, pageCount, projects, fetchData } = useSearchProject();

    const variables = {
        searchInput: {
            ...(debouncedSearchTerm
                ? { [searchEntity]: parseInt(debouncedSearchTerm, 10) }
                : {
                    anlageBis: format(new Date(), 'yyyy-MM-dd'),
                    anlageAb: format(subMonths(new Date(), 6), 'yyyy-MM-dd'),
                }),
        },
        orderBy: [{ projekt: { anlageDatum: 'desc' } }],
    };


    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, variables);

    return (
        <ModalContent rounded="none" maxWidth="container.xl">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                p={5}
                borderColor="gray.200"
            >
                Projekt auswählen
                <HStack>
                    <Tooltip label="Suche nach laufender Nummer" placement="top" hasArrow>
                        <InputGroup width={400}>
                            <InputLeftElement pointerEvents="none" color="gray.400">
                                <BsSearch />
                            </InputLeftElement>
                            <Input
                                type="search"
                                placeholder="Suche"
                                value={searchTerm}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setSearchTerm(e.target.value)
                                }
                            />
                        </InputGroup>
                    </Tooltip>
                </HStack>
            </ModalHeader>
            <ModalBody p={0}>
                <DataTable<any>
                    columns={columns}
                    hiddenColumns={tables.projectSearch.hiddenColumns}
                    loading={loading}
                    data={projects}
                    pageCount={pageCount}
                    fetchData={fetchDataWithFilters}
                    pageSize={15}
                    onClick={async (row) => {
                        const subprojektId = row?.original?.id;
                        const projektId = row?.original?.projektId;
                        const dokumentId = state?.formState?.id;
                        const pathname = `/projekte/${projektId}/${subprojektId}/dokumente`;
                        const data = {
                            // pfad: String!;
                            // name: String!;
                            ...pick(state?.formState, 'name', 'pfad'),
                            // beschreibung: String!;
                            ...(state?.formState?.beschreibung && {
                                beschreibung: state?.formState?.beschreibung,
                            }),
                            // typSnippetId: Int;
                            ...(state?.formState?.typSnippetId && {
                                typSnippetId: state?.formState?.typSnippetId,
                            }),
                            // subprojektId: Int;
                            subprojektId,
                        };
                        log.debug('useDocuments', row.original, state.formState, data);
                        await updateMutation?.({
                            variables: { id: dokumentId, data },
                        });
                        onClose?.();
                        navigate(pathname);
                    }}
                />
            </ModalBody>
        </ModalContent>
    );
};