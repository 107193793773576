import React from 'react';
import { tables } from '../meta/data/dashboard.schema';
import { withRenderers } from '~/utils/withRenderers';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useDataLoader } from '~/hooks/useDataLoader';
import { ListTasksDocument } from '~/gql/ucpw/graphql';
import { useFetchDataWithFilter } from '~/hooks';

export const useDashboard = (variables: any = {}) => {
    const navigate = useNavigate()

    const [load, { data, loading }] = useLazyQuery(ListTasksDocument, {
        context: { clientName: 'ucpw' },
        fetchPolicy: 'network-only',
    });
    const openTasks: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, variables);

    // ===============================================
    // CONTROLS
    // ===============================================

    const controls: any = React.useMemo(() => [
        {
            title: 'zur Projektübersicht',
            props: {
                onClick: (row: any) => {
                    navigate(`/projekte/${row?.original?.subprojekt?.projekt?.id}/${row?.original?.subprojekt?.id}/details`);
                },
            },
        },
    ], [navigate]);

    const onClick = (row: any = {}) => {
        const state = row?.original || {};
        navigate(
            `/projekte/${state?.subprojekt?.projekt?.id}/${state?.subprojekt?.id}/details`,
            {
                state,
            }
        );
    };

    // ===============================================
    // TABLE
    // ===============================================

    const columns = React.useMemo(() => withRenderers(tables.columns as any, controls), [controls]);
    const hiddenColumns = tables?.hiddenColumns;


    return {
        data: openTasks,
        fetchData: fetchDataWithFilters,
        pageCount,
        loading,
        columns,
        hiddenColumns,
        onClick,
    };
};
