import React from "react";
import * as yup from 'yup';
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid, } from '@chakra-ui/react';
import { ListEmployeesDocument, CreateEmployeeDocument, UpdateEmployeeDocument, DeleteEmployeeDocument, CreateEmployeeFunctionDocument, DeleteEmployeeFunctionDocument, ListEmployeeFunctionsDocument } from "~/gql/ucpw/graphql";
import { usePermission, useDebounce, useDeleteWithConfirmation, useFetchDataWithFilter, useContextAndRefetchQueries, useModal, useDataLoader } from "~/hooks";
import { Form, renderField } from "~/components/Form/Form";
import { tables, forms, fields } from '~/pages/staffing/meta/data/employees.schema';
import { HasPermission } from "~/layout/HasPermission";
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { resolveFormFields, withRenderers } from "~/utils";
import log from '~/log'

const StaffModal = (props: any) => {
    const { createMutation, updateMutation, loading: isLoading, handleFunctionChanges, canEdit = true } = props
    const { state, dispatch, onClose } = useModal();
    const { row: employee = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.employee, fields.employee);
    const id = useDebounce(rowId, 200)

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(employee),
        ...(id && {
            functions: employee?.mitarbeiterFunktion?.map((item: any) => ({
                value: item.funktion.id,
                label: item.funktion.bezeichnung,
            })),
        })

    };

    const { handleSubmit, control, register, formState: { errors } } = useForm({
        defaultValues,
        resolver: useYupValidationResolver(
            yup.object(formFields.rules)
        ),
    });

    const onSubmit = React.useCallback(async (values: any) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const { mitarbeiterFunktion, passwortWiederholung, ...data } = formFields.toGql(values, {}) as any;
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));



        const response = id ? await updateMutation({ variables: { id, data } }) : await createMutation({ variables: { data } });

        await handleFunctionChanges(
            employee?.mitarbeiterFunktion,
            values.functions?.map(({ value }: any) => value),
            response?.data?.item?.item?.id || id
        );

        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    }, [id, employee])

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };


    return <ModalContent maxWidth="container.md" rounded="none" >
        <ModalHeader
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            borderBottomWidth={1}
            borderColor="gray.200"
            mb={6}
            p={5}>

            {id ? `Mitarbeiter ${canEdit ? 'bearbeiten' : 'ansehen'}` : 'Neuer Mitarbeiter'}
            <HStack>
                <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                    Abbrechen
                </Button>
                <HasPermission resource="staff.employees" permission="update">
                    <Button
                        isLoading={isLoading}
                        data-test-id="button-save-staff"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>

                </HasPermission>
            </HStack>
        </ModalHeader>
        <ModalBody pointerEvents={canEdit ? 'auto' : 'none'}>
            <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                <SimpleGrid spacing={4} columns={2} mb={6}>
                    {formFields.fields.map((field: any) => (
                        <React.Fragment key={field.name}>
                            {renderField({
                                field,
                                control,
                                register,
                                errors,
                                context: {},
                            })}
                        </React.Fragment>
                    ))}
                </SimpleGrid>
            </Form>
        </ModalBody>
    </ModalContent>

}

export const useStaff = (args: any = {}) => {
    log.debug('useStaff', args)
    const { canEdit, canDelete } = usePermission('staff.employees')
    const { branchId, employeeFunctions = {}, revalidator } = args;


    // ===============================================
    // LAZY LISTING
    // ===============================================

    const context = { clientName: 'ucpw' };

    const [load, { data, loading: lazyLoading }] = useLazyQuery(ListEmployeesDocument, { context });
    const employees = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;
    const { fetchData, variables } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, {
        // orderBy: [{ vorname: SortOrder.Asc }],
        // orderBy: [{ rolle: { bezeichnung: "asc" } }], //FIXME: rolle filtering is only an example for default sorting, but not working maybe CZA has some initial sorting logic here! 
        ...(branchId && { filter: { niederlassungId: branchId } }),
    });
    const listEmployeesContextAndRefetchQueries = useContextAndRefetchQueries(ListEmployeesDocument, variables);

    console.log('useStaff', { employeeFunctions })

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const contextAndRefetchQueries = {
        context,
        refetchQueries: [
            ...listEmployeesContextAndRefetchQueries?.refetchQueries,
            {
                query: ListEmployeeFunctionsDocument,
                variables: { limit: 1000, filter: {} },
                context,
            }
        ]
    }

    const [createMutation, { loading: createLoading }] = useMutation(CreateEmployeeDocument, contextAndRefetchQueries);
    const [updateMutation, { loading: updateLoading }] = useMutation(UpdateEmployeeDocument, contextAndRefetchQueries);
    const [deleteMutation, { loading: deleteLoading }] = useMutation(DeleteEmployeeDocument, contextAndRefetchQueries);


    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries?.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries?.refetchQueries]
    });

    const [createFunctionMutation, { loading: createFunctionLoading }] = useMutation(CreateEmployeeFunctionDocument, contextAndRefetchQueries);
    const [deleteFunctionMutation, { loading: deleteFunctionLoading }] = useMutation(DeleteEmployeeFunctionDocument, contextAndRefetchQueries);

    async function handleFunctionChanges(current: any[] = [], updated: any[] = [], employeeId: number) {
        const currentIds = current?.map?.(({ funktionId }) => funktionId).sort();
        const latestIds = updated.sort();


        if (!isEqual(currentIds, latestIds)) {
            const itemsToDelete = currentIds
                .filter((id) => !latestIds.includes(id))
                .map((id) => current.find((item) => item.funktionId === id)?.id)
                ?.filter(Boolean);

            const newItems = latestIds.filter((id) => !currentIds.includes(id))?.filter(Boolean);

            await Promise.all(
                itemsToDelete?.map?.(async (id) => deleteFunctionMutation({ variables: { id } }))
            );

            if (employeeId) {
                await Promise.all(
                    newItems?.map?.(async (funktionId) =>
                        createFunctionMutation({
                            variables: { data: { funktionId, mitarbeiterId: employeeId } },
                        })
                    )
                );
            }
        }

        return Promise.resolve()
    }

    const loading = lazyLoading || createLoading || updateLoading || deleteLoading || createFunctionLoading || deleteFunctionLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal(<StaffModal {...{ createMutation, updateMutation, handleFunctionChanges, loading, canEdit }} />,)

    // ===============================================
    // TABLE
    // ===============================================

    const onClick = React.useCallback(
        (row: any) => {
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen]
    );

    const controls = React.useMemo(() => [
        {
            title: canEdit ? 'Bearbeiten' : 'Ansehen',
            props: { onClick },
        },
        ...(canDelete ? ['divider', {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({
                        id: row?.original?.id,
                    });
                },
            },
        },] : []),
    ], [canEdit, canDelete, onClick, deleteWithConfirmation]);

    const columns = React.useMemo(() => withRenderers(tables.employees.columns, controls), [controls]);
    const hiddenColumns = tables.employees.hiddenColumns;

    const onCreate = () => {
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    }

    return {
        data: employees?.map((employee: any) => ({ ...employee, mitarbeiterFunktion: employeeFunctions?.[employee?.id] || [] })),
        fetchData: fetchDataWithFilters,
        pageCount,
        columns,
        loading,
        pageSize: 15,
        hiddenColumns,
        onClick,
        onCreate,
    }
}