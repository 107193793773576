import { Grid, GridItem, Center, Box } from '@chakra-ui/react';
import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Card } from '@ucc/react/ui';
import { CRUDL } from './CRUDL';
import { FLAGS } from './FLAGS';
import { Column, PermissionGroup } from './types';

type Props = UseControllerProps<{ name: string; permissionGroups: PermissionGroup[] }, any> & {
    isDebug?: boolean;
    fullAccessId?: string;
    columns?: Column[];
    width?: number;
    height?: number;
    gap?: number;
};

export function PermissionGroups({
    name,
    control,
    columns = [
        { label: 'Ansehen', id: 'view' },
        { label: 'Erstellen', id: 'create' },
        { label: 'Bearbeiten', id: 'update' },
        { label: 'Löschen', id: 'delete' },
        { label: 'Alle', id: 'all' },
    ],
    width = 100,
    height = 60,
    gap = 1,
}: React.PropsWithChildren<Props>) {
    const { field } = useController({ control, name });

    const props = { height, width, gap };
    const labels = columns?.map((c) => c.label);

    const onChange = (permissionGroup: PermissionGroup) => {
        const groups = field.value.map((group: PermissionGroup) => {
            if (group.id === permissionGroup.id) {
                return permissionGroup;
            }
            return group;
        });
        field.onChange(groups);
    };

    const crudlGroups = field?.value?.filter((pg: PermissionGroup) => pg.type === 'CRUDL') || [];
    const flagsGroups = field?.value?.filter((pg: PermissionGroup) => pg.type === 'FLAGS') || [];

    return (
        <>
            <Card>
                <Box>
                    <Grid
                        h={height + 'px'}
                        templateColumns={`1fr repeat(${columns?.length}, ${width}px)`}
                        gap={gap}
                    >
                        <GridItem />
                        {labels?.map((label) => (
                            <Center fontWeight="bold" key={label}>
                                {label}
                            </Center>
                        ))}
                    </Grid>

                    {crudlGroups.map((group: PermissionGroup) => {
                        return (
                            <CRUDL
                                key={group.id}
                                permissionGroup={group}
                                columns={columns}
                                onChange={onChange}
                                {...props}
                            />
                        );
                    })}
                </Box>
            </Card>
            {flagsGroups.map((group: PermissionGroup) => (
                <Card key={group.id} p={5}>
                    <FLAGS key={group.id} permissionGroup={group} onChange={onChange} />
                </Card>
            ))}
        </>
    );
}
