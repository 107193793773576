import { resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = {
  state: {
    reducer: (state:any, action:any) => {
      switch (action.type) {
        case 'snippets.linkVariables': {
          const filter = action.linkVariables?.filter;
          const newState = { ...state, snippets: { ...state.snippets, variables: { ...(state.snippets?.variables || {}), filter } } };
          console.log('stateReducer', { action, newState, state });
          return newState;
        }
        default: {
          return state;
        }
      }
    },
  },
  tables: [{ id: 'snippets', colSpan: 3, snippets: { create: 'Neuer Wert' } }],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
  snippets: {
    __graphqlType: 'Snippet',
    id: { header: 'Id', accessor: 'id' },
    property: { header: 'Eigenschaft', accessor: 'eigenschaft' },
    abbreviation: { header: 'Kürzel', accessor: 'kuerzel' },
    name: { header: 'Wert', accessor: 'name' },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  snippets: {
    columns: ['id', 'property', 'abbreviation', 'name', 'controls'].map(tableColumnMapper(columns.snippets)),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  snippets: {
    __graphqlInput: 'SnippetInput',
    category: { api: { path: 'kategorie', required: true } },
    property: { api: { path: 'eigenschaft', required: true } },
    abbreviation: { api: { path: 'kuerzel', required: true } },
    name: { api: { path: 'name', required: true } },
  },
};

export const forms = {
  snippets: [
    { path: 'category', ui: { label: 'Kategorie', props: { isDisabled: false } } },
    { path: 'property', ui: { label: 'Eigenschaft' } },
    { path: 'abbreviation', ui: { label: 'Kürzel' } },
    { path: 'name', ui: { label: 'Wert' } },
  ],
};

export const snippets = {
  columns: tables.snippets.columns,
  tableProps: { hiddenColumns: ['id'], pageSize: 10 },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.snippets, fields: fields.snippets },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.snippets, fields.snippets, context),

      defaultValues: ({ category }:any) => ({ category }),

      snippets: {
        create: 'Neuer Wert',
        update: 'Wert bearbeiten',
        displayName: (item: any) => `${item?.eigenschaft} ${item?.name}`,
      },
    };
  },
};
