import { useQuery } from '@apollo/client';
import { Beteiligter, Projekt, Subprojekt, ProjectDetailsDocument } from '~/gql/ucpw/graphql';
import { ProjectIds, Projects } from '../types';

export const useProjectDetails = ({ projectId, subprojectId }: ProjectIds = {}): Projects => {
    const { data, loading, error } = useQuery(ProjectDetailsDocument, {
        skip: ![projectId, subprojectId].every(Boolean),
        variables: { projektId: projectId as number, subprojektId: subprojectId as number },
        context: { clientName: 'ucpw' },
    });

    const project = (data?.project.item as Projekt) || ({} as Projekt);
    const subproject = (data?.subproject.item as Subprojekt) || ({} as Subprojekt);
    const participants = subproject?.beteiligte?.reduce((acc: any, beteiligter: any) => {
        // TODO: Use participants IDs for Mapping (useProjectDetails)
        const map = {
            Handwerker: 'handwerkerId',
            'Beauftragter Handwerker': 'handwerkerId',
            HW: 'handwerkerId',
            Vermittler: 'vermittlerId',
            Eigentuemer: 'eigentuemerId',
            Eigentümer: 'eigentuemerId',
            Auftraggeber: 'auftraggeberId',
            Versicherungsnehmer: 'versicherungsnehmerId',
        };
        const key = map[beteiligter?.typSnippet?.name as keyof typeof map];
        if (!key || acc?.[key as keyof typeof acc]) {
            return acc;
        }
        return { ...acc, [key]: beteiligter?.kontaktId };
    }, {}) as Beteiligter[];

    return { project, subproject, participants, loading, error };
};
