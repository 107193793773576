import React from 'react';
import { RiAddLine } from 'react-icons/ri';
import { AiOutlineCheck } from 'react-icons/ai';
import { useController } from 'react-hook-form';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import {
    Button,
    HStack,
    IconButton,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    VStack,
} from '@chakra-ui/react';
import { DataTable, Card, CardContent } from '@ucc/react/ui';
import { Form, renderField } from '~/components/Form/Form';
import { ModalProvider } from '~/contexts/ModalContext';
import { useModal } from '~/hooks/useModal';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { isJsonString, resolveFormFields, withRenderers } from '~/utils';
import { usePermission } from '~/hooks';
import log from '~/log';

const Modals = { MeterReadings: 'MeterReadings' };

const initialState = {
    modals: { activeModal: Modals.MeterReadings },
    readings: [],
    remote: true,
};

const readingsReducer = (state: any = {}, action: any = {}) => {
    const { type, data = {} } = action;

    switch (type) {
        case 'readings': {
            const newState = {
                ...state,
                readings: [...state?.readings, ...(Array.isArray(data) ? data : [])],
                remote: true,
            };
            return newState;
        }
        case 'reading.create': {
            log.trace('reading.create', data);
            const { id, values } = data;
            const readings = [...(state.readings || []), { id, ...values }];
            const newState = { ...state, readings, remote: false };
            return newState;
        }
        case 'reading.update': {
            log.trace('reading.update', data);
            const id = data.id;
            const readings = state.readings.map((reading: any) =>
                reading.id !== id ? reading : data.values
            );
            const newState = { ...state, readings, remote: false };
            return newState;
        }
        case 'reading.delete': {
            const id = data.row?.original?.id;
            log.trace('reading.delete', id);
            const readings = state.readings?.filter((reading: any) => reading.id !== id);
            const newState = { ...state, readings, remote: false };
            return newState;
        }
    }
};

const reducer = combineReducers({
    modals: modalReducer(Modals.MeterReadings),
    row: rowReducer,
    readings: readingsReducer,
});

export const MeterReadings = (props: any) => {
    return (
        <ModalProvider reducer={reducer} defaultState={initialState}>
            <MeterReadingsCard {...props} />
            <MeterReadingsModal {...props} />
        </ModalProvider>
    );
};

export const MeterReadingsCard = ({ control, ...props }: any) => {
    const { canEdit, canDelete } = usePermission('project.deviceUsage');
    const { onOpen, dispatch, state } = useModal();
    const readings = state?.readings || [];
    const remote = state?.remote;
    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });

    React.useEffect(() => {
        log.debug(
            'MeterReadingsCard.dispatch',
            value,
            JSON.parse(value?.text || JSON.stringify({}))
        );
        dispatch?.({
            type: 'readings',
            data: value?.text && isJsonString(value?.text) ? JSON.parse(value?.text) : [],
        });
    }, [value]);

    React.useEffect(() => {
        if (!remote) {
            log.debug('MeterReadingsCard.inputProps.onChange', readings, remote);
            inputProps.onChange?.(readings);
        }
    }, [readings, remote]);

    const controls = [
        canEdit && {
            title: 'Bearbeiten',
            props: {
                onClick: (row: any) => {
                    dispatch?.({ type: 'setRow', data: { row } });
                    onOpen?.();
                },
            },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => dispatch?.({ type: 'reading.delete', data: { row } }),
            },
        },
    ].filter(Boolean);
    const columns = React.useMemo(
        () =>
            withRenderers(
                [
                    { Header: 'Id', accessor: 'id' },
                    { Header: 'Hersteller', accessor: 'manufacturer' },
                    { Header: 'Nummer', accessor: 'meterNumber' },
                    {
                        Header: 'Aufbau',
                        width: 80,
                        accessor: 'construction',
                        Cell: ({ row }: any) =>
                            row.original?.setup === 'construction' ? <AiOutlineCheck /> : null,
                    },
                    {
                        Header: 'Abbau',
                        width: 100,
                        accessor: 'dismantling',
                        Cell: ({ row }: any) =>
                            row.original?.setup === 'dismantling' ? <AiOutlineCheck /> : null,
                    },
                    {
                        Header: 'Hauszähler',
                        accessor: 'houseMeter',
                        Cell: ({ value }: any) => (value ? <AiOutlineCheck /> : null),
                    },
                    { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
                ],
                controls
            ),
        []
    );

    return (
        <Card boxShadow="none">
            <CardContent>
                <DataTable<any> columns={columns} hiddenColumns={['id']} data={readings} />
                <VStack py={6}>
                    <IconButton
                        colorScheme="blue"
                        size="lg"
                        color="white"
                        aria-label="weiterer Zählerstand"
                        onClick={() => {
                            onOpen?.();
                        }}
                        icon={<RiAddLine />}
                    />
                </VStack>
            </CardContent>
        </Card>
    );
};

export const MeterReadingsModal = (props: any) => {
    const { isOpen, onClose, dispatch } = useModal();

    const onOverlayClick = () => {
        dispatch?.({ type: 'resetState' });
    };

    return (
        <Modal isOpen={isOpen!} onClose={onClose!} onOverlayClick={onOverlayClick}>
            <ModalOverlay />
            <MeterReadingsModalContent {...props} />
        </Modal>
    );
};

const form = [
    { path: 'manufacturer', ui: { label: 'Hersteller', colSpan: 3 } },
    { path: 'meterNumber', required: true, ui: { label: 'Zählernummer', colSpan: 3 } },
    {
        path: 'setup',
        ui: {
            label: 'Aufbau',
            colSpan: 3,
            component: 'RadioGroup',
            props: {
                options: [
                    { name: 'construction', label: 'Aufbau' },
                    { name: 'dismantling', label: 'Abbau' },
                ],
            },
        },
    },
    { path: 'houseMeter', ui: { label: 'Hauszähler', colSpan: 3, component: 'Checkbox' } },
];
const fields = form.reduce(
    (acc, field) => ({
        ...acc,
        [field?.path]: { api: { path: field?.path, required: field?.required } },
    }),
    {}
);

export const MeterReadingsModalContent = () => {
    const { onClose, dispatch, state } = useModal();
    const { row: meter, rowId: meterId } = state.rows || {};
    const formFields = resolveFormFields(form, fields);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(meter),
    };
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    const onSubmit: SubmitHandler<any> = async (values) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        meterId
            ? dispatch?.({ type: 'reading.update', data: { id: meterId, values } })
            : dispatch?.({ type: 'reading.create', data: { id: Date.now(), values } });
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {meterId ? `Zählerstand bearbeiten` : `Neuer Zählerstand`}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        data-test-id="button-save"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={6} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({ field, control, register, errors })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};
