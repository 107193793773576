import { FiDownload } from 'react-icons/fi';
import { Button, MenuButton, Menu, MenuList, MenuItem, Box } from '@chakra-ui/react';
import { usePdf } from '~/pages/projects/hooks/usePdf';
import { uniqBy } from 'lodash';

export const DeviceUsageDownload = ({ projectId, subprojectId }: any) => {
    const pdf: any = usePdf({ projectId, subprojectId });
    const meterReadingsAndDeviceUsage = [...pdf?.meterReadings, ...pdf?.deviceUsage];
    const residentalUnits = uniqBy(meterReadingsAndDeviceUsage, 'subprojektWohneinheitId');
    const checkCondition = (cond: any, key: string, data: any) => ({ ...(cond && { [key]: data }) })

    console.log('DeviceUsageDownload', { meterReadingsAndDeviceUsage, residentalUnits })

    return residentalUnits.length > 1 ?
        <Box>
            <Menu>
                <MenuButton size="sm" as={Button} colorScheme="gray" leftIcon={<FiDownload />}>Berichte herunterladen</MenuButton>
                <Box>
                    <MenuList>{residentalUnits.map(({ subprojektWohneinheitId, subprojektWohneinheit }: any) =>
                        <MenuItem key={subprojektWohneinheitId} onClick={() => {
                            const stromzaehler = pdf?.meterReadings.filter((meter: any) => meter?.subprojektWohneinheitId === subprojektWohneinheitId);
                            const geraeteeinsatz = pdf?.deviceUsage.filter((device: any) => device?.subprojektWohneinheitId === subprojektWohneinheitId);
                            const client = subprojektWohneinheit?.beteiligter?.[0]?.kontakt


                            pdf?.devicesAndPowerConsumptionNotice?.({
                                ...checkCondition(stromzaehler.length, 'powerConsumptionNotice', { stromzaehler, client }),
                                ...checkCondition(geraeteeinsatz.length, 'deviceUsage', {
                                    geraeteeinsatz,
                                    client
                                }),
                            })
                        }}>{subprojektWohneinheit?.wohneinheit?.bezeichnung}</MenuItem>)}
                        <MenuItem onClick={() => {
                            pdf?.devicesAndPowerConsumptionNotice?.({
                                ...checkCondition(pdf?.meterReadings.length, 'powerConsumptionNotice', { stromzaehler: pdf?.meterReadings }),
                                ...checkCondition(pdf?.deviceUsage.length, 'deviceUsage', { geraeteeinsatz: pdf?.deviceUsage }),
                            })
                        }}>Alle Wohneinheiten</MenuItem>
                    </MenuList>
                </Box>
            </Menu>
        </Box> :
        <Button size="sm" isDisabled={!Boolean(residentalUnits.length)} colorScheme="gray" leftIcon={<FiDownload />} onClick={() => {
            const client = residentalUnits?.[0]?.subprojektWohneinheit?.beteiligter?.[0]?.kontakt
            meterReadingsAndDeviceUsage?.length && pdf?.devicesAndPowerConsumptionNotice?.({
                ...checkCondition(pdf?.meterReadings.length, 'powerConsumptionNotice', { stromzaehler: pdf?.meterReadings, client }),
                ...checkCondition(pdf?.deviceUsage.length, 'deviceUsage', { geraeteeinsatz: pdf?.deviceUsage, client }),
            })
        }}>
            Bericht herunterladen
        </Button>
};
