import React from 'react';
import {
    BoxProps,
    VStack,
    Button,
    SimpleGrid,
    HStack,
    IconButton,
    Text,
    Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AiOutlineArrowRight, AiOutlinePlus } from 'react-icons/ai';
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading } from '@ucc/react/ui';
import { usePage } from '~/hooks/usePage';
import { useDashboard } from '../hooks/useDashboard';
import { HasPermission } from '~/layout/HasPermission';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { selects } from '~/meta/data/selects.gql';
import { useViewer } from '~/hooks/useViewer';
import { PageHeader } from '~/components/Layout/PageHeader';
import log from '~/log';

type PageProps = { name?: string } & BoxProps;

export const Dashboard = ({ name: title = 'Dashboard' }: PageProps) => {
    const viewer = useViewer();
    const { control, watch } = useForm();
    const { branch } = watch();
    const branchId = branch?.value;
    const employeeId = viewer?.app?.mitarbeiterId;
    const dataTableProps = useDashboard({
        filter: {
            erledigtExists: false,
            zuErledigenNotInFuture: true,
            ...(employeeId && { mitarbeiterIdSachbearbeiter: employeeId }),
            ...(branchId && {
                subprojekt: {
                    projekt: { niederlassungId: branchId },
                },
            }),
        },
    });
    const { setBranchId } = usePage();
    const canView = viewer.hasPermission('project.tasks', 'view');
    log.debug('viewer', { employeeId, canView });




    React.useEffect(() => {
        setBranchId?.(branchId);
    }, [branchId]);

    if (!canView) {
        return null;
    }

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
            </PageHeader>
            <VStack p={6} align="flex-start">
                <Card boxShadow="none">
                    <CardHeader>
                        <CardHeading>Meine offenen Aufgaben</CardHeading>
                        <CardActions>
                            <Box minW={210}>
                                <QuerySelect
                                    control={control}
                                    isClearable
                                    name="branch"
                                    placeholder="Niederlassung"
                                    query={selects.branches}
                                    mapOptions={(item) => ({
                                        value: item?.value,
                                        label: `${item?.nummer} - ${item?.bezeichnung}`,
                                    })}
                                />
                            </Box>
                            <Button
                                data-test-id="to-aufgaben"
                                as={Link}
                                to="/projekte/aufgaben"
                                px={5}
                                colorScheme="blue"
                                rightIcon={<AiOutlineArrowRight />}
                            >
                                Alle Offenen Aufgaben
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardContent>
                        <DataTable pageSize={15} {...dataTableProps} />
                    </CardContent>
                </Card>
                <HasPermission resource="project.project" permission='create'>
                    <SimpleGrid columns={3}>
                        <Card boxShadow="none">
                            <CardHeader>
                                <CardHeading>Schnelleinstieg</CardHeading>
                            </CardHeader>
                            <HStack
                                boxShadow="md"
                                p={6}
                                rounded="md"
                                w="full"
                                borderWidth={1}
                                borderColor="gray.200"
                            >
                                <Text>Neues Projekt anlegen</Text>
                                <IconButton
                                    aria-label="creat new project"
                                    icon={<AiOutlinePlus />}
                                    as={Link}
                                    to="/projekt/neu"
                                />
                            </HStack>
                        </Card>
                    </SimpleGrid>
                </HasPermission>

            </VStack>
        </>
    );
};
