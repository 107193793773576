import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useRedirectToProjectSearch = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname = '' } = location || {};

    const onCompleted = React.useCallback(
        (data: any) => {
            const { error, item } = data?.item || {};
            const state = { isProjectRedirect: true, location };

            /** FIXME: redirect if combination of projektId and subprojektId makes no sence  */
            // if (item) {
            //     if (
            //         item?.__typename === 'Subprojekt' &&
            //         !(
            //             pathname.includes(`/projekte/${item?.projektId}/${item?.id}`) ||
            //             pathname.includes(`/projekte/${item?.projektId}`)
            //         )
            //     ) {
            //         setTimeout(
            //             () =>
            //                 navigate('/projekt/suchen', {
            //                     state,
            //                 }),
            //             1000
            //         );
            //     }
            //     if (
            //         item?.__typename === 'Projekt' &&
            //         !(
            //             pathname.includes(`/projekte/${item?.id}/${item?.subprojektId}`) ||
            //             pathname.includes(`/projekte/${item?.id}`)
            //         )
            //     ) {
            //         setTimeout(
            //             () =>
            //                 navigate('/projekt/suchen', {
            //                     state,
            //                 }),
            //             1000
            //         );
            //     }
            // }

            if (error) {
                setTimeout(
                    () =>
                        navigate('/projekt/suchen', {
                            state: {
                                ...state,
                                description: data?.item?.error?.message,
                            },
                        }),
                    1000
                );
            }
        },
        [location, pathname, navigate]
    );

    return onCompleted;
};
