import React from 'react';
import { useController } from 'react-hook-form';
import { Input, InputGroup, InputRightAddon, chakra, Spinner, useToast } from '@chakra-ui/react';
import { useAsset } from '~/hooks/useAsset';

export const S3Upload = ({
    control,
    placeholder,
    multiple,
    fileName: controlledFileName,
    onlySupportedFormats = true,
    imageOnly = false,
    isDisabled = false,
    ...props
}: any & { multiple?: boolean, imageOnly?: boolean, onlySupportedFormats?: boolean }) => {
    const toast = useToast();
    const imageFormats = ['image/png', 'image/jpg', 'image/jpeg'];
    const fileFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const supportedFormats = imageOnly ? imageFormats : [...imageFormats, ...fileFormats];

    const toastProps: any = ({
        position: 'top',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        title: imageOnly ? 'Falsches Bildformat' : 'Falsches Dateiformat',
        description:
            imageOnly ?
                'Bitte wählen Sie ein Bild im PNG oder JPG/JPEG Format aus.' :
                'Bitte wählen Sie nur Dateien im folgendem Format aus: PNG, JPG/JPEG, Word oder PDF.',
    })

    const { upload } = useAsset();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [loading, setLoading] = React.useState(false);

    const {
        field: { name, value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });

    const fileName = controlledFileName ? controlledFileName : Array.isArray(value)
        ? value?.map(({ fileName }) => fileName)?.join(', ')
        : value?.fileName || '';

    const handleChange = multiple
        ? async (event: React.ChangeEvent<HTMLInputElement>) => {
            setLoading(true);
            const files = [...(event.target.files || [])];


            try {
                if (files?.every((file) => file instanceof File)) {
                    const response = await Promise.all(
                        files?.map(async (file) => {
                            if (!supportedFormats.includes(file?.type)) {
                                setLoading(false);
                                toast(toastProps);
                                return Promise.reject();
                            }

                            return await upload(file)
                        })
                    );

                    props?.onChange?.(response);
                    inputProps.onChange?.(
                        response?.map(({ key, name: fileName }: any) => ({ key, fileName }))
                    );
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
        : async (event: React.ChangeEvent<HTMLInputElement>) => {
            setLoading(true);

            try {
                const file = event.target.files?.[0] as File;

                if (!supportedFormats.includes(file?.type)) {
                    setLoading(false);
                    return toast(toastProps);
                }

                if (file instanceof File) {
                    const { key, name } = await upload(file) as any || {};
                    props?.onChange?.({ key, fileName: name });
                    inputProps.onChange?.({ key, fileName: name });
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

    const open = () => inputRef?.current?.click();

    return (
        <InputGroup>
            <chakra.input
                ref={inputRef}
                name={name}
                onChange={handleChange}
                type="file"
                display="none"
                multiple={multiple}
            />
            <Input
                id={name}
                data-test-id={name}
                defaultValue={fileName}
                onClick={open}
                isDisabled={isDisabled}
                placeholder={placeholder || 'keine Datei ausgewählt'}
            />
            <InputRightAddon cursor="pointer" onClick={open} pointerEvents={isDisabled ? 'none' : 'auto'} >
                {loading ? <Spinner size="sm" /> : 'Datei auswählen'}
            </InputRightAddon>
        </InputGroup>
    );
};
