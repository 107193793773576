import { Button, ButtonGroup, Center, Spinner, Stack, Text } from '@chakra-ui/react';
import { useSecurity } from '@ucc/react/security';
import * as Sentry from '@sentry/react';
import { useActor } from '@xstate/react';
import { PropsWithChildren } from 'react';
import log from '~/log';

export function AppLoader({ children }: PropsWithChildren) {
    const { authService } = useSecurity();
    const [state] = useActor(authService);

    log.debug('auth.loader', state.value, state.context);

    if (state.matches('idle')) {
        return <Loading />;
    }

    const isLoading = ['authenticating', 'fetchingUserData', 'retrying'].some(state.matches);

    if (isLoading) {
        return <Loading message="Lade..." />;
    }

    if (state.matches('unauthorized')) {
        return <Loading message="Lade Berechtigungen..." />;
    }

    if (state.context.user && state.matches('error')) {
        return <Retry />;
    }

    return <>{children}</>;
}

function Loading({ message }: { message?: string }) {
    return (
        <Center height={500}>
            <Stack align="center">
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
                <Text fontSize="xl" color="blue.500">
                    {message}
                </Text>
            </Stack>
        </Center>
    );
}

function Retry() {
    const { authService } = useSecurity();
    const [state] = useActor(authService);

    const retry = () => {
        log.debug('auth.retry', state.value, state.context);
        const { user } = state.context;
        Sentry.captureMessage(`User retrying permission loading`, {
            extra: {
                email: user?.id,
                hasPermissions: user?.permissions && user?.permissions.length > 0,
            },
        });
        authService.send({ type: 'RETRY' });
    };

    const logout = () => {
        log.debug('auth.logout', state.value, state.context);
        authService.send({ type: 'LOGOUT' });
    };

    return (
        <Center height={500}>
            <Stack align="center" maxW={500}>
                <Text>Ihre Berechtigungen konnten nicht geladen werden.</Text>
                <Text>Bitte versuchen Sie es erneut.</Text>
                <ButtonGroup>
                    <Button
                        isLoading={state.matches('retrying')}
                        colorScheme="gray"
                        fontSize="md"
                        onClick={retry}
                    >
                        Nochmal versuchen
                    </Button>
                    <Button
                        isLoading={state.matches('retrying')}
                        colorScheme="teal"
                        fontSize="md"
                        onClick={logout}
                    >
                        Neu anmelden
                    </Button>
                </ButtonGroup>
            </Stack>
        </Center>
    );
}
