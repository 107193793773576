import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Stack, Heading, Box, useColorModeValue as mode, BoxProps, HStack, useMediaQuery, Portal } from '@chakra-ui/react';
import { usePage } from '~/hooks/usePage';

export const PageHeaderPortal = () => {
    const { pageContext } = usePage();
    return <Box ref={pageContext?.pageHeaderRef} zIndex={100} />
};

export const PageHeaderLink = (props: NavLinkProps & BoxProps) => (
    <Box
        as={NavLink}
        fontWeight={400}
        px="4"
        py="3"
        color={mode('gray.600', 'gray.400')}
        borderBottom="2px solid transparent"
        transition="all 0.2s"
        _hover={{
            borderColor: mode('gray.400', 'gray.600'),
        }}
        _activeLink={{
            color: mode('blue.600', 'blue.400'),
            borderColor: 'currentColor',
        }}
        style={{ margin: 0 }}
        {...props}
    />
);


export const PageHeader = ({ children }: React.PropsWithChildren & { renderActions?: () => any }) => {
    const { pageContext } = usePage();
    const [isMedia] = useMediaQuery('(max-width: 1290px)');

    const childrenArray = React.Children.toArray(children);
    const renderChild = (displayName: 'Actions' | 'Title' | 'Navigation') => childrenArray.find((child: any) => child?.type?.displayName === displayName) || null;


    const navigation = renderChild('Navigation');
    const actions = renderChild('Actions');

    return <Portal containerRef={pageContext?.pageHeaderRef}>
        <Stack
            direction={{ base: 'column', lg: 'row' }}
            align={{ base: 'start', lg: 'center' }}
            justify="space-between"
            bg="bg-accent"
            zIndex={100}
            spacing={4}
            px={16}
            {...{ ...(navigation ? { pt: 8 } : { py: 8 }) }}
        >
            <Stack spacing="1" w="full">
                <HStack justify="space-between" w="full">
                    {renderChild('Title')}
                    {isMedia && <>
                        <Box>{actions}</Box>
                        <Box ref={pageContext?.pageActionsRef} />
                    </>
                    }
                </HStack>
                <Box>{navigation}</Box>
            </Stack>
            {!isMedia && <>
                <Box>{actions}</Box>
                <Box ref={pageContext?.pageActionsRef} />
            </>
            }
        </Stack>
    </Portal>

}

export const Title = (props: React.PropsWithChildren) => {
    return <Heading color="gray.700" fontWeight="bold" size={{ base: 'xs', lg: 'sm' }} {...props} />
}

export const Actions = (props: React.PropsWithChildren) => {
    return <Box {...props} />
}

export const OverrideActions = ({ children }: React.PropsWithChildren) => {
    const { pageContext } = usePage();
    return <Portal containerRef={pageContext?.pageActionsRef}>{children}</Portal>
}

export const Navigation = (props: React.PropsWithChildren) => {
    return <Box {...props} />
}

Title.displayName = 'Title'
Actions.displayName = 'Actions'
OverrideActions.displayName = 'OverrideActions'
Navigation.displayName = 'Navigation'


PageHeader.Title = Title
PageHeader.Actions = Actions
PageHeader.OverrideActions = OverrideActions
PageHeader.Navigation = Navigation


