import React from 'react';
import {
    Button,
    Center,
    Container,
    HStack,
    FormControl,
    FormLabel,
    Input,
    Spinner,
    VStack,
    Stack,
    useToast,
} from '@chakra-ui/react';
import { Card, CardContent } from '@ucc/react/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { PageHeader } from '~/components/Layout/PageHeader';
import { PermissionGroups } from '../components/PermissionGroups';
import { useAuth } from '../hooks/useAuth';
import { permissionGroups } from '../config/permissionGroups';
import { SecurityRoleCreateInputSchema, SecurityRoleUpdateInputSchema } from '~/gql/ucc/validation';
import { PermissionGroup } from '../components/types';
import { HasPermission } from '~/layout/HasPermission';
import log from '~/log';

type Inputs = { name: string; permissionGroups: PermissionGroup[] };

export function RoleForm() {
    const { state = {} } = useLocation();
    const isCopy = state?.isCopy || false;
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const isNew = id === 'neu';
    const { role, createMutation, updateMutation, isReadLoading } = useAuth({ id });
    const toast = useToast();

    const defaultValues = {
        permissionGroups,
    };
    const { control, register, handleSubmit, reset } = useForm<Inputs>({
        defaultValues,
    });

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        log.debug('onSubmit.formData', formData);
        const data = isNew
            ? SecurityRoleCreateInputSchema().parse(formData)
            : SecurityRoleUpdateInputSchema().parse(formData);
        log.debug('onSubmit.data', data);

        const response =
            isNew || isCopy
                ? await createMutation({ variables: { data } })
                : await updateMutation({ variables: { id: id!, data } });
        log.debug('onSubmit.response', response);

        const { error } = response.data?.security?.role || {};
        if (error) {
            toast({
                title: 'Fehler beim Speichern',
                description:
                    error.code === 'P2002' ? 'Kürzel bereits vergeben' : 'Unerwarteter Fehler',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        } else {
            navigate('/personal/berechtigungen');
        }
    };


    React.useEffect(() => {
        reset({ ...defaultValues, ...role, ...(isCopy && { name: `${role?.name} (Kopie)` }) });
    }, [role?.id, isCopy]);

    if (isReadLoading) {
        return (
            <Center mt={5}>
                <Spinner color="blue.500" size="xl" />
            </Center>
        );
    }

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{isNew
                    ? 'Neues Berechtigungsprofil'
                    : isCopy
                        ? 'Berechtigungsprofil kopieren'
                        : 'Berechtigungsprofil bearbeiten'}
                </PageHeader.Title>
                <PageHeader.Actions>
                    <HStack>
                        {isNew && (
                            <HasPermission resource="staff.permissions" permission="create">
                                <Button onClick={() => navigate('/personal/berechtigungen')}>
                                    Abbrechen
                                </Button>
                                <Button colorScheme="blue" onClick={handleSubmit(onSubmit)}>
                                    Speichern
                                </Button>
                            </HasPermission>
                        )}
                        {!isNew && (
                            <HasPermission resource="staff.permissions" permission="update">
                                <Button onClick={() => navigate('/personal/berechtigungen')}>
                                    Abbrechen
                                </Button>
                                <Button colorScheme="blue" onClick={handleSubmit(onSubmit)}>
                                    Speichern
                                </Button>
                            </HasPermission>
                        )}
                    </HStack>
                </PageHeader.Actions>
            </PageHeader>
            <Container maxW={800} py={8}>
                <VStack spacing={8}>
                    <Card>
                        <CardContent p={6}>
                            <Stack>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="name">Name</FormLabel>
                                    <Input {...register('name')} type="text" />
                                </FormControl>
                            </Stack>
                        </CardContent>
                    </Card>
                    <PermissionGroups control={control} name="permissionGroups" />
                </VStack>
            </Container>
        </>
    );
}
