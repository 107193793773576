import React from 'react';
import { ModalProps } from '@chakra-ui/react';
import { useModal } from '~/hooks/useModal';

export const MultiModal = ({
    children,
}: Pick<ModalProps, 'initialFocusRef' | 'scrollBehavior' | 'children'>) => {
    const { state, isOpen } = useModal();

    const modalContent = React.Children.toArray(children).find(
        (child) => {
            // @ts-ignore
            return React.isValidElement(child) && child.props.id === state.modals?.activeModal || React.isValidElement(child) && child?.type?.name === state.modals?.activeModal;
        }
    ) as React.ReactElement;

    if (!modalContent || !isOpen) {
        return null;
    }

    return React.cloneElement(modalContent, {})
};
