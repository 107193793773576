import { NavLink } from 'react-router-dom';
import { chakra, Flex, FlexProps } from '@chakra-ui/react';
import poeppinghaus from '~/assets/logo_poeppinghaus.png';

interface LogoProps extends FlexProps {
    [key: string]: any;
}

export const Logo = (props: LogoProps) => {
    return (
        <Flex
            as={NavLink}
            py={4}
            to="/"
            justifyContent="center"
            cursor="pointer"
            maxW="256px"
            {...props}
        >
            <chakra.img src={poeppinghaus} alt="poeppinghaus logo" height="100%" />
        </Flex>
    );
};