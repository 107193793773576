import { tableColumnMapper } from '~/utils';

// Tables
const columns = {
  units: {
    __graphqlType: 'Wohneinheit',
    id: { header: 'Id', accessor: 'id' },
    description: { header: 'Bezeichnung', accessor: 'wohneinheit.bezeichnung' },
    contact: { header: 'Kontakt', accessor: 'beteiligter', renderer: { name: 'ShowContact' } },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  units: {
    columns: ['id', 'description',  'wattro','contact', 'controls'].map(tableColumnMapper(columns.units)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  input: {
    __graphqlMutation: 'createWohneinheitWithSubprojektWohneinheit',
    subprojectId: { api: { path: 'subprojektId', required: true } },
    description: { api: { path: 'bezeichnung', required: true } },
  },
  units: {
    __graphqlType: 'Wohneinheit',
    projectId: { api: { path: 'projektId', required: true } },
    description: { api: { path: 'bezeichnung', required: true } },
    number: { api: { path: 'nr', required: true } },
    contactId: { api: { path: 'subprojektWohneinheit', required: true } },
  },
  subprojectUnits: {
    __graphqlType: 'SubprojektWohneinheit',
    description: { api: { path: 'wohneinheit.bezeichnung', required: true } },
    number: { api: { path: 'nr', required: true } },
    contactId: { api: { path: 'beteiligter', required: true } },
  }
};

export const forms = {
  units: [
    { path: 'description', ui: { label: 'Bezeichnung' } },
    {
      path: 'contactId',
      ui: { label: 'Kontakt', component: 'ContactInput' },
      required: true,
      decorators: {
        form: [
          (value:any) => value?.[0]?.beteiligter?.[0]?.kontakt?.id
        ],
      },
    },
  ],
  subprojectUnits:[
    { path: 'description', ui: { label: 'Bezeichnung' } },
    {
      path: 'contactId',
      ui: { label: 'Kontakt', component: 'ContactInput' },
      required: true,
      decorators: {
        form: [ 
          (value:any) => value?.[0]?.kontakt?.id
         ],
      },
    },
  ]
};
