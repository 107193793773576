import React from 'react';
import { VStack, Button, Checkbox } from '@chakra-ui/react';
import { DataTable, Card, CardContent, CardHeading, CardActions, CardHeader } from '@ucc/react/ui';
import { useOpenDocuments } from '../hooks/useOpenDocuments';
import { PageHeader } from '~/components/Layout/PageHeader';

export function OpenDocuments({ title }: { title: string }) {
    const [selected, setSelected] = React.useState<any[]>([]);
    const { data, columns, loading, onMoveSelection } = useOpenDocuments({
        selection: [selected, setSelected],
    });
    const dataIds = data.map(({ id }: any) => id);
    const isChecked = dataIds?.length === selected.length;
    const counter = selected.length && `(${selected.length})`;

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
            </PageHeader>
            <VStack spacing={6} p={6}>
                <Card boxShadow="none">
                    {data?.length ? (
                        <CardHeader>
                            <CardHeading />
                            <CardActions w={430} justify="space-between">
                                <Checkbox
                                    data-test-id="open-documents-select-all"
                                    isChecked={isChecked}
                                    onChange={(e) =>
                                        e.target.checked ? setSelected(dataIds) : setSelected([])
                                    }
                                >
                                    Alle Treffer auswählen
                                </Checkbox>
                                <Button
                                    data-test-id="open-documents-move-button"
                                    colorScheme="blue"
                                    isDisabled={!Boolean(counter)}
                                    onClick={onMoveSelection}
                                >
                                    {['Verschieben', counter].filter(Boolean).join('  ')}
                                </Button>
                            </CardActions>
                        </CardHeader>
                    ) : null}
                    <CardContent>
                        <DataTable columns={columns} data={data} loading={loading} pageSize={50} />
                    </CardContent>
                </Card>
            </VStack>
        </>
    );
}
