import { tableColumnMapper } from '~/utils/tableColumnMapper';

const columns = {
    __graphqlType: 'Notdienstprojekt',
    humanid: { header: 'Titel', accessor: 'humanid',  renderer: { name:'HumanId' } },
    address: { header: 'Adresse', accessor: 'address' },
    notes: { header: 'Notiz', accessor: 'notes' },
    responsible: { header: 'Verantwortlich', accessor: 'mitarbeiterByEmail.name', renderer: { name: 'EmergencyResponsible' } },
    createdAt: { header: 'Timestamp', accessor: 'createdAt', renderer: { name: 'EmergencyTimestamp' } },
    status: { header: 'Status', accessor: 'status' },
    controls: {
        accessor: 'controls',
        width: 60,
        renderer: { name: 'Controls' },
        disableSortBy: true,
    },
};

export const table = {
    columns: ['humanid', 'address', 'notes', 'responsible', 'createdAt', 'status', 'controls'].map(
        tableColumnMapper(columns)
    )
};

