import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    useDisclosure,
    Button,
} from '@chakra-ui/react';

export function Question({
    listenTo,
    question,
    description,
    onConfirmation,
    isCentered = true
}: {
    listenTo: unknown;
    question: string;
    description: string;
    onConfirmation: () => void;
    isCentered?: boolean
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);

    React.useEffect(() => {
        const delay = setTimeout(() => listenTo && onOpen(), 700);
        return () => clearInterval(delay);
    }, [listenTo]);

    return (
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered={isCentered}
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>{question}</AlertDialogHeader>
                <AlertDialogBody>{description}</AlertDialogBody>
                <AlertDialogFooter>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            onConfirmation?.();
                            onClose();
                        }}
                    >
                        Ja
                    </Button>
                    <Button ref={cancelRef} onClick={onClose} ml={3}>
                        Nein
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
