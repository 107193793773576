import { Rahmenvertragsposition } from '~/gql/ucpw/graphql';

export const calculationReducer = (state: any = {}, action: any = {}) => {
    const { type, data = {} } = action;

    switch (type) {
        case 'setAgreement': {
            const { row, ...context } = data || {};
            const original = row?.original as Rahmenvertragsposition;
            const position = {
                rahmenvertragspositionId: original?.id,
                preisProEinheit: original?.preisProEinheit,
                rahmenvertragsposition: {
                    leistung: original?.leistung,
                    beschreibung: original?.beschreibung,
                },
            };
            const newState = { ...state, rows: { row: position, rowId: undefined, context } };

            return newState;
        }
    }
};
