import { useConfirmationDialog } from '~/hooks/useConfirmationDialog';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react';

export const Dialog = () => {
    const { dialogContext, dialogService, onClose } = useConfirmationDialog();

    return (
        <Modal isOpen={dialogContext?.isOpen || false} onClose={() => onClose?.()} data-testid="dialog">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{dialogContext?.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{dialogContext?.message}</ModalBody>

                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="blue"
                        onClick={() => {
                            dialogService.send('CANCEL');
                        }}
                    >
                        {dialogContext?.cancelLabel}
                    </Button>
                    <Button variant="ghost" onClick={() => {
                        dialogService.send('CONFIRM');
                    }}>
                        {dialogContext?.acceptLabel}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};
