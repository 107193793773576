export const getCommaDecimals = (value: any) => {
    const type = typeof value;
    switch (type) {
        case 'string':
            return value.replace('.', ',');
        case 'number':
            return value.toFixed(2).replace('.', ',');
        default:
            return null;
    }
};
