import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    CreateOperationReportMutationVariables,
    Einsatzbericht,
    EinsatzberichtDetail,
    EinsatzberichtDetailInput,
    ListOperationReportDetailsQueryVariables,
    UpdateOperationReportMutationVariables,
    CreateOperationReportDetailDocument,
    ListOperationReportDetailsDocument,
    ListOperationReportsDocument,
    ListOperationReportOptionsDocument,
    GetOperationReportDocument,
    UpsertOperationReportDocument,
    CreateOperationReportDocument,
    UpdateOperationReportDocument,
    UpdateOperationReportDetailDocument,
    DeleteOperationReportDocument,
} from '~/gql/ucpw/graphql';
import { useContextAndRefetchQueries, useDeleteWithConfirmation, usePermission } from '~/hooks';
import { client } from '~/apollo';
import { usePdf, usePdfRefetchQueries } from '~/pages/projects/hooks/usePdf';
import { tables } from '~/pages/projects/meta/data/operation-reports.schema';
import { printCreateOrUpdateMutation } from '~/pages/projects/meta/operationalReport';
import { ProjectIds } from '~/pages/projects/types';
import { useEditable } from './useEditable';
import { document } from '~/pages/projects/meta/data/operation-reports.schema';
import { isJsonString, withRenderers } from '~/utils';
import log from '~/log';

type Props = {
    tableName?: 'operationalReports';
    refetchQueries?: [];
} & ProjectIds;

export const useOperationalReports = ({
    tableName = 'operationalReports',
    subprojectId,
    ...props
}: Props) => {
    const { canView, canEdit, canDelete, readOnly, canCreate } = usePermission('project.report');
    const pdf = usePdf({ subprojectId });
    const { reports: reportsRefetchQueries } = usePdfRefetchQueries({ subprojectId });
    const { updateEditable, editId } = useEditable();
    const variables = { filter: { subprojektId: subprojectId } };
    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListOperationReportDetailsDocument,
        { filter: { einsatzbericht: { subprojektId: subprojectId } } }
    );

    const contextAndRefetchQueriesListOperationReports = useContextAndRefetchQueries(
        ListOperationReportsDocument,
        variables
    );

    // ===============================================
    // LIST
    // ===============================================

    const context = { clientName: 'ucpw' };
    const {
        data: listData,
        loading: listLoading,
        error: listError,
    } = useQuery(ListOperationReportsDocument, { context, variables });
    const reports = listData?.items?.items || [];

    const {
        data: optionsData,
        loading: optionsLoading,
        error: optionsError,
    } = useQuery(ListOperationReportOptionsDocument, {
        variables: { limit: 1000, filter: {} },
        context,
    });
    const options = optionsData?.items?.items || [];

    // ===============================================
    // GET
    // ===============================================

    const reportId = parseInt(editId as string);

    const {
        data: reportData,
        loading: reportLoading,
        error: reportError,
    } = useQuery(GetOperationReportDocument, {
        skip: !Boolean(reportId),
        variables: { id: reportId as number },
        context,
    });
    const report = reportData?.item?.item || ({} as Einsatzbericht);

    const {
        data: detailsData,
        loading: detailsLoading,
        error: detailsError,
    } = useQuery(ListOperationReportDetailsDocument, {
        variables: { limit: 100, filter: { einsatzberichtId: reportId } },
        skip: !Boolean(reportId),
        context,
    });
    const details = (detailsData?.items?.items || []) as EinsatzberichtDetail[];
    log.debug('useOperationalReports.details', details);

    const refetchQueries = [
        // internal
        ...contextAndRefetchQueries.refetchQueries,
        ...contextAndRefetchQueriesListOperationReports.refetchQueries,
        ...(reportId
            ? [
                  { query: GetOperationReportDocument, context, variables: { id: reportId } },
                  // proper refetching of report details
                  {
                      query: ListOperationReportDetailsDocument,
                      context,
                      variables: { limit: 100, filter: { einsatzberichtId: reportId } },
                  },
              ]
            : []),

        // usePdf
        reportsRefetchQueries,
        // external
        ...(props.refetchQueries || []),
    ];

    // ===============================================
    // UPSERT
    // ===============================================

    const config = { context, refetchQueries };

    const [upsertMutation] = useMutation(UpsertOperationReportDocument, config);

    // ===============================================
    // CREATE
    // ===============================================
    const [createDetailsLoading, setCreateDetailsLoading] = React.useState(false);

    const [createReportMutation] = useMutation(CreateOperationReportDocument, config);
    const [createDetailMutation] = useMutation(CreateOperationReportDetailDocument, { context });

    const createMutation = async ({
        report,
        details = [],
    }: {
        report: CreateOperationReportMutationVariables;
        details: any;
    }) => {
        log.debug('operationalReport.createMutation', { report, details });
        await createReportMutation({ variables: report });

        const mutation = printCreateOrUpdateMutation(
            details?.filter(
                (detail: any) => (detail as EinsatzberichtDetailInput).einsatzberichtOptionId
            )
        );
        log.debug('useOperationalReports.printMutation', mutation);

        try {
            setCreateDetailsLoading(true);
            await client.mutate({ mutation: gql(mutation), context: { clientName: 'ucpw' } });
            setCreateDetailsLoading(false);
        } catch (error) {
            log.error(error);
            setCreateDetailsLoading(false);
        }
    };

    // ===============================================
    // UPDATE
    // ===============================================
    const [updateDetailsLoading, setUpdateDetailsLoading] = React.useState(false);

    const [updateReportMutation, { loading: updateLoading }] = useMutation(
        UpdateOperationReportDocument,
        config
    );

    const [updateDetailMutation, { loading: udateDetailLoading }] = useMutation(
        UpdateOperationReportDetailDocument,
        config
    );

    const updateMutation = async ({
        report,
        details = [],
    }: {
        report: UpdateOperationReportMutationVariables;
        details: any;
    }) => {
        log.debug('operationalReport.updateMutation', { report, details });
        await updateReportMutation({ variables: report });

        const { data } = await client.query<any, ListOperationReportDetailsQueryVariables>({
            query: ListOperationReportDetailsDocument,
            variables: { limit: 100, filter: { einsatzberichtId: report?.id } },
            context,
            fetchPolicy: 'no-cache',
        });
        const idByOptionId = data?.items?.items.reduce(
            (acc: { [key: number]: number }, detail: EinsatzberichtDetail) => ({
                ...acc,
                [detail.einsatzberichtOptionId]: detail.id,
            }),
            {}
        );
        log.debug('operationalReport.updateMutation', { data, idByOptionId });

        const mutation = printCreateOrUpdateMutation(
            details
                ?.filter(
                    (detail: any) => (detail as EinsatzberichtDetailInput).einsatzberichtOptionId
                )
                ?.map((detail: EinsatzberichtDetailInput) => ({
                    ...detail,
                    id: idByOptionId[detail.einsatzberichtOptionId],
                }))
        );
        log.debug('useOperationalReports.printMutation', mutation);

        try {
            setUpdateDetailsLoading(true);
            await client.mutate({ mutation: gql(mutation), context: { clientName: 'ucpw' } });
            setUpdateDetailsLoading(false);
        } catch (error) {
            log.error(error);
            setUpdateDetailsLoading(false);
        }
    };

    // ===============================================
    // DELETE
    // ===============================================

    const [deleteMutation] = useMutation(DeleteOperationReportDocument, config);

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries,
        dependencies: [deleteMutation, refetchQueries],
    });

    // ===============================================
    // TABLE
    // ===============================================

    const controls = [
        {
            title: 'Ansehen',
            props: {
                onClick: (row: any) => updateEditable(row?.original?.id),
            },
            enabled: () => (canView || readOnly) && !canEdit,
        },
        {
            title: 'Bearbeiten',
            props: {
                onClick: (row: any) => updateEditable(row?.original?.id),
            },
            enabled: () => canEdit,
        },
        {
            title: 'Download',
            props: {
                onClick: (row: any) => {
                    console.log('Download', row?.original);
                    return pdf.leakDetectionReport({
                        report: row?.original,
                        details,
                        documentRaw: document,
                    });
                },
            },
            enabled: (row: any) => [canView, readOnly, canEdit, canCreate].some(Boolean),
        },
        { title: 'divider', enabled: () => (canEdit || canView || readOnly) && canDelete },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => deleteWithConfirmation({ id: row?.original?.id }),
            },
            enabled: () => canDelete,
        },
    ].filter(Boolean);

    const resolvedResetValues = React.useMemo(
        () =>
            details?.reduce?.((acc: any, detail: any) => {
                const key = detail?.einsatzberichtOption.bezeichnung
                    ?.toLowerCase()
                    ?.replace(/([-_][a-z])/g, (group: any) =>
                        group.toUpperCase().replace('-', '').replace('_', '')
                    );
                const data = {
                    ...(detail?.text && { text: detail?.text }),
                    ...(detail?.anzahl && { anzahl: detail?.anzahl }),
                    ...((detail?.jaNein || typeof detail?.jaNein === 'boolean') && {
                        jaNein: detail?.jaNein,
                    }),
                };

                const resolve = (data: any) => ({
                    ...acc,
                    [key]: ['agHandwerker', 'weitereArbeiten'].includes(key)
                        ? data
                        : data.hasOwnProperty('jaNein')
                        ? data?.jaNein
                        : data,
                    ...data,
                });

                switch (detail?.einsatzberichtOption.bezeichnung) {
                    case 'ag_handwerker':
                        return resolve({
                            ...data,
                            details,
                        });
                    case 'weitere_arbeiten':
                        return resolve({
                            ...data,
                            details,
                        });
                    case 'druckpruefung_massnahme':
                        return resolve({ [detail?.einsatzberichtOption.bezeichnung]: data });
                    case 'fotodokumentation':
                        return resolve(isJsonString(data?.text) ? JSON.parse(data?.text) : []);
                    case 'erstortungTyp':
                        return {
                            ...acc,
                            erstortungTyp: { ...acc?.erstortungTyp, erstortungTyp: data?.jaNein },
                        };
                    case 'nachortungTyp':
                        return {
                            ...acc,
                            erstortungTyp: { ...acc?.erstortungTyp, nachortungTyp: data?.jaNein },
                        };
                    case 'notdiensteinsatzTyp':
                        return { ...acc, notdiensteinsatzTyp: data };
                    default:
                        return [
                            'druckpruefung_massnahme',
                            'druckverlust_heizung_druckpruefung',
                            'druckverlust_kaltwasser_druckpruefung',
                            'druckverlust_warmwasser_druckpruefung',
                            'heizung_druckpruefung_massnahme',
                            'kaltwasser_druckpruefung_massnahme',
                            'warmwasser_druckpruefung_massnahme',
                        ].includes(detail?.einsatzberichtOption.bezeichnung)
                            ? {
                                  ...acc,
                                  druckpruefungMassnahme: {
                                      ...acc?.['druckpruefungMassnahme'],
                                      [detail?.einsatzberichtOption.bezeichnung]: data,
                                  },
                              }
                            : [
                                  'sonstigesSchadenursache',
                                  'mehrfamilienhausSchadenursache',
                                  'tracerGasMassnahme',
                                  'geboschelleMassnahme',
                                  'fliesenanzahlSum',
                                  'gewerbeSchadenursache',
                                  'sonstigesMassnahme',
                              ].includes(key)
                            ? resolve({ checked: data?.jaNein, input: data?.text })
                            : resolve(data);
                }
            }, {}),
        [details]
    );

    const columns = React.useMemo(
        () => withRenderers(tables[tableName].columns, controls),
        [controls, pdf?.leakDetectionReports]
    );
    const hiddenColumns = tables[tableName].hiddenColumns;

    const isReadLoading = listLoading || optionsLoading || reportLoading || detailsLoading;
    const isReadError = listError || optionsError || reportError || detailsError;

    const isWriteLoading =
        updateLoading || udateDetailLoading || createDetailsLoading || updateDetailsLoading;

    const loading = isReadLoading || isWriteLoading;

    return {
        details,
        document,
        data: reports,
        report: report as any,
        options: options as any,
        loading,
        isReadLoading,
        isReadError,
        resolvedResetValues,
        isWriteLoading,
        columns,
        hiddenColumns,
        upsertMutation,
        createMutation,
        createDetailMutation,
        updateMutation,
        updateDetailMutation,
        deleteMutation,
        deleteWithConfirmation,
    };
};
