/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    AWSDate: any;
    AWSDateTime: any;
    AWSJSON: any;
    AWSPhone: any;
};

export type Abwesenheit = {
    __typename?: 'Abwesenheit';
    datumFrom: Scalars['AWSDate'];
    datumUntil: Scalars['AWSDate'];
    id: Scalars['Int'];
    mitarbeiter?: Maybe<Mitarbeiter>;
    mitarbeiterId: Scalars['Int'];
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
};

export type AbwesenheitCollection = {
    __typename?: 'AbwesenheitCollection';
    error?: Maybe<Error>;
    items: Array<Abwesenheit>;
    pageInfo: PageInfo;
};

export type AbwesenheitFilterInput = {
    datumFromFrom?: InputMaybe<Scalars['AWSDate']>;
    datumFromTo?: InputMaybe<Scalars['AWSDate']>;
    datumUntilFrom?: InputMaybe<Scalars['AWSDate']>;
    datumUntilTo?: InputMaybe<Scalars['AWSDate']>;
    mitarbeiter?: InputMaybe<MitarbeiterFilterInput>;
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
};

export type AbwesenheitInput = {
    datumFrom: Scalars['AWSDate'];
    datumUntil: Scalars['AWSDate'];
    mitarbeiterId: Scalars['Int'];
    typSnippetId: Scalars['Int'];
};

export type AbwesenheitItem = {
    __typename?: 'AbwesenheitItem';
    error?: Maybe<Error>;
    item?: Maybe<Abwesenheit>;
};

export type AbwesenheitOrderByInput = {
    datumFrom?: InputMaybe<SortOrder>;
    datumUntil?: InputMaybe<SortOrder>;
    mitarbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
};

export type Angebot = {
    __typename?: 'Angebot';
    angebotspositionen?: Maybe<Array<Maybe<Angebotsposition>>>;
    datum?: Maybe<Scalars['AWSDate']>;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    dokument?: Maybe<Array<Maybe<Dokument>>>;
    freitext: Richtext;
    freitextFooter: Richtext;
    gesamtpreisNetto: Scalars['Float'];
    id: Scalars['Int'];
    mitarbeiterIdProjektleitung?: Maybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: Maybe<Scalars['Int']>;
    mwstSatz: Scalars['Float'];
    nummer: Scalars['String'];
    projektleitung?: Maybe<Mitarbeiter>;
    rabatt: Scalars['String'];
    sachbearbeiter?: Maybe<Mitarbeiter>;
    statusSnippet?: Maybe<Snippet>;
    statusSnippetId: Scalars['Int'];
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
};

export type AngebotAutoCompleteFields = {
    freitext?: InputMaybe<Scalars['String']>;
    freitextFooter?: InputMaybe<Scalars['String']>;
    nummer?: InputMaybe<Scalars['String']>;
    rabatt?: InputMaybe<Scalars['String']>;
};

export type AngebotCollection = {
    __typename?: 'AngebotCollection';
    error?: Maybe<Error>;
    items: Array<Angebot>;
    pageInfo: PageInfo;
};

export type AngebotFilterInput = {
    angebotspositionen?: InputMaybe<AngebotspositionFilterInput>;
    datumExists?: InputMaybe<Scalars['Boolean']>;
    datumFrom?: InputMaybe<Scalars['AWSDate']>;
    datumTo?: InputMaybe<Scalars['AWSDate']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    dokument?: InputMaybe<DokumentFilterInput>;
    freitextExists?: InputMaybe<Scalars['Boolean']>;
    freitextFooterExists?: InputMaybe<Scalars['Boolean']>;
    freitextFooterPrefix?: InputMaybe<Scalars['String']>;
    freitextPrefix?: InputMaybe<Scalars['String']>;
    gesamtpreisNettoFrom?: InputMaybe<Scalars['Float']>;
    gesamtpreisNettoTo?: InputMaybe<Scalars['Float']>;
    mitarbeiterIdProjektleitung?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdProjektleitungExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiterExists?: InputMaybe<Scalars['Boolean']>;
    mwstSatzFrom?: InputMaybe<Scalars['Float']>;
    mwstSatzTo?: InputMaybe<Scalars['Float']>;
    nummerExists?: InputMaybe<Scalars['Boolean']>;
    nummerPrefix?: InputMaybe<Scalars['String']>;
    projektleitung?: InputMaybe<MitarbeiterFilterInput>;
    rabattExists?: InputMaybe<Scalars['Boolean']>;
    rabattPrefix?: InputMaybe<Scalars['String']>;
    sachbearbeiter?: InputMaybe<MitarbeiterFilterInput>;
    statusSnippet?: InputMaybe<SnippetFilterInput>;
    statusSnippetId?: InputMaybe<Scalars['Int']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
};

export type AngebotInput = {
    datum?: InputMaybe<Scalars['AWSDate']>;
    freitext: Scalars['AWSJSON'];
    freitextFooter: Scalars['AWSJSON'];
    gesamtpreisNetto: Scalars['Float'];
    mitarbeiterIdProjektleitung?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mwstSatz: Scalars['Float'];
    nummer: Scalars['String'];
    rabatt: Scalars['String'];
    statusSnippetId: Scalars['Int'];
    subprojektId: Scalars['Int'];
};

export type AngebotItem = {
    __typename?: 'AngebotItem';
    error?: Maybe<Error>;
    item?: Maybe<Angebot>;
};

export type AngebotOrderByInput = {
    angebotspositionen?: InputMaybe<AngebotspositionOrderByInput>;
    datum?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    dokument?: InputMaybe<DokumentOrderByInput>;
    freitext?: InputMaybe<SortOrder>;
    freitextFooter?: InputMaybe<SortOrder>;
    gesamtpreisNetto?: InputMaybe<SortOrder>;
    mwstSatz?: InputMaybe<SortOrder>;
    nummer?: InputMaybe<SortOrder>;
    projektleitung?: InputMaybe<MitarbeiterOrderByInput>;
    rabatt?: InputMaybe<SortOrder>;
    sachbearbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    statusSnippet?: InputMaybe<SnippetOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
};

export type Angebotsposition = {
    __typename?: 'Angebotsposition';
    angebot?: Maybe<Angebot>;
    angebotId: Scalars['Int'];
    beschreibung: Scalars['String'];
    einheit: Scalars['String'];
    einzelpreis: Scalars['Float'];
    id: Scalars['Int'];
    lfdNr: Scalars['String'];
    menge: Scalars['Float'];
    rahmenvertragsposition?: Maybe<Rahmenvertragsposition>;
    rahmenvertragspositionId?: Maybe<Scalars['Int']>;
    subprojektWohneinheit?: Maybe<SubprojektWohneinheit>;
    subprojektWohneinheitId?: Maybe<Scalars['Int']>;
};

export type AngebotspositionAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
    einheit?: InputMaybe<Scalars['String']>;
    lfdNr?: InputMaybe<Scalars['String']>;
};

export type AngebotspositionCollection = {
    __typename?: 'AngebotspositionCollection';
    error?: Maybe<Error>;
    items: Array<Angebotsposition>;
    pageInfo: PageInfo;
};

export type AngebotspositionFilterInput = {
    angebot?: InputMaybe<AngebotFilterInput>;
    angebotId?: InputMaybe<Scalars['Int']>;
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    einheitExists?: InputMaybe<Scalars['Boolean']>;
    einheitPrefix?: InputMaybe<Scalars['String']>;
    einzelpreisFrom?: InputMaybe<Scalars['Float']>;
    einzelpreisTo?: InputMaybe<Scalars['Float']>;
    lfdNrExists?: InputMaybe<Scalars['Boolean']>;
    lfdNrPrefix?: InputMaybe<Scalars['String']>;
    mengeFrom?: InputMaybe<Scalars['Float']>;
    mengeTo?: InputMaybe<Scalars['Float']>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionFilterInput>;
    rahmenvertragspositionId?: InputMaybe<Scalars['Int']>;
    rahmenvertragspositionIdExists?: InputMaybe<Scalars['Boolean']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheitIdExists?: InputMaybe<Scalars['Boolean']>;
};

export type AngebotspositionInput = {
    angebotId: Scalars['Int'];
    beschreibung: Scalars['String'];
    einheit: Scalars['String'];
    einzelpreis: Scalars['Float'];
    lfdNr: Scalars['String'];
    menge: Scalars['Float'];
    rahmenvertragspositionId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
};

export type AngebotspositionItem = {
    __typename?: 'AngebotspositionItem';
    error?: Maybe<Error>;
    item?: Maybe<Angebotsposition>;
};

export type AngebotspositionOrderByInput = {
    angebot?: InputMaybe<AngebotOrderByInput>;
    beschreibung?: InputMaybe<SortOrder>;
    einheit?: InputMaybe<SortOrder>;
    einzelpreis?: InputMaybe<SortOrder>;
    lfdNr?: InputMaybe<SortOrder>;
    menge?: InputMaybe<SortOrder>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionOrderByInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
};

export type AppMutation = {
    __typename?: 'AppMutation';
    createEinsatzberichtWithEinsatzberichtDetails: EinsatzberichtItem;
    createGewerkWithProjekttypen: GewerkItem;
    createSubprojektToProjekt: SubprojektItem;
    createSubprojektWithProjekt: SubprojektItem;
    createWohneinheitWithSubprojektWohneinheit: WohneinheitItem;
    updateEinsatzberichtWithEinsatzberichtDetails: EinsatzberichtItem;
    updateGewerkWithProjekttypen: GewerkItem;
    updateSubprojektBemerkungAufmass: SubprojektItem;
    updateSubprojektWithProjekt: SubprojektItem;
    updateUmsatzPrognose: SubprojektItem;
    upsertEinsatzbericht: UpsertEinsatzberichtReturn;
    upsertGewerk: UpsertGewerkReturn;
    wattroAssignNotdienstprojektToSubprojektWohneinheit: CommandResult;
    wattroCreateProjectForSubprojektWohneinheit: CommandResult;
    wattroUpdateDeploymentsToProjekt: CommandResult;
};

export type AppMutationCreateEinsatzberichtWithEinsatzberichtDetailsArgs = {
    einsatzbericht: EinsatzberichtInput;
    einsatzberichtDetails: Array<EinsatzberichtDetailWithoutEinsatzberichtInput>;
    sachbearbeiterIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type AppMutationCreateGewerkWithProjekttypenArgs = {
    input: CreateGewerkWithProjekttypen;
};

export type AppMutationCreateSubprojektToProjektArgs = {
    input: CreateSubprojektToProjektInput;
};

export type AppMutationCreateSubprojektWithProjektArgs = {
    input: CreateSubprojektWithProjektInput;
};

export type AppMutationCreateWohneinheitWithSubprojektWohneinheitArgs = {
    bezeichnung: Scalars['String'];
    subprojektId: Scalars['Int'];
};

export type AppMutationUpdateEinsatzberichtWithEinsatzberichtDetailsArgs = {
    einsatzbericht: EinsatzberichtInput;
    einsatzberichtDetails: Array<EinsatzberichtDetailWithoutEinsatzberichtInput>;
    id: Scalars['Int'];
    sachbearbeiterIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type AppMutationUpdateGewerkWithProjekttypenArgs = {
    id: Scalars['Int'];
    input: UpdateGewerkWithProjekttypen;
};

export type AppMutationUpdateSubprojektBemerkungAufmassArgs = {
    bemerkungAufmass: Scalars['String'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    subprojektId: Scalars['Int'];
    version: Scalars['Int'];
};

export type AppMutationUpdateSubprojektWithProjektArgs = {
    input: UpdateSubprojektWithProjektInput;
};

export type AppMutationUpdateUmsatzPrognoseArgs = {
    subprojektId: Scalars['Int'];
};

export type AppMutationUpsertEinsatzberichtArgs = {
    einsatzbericht?: InputMaybe<EinsatzberichtInput>;
    einsatzberichtDetails?: InputMaybe<Array<UpsertEinsatzberichtDetailInput>>;
    id?: InputMaybe<Scalars['Int']>;
    sachbearbeiterIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type AppMutationUpsertGewerkArgs = {
    gewerk?: InputMaybe<GewerkInput>;
    gewerkProjekttypen?: InputMaybe<Array<Scalars['Int']>>;
    id?: InputMaybe<Scalars['Int']>;
};

export type AppMutationWattroAssignNotdienstprojektToSubprojektWohneinheitArgs = {
    notdienstprojektId: Scalars['Int'];
    subprojektWohneinheitId: Scalars['Int'];
};

export type AppMutationWattroCreateProjectForSubprojektWohneinheitArgs = {
    subprojektWohneinheitId: Scalars['Int'];
};

export type AppMutationWattroUpdateDeploymentsToProjektArgs = {
    projektId: Scalars['Int'];
};

export type AppQuery = {
    __typename?: 'AppQuery';
    kontaktCsvExport: CsvItem;
    kontaktSearch: KontaktCollection;
    korrespondenzenUndTermine: KorrespondenzUndTerminCollection;
    offeneAufgaben: AufgabeCollection;
    offenePosten: OffenePostenCollection;
    offenePostenCsvExport: CsvItem;
    offenePostenMitarbeiter: MitarbeiterCollection;
    projektByLfdNr: ProjektItem;
    subprojectCsvExport: CsvItem;
    subprojektSearch: SubprojektCollection;
};

export type AppQueryKontaktCsvExportArgs = {
    searchTerm: Scalars['String'];
};

export type AppQueryKontaktSearchArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<KontaktOrderByInput>>>;
    searchTerm: Scalars['String'];
};

export type AppQueryKorrespondenzenUndTermineArgs = {
    projektId?: InputMaybe<Scalars['Int']>;
    subprojektId?: InputMaybe<Scalars['Int']>;
};

export type AppQueryOffeneAufgabenArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    mitarbeiterId: Scalars['Int'];
    niederlassungId?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AufgabeOrderByInput>>>;
};

export type AppQueryOffenePostenArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
    niederlassungId: Scalars['Int'];
    offset?: InputMaybe<Scalars['Int']>;
    only28DaysAndOlder?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<Array<InputMaybe<OffenePostenOrderByInput>>>;
};

export type AppQueryOffenePostenCsvExportArgs = {
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
    niederlassungId: Scalars['Int'];
    only28DaysAndOlder?: InputMaybe<Scalars['Boolean']>;
};

export type AppQueryOffenePostenMitarbeiterArgs = {
    niederlassungId: Scalars['Int'];
};

export type AppQueryProjektByLfdNrArgs = {
    lfdNr: Scalars['Int'];
};

export type AppQuerySubprojectCsvExportArgs = {
    orderBy?: InputMaybe<Array<InputMaybe<SubprojektOrderByInput>>>;
    searchInput: SubprojektSearch;
};

export type AppQuerySubprojektSearchArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<SubprojektOrderByInput>>>;
    searchInput: SubprojektSearch;
};

export type Aufgabe = {
    __typename?: 'Aufgabe';
    abschlusszustaendiger?: Maybe<Mitarbeiter>;
    angelegt?: Maybe<Scalars['AWSDateTime']>;
    beschreibung: Richtext;
    erledigt?: Maybe<Scalars['AWSDate']>;
    funktion?: Maybe<Funktion>;
    funktionId?: Maybe<Scalars['Int']>;
    id: Scalars['Int'];
    mitarbeiterIdAbschlusszustaendiger?: Maybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: Maybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: Maybe<Scalars['Int']>;
    rolle?: Maybe<Rolle>;
    rolleId?: Maybe<Scalars['Int']>;
    sachbearbeiter?: Maybe<Mitarbeiter>;
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
    typSnippet?: Maybe<Snippet>;
    typSnippetId?: Maybe<Scalars['Int']>;
    urheber?: Maybe<Mitarbeiter>;
    version: Scalars['Int'];
    zuErledigen?: Maybe<Scalars['AWSDate']>;
};

export type AufgabeAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
};

export type AufgabeCollection = {
    __typename?: 'AufgabeCollection';
    error?: Maybe<Error>;
    items: Array<Aufgabe>;
    pageInfo: PageInfo;
};

export type AufgabeCreateInput = {
    beschreibung: Scalars['AWSJSON'];
    erledigt?: InputMaybe<Scalars['AWSDate']>;
    funktionId?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdAbschlusszustaendiger?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    rolleId?: InputMaybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    typSnippetId?: InputMaybe<Scalars['Int']>;
    zuErledigen?: InputMaybe<Scalars['AWSDate']>;
};

export type AufgabeFilterInput = {
    abschlusszustaendiger?: InputMaybe<MitarbeiterFilterInput>;
    angelegtExists?: InputMaybe<Scalars['Boolean']>;
    angelegtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    angelegtTo?: InputMaybe<Scalars['AWSDateTime']>;
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    erledigtExists?: InputMaybe<Scalars['Boolean']>;
    erledigtFrom?: InputMaybe<Scalars['AWSDate']>;
    erledigtTo?: InputMaybe<Scalars['AWSDate']>;
    funktion?: InputMaybe<FunktionFilterInput>;
    funktionId?: InputMaybe<Scalars['Int']>;
    funktionIdExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdAbschlusszustaendiger?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdAbschlusszustaendigerExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiterExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheberExists?: InputMaybe<Scalars['Boolean']>;
    rolle?: InputMaybe<RolleFilterInput>;
    rolleId?: InputMaybe<Scalars['Int']>;
    rolleIdExists?: InputMaybe<Scalars['Boolean']>;
    sachbearbeiter?: InputMaybe<MitarbeiterFilterInput>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
    typSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    urheber?: InputMaybe<MitarbeiterFilterInput>;
    zuErledigenExists?: InputMaybe<Scalars['Boolean']>;
    zuErledigenFrom?: InputMaybe<Scalars['AWSDate']>;
    zuErledigenNotInFuture?: InputMaybe<Scalars['Boolean']>;
    zuErledigenTo?: InputMaybe<Scalars['AWSDate']>;
};

export type AufgabeFunktionInput = {
    aufgabeId: Scalars['Int'];
    funktionId: Scalars['Int'];
};

export type AufgabeItem = {
    __typename?: 'AufgabeItem';
    error?: Maybe<Error>;
    item?: Maybe<Aufgabe>;
};

export type AufgabeOrderByInput = {
    abschlusszustaendiger?: InputMaybe<MitarbeiterOrderByInput>;
    angelegt?: InputMaybe<SortOrder>;
    beschreibung?: InputMaybe<SortOrder>;
    erledigt?: InputMaybe<SortOrder>;
    funktion?: InputMaybe<FunktionOrderByInput>;
    rolle?: InputMaybe<RolleOrderByInput>;
    sachbearbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
    urheber?: InputMaybe<MitarbeiterOrderByInput>;
    zuErledigen?: InputMaybe<SortOrder>;
};

export type AufgabeUpdateInput = {
    beschreibung: Scalars['AWSJSON'];
    erledigt?: InputMaybe<Scalars['AWSDate']>;
    funktionId?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdAbschlusszustaendiger?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    rolleId?: InputMaybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    typSnippetId?: InputMaybe<Scalars['Int']>;
    zuErledigen?: InputMaybe<Scalars['AWSDate']>;
};

export type Aufmass = {
    __typename?: 'Aufmass';
    bezeichnung: Scalars['String'];
    breite?: Maybe<Scalars['Float']>;
    hoehe?: Maybe<Scalars['Float']>;
    id: Scalars['Int'];
    laenge?: Maybe<Scalars['Float']>;
    subprojektWohneinheit?: Maybe<SubprojektWohneinheit>;
    subprojektWohneinheitId: Scalars['Int'];
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
};

export type AufmassAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type AufmassCollection = {
    __typename?: 'AufmassCollection';
    error?: Maybe<Error>;
    items: Array<Aufmass>;
    pageInfo: PageInfo;
};

export type AufmassFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    breiteExists?: InputMaybe<Scalars['Boolean']>;
    breiteFrom?: InputMaybe<Scalars['Float']>;
    breiteTo?: InputMaybe<Scalars['Float']>;
    hoeheExists?: InputMaybe<Scalars['Boolean']>;
    hoeheFrom?: InputMaybe<Scalars['Float']>;
    hoeheTo?: InputMaybe<Scalars['Float']>;
    laengeExists?: InputMaybe<Scalars['Boolean']>;
    laengeFrom?: InputMaybe<Scalars['Float']>;
    laengeTo?: InputMaybe<Scalars['Float']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
};

export type AufmassInput = {
    bezeichnung: Scalars['String'];
    breite?: InputMaybe<Scalars['Float']>;
    hoehe?: InputMaybe<Scalars['Float']>;
    laenge?: InputMaybe<Scalars['Float']>;
    subprojektWohneinheitId: Scalars['Int'];
    typSnippetId: Scalars['Int'];
};

export type AufmassItem = {
    __typename?: 'AufmassItem';
    error?: Maybe<Error>;
    item?: Maybe<Aufmass>;
};

export type AufmassOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    breite?: InputMaybe<SortOrder>;
    hoehe?: InputMaybe<SortOrder>;
    laenge?: InputMaybe<SortOrder>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
};

export type AutocompleteInput = {
    angebot?: InputMaybe<AngebotAutoCompleteFields>;
    angebotsposition?: InputMaybe<AngebotspositionAutoCompleteFields>;
    aufgabe?: InputMaybe<AufgabeAutoCompleteFields>;
    aufmass?: InputMaybe<AufmassAutoCompleteFields>;
    dokument?: InputMaybe<DokumentAutoCompleteFields>;
    einsatzberichtDetail?: InputMaybe<EinsatzberichtDetailAutoCompleteFields>;
    einsatzberichtModul?: InputMaybe<EinsatzberichtModulAutoCompleteFields>;
    einsatzberichtOption?: InputMaybe<EinsatzberichtOptionAutoCompleteFields>;
    einsatzberichtTyp?: InputMaybe<EinsatzberichtTypAutoCompleteFields>;
    funktion?: InputMaybe<FunktionAutoCompleteFields>;
    geraet?: InputMaybe<GeraetAutoCompleteFields>;
    geraetetyp?: InputMaybe<GeraetetypAutoCompleteFields>;
    gewerk?: InputMaybe<GewerkAutoCompleteFields>;
    kalkulationsposition?: InputMaybe<KalkulationspositionAutoCompleteFields>;
    kontakt?: InputMaybe<KontaktAutoCompleteFields>;
    korrespondenz?: InputMaybe<KorrespondenzAutoCompleteFields>;
    leistung?: InputMaybe<LeistungAutoCompleteFields>;
    mitarbeiter?: InputMaybe<MitarbeiterAutoCompleteFields>;
    niederlassung?: InputMaybe<NiederlassungAutoCompleteFields>;
    notdienstprojekt?: InputMaybe<NotdienstprojektAutoCompleteFields>;
    postleitzahl?: InputMaybe<PostleitzahlAutoCompleteFields>;
    projekt?: InputMaybe<ProjektAutoCompleteFields>;
    projekttyp?: InputMaybe<ProjekttypAutoCompleteFields>;
    rahmenvertrag?: InputMaybe<RahmenvertragAutoCompleteFields>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionAutoCompleteFields>;
    rechnung?: InputMaybe<RechnungAutoCompleteFields>;
    rechnungsposition?: InputMaybe<RechnungspositionAutoCompleteFields>;
    rolle?: InputMaybe<RolleAutoCompleteFields>;
    snippet?: InputMaybe<SnippetAutoCompleteFields>;
    stromzaehler?: InputMaybe<StromzaehlerAutoCompleteFields>;
    subprojekt?: InputMaybe<SubprojektAutoCompleteFields>;
    termin?: InputMaybe<TerminAutoCompleteFields>;
    textbaustein?: InputMaybe<TextbausteinAutoCompleteFields>;
    versicherung?: InputMaybe<VersicherungAutoCompleteFields>;
    wohneinheit?: InputMaybe<WohneinheitAutoCompleteFields>;
};

export type BeteiligteVersion = {
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type Beteiligter = {
    __typename?: 'Beteiligter';
    id: Scalars['Int'];
    kontakt?: Maybe<Kontakt>;
    kontaktId: Scalars['Int'];
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
    subprojektWohneinheit?: Maybe<SubprojektWohneinheit>;
    subprojektWohneinheitId?: Maybe<Scalars['Int']>;
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
    version: Scalars['Int'];
};

export type BeteiligterCollection = {
    __typename?: 'BeteiligterCollection';
    error?: Maybe<Error>;
    items: Array<Beteiligter>;
    pageInfo: PageInfo;
};

export type BeteiligterFilterInput = {
    kontakt?: InputMaybe<KontaktFilterInput>;
    kontaktId?: InputMaybe<Scalars['Int']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheitIdExists?: InputMaybe<Scalars['Boolean']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
};

export type BeteiligterInput = {
    kontaktId: Scalars['Int'];
    subprojektId: Scalars['Int'];
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    typSnippetId: Scalars['Int'];
};

export type BeteiligterItem = {
    __typename?: 'BeteiligterItem';
    error?: Maybe<Error>;
    item?: Maybe<Beteiligter>;
};

export type BeteiligterOrderByInput = {
    kontakt?: InputMaybe<KontaktOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
};

export type CommandResult = {
    __typename?: 'CommandResult';
    error?: Maybe<Error>;
    result?: Maybe<Scalars['Boolean']>;
};

export type CreateGewerkWithProjekttypen = {
    name: Scalars['String'];
    notdienstFaehig?: InputMaybe<Scalars['Boolean']>;
    projekttypIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    publicId: Scalars['Int'];
};

export type CreateSubprojektToProjektInput = {
    beteiligte?: InputMaybe<Array<CreateSubprojektToProjektInputBeteiligter>>;
    beteiligterAuftraggeberId: Scalars['Int'];
    beteiligterEigentuemerId?: InputMaybe<Scalars['Int']>;
    beteiligterHandwerkerId?: InputMaybe<Scalars['Int']>;
    beteiligterVermittlerId?: InputMaybe<Scalars['Int']>;
    beteiligterVersicherungsnehmerId?: InputMaybe<Scalars['Int']>;
    projektId: Scalars['Int'];
    subprojektAbrechnungsDatum?: InputMaybe<Scalars['AWSDate']>;
    subprojektBemerkung?: InputMaybe<Scalars['AWSJSON']>;
    subprojektBonitaetSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektBuergschaft?: InputMaybe<Scalars['Float']>;
    subprojektGewerkIds: Array<Scalars['Int']>;
    subprojektIhrZeichen?: InputMaybe<Scalars['String']>;
    subprojektIntern?: InputMaybe<Scalars['Boolean']>;
    subprojektNoteinsatz?: InputMaybe<Scalars['Boolean']>;
    subprojektProjekttypId: Scalars['Int'];
    subprojektRahmenvertragId?: InputMaybe<Scalars['Int']>;
    subprojektSchadenNr?: InputMaybe<Scalars['String']>;
    subprojektVersicherungId?: InputMaybe<Scalars['Int']>;
    subprojektVersicherungsArtSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektVertragsNr?: InputMaybe<Scalars['String']>;
    subprojektWeg?: InputMaybe<Scalars['String']>;
    subprojektWohneinheit?: InputMaybe<Array<CreateSubprojektToProjektInputSubprojektWohneinheit>>;
};

export type CreateSubprojektToProjektInputBeteiligter = {
    kontaktId: Scalars['Int'];
    typId: Scalars['Int'];
};

export type CreateSubprojektToProjektInputSubprojektWohneinheit = {
    mieterId?: InputMaybe<Scalars['Int']>;
    wohneinheitId: Scalars['Int'];
};

export type CreateSubprojektWithProjektInput = {
    beteiligterAuftraggeberId: Scalars['Int'];
    beteiligterEigentuemerId?: InputMaybe<Scalars['Int']>;
    beteiligterHandwerkerId?: InputMaybe<Scalars['Int']>;
    beteiligterVermittlerId?: InputMaybe<Scalars['Int']>;
    beteiligterVersicherungsnehmerId?: InputMaybe<Scalars['Int']>;
    objektOrt: Scalars['String'];
    objektPlz: Scalars['String'];
    objektStrasse: Scalars['String'];
    projektNiederlassungId: Scalars['Int'];
    projektSchadenTag?: InputMaybe<Scalars['AWSDate']>;
    subprojektBemerkung?: InputMaybe<Scalars['AWSJSON']>;
    subprojektBonitaetSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektBuergschaft?: InputMaybe<Scalars['Float']>;
    subprojektGewerkIds: Array<Scalars['Int']>;
    subprojektIhrZeichen?: InputMaybe<Scalars['String']>;
    subprojektIntern?: InputMaybe<Scalars['Boolean']>;
    subprojektNoteinsatz?: InputMaybe<Scalars['Boolean']>;
    subprojektProjekttypId: Scalars['Int'];
    subprojektRahmenvertragId?: InputMaybe<Scalars['Int']>;
    subprojektSchadenNr?: InputMaybe<Scalars['String']>;
    subprojektVersicherungId?: InputMaybe<Scalars['Int']>;
    subprojektVersicherungsArtSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektVertragsNr?: InputMaybe<Scalars['String']>;
    subprojektWeg?: InputMaybe<Scalars['String']>;
};

export type CsvItem = {
    __typename?: 'CsvItem';
    error?: Maybe<Error>;
    item?: Maybe<Scalars['String']>;
};

export type Dokument = {
    __typename?: 'Dokument';
    angebot?: Maybe<Angebot>;
    angebotId?: Maybe<Scalars['Int']>;
    beschreibung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzbericht?: Maybe<Einsatzbericht>;
    einsatzberichtId?: Maybe<Scalars['Int']>;
    geraetetyp?: Maybe<Array<Maybe<Geraetetyp>>>;
    id: Scalars['Int'];
    korrespondenz?: Maybe<Array<Maybe<Korrespondenz>>>;
    name: Scalars['String'];
    pfad: Scalars['String'];
    rechnung?: Maybe<Rechnung>;
    rechnungId?: Maybe<Scalars['Int']>;
    subprojekt?: Maybe<Subprojekt>;
    subprojektId?: Maybe<Scalars['Int']>;
    typSnippet?: Maybe<Snippet>;
    typSnippetId?: Maybe<Scalars['Int']>;
};

export type DokumentAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    pfad?: InputMaybe<Scalars['String']>;
};

export type DokumentCollection = {
    __typename?: 'DokumentCollection';
    error?: Maybe<Error>;
    items: Array<Dokument>;
    pageInfo: PageInfo;
};

export type DokumentFilterInput = {
    angebot?: InputMaybe<AngebotFilterInput>;
    angebotId?: InputMaybe<Scalars['Int']>;
    angebotIdExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    einsatzberichtId?: InputMaybe<Scalars['Int']>;
    einsatzberichtIdExists?: InputMaybe<Scalars['Boolean']>;
    geraetetyp?: InputMaybe<GeraetetypFilterInput>;
    korrespondenz?: InputMaybe<KorrespondenzFilterInput>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    pfadExists?: InputMaybe<Scalars['Boolean']>;
    pfadPrefix?: InputMaybe<Scalars['String']>;
    rechnung?: InputMaybe<RechnungFilterInput>;
    rechnungId?: InputMaybe<Scalars['Int']>;
    rechnungIdExists?: InputMaybe<Scalars['Boolean']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    subprojektIdExists?: InputMaybe<Scalars['Boolean']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
    typSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
};

export type DokumentInput = {
    angebotId?: InputMaybe<Scalars['Int']>;
    beschreibung: Scalars['String'];
    einsatzberichtId?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    pfad: Scalars['String'];
    rechnungId?: InputMaybe<Scalars['Int']>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
};

export type DokumentItem = {
    __typename?: 'DokumentItem';
    error?: Maybe<Error>;
    item?: Maybe<Dokument>;
};

export type DokumentOrderByInput = {
    angebot?: InputMaybe<AngebotOrderByInput>;
    beschreibung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    geraetetyp?: InputMaybe<GeraetetypOrderByInput>;
    korrespondenz?: InputMaybe<KorrespondenzOrderByInput>;
    name?: InputMaybe<SortOrder>;
    pfad?: InputMaybe<SortOrder>;
    rechnung?: InputMaybe<RechnungOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
};

export type Einsatzbericht = {
    __typename?: 'Einsatzbericht';
    abfahrtFrom?: Maybe<Scalars['AWSDateTime']>;
    abfahrtUntil?: Maybe<Scalars['AWSDateTime']>;
    anfahrtFrom?: Maybe<Scalars['AWSDateTime']>;
    anfahrtUntil?: Maybe<Scalars['AWSDateTime']>;
    datum?: Maybe<Scalars['AWSDate']>;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    dokument?: Maybe<Array<Maybe<Dokument>>>;
    einsatzFrom?: Maybe<Scalars['AWSDateTime']>;
    einsatzUntil?: Maybe<Scalars['AWSDateTime']>;
    einsatzberichtDetail?: Maybe<Array<Maybe<EinsatzberichtDetail>>>;
    einsatzberichtGewerk?: Maybe<Array<Maybe<EinsatzberichtGewerk>>>;
    einsatzberichtSachbearbeiter?: Maybe<Array<Maybe<EinsatzberichtSachbearbeiter>>>;
    einsatzberichtTyp?: Maybe<EinsatzberichtTyp>;
    einsatzberichtTypId: Scalars['Int'];
    id: Scalars['Int'];
    kontakt?: Maybe<Kontakt>;
    kontaktId?: Maybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: Maybe<Scalars['Int']>;
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
    urheber?: Maybe<Mitarbeiter>;
};

export type EinsatzberichtCollection = {
    __typename?: 'EinsatzberichtCollection';
    error?: Maybe<Error>;
    items: Array<Einsatzbericht>;
    pageInfo: PageInfo;
};

export type EinsatzberichtDetail = {
    __typename?: 'EinsatzberichtDetail';
    anzahl?: Maybe<Scalars['Int']>;
    einsatzbericht?: Maybe<Einsatzbericht>;
    einsatzberichtId: Scalars['Int'];
    einsatzberichtOption?: Maybe<EinsatzberichtOption>;
    einsatzberichtOptionId: Scalars['Int'];
    id: Scalars['Int'];
    jaNein?: Maybe<Scalars['Boolean']>;
    text?: Maybe<Scalars['String']>;
};

export type EinsatzberichtDetailAutoCompleteFields = {
    text?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtDetailCollection = {
    __typename?: 'EinsatzberichtDetailCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtDetail>;
    pageInfo: PageInfo;
};

export type EinsatzberichtDetailFilterInput = {
    anzahlExists?: InputMaybe<Scalars['Boolean']>;
    anzahlFrom?: InputMaybe<Scalars['Float']>;
    anzahlTo?: InputMaybe<Scalars['Float']>;
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    einsatzberichtId?: InputMaybe<Scalars['Int']>;
    einsatzberichtOption?: InputMaybe<EinsatzberichtOptionFilterInput>;
    einsatzberichtOptionId?: InputMaybe<Scalars['Int']>;
    jaNein?: InputMaybe<Scalars['Boolean']>;
    jaNeinExists?: InputMaybe<Scalars['Boolean']>;
    textExists?: InputMaybe<Scalars['Boolean']>;
    textPrefix?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtDetailInput = {
    anzahl?: InputMaybe<Scalars['Int']>;
    einsatzberichtId: Scalars['Int'];
    einsatzberichtOptionId: Scalars['Int'];
    jaNein?: InputMaybe<Scalars['Boolean']>;
    text?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtDetailItem = {
    __typename?: 'EinsatzberichtDetailItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtDetail>;
};

export type EinsatzberichtDetailOrderByInput = {
    anzahl?: InputMaybe<SortOrder>;
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    einsatzberichtOption?: InputMaybe<EinsatzberichtOptionOrderByInput>;
    jaNein?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
};

export type EinsatzberichtDetailWithoutEinsatzberichtInput = {
    anzahl?: InputMaybe<Scalars['Int']>;
    einsatzberichtOptionId: Scalars['Int'];
    jaNein?: InputMaybe<Scalars['Boolean']>;
    text?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtFilterInput = {
    abfahrtFromExists?: InputMaybe<Scalars['Boolean']>;
    abfahrtFromFrom?: InputMaybe<Scalars['AWSDateTime']>;
    abfahrtFromTo?: InputMaybe<Scalars['AWSDateTime']>;
    abfahrtUntilExists?: InputMaybe<Scalars['Boolean']>;
    abfahrtUntilFrom?: InputMaybe<Scalars['AWSDateTime']>;
    abfahrtUntilTo?: InputMaybe<Scalars['AWSDateTime']>;
    anfahrtFromExists?: InputMaybe<Scalars['Boolean']>;
    anfahrtFromFrom?: InputMaybe<Scalars['AWSDateTime']>;
    anfahrtFromTo?: InputMaybe<Scalars['AWSDateTime']>;
    anfahrtUntilExists?: InputMaybe<Scalars['Boolean']>;
    anfahrtUntilFrom?: InputMaybe<Scalars['AWSDateTime']>;
    anfahrtUntilTo?: InputMaybe<Scalars['AWSDateTime']>;
    datumExists?: InputMaybe<Scalars['Boolean']>;
    datumFrom?: InputMaybe<Scalars['AWSDate']>;
    datumTo?: InputMaybe<Scalars['AWSDate']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    dokument?: InputMaybe<DokumentFilterInput>;
    einsatzFromExists?: InputMaybe<Scalars['Boolean']>;
    einsatzFromFrom?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzFromTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzUntilExists?: InputMaybe<Scalars['Boolean']>;
    einsatzUntilFrom?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzUntilTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzberichtDetail?: InputMaybe<EinsatzberichtDetailFilterInput>;
    einsatzberichtGewerk?: InputMaybe<EinsatzberichtGewerkFilterInput>;
    einsatzberichtSachbearbeiter?: InputMaybe<EinsatzberichtSachbearbeiterFilterInput>;
    einsatzberichtTyp?: InputMaybe<EinsatzberichtTypFilterInput>;
    einsatzberichtTypId?: InputMaybe<Scalars['Int']>;
    kontakt?: InputMaybe<KontaktFilterInput>;
    kontaktId?: InputMaybe<Scalars['Int']>;
    kontaktIdExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheberExists?: InputMaybe<Scalars['Boolean']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    urheber?: InputMaybe<MitarbeiterFilterInput>;
};

export type EinsatzberichtGewerk = {
    __typename?: 'EinsatzberichtGewerk';
    einsatzbericht?: Maybe<Einsatzbericht>;
    einsatzberichtId: Scalars['Int'];
    gewerk?: Maybe<Gewerk>;
    gewerkId: Scalars['Int'];
    id: Scalars['Int'];
};

export type EinsatzberichtGewerkCollection = {
    __typename?: 'EinsatzberichtGewerkCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtGewerk>;
    pageInfo: PageInfo;
};

export type EinsatzberichtGewerkFilterInput = {
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    einsatzberichtId?: InputMaybe<Scalars['Int']>;
    gewerk?: InputMaybe<GewerkFilterInput>;
    gewerkId?: InputMaybe<Scalars['Int']>;
};

export type EinsatzberichtGewerkInput = {
    einsatzberichtId: Scalars['Int'];
    gewerkId: Scalars['Int'];
};

export type EinsatzberichtGewerkItem = {
    __typename?: 'EinsatzberichtGewerkItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtGewerk>;
};

export type EinsatzberichtGewerkOrderByInput = {
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    gewerk?: InputMaybe<GewerkOrderByInput>;
};

export type EinsatzberichtInput = {
    abfahrtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    abfahrtUntil?: InputMaybe<Scalars['AWSDateTime']>;
    anfahrtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    anfahrtUntil?: InputMaybe<Scalars['AWSDateTime']>;
    datum?: InputMaybe<Scalars['AWSDate']>;
    einsatzFrom?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzUntil?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzberichtTypId: Scalars['Int'];
    kontaktId?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
};

export type EinsatzberichtItem = {
    __typename?: 'EinsatzberichtItem';
    error?: Maybe<Error>;
    item?: Maybe<Einsatzbericht>;
};

export type EinsatzberichtModul = {
    __typename?: 'EinsatzberichtModul';
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzberichtOption?: Maybe<Array<Maybe<EinsatzberichtOption>>>;
    einsatzberichtTypModul?: Maybe<Array<Maybe<EinsatzberichtTypModul>>>;
    id: Scalars['Int'];
    name: Scalars['String'];
};

export type EinsatzberichtModulAutoCompleteFields = {
    name?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtModulCollection = {
    __typename?: 'EinsatzberichtModulCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtModul>;
    pageInfo: PageInfo;
};

export type EinsatzberichtModulFilterInput = {
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzberichtOption?: InputMaybe<EinsatzberichtOptionFilterInput>;
    einsatzberichtTypModul?: InputMaybe<EinsatzberichtTypModulFilterInput>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtModulInput = {
    name: Scalars['String'];
};

export type EinsatzberichtModulItem = {
    __typename?: 'EinsatzberichtModulItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtModul>;
};

export type EinsatzberichtModulOrderByInput = {
    deleted?: InputMaybe<SortOrder>;
    einsatzberichtOption?: InputMaybe<EinsatzberichtOptionOrderByInput>;
    einsatzberichtTypModul?: InputMaybe<EinsatzberichtTypModulOrderByInput>;
    name?: InputMaybe<SortOrder>;
};

export type EinsatzberichtOption = {
    __typename?: 'EinsatzberichtOption';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzberichtDetail?: Maybe<Array<Maybe<EinsatzberichtDetail>>>;
    einsatzberichtModul?: Maybe<EinsatzberichtModul>;
    einsatzberichtModulId: Scalars['Int'];
    id: Scalars['Int'];
    typ: EinsatzberichtOptionTyp;
};

export type EinsatzberichtOptionAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtOptionCollection = {
    __typename?: 'EinsatzberichtOptionCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtOption>;
    pageInfo: PageInfo;
};

export type EinsatzberichtOptionFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzberichtDetail?: InputMaybe<EinsatzberichtDetailFilterInput>;
    einsatzberichtModul?: InputMaybe<EinsatzberichtModulFilterInput>;
    einsatzberichtModulId?: InputMaybe<Scalars['Int']>;
    typ?: InputMaybe<EinsatzberichtOptionTyp>;
};

export type EinsatzberichtOptionInput = {
    bezeichnung: Scalars['String'];
    einsatzberichtModulId: Scalars['Int'];
    typ: EinsatzberichtOptionTyp;
};

export type EinsatzberichtOptionItem = {
    __typename?: 'EinsatzberichtOptionItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtOption>;
};

export type EinsatzberichtOptionOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    einsatzberichtDetail?: InputMaybe<EinsatzberichtDetailOrderByInput>;
    einsatzberichtModul?: InputMaybe<EinsatzberichtModulOrderByInput>;
    typ?: InputMaybe<SortOrder>;
};

export enum EinsatzberichtOptionTyp {
    Boolean = 'BOOLEAN',
    BooleanString = 'BOOLEAN_STRING',
    Integer = 'INTEGER',
    String = 'STRING',
}

export type EinsatzberichtOrderByInput = {
    abfahrtFrom?: InputMaybe<SortOrder>;
    abfahrtUntil?: InputMaybe<SortOrder>;
    anfahrtFrom?: InputMaybe<SortOrder>;
    anfahrtUntil?: InputMaybe<SortOrder>;
    datum?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    dokument?: InputMaybe<DokumentOrderByInput>;
    einsatzFrom?: InputMaybe<SortOrder>;
    einsatzUntil?: InputMaybe<SortOrder>;
    einsatzberichtDetail?: InputMaybe<EinsatzberichtDetailOrderByInput>;
    einsatzberichtGewerk?: InputMaybe<EinsatzberichtGewerkOrderByInput>;
    einsatzberichtSachbearbeiter?: InputMaybe<EinsatzberichtSachbearbeiterOrderByInput>;
    einsatzberichtTyp?: InputMaybe<EinsatzberichtTypOrderByInput>;
    kontakt?: InputMaybe<KontaktOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    urheber?: InputMaybe<MitarbeiterOrderByInput>;
};

export type EinsatzberichtSachbearbeiter = {
    __typename?: 'EinsatzberichtSachbearbeiter';
    einsatzbericht?: Maybe<Einsatzbericht>;
    einsatzberichtId: Scalars['Int'];
    id: Scalars['Int'];
    mitarbeiter?: Maybe<Mitarbeiter>;
    mitarbeiterId: Scalars['Int'];
};

export type EinsatzberichtSachbearbeiterCollection = {
    __typename?: 'EinsatzberichtSachbearbeiterCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtSachbearbeiter>;
    pageInfo: PageInfo;
};

export type EinsatzberichtSachbearbeiterFilterInput = {
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    einsatzberichtId?: InputMaybe<Scalars['Int']>;
    mitarbeiter?: InputMaybe<MitarbeiterFilterInput>;
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
};

export type EinsatzberichtSachbearbeiterInput = {
    einsatzberichtId: Scalars['Int'];
    mitarbeiterId: Scalars['Int'];
};

export type EinsatzberichtSachbearbeiterItem = {
    __typename?: 'EinsatzberichtSachbearbeiterItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtSachbearbeiter>;
};

export type EinsatzberichtSachbearbeiterOrderByInput = {
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    mitarbeiter?: InputMaybe<MitarbeiterOrderByInput>;
};

export type EinsatzberichtTyp = {
    __typename?: 'EinsatzberichtTyp';
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzbericht?: Maybe<Array<Maybe<Einsatzbericht>>>;
    einsatzberichtTypModul?: Maybe<Array<Maybe<EinsatzberichtTypModul>>>;
    id: Scalars['Int'];
    name: Scalars['String'];
};

export type EinsatzberichtTypAutoCompleteFields = {
    name?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtTypCollection = {
    __typename?: 'EinsatzberichtTypCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtTyp>;
    pageInfo: PageInfo;
};

export type EinsatzberichtTypFilterInput = {
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    einsatzberichtTypModul?: InputMaybe<EinsatzberichtTypModulFilterInput>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
};

export type EinsatzberichtTypInput = {
    name: Scalars['String'];
};

export type EinsatzberichtTypItem = {
    __typename?: 'EinsatzberichtTypItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtTyp>;
};

export type EinsatzberichtTypModul = {
    __typename?: 'EinsatzberichtTypModul';
    einsatzberichtModul?: Maybe<EinsatzberichtModul>;
    einsatzberichtModulId: Scalars['Int'];
    einsatzberichtTyp?: Maybe<EinsatzberichtTyp>;
    einsatzberichtTypId: Scalars['Int'];
    id: Scalars['Int'];
};

export type EinsatzberichtTypModulCollection = {
    __typename?: 'EinsatzberichtTypModulCollection';
    error?: Maybe<Error>;
    items: Array<EinsatzberichtTypModul>;
    pageInfo: PageInfo;
};

export type EinsatzberichtTypModulFilterInput = {
    einsatzberichtModul?: InputMaybe<EinsatzberichtModulFilterInput>;
    einsatzberichtModulId?: InputMaybe<Scalars['Int']>;
    einsatzberichtTyp?: InputMaybe<EinsatzberichtTypFilterInput>;
    einsatzberichtTypId?: InputMaybe<Scalars['Int']>;
};

export type EinsatzberichtTypModulInput = {
    einsatzberichtModulId: Scalars['Int'];
    einsatzberichtTypId: Scalars['Int'];
};

export type EinsatzberichtTypModulItem = {
    __typename?: 'EinsatzberichtTypModulItem';
    error?: Maybe<Error>;
    item?: Maybe<EinsatzberichtTypModul>;
};

export type EinsatzberichtTypModulOrderByInput = {
    einsatzberichtModul?: InputMaybe<EinsatzberichtModulOrderByInput>;
    einsatzberichtTyp?: InputMaybe<EinsatzberichtTypOrderByInput>;
};

export type EinsatzberichtTypOrderByInput = {
    deleted?: InputMaybe<SortOrder>;
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    einsatzberichtTypModul?: InputMaybe<EinsatzberichtTypModulOrderByInput>;
    name?: InputMaybe<SortOrder>;
};

export type Error = {
    __typename?: 'Error';
    code: Scalars['String'];
    data: Scalars['String'];
    message: Scalars['String'];
};

export type Funktion = {
    __typename?: 'Funktion';
    aufgabe?: Maybe<Array<Maybe<Aufgabe>>>;
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    mitarbeiterFunktion?: Maybe<Array<Maybe<MitarbeiterFunktion>>>;
};

export type FunktionAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type FunktionCollection = {
    __typename?: 'FunktionCollection';
    error?: Maybe<Error>;
    items: Array<Funktion>;
    pageInfo: PageInfo;
};

export type FunktionFilterInput = {
    aufgabe?: InputMaybe<AufgabeFilterInput>;
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    mitarbeiterFunktion?: InputMaybe<MitarbeiterFunktionFilterInput>;
};

export type FunktionInput = {
    bezeichnung: Scalars['String'];
};

export type FunktionItem = {
    __typename?: 'FunktionItem';
    error?: Maybe<Error>;
    item?: Maybe<Funktion>;
};

export type FunktionOrderByInput = {
    aufgabe?: InputMaybe<AufgabeOrderByInput>;
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    mitarbeiterFunktion?: InputMaybe<MitarbeiterFunktionOrderByInput>;
};

export type Geraet = {
    __typename?: 'Geraet';
    deleted?: Maybe<Scalars['AWSDateTime']>;
    geraeteeinsatz?: Maybe<Array<Maybe<Geraeteeinsatz>>>;
    geraetetyp?: Maybe<Geraetetyp>;
    geraetetypId: Scalars['Int'];
    id: Scalars['Int'];
    leistung: Scalars['Float'];
    nummer: Scalars['String'];
    standort: Scalars['String'];
};

export type GeraetAutoCompleteFields = {
    nummer?: InputMaybe<Scalars['String']>;
    standort?: InputMaybe<Scalars['String']>;
};

export type GeraetCollection = {
    __typename?: 'GeraetCollection';
    error?: Maybe<Error>;
    items: Array<Geraet>;
    pageInfo: PageInfo;
};

export type GeraetFilterInput = {
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    geraeteeinsatz?: InputMaybe<GeraeteeinsatzFilterInput>;
    geraetetyp?: InputMaybe<GeraetetypFilterInput>;
    geraetetypId?: InputMaybe<Scalars['Int']>;
    leistungFrom?: InputMaybe<Scalars['Float']>;
    leistungTo?: InputMaybe<Scalars['Float']>;
    nummerExists?: InputMaybe<Scalars['Boolean']>;
    nummerPrefix?: InputMaybe<Scalars['String']>;
    standortExists?: InputMaybe<Scalars['Boolean']>;
    standortPrefix?: InputMaybe<Scalars['String']>;
};

export type GeraetInput = {
    geraetetypId: Scalars['Int'];
    leistung: Scalars['Float'];
    nummer: Scalars['String'];
    standort: Scalars['String'];
};

export type GeraetItem = {
    __typename?: 'GeraetItem';
    error?: Maybe<Error>;
    item?: Maybe<Geraet>;
};

export type GeraetOrderByInput = {
    deleted?: InputMaybe<SortOrder>;
    geraeteeinsatz?: InputMaybe<GeraeteeinsatzOrderByInput>;
    geraetetyp?: InputMaybe<GeraetetypOrderByInput>;
    leistung?: InputMaybe<SortOrder>;
    nummer?: InputMaybe<SortOrder>;
    standort?: InputMaybe<SortOrder>;
};

export type Geraeteeinsatz = {
    __typename?: 'Geraeteeinsatz';
    abrechnen: Scalars['Boolean'];
    beginnTag?: Maybe<Scalars['AWSDate']>;
    berechnungsmethodeSnippet?: Maybe<Snippet>;
    berechnungsmethodeSnippetId?: Maybe<Scalars['Int']>;
    endeTag?: Maybe<Scalars['AWSDate']>;
    geraet?: Maybe<Geraet>;
    geraetId?: Maybe<Scalars['Int']>;
    geraetetyp?: Maybe<Geraetetyp>;
    geraetetypId: Scalars['Int'];
    id: Scalars['Int'];
    subprojektWohneinheit?: Maybe<SubprojektWohneinheit>;
    subprojektWohneinheitId: Scalars['Int'];
    wattroAssetId?: Maybe<Scalars['Int']>;
    wattroId?: Maybe<Scalars['Int']>;
};

export type GeraeteeinsatzCollection = {
    __typename?: 'GeraeteeinsatzCollection';
    error?: Maybe<Error>;
    items: Array<Geraeteeinsatz>;
    pageInfo: PageInfo;
};

export type GeraeteeinsatzFilterInput = {
    abrechnen?: InputMaybe<Scalars['Boolean']>;
    beginnTagExists?: InputMaybe<Scalars['Boolean']>;
    beginnTagFrom?: InputMaybe<Scalars['AWSDate']>;
    beginnTagTo?: InputMaybe<Scalars['AWSDate']>;
    berechnungsmethodeSnippet?: InputMaybe<SnippetFilterInput>;
    berechnungsmethodeSnippetId?: InputMaybe<Scalars['Int']>;
    berechnungsmethodeSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    endeTagExists?: InputMaybe<Scalars['Boolean']>;
    endeTagFrom?: InputMaybe<Scalars['AWSDate']>;
    endeTagTo?: InputMaybe<Scalars['AWSDate']>;
    geraet?: InputMaybe<GeraetFilterInput>;
    geraetId?: InputMaybe<Scalars['Int']>;
    geraetIdExists?: InputMaybe<Scalars['Boolean']>;
    geraetetyp?: InputMaybe<GeraetetypFilterInput>;
    geraetetypId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    wattroAssetId?: InputMaybe<Scalars['Int']>;
    wattroAssetIdExists?: InputMaybe<Scalars['Boolean']>;
    wattroId?: InputMaybe<Scalars['Int']>;
    wattroIdExists?: InputMaybe<Scalars['Boolean']>;
};

export type GeraeteeinsatzInput = {
    abrechnen: Scalars['Boolean'];
    beginnTag?: InputMaybe<Scalars['AWSDate']>;
    berechnungsmethodeSnippetId?: InputMaybe<Scalars['Int']>;
    endeTag?: InputMaybe<Scalars['AWSDate']>;
    geraetId?: InputMaybe<Scalars['Int']>;
    geraetetypId: Scalars['Int'];
    subprojektWohneinheitId: Scalars['Int'];
    wattroAssetId?: InputMaybe<Scalars['Int']>;
    wattroId?: InputMaybe<Scalars['Int']>;
};

export type GeraeteeinsatzItem = {
    __typename?: 'GeraeteeinsatzItem';
    error?: Maybe<Error>;
    item?: Maybe<Geraeteeinsatz>;
};

export type GeraeteeinsatzOrderByInput = {
    abrechnen?: InputMaybe<SortOrder>;
    beginnTag?: InputMaybe<SortOrder>;
    berechnungsmethodeSnippet?: InputMaybe<SnippetOrderByInput>;
    endeTag?: InputMaybe<SortOrder>;
    geraet?: InputMaybe<GeraetOrderByInput>;
    geraetetyp?: InputMaybe<GeraetetypOrderByInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
};

export type Geraetetyp = {
    __typename?: 'Geraetetyp';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    dokument?: Maybe<Dokument>;
    dokumentId?: Maybe<Scalars['Int']>;
    geraet?: Maybe<Array<Maybe<Geraet>>>;
    geraeteeinsatz?: Maybe<Array<Maybe<Geraeteeinsatz>>>;
    hersteller: Scalars['String'];
    id: Scalars['Int'];
    isWattro: Scalars['Boolean'];
    mitZaehler: Scalars['Boolean'];
    nennleistung: Scalars['Float'];
    typ: Scalars['String'];
};

export type GeraetetypAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
    hersteller?: InputMaybe<Scalars['String']>;
    typ?: InputMaybe<Scalars['String']>;
};

export type GeraetetypCollection = {
    __typename?: 'GeraetetypCollection';
    error?: Maybe<Error>;
    items: Array<Geraetetyp>;
    pageInfo: PageInfo;
};

export type GeraetetypFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    dokument?: InputMaybe<DokumentFilterInput>;
    dokumentId?: InputMaybe<Scalars['Int']>;
    dokumentIdExists?: InputMaybe<Scalars['Boolean']>;
    geraet?: InputMaybe<GeraetFilterInput>;
    geraeteeinsatz?: InputMaybe<GeraeteeinsatzFilterInput>;
    herstellerExists?: InputMaybe<Scalars['Boolean']>;
    herstellerPrefix?: InputMaybe<Scalars['String']>;
    isWattro?: InputMaybe<Scalars['Boolean']>;
    mitZaehler?: InputMaybe<Scalars['Boolean']>;
    nennleistungFrom?: InputMaybe<Scalars['Float']>;
    nennleistungTo?: InputMaybe<Scalars['Float']>;
    typExists?: InputMaybe<Scalars['Boolean']>;
    typPrefix?: InputMaybe<Scalars['String']>;
};

export type GeraetetypInput = {
    bezeichnung: Scalars['String'];
    dokumentId?: InputMaybe<Scalars['Int']>;
    hersteller: Scalars['String'];
    isWattro: Scalars['Boolean'];
    mitZaehler: Scalars['Boolean'];
    nennleistung: Scalars['Float'];
    typ: Scalars['String'];
};

export type GeraetetypItem = {
    __typename?: 'GeraetetypItem';
    error?: Maybe<Error>;
    item?: Maybe<Geraetetyp>;
};

export type GeraetetypOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    dokument?: InputMaybe<DokumentOrderByInput>;
    geraet?: InputMaybe<GeraetOrderByInput>;
    geraeteeinsatz?: InputMaybe<GeraeteeinsatzOrderByInput>;
    hersteller?: InputMaybe<SortOrder>;
    isWattro?: InputMaybe<SortOrder>;
    mitZaehler?: InputMaybe<SortOrder>;
    nennleistung?: InputMaybe<SortOrder>;
    typ?: InputMaybe<SortOrder>;
};

export type Gewerk = {
    __typename?: 'Gewerk';
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzberichtGewerk?: Maybe<Array<Maybe<EinsatzberichtGewerk>>>;
    gewerkProjekttyp?: Maybe<Array<Maybe<GewerkProjekttyp>>>;
    id: Scalars['Int'];
    name: Scalars['String'];
    notdienstFaehig: Scalars['Boolean'];
    publicId: Scalars['Int'];
    subprojektGewerk?: Maybe<Array<Maybe<SubprojektGewerk>>>;
};

export type GewerkAutoCompleteFields = {
    name?: InputMaybe<Scalars['String']>;
};

export type GewerkCollection = {
    __typename?: 'GewerkCollection';
    error?: Maybe<Error>;
    items: Array<Gewerk>;
    pageInfo: PageInfo;
};

export type GewerkFilterInput = {
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzberichtGewerk?: InputMaybe<EinsatzberichtGewerkFilterInput>;
    gewerkProjekttyp?: InputMaybe<GewerkProjekttypFilterInput>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    notdienstFaehig?: InputMaybe<Scalars['Boolean']>;
    publicId?: InputMaybe<Scalars['Int']>;
    subprojektGewerk?: InputMaybe<SubprojektGewerkFilterInput>;
};

export type GewerkInput = {
    name: Scalars['String'];
    notdienstFaehig: Scalars['Boolean'];
    publicId: Scalars['Int'];
};

export type GewerkItem = {
    __typename?: 'GewerkItem';
    error?: Maybe<Error>;
    item?: Maybe<Gewerk>;
};

export type GewerkOrderByInput = {
    deleted?: InputMaybe<SortOrder>;
    einsatzberichtGewerk?: InputMaybe<EinsatzberichtGewerkOrderByInput>;
    gewerkProjekttyp?: InputMaybe<GewerkProjekttypOrderByInput>;
    name?: InputMaybe<SortOrder>;
    notdienstFaehig?: InputMaybe<SortOrder>;
    subprojektGewerk?: InputMaybe<SubprojektGewerkOrderByInput>;
};

export type GewerkProjekttyp = {
    __typename?: 'GewerkProjekttyp';
    gewerk?: Maybe<Gewerk>;
    gewerkId: Scalars['Int'];
    id: Scalars['Int'];
    projekttyp?: Maybe<Projekttyp>;
    projekttypId: Scalars['Int'];
};

export type GewerkProjekttypCollection = {
    __typename?: 'GewerkProjekttypCollection';
    error?: Maybe<Error>;
    items: Array<GewerkProjekttyp>;
    pageInfo: PageInfo;
};

export type GewerkProjekttypFilterInput = {
    gewerk?: InputMaybe<GewerkFilterInput>;
    gewerkId?: InputMaybe<Scalars['Int']>;
    projekttyp?: InputMaybe<ProjekttypFilterInput>;
    projekttypId?: InputMaybe<Scalars['Int']>;
};

export type GewerkProjekttypInput = {
    gewerkId: Scalars['Int'];
    projekttypId: Scalars['Int'];
};

export type GewerkProjekttypItem = {
    __typename?: 'GewerkProjekttypItem';
    error?: Maybe<Error>;
    item?: Maybe<GewerkProjekttyp>;
};

export type GewerkProjekttypOrderByInput = {
    gewerk?: InputMaybe<GewerkOrderByInput>;
    projekttyp?: InputMaybe<ProjekttypOrderByInput>;
};

export type Kalkulationsposition = {
    __typename?: 'Kalkulationsposition';
    beschreibung: Scalars['String'];
    einheit: Scalars['String'];
    einzelpreis: Scalars['Float'];
    id: Scalars['Int'];
    lfdNr: Scalars['String'];
    menge: Scalars['Float'];
    rahmenvertragsposition?: Maybe<Rahmenvertragsposition>;
    rahmenvertragspositionId?: Maybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    subprojektWohneinheitId?: Maybe<Scalars['Int']>;
    subprojekt_wohneinheit?: Maybe<SubprojektWohneinheit>;
};

export type KalkulationspositionAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
    einheit?: InputMaybe<Scalars['String']>;
    lfdNr?: InputMaybe<Scalars['String']>;
};

export type KalkulationspositionCollection = {
    __typename?: 'KalkulationspositionCollection';
    error?: Maybe<Error>;
    items: Array<Kalkulationsposition>;
    pageInfo: PageInfo;
};

export type KalkulationspositionFilterInput = {
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    einheitExists?: InputMaybe<Scalars['Boolean']>;
    einheitPrefix?: InputMaybe<Scalars['String']>;
    einzelpreisFrom?: InputMaybe<Scalars['Float']>;
    einzelpreisTo?: InputMaybe<Scalars['Float']>;
    lfdNrExists?: InputMaybe<Scalars['Boolean']>;
    lfdNrPrefix?: InputMaybe<Scalars['String']>;
    mengeFrom?: InputMaybe<Scalars['Float']>;
    mengeTo?: InputMaybe<Scalars['Float']>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionFilterInput>;
    rahmenvertragspositionId?: InputMaybe<Scalars['Int']>;
    rahmenvertragspositionIdExists?: InputMaybe<Scalars['Boolean']>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheitIdExists?: InputMaybe<Scalars['Boolean']>;
    subprojekt_wohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
};

export type KalkulationspositionInput = {
    beschreibung: Scalars['String'];
    einheit: Scalars['String'];
    einzelpreis: Scalars['Float'];
    lfdNr: Scalars['String'];
    menge: Scalars['Float'];
    rahmenvertragspositionId?: InputMaybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
};

export type KalkulationspositionItem = {
    __typename?: 'KalkulationspositionItem';
    error?: Maybe<Error>;
    item?: Maybe<Kalkulationsposition>;
};

export type KalkulationspositionOrderByInput = {
    beschreibung?: InputMaybe<SortOrder>;
    einheit?: InputMaybe<SortOrder>;
    einzelpreis?: InputMaybe<SortOrder>;
    lfdNr?: InputMaybe<SortOrder>;
    menge?: InputMaybe<SortOrder>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionOrderByInput>;
    subprojekt_wohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
};

export type Kontakt = {
    __typename?: 'Kontakt';
    anredeSnippet?: Maybe<Snippet>;
    anredeSnippetId?: Maybe<Scalars['Int']>;
    beteiligter?: Maybe<Array<Maybe<Beteiligter>>>;
    datumErfasst?: Maybe<Scalars['AWSDate']>;
    datumGeaendert?: Maybe<Scalars['AWSDate']>;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzbericht?: Maybe<Array<Maybe<Einsatzbericht>>>;
    email: Scalars['String'];
    fax: Scalars['String'];
    firma1: Scalars['String'];
    firma2: Scalars['String'];
    freitext: Richtext;
    id: Scalars['Int'];
    landSnippet?: Maybe<Snippet>;
    landSnippetId?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    ort: Scalars['String'];
    plz: Scalars['String'];
    postfach: Scalars['String'];
    statusSnippet?: Maybe<Snippet>;
    statusSnippetId?: Maybe<Scalars['Int']>;
    strasse: Scalars['String'];
    telefon: Scalars['String'];
    telefonDirekt: Scalars['String'];
    telefonMobil: Scalars['String'];
    telefonPrivat: Scalars['String'];
    typSnippet?: Maybe<Snippet>;
    typSnippetId?: Maybe<Scalars['Int']>;
    version: Scalars['Int'];
    vorname: Scalars['String'];
    zustellOrt: Scalars['String'];
    zustellPlz: Scalars['String'];
    zustellStrasse: Scalars['String'];
};

export type KontaktAutoCompleteFields = {
    email?: InputMaybe<Scalars['String']>;
    fax?: InputMaybe<Scalars['String']>;
    firma1?: InputMaybe<Scalars['String']>;
    firma2?: InputMaybe<Scalars['String']>;
    freitext?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    ort?: InputMaybe<Scalars['String']>;
    plz?: InputMaybe<Scalars['String']>;
    postfach?: InputMaybe<Scalars['String']>;
    strasse?: InputMaybe<Scalars['String']>;
    telefon?: InputMaybe<Scalars['String']>;
    telefonDirekt?: InputMaybe<Scalars['String']>;
    telefonMobil?: InputMaybe<Scalars['String']>;
    telefonPrivat?: InputMaybe<Scalars['String']>;
    vorname?: InputMaybe<Scalars['String']>;
    zustellOrt?: InputMaybe<Scalars['String']>;
    zustellPlz?: InputMaybe<Scalars['String']>;
    zustellStrasse?: InputMaybe<Scalars['String']>;
};

export type KontaktCollection = {
    __typename?: 'KontaktCollection';
    error?: Maybe<Error>;
    items: Array<Kontakt>;
    pageInfo: PageInfo;
};

export type KontaktFilterInput = {
    anredeSnippet?: InputMaybe<SnippetFilterInput>;
    anredeSnippetId?: InputMaybe<Scalars['Int']>;
    anredeSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    beteiligter?: InputMaybe<BeteiligterFilterInput>;
    datumErfasstExists?: InputMaybe<Scalars['Boolean']>;
    datumErfasstFrom?: InputMaybe<Scalars['AWSDate']>;
    datumErfasstTo?: InputMaybe<Scalars['AWSDate']>;
    datumGeaendertExists?: InputMaybe<Scalars['Boolean']>;
    datumGeaendertFrom?: InputMaybe<Scalars['AWSDate']>;
    datumGeaendertTo?: InputMaybe<Scalars['AWSDate']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    emailExists?: InputMaybe<Scalars['Boolean']>;
    emailPrefix?: InputMaybe<Scalars['String']>;
    faxExists?: InputMaybe<Scalars['Boolean']>;
    faxPrefix?: InputMaybe<Scalars['String']>;
    firma1Exists?: InputMaybe<Scalars['Boolean']>;
    firma1Prefix?: InputMaybe<Scalars['String']>;
    firma2Exists?: InputMaybe<Scalars['Boolean']>;
    firma2Prefix?: InputMaybe<Scalars['String']>;
    freitextExists?: InputMaybe<Scalars['Boolean']>;
    freitextPrefix?: InputMaybe<Scalars['String']>;
    landSnippet?: InputMaybe<SnippetFilterInput>;
    landSnippetId?: InputMaybe<Scalars['Int']>;
    landSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    ortExists?: InputMaybe<Scalars['Boolean']>;
    ortPrefix?: InputMaybe<Scalars['String']>;
    plzExists?: InputMaybe<Scalars['Boolean']>;
    plzPrefix?: InputMaybe<Scalars['String']>;
    postfachExists?: InputMaybe<Scalars['Boolean']>;
    postfachPrefix?: InputMaybe<Scalars['String']>;
    statusSnippet?: InputMaybe<SnippetFilterInput>;
    statusSnippetId?: InputMaybe<Scalars['Int']>;
    statusSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    strasseExists?: InputMaybe<Scalars['Boolean']>;
    strassePrefix?: InputMaybe<Scalars['String']>;
    telefonDirektExists?: InputMaybe<Scalars['Boolean']>;
    telefonDirektPrefix?: InputMaybe<Scalars['String']>;
    telefonExists?: InputMaybe<Scalars['Boolean']>;
    telefonMobilExists?: InputMaybe<Scalars['Boolean']>;
    telefonMobilPrefix?: InputMaybe<Scalars['String']>;
    telefonPrefix?: InputMaybe<Scalars['String']>;
    telefonPrivatExists?: InputMaybe<Scalars['Boolean']>;
    telefonPrivatPrefix?: InputMaybe<Scalars['String']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
    typSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    vornameExists?: InputMaybe<Scalars['Boolean']>;
    vornamePrefix?: InputMaybe<Scalars['String']>;
    zustellOrtExists?: InputMaybe<Scalars['Boolean']>;
    zustellOrtPrefix?: InputMaybe<Scalars['String']>;
    zustellPlzExists?: InputMaybe<Scalars['Boolean']>;
    zustellPlzPrefix?: InputMaybe<Scalars['String']>;
    zustellStrasseExists?: InputMaybe<Scalars['Boolean']>;
    zustellStrassePrefix?: InputMaybe<Scalars['String']>;
};

export type KontaktInput = {
    anredeSnippetId?: InputMaybe<Scalars['Int']>;
    email: Scalars['String'];
    fax: Scalars['String'];
    firma1: Scalars['String'];
    firma2: Scalars['String'];
    freitext: Scalars['AWSJSON'];
    landSnippetId?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    ort: Scalars['String'];
    plz: Scalars['String'];
    postfach: Scalars['String'];
    statusSnippetId?: InputMaybe<Scalars['Int']>;
    strasse: Scalars['String'];
    telefon: Scalars['String'];
    telefonDirekt: Scalars['String'];
    telefonMobil: Scalars['String'];
    telefonPrivat: Scalars['String'];
    typSnippetId?: InputMaybe<Scalars['Int']>;
    vorname: Scalars['String'];
    zustellOrt: Scalars['String'];
    zustellPlz: Scalars['String'];
    zustellStrasse: Scalars['String'];
};

export type KontaktItem = {
    __typename?: 'KontaktItem';
    error?: Maybe<Error>;
    item?: Maybe<Kontakt>;
};

export type KontaktOrderByInput = {
    anredeSnippet?: InputMaybe<SnippetOrderByInput>;
    beteiligter?: InputMaybe<BeteiligterOrderByInput>;
    datumErfasst?: InputMaybe<SortOrder>;
    datumGeaendert?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    email?: InputMaybe<SortOrder>;
    fax?: InputMaybe<SortOrder>;
    firma1?: InputMaybe<SortOrder>;
    firma2?: InputMaybe<SortOrder>;
    freitext?: InputMaybe<SortOrder>;
    landSnippet?: InputMaybe<SnippetOrderByInput>;
    name?: InputMaybe<SortOrder>;
    ort?: InputMaybe<SortOrder>;
    plz?: InputMaybe<SortOrder>;
    postfach?: InputMaybe<SortOrder>;
    statusSnippet?: InputMaybe<SnippetOrderByInput>;
    strasse?: InputMaybe<SortOrder>;
    telefon?: InputMaybe<SortOrder>;
    telefonDirekt?: InputMaybe<SortOrder>;
    telefonMobil?: InputMaybe<SortOrder>;
    telefonPrivat?: InputMaybe<SortOrder>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
    vorname?: InputMaybe<SortOrder>;
    zustellOrt?: InputMaybe<SortOrder>;
    zustellPlz?: InputMaybe<SortOrder>;
    zustellStrasse?: InputMaybe<SortOrder>;
};

export type Korrespondenz = {
    __typename?: 'Korrespondenz';
    angelegt?: Maybe<Scalars['AWSDateTime']>;
    beteiligter: Scalars['String'];
    dokument?: Maybe<Dokument>;
    dokumentId?: Maybe<Scalars['Int']>;
    freitext: Richtext;
    id: Scalars['Int'];
    mitarbeiterIdSachbearbeiter?: Maybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: Maybe<Scalars['Int']>;
    sachbearbeiter?: Maybe<Mitarbeiter>;
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
    urheber?: Maybe<Mitarbeiter>;
    version: Scalars['Int'];
    zeit?: Maybe<Scalars['AWSDateTime']>;
};

export type KorrespondenzAutoCompleteFields = {
    beteiligter?: InputMaybe<Scalars['String']>;
    freitext?: InputMaybe<Scalars['String']>;
};

export type KorrespondenzCollection = {
    __typename?: 'KorrespondenzCollection';
    error?: Maybe<Error>;
    items: Array<Korrespondenz>;
    pageInfo: PageInfo;
};

export type KorrespondenzCreateInput = {
    beteiligter: Scalars['String'];
    dokumentId?: InputMaybe<Scalars['Int']>;
    freitext: Scalars['AWSJSON'];
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    typSnippetId: Scalars['Int'];
    zeit?: InputMaybe<Scalars['AWSDateTime']>;
};

export type KorrespondenzFilterInput = {
    angelegtExists?: InputMaybe<Scalars['Boolean']>;
    angelegtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    angelegtTo?: InputMaybe<Scalars['AWSDateTime']>;
    beteiligterExists?: InputMaybe<Scalars['Boolean']>;
    beteiligterPrefix?: InputMaybe<Scalars['String']>;
    dokument?: InputMaybe<DokumentFilterInput>;
    dokumentId?: InputMaybe<Scalars['Int']>;
    dokumentIdExists?: InputMaybe<Scalars['Boolean']>;
    freitextExists?: InputMaybe<Scalars['Boolean']>;
    freitextPrefix?: InputMaybe<Scalars['String']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiterExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheberExists?: InputMaybe<Scalars['Boolean']>;
    sachbearbeiter?: InputMaybe<MitarbeiterFilterInput>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
    urheber?: InputMaybe<MitarbeiterFilterInput>;
    zeitExists?: InputMaybe<Scalars['Boolean']>;
    zeitFrom?: InputMaybe<Scalars['AWSDateTime']>;
    zeitTo?: InputMaybe<Scalars['AWSDateTime']>;
};

export type KorrespondenzItem = {
    __typename?: 'KorrespondenzItem';
    error?: Maybe<Error>;
    item?: Maybe<Korrespondenz>;
};

export type KorrespondenzOderTermin = {
    __typename?: 'KorrespondenzOderTermin';
    beschreibung: Scalars['String'];
    datum?: Maybe<Scalars['AWSDate']>;
    korrespondenz?: Maybe<Korrespondenz>;
    korrespondenzId?: Maybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: Maybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: Maybe<Scalars['Int']>;
    sachbearbeiter?: Maybe<Mitarbeiter>;
    termin?: Maybe<Termin>;
    terminId?: Maybe<Scalars['Int']>;
    typ: Scalars['String'];
    urheber?: Maybe<Mitarbeiter>;
};

export type KorrespondenzOrderByInput = {
    angelegt?: InputMaybe<SortOrder>;
    beteiligter?: InputMaybe<SortOrder>;
    dokument?: InputMaybe<DokumentOrderByInput>;
    freitext?: InputMaybe<SortOrder>;
    sachbearbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
    urheber?: InputMaybe<MitarbeiterOrderByInput>;
    zeit?: InputMaybe<SortOrder>;
};

export type KorrespondenzUndTerminCollection = {
    __typename?: 'KorrespondenzUndTerminCollection';
    error?: Maybe<Error>;
    items: Array<KorrespondenzOderTermin>;
    pageInfo: PageInfo;
};

export type KorrespondenzUpdateInput = {
    beteiligter: Scalars['String'];
    dokumentId?: InputMaybe<Scalars['Int']>;
    freitext: Scalars['AWSJSON'];
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    typSnippetId: Scalars['Int'];
    zeit?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Leistung = {
    __typename?: 'Leistung';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einheit: Scalars['String'];
    id: Scalars['Int'];
    rahmenvertragsposition?: Maybe<Array<Maybe<Rahmenvertragsposition>>>;
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
};

export type LeistungAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
    einheit?: InputMaybe<Scalars['String']>;
};

export type LeistungCollection = {
    __typename?: 'LeistungCollection';
    error?: Maybe<Error>;
    items: Array<Leistung>;
    pageInfo: PageInfo;
};

export type LeistungFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einheitExists?: InputMaybe<Scalars['Boolean']>;
    einheitPrefix?: InputMaybe<Scalars['String']>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionFilterInput>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
};

export type LeistungInput = {
    bezeichnung: Scalars['String'];
    einheit: Scalars['String'];
    typSnippetId: Scalars['Int'];
};

export type LeistungItem = {
    __typename?: 'LeistungItem';
    error?: Maybe<Error>;
    item?: Maybe<Leistung>;
};

export type LeistungOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    einheit?: InputMaybe<SortOrder>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
};

export type Mitarbeiter = {
    __typename?: 'Mitarbeiter';
    abwesenheiten?: Maybe<Array<Maybe<Abwesenheit>>>;
    accessIp: Scalars['String'];
    angeboteAsProjektleitung?: Maybe<Array<Maybe<Angebot>>>;
    angeboteAsSachbearbeiter?: Maybe<Array<Maybe<Angebot>>>;
    aufgabenAsAbschlusszustaendiger?: Maybe<Array<Maybe<Aufgabe>>>;
    aufgabenAsSachbearbeiter?: Maybe<Array<Maybe<Aufgabe>>>;
    aufgabenAsUrheber?: Maybe<Array<Maybe<Aufgabe>>>;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    einsatzberichtAsUrheber?: Maybe<Array<Maybe<Einsatzbericht>>>;
    einsatzberichtSachbearbeiter?: Maybe<Array<Maybe<EinsatzberichtSachbearbeiter>>>;
    email: Scalars['String'];
    farbe: Scalars['String'];
    id: Scalars['Int'];
    kennung: Scalars['String'];
    korrespondenzenAsSachbearbeiter?: Maybe<Array<Maybe<Korrespondenz>>>;
    korrespondenznAsUrheber?: Maybe<Array<Maybe<Korrespondenz>>>;
    mitarbeiterFunktion?: Maybe<Array<Maybe<MitarbeiterFunktion>>>;
    name: Scalars['String'];
    niederlassung?: Maybe<Niederlassung>;
    niederlassungId: Scalars['Int'];
    notdienstprojekteAsByEmail?: Maybe<Array<Maybe<Notdienstprojekt>>>;
    notdienstprojekteAsByStartedBy?: Maybe<Array<Maybe<Notdienstprojekt>>>;
    passwort: Scalars['String'];
    projekthistorie?: Maybe<Scalars['AWSJSON']>;
    rechnungenAsProjektleitung?: Maybe<Array<Maybe<Rechnung>>>;
    rechnungenAsSachbearbeiter?: Maybe<Array<Maybe<Rechnung>>>;
    rolle?: Maybe<Rolle>;
    rolleId: Scalars['Int'];
    telefon: Scalars['String'];
    termineAsSachbearbeiter?: Maybe<Array<Maybe<Termin>>>;
    termineAsUrheber?: Maybe<Array<Maybe<Termin>>>;
    urlaubsgruppe: Scalars['String'];
    urlaubstage: Scalars['Int'];
    vorname: Scalars['String'];
};

export type MitarbeiterAutoCompleteFields = {
    accessIp?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    farbe?: InputMaybe<Scalars['String']>;
    kennung?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    passwort?: InputMaybe<Scalars['String']>;
    projekthistorie?: InputMaybe<Scalars['AWSJSON']>;
    telefon?: InputMaybe<Scalars['String']>;
    urlaubsgruppe?: InputMaybe<Scalars['String']>;
    vorname?: InputMaybe<Scalars['String']>;
};

export type MitarbeiterCollection = {
    __typename?: 'MitarbeiterCollection';
    error?: Maybe<Error>;
    items: Array<Mitarbeiter>;
    pageInfo: PageInfo;
};

export type MitarbeiterFilterInput = {
    abwesenheiten?: InputMaybe<AbwesenheitFilterInput>;
    accessIpExists?: InputMaybe<Scalars['Boolean']>;
    accessIpPrefix?: InputMaybe<Scalars['String']>;
    angeboteAsProjektleitung?: InputMaybe<AngebotFilterInput>;
    angeboteAsSachbearbeiter?: InputMaybe<AngebotFilterInput>;
    aufgabenAsAbschlusszustaendiger?: InputMaybe<AufgabeFilterInput>;
    aufgabenAsSachbearbeiter?: InputMaybe<AufgabeFilterInput>;
    aufgabenAsUrheber?: InputMaybe<AufgabeFilterInput>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    einsatzberichtAsUrheber?: InputMaybe<EinsatzberichtFilterInput>;
    einsatzberichtSachbearbeiter?: InputMaybe<EinsatzberichtSachbearbeiterFilterInput>;
    emailExists?: InputMaybe<Scalars['Boolean']>;
    emailPrefix?: InputMaybe<Scalars['String']>;
    farbeExists?: InputMaybe<Scalars['Boolean']>;
    farbePrefix?: InputMaybe<Scalars['String']>;
    kennungExists?: InputMaybe<Scalars['Boolean']>;
    kennungPrefix?: InputMaybe<Scalars['String']>;
    korrespondenzenAsSachbearbeiter?: InputMaybe<KorrespondenzFilterInput>;
    korrespondenznAsUrheber?: InputMaybe<KorrespondenzFilterInput>;
    mitarbeiterFunktion?: InputMaybe<MitarbeiterFunktionFilterInput>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    niederlassung?: InputMaybe<NiederlassungFilterInput>;
    niederlassungId?: InputMaybe<Scalars['Int']>;
    notdienstprojekteAsByEmail?: InputMaybe<NotdienstprojektFilterInput>;
    notdienstprojekteAsByStartedBy?: InputMaybe<NotdienstprojektFilterInput>;
    passwortExists?: InputMaybe<Scalars['Boolean']>;
    passwortPrefix?: InputMaybe<Scalars['String']>;
    projekthistorieExists?: InputMaybe<Scalars['Boolean']>;
    projekthistoriePrefix?: InputMaybe<Scalars['String']>;
    rechnungenAsProjektleitung?: InputMaybe<RechnungFilterInput>;
    rechnungenAsSachbearbeiter?: InputMaybe<RechnungFilterInput>;
    rolle?: InputMaybe<RolleFilterInput>;
    rolleId?: InputMaybe<Scalars['Int']>;
    telefonExists?: InputMaybe<Scalars['Boolean']>;
    telefonPrefix?: InputMaybe<Scalars['String']>;
    termineAsSachbearbeiter?: InputMaybe<TerminFilterInput>;
    termineAsUrheber?: InputMaybe<TerminFilterInput>;
    urlaubsgruppeExists?: InputMaybe<Scalars['Boolean']>;
    urlaubsgruppePrefix?: InputMaybe<Scalars['String']>;
    urlaubstageFrom?: InputMaybe<Scalars['Float']>;
    urlaubstageTo?: InputMaybe<Scalars['Float']>;
    vornameExists?: InputMaybe<Scalars['Boolean']>;
    vornamePrefix?: InputMaybe<Scalars['String']>;
};

export type MitarbeiterFunktion = {
    __typename?: 'MitarbeiterFunktion';
    funktion?: Maybe<Funktion>;
    funktionId: Scalars['Int'];
    id: Scalars['Int'];
    mitarbeiter?: Maybe<Mitarbeiter>;
    mitarbeiterId: Scalars['Int'];
};

export type MitarbeiterFunktionCollection = {
    __typename?: 'MitarbeiterFunktionCollection';
    error?: Maybe<Error>;
    items: Array<MitarbeiterFunktion>;
    pageInfo: PageInfo;
};

export type MitarbeiterFunktionFilterInput = {
    funktion?: InputMaybe<FunktionFilterInput>;
    funktionId?: InputMaybe<Scalars['Int']>;
    mitarbeiter?: InputMaybe<MitarbeiterFilterInput>;
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
};

export type MitarbeiterFunktionInput = {
    funktionId: Scalars['Int'];
    mitarbeiterId: Scalars['Int'];
};

export type MitarbeiterFunktionItem = {
    __typename?: 'MitarbeiterFunktionItem';
    error?: Maybe<Error>;
    item?: Maybe<MitarbeiterFunktion>;
};

export type MitarbeiterFunktionOrderByInput = {
    funktion?: InputMaybe<FunktionOrderByInput>;
    mitarbeiter?: InputMaybe<MitarbeiterOrderByInput>;
};

export type MitarbeiterInput = {
    accessIp: Scalars['String'];
    email: Scalars['String'];
    farbe: Scalars['String'];
    kennung: Scalars['String'];
    name: Scalars['String'];
    niederlassungId: Scalars['Int'];
    passwort: Scalars['String'];
    projekthistorie?: InputMaybe<Scalars['AWSJSON']>;
    rolleId: Scalars['Int'];
    telefon: Scalars['String'];
    urlaubsgruppe: Scalars['String'];
    urlaubstage: Scalars['Int'];
    vorname: Scalars['String'];
};

export type MitarbeiterItem = {
    __typename?: 'MitarbeiterItem';
    error?: Maybe<Error>;
    item?: Maybe<Mitarbeiter>;
};

export type MitarbeiterOrderByInput = {
    abwesenheiten?: InputMaybe<AbwesenheitOrderByInput>;
    accessIp?: InputMaybe<SortOrder>;
    angeboteAsProjektleitung?: InputMaybe<AngebotOrderByInput>;
    angeboteAsSachbearbeiter?: InputMaybe<AngebotOrderByInput>;
    aufgabenAsAbschlusszustaendiger?: InputMaybe<AufgabeOrderByInput>;
    aufgabenAsSachbearbeiter?: InputMaybe<AufgabeOrderByInput>;
    aufgabenAsUrheber?: InputMaybe<AufgabeOrderByInput>;
    deleted?: InputMaybe<SortOrder>;
    einsatzberichtAsUrheber?: InputMaybe<EinsatzberichtOrderByInput>;
    einsatzberichtSachbearbeiter?: InputMaybe<EinsatzberichtSachbearbeiterOrderByInput>;
    email?: InputMaybe<SortOrder>;
    farbe?: InputMaybe<SortOrder>;
    kennung?: InputMaybe<SortOrder>;
    korrespondenzenAsSachbearbeiter?: InputMaybe<KorrespondenzOrderByInput>;
    korrespondenznAsUrheber?: InputMaybe<KorrespondenzOrderByInput>;
    mitarbeiterFunktion?: InputMaybe<MitarbeiterFunktionOrderByInput>;
    name?: InputMaybe<SortOrder>;
    niederlassung?: InputMaybe<NiederlassungOrderByInput>;
    notdienstprojekteAsByEmail?: InputMaybe<NotdienstprojektOrderByInput>;
    notdienstprojekteAsByStartedBy?: InputMaybe<NotdienstprojektOrderByInput>;
    passwort?: InputMaybe<SortOrder>;
    projekthistorie?: InputMaybe<SortOrder>;
    rechnungenAsProjektleitung?: InputMaybe<RechnungOrderByInput>;
    rechnungenAsSachbearbeiter?: InputMaybe<RechnungOrderByInput>;
    rolle?: InputMaybe<RolleOrderByInput>;
    telefon?: InputMaybe<SortOrder>;
    termineAsSachbearbeiter?: InputMaybe<TerminOrderByInput>;
    termineAsUrheber?: InputMaybe<TerminOrderByInput>;
    urlaubsgruppe?: InputMaybe<SortOrder>;
    urlaubstage?: InputMaybe<SortOrder>;
    vorname?: InputMaybe<SortOrder>;
};

export type Mutation = {
    __typename?: 'Mutation';
    app?: Maybe<AppMutation>;
    createAbwesenheit: AbwesenheitItem;
    createAngebot: AngebotItem;
    createAngebotsposition: AngebotspositionItem;
    createAufgabe: AufgabeItem;
    createAufmass: AufmassItem;
    createBeteiligter: BeteiligterItem;
    createDokument: DokumentItem;
    createEinsatzbericht: EinsatzberichtItem;
    createEinsatzberichtDetail: EinsatzberichtDetailItem;
    createEinsatzberichtGewerk: EinsatzberichtGewerkItem;
    createEinsatzberichtModul: EinsatzberichtModulItem;
    createEinsatzberichtOption: EinsatzberichtOptionItem;
    createEinsatzberichtSachbearbeiter: EinsatzberichtSachbearbeiterItem;
    createEinsatzberichtTyp: EinsatzberichtTypItem;
    createEinsatzberichtTypModul: EinsatzberichtTypModulItem;
    createFunktion: FunktionItem;
    createGeraet: GeraetItem;
    createGeraeteeinsatz: GeraeteeinsatzItem;
    createGeraetetyp: GeraetetypItem;
    createGewerk: GewerkItem;
    createGewerkProjekttyp: GewerkProjekttypItem;
    createKalkulationsposition: KalkulationspositionItem;
    createKontakt: KontaktItem;
    createKorrespondenz: KorrespondenzItem;
    createLeistung: LeistungItem;
    createMitarbeiter: MitarbeiterItem;
    createMitarbeiterFunktion: MitarbeiterFunktionItem;
    createNiederlassung: NiederlassungItem;
    createNotdienstprojekt: NotdienstprojektItem;
    createPostleitzahl: PostleitzahlItem;
    createProjekt: ProjektItem;
    createProjekttyp: ProjekttypItem;
    createRahmenvertrag: RahmenvertragItem;
    createRahmenvertragsposition: RahmenvertragspositionItem;
    createRechnung: RechnungItem;
    createRechnungsposition: RechnungspositionItem;
    createRolle: RolleItem;
    createSnippet: SnippetItem;
    createStromzaehler: StromzaehlerItem;
    createSubprojekt: SubprojektItem;
    createSubprojektGewerk: SubprojektGewerkItem;
    createSubprojektWohneinheit: SubprojektWohneinheitItem;
    createTermin: TerminItem;
    createTextbaustein: TextbausteinItem;
    createVersicherung: VersicherungItem;
    createWohneinheit: WohneinheitItem;
    deleteAbwesenheit: AbwesenheitItem;
    deleteAngebot: AngebotItem;
    deleteAngebotsposition: AngebotspositionItem;
    deleteAufgabe: AufgabeItem;
    deleteAufmass: AufmassItem;
    deleteBeteiligter: BeteiligterItem;
    deleteDokument: DokumentItem;
    deleteEinsatzbericht: EinsatzberichtItem;
    deleteEinsatzberichtDetail: EinsatzberichtDetailItem;
    deleteEinsatzberichtGewerk: EinsatzberichtGewerkItem;
    deleteEinsatzberichtModul: EinsatzberichtModulItem;
    deleteEinsatzberichtOption: EinsatzberichtOptionItem;
    deleteEinsatzberichtSachbearbeiter: EinsatzberichtSachbearbeiterItem;
    deleteEinsatzberichtTyp: EinsatzberichtTypItem;
    deleteEinsatzberichtTypModul: EinsatzberichtTypModulItem;
    deleteFunktion: FunktionItem;
    deleteGeraet: GeraetItem;
    deleteGeraeteeinsatz: GeraeteeinsatzItem;
    deleteGeraetetyp: GeraetetypItem;
    deleteGewerk: GewerkItem;
    deleteGewerkProjekttyp: GewerkProjekttypItem;
    deleteKalkulationsposition: KalkulationspositionItem;
    deleteKontakt: KontaktItem;
    deleteKorrespondenz: KorrespondenzItem;
    deleteLeistung: LeistungItem;
    deleteMitarbeiter: MitarbeiterItem;
    deleteMitarbeiterFunktion: MitarbeiterFunktionItem;
    deleteNiederlassung: NiederlassungItem;
    deleteNotdienstprojekt: NotdienstprojektItem;
    deletePostleitzahl: PostleitzahlItem;
    deleteProjekt: ProjektItem;
    deleteProjekttyp: ProjekttypItem;
    deleteRahmenvertrag: RahmenvertragItem;
    deleteRahmenvertragsposition: RahmenvertragspositionItem;
    deleteRechnung: RechnungItem;
    deleteRechnungsposition: RechnungspositionItem;
    deleteRolle: RolleItem;
    deleteSnippet: SnippetItem;
    deleteStromzaehler: StromzaehlerItem;
    deleteSubprojekt: SubprojektItem;
    deleteSubprojektGewerk: SubprojektGewerkItem;
    deleteSubprojektWohneinheit: SubprojektWohneinheitItem;
    deleteTermin: TerminItem;
    deleteTextbaustein: TextbausteinItem;
    deleteVersicherung: VersicherungItem;
    deleteWohneinheit: WohneinheitItem;
    updateAbwesenheit: AbwesenheitItem;
    updateAngebot: AngebotItem;
    updateAngebotsposition: AngebotspositionItem;
    updateAufgabe: AufgabeItem;
    updateAufmass: AufmassItem;
    updateBeteiligter: BeteiligterItem;
    updateDokument: DokumentItem;
    updateEinsatzbericht: EinsatzberichtItem;
    updateEinsatzberichtDetail: EinsatzberichtDetailItem;
    updateEinsatzberichtGewerk: EinsatzberichtGewerkItem;
    updateEinsatzberichtModul: EinsatzberichtModulItem;
    updateEinsatzberichtOption: EinsatzberichtOptionItem;
    updateEinsatzberichtSachbearbeiter: EinsatzberichtSachbearbeiterItem;
    updateEinsatzberichtTyp: EinsatzberichtTypItem;
    updateEinsatzberichtTypModul: EinsatzberichtTypModulItem;
    updateFunktion: FunktionItem;
    updateGeraet: GeraetItem;
    updateGeraeteeinsatz: GeraeteeinsatzItem;
    updateGeraetetyp: GeraetetypItem;
    updateGewerk: GewerkItem;
    updateGewerkProjekttyp: GewerkProjekttypItem;
    updateKalkulationsposition: KalkulationspositionItem;
    updateKontakt: KontaktItem;
    updateKorrespondenz: KorrespondenzItem;
    updateLeistung: LeistungItem;
    updateMitarbeiter: MitarbeiterItem;
    updateMitarbeiterFunktion: MitarbeiterFunktionItem;
    updateNiederlassung: NiederlassungItem;
    updateNotdienstprojekt: NotdienstprojektItem;
    updatePostleitzahl: PostleitzahlItem;
    updateProjekt: ProjektItem;
    updateProjekttyp: ProjekttypItem;
    updateRahmenvertrag: RahmenvertragItem;
    updateRahmenvertragsposition: RahmenvertragspositionItem;
    updateRechnung: RechnungItem;
    updateRechnungsposition: RechnungspositionItem;
    updateRolle: RolleItem;
    updateSnippet: SnippetItem;
    updateStromzaehler: StromzaehlerItem;
    updateSubprojekt: SubprojektItem;
    updateSubprojektGewerk: SubprojektGewerkItem;
    updateSubprojektWohneinheit: SubprojektWohneinheitItem;
    updateTermin: TerminItem;
    updateTextbaustein: TextbausteinItem;
    updateVersicherung: VersicherungItem;
    updateWohneinheit: WohneinheitItem;
};

export type MutationCreateAbwesenheitArgs = {
    data: AbwesenheitInput;
};

export type MutationCreateAngebotArgs = {
    data: AngebotInput;
};

export type MutationCreateAngebotspositionArgs = {
    data: AngebotspositionInput;
};

export type MutationCreateAufgabeArgs = {
    data: AufgabeCreateInput;
};

export type MutationCreateAufmassArgs = {
    data: AufmassInput;
};

export type MutationCreateBeteiligterArgs = {
    data: BeteiligterInput;
};

export type MutationCreateDokumentArgs = {
    data: DokumentInput;
};

export type MutationCreateEinsatzberichtArgs = {
    data: EinsatzberichtInput;
};

export type MutationCreateEinsatzberichtDetailArgs = {
    data: EinsatzberichtDetailInput;
};

export type MutationCreateEinsatzberichtGewerkArgs = {
    data: EinsatzberichtGewerkInput;
};

export type MutationCreateEinsatzberichtModulArgs = {
    data: EinsatzberichtModulInput;
};

export type MutationCreateEinsatzberichtOptionArgs = {
    data: EinsatzberichtOptionInput;
};

export type MutationCreateEinsatzberichtSachbearbeiterArgs = {
    data: EinsatzberichtSachbearbeiterInput;
};

export type MutationCreateEinsatzberichtTypArgs = {
    data: EinsatzberichtTypInput;
};

export type MutationCreateEinsatzberichtTypModulArgs = {
    data: EinsatzberichtTypModulInput;
};

export type MutationCreateFunktionArgs = {
    data: FunktionInput;
};

export type MutationCreateGeraetArgs = {
    data: GeraetInput;
};

export type MutationCreateGeraeteeinsatzArgs = {
    data: GeraeteeinsatzInput;
};

export type MutationCreateGeraetetypArgs = {
    data: GeraetetypInput;
};

export type MutationCreateGewerkArgs = {
    data: GewerkInput;
};

export type MutationCreateGewerkProjekttypArgs = {
    data: GewerkProjekttypInput;
};

export type MutationCreateKalkulationspositionArgs = {
    data: KalkulationspositionInput;
};

export type MutationCreateKontaktArgs = {
    data: KontaktInput;
};

export type MutationCreateKorrespondenzArgs = {
    data: KorrespondenzCreateInput;
};

export type MutationCreateLeistungArgs = {
    data: LeistungInput;
};

export type MutationCreateMitarbeiterArgs = {
    data: MitarbeiterInput;
};

export type MutationCreateMitarbeiterFunktionArgs = {
    data: MitarbeiterFunktionInput;
};

export type MutationCreateNiederlassungArgs = {
    data: NiederlassungInput;
};

export type MutationCreateNotdienstprojektArgs = {
    data: NotdienstprojektInput;
};

export type MutationCreatePostleitzahlArgs = {
    data: PostleitzahlInput;
};

export type MutationCreateProjektArgs = {
    data: ProjektInput;
};

export type MutationCreateProjekttypArgs = {
    data: ProjekttypInput;
};

export type MutationCreateRahmenvertragArgs = {
    data: RahmenvertragInput;
};

export type MutationCreateRahmenvertragspositionArgs = {
    data: RahmenvertragspositionInput;
};

export type MutationCreateRechnungArgs = {
    data: RechnungInput;
};

export type MutationCreateRechnungspositionArgs = {
    data: RechnungspositionInput;
};

export type MutationCreateRolleArgs = {
    data: RolleInput;
};

export type MutationCreateSnippetArgs = {
    data: SnippetInput;
};

export type MutationCreateStromzaehlerArgs = {
    data: StromzaehlerInput;
};

export type MutationCreateSubprojektArgs = {
    data: SubprojektInput;
};

export type MutationCreateSubprojektGewerkArgs = {
    data: SubprojektGewerkInput;
};

export type MutationCreateSubprojektWohneinheitArgs = {
    data: SubprojektWohneinheitInput;
};

export type MutationCreateTerminArgs = {
    data: TerminCreateInput;
};

export type MutationCreateTextbausteinArgs = {
    data: TextbausteinInput;
};

export type MutationCreateVersicherungArgs = {
    data: VersicherungInput;
};

export type MutationCreateWohneinheitArgs = {
    data: WohneinheitInput;
};

export type MutationDeleteAbwesenheitArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteAngebotArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteAngebotspositionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteAufgabeArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteAufmassArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteBeteiligterArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteDokumentArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtDetailArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtGewerkArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtModulArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtOptionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtSachbearbeiterArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtTypArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteEinsatzberichtTypModulArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteFunktionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteGeraetArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteGeraeteeinsatzArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteGeraetetypArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteGewerkArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteGewerkProjekttypArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteKalkulationspositionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteKontaktArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteKorrespondenzArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteLeistungArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteMitarbeiterArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteMitarbeiterFunktionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteNiederlassungArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteNotdienstprojektArgs = {
    id: Scalars['Int'];
};

export type MutationDeletePostleitzahlArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteProjektArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteProjekttypArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteRahmenvertragArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteRahmenvertragspositionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteRechnungArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteRechnungspositionArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteRolleArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteSnippetArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteStromzaehlerArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteSubprojektArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteSubprojektGewerkArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteSubprojektWohneinheitArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteTerminArgs = {
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationDeleteTextbausteinArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteVersicherungArgs = {
    id: Scalars['Int'];
};

export type MutationDeleteWohneinheitArgs = {
    id: Scalars['Int'];
};

export type MutationUpdateAbwesenheitArgs = {
    data: AbwesenheitInput;
    id: Scalars['Int'];
};

export type MutationUpdateAngebotArgs = {
    data: AngebotInput;
    id: Scalars['Int'];
};

export type MutationUpdateAngebotspositionArgs = {
    data: AngebotspositionInput;
    id: Scalars['Int'];
};

export type MutationUpdateAufgabeArgs = {
    data: AufgabeUpdateInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateAufmassArgs = {
    data: AufmassInput;
    id: Scalars['Int'];
};

export type MutationUpdateBeteiligterArgs = {
    data: BeteiligterInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateDokumentArgs = {
    data: DokumentInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtArgs = {
    data: EinsatzberichtInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtDetailArgs = {
    data: EinsatzberichtDetailInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtGewerkArgs = {
    data: EinsatzberichtGewerkInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtModulArgs = {
    data: EinsatzberichtModulInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtOptionArgs = {
    data: EinsatzberichtOptionInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtSachbearbeiterArgs = {
    data: EinsatzberichtSachbearbeiterInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtTypArgs = {
    data: EinsatzberichtTypInput;
    id: Scalars['Int'];
};

export type MutationUpdateEinsatzberichtTypModulArgs = {
    data: EinsatzberichtTypModulInput;
    id: Scalars['Int'];
};

export type MutationUpdateFunktionArgs = {
    data: FunktionInput;
    id: Scalars['Int'];
};

export type MutationUpdateGeraetArgs = {
    data: GeraetInput;
    id: Scalars['Int'];
};

export type MutationUpdateGeraeteeinsatzArgs = {
    data: GeraeteeinsatzInput;
    id: Scalars['Int'];
};

export type MutationUpdateGeraetetypArgs = {
    data: GeraetetypInput;
    id: Scalars['Int'];
};

export type MutationUpdateGewerkArgs = {
    data: GewerkInput;
    id: Scalars['Int'];
};

export type MutationUpdateGewerkProjekttypArgs = {
    data: GewerkProjekttypInput;
    id: Scalars['Int'];
};

export type MutationUpdateKalkulationspositionArgs = {
    data: KalkulationspositionInput;
    id: Scalars['Int'];
};

export type MutationUpdateKontaktArgs = {
    data: KontaktInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateKorrespondenzArgs = {
    data: KorrespondenzUpdateInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateLeistungArgs = {
    data: LeistungInput;
    id: Scalars['Int'];
};

export type MutationUpdateMitarbeiterArgs = {
    data: MitarbeiterInput;
    id: Scalars['Int'];
};

export type MutationUpdateMitarbeiterFunktionArgs = {
    data: MitarbeiterFunktionInput;
    id: Scalars['Int'];
};

export type MutationUpdateNiederlassungArgs = {
    data: NiederlassungInput;
    id: Scalars['Int'];
};

export type MutationUpdateNotdienstprojektArgs = {
    data: NotdienstprojektInput;
    id: Scalars['Int'];
};

export type MutationUpdatePostleitzahlArgs = {
    data: PostleitzahlInput;
    id: Scalars['Int'];
};

export type MutationUpdateProjektArgs = {
    data: ProjektInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateProjekttypArgs = {
    data: ProjekttypInput;
    id: Scalars['Int'];
};

export type MutationUpdateRahmenvertragArgs = {
    data: RahmenvertragInput;
    id: Scalars['Int'];
};

export type MutationUpdateRahmenvertragspositionArgs = {
    data: RahmenvertragspositionInput;
    id: Scalars['Int'];
};

export type MutationUpdateRechnungArgs = {
    data: RechnungInput;
    id: Scalars['Int'];
};

export type MutationUpdateRechnungspositionArgs = {
    data: RechnungspositionInput;
    id: Scalars['Int'];
};

export type MutationUpdateRolleArgs = {
    data: RolleInput;
    id: Scalars['Int'];
};

export type MutationUpdateSnippetArgs = {
    data: SnippetInput;
    id: Scalars['Int'];
};

export type MutationUpdateStromzaehlerArgs = {
    data: StromzaehlerInput;
    id: Scalars['Int'];
};

export type MutationUpdateSubprojektArgs = {
    data: SubprojektInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateSubprojektGewerkArgs = {
    data: SubprojektGewerkInput;
    id: Scalars['Int'];
};

export type MutationUpdateSubprojektWohneinheitArgs = {
    data: SubprojektWohneinheitInput;
    id: Scalars['Int'];
};

export type MutationUpdateTerminArgs = {
    data: TerminUpdateInput;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    version: Scalars['Int'];
};

export type MutationUpdateTextbausteinArgs = {
    data: TextbausteinInput;
    id: Scalars['Int'];
};

export type MutationUpdateVersicherungArgs = {
    data: VersicherungInput;
    id: Scalars['Int'];
};

export type MutationUpdateWohneinheitArgs = {
    data: WohneinheitInput;
    id: Scalars['Int'];
};

export type Niederlassung = {
    __typename?: 'Niederlassung';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    mitarbeiter?: Maybe<Array<Maybe<Mitarbeiter>>>;
    nummer: Scalars['Int'];
    postleitzahl?: Maybe<Array<Maybe<Postleitzahl>>>;
    projekt?: Maybe<Array<Maybe<Projekt>>>;
};

export type NiederlassungAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type NiederlassungCollection = {
    __typename?: 'NiederlassungCollection';
    error?: Maybe<Error>;
    items: Array<Niederlassung>;
    pageInfo: PageInfo;
};

export type NiederlassungFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    mitarbeiter?: InputMaybe<MitarbeiterFilterInput>;
    nummerFrom?: InputMaybe<Scalars['Float']>;
    nummerTo?: InputMaybe<Scalars['Float']>;
    postleitzahl?: InputMaybe<PostleitzahlFilterInput>;
    projekt?: InputMaybe<ProjektFilterInput>;
};

export type NiederlassungInput = {
    bezeichnung: Scalars['String'];
    nummer: Scalars['Int'];
};

export type NiederlassungItem = {
    __typename?: 'NiederlassungItem';
    error?: Maybe<Error>;
    item?: Maybe<Niederlassung>;
};

export type NiederlassungOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    mitarbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    nummer?: InputMaybe<SortOrder>;
    postleitzahl?: InputMaybe<PostleitzahlOrderByInput>;
    projekt?: InputMaybe<ProjektOrderByInput>;
};

export type Notdienstprojekt = {
    __typename?: 'Notdienstprojekt';
    address: Scalars['String'];
    createdAt: Scalars['AWSDateTime'];
    email: Scalars['String'];
    finishedAt?: Maybe<Scalars['AWSDateTime']>;
    humanid: Scalars['String'];
    id: Scalars['Int'];
    mitarbeiterByEmail?: Maybe<Mitarbeiter>;
    mitarbeiterByStartedBy?: Maybe<Mitarbeiter>;
    mitarbeiterIdByEmail?: Maybe<Scalars['Int']>;
    mitarbeiterIdByStartedBy?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    notes: Scalars['String'];
    startedAt?: Maybe<Scalars['AWSDateTime']>;
    startedByEmail: Scalars['String'];
    status: Scalars['String'];
};

export type NotdienstprojektAutoCompleteFields = {
    address?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    humanid?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    startedByEmail?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
};

export type NotdienstprojektCollection = {
    __typename?: 'NotdienstprojektCollection';
    error?: Maybe<Error>;
    items: Array<Notdienstprojekt>;
    pageInfo: PageInfo;
};

export type NotdienstprojektFilterInput = {
    addressExists?: InputMaybe<Scalars['Boolean']>;
    addressPrefix?: InputMaybe<Scalars['String']>;
    createdAtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    createdAtTo?: InputMaybe<Scalars['AWSDateTime']>;
    emailExists?: InputMaybe<Scalars['Boolean']>;
    emailPrefix?: InputMaybe<Scalars['String']>;
    finishedAtExists?: InputMaybe<Scalars['Boolean']>;
    finishedAtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    finishedAtTo?: InputMaybe<Scalars['AWSDateTime']>;
    humanidExists?: InputMaybe<Scalars['Boolean']>;
    humanidPrefix?: InputMaybe<Scalars['String']>;
    mitarbeiterByEmail?: InputMaybe<MitarbeiterFilterInput>;
    mitarbeiterByStartedBy?: InputMaybe<MitarbeiterFilterInput>;
    mitarbeiterIdByEmail?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdByEmailExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdByStartedBy?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdByStartedByExists?: InputMaybe<Scalars['Boolean']>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    notesExists?: InputMaybe<Scalars['Boolean']>;
    notesPrefix?: InputMaybe<Scalars['String']>;
    startedAtExists?: InputMaybe<Scalars['Boolean']>;
    startedAtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    startedAtTo?: InputMaybe<Scalars['AWSDateTime']>;
    startedByEmailExists?: InputMaybe<Scalars['Boolean']>;
    startedByEmailPrefix?: InputMaybe<Scalars['String']>;
    statusExists?: InputMaybe<Scalars['Boolean']>;
    statusPrefix?: InputMaybe<Scalars['String']>;
};

export type NotdienstprojektInput = {
    address: Scalars['String'];
    createdAt: Scalars['AWSDateTime'];
    email: Scalars['String'];
    finishedAt?: InputMaybe<Scalars['AWSDateTime']>;
    humanid: Scalars['String'];
    mitarbeiterIdByEmail?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdByStartedBy?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    notes: Scalars['String'];
    startedAt?: InputMaybe<Scalars['AWSDateTime']>;
    startedByEmail: Scalars['String'];
    status: Scalars['String'];
};

export type NotdienstprojektItem = {
    __typename?: 'NotdienstprojektItem';
    error?: Maybe<Error>;
    item?: Maybe<Notdienstprojekt>;
};

export type NotdienstprojektOrderByInput = {
    address?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    finishedAt?: InputMaybe<SortOrder>;
    humanid?: InputMaybe<SortOrder>;
    mitarbeiterByEmail?: InputMaybe<MitarbeiterOrderByInput>;
    mitarbeiterByStartedBy?: InputMaybe<MitarbeiterOrderByInput>;
    name?: InputMaybe<SortOrder>;
    notes?: InputMaybe<SortOrder>;
    startedAt?: InputMaybe<SortOrder>;
    startedByEmail?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type OffenePosten = {
    __typename?: 'OffenePosten';
    aufbau: Scalars['String'];
    letzterTermin: Scalars['String'];
    niederlassung: Scalars['String'];
    objekt: Scalars['String'];
    offenerTermin: Scalars['Boolean'];
    projekt: Scalars['String'];
    umsatz: Scalars['Float'];
};

export type OffenePostenCollection = {
    __typename?: 'OffenePostenCollection';
    error?: Maybe<Error>;
    gesamtUmsatz: Scalars['Float'];
    items: Array<OffenePosten>;
    pageInfo: PageInfo;
};

export type OffenePostenOrderByInput = {
    aufbau?: InputMaybe<SortOrder>;
    letzterTermin?: InputMaybe<SortOrder>;
    niederlassung?: InputMaybe<SortOrder>;
    objekt?: InputMaybe<SortOrder>;
    projekt?: InputMaybe<SortOrder>;
    umsatz?: InputMaybe<SortOrder>;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    pageCount: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type Postleitzahl = {
    __typename?: 'Postleitzahl';
    id: Scalars['Int'];
    niederlassung?: Maybe<Niederlassung>;
    niederlassungId?: Maybe<Scalars['Int']>;
    ort: Scalars['String'];
    plz: Scalars['String'];
};

export type PostleitzahlAutoCompleteFields = {
    ort?: InputMaybe<Scalars['String']>;
    plz?: InputMaybe<Scalars['String']>;
};

export type PostleitzahlCollection = {
    __typename?: 'PostleitzahlCollection';
    error?: Maybe<Error>;
    items: Array<Postleitzahl>;
    pageInfo: PageInfo;
};

export type PostleitzahlFilterInput = {
    niederlassung?: InputMaybe<NiederlassungFilterInput>;
    niederlassungId?: InputMaybe<Scalars['Int']>;
    niederlassungIdExists?: InputMaybe<Scalars['Boolean']>;
    ortExists?: InputMaybe<Scalars['Boolean']>;
    ortPrefix?: InputMaybe<Scalars['String']>;
    plzExists?: InputMaybe<Scalars['Boolean']>;
    plzPrefix?: InputMaybe<Scalars['String']>;
};

export type PostleitzahlInput = {
    niederlassungId?: InputMaybe<Scalars['Int']>;
    ort: Scalars['String'];
    plz: Scalars['String'];
};

export type PostleitzahlItem = {
    __typename?: 'PostleitzahlItem';
    error?: Maybe<Error>;
    item?: Maybe<Postleitzahl>;
};

export type PostleitzahlOrderByInput = {
    niederlassung?: InputMaybe<NiederlassungOrderByInput>;
    ort?: InputMaybe<SortOrder>;
    plz?: InputMaybe<SortOrder>;
};

export type Projekt = {
    __typename?: 'Projekt';
    anlageDatum: Scalars['AWSDateTime'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    lfdNr: Scalars['Int'];
    niederlassung?: Maybe<Niederlassung>;
    niederlassungId: Scalars['Int'];
    ort: Scalars['String'];
    plz: Scalars['String'];
    schadenTag?: Maybe<Scalars['AWSDate']>;
    statusSnippet?: Maybe<Snippet>;
    statusSnippetId: Scalars['Int'];
    strasse: Scalars['String'];
    subprojekt?: Maybe<Array<Maybe<Subprojekt>>>;
    version: Scalars['Int'];
    wohneinheit?: Maybe<Array<Maybe<Wohneinheit>>>;
};

export type ProjektAutoCompleteFields = {
    ort?: InputMaybe<Scalars['String']>;
    plz?: InputMaybe<Scalars['String']>;
    strasse?: InputMaybe<Scalars['String']>;
};

export type ProjektCollection = {
    __typename?: 'ProjektCollection';
    error?: Maybe<Error>;
    items: Array<Projekt>;
    pageInfo: PageInfo;
};

export type ProjektFilterInput = {
    anlageDatumFrom?: InputMaybe<Scalars['AWSDateTime']>;
    anlageDatumTo?: InputMaybe<Scalars['AWSDateTime']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    lfdNrFrom?: InputMaybe<Scalars['Float']>;
    lfdNrTo?: InputMaybe<Scalars['Float']>;
    niederlassung?: InputMaybe<NiederlassungFilterInput>;
    niederlassungId?: InputMaybe<Scalars['Int']>;
    ortExists?: InputMaybe<Scalars['Boolean']>;
    ortPrefix?: InputMaybe<Scalars['String']>;
    plzExists?: InputMaybe<Scalars['Boolean']>;
    plzPrefix?: InputMaybe<Scalars['String']>;
    schadenTagExists?: InputMaybe<Scalars['Boolean']>;
    schadenTagFrom?: InputMaybe<Scalars['AWSDate']>;
    schadenTagTo?: InputMaybe<Scalars['AWSDate']>;
    statusSnippet?: InputMaybe<SnippetFilterInput>;
    statusSnippetId?: InputMaybe<Scalars['Int']>;
    strasseExists?: InputMaybe<Scalars['Boolean']>;
    strassePrefix?: InputMaybe<Scalars['String']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    wohneinheit?: InputMaybe<WohneinheitFilterInput>;
};

export type ProjektInput = {
    niederlassungId: Scalars['Int'];
    ort: Scalars['String'];
    plz: Scalars['String'];
    schadenTag?: InputMaybe<Scalars['AWSDate']>;
    statusSnippetId: Scalars['Int'];
    strasse: Scalars['String'];
};

export type ProjektItem = {
    __typename?: 'ProjektItem';
    error?: Maybe<Error>;
    item?: Maybe<Projekt>;
};

export type ProjektOrderByInput = {
    anlageDatum?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    lfdNr?: InputMaybe<SortOrder>;
    niederlassung?: InputMaybe<NiederlassungOrderByInput>;
    ort?: InputMaybe<SortOrder>;
    plz?: InputMaybe<SortOrder>;
    schadenTag?: InputMaybe<SortOrder>;
    statusSnippet?: InputMaybe<SnippetOrderByInput>;
    strasse?: InputMaybe<SortOrder>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    wohneinheit?: InputMaybe<WohneinheitOrderByInput>;
};

export type Projekttyp = {
    __typename?: 'Projekttyp';
    deleted?: Maybe<Scalars['AWSDateTime']>;
    gewerkProjekttyp?: Maybe<Array<Maybe<GewerkProjekttyp>>>;
    id: Scalars['Int'];
    name: Scalars['String'];
    subprojekt?: Maybe<Array<Maybe<Subprojekt>>>;
};

export type ProjekttypAutoCompleteFields = {
    name?: InputMaybe<Scalars['String']>;
};

export type ProjekttypCollection = {
    __typename?: 'ProjekttypCollection';
    error?: Maybe<Error>;
    items: Array<Projekttyp>;
    pageInfo: PageInfo;
};

export type ProjekttypFilterInput = {
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    gewerkProjekttyp?: InputMaybe<GewerkProjekttypFilterInput>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
};

export type ProjekttypInput = {
    name: Scalars['String'];
};

export type ProjekttypItem = {
    __typename?: 'ProjekttypItem';
    error?: Maybe<Error>;
    item?: Maybe<Projekttyp>;
};

export type ProjekttypOrderByInput = {
    deleted?: InputMaybe<SortOrder>;
    gewerkProjekttyp?: InputMaybe<GewerkProjekttypOrderByInput>;
    name?: InputMaybe<SortOrder>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
};

export type Query = {
    __typename?: 'Query';
    abwesenheit: AbwesenheitCollection;
    abwesenheitById: AbwesenheitItem;
    angebot: AngebotCollection;
    angebotById: AngebotItem;
    angebotsposition: AngebotspositionCollection;
    angebotspositionById: AngebotspositionItem;
    app?: Maybe<AppQuery>;
    aufgabe: AufgabeCollection;
    aufgabeById: AufgabeItem;
    aufmass: AufmassCollection;
    aufmassById: AufmassItem;
    autocomplete: Array<Maybe<Scalars['String']>>;
    beteiligter: BeteiligterCollection;
    beteiligterById: BeteiligterItem;
    dokument: DokumentCollection;
    dokumentById: DokumentItem;
    einsatzbericht: EinsatzberichtCollection;
    einsatzberichtById: EinsatzberichtItem;
    einsatzberichtDetail: EinsatzberichtDetailCollection;
    einsatzberichtDetailById: EinsatzberichtDetailItem;
    einsatzberichtGewerk: EinsatzberichtGewerkCollection;
    einsatzberichtGewerkById: EinsatzberichtGewerkItem;
    einsatzberichtModul: EinsatzberichtModulCollection;
    einsatzberichtModulById: EinsatzberichtModulItem;
    einsatzberichtOption: EinsatzberichtOptionCollection;
    einsatzberichtOptionById: EinsatzberichtOptionItem;
    einsatzberichtSachbearbeiter: EinsatzberichtSachbearbeiterCollection;
    einsatzberichtSachbearbeiterById: EinsatzberichtSachbearbeiterItem;
    einsatzberichtTyp: EinsatzberichtTypCollection;
    einsatzberichtTypById: EinsatzberichtTypItem;
    einsatzberichtTypModul: EinsatzberichtTypModulCollection;
    einsatzberichtTypModulById: EinsatzberichtTypModulItem;
    funktion: FunktionCollection;
    funktionById: FunktionItem;
    geraet: GeraetCollection;
    geraetById: GeraetItem;
    geraeteeinsatz: GeraeteeinsatzCollection;
    geraeteeinsatzById: GeraeteeinsatzItem;
    geraetetyp: GeraetetypCollection;
    geraetetypById: GeraetetypItem;
    gewerk: GewerkCollection;
    gewerkById: GewerkItem;
    gewerkProjekttyp: GewerkProjekttypCollection;
    gewerkProjekttypById: GewerkProjekttypItem;
    kalkulationsposition: KalkulationspositionCollection;
    kalkulationspositionById: KalkulationspositionItem;
    kontakt: KontaktCollection;
    kontaktById: KontaktItem;
    korrespondenz: KorrespondenzCollection;
    korrespondenzById: KorrespondenzItem;
    leistung: LeistungCollection;
    leistungById: LeistungItem;
    mitarbeiter: MitarbeiterCollection;
    mitarbeiterById: MitarbeiterItem;
    mitarbeiterFunktion: MitarbeiterFunktionCollection;
    mitarbeiterFunktionById: MitarbeiterFunktionItem;
    niederlassung: NiederlassungCollection;
    niederlassungById: NiederlassungItem;
    notdienstprojekt: NotdienstprojektCollection;
    notdienstprojektById: NotdienstprojektItem;
    postleitzahl: PostleitzahlCollection;
    postleitzahlById: PostleitzahlItem;
    projekt: ProjektCollection;
    projektById: ProjektItem;
    projekttyp: ProjekttypCollection;
    projekttypById: ProjekttypItem;
    rahmenvertrag: RahmenvertragCollection;
    rahmenvertragById: RahmenvertragItem;
    rahmenvertragsposition: RahmenvertragspositionCollection;
    rahmenvertragspositionById: RahmenvertragspositionItem;
    rechnung: RechnungCollection;
    rechnungById: RechnungItem;
    rechnungsposition: RechnungspositionCollection;
    rechnungspositionById: RechnungspositionItem;
    rolle: RolleCollection;
    rolleById: RolleItem;
    snippet: SnippetCollection;
    snippetById: SnippetItem;
    stromzaehler: StromzaehlerCollection;
    stromzaehlerById: StromzaehlerItem;
    subprojekt: SubprojektCollection;
    subprojektById: SubprojektItem;
    subprojektGewerk: SubprojektGewerkCollection;
    subprojektGewerkById: SubprojektGewerkItem;
    subprojektWohneinheit: SubprojektWohneinheitCollection;
    subprojektWohneinheitById: SubprojektWohneinheitItem;
    termin: TerminCollection;
    terminById: TerminItem;
    textbaustein: TextbausteinCollection;
    textbausteinById: TextbausteinItem;
    versicherung: VersicherungCollection;
    versicherungById: VersicherungItem;
    wohneinheit: WohneinheitCollection;
    wohneinheitById: WohneinheitItem;
};

export type QueryAbwesenheitArgs = {
    filter?: InputMaybe<AbwesenheitFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AbwesenheitOrderByInput>>>;
};

export type QueryAbwesenheitByIdArgs = {
    id: Scalars['Int'];
};

export type QueryAngebotArgs = {
    filter?: InputMaybe<AngebotFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AngebotOrderByInput>>>;
};

export type QueryAngebotByIdArgs = {
    id: Scalars['Int'];
};

export type QueryAngebotspositionArgs = {
    filter?: InputMaybe<AngebotspositionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AngebotspositionOrderByInput>>>;
};

export type QueryAngebotspositionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryAufgabeArgs = {
    filter?: InputMaybe<AufgabeFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AufgabeOrderByInput>>>;
};

export type QueryAufgabeByIdArgs = {
    id: Scalars['Int'];
};

export type QueryAufmassArgs = {
    filter?: InputMaybe<AufmassFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AufmassOrderByInput>>>;
};

export type QueryAufmassByIdArgs = {
    id: Scalars['Int'];
};

export type QueryAutocompleteArgs = {
    input: AutocompleteInput;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBeteiligterArgs = {
    filter?: InputMaybe<BeteiligterFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<BeteiligterOrderByInput>>>;
};

export type QueryBeteiligterByIdArgs = {
    id: Scalars['Int'];
};

export type QueryDokumentArgs = {
    filter?: InputMaybe<DokumentFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<DokumentOrderByInput>>>;
};

export type QueryDokumentByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtArgs = {
    filter?: InputMaybe<EinsatzberichtFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtOrderByInput>>>;
};

export type QueryEinsatzberichtByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtDetailArgs = {
    filter?: InputMaybe<EinsatzberichtDetailFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtDetailOrderByInput>>>;
};

export type QueryEinsatzberichtDetailByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtGewerkArgs = {
    filter?: InputMaybe<EinsatzberichtGewerkFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtGewerkOrderByInput>>>;
};

export type QueryEinsatzberichtGewerkByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtModulArgs = {
    filter?: InputMaybe<EinsatzberichtModulFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtModulOrderByInput>>>;
};

export type QueryEinsatzberichtModulByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtOptionArgs = {
    filter?: InputMaybe<EinsatzberichtOptionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtOptionOrderByInput>>>;
};

export type QueryEinsatzberichtOptionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtSachbearbeiterArgs = {
    filter?: InputMaybe<EinsatzberichtSachbearbeiterFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtSachbearbeiterOrderByInput>>>;
};

export type QueryEinsatzberichtSachbearbeiterByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtTypArgs = {
    filter?: InputMaybe<EinsatzberichtTypFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtTypOrderByInput>>>;
};

export type QueryEinsatzberichtTypByIdArgs = {
    id: Scalars['Int'];
};

export type QueryEinsatzberichtTypModulArgs = {
    filter?: InputMaybe<EinsatzberichtTypModulFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<EinsatzberichtTypModulOrderByInput>>>;
};

export type QueryEinsatzberichtTypModulByIdArgs = {
    id: Scalars['Int'];
};

export type QueryFunktionArgs = {
    filter?: InputMaybe<FunktionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<FunktionOrderByInput>>>;
};

export type QueryFunktionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryGeraetArgs = {
    filter?: InputMaybe<GeraetFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GeraetOrderByInput>>>;
};

export type QueryGeraetByIdArgs = {
    id: Scalars['Int'];
};

export type QueryGeraeteeinsatzArgs = {
    filter?: InputMaybe<GeraeteeinsatzFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GeraeteeinsatzOrderByInput>>>;
};

export type QueryGeraeteeinsatzByIdArgs = {
    id: Scalars['Int'];
};

export type QueryGeraetetypArgs = {
    filter?: InputMaybe<GeraetetypFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GeraetetypOrderByInput>>>;
};

export type QueryGeraetetypByIdArgs = {
    id: Scalars['Int'];
};

export type QueryGewerkArgs = {
    filter?: InputMaybe<GewerkFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GewerkOrderByInput>>>;
};

export type QueryGewerkByIdArgs = {
    id: Scalars['Int'];
};

export type QueryGewerkProjekttypArgs = {
    filter?: InputMaybe<GewerkProjekttypFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GewerkProjekttypOrderByInput>>>;
};

export type QueryGewerkProjekttypByIdArgs = {
    id: Scalars['Int'];
};

export type QueryKalkulationspositionArgs = {
    filter?: InputMaybe<KalkulationspositionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<KalkulationspositionOrderByInput>>>;
};

export type QueryKalkulationspositionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryKontaktArgs = {
    filter?: InputMaybe<KontaktFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<KontaktOrderByInput>>>;
};

export type QueryKontaktByIdArgs = {
    id: Scalars['Int'];
};

export type QueryKorrespondenzArgs = {
    filter?: InputMaybe<KorrespondenzFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<KorrespondenzOrderByInput>>>;
};

export type QueryKorrespondenzByIdArgs = {
    id: Scalars['Int'];
};

export type QueryLeistungArgs = {
    filter?: InputMaybe<LeistungFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<LeistungOrderByInput>>>;
};

export type QueryLeistungByIdArgs = {
    id: Scalars['Int'];
};

export type QueryMitarbeiterArgs = {
    filter?: InputMaybe<MitarbeiterFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<MitarbeiterOrderByInput>>>;
};

export type QueryMitarbeiterByIdArgs = {
    id: Scalars['Int'];
};

export type QueryMitarbeiterFunktionArgs = {
    filter?: InputMaybe<MitarbeiterFunktionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<MitarbeiterFunktionOrderByInput>>>;
};

export type QueryMitarbeiterFunktionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryNiederlassungArgs = {
    filter?: InputMaybe<NiederlassungFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<NiederlassungOrderByInput>>>;
};

export type QueryNiederlassungByIdArgs = {
    id: Scalars['Int'];
};

export type QueryNotdienstprojektArgs = {
    filter?: InputMaybe<NotdienstprojektFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<NotdienstprojektOrderByInput>>>;
};

export type QueryNotdienstprojektByIdArgs = {
    id: Scalars['Int'];
};

export type QueryPostleitzahlArgs = {
    filter?: InputMaybe<PostleitzahlFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<PostleitzahlOrderByInput>>>;
};

export type QueryPostleitzahlByIdArgs = {
    id: Scalars['Int'];
};

export type QueryProjektArgs = {
    filter?: InputMaybe<ProjektFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<ProjektOrderByInput>>>;
};

export type QueryProjektByIdArgs = {
    id: Scalars['Int'];
};

export type QueryProjekttypArgs = {
    filter?: InputMaybe<ProjekttypFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<ProjekttypOrderByInput>>>;
};

export type QueryProjekttypByIdArgs = {
    id: Scalars['Int'];
};

export type QueryRahmenvertragArgs = {
    filter?: InputMaybe<RahmenvertragFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<RahmenvertragOrderByInput>>>;
};

export type QueryRahmenvertragByIdArgs = {
    id: Scalars['Int'];
};

export type QueryRahmenvertragspositionArgs = {
    filter?: InputMaybe<RahmenvertragspositionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<RahmenvertragspositionOrderByInput>>>;
};

export type QueryRahmenvertragspositionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryRechnungArgs = {
    filter?: InputMaybe<RechnungFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<RechnungOrderByInput>>>;
};

export type QueryRechnungByIdArgs = {
    id: Scalars['Int'];
};

export type QueryRechnungspositionArgs = {
    filter?: InputMaybe<RechnungspositionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<RechnungspositionOrderByInput>>>;
};

export type QueryRechnungspositionByIdArgs = {
    id: Scalars['Int'];
};

export type QueryRolleArgs = {
    filter?: InputMaybe<RolleFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<RolleOrderByInput>>>;
};

export type QueryRolleByIdArgs = {
    id: Scalars['Int'];
};

export type QuerySnippetArgs = {
    filter?: InputMaybe<SnippetFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<SnippetOrderByInput>>>;
};

export type QuerySnippetByIdArgs = {
    id: Scalars['Int'];
};

export type QueryStromzaehlerArgs = {
    filter?: InputMaybe<StromzaehlerFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<StromzaehlerOrderByInput>>>;
};

export type QueryStromzaehlerByIdArgs = {
    id: Scalars['Int'];
};

export type QuerySubprojektArgs = {
    filter?: InputMaybe<SubprojektFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<SubprojektOrderByInput>>>;
};

export type QuerySubprojektByIdArgs = {
    id: Scalars['Int'];
};

export type QuerySubprojektGewerkArgs = {
    filter?: InputMaybe<SubprojektGewerkFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<SubprojektGewerkOrderByInput>>>;
};

export type QuerySubprojektGewerkByIdArgs = {
    id: Scalars['Int'];
};

export type QuerySubprojektWohneinheitArgs = {
    filter?: InputMaybe<SubprojektWohneinheitFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<SubprojektWohneinheitOrderByInput>>>;
};

export type QuerySubprojektWohneinheitByIdArgs = {
    id: Scalars['Int'];
};

export type QueryTerminArgs = {
    filter?: InputMaybe<TerminFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<TerminOrderByInput>>>;
};

export type QueryTerminByIdArgs = {
    id: Scalars['Int'];
};

export type QueryTextbausteinArgs = {
    filter?: InputMaybe<TextbausteinFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<TextbausteinOrderByInput>>>;
};

export type QueryTextbausteinByIdArgs = {
    id: Scalars['Int'];
};

export type QueryVersicherungArgs = {
    filter?: InputMaybe<VersicherungFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<VersicherungOrderByInput>>>;
};

export type QueryVersicherungByIdArgs = {
    id: Scalars['Int'];
};

export type QueryWohneinheitArgs = {
    filter?: InputMaybe<WohneinheitFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<WohneinheitOrderByInput>>>;
};

export type QueryWohneinheitByIdArgs = {
    id: Scalars['Int'];
};

export type Rahmenvertrag = {
    __typename?: 'Rahmenvertrag';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    gueltigAb?: Maybe<Scalars['AWSDate']>;
    gueltigBis?: Maybe<Scalars['AWSDate']>;
    id: Scalars['Int'];
    isStandard: Scalars['Boolean'];
    rahmenvertragsposition?: Maybe<Array<Maybe<Rahmenvertragsposition>>>;
    subprojekt?: Maybe<Array<Maybe<Subprojekt>>>;
};

export type RahmenvertragAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type RahmenvertragCollection = {
    __typename?: 'RahmenvertragCollection';
    error?: Maybe<Error>;
    items: Array<Rahmenvertrag>;
    pageInfo: PageInfo;
};

export type RahmenvertragFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    gueltigAbExists?: InputMaybe<Scalars['Boolean']>;
    gueltigAbFrom?: InputMaybe<Scalars['AWSDate']>;
    gueltigAbTo?: InputMaybe<Scalars['AWSDate']>;
    gueltigBisExists?: InputMaybe<Scalars['Boolean']>;
    gueltigBisFrom?: InputMaybe<Scalars['AWSDate']>;
    gueltigBisTo?: InputMaybe<Scalars['AWSDate']>;
    isStandard?: InputMaybe<Scalars['Boolean']>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionFilterInput>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
};

export type RahmenvertragInput = {
    bezeichnung: Scalars['String'];
    gueltigAb?: InputMaybe<Scalars['AWSDate']>;
    gueltigBis?: InputMaybe<Scalars['AWSDate']>;
    isStandard: Scalars['Boolean'];
};

export type RahmenvertragItem = {
    __typename?: 'RahmenvertragItem';
    error?: Maybe<Error>;
    item?: Maybe<Rahmenvertrag>;
};

export type RahmenvertragOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    gueltigAb?: InputMaybe<SortOrder>;
    gueltigBis?: InputMaybe<SortOrder>;
    isStandard?: InputMaybe<SortOrder>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
};

export type Rahmenvertragsposition = {
    __typename?: 'Rahmenvertragsposition';
    angebotsposition?: Maybe<Array<Maybe<Angebotsposition>>>;
    beschreibung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    details: Scalars['String'];
    id: Scalars['Int'];
    kalkulationsposition?: Maybe<Array<Maybe<Kalkulationsposition>>>;
    leistung?: Maybe<Leistung>;
    leistungId: Scalars['Int'];
    nr: Scalars['String'];
    preisProEinheit: Scalars['Float'];
    rahmenvertrag?: Maybe<Rahmenvertrag>;
    rahmenvertragId: Scalars['Int'];
    rechnungsposition?: Maybe<Array<Maybe<Rechnungsposition>>>;
};

export type RahmenvertragspositionAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
    details?: InputMaybe<Scalars['String']>;
    nr?: InputMaybe<Scalars['String']>;
};

export type RahmenvertragspositionCollection = {
    __typename?: 'RahmenvertragspositionCollection';
    error?: Maybe<Error>;
    items: Array<Rahmenvertragsposition>;
    pageInfo: PageInfo;
};

export type RahmenvertragspositionFilterInput = {
    angebotsposition?: InputMaybe<AngebotspositionFilterInput>;
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    detailsExists?: InputMaybe<Scalars['Boolean']>;
    detailsPrefix?: InputMaybe<Scalars['String']>;
    kalkulationsposition?: InputMaybe<KalkulationspositionFilterInput>;
    leistung?: InputMaybe<LeistungFilterInput>;
    leistungId?: InputMaybe<Scalars['Int']>;
    nrExists?: InputMaybe<Scalars['Boolean']>;
    nrPrefix?: InputMaybe<Scalars['String']>;
    preisProEinheitFrom?: InputMaybe<Scalars['Float']>;
    preisProEinheitTo?: InputMaybe<Scalars['Float']>;
    rahmenvertrag?: InputMaybe<RahmenvertragFilterInput>;
    rahmenvertragId?: InputMaybe<Scalars['Int']>;
    rechnungsposition?: InputMaybe<RechnungspositionFilterInput>;
};

export type RahmenvertragspositionInput = {
    beschreibung: Scalars['String'];
    details: Scalars['String'];
    leistungId: Scalars['Int'];
    nr: Scalars['String'];
    preisProEinheit: Scalars['Float'];
    rahmenvertragId: Scalars['Int'];
};

export type RahmenvertragspositionItem = {
    __typename?: 'RahmenvertragspositionItem';
    error?: Maybe<Error>;
    item?: Maybe<Rahmenvertragsposition>;
};

export type RahmenvertragspositionOrderByInput = {
    angebotsposition?: InputMaybe<AngebotspositionOrderByInput>;
    beschreibung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    details?: InputMaybe<SortOrder>;
    kalkulationsposition?: InputMaybe<KalkulationspositionOrderByInput>;
    leistung?: InputMaybe<LeistungOrderByInput>;
    nr?: InputMaybe<SortOrder>;
    preisProEinheit?: InputMaybe<SortOrder>;
    rahmenvertrag?: InputMaybe<RahmenvertragOrderByInput>;
    rechnungsposition?: InputMaybe<RechnungspositionOrderByInput>;
};

export type Rechnung = {
    __typename?: 'Rechnung';
    datum: Scalars['AWSDate'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    dokument?: Maybe<Array<Maybe<Dokument>>>;
    freitext: Richtext;
    freitextFooter: Richtext;
    gesamtpreisNetto: Scalars['Float'];
    id: Scalars['Int'];
    mitarbeiterIdProjektleitung?: Maybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: Maybe<Scalars['Int']>;
    mwstSatz: Scalars['Float'];
    nummer: Scalars['String'];
    projektleitung?: Maybe<Mitarbeiter>;
    rabatt: Scalars['String'];
    rechnungspositionen?: Maybe<Array<Maybe<Rechnungsposition>>>;
    sachbearbeiter?: Maybe<Mitarbeiter>;
    statusSnippet?: Maybe<Snippet>;
    statusSnippetId: Scalars['Int'];
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
};

export type RechnungAutoCompleteFields = {
    freitext?: InputMaybe<Scalars['String']>;
    freitextFooter?: InputMaybe<Scalars['String']>;
    nummer?: InputMaybe<Scalars['String']>;
    rabatt?: InputMaybe<Scalars['String']>;
};

export type RechnungCollection = {
    __typename?: 'RechnungCollection';
    error?: Maybe<Error>;
    items: Array<Rechnung>;
    pageInfo: PageInfo;
};

export type RechnungFilterInput = {
    datumFrom?: InputMaybe<Scalars['AWSDate']>;
    datumTo?: InputMaybe<Scalars['AWSDate']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    dokument?: InputMaybe<DokumentFilterInput>;
    freitextExists?: InputMaybe<Scalars['Boolean']>;
    freitextFooterExists?: InputMaybe<Scalars['Boolean']>;
    freitextFooterPrefix?: InputMaybe<Scalars['String']>;
    freitextPrefix?: InputMaybe<Scalars['String']>;
    gesamtpreisNettoFrom?: InputMaybe<Scalars['Float']>;
    gesamtpreisNettoTo?: InputMaybe<Scalars['Float']>;
    mitarbeiterIdProjektleitung?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdProjektleitungExists?: InputMaybe<Scalars['Boolean']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiterExists?: InputMaybe<Scalars['Boolean']>;
    mwstSatzFrom?: InputMaybe<Scalars['Float']>;
    mwstSatzTo?: InputMaybe<Scalars['Float']>;
    nummerExists?: InputMaybe<Scalars['Boolean']>;
    nummerPrefix?: InputMaybe<Scalars['String']>;
    projektleitung?: InputMaybe<MitarbeiterFilterInput>;
    rabattExists?: InputMaybe<Scalars['Boolean']>;
    rabattPrefix?: InputMaybe<Scalars['String']>;
    rechnungspositionen?: InputMaybe<RechnungspositionFilterInput>;
    sachbearbeiter?: InputMaybe<MitarbeiterFilterInput>;
    statusSnippet?: InputMaybe<SnippetFilterInput>;
    statusSnippetId?: InputMaybe<Scalars['Int']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
};

export type RechnungInput = {
    datum: Scalars['AWSDate'];
    freitext: Scalars['AWSJSON'];
    freitextFooter: Scalars['AWSJSON'];
    gesamtpreisNetto: Scalars['Float'];
    mitarbeiterIdProjektleitung?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mwstSatz: Scalars['Float'];
    nummer: Scalars['String'];
    rabatt: Scalars['String'];
    statusSnippetId: Scalars['Int'];
    subprojektId: Scalars['Int'];
};

export type RechnungItem = {
    __typename?: 'RechnungItem';
    error?: Maybe<Error>;
    item?: Maybe<Rechnung>;
};

export type RechnungOrderByInput = {
    datum?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    dokument?: InputMaybe<DokumentOrderByInput>;
    freitext?: InputMaybe<SortOrder>;
    freitextFooter?: InputMaybe<SortOrder>;
    gesamtpreisNetto?: InputMaybe<SortOrder>;
    mwstSatz?: InputMaybe<SortOrder>;
    nummer?: InputMaybe<SortOrder>;
    projektleitung?: InputMaybe<MitarbeiterOrderByInput>;
    rabatt?: InputMaybe<SortOrder>;
    rechnungspositionen?: InputMaybe<RechnungspositionOrderByInput>;
    sachbearbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    statusSnippet?: InputMaybe<SnippetOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
};

export type Rechnungsposition = {
    __typename?: 'Rechnungsposition';
    beschreibung: Scalars['String'];
    einheit: Scalars['String'];
    einzelpreis: Scalars['Float'];
    id: Scalars['Int'];
    lfdNr: Scalars['String'];
    menge: Scalars['Float'];
    rahmenvertragsposition?: Maybe<Rahmenvertragsposition>;
    rahmenvertragspositionId?: Maybe<Scalars['Int']>;
    rechnung?: Maybe<Rechnung>;
    rechnungId: Scalars['Int'];
    subprojektWohneinheit?: Maybe<SubprojektWohneinheit>;
    subprojektWohneinheitId?: Maybe<Scalars['Int']>;
};

export type RechnungspositionAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
    einheit?: InputMaybe<Scalars['String']>;
    lfdNr?: InputMaybe<Scalars['String']>;
};

export type RechnungspositionCollection = {
    __typename?: 'RechnungspositionCollection';
    error?: Maybe<Error>;
    items: Array<Rechnungsposition>;
    pageInfo: PageInfo;
};

export type RechnungspositionFilterInput = {
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    einheitExists?: InputMaybe<Scalars['Boolean']>;
    einheitPrefix?: InputMaybe<Scalars['String']>;
    einzelpreisFrom?: InputMaybe<Scalars['Float']>;
    einzelpreisTo?: InputMaybe<Scalars['Float']>;
    lfdNrExists?: InputMaybe<Scalars['Boolean']>;
    lfdNrPrefix?: InputMaybe<Scalars['String']>;
    mengeFrom?: InputMaybe<Scalars['Float']>;
    mengeTo?: InputMaybe<Scalars['Float']>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionFilterInput>;
    rahmenvertragspositionId?: InputMaybe<Scalars['Int']>;
    rahmenvertragspositionIdExists?: InputMaybe<Scalars['Boolean']>;
    rechnung?: InputMaybe<RechnungFilterInput>;
    rechnungId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheitIdExists?: InputMaybe<Scalars['Boolean']>;
};

export type RechnungspositionInput = {
    beschreibung: Scalars['String'];
    einheit: Scalars['String'];
    einzelpreis: Scalars['Float'];
    lfdNr: Scalars['String'];
    menge: Scalars['Float'];
    rahmenvertragspositionId?: InputMaybe<Scalars['Int']>;
    rechnungId: Scalars['Int'];
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
};

export type RechnungspositionItem = {
    __typename?: 'RechnungspositionItem';
    error?: Maybe<Error>;
    item?: Maybe<Rechnungsposition>;
};

export type RechnungspositionOrderByInput = {
    beschreibung?: InputMaybe<SortOrder>;
    einheit?: InputMaybe<SortOrder>;
    einzelpreis?: InputMaybe<SortOrder>;
    lfdNr?: InputMaybe<SortOrder>;
    menge?: InputMaybe<SortOrder>;
    rahmenvertragsposition?: InputMaybe<RahmenvertragspositionOrderByInput>;
    rechnung?: InputMaybe<RechnungOrderByInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
};

export type Richtext = {
    __typename?: 'Richtext';
    html: Scalars['String'];
    raw: Scalars['AWSJSON'];
};

export type Rolle = {
    __typename?: 'Rolle';
    aufgabe?: Maybe<Array<Maybe<Aufgabe>>>;
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    mitarbeiter?: Maybe<Array<Maybe<Mitarbeiter>>>;
};

export type RolleAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type RolleCollection = {
    __typename?: 'RolleCollection';
    error?: Maybe<Error>;
    items: Array<Rolle>;
    pageInfo: PageInfo;
};

export type RolleFilterInput = {
    aufgabe?: InputMaybe<AufgabeFilterInput>;
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    mitarbeiter?: InputMaybe<MitarbeiterFilterInput>;
};

export type RolleInput = {
    bezeichnung: Scalars['String'];
};

export type RolleItem = {
    __typename?: 'RolleItem';
    error?: Maybe<Error>;
    item?: Maybe<Rolle>;
};

export type RolleOrderByInput = {
    aufgabe?: InputMaybe<AufgabeOrderByInput>;
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    mitarbeiter?: InputMaybe<MitarbeiterOrderByInput>;
};

export type Snippet = {
    __typename?: 'Snippet';
    abwesenheitAsTyp?: Maybe<Array<Maybe<Abwesenheit>>>;
    angebotAsStatus?: Maybe<Array<Maybe<Angebot>>>;
    aufgabeAsTyp?: Maybe<Array<Maybe<Aufgabe>>>;
    aufmassAsTyp?: Maybe<Array<Maybe<Aufmass>>>;
    bearbeitbar: Scalars['Boolean'];
    beteiligterAsTyp?: Maybe<Array<Maybe<Beteiligter>>>;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    dokumentAsTyp?: Maybe<Array<Maybe<Dokument>>>;
    eigenschaft: Scalars['String'];
    geraeteeinsatzAsBerechnungsmethode?: Maybe<Array<Maybe<Geraeteeinsatz>>>;
    id: Scalars['Int'];
    kategorie: Scalars['String'];
    kontaktAsAnrede?: Maybe<Array<Maybe<Kontakt>>>;
    kontaktAsLand?: Maybe<Array<Maybe<Kontakt>>>;
    kontaktAsStatus?: Maybe<Array<Maybe<Kontakt>>>;
    kontaktAsTyp?: Maybe<Array<Maybe<Kontakt>>>;
    korrespondenzAsTyp?: Maybe<Array<Maybe<Korrespondenz>>>;
    kuerzel: Scalars['String'];
    leistungAsTyp?: Maybe<Array<Maybe<Leistung>>>;
    metadata: Scalars['String'];
    name: Scalars['String'];
    projektAsStatus?: Maybe<Array<Maybe<Projekt>>>;
    rechnungAsStatus?: Maybe<Array<Maybe<Rechnung>>>;
    stromzaehlerAsTyp?: Maybe<Array<Maybe<Stromzaehler>>>;
    subprojektAsBonitaet?: Maybe<Array<Maybe<Subprojekt>>>;
    subprojektAsStatus?: Maybe<Array<Maybe<Subprojekt>>>;
    subprojektAsVersicherungsArt?: Maybe<Array<Maybe<Subprojekt>>>;
    terminAsTyp?: Maybe<Array<Maybe<Termin>>>;
};

export type SnippetAutoCompleteFields = {
    eigenschaft?: InputMaybe<Scalars['String']>;
    kategorie?: InputMaybe<Scalars['String']>;
    kuerzel?: InputMaybe<Scalars['String']>;
    metadata?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type SnippetCollection = {
    __typename?: 'SnippetCollection';
    error?: Maybe<Error>;
    items: Array<Snippet>;
    pageInfo: PageInfo;
};

export type SnippetFilterInput = {
    abwesenheitAsTyp?: InputMaybe<AbwesenheitFilterInput>;
    angebotAsStatus?: InputMaybe<AngebotFilterInput>;
    aufgabeAsTyp?: InputMaybe<AufgabeFilterInput>;
    aufmassAsTyp?: InputMaybe<AufmassFilterInput>;
    bearbeitbar?: InputMaybe<Scalars['Boolean']>;
    beteiligterAsTyp?: InputMaybe<BeteiligterFilterInput>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    dokumentAsTyp?: InputMaybe<DokumentFilterInput>;
    eigenschaftExists?: InputMaybe<Scalars['Boolean']>;
    eigenschaftPrefix?: InputMaybe<Scalars['String']>;
    geraeteeinsatzAsBerechnungsmethode?: InputMaybe<GeraeteeinsatzFilterInput>;
    kategorieExists?: InputMaybe<Scalars['Boolean']>;
    kategoriePrefix?: InputMaybe<Scalars['String']>;
    kontaktAsAnrede?: InputMaybe<KontaktFilterInput>;
    kontaktAsLand?: InputMaybe<KontaktFilterInput>;
    kontaktAsStatus?: InputMaybe<KontaktFilterInput>;
    kontaktAsTyp?: InputMaybe<KontaktFilterInput>;
    korrespondenzAsTyp?: InputMaybe<KorrespondenzFilterInput>;
    kuerzelExists?: InputMaybe<Scalars['Boolean']>;
    kuerzelPrefix?: InputMaybe<Scalars['String']>;
    leistungAsTyp?: InputMaybe<LeistungFilterInput>;
    metadataExists?: InputMaybe<Scalars['Boolean']>;
    metadataPrefix?: InputMaybe<Scalars['String']>;
    nameExists?: InputMaybe<Scalars['Boolean']>;
    namePrefix?: InputMaybe<Scalars['String']>;
    projektAsStatus?: InputMaybe<ProjektFilterInput>;
    rechnungAsStatus?: InputMaybe<RechnungFilterInput>;
    stromzaehlerAsTyp?: InputMaybe<StromzaehlerFilterInput>;
    subprojektAsBonitaet?: InputMaybe<SubprojektFilterInput>;
    subprojektAsStatus?: InputMaybe<SubprojektFilterInput>;
    subprojektAsVersicherungsArt?: InputMaybe<SubprojektFilterInput>;
    terminAsTyp?: InputMaybe<TerminFilterInput>;
};

export type SnippetInput = {
    eigenschaft: Scalars['String'];
    kategorie: Scalars['String'];
    kuerzel: Scalars['String'];
    name: Scalars['String'];
};

export type SnippetItem = {
    __typename?: 'SnippetItem';
    error?: Maybe<Error>;
    item?: Maybe<Snippet>;
};

export type SnippetOrderByInput = {
    abwesenheitAsTyp?: InputMaybe<AbwesenheitOrderByInput>;
    angebotAsStatus?: InputMaybe<AngebotOrderByInput>;
    aufgabeAsTyp?: InputMaybe<AufgabeOrderByInput>;
    aufmassAsTyp?: InputMaybe<AufmassOrderByInput>;
    bearbeitbar?: InputMaybe<SortOrder>;
    beteiligterAsTyp?: InputMaybe<BeteiligterOrderByInput>;
    deleted?: InputMaybe<SortOrder>;
    dokumentAsTyp?: InputMaybe<DokumentOrderByInput>;
    eigenschaft?: InputMaybe<SortOrder>;
    geraeteeinsatzAsBerechnungsmethode?: InputMaybe<GeraeteeinsatzOrderByInput>;
    kategorie?: InputMaybe<SortOrder>;
    kontaktAsAnrede?: InputMaybe<KontaktOrderByInput>;
    kontaktAsLand?: InputMaybe<KontaktOrderByInput>;
    kontaktAsStatus?: InputMaybe<KontaktOrderByInput>;
    kontaktAsTyp?: InputMaybe<KontaktOrderByInput>;
    korrespondenzAsTyp?: InputMaybe<KorrespondenzOrderByInput>;
    kuerzel?: InputMaybe<SortOrder>;
    leistungAsTyp?: InputMaybe<LeistungOrderByInput>;
    metadata?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projektAsStatus?: InputMaybe<ProjektOrderByInput>;
    rechnungAsStatus?: InputMaybe<RechnungOrderByInput>;
    stromzaehlerAsTyp?: InputMaybe<StromzaehlerOrderByInput>;
    subprojektAsBonitaet?: InputMaybe<SubprojektOrderByInput>;
    subprojektAsStatus?: InputMaybe<SubprojektOrderByInput>;
    subprojektAsVersicherungsArt?: InputMaybe<SubprojektOrderByInput>;
    terminAsTyp?: InputMaybe<TerminOrderByInput>;
};

export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export type Stromzaehler = {
    __typename?: 'Stromzaehler';
    abrechnen: Scalars['Boolean'];
    beginnTag?: Maybe<Scalars['AWSDateTime']>;
    beginnWert: Scalars['Float'];
    endeTag?: Maybe<Scalars['AWSDateTime']>;
    endeWert?: Maybe<Scalars['Float']>;
    id: Scalars['Int'];
    nummer: Scalars['String'];
    subprojektWohneinheit?: Maybe<SubprojektWohneinheit>;
    subprojektWohneinheitId: Scalars['Int'];
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
    wattroAssetId?: Maybe<Scalars['Int']>;
    wattroId?: Maybe<Scalars['Int']>;
};

export type StromzaehlerAutoCompleteFields = {
    nummer?: InputMaybe<Scalars['String']>;
};

export type StromzaehlerCollection = {
    __typename?: 'StromzaehlerCollection';
    error?: Maybe<Error>;
    items: Array<Stromzaehler>;
    pageInfo: PageInfo;
};

export type StromzaehlerFilterInput = {
    abrechnen?: InputMaybe<Scalars['Boolean']>;
    beginnTagExists?: InputMaybe<Scalars['Boolean']>;
    beginnTagFrom?: InputMaybe<Scalars['AWSDateTime']>;
    beginnTagTo?: InputMaybe<Scalars['AWSDateTime']>;
    beginnWertFrom?: InputMaybe<Scalars['Float']>;
    beginnWertTo?: InputMaybe<Scalars['Float']>;
    endeTagExists?: InputMaybe<Scalars['Boolean']>;
    endeTagFrom?: InputMaybe<Scalars['AWSDateTime']>;
    endeTagTo?: InputMaybe<Scalars['AWSDateTime']>;
    endeWertExists?: InputMaybe<Scalars['Boolean']>;
    endeWertFrom?: InputMaybe<Scalars['Float']>;
    endeWertTo?: InputMaybe<Scalars['Float']>;
    nummerExists?: InputMaybe<Scalars['Boolean']>;
    nummerPrefix?: InputMaybe<Scalars['String']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    subprojektWohneinheitId?: InputMaybe<Scalars['Int']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
    wattroAssetId?: InputMaybe<Scalars['Int']>;
    wattroAssetIdExists?: InputMaybe<Scalars['Boolean']>;
    wattroId?: InputMaybe<Scalars['Int']>;
    wattroIdExists?: InputMaybe<Scalars['Boolean']>;
};

export type StromzaehlerInput = {
    abrechnen: Scalars['Boolean'];
    beginnTag?: InputMaybe<Scalars['AWSDateTime']>;
    beginnWert: Scalars['Float'];
    endeTag?: InputMaybe<Scalars['AWSDateTime']>;
    endeWert?: InputMaybe<Scalars['Float']>;
    nummer: Scalars['String'];
    subprojektWohneinheitId: Scalars['Int'];
    typSnippetId: Scalars['Int'];
    wattroAssetId?: InputMaybe<Scalars['Int']>;
    wattroId?: InputMaybe<Scalars['Int']>;
};

export type StromzaehlerItem = {
    __typename?: 'StromzaehlerItem';
    error?: Maybe<Error>;
    item?: Maybe<Stromzaehler>;
};

export type StromzaehlerOrderByInput = {
    abrechnen?: InputMaybe<SortOrder>;
    beginnTag?: InputMaybe<SortOrder>;
    beginnWert?: InputMaybe<SortOrder>;
    endeTag?: InputMaybe<SortOrder>;
    endeWert?: InputMaybe<SortOrder>;
    nummer?: InputMaybe<SortOrder>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
};

export type Subprojekt = {
    __typename?: 'Subprojekt';
    abrechnungsDatum?: Maybe<Scalars['AWSDate']>;
    angebote?: Maybe<Array<Maybe<Angebot>>>;
    anlageDatum: Scalars['AWSDateTime'];
    aufgaben?: Maybe<Array<Maybe<Aufgabe>>>;
    auftragsNr: Scalars['String'];
    bemerkung: Richtext;
    bemerkungAufmass: Scalars['String'];
    beteiligte?: Maybe<Array<Maybe<Beteiligter>>>;
    bonitaetSnippet?: Maybe<Snippet>;
    bonitaetSnippetId: Scalars['Int'];
    buergschaft: Scalars['Float'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    dokumente?: Maybe<Array<Maybe<Dokument>>>;
    einsatzbericht?: Maybe<Array<Maybe<Einsatzbericht>>>;
    id: Scalars['Int'];
    ihrZeichen: Scalars['String'];
    intern: Scalars['Boolean'];
    korrespondenzen?: Maybe<Array<Maybe<Korrespondenz>>>;
    lfdNr: Scalars['Int'];
    notdienst: Scalars['Boolean'];
    projekt?: Maybe<Projekt>;
    projektId: Scalars['Int'];
    projekttyp?: Maybe<Projekttyp>;
    projekttypId: Scalars['Int'];
    rahmenvertrag?: Maybe<Rahmenvertrag>;
    rahmenvertragId?: Maybe<Scalars['Int']>;
    rechnungen?: Maybe<Array<Maybe<Rechnung>>>;
    schadenNr: Scalars['String'];
    scheinNr: Scalars['String'];
    statusSnippet?: Maybe<Snippet>;
    statusSnippetId: Scalars['Int'];
    subprojektGewerk?: Maybe<Array<Maybe<SubprojektGewerk>>>;
    subprojektWohneinheit?: Maybe<Array<Maybe<SubprojektWohneinheit>>>;
    termine?: Maybe<Array<Maybe<Termin>>>;
    umsatzPrognose?: Maybe<Scalars['Float']>;
    versicherung?: Maybe<Versicherung>;
    versicherungId?: Maybe<Scalars['Int']>;
    versicherungsArtSnippet?: Maybe<Snippet>;
    versicherungsArtSnippetId?: Maybe<Scalars['Int']>;
    version: Scalars['Int'];
    weg: Scalars['String'];
};

export type SubprojektAutoCompleteFields = {
    auftragsNr?: InputMaybe<Scalars['String']>;
    bemerkung?: InputMaybe<Scalars['String']>;
    bemerkungAufmass?: InputMaybe<Scalars['String']>;
    ihrZeichen?: InputMaybe<Scalars['String']>;
    schadenNr?: InputMaybe<Scalars['String']>;
    scheinNr?: InputMaybe<Scalars['String']>;
    weg?: InputMaybe<Scalars['String']>;
};

export type SubprojektCollection = {
    __typename?: 'SubprojektCollection';
    error?: Maybe<Error>;
    items: Array<Subprojekt>;
    pageInfo: PageInfo;
};

export type SubprojektFilterInput = {
    abrechnungsDatumExists?: InputMaybe<Scalars['Boolean']>;
    abrechnungsDatumFrom?: InputMaybe<Scalars['AWSDate']>;
    abrechnungsDatumTo?: InputMaybe<Scalars['AWSDate']>;
    angebote?: InputMaybe<AngebotFilterInput>;
    anlageDatumFrom?: InputMaybe<Scalars['AWSDateTime']>;
    anlageDatumTo?: InputMaybe<Scalars['AWSDateTime']>;
    aufgaben?: InputMaybe<AufgabeFilterInput>;
    auftragsNrExists?: InputMaybe<Scalars['Boolean']>;
    auftragsNrPrefix?: InputMaybe<Scalars['String']>;
    bemerkungAufmassExists?: InputMaybe<Scalars['Boolean']>;
    bemerkungAufmassPrefix?: InputMaybe<Scalars['String']>;
    bemerkungExists?: InputMaybe<Scalars['Boolean']>;
    bemerkungPrefix?: InputMaybe<Scalars['String']>;
    beteiligte?: InputMaybe<BeteiligterFilterInput>;
    bonitaetSnippet?: InputMaybe<SnippetFilterInput>;
    bonitaetSnippetId?: InputMaybe<Scalars['Int']>;
    buergschaftFrom?: InputMaybe<Scalars['Float']>;
    buergschaftTo?: InputMaybe<Scalars['Float']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    dokumente?: InputMaybe<DokumentFilterInput>;
    einsatzbericht?: InputMaybe<EinsatzberichtFilterInput>;
    ihrZeichenExists?: InputMaybe<Scalars['Boolean']>;
    ihrZeichenPrefix?: InputMaybe<Scalars['String']>;
    intern?: InputMaybe<Scalars['Boolean']>;
    korrespondenzen?: InputMaybe<KorrespondenzFilterInput>;
    lfdNrFrom?: InputMaybe<Scalars['Float']>;
    lfdNrTo?: InputMaybe<Scalars['Float']>;
    notdienst?: InputMaybe<Scalars['Boolean']>;
    projekt?: InputMaybe<ProjektFilterInput>;
    projektId?: InputMaybe<Scalars['Int']>;
    projekttyp?: InputMaybe<ProjekttypFilterInput>;
    projekttypId?: InputMaybe<Scalars['Int']>;
    rahmenvertrag?: InputMaybe<RahmenvertragFilterInput>;
    rahmenvertragId?: InputMaybe<Scalars['Int']>;
    rahmenvertragIdExists?: InputMaybe<Scalars['Boolean']>;
    rechnungen?: InputMaybe<RechnungFilterInput>;
    schadenNrExists?: InputMaybe<Scalars['Boolean']>;
    schadenNrPrefix?: InputMaybe<Scalars['String']>;
    scheinNrExists?: InputMaybe<Scalars['Boolean']>;
    scheinNrPrefix?: InputMaybe<Scalars['String']>;
    statusSnippet?: InputMaybe<SnippetFilterInput>;
    statusSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektGewerk?: InputMaybe<SubprojektGewerkFilterInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
    termine?: InputMaybe<TerminFilterInput>;
    umsatzPrognoseExists?: InputMaybe<Scalars['Boolean']>;
    umsatzPrognoseFrom?: InputMaybe<Scalars['Float']>;
    umsatzPrognoseTo?: InputMaybe<Scalars['Float']>;
    versicherung?: InputMaybe<VersicherungFilterInput>;
    versicherungId?: InputMaybe<Scalars['Int']>;
    versicherungIdExists?: InputMaybe<Scalars['Boolean']>;
    versicherungsArtSnippet?: InputMaybe<SnippetFilterInput>;
    versicherungsArtSnippetId?: InputMaybe<Scalars['Int']>;
    versicherungsArtSnippetIdExists?: InputMaybe<Scalars['Boolean']>;
    wegExists?: InputMaybe<Scalars['Boolean']>;
    wegPrefix?: InputMaybe<Scalars['String']>;
};

export type SubprojektGewerk = {
    __typename?: 'SubprojektGewerk';
    gewerk?: Maybe<Gewerk>;
    gewerkId: Scalars['Int'];
    id: Scalars['Int'];
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
};

export type SubprojektGewerkCollection = {
    __typename?: 'SubprojektGewerkCollection';
    error?: Maybe<Error>;
    items: Array<SubprojektGewerk>;
    pageInfo: PageInfo;
};

export type SubprojektGewerkFilterInput = {
    gewerk?: InputMaybe<GewerkFilterInput>;
    gewerkId?: InputMaybe<Scalars['Int']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
};

export type SubprojektGewerkInput = {
    gewerkId: Scalars['Int'];
    subprojektId: Scalars['Int'];
};

export type SubprojektGewerkItem = {
    __typename?: 'SubprojektGewerkItem';
    error?: Maybe<Error>;
    item?: Maybe<SubprojektGewerk>;
};

export type SubprojektGewerkOrderByInput = {
    gewerk?: InputMaybe<GewerkOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
};

export type SubprojektInput = {
    abrechnungsDatum?: InputMaybe<Scalars['AWSDate']>;
    auftragsNr: Scalars['String'];
    bemerkung: Scalars['AWSJSON'];
    bemerkungAufmass: Scalars['String'];
    bonitaetSnippetId: Scalars['Int'];
    buergschaft: Scalars['Float'];
    ihrZeichen: Scalars['String'];
    intern: Scalars['Boolean'];
    notdienst: Scalars['Boolean'];
    projektId: Scalars['Int'];
    projekttypId: Scalars['Int'];
    rahmenvertragId?: InputMaybe<Scalars['Int']>;
    schadenNr: Scalars['String'];
    scheinNr: Scalars['String'];
    statusSnippetId: Scalars['Int'];
    umsatzPrognose?: InputMaybe<Scalars['Float']>;
    versicherungId?: InputMaybe<Scalars['Int']>;
    versicherungsArtSnippetId?: InputMaybe<Scalars['Int']>;
    weg: Scalars['String'];
};

export type SubprojektItem = {
    __typename?: 'SubprojektItem';
    error?: Maybe<Error>;
    item?: Maybe<Subprojekt>;
};

export type SubprojektOrderByInput = {
    abrechnungsDatum?: InputMaybe<SortOrder>;
    angebote?: InputMaybe<AngebotOrderByInput>;
    anlageDatum?: InputMaybe<SortOrder>;
    aufgaben?: InputMaybe<AufgabeOrderByInput>;
    auftragsNr?: InputMaybe<SortOrder>;
    bemerkung?: InputMaybe<SortOrder>;
    bemerkungAufmass?: InputMaybe<SortOrder>;
    beteiligte?: InputMaybe<BeteiligterOrderByInput>;
    bonitaetSnippet?: InputMaybe<SnippetOrderByInput>;
    buergschaft?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    dokumente?: InputMaybe<DokumentOrderByInput>;
    einsatzbericht?: InputMaybe<EinsatzberichtOrderByInput>;
    ihrZeichen?: InputMaybe<SortOrder>;
    intern?: InputMaybe<SortOrder>;
    korrespondenzen?: InputMaybe<KorrespondenzOrderByInput>;
    lfdNr?: InputMaybe<SortOrder>;
    notdienst?: InputMaybe<SortOrder>;
    projekt?: InputMaybe<ProjektOrderByInput>;
    projekttyp?: InputMaybe<ProjekttypOrderByInput>;
    rahmenvertrag?: InputMaybe<RahmenvertragOrderByInput>;
    rechnungen?: InputMaybe<RechnungOrderByInput>;
    schadenNr?: InputMaybe<SortOrder>;
    scheinNr?: InputMaybe<SortOrder>;
    statusSnippet?: InputMaybe<SnippetOrderByInput>;
    subprojektGewerk?: InputMaybe<SubprojektGewerkOrderByInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
    termine?: InputMaybe<TerminOrderByInput>;
    umsatzPrognose?: InputMaybe<SortOrder>;
    versicherung?: InputMaybe<VersicherungOrderByInput>;
    versicherungsArtSnippet?: InputMaybe<SnippetOrderByInput>;
    weg?: InputMaybe<SortOrder>;
};

export type SubprojektSearch = {
    anlageAb?: InputMaybe<Scalars['AWSDate']>;
    anlageBis?: InputMaybe<Scalars['AWSDate']>;
    beteiligter?: InputMaybe<Scalars['String']>;
    niederlassungId?: InputMaybe<Scalars['Int']>;
    objektOrt?: InputMaybe<Scalars['String']>;
    objektPlz?: InputMaybe<Scalars['String']>;
    objektStrasse?: InputMaybe<Scalars['String']>;
    projektLfdNr?: InputMaybe<Scalars['Int']>;
    projekttypId?: InputMaybe<Scalars['Int']>;
    referenznummern?: InputMaybe<Scalars['String']>;
    statusSnippetId?: InputMaybe<Scalars['Int']>;
};

export type SubprojektWohneinheit = {
    __typename?: 'SubprojektWohneinheit';
    angebotsposition?: Maybe<Array<Maybe<Angebotsposition>>>;
    aufmass?: Maybe<Array<Maybe<Aufmass>>>;
    beteiligter?: Maybe<Array<Maybe<Beteiligter>>>;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    geraeteeinsatz?: Maybe<Array<Maybe<Geraeteeinsatz>>>;
    id: Scalars['Int'];
    kalkulationsposition?: Maybe<Array<Maybe<Kalkulationsposition>>>;
    projektId: Scalars['Int'];
    rechnungsposition?: Maybe<Array<Maybe<Rechnungsposition>>>;
    stromzaehler?: Maybe<Array<Maybe<Stromzaehler>>>;
    subprojekt?: Maybe<Subprojekt>;
    subprojektId: Scalars['Int'];
    wattroProjectExists: Scalars['Boolean'];
    wohneinheit?: Maybe<Wohneinheit>;
    wohneinheitId: Scalars['Int'];
};

export type SubprojektWohneinheitCollection = {
    __typename?: 'SubprojektWohneinheitCollection';
    error?: Maybe<Error>;
    items: Array<SubprojektWohneinheit>;
    pageInfo: PageInfo;
};

export type SubprojektWohneinheitFilterInput = {
    angebotsposition?: InputMaybe<AngebotspositionFilterInput>;
    aufmass?: InputMaybe<AufmassFilterInput>;
    beteiligter?: InputMaybe<BeteiligterFilterInput>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    geraeteeinsatz?: InputMaybe<GeraeteeinsatzFilterInput>;
    kalkulationsposition?: InputMaybe<KalkulationspositionFilterInput>;
    projektId?: InputMaybe<Scalars['Int']>;
    rechnungsposition?: InputMaybe<RechnungspositionFilterInput>;
    stromzaehler?: InputMaybe<StromzaehlerFilterInput>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    wattroProjectExists?: InputMaybe<Scalars['Boolean']>;
    wohneinheit?: InputMaybe<WohneinheitFilterInput>;
    wohneinheitId?: InputMaybe<Scalars['Int']>;
};

export type SubprojektWohneinheitInput = {
    projektId: Scalars['Int'];
    subprojektId: Scalars['Int'];
    wattroProjectExists: Scalars['Boolean'];
    wohneinheitId: Scalars['Int'];
};

export type SubprojektWohneinheitItem = {
    __typename?: 'SubprojektWohneinheitItem';
    error?: Maybe<Error>;
    item?: Maybe<SubprojektWohneinheit>;
};

export type SubprojektWohneinheitOrderByInput = {
    angebotsposition?: InputMaybe<AngebotspositionOrderByInput>;
    aufmass?: InputMaybe<AufmassOrderByInput>;
    beteiligter?: InputMaybe<BeteiligterOrderByInput>;
    deleted?: InputMaybe<SortOrder>;
    geraeteeinsatz?: InputMaybe<GeraeteeinsatzOrderByInput>;
    kalkulationsposition?: InputMaybe<KalkulationspositionOrderByInput>;
    rechnungsposition?: InputMaybe<RechnungspositionOrderByInput>;
    stromzaehler?: InputMaybe<StromzaehlerOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    wattroProjectExists?: InputMaybe<SortOrder>;
    wohneinheit?: InputMaybe<WohneinheitOrderByInput>;
};

export type Termin = {
    __typename?: 'Termin';
    angelegt?: Maybe<Scalars['AWSDateTime']>;
    bemerkung: Scalars['String'];
    dauer: Scalars['Int'];
    erledigt?: Maybe<Scalars['AWSDateTime']>;
    geplant?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    mitarbeiterIdSachbearbeiter: Scalars['Int'];
    mitarbeiterIdUrheber?: Maybe<Scalars['Int']>;
    prio?: Maybe<Scalars['Int']>;
    sachbearbeiter?: Maybe<Mitarbeiter>;
    subprojekt?: Maybe<Subprojekt>;
    subprojektId?: Maybe<Scalars['Int']>;
    typSnippet?: Maybe<Snippet>;
    typSnippetId: Scalars['Int'];
    urheber?: Maybe<Mitarbeiter>;
    version: Scalars['Int'];
};

export type TerminAutoCompleteFields = {
    bemerkung?: InputMaybe<Scalars['String']>;
};

export type TerminCollection = {
    __typename?: 'TerminCollection';
    error?: Maybe<Error>;
    items: Array<Termin>;
    pageInfo: PageInfo;
};

export type TerminCreateInput = {
    bemerkung: Scalars['String'];
    dauer: Scalars['Int'];
    erledigt?: InputMaybe<Scalars['AWSDateTime']>;
    geplant?: InputMaybe<Scalars['AWSDateTime']>;
    mitarbeiterIdSachbearbeiter: Scalars['Int'];
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    prio?: InputMaybe<Scalars['Int']>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    typSnippetId: Scalars['Int'];
};

export type TerminFilterInput = {
    angelegtExists?: InputMaybe<Scalars['Boolean']>;
    angelegtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    angelegtTo?: InputMaybe<Scalars['AWSDateTime']>;
    bemerkungExists?: InputMaybe<Scalars['Boolean']>;
    bemerkungPrefix?: InputMaybe<Scalars['String']>;
    dauerFrom?: InputMaybe<Scalars['Float']>;
    dauerTo?: InputMaybe<Scalars['Float']>;
    erledigtExists?: InputMaybe<Scalars['Boolean']>;
    erledigtFrom?: InputMaybe<Scalars['AWSDateTime']>;
    erledigtTo?: InputMaybe<Scalars['AWSDateTime']>;
    geplantExists?: InputMaybe<Scalars['Boolean']>;
    geplantFrom?: InputMaybe<Scalars['AWSDateTime']>;
    geplantTo?: InputMaybe<Scalars['AWSDateTime']>;
    mitarbeiterIdSachbearbeiter?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheber?: InputMaybe<Scalars['Int']>;
    mitarbeiterIdUrheberExists?: InputMaybe<Scalars['Boolean']>;
    prioExists?: InputMaybe<Scalars['Boolean']>;
    prioFrom?: InputMaybe<Scalars['Float']>;
    prioTo?: InputMaybe<Scalars['Float']>;
    sachbearbeiter?: InputMaybe<MitarbeiterFilterInput>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    subprojektIdExists?: InputMaybe<Scalars['Boolean']>;
    typSnippet?: InputMaybe<SnippetFilterInput>;
    typSnippetId?: InputMaybe<Scalars['Int']>;
    urheber?: InputMaybe<MitarbeiterFilterInput>;
};

export type TerminItem = {
    __typename?: 'TerminItem';
    error?: Maybe<Error>;
    item?: Maybe<Termin>;
};

export type TerminOrderByInput = {
    angelegt?: InputMaybe<SortOrder>;
    bemerkung?: InputMaybe<SortOrder>;
    dauer?: InputMaybe<SortOrder>;
    erledigt?: InputMaybe<SortOrder>;
    geplant?: InputMaybe<SortOrder>;
    prio?: InputMaybe<SortOrder>;
    sachbearbeiter?: InputMaybe<MitarbeiterOrderByInput>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
    typSnippet?: InputMaybe<SnippetOrderByInput>;
    urheber?: InputMaybe<MitarbeiterOrderByInput>;
};

export type TerminUpdateInput = {
    bemerkung: Scalars['String'];
    dauer: Scalars['Int'];
    erledigt?: InputMaybe<Scalars['AWSDateTime']>;
    geplant?: InputMaybe<Scalars['AWSDateTime']>;
    mitarbeiterIdSachbearbeiter: Scalars['Int'];
    prio?: InputMaybe<Scalars['Int']>;
    subprojektId?: InputMaybe<Scalars['Int']>;
    typSnippetId: Scalars['Int'];
};

export type Textbaustein = {
    __typename?: 'Textbaustein';
    beschreibung: Richtext;
    deleted?: Maybe<Scalars['AWSDateTime']>;
    gruppe: Scalars['String'];
    id: Scalars['Int'];
};

export type TextbausteinAutoCompleteFields = {
    beschreibung?: InputMaybe<Scalars['String']>;
    gruppe?: InputMaybe<Scalars['String']>;
};

export type TextbausteinCollection = {
    __typename?: 'TextbausteinCollection';
    error?: Maybe<Error>;
    items: Array<Textbaustein>;
    pageInfo: PageInfo;
};

export type TextbausteinFilterInput = {
    beschreibungExists?: InputMaybe<Scalars['Boolean']>;
    beschreibungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    gruppeExists?: InputMaybe<Scalars['Boolean']>;
    gruppePrefix?: InputMaybe<Scalars['String']>;
};

export type TextbausteinInput = {
    beschreibung: Scalars['AWSJSON'];
    gruppe: Scalars['String'];
};

export type TextbausteinItem = {
    __typename?: 'TextbausteinItem';
    error?: Maybe<Error>;
    item?: Maybe<Textbaustein>;
};

export type TextbausteinOrderByInput = {
    beschreibung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    gruppe?: InputMaybe<SortOrder>;
};

export type UpdateGewerkWithProjekttypen = {
    name?: InputMaybe<Scalars['String']>;
    notdienstFaehig?: InputMaybe<Scalars['Boolean']>;
    projekttypIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    publicId?: InputMaybe<Scalars['Int']>;
};

export type UpdateSubprojektWithProjektInput = {
    beteiligteVersion: Array<BeteiligteVersion>;
    beteiligterAuftraggeberId: Scalars['Int'];
    beteiligterEigentuemerId?: InputMaybe<Scalars['Int']>;
    beteiligterHandwerkerId?: InputMaybe<Scalars['Int']>;
    beteiligterVermittlerId?: InputMaybe<Scalars['Int']>;
    beteiligterVersicherungsnehmerId?: InputMaybe<Scalars['Int']>;
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    objektOrt: Scalars['String'];
    objektPlz: Scalars['String'];
    objektStrasse: Scalars['String'];
    projektNiederlassungId: Scalars['Int'];
    projektSchadenTag?: InputMaybe<Scalars['AWSDate']>;
    projektVersion: Scalars['Int'];
    statusSnippetId: Scalars['Int'];
    subprojektAbrechnungsDatum?: InputMaybe<Scalars['AWSDate']>;
    subprojektBemerkung?: InputMaybe<Scalars['AWSJSON']>;
    subprojektBonitaetSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektBuergschaft?: InputMaybe<Scalars['Float']>;
    subprojektGewerkIds: Array<Scalars['Int']>;
    subprojektId: Scalars['Int'];
    subprojektIhrZeichen?: InputMaybe<Scalars['String']>;
    subprojektIntern?: InputMaybe<Scalars['Boolean']>;
    subprojektNoteinsatz?: InputMaybe<Scalars['Boolean']>;
    subprojektProjekttypId: Scalars['Int'];
    subprojektRahmenvertragId?: InputMaybe<Scalars['Int']>;
    subprojektSchadenNr?: InputMaybe<Scalars['String']>;
    subprojektVersicherungId?: InputMaybe<Scalars['Int']>;
    subprojektVersicherungsArtSnippetId?: InputMaybe<Scalars['Int']>;
    subprojektVersion: Scalars['Int'];
    subprojektVertragsNr?: InputMaybe<Scalars['String']>;
    subprojektWeg?: InputMaybe<Scalars['String']>;
};

export type UpsertEinsatzberichtDetailInput = {
    anzahl?: InputMaybe<Scalars['Int']>;
    einsatzberichtOptionId: Scalars['Int'];
    jaNein?: InputMaybe<Scalars['Boolean']>;
    text?: InputMaybe<Scalars['String']>;
};

export type UpsertEinsatzberichtDetailInputAsOutput = {
    __typename?: 'UpsertEinsatzberichtDetailInputAsOutput';
    anzahl?: Maybe<Scalars['Int']>;
    einsatzberichtOptionId: Scalars['Int'];
    jaNein?: Maybe<Scalars['Boolean']>;
    text?: Maybe<Scalars['String']>;
};

export type UpsertEinsatzberichtDetailsErrorReturn = {
    __typename?: 'UpsertEinsatzberichtDetailsErrorReturn';
    einsatzberichtDetail: UpsertEinsatzberichtDetailInputAsOutput;
    error: Error;
};

export type UpsertEinsatzberichtReturn = {
    __typename?: 'UpsertEinsatzberichtReturn';
    detailsError?: Maybe<Array<UpsertEinsatzberichtDetailsErrorReturn>>;
    einsatzbericht?: Maybe<Einsatzbericht>;
    globalError?: Maybe<Error>;
};

export type UpsertGewerkProjekttypenErrorReturn = {
    __typename?: 'UpsertGewerkProjekttypenErrorReturn';
    error: Error;
    projekttypeId: Scalars['Int'];
};

export type UpsertGewerkReturn = {
    __typename?: 'UpsertGewerkReturn';
    gewerk?: Maybe<Gewerk>;
    globalError?: Maybe<Error>;
    projekttypenError?: Maybe<Array<UpsertGewerkProjekttypenErrorReturn>>;
};

export type Versicherung = {
    __typename?: 'Versicherung';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    subprojekt?: Maybe<Array<Maybe<Subprojekt>>>;
};

export type VersicherungAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type VersicherungCollection = {
    __typename?: 'VersicherungCollection';
    error?: Maybe<Error>;
    items: Array<Versicherung>;
    pageInfo: PageInfo;
};

export type VersicherungFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    subprojekt?: InputMaybe<SubprojektFilterInput>;
};

export type VersicherungInput = {
    bezeichnung: Scalars['String'];
};

export type VersicherungItem = {
    __typename?: 'VersicherungItem';
    error?: Maybe<Error>;
    item?: Maybe<Versicherung>;
};

export type VersicherungOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    subprojekt?: InputMaybe<SubprojektOrderByInput>;
};

export type Wohneinheit = {
    __typename?: 'Wohneinheit';
    bezeichnung: Scalars['String'];
    deleted?: Maybe<Scalars['AWSDateTime']>;
    id: Scalars['Int'];
    istGesamtobjekt: Scalars['Boolean'];
    nr: Scalars['Int'];
    projekt?: Maybe<Projekt>;
    projektId: Scalars['Int'];
    subprojektWohneinheit?: Maybe<Array<Maybe<SubprojektWohneinheit>>>;
};

export type WohneinheitAutoCompleteFields = {
    bezeichnung?: InputMaybe<Scalars['String']>;
};

export type WohneinheitCollection = {
    __typename?: 'WohneinheitCollection';
    error?: Maybe<Error>;
    items: Array<Wohneinheit>;
    pageInfo: PageInfo;
};

export type WohneinheitFilterInput = {
    bezeichnungExists?: InputMaybe<Scalars['Boolean']>;
    bezeichnungPrefix?: InputMaybe<Scalars['String']>;
    deletedExists?: InputMaybe<Scalars['Boolean']>;
    deletedFrom?: InputMaybe<Scalars['AWSDateTime']>;
    deletedTo?: InputMaybe<Scalars['AWSDateTime']>;
    istGesamtobjekt?: InputMaybe<Scalars['Boolean']>;
    nrFrom?: InputMaybe<Scalars['Float']>;
    nrTo?: InputMaybe<Scalars['Float']>;
    projekt?: InputMaybe<ProjektFilterInput>;
    projektId?: InputMaybe<Scalars['Int']>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitFilterInput>;
};

export type WohneinheitInput = {
    bezeichnung: Scalars['String'];
    nr: Scalars['Int'];
    projektId: Scalars['Int'];
};

export type WohneinheitItem = {
    __typename?: 'WohneinheitItem';
    error?: Maybe<Error>;
    item?: Maybe<Wohneinheit>;
};

export type WohneinheitOrderByInput = {
    bezeichnung?: InputMaybe<SortOrder>;
    deleted?: InputMaybe<SortOrder>;
    istGesamtobjekt?: InputMaybe<SortOrder>;
    nr?: InputMaybe<SortOrder>;
    projekt?: InputMaybe<ProjektOrderByInput>;
    subprojektWohneinheit?: InputMaybe<SubprojektWohneinheitOrderByInput>;
};

export type NiederlassungFieldsFragment = {
    __typename?: 'Niederlassung';
    id: number;
    nummer: number;
    bezeichnung: string;
} & { ' $fragmentName'?: 'NiederlassungFieldsFragment' };

export type PostleitzahlFieldsFragment = {
    __typename?: 'Postleitzahl';
    id: number;
    niederlassungId?: number | null;
    ort: string;
    plz: string;
} & { ' $fragmentName'?: 'PostleitzahlFieldsFragment' };

export type CreateBranchMutationVariables = Exact<{
    data: NiederlassungInput;
}>;

export type CreateBranchMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'NiederlassungItem';
        item?: {
            __typename?: 'Niederlassung';
            id: number;
            nummer: number;
            bezeichnung: string;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateBranchMutationVariables = Exact<{
    id: Scalars['Int'];
    data: NiederlassungInput;
}>;

export type UpdateBranchMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'NiederlassungItem';
        item?: { __typename?: 'Niederlassung'; nummer: number; bezeichnung: string } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteBranchMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteBranchMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'NiederlassungItem';
        item?: { __typename?: 'Niederlassung'; nummer: number; bezeichnung: string } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListZipCodesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<PostleitzahlFilterInput>;
    orderBy?: InputMaybe<
        Array<InputMaybe<PostleitzahlOrderByInput>> | InputMaybe<PostleitzahlOrderByInput>
    >;
    limit?: InputMaybe<Scalars['Int']>;
}>;

export type ListZipCodesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'PostleitzahlCollection';
        items: Array<{
            __typename?: 'Postleitzahl';
            id: number;
            plz: string;
            ort: string;
            niederlassungId?: number | null;
            niederlassung?: {
                __typename?: 'Niederlassung';
                id: number;
                nummer: number;
                bezeichnung: string;
            } | null;
        }>;
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
    };
};

export type GetBranchQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetBranchQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'NiederlassungItem';
        item?:
            | ({ __typename?: 'Niederlassung' } & {
                  ' $fragmentRefs'?: { NiederlassungFieldsFragment: NiederlassungFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CreateBranchZipCodeMutationVariables = Exact<{
    data: PostleitzahlInput;
}>;

export type CreateBranchZipCodeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'PostleitzahlItem';
        item?:
            | ({ __typename?: 'Postleitzahl' } & {
                  ' $fragmentRefs'?: { PostleitzahlFieldsFragment: PostleitzahlFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateBranchZipCodeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: PostleitzahlInput;
}>;

export type UpdateBranchZipCodeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'PostleitzahlItem';
        item?:
            | ({ __typename?: 'Postleitzahl' } & {
                  ' $fragmentRefs'?: { PostleitzahlFieldsFragment: PostleitzahlFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteBranchZipCodeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteBranchZipCodeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'PostleitzahlItem';
        item?:
            | ({ __typename?: 'Postleitzahl' } & {
                  ' $fragmentRefs'?: { PostleitzahlFieldsFragment: PostleitzahlFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetBranchZipCodeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetBranchZipCodeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'PostleitzahlItem';
        item?:
            | ({ __typename?: 'Postleitzahl' } & {
                  ' $fragmentRefs'?: { PostleitzahlFieldsFragment: PostleitzahlFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListBranchesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<NiederlassungOrderByInput>> | InputMaybe<NiederlassungOrderByInput>
    >;
    filter?: InputMaybe<NiederlassungFilterInput>;
}>;

export type ListBranchesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'NiederlassungCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Niederlassung' } & {
                ' $fragmentRefs'?: { NiederlassungFieldsFragment: NiederlassungFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type KontaktMutationFieldsFragment = {
    __typename?: 'Kontakt';
    id: number;
    version: number;
    anredeSnippetId?: number | null;
    email: string;
    fax: string;
    firma1: string;
    firma2: string;
    landSnippetId?: number | null;
    name: string;
    ort: string;
    plz: string;
    postfach: string;
    statusSnippetId?: number | null;
    strasse: string;
    telefon: string;
    telefonDirekt: string;
    telefonMobil: string;
    telefonPrivat: string;
    typSnippetId?: number | null;
    vorname: string;
    zustellOrt: string;
    zustellPlz: string;
    zustellStrasse: string;
    freitext: { __typename?: 'Richtext'; html: string; raw: any };
} & { ' $fragmentName'?: 'KontaktMutationFieldsFragment' };

export type KontaktFieldsFragment = {
    __typename?: 'Kontakt';
    id: number;
    version: number;
    name: string;
    vorname: string;
    firma1: string;
    firma2: string;
    plz: string;
    ort: string;
    strasse: string;
    email: string;
    fax: string;
    telefon: string;
    telefonDirekt: string;
    telefonMobil: string;
    telefonPrivat: string;
    anredeSnippetId?: number | null;
    landSnippetId?: number | null;
    statusSnippetId?: number | null;
    typSnippetId?: number | null;
    freitext: { __typename?: 'Richtext'; html: string; raw: any };
    typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'KontaktFieldsFragment' };

export type CreateContactMutationVariables = Exact<{
    data: KontaktInput;
}>;

export type CreateContactMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KontaktItem';
        item?:
            | ({ __typename?: 'Kontakt' } & {
                  ' $fragmentRefs'?: {
                      KontaktMutationFieldsFragment: KontaktMutationFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateContactMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: KontaktInput;
}>;

export type UpdateContactMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KontaktItem';
        item?:
            | ({ __typename?: 'Kontakt' } & {
                  ' $fragmentRefs'?: {
                      KontaktMutationFieldsFragment: KontaktMutationFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteContactMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteContactMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KontaktItem';
        item?:
            | ({ __typename?: 'Kontakt' } & {
                  ' $fragmentRefs'?: {
                      KontaktMutationFieldsFragment: KontaktMutationFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListContactQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<KontaktOrderByInput>> | InputMaybe<KontaktOrderByInput>>;
    filter?: InputMaybe<KontaktFilterInput>;
}>;

export type ListContactQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'KontaktCollection';
        items: Array<
            { __typename?: 'Kontakt' } & {
                ' $fragmentRefs'?: { KontaktFieldsFragment: KontaktFieldsFragment };
            }
        >;
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetContactQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetContactQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'KontaktItem';
        item?:
            | ({ __typename?: 'Kontakt' } & {
                  ' $fragmentRefs'?: { KontaktFieldsFragment: KontaktFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetContactCsvExportQueryVariables = Exact<{
    searchTerm: Scalars['String'];
}>;

export type GetContactCsvExportQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        kontaktCsvExport: {
            __typename?: 'CsvItem';
            item?: string | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type SearchContactQueryVariables = Exact<{
    searchTerm: Scalars['String'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type SearchContactQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        kontaktSearch: {
            __typename?: 'KontaktCollection';
            items: Array<
                { __typename?: 'Kontakt' } & {
                    ' $fragmentRefs'?: { KontaktFieldsFragment: KontaktFieldsFragment };
                }
            >;
            pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type ListVacantPositionsQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']>;
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
    niederlassungId: Scalars['Int'];
    offset?: InputMaybe<Scalars['Int']>;
    only28DaysAndOlder?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<OffenePostenOrderByInput>> | InputMaybe<OffenePostenOrderByInput>
    >;
}>;

export type ListVacantPositionsQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        item: {
            __typename?: 'OffenePostenCollection';
            gesamtUmsatz: number;
            items: Array<{
                __typename?: 'OffenePosten';
                aufbau: string;
                letzterTermin: string;
                niederlassung: string;
                objekt: string;
                projekt: string;
                umsatz: number;
                offenerTermin: boolean;
            }>;
            pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
            error?: { __typename?: 'Error'; data: string; code: string; message: string } | null;
        };
    } | null;
};

export type ListVacantPositionEmployeesQueryVariables = Exact<{
    niederlassungId: Scalars['Int'];
}>;

export type ListVacantPositionEmployeesQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        item: {
            __typename?: 'MitarbeiterCollection';
            items: Array<{ __typename?: 'Mitarbeiter'; id: number; name: string; vorname: string }>;
            pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
            error?: { __typename?: 'Error'; data: string; code: string; message: string } | null;
        };
    } | null;
};

export type VacantPositionCsvExportQueryVariables = Exact<{
    mitarbeiterId?: InputMaybe<Scalars['Int']>;
    niederlassungId: Scalars['Int'];
    only28DaysAndOlder?: InputMaybe<Scalars['Boolean']>;
}>;

export type VacantPositionCsvExportQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        item: {
            __typename?: 'CsvItem';
            item?: string | null;
            error?: { __typename?: 'Error'; data: string; code: string; message: string } | null;
        };
    } | null;
};

export type GetProjectByLfdNrQueryVariables = Exact<{
    lfdNr: Scalars['Int'];
}>;

export type GetProjectByLfdNrQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        item: {
            __typename?: 'ProjektItem';
            item?: {
                __typename?: 'Projekt';
                id: number;
                lfdNr: number;
                subprojekt?: Array<{
                    __typename?: 'Subprojekt';
                    id: number;
                    lfdNr: number;
                } | null> | null;
            } | null;
            error?: { __typename?: 'Error'; data: string; code: string; message: string } | null;
        };
    } | null;
};

export type GeraetetypFieldsFragment = {
    __typename?: 'Geraetetyp';
    id: number;
    typ: string;
    bezeichnung: string;
    hersteller: string;
    nennleistung: number;
    mitZaehler: boolean;
    isWattro: boolean;
    dokumentId?: number | null;
    dokument?: {
        __typename?: 'Dokument';
        beschreibung: string;
        id: number;
        name: string;
        pfad: string;
    } | null;
} & { ' $fragmentName'?: 'GeraetetypFieldsFragment' };

export type CreateDeviceTypeMutationVariables = Exact<{
    data: GeraetetypInput;
}>;

export type CreateDeviceTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GeraetetypItem';
        item?:
            | ({ __typename?: 'Geraetetyp' } & {
                  ' $fragmentRefs'?: { GeraetetypFieldsFragment: GeraetetypFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateDeviceTypeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: GeraetetypInput;
}>;

export type UpdateDeviceTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GeraetetypItem';
        item?:
            | ({ __typename?: 'Geraetetyp' } & {
                  ' $fragmentRefs'?: { GeraetetypFieldsFragment: GeraetetypFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteDeviceTypeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteDeviceTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GeraetetypItem';
        item?:
            | ({ __typename?: 'Geraetetyp' } & {
                  ' $fragmentRefs'?: { GeraetetypFieldsFragment: GeraetetypFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListDeviceTypeQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<GeraetetypOrderByInput>> | InputMaybe<GeraetetypOrderByInput>
    >;
    filter?: InputMaybe<GeraetetypFilterInput>;
}>;

export type ListDeviceTypeQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'GeraetetypCollection';
        pageInfo: { __typename?: 'PageInfo'; totalCount: number; pageCount: number };
        items: Array<
            { __typename?: 'Geraetetyp' } & {
                ' $fragmentRefs'?: { GeraetetypFieldsFragment: GeraetetypFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetDeviceTypeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetDeviceTypeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'GeraetetypItem';
        item?:
            | ({ __typename?: 'Geraetetyp' } & {
                  ' $fragmentRefs'?: { GeraetetypFieldsFragment: GeraetetypFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type FunctionFieldsFragment = {
    __typename?: 'Funktion';
    id: number;
    bezeichnung: string;
} & { ' $fragmentName'?: 'FunctionFieldsFragment' };

export type CreateFunctionMutationVariables = Exact<{
    data: FunktionInput;
}>;

export type CreateFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { FunctionFieldsFragment: FunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateFunctionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: FunktionInput;
}>;

export type UpdateFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { FunctionFieldsFragment: FunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteFunctionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { FunctionFieldsFragment: FunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListFunctionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<FunktionOrderByInput>> | InputMaybe<FunktionOrderByInput>
    >;
    filter: FunktionFilterInput;
}>;

export type ListFunctionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'FunktionCollection';
        items: Array<
            { __typename?: 'Funktion' } & {
                ' $fragmentRefs'?: { FunctionFieldsFragment: FunctionFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        pageInfo: { __typename?: 'PageInfo'; totalCount: number; pageCount: number };
    };
};

export type GetFunctionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetFunctionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { FunctionFieldsFragment: FunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CreateGeneralAgreementMutationVariables = Exact<{
    data: RahmenvertragInput;
}>;

export type CreateGeneralAgreementMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RahmenvertragItem';
        item?: {
            __typename?: 'Rahmenvertrag';
            id: number;
            bezeichnung: string;
            gueltigAb?: any | null;
            gueltigBis?: any | null;
            isStandard: boolean;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateGeneralAgreementMutationVariables = Exact<{
    id: Scalars['Int'];
    data: RahmenvertragInput;
}>;

export type UpdateGeneralAgreementMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RahmenvertragItem';
        item?: {
            __typename?: 'Rahmenvertrag';
            id: number;
            bezeichnung: string;
            gueltigAb?: any | null;
            gueltigBis?: any | null;
            isStandard: boolean;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteGeneralAgreementMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteGeneralAgreementMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RahmenvertragItem';
        item?: {
            __typename?: 'Rahmenvertrag';
            id: number;
            bezeichnung: string;
            gueltigAb?: any | null;
            gueltigBis?: any | null;
            isStandard: boolean;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListGeneralAgreementsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<RahmenvertragOrderByInput>> | InputMaybe<RahmenvertragOrderByInput>
    >;
    filter?: InputMaybe<RahmenvertragFilterInput>;
}>;

export type ListGeneralAgreementsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'RahmenvertragCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<{
            __typename?: 'Rahmenvertrag';
            id: number;
            bezeichnung: string;
            gueltigAb?: any | null;
            gueltigBis?: any | null;
            isStandard: boolean;
        }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetGeneralAgreementQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetGeneralAgreementQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'RahmenvertragItem';
        item?: {
            __typename?: 'Rahmenvertrag';
            bezeichnung: string;
            id: number;
            isStandard: boolean;
            deleted?: any | null;
            gueltigAb?: any | null;
            gueltigBis?: any | null;
            rahmenvertragsposition?: Array<{
                __typename?: 'Rahmenvertragsposition';
                beschreibung: string;
                details: string;
                id: number;
                leistungId: number;
                nr: string;
                preisProEinheit: number;
                leistung?: { __typename?: 'Leistung'; bezeichnung: string; einheit: string } | null;
            } | null> | null;
            subprojekt?: Array<{ __typename?: 'Subprojekt'; id: number } | null> | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type RahmenvertragspositionFieldsFragment = {
    __typename?: 'Rahmenvertragsposition';
    id: number;
    nr: string;
    details: string;
    beschreibung: string;
    preisProEinheit: number;
    rahmenvertragId: number;
    leistungId: number;
    rahmenvertrag?: { __typename?: 'Rahmenvertrag'; bezeichnung: string } | null;
    leistung?: { __typename?: 'Leistung'; bezeichnung: string; einheit: string } | null;
} & { ' $fragmentName'?: 'RahmenvertragspositionFieldsFragment' };

export type CreateGeneralAgreementPositionMutationVariables = Exact<{
    data: RahmenvertragspositionInput;
}>;

export type CreateGeneralAgreementPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RahmenvertragspositionItem';
        item?:
            | ({ __typename?: 'Rahmenvertragsposition' } & {
                  ' $fragmentRefs'?: {
                      RahmenvertragspositionFieldsFragment: RahmenvertragspositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateGeneralAgreementPositionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: RahmenvertragspositionInput;
}>;

export type UpdateGeneralAgreementPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RahmenvertragspositionItem';
        item?:
            | ({ __typename?: 'Rahmenvertragsposition' } & {
                  ' $fragmentRefs'?: {
                      RahmenvertragspositionFieldsFragment: RahmenvertragspositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteGeneralAgreementPositionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteGeneralAgreementPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RahmenvertragspositionItem';
        item?:
            | ({ __typename?: 'Rahmenvertragsposition' } & {
                  ' $fragmentRefs'?: {
                      RahmenvertragspositionFieldsFragment: RahmenvertragspositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListGeneralAgreementPostionQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<RahmenvertragspositionFilterInput>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<RahmenvertragspositionOrderByInput>>
        | InputMaybe<RahmenvertragspositionOrderByInput>
    >;
}>;

export type ListGeneralAgreementPostionQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'RahmenvertragspositionCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Rahmenvertragsposition' } & {
                ' $fragmentRefs'?: {
                    RahmenvertragspositionFieldsFragment: RahmenvertragspositionFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetGeneralAgreementPostionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetGeneralAgreementPostionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'RahmenvertragspositionItem';
        item?:
            | ({ __typename?: 'Rahmenvertragsposition' } & {
                  ' $fragmentRefs'?: {
                      RahmenvertragspositionFieldsFragment: RahmenvertragspositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type VersicherungFieldsFragment = {
    __typename?: 'Versicherung';
    id: number;
    bezeichnung: string;
} & { ' $fragmentName'?: 'VersicherungFieldsFragment' };

export type CreateInsuranceMutationVariables = Exact<{
    data: VersicherungInput;
}>;

export type CreateInsuranceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'VersicherungItem';
        item?:
            | ({ __typename?: 'Versicherung' } & {
                  ' $fragmentRefs'?: { VersicherungFieldsFragment: VersicherungFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateInsuranceMutationVariables = Exact<{
    id: Scalars['Int'];
    data: VersicherungInput;
}>;

export type UpdateInsuranceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'VersicherungItem';
        item?:
            | ({ __typename?: 'Versicherung' } & {
                  ' $fragmentRefs'?: { VersicherungFieldsFragment: VersicherungFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteInsuranceMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteInsuranceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'VersicherungItem';
        item?:
            | ({ __typename?: 'Versicherung' } & {
                  ' $fragmentRefs'?: { VersicherungFieldsFragment: VersicherungFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListInsurancesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<VersicherungOrderByInput>> | InputMaybe<VersicherungOrderByInput>
    >;
    filter?: InputMaybe<VersicherungFilterInput>;
}>;

export type ListInsurancesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'VersicherungCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Versicherung' } & {
                ' $fragmentRefs'?: { VersicherungFieldsFragment: VersicherungFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetInsuranceQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetInsuranceQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'VersicherungItem';
        item?:
            | ({ __typename?: 'Versicherung' } & {
                  ' $fragmentRefs'?: { VersicherungFieldsFragment: VersicherungFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ProjekttypFieldsFragment = { __typename?: 'Projekttyp'; id: number; name: string } & {
    ' $fragmentName'?: 'ProjekttypFieldsFragment';
};

export type CreateProjectTypeMutationVariables = Exact<{
    data: ProjekttypInput;
}>;

export type CreateProjectTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'ProjekttypItem';
        item?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateProjectTypeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: ProjekttypInput;
}>;

export type UpdateProjectTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'ProjekttypItem';
        item?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteProjectTypeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteProjectTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'ProjekttypItem';
        item?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListProjectTypeQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<ProjekttypOrderByInput>> | InputMaybe<ProjekttypOrderByInput>
    >;
    filter?: InputMaybe<ProjekttypFilterInput>;
}>;

export type ListProjectTypeQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'ProjekttypCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Projekttyp' } & {
                ' $fragmentRefs'?: { ProjekttypFieldsFragment: ProjekttypFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetProjectTypeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetProjectTypeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'ProjekttypItem';
        item?:
            | ({ __typename?: 'Projekttyp' } & {
                  ' $fragmentRefs'?: { ProjekttypFieldsFragment: ProjekttypFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type RoleFieldsFragment = {
    __typename?: 'Rolle';
    id: number;
    bezeichnung: string;
    deleted?: any | null;
    mitarbeiter?: Array<{
        __typename?: 'Mitarbeiter';
        id: number;
        name: string;
        vorname: string;
    } | null> | null;
} & { ' $fragmentName'?: 'RoleFieldsFragment' };

export type CreateRoleMutationVariables = Exact<{
    data: RolleInput;
}>;

export type CreateRoleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RolleItem';
        item?:
            | ({ __typename?: 'Rolle' } & {
                  ' $fragmentRefs'?: { RoleFieldsFragment: RoleFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateRoleMutationVariables = Exact<{
    id: Scalars['Int'];
    data: RolleInput;
}>;

export type UpdateRoleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RolleItem';
        item?:
            | ({ __typename?: 'Rolle' } & {
                  ' $fragmentRefs'?: { RoleFieldsFragment: RoleFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteRoleMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteRoleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RolleItem';
        item?:
            | ({ __typename?: 'Rolle' } & {
                  ' $fragmentRefs'?: { RoleFieldsFragment: RoleFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListRolesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<RolleOrderByInput>> | InputMaybe<RolleOrderByInput>>;
    filter: RolleFilterInput;
}>;

export type ListRolesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'RolleCollection';
        items: Array<
            { __typename?: 'Rolle' } & {
                ' $fragmentRefs'?: { RoleFieldsFragment: RoleFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetRoleQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetRoleQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'RolleItem';
        item?:
            | ({ __typename?: 'Rolle' } & {
                  ' $fragmentRefs'?: { RoleFieldsFragment: RoleFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SnippetFieldsFragment = {
    __typename?: 'Snippet';
    id: number;
    kategorie: string;
    eigenschaft: string;
    kuerzel: string;
    name: string;
    bearbeitbar: boolean;
    metadata: string;
} & { ' $fragmentName'?: 'SnippetFieldsFragment' };

export type CreateSnippetMutationVariables = Exact<{
    data: SnippetInput;
}>;

export type CreateSnippetMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SnippetItem';
        item?:
            | ({ __typename?: 'Snippet' } & {
                  ' $fragmentRefs'?: { SnippetFieldsFragment: SnippetFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateSnippetMutationVariables = Exact<{
    id: Scalars['Int'];
    data: SnippetInput;
}>;

export type UpdateSnippetMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SnippetItem';
        item?:
            | ({ __typename?: 'Snippet' } & {
                  ' $fragmentRefs'?: { SnippetFieldsFragment: SnippetFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteSnippetMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteSnippetMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SnippetItem';
        item?:
            | ({ __typename?: 'Snippet' } & {
                  ' $fragmentRefs'?: { SnippetFieldsFragment: SnippetFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListSnippetQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<SnippetOrderByInput>> | InputMaybe<SnippetOrderByInput>>;
    filter?: InputMaybe<SnippetFilterInput>;
}>;

export type ListSnippetQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'SnippetCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Snippet' } & {
                ' $fragmentRefs'?: { SnippetFieldsFragment: SnippetFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetSnippetQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetSnippetQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'SnippetItem';
        item?:
            | ({ __typename?: 'Snippet' } & {
                  ' $fragmentRefs'?: { SnippetFieldsFragment: SnippetFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type TextbausteinFieldsFragment = {
    __typename?: 'Textbaustein';
    id: number;
    gruppe: string;
    deleted?: any | null;
    beschreibung: { __typename?: 'Richtext'; raw: any; html: string };
} & { ' $fragmentName'?: 'TextbausteinFieldsFragment' };

export type CreateTextSnippetMutationVariables = Exact<{
    data: TextbausteinInput;
}>;

export type CreateTextSnippetMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'TextbausteinItem';
        item?:
            | ({ __typename?: 'Textbaustein' } & {
                  ' $fragmentRefs'?: { TextbausteinFieldsFragment: TextbausteinFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateTextSnippetMutationVariables = Exact<{
    id: Scalars['Int'];
    data: TextbausteinInput;
}>;

export type UpdateTextSnippetMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'TextbausteinItem';
        item?:
            | ({ __typename?: 'Textbaustein' } & {
                  ' $fragmentRefs'?: { TextbausteinFieldsFragment: TextbausteinFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteTextSnippetMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteTextSnippetMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'TextbausteinItem';
        item?:
            | ({ __typename?: 'Textbaustein' } & {
                  ' $fragmentRefs'?: { TextbausteinFieldsFragment: TextbausteinFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListTextSnippetQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<TextbausteinOrderByInput>> | InputMaybe<TextbausteinOrderByInput>
    >;
    filter?: InputMaybe<TextbausteinFilterInput>;
}>;

export type ListTextSnippetQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'TextbausteinCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Textbaustein' } & {
                ' $fragmentRefs'?: { TextbausteinFieldsFragment: TextbausteinFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetTextSnippetQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetTextSnippetQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'TextbausteinItem';
        item?:
            | ({ __typename?: 'Textbaustein' } & {
                  ' $fragmentRefs'?: { TextbausteinFieldsFragment: TextbausteinFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GewerkFieldsFragment = {
    __typename?: 'Gewerk';
    id: number;
    name: string;
    notdienstFaehig: boolean;
    publicId: number;
    gewerkProjekttyp?: Array<{
        __typename?: 'GewerkProjekttyp';
        id: number;
        projekttyp?: { __typename?: 'Projekttyp'; name: string; id: number } | null;
    } | null> | null;
} & { ' $fragmentName'?: 'GewerkFieldsFragment' };

export type CreateTradeMutationVariables = Exact<{
    data: GewerkInput;
    gewerkProjekttyp?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type CreateTradeMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        item: {
            __typename?: 'UpsertGewerkReturn';
            item?:
                | ({ __typename?: 'Gewerk' } & {
                      ' $fragmentRefs'?: { GewerkFieldsFragment: GewerkFieldsFragment };
                  })
                | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type UpdateTradeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: GewerkInput;
    gewerkProjekttyp?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type UpdateTradeMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        item: {
            __typename?: 'UpsertGewerkReturn';
            item?:
                | ({ __typename?: 'Gewerk' } & {
                      ' $fragmentRefs'?: { GewerkFieldsFragment: GewerkFieldsFragment };
                  })
                | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type DeleteTradeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteTradeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GewerkItem';
        item?:
            | ({ __typename?: 'Gewerk' } & {
                  ' $fragmentRefs'?: { GewerkFieldsFragment: GewerkFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListTradeQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GewerkOrderByInput>> | InputMaybe<GewerkOrderByInput>>;
    filter?: InputMaybe<GewerkFilterInput>;
}>;

export type ListTradeQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'GewerkCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Gewerk' } & {
                ' $fragmentRefs'?: { GewerkFieldsFragment: GewerkFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetTradeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetTradeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'GewerkItem';
        item?:
            | ({ __typename?: 'Gewerk' } & {
                  ' $fragmentRefs'?: { GewerkFieldsFragment: GewerkFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CorrespondenceFieldsFragment = {
    __typename?: 'Korrespondenz';
    id: number;
    version: number;
    angelegt?: any | null;
    beteiligter: string;
    dokumentId?: number | null;
    mitarbeiterIdSachbearbeiter?: number | null;
    mitarbeiterIdUrheber?: number | null;
    subprojektId: number;
    typSnippetId: number;
    zeit?: any | null;
    dokument?: {
        __typename?: 'Dokument';
        beschreibung: string;
        deleted?: any | null;
        id: number;
        name: string;
        pfad: string;
        subprojektId?: number | null;
        typSnippetId?: number | null;
        typSnippet?: {
            __typename?: 'Snippet';
            eigenschaft: string;
            id: number;
            kategorie: string;
            name: string;
        } | null;
    } | null;
    freitext: { __typename?: 'Richtext'; html: string; raw: any };
    typSnippet?: {
        __typename?: 'Snippet';
        id: number;
        eigenschaft: string;
        kategorie: string;
        name: string;
    } | null;
    urheber?: {
        __typename?: 'Mitarbeiter';
        id: number;
        kennung: string;
        name: string;
        vorname: string;
    } | null;
    sachbearbeiter?: {
        __typename?: 'Mitarbeiter';
        id: number;
        kennung: string;
        name: string;
        vorname: string;
    } | null;
    subprojekt?: {
        __typename?: 'Subprojekt';
        id: number;
        lfdNr: number;
        projekttypId: number;
        beteiligte?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                name: string;
                vorname: string;
                firma1: string;
                firma2: string;
            } | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
        projekt?: {
            __typename?: 'Projekt';
            id: number;
            lfdNr: number;
            anlageDatum: any;
            plz: string;
            ort: string;
            strasse: string;
            niederlassung?: { __typename?: 'Niederlassung'; id: number; nummer: number } | null;
        } | null;
        projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
    } | null;
} & { ' $fragmentName'?: 'CorrespondenceFieldsFragment' };

export type ListCorrespondencesAndEventsQueryVariables = Exact<{
    projektId?: InputMaybe<Scalars['Int']>;
    subprojektId?: InputMaybe<Scalars['Int']>;
}>;

export type ListCorrespondencesAndEventsQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        items: {
            __typename?: 'KorrespondenzUndTerminCollection';
            pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
            items: Array<{
                __typename?: 'KorrespondenzOderTermin';
                typ: string;
                datum?: any | null;
                beschreibung: string;
                mitarbeiterIdSachbearbeiter?: number | null;
                mitarbeiterIdUrheber?: number | null;
                terminId?: number | null;
                korrespondenzId?: number | null;
                sachbearbeiter?: {
                    __typename?: 'Mitarbeiter';
                    id: number;
                    name: string;
                    vorname: string;
                } | null;
                urheber?: {
                    __typename?: 'Mitarbeiter';
                    id: number;
                    name: string;
                    vorname: string;
                } | null;
                termin?: {
                    __typename?: 'Termin';
                    id: number;
                    version: number;
                    angelegt?: any | null;
                    bemerkung: string;
                    dauer: number;
                    erledigt?: any | null;
                    geplant?: any | null;
                    typSnippetId: number;
                    mitarbeiterIdSachbearbeiter: number;
                    mitarbeiterIdUrheber?: number | null;
                    prio?: number | null;
                    subprojektId?: number | null;
                    sachbearbeiter?: {
                        __typename?: 'Mitarbeiter';
                        id: number;
                        name: string;
                        vorname: string;
                    } | null;
                    urheber?: {
                        __typename?: 'Mitarbeiter';
                        id: number;
                        name: string;
                        vorname: string;
                    } | null;
                    subprojekt?: {
                        __typename?: 'Subprojekt';
                        id: number;
                        lfdNr: number;
                        projektId: number;
                        beteiligte?: Array<{
                            __typename?: 'Beteiligter';
                            id: number;
                            kontakt?: {
                                __typename?: 'Kontakt';
                                id: number;
                                name: string;
                                vorname: string;
                                firma1: string;
                                firma2: string;
                            } | null;
                            typSnippet?: {
                                __typename?: 'Snippet';
                                id: number;
                                name: string;
                            } | null;
                        } | null> | null;
                        projekt?: {
                            __typename?: 'Projekt';
                            id: number;
                            anlageDatum: any;
                            lfdNr: number;
                            strasse: string;
                            plz: string;
                            ort: string;
                            niederlassungId: number;
                            niederlassung?: {
                                __typename?: 'Niederlassung';
                                id: number;
                                nummer: number;
                            } | null;
                        } | null;
                    } | null;
                } | null;
                korrespondenz?: {
                    __typename?: 'Korrespondenz';
                    id: number;
                    version: number;
                    typSnippetId: number;
                    beteiligter: string;
                    angelegt?: any | null;
                    mitarbeiterIdSachbearbeiter?: number | null;
                    mitarbeiterIdUrheber?: number | null;
                    zeit?: any | null;
                    dokumentId?: number | null;
                    freitext: { __typename?: 'Richtext'; html: string; raw: any };
                    sachbearbeiter?: {
                        __typename?: 'Mitarbeiter';
                        id: number;
                        name: string;
                        vorname: string;
                    } | null;
                    urheber?: {
                        __typename?: 'Mitarbeiter';
                        id: number;
                        name: string;
                        vorname: string;
                    } | null;
                    dokument?: {
                        __typename?: 'Dokument';
                        id: number;
                        beschreibung: string;
                        name: string;
                        pfad: string;
                        typSnippetId?: number | null;
                    } | null;
                    subprojekt?: {
                        __typename?: 'Subprojekt';
                        lfdNr: number;
                        id: number;
                        projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
                        beteiligte?: Array<{
                            __typename?: 'Beteiligter';
                            id: number;
                            kontakt?: {
                                __typename?: 'Kontakt';
                                id: number;
                                name: string;
                                vorname: string;
                                firma1: string;
                                firma2: string;
                            } | null;
                            typSnippet?: {
                                __typename?: 'Snippet';
                                id: number;
                                name: string;
                            } | null;
                        } | null> | null;
                        projekt?: {
                            __typename?: 'Projekt';
                            plz: string;
                            ort: string;
                            strasse: string;
                            niederlassungId: number;
                            anlageDatum: any;
                            id: number;
                            lfdNr: number;
                            niederlassung?: {
                                __typename?: 'Niederlassung';
                                id: number;
                                nummer: number;
                            } | null;
                        } | null;
                    } | null;
                } | null;
            }>;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type CreateCorrespondenceMutationVariables = Exact<{
    data: KorrespondenzCreateInput;
}>;

export type CreateCorrespondenceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KorrespondenzItem';
        item?:
            | ({ __typename?: 'Korrespondenz' } & {
                  ' $fragmentRefs'?: { CorrespondenceFieldsFragment: CorrespondenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateCorrespondenceMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: KorrespondenzUpdateInput;
}>;

export type UpdateCorrespondenceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KorrespondenzItem';
        item?:
            | ({ __typename?: 'Korrespondenz' } & {
                  ' $fragmentRefs'?: { CorrespondenceFieldsFragment: CorrespondenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteCorrespondenceMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteCorrespondenceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KorrespondenzItem';
        item?:
            | ({ __typename?: 'Korrespondenz' } & {
                  ' $fragmentRefs'?: { CorrespondenceFieldsFragment: CorrespondenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListCorrespondenceQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<KorrespondenzOrderByInput>> | InputMaybe<KorrespondenzOrderByInput>
    >;
    filter: KorrespondenzFilterInput;
}>;

export type ListCorrespondenceQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'KorrespondenzCollection';
        items: Array<
            { __typename?: 'Korrespondenz' } & {
                ' $fragmentRefs'?: { CorrespondenceFieldsFragment: CorrespondenceFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetCorrespondenceQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetCorrespondenceQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'KorrespondenzItem';
        item?:
            | ({ __typename?: 'Korrespondenz' } & {
                  ' $fragmentRefs'?: { CorrespondenceFieldsFragment: CorrespondenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeviceUsageFieldsFragment = {
    __typename?: 'Geraeteeinsatz';
    id: number;
    abrechnen: boolean;
    wattroId?: number | null;
    wattroAssetId?: number | null;
    beginnTag?: any | null;
    endeTag?: any | null;
    berechnungsmethodeSnippetId?: number | null;
    geraetId?: number | null;
    geraetetypId: number;
    subprojektWohneinheitId: number;
    berechnungsmethodeSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
    geraet?: { __typename?: 'Geraet'; id: number; nummer: string } | null;
    geraetetyp?: {
        __typename?: 'Geraetetyp';
        typ: string;
        bezeichnung: string;
        isWattro: boolean;
        hersteller: string;
        nennleistung: number;
    } | null;
    subprojektWohneinheit?: {
        __typename?: 'SubprojektWohneinheit';
        id: number;
        wattroProjectExists: boolean;
        subprojektId: number;
        projektId: number;
        wohneinheit?: { __typename?: 'Wohneinheit'; bezeichnung: string } | null;
        beteiligter?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                name: string;
                vorname: string;
                firma1: string;
                firma2: string;
                strasse: string;
                plz: string;
                ort: string;
                anredeSnippetId?: number | null;
                anredeSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
            } | null;
        } | null> | null;
    } | null;
} & { ' $fragmentName'?: 'DeviceUsageFieldsFragment' };

export type CreateDeviceUsageMutationVariables = Exact<{
    data: GeraeteeinsatzInput;
}>;

export type CreateDeviceUsageMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GeraeteeinsatzItem';
        item?:
            | ({ __typename?: 'Geraeteeinsatz' } & {
                  ' $fragmentRefs'?: { DeviceUsageFieldsFragment: DeviceUsageFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateDeviceUsageMutationVariables = Exact<{
    id: Scalars['Int'];
    data: GeraeteeinsatzInput;
}>;

export type UpdateDeviceUsageMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GeraeteeinsatzItem';
        item?:
            | ({ __typename?: 'Geraeteeinsatz' } & {
                  ' $fragmentRefs'?: { DeviceUsageFieldsFragment: DeviceUsageFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteDeviceUsageMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteDeviceUsageMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'GeraeteeinsatzItem';
        item?:
            | ({ __typename?: 'Geraeteeinsatz' } & {
                  ' $fragmentRefs'?: { DeviceUsageFieldsFragment: DeviceUsageFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListDeviceUsageQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<GeraeteeinsatzOrderByInput>> | InputMaybe<GeraeteeinsatzOrderByInput>
    >;
    filter: GeraeteeinsatzFilterInput;
}>;

export type ListDeviceUsageQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'GeraeteeinsatzCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Geraeteeinsatz' } & {
                ' $fragmentRefs'?: { DeviceUsageFieldsFragment: DeviceUsageFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetDeviceUsageQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetDeviceUsageQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'GeraeteeinsatzItem';
        item?:
            | ({ __typename?: 'Geraeteeinsatz' } & {
                  ' $fragmentRefs'?: { DeviceUsageFieldsFragment: DeviceUsageFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetDeviceUsagePdfQueryVariables = Exact<{
    subprojektId: Scalars['Int'];
}>;

export type GetDeviceUsagePdfQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'SubprojektItem';
        item?: {
            __typename?: 'Subprojekt';
            id: number;
            beteiligte?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    firma1: string;
                    firma2: string;
                    name: string;
                    vorname: string;
                    ort: string;
                    plz: string;
                    strasse: string;
                } | null;
            } | null> | null;
            subprojektWohneinheit?: Array<{
                __typename?: 'SubprojektWohneinheit';
                id: number;
                wattroProjectExists: boolean;
                wohneinheit?: {
                    __typename?: 'Wohneinheit';
                    bezeichnung: string;
                    nr: number;
                    istGesamtobjekt: boolean;
                    projekt?: {
                        __typename?: 'Projekt';
                        ort: string;
                        plz: string;
                        strasse: string;
                    } | null;
                } | null;
                geraeteeinsatz?: Array<{
                    __typename?: 'Geraeteeinsatz';
                    id: number;
                    abrechnen: boolean;
                    geraetId?: number | null;
                    beginnTag?: any | null;
                    endeTag?: any | null;
                    berechnungsmethodeSnippetId?: number | null;
                    geraetetypId: number;
                    geraetetyp?: {
                        __typename?: 'Geraetetyp';
                        isWattro: boolean;
                        bezeichnung: string;
                        nennleistung: number;
                    } | null;
                } | null> | null;
                stromzaehler?: Array<{
                    __typename?: 'Stromzaehler';
                    id: number;
                    wattroId?: number | null;
                    wattroAssetId?: number | null;
                    nummer: string;
                    abrechnen: boolean;
                    beginnTag?: any | null;
                    endeTag?: any | null;
                    beginnWert: number;
                    endeWert?: number | null;
                    typSnippetId: number;
                    typSnippet?: {
                        __typename?: 'Snippet';
                        name: string;
                        kategorie: string;
                        eigenschaft: string;
                    } | null;
                } | null> | null;
            } | null> | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DocumentFieldsFragment = {
    __typename?: 'Dokument';
    id: number;
    beschreibung: string;
    deleted?: any | null;
    name: string;
    pfad: string;
    subprojektId?: number | null;
    typSnippetId?: number | null;
    angebotId?: number | null;
    rechnungId?: number | null;
    einsatzberichtId?: number | null;
    geraetetyp?: Array<{
        __typename?: 'Geraetetyp';
        id: number;
        bezeichnung: string;
        isWattro: boolean;
        hersteller: string;
    } | null> | null;
    korrespondenz?: Array<{
        __typename?: 'Korrespondenz';
        id: number;
        angelegt?: any | null;
        mitarbeiterIdSachbearbeiter?: number | null;
        mitarbeiterIdUrheber?: number | null;
        freitext: { __typename?: 'Richtext'; html: string; raw: any };
    } | null> | null;
    einsatzbericht?: {
        __typename?: 'Einsatzbericht';
        id: number;
        einsatzberichtDetail?: Array<{
            __typename?: 'EinsatzberichtDetail';
            jaNein?: boolean | null;
            text?: string | null;
            id: number;
            einsatzberichtOption?: {
                __typename?: 'EinsatzberichtOption';
                id: number;
                bezeichnung: string;
            } | null;
        } | null> | null;
    } | null;
    typSnippet?: {
        __typename?: 'Snippet';
        id: number;
        name: string;
        kategorie: string;
        kuerzel: string;
        eigenschaft: string;
    } | null;
} & { ' $fragmentName'?: 'DocumentFieldsFragment' };

export type CreateDocumentMutationVariables = Exact<{
    data: DokumentInput;
}>;

export type CreateDocumentMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'DokumentItem';
        item?:
            | ({ __typename?: 'Dokument' } & {
                  ' $fragmentRefs'?: { DocumentFieldsFragment: DocumentFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateDocumentMutationVariables = Exact<{
    id: Scalars['Int'];
    data: DokumentInput;
}>;

export type UpdateDocumentMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'DokumentItem';
        item?:
            | ({ __typename?: 'Dokument' } & {
                  ' $fragmentRefs'?: { DocumentFieldsFragment: DocumentFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteDocumentMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteDocumentMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'DokumentItem';
        item?:
            | ({ __typename?: 'Dokument' } & {
                  ' $fragmentRefs'?: { DocumentFieldsFragment: DocumentFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListDocumentsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<DokumentOrderByInput>> | InputMaybe<DokumentOrderByInput>
    >;
    filter: DokumentFilterInput;
}>;

export type ListDocumentsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'DokumentCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Dokument' } & {
                ' $fragmentRefs'?: { DocumentFieldsFragment: DocumentFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetDocumentQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetDocumentQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'DokumentItem';
        item?:
            | ({ __typename?: 'Dokument' } & {
                  ' $fragmentRefs'?: { DocumentFieldsFragment: DocumentFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DocumentDetailsQueryVariables = Exact<{
    subprojektId: Scalars['Int'];
}>;

export type DocumentDetailsQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'SubprojektItem';
        item?: {
            __typename?: 'Subprojekt';
            id: number;
            weg: string;
            ihrZeichen: string;
            projektId: number;
            lfdNr: number;
            scheinNr: string;
            schadenNr: string;
            einsatzbericht?: Array<{
                __typename?: 'Einsatzbericht';
                id: number;
                datum?: any | null;
                deleted?: any | null;
                kontaktId?: number | null;
                subprojektId: number;
                anfahrtFrom?: any | null;
                anfahrtUntil?: any | null;
                einsatzFrom?: any | null;
                einsatzUntil?: any | null;
                abfahrtFrom?: any | null;
                abfahrtUntil?: any | null;
                mitarbeiterIdUrheber?: number | null;
                einsatzberichtTyp?: {
                    __typename?: 'EinsatzberichtTyp';
                    id: number;
                    name: string;
                } | null;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    name: string;
                    vorname: string;
                    firma1: string;
                    firma2: string;
                } | null;
                einsatzberichtSachbearbeiter?: Array<{
                    __typename?: 'EinsatzberichtSachbearbeiter';
                    id: number;
                    einsatzberichtId: number;
                    mitarbeiterId: number;
                    mitarbeiter?: {
                        __typename?: 'Mitarbeiter';
                        id: number;
                        name: string;
                        vorname: string;
                    } | null;
                } | null> | null;
                urheber?: {
                    __typename?: 'Mitarbeiter';
                    id: number;
                    name: string;
                    vorname: string;
                } | null;
                einsatzberichtDetail?: Array<{
                    __typename?: 'EinsatzberichtDetail';
                    id: number;
                    einsatzberichtOptionId: number;
                    jaNein?: boolean | null;
                    anzahl?: number | null;
                    text?: string | null;
                    einsatzberichtOption?: {
                        __typename?: 'EinsatzberichtOption';
                        id: number;
                        bezeichnung: string;
                        typ: EinsatzberichtOptionTyp;
                    } | null;
                } | null> | null;
            } | null> | null;
            subprojektWohneinheit?: Array<{
                __typename?: 'SubprojektWohneinheit';
                id: number;
                wattroProjectExists: boolean;
                aufmass?: Array<{
                    __typename?: 'Aufmass';
                    id: number;
                    bezeichnung: string;
                    laenge?: number | null;
                    breite?: number | null;
                    hoehe?: number | null;
                    typSnippet?: { __typename?: 'Snippet'; name: string } | null;
                } | null> | null;
                wohneinheit?: {
                    __typename?: 'Wohneinheit';
                    id: number;
                    nr: number;
                    bezeichnung: string;
                    istGesamtobjekt: boolean;
                } | null;
            } | null> | null;
            projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
            projekt?: {
                __typename?: 'Projekt';
                id: number;
                lfdNr: number;
                anlageDatum: any;
                ort: string;
                plz: string;
                strasse: string;
                niederlassung?: {
                    __typename?: 'Niederlassung';
                    nummer: number;
                    bezeichnung: string;
                } | null;
            } | null;
            bemerkung: { __typename?: 'Richtext'; html: string; raw: any };
            angebote?: Array<{
                __typename?: 'Angebot';
                id: number;
                deleted?: any | null;
                nummer: string;
                datum?: any | null;
                gesamtpreisNetto: number;
                mwstSatz: number;
                mitarbeiterIdSachbearbeiter?: number | null;
                mitarbeiterIdProjektleitung?: number | null;
                rabatt: string;
                statusSnippetId: number;
                dokument?: Array<{
                    __typename?: 'Dokument';
                    id: number;
                    pfad: string;
                    name: string;
                } | null> | null;
                freitext: { __typename?: 'Richtext'; html: string; raw: any };
                freitextFooter: { __typename?: 'Richtext'; html: string; raw: any };
                sachbearbeiter?: {
                    __typename?: 'Mitarbeiter';
                    name: string;
                    vorname: string;
                } | null;
                angebotspositionen?: Array<{
                    __typename?: 'Angebotsposition';
                    id: number;
                    subprojektWohneinheitId?: number | null;
                    beschreibung: string;
                    einheit: string;
                    einzelpreis: number;
                    menge: number;
                    lfdNr: string;
                } | null> | null;
                statusSnippet?: { __typename?: 'Snippet'; name: string } | null;
            } | null> | null;
            rechnungen?: Array<{
                __typename?: 'Rechnung';
                id: number;
                deleted?: any | null;
                nummer: string;
                datum: any;
                mwstSatz: number;
                mitarbeiterIdSachbearbeiter?: number | null;
                mitarbeiterIdProjektleitung?: number | null;
                rabatt: string;
                statusSnippetId: number;
                dokument?: Array<{
                    __typename?: 'Dokument';
                    id: number;
                    pfad: string;
                    name: string;
                } | null> | null;
                freitext: { __typename?: 'Richtext'; html: string; raw: any };
                freitextFooter: { __typename?: 'Richtext'; html: string; raw: any };
                sachbearbeiter?: {
                    __typename?: 'Mitarbeiter';
                    name: string;
                    vorname: string;
                } | null;
                rechnungspositionen?: Array<{
                    __typename?: 'Rechnungsposition';
                    id: number;
                    subprojektWohneinheitId?: number | null;
                    beschreibung: string;
                    einheit: string;
                    einzelpreis: number;
                    menge: number;
                    lfdNr: string;
                } | null> | null;
                statusSnippet?: { __typename?: 'Snippet'; name: string } | null;
            } | null> | null;
            beteiligte?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                subprojektWohneinheitId?: number | null;
                typSnippet?: {
                    __typename?: 'Snippet';
                    id: number;
                    name: string;
                    kuerzel: string;
                } | null;
                subprojektWohneinheit?: {
                    __typename?: 'SubprojektWohneinheit';
                    id: number;
                    wattroProjectExists: boolean;
                    wohneinheitId: number;
                    wohneinheit?: { __typename?: 'Wohneinheit'; bezeichnung: string } | null;
                } | null;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    firma1: string;
                    firma2: string;
                    name: string;
                    vorname: string;
                    ort: string;
                    plz: string;
                    strasse: string;
                    telefon: string;
                    telefonDirekt: string;
                    telefonMobil: string;
                    telefonPrivat: string;
                    email: string;
                    fax: string;
                    anredeSnippet?: {
                        __typename?: 'Snippet';
                        name: string;
                        kuerzel: string;
                    } | null;
                    freitext: { __typename?: 'Richtext'; raw: any };
                } | null;
            } | null> | null;
            termine?: Array<{
                __typename?: 'Termin';
                angelegt?: any | null;
                bemerkung: string;
                mitarbeiterIdUrheber?: number | null;
                mitarbeiterIdSachbearbeiter: number;
                typSnippetId: number;
                geplant?: any | null;
                dauer: number;
                typSnippet?: { __typename?: 'Snippet'; name: string; kuerzel: string } | null;
                sachbearbeiter?: {
                    __typename?: 'Mitarbeiter';
                    id: number;
                    name: string;
                    vorname: string;
                } | null;
                urheber?: {
                    __typename?: 'Mitarbeiter';
                    name: string;
                    vorname: string;
                    id: number;
                } | null;
                subprojekt?: { __typename?: 'Subprojekt'; id: number } | null;
            } | null> | null;
            dokumente?: Array<{
                __typename?: 'Dokument';
                beschreibung: string;
                name: string;
                pfad: string;
                typSnippet?: { __typename?: 'Snippet'; name: string } | null;
            } | null> | null;
            versicherung?: { __typename?: 'Versicherung'; bezeichnung: string } | null;
            aufgaben?: Array<{
                __typename?: 'Aufgabe';
                angelegt?: any | null;
                zuErledigen?: any | null;
                mitarbeiterIdUrheber?: number | null;
                typSnippetId?: number | null;
                beschreibung: { __typename?: 'Richtext'; html: string; raw: any };
                urheber?: { __typename?: 'Mitarbeiter'; name: string; vorname: string } | null;
                typSnippet?: { __typename?: 'Snippet'; name: string } | null;
                sachbearbeiter?: {
                    __typename?: 'Mitarbeiter';
                    name: string;
                    vorname: string;
                } | null;
            } | null> | null;
            korrespondenzen?: Array<{
                __typename?: 'Korrespondenz';
                typSnippetId: number;
                angelegt?: any | null;
                mitarbeiterIdSachbearbeiter?: number | null;
                mitarbeiterIdUrheber?: number | null;
                typSnippet?: { __typename?: 'Snippet'; name: string } | null;
                sachbearbeiter?: {
                    __typename?: 'Mitarbeiter';
                    name: string;
                    vorname: string;
                } | null;
                urheber?: { __typename?: 'Mitarbeiter'; name: string; vorname: string } | null;
            } | null> | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ElectricityMeterFieldsFragment = {
    __typename?: 'Stromzaehler';
    id: number;
    abrechnen: boolean;
    beginnTag?: any | null;
    beginnWert: number;
    endeTag?: any | null;
    endeWert?: number | null;
    nummer: string;
    typSnippetId: number;
    wattroId?: number | null;
    wattroAssetId?: number | null;
    subprojektWohneinheitId: number;
    subprojektWohneinheit?: {
        __typename?: 'SubprojektWohneinheit';
        id: number;
        wattroProjectExists: boolean;
        subprojektId: number;
        projektId: number;
        wohneinheit?: { __typename?: 'Wohneinheit'; bezeichnung: string } | null;
        beteiligter?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                name: string;
                vorname: string;
                firma1: string;
                firma2: string;
                strasse: string;
                plz: string;
                ort: string;
                anredeSnippetId?: number | null;
                anredeSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
            } | null;
        } | null> | null;
    } | null;
} & { ' $fragmentName'?: 'ElectricityMeterFieldsFragment' };

export type CreateElectricityMeterMutationVariables = Exact<{
    data: StromzaehlerInput;
}>;

export type CreateElectricityMeterMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'StromzaehlerItem';
        item?:
            | ({ __typename?: 'Stromzaehler' } & {
                  ' $fragmentRefs'?: {
                      ElectricityMeterFieldsFragment: ElectricityMeterFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateElectricityMeterMutationVariables = Exact<{
    id: Scalars['Int'];
    data: StromzaehlerInput;
}>;

export type UpdateElectricityMeterMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'StromzaehlerItem';
        item?:
            | ({ __typename?: 'Stromzaehler' } & {
                  ' $fragmentRefs'?: {
                      ElectricityMeterFieldsFragment: ElectricityMeterFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteElectricityMeterMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteElectricityMeterMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'StromzaehlerItem';
        item?:
            | ({ __typename?: 'Stromzaehler' } & {
                  ' $fragmentRefs'?: {
                      ElectricityMeterFieldsFragment: ElectricityMeterFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListElectricityMeterQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<StromzaehlerOrderByInput>> | InputMaybe<StromzaehlerOrderByInput>
    >;
    filter: StromzaehlerFilterInput;
}>;

export type ListElectricityMeterQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'StromzaehlerCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Stromzaehler' } & {
                ' $fragmentRefs'?: {
                    ElectricityMeterFieldsFragment: ElectricityMeterFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetElectricityMeterQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetElectricityMeterQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'StromzaehlerItem';
        item?:
            | ({ __typename?: 'Stromzaehler' } & {
                  ' $fragmentRefs'?: {
                      ElectricityMeterFieldsFragment: ElectricityMeterFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type NotdienstprojektFieldsFragment = {
    __typename?: 'Notdienstprojekt';
    id: number;
    humanid: string;
    email: string;
    mitarbeiterIdByEmail?: number | null;
    createdAt: any;
    name: string;
    address: string;
    notes: string;
    startedByEmail: string;
    mitarbeiterIdByStartedBy?: number | null;
    startedAt?: any | null;
    finishedAt?: any | null;
    status: string;
    mitarbeiterByEmail?: {
        __typename?: 'Mitarbeiter';
        id: number;
        name: string;
        vorname: string;
    } | null;
    mitarbeiterByStartedBy?: { __typename?: 'Mitarbeiter'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'NotdienstprojektFieldsFragment' };

export type CreateNotdienstprojektMutationVariables = Exact<{
    data: NotdienstprojektInput;
}>;

export type CreateNotdienstprojektMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'NotdienstprojektItem';
        item?:
            | ({ __typename?: 'Notdienstprojekt' } & {
                  ' $fragmentRefs'?: {
                      NotdienstprojektFieldsFragment: NotdienstprojektFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateNotdienstprojektMutationVariables = Exact<{
    id: Scalars['Int'];
    data: NotdienstprojektInput;
}>;

export type UpdateNotdienstprojektMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'NotdienstprojektItem';
        item?:
            | ({ __typename?: 'Notdienstprojekt' } & {
                  ' $fragmentRefs'?: {
                      NotdienstprojektFieldsFragment: NotdienstprojektFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteNotdienstprojektMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteNotdienstprojektMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'NotdienstprojektItem';
        item?:
            | ({ __typename?: 'Notdienstprojekt' } & {
                  ' $fragmentRefs'?: {
                      NotdienstprojektFieldsFragment: NotdienstprojektFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListNotdienstprojektQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<NotdienstprojektOrderByInput>> | InputMaybe<NotdienstprojektOrderByInput>
    >;
    filter: NotdienstprojektFilterInput;
}>;

export type ListNotdienstprojektQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'NotdienstprojektCollection';
        items: Array<
            { __typename?: 'Notdienstprojekt' } & {
                ' $fragmentRefs'?: {
                    NotdienstprojektFieldsFragment: NotdienstprojektFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type EventFieldsFragment = {
    __typename?: 'Termin';
    id: number;
    version: number;
    angelegt?: any | null;
    bemerkung: string;
    dauer: number;
    erledigt?: any | null;
    geplant?: any | null;
    mitarbeiterIdSachbearbeiter: number;
    mitarbeiterIdUrheber?: number | null;
    prio?: number | null;
    subprojektId?: number | null;
    typSnippetId: number;
    sachbearbeiter?: {
        __typename?: 'Mitarbeiter';
        id: number;
        kennung: string;
        name: string;
        vorname: string;
        farbe: string;
    } | null;
    subprojekt?: {
        __typename?: 'Subprojekt';
        id: number;
        version: number;
        lfdNr: number;
        beteiligte?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                firma1: string;
                firma2: string;
                name: string;
                vorname: string;
            } | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
        projekt?: {
            __typename?: 'Projekt';
            lfdNr: number;
            id: number;
            version: number;
            plz: string;
            ort: string;
            anlageDatum: any;
            strasse: string;
            niederlassung?: { __typename?: 'Niederlassung'; id: number; nummer: number } | null;
        } | null;
        projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
    } | null;
    typSnippet?: {
        __typename?: 'Snippet';
        id: number;
        kategorie: string;
        kuerzel: string;
        eigenschaft: string;
        name: string;
    } | null;
    urheber?: {
        __typename?: 'Mitarbeiter';
        id: number;
        kennung: string;
        name: string;
        vorname: string;
    } | null;
} & { ' $fragmentName'?: 'EventFieldsFragment' };

export type CreateEventMutationVariables = Exact<{
    data: TerminCreateInput;
}>;

export type CreateEventMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'TerminItem';
        item?:
            | ({ __typename?: 'Termin' } & {
                  ' $fragmentRefs'?: { EventFieldsFragment: EventFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateEventMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: TerminUpdateInput;
}>;

export type UpdateEventMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'TerminItem';
        item?:
            | ({ __typename?: 'Termin' } & {
                  ' $fragmentRefs'?: { EventFieldsFragment: EventFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteEventMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteEventMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'TerminItem';
        item?:
            | ({ __typename?: 'Termin' } & {
                  ' $fragmentRefs'?: { EventFieldsFragment: EventFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListEventsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<TerminOrderByInput>> | InputMaybe<TerminOrderByInput>>;
    filter: TerminFilterInput;
}>;

export type ListEventsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'TerminCollection';
        items: Array<
            { __typename?: 'Termin' } & {
                ' $fragmentRefs'?: { EventFieldsFragment: EventFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetEventQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetEventQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'TerminItem';
        item?:
            | ({ __typename?: 'Termin' } & {
                  ' $fragmentRefs'?: { EventFieldsFragment: EventFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CalculationPositionFieldsFragment = {
    __typename?: 'Kalkulationsposition';
    id: number;
    beschreibung: string;
    einheit: string;
    einzelpreis: number;
    lfdNr: string;
    menge: number;
    rahmenvertragspositionId?: number | null;
    subprojektId: number;
    subprojektWohneinheitId?: number | null;
    rahmenvertragsposition?: {
        __typename?: 'Rahmenvertragsposition';
        id: number;
        nr: string;
        beschreibung: string;
        rahmenvertragId: number;
        rahmenvertrag?: { __typename?: 'Rahmenvertrag'; id: number; bezeichnung: string } | null;
        leistung?: { __typename?: 'Leistung'; einheit: string; bezeichnung: string } | null;
    } | null;
    subprojektWohneinheit?: {
        __typename?: 'SubprojektWohneinheit';
        aufmass?: Array<{
            __typename?: 'Aufmass';
            id: number;
            bezeichnung: string;
            laenge?: number | null;
            breite?: number | null;
            hoehe?: number | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
    } | null;
} & { ' $fragmentName'?: 'CalculationPositionFieldsFragment' };

export type CreateCalculationPositionMutationVariables = Exact<{
    data: KalkulationspositionInput;
}>;

export type CreateCalculationPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KalkulationspositionItem';
        item?:
            | ({ __typename?: 'Kalkulationsposition' } & {
                  ' $fragmentRefs'?: {
                      CalculationPositionFieldsFragment: CalculationPositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateCalculationPositionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: KalkulationspositionInput;
}>;

export type UpdateCalculationPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KalkulationspositionItem';
        item?:
            | ({ __typename?: 'Kalkulationsposition' } & {
                  ' $fragmentRefs'?: {
                      CalculationPositionFieldsFragment: CalculationPositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteCalculationPositionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteCalculationPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'KalkulationspositionItem';
        item?:
            | ({ __typename?: 'Kalkulationsposition' } & {
                  ' $fragmentRefs'?: {
                      CalculationPositionFieldsFragment: CalculationPositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListCalculationPositionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<KalkulationspositionOrderByInput>>
        | InputMaybe<KalkulationspositionOrderByInput>
    >;
    filter: KalkulationspositionFilterInput;
}>;

export type ListCalculationPositionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'KalkulationspositionCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Kalkulationsposition' } & {
                ' $fragmentRefs'?: {
                    CalculationPositionFieldsFragment: CalculationPositionFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetCalculationPositionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetCalculationPositionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'KalkulationspositionItem';
        item?:
            | ({ __typename?: 'Kalkulationsposition' } & {
                  ' $fragmentRefs'?: {
                      CalculationPositionFieldsFragment: CalculationPositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SelectSubprojectResidentialUnitsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<SubprojektWohneinheitOrderByInput>>
        | InputMaybe<SubprojektWohneinheitOrderByInput>
    >;
    filter?: InputMaybe<SubprojektWohneinheitFilterInput>;
}>;

export type SelectSubprojectResidentialUnitsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'SubprojektWohneinheitCollection';
        items: Array<{
            __typename?: 'SubprojektWohneinheit';
            id: number;
            subprojektId: number;
            deleted?: any | null;
            wattroProjectExists: boolean;
            subprojekt?: {
                __typename?: 'Subprojekt';
                lfdNr: number;
                projekt?: { __typename?: 'Projekt'; lfdNr: number } | null;
                projekttyp?: { __typename?: 'Projekttyp'; name: string } | null;
            } | null;
            beteiligter?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                version: number;
                subprojektId: number;
                typSnippetId: number;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    version: number;
                    anredeSnippetId?: number | null;
                    email: string;
                    fax: string;
                    firma1: string;
                    firma2: string;
                    landSnippetId?: number | null;
                    name: string;
                    ort: string;
                    plz: string;
                    postfach: string;
                    statusSnippetId?: number | null;
                    strasse: string;
                    telefon: string;
                    telefonDirekt: string;
                    telefonMobil: string;
                    telefonPrivat: string;
                    typSnippetId?: number | null;
                    vorname: string;
                    zustellOrt: string;
                    zustellPlz: string;
                    zustellStrasse: string;
                    freitext: { __typename?: 'Richtext'; html: string; raw: any };
                    typSnippet?: {
                        __typename?: 'Snippet';
                        id: number;
                        name: string;
                        kategorie: string;
                    } | null;
                } | null;
            } | null> | null;
            aufmass?: Array<{
                __typename?: 'Aufmass';
                id: number;
                bezeichnung: string;
                laenge?: number | null;
                breite?: number | null;
                hoehe?: number | null;
                typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
            } | null> | null;
            wohneinheit?: {
                __typename?: 'Wohneinheit';
                id: number;
                nr: number;
                istGesamtobjekt: boolean;
                bezeichnung: string;
                deleted?: any | null;
                projekt?: {
                    __typename?: 'Projekt';
                    id: number;
                    plz: string;
                    ort: string;
                    strasse: string;
                } | null;
            } | null;
        }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type InvoicePositionFieldsFragment = {
    __typename?: 'Rechnungsposition';
    id: number;
    rechnungId: number;
    einzelpreis: number;
    einheit: string;
    beschreibung: string;
    lfdNr: string;
    menge: number;
    rahmenvertragspositionId?: number | null;
    subprojektWohneinheitId?: number | null;
    rahmenvertragsposition?: {
        __typename?: 'Rahmenvertragsposition';
        id: number;
        nr: string;
        beschreibung: string;
        rahmenvertragId: number;
        rahmenvertrag?: { __typename?: 'Rahmenvertrag'; id: number; bezeichnung: string } | null;
        leistung?: { __typename?: 'Leistung'; einheit: string; bezeichnung: string } | null;
    } | null;
    subprojektWohneinheit?: {
        __typename?: 'SubprojektWohneinheit';
        id: number;
        wattroProjectExists: boolean;
        wohneinheitId: number;
        wohneinheit?: {
            __typename?: 'Wohneinheit';
            id: number;
            nr: number;
            bezeichnung: string;
            istGesamtobjekt: boolean;
        } | null;
        aufmass?: Array<{
            __typename?: 'Aufmass';
            id: number;
            bezeichnung: string;
            laenge?: number | null;
            breite?: number | null;
            hoehe?: number | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
    } | null;
} & { ' $fragmentName'?: 'InvoicePositionFieldsFragment' };

export type CreateInvoicePositionMutationVariables = Exact<{
    data: RechnungspositionInput;
}>;

export type CreateInvoicePositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RechnungspositionItem';
        item?:
            | ({ __typename?: 'Rechnungsposition' } & {
                  ' $fragmentRefs'?: {
                      InvoicePositionFieldsFragment: InvoicePositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateInvoicePositionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: RechnungspositionInput;
}>;

export type UpdateInvoicePositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RechnungspositionItem';
        item?:
            | ({ __typename?: 'Rechnungsposition' } & {
                  ' $fragmentRefs'?: {
                      InvoicePositionFieldsFragment: InvoicePositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteInvoicePositionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteInvoicePositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RechnungspositionItem';
        item?:
            | ({ __typename?: 'Rechnungsposition' } & {
                  ' $fragmentRefs'?: {
                      InvoicePositionFieldsFragment: InvoicePositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetInvoicePositionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetInvoicePositionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'RechnungspositionItem';
        item?:
            | ({ __typename?: 'Rechnungsposition' } & {
                  ' $fragmentRefs'?: {
                      InvoicePositionFieldsFragment: InvoicePositionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListInvoicePositionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<RechnungspositionOrderByInput>> | InputMaybe<RechnungspositionOrderByInput>
    >;
    filter: RechnungspositionFilterInput;
}>;

export type ListInvoicePositionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'RechnungspositionCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Rechnungsposition' } & {
                ' $fragmentRefs'?: { InvoicePositionFieldsFragment: InvoicePositionFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type InvoiceFieldsFragment = {
    __typename?: 'Rechnung';
    id: number;
    datum: any;
    deleted?: any | null;
    mitarbeiterIdProjektleitung?: number | null;
    mitarbeiterIdSachbearbeiter?: number | null;
    mwstSatz: number;
    nummer: string;
    rabatt: string;
    gesamtpreisNetto: number;
    statusSnippetId: number;
    subprojektId: number;
    dokument?: Array<{
        __typename?: 'Dokument';
        id: number;
        pfad: string;
        name: string;
        beschreibung: string;
        deleted?: any | null;
    } | null> | null;
    freitext: { __typename?: 'Richtext'; html: string; raw: any };
    freitextFooter: { __typename?: 'Richtext'; html: string; raw: any };
    statusSnippet?: {
        __typename?: 'Snippet';
        id: number;
        name: string;
        kategorie: string;
        eigenschaft: string;
        kuerzel: string;
    } | null;
    projektleitung?: {
        __typename?: 'Mitarbeiter';
        id: number;
        name: string;
        vorname: string;
        kennung: string;
    } | null;
    rechnungspositionen?: Array<{
        __typename?: 'Rechnungsposition';
        id: number;
        einzelpreis: number;
        einheit: string;
        beschreibung: string;
        lfdNr: string;
        menge: number;
        rahmenvertragspositionId?: number | null;
        subprojektWohneinheitId?: number | null;
        rahmenvertragsposition?: {
            __typename?: 'Rahmenvertragsposition';
            id: number;
            nr: string;
            beschreibung: string;
            rahmenvertragId: number;
            rahmenvertrag?: {
                __typename?: 'Rahmenvertrag';
                id: number;
                bezeichnung: string;
            } | null;
            leistung?: { __typename?: 'Leistung'; einheit: string; bezeichnung: string } | null;
        } | null;
    } | null> | null;
    sachbearbeiter?: {
        __typename?: 'Mitarbeiter';
        id: number;
        name: string;
        vorname: string;
        kennung: string;
    } | null;
} & { ' $fragmentName'?: 'InvoiceFieldsFragment' };

export type CreateInvoiceMutationVariables = Exact<{
    data: RechnungInput;
}>;

export type CreateInvoiceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RechnungItem';
        item?:
            | ({ __typename?: 'Rechnung' } & {
                  ' $fragmentRefs'?: { InvoiceFieldsFragment: InvoiceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateInvoiceMutationVariables = Exact<{
    id: Scalars['Int'];
    data: RechnungInput;
}>;

export type UpdateInvoiceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RechnungItem';
        item?:
            | ({ __typename?: 'Rechnung' } & {
                  ' $fragmentRefs'?: { InvoiceFieldsFragment: InvoiceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteInvoiceMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteInvoiceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'RechnungItem';
        item?:
            | ({ __typename?: 'Rechnung' } & {
                  ' $fragmentRefs'?: { InvoiceFieldsFragment: InvoiceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetInvoiceQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetInvoiceQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'RechnungItem';
        item?:
            | ({ __typename?: 'Rechnung' } & {
                  ' $fragmentRefs'?: { InvoiceFieldsFragment: InvoiceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListInvoicesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<RechnungOrderByInput>> | InputMaybe<RechnungOrderByInput>
    >;
    filter: RechnungFilterInput;
}>;

export type ListInvoicesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'RechnungCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Rechnung' } & {
                ' $fragmentRefs'?: { InvoiceFieldsFragment: InvoiceFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OfferPositionFieldsFragment = {
    __typename?: 'Angebotsposition';
    id: number;
    angebotId: number;
    einzelpreis: number;
    einheit: string;
    beschreibung: string;
    lfdNr: string;
    menge: number;
    rahmenvertragspositionId?: number | null;
    subprojektWohneinheitId?: number | null;
    rahmenvertragsposition?: {
        __typename?: 'Rahmenvertragsposition';
        id: number;
        nr: string;
        beschreibung: string;
        rahmenvertragId: number;
        rahmenvertrag?: { __typename?: 'Rahmenvertrag'; id: number; bezeichnung: string } | null;
        leistung?: { __typename?: 'Leistung'; einheit: string; bezeichnung: string } | null;
    } | null;
    subprojektWohneinheit?: {
        __typename?: 'SubprojektWohneinheit';
        id: number;
        wattroProjectExists: boolean;
        wohneinheitId: number;
        wohneinheit?: {
            __typename?: 'Wohneinheit';
            id: number;
            nr: number;
            bezeichnung: string;
            istGesamtobjekt: boolean;
        } | null;
        aufmass?: Array<{
            __typename?: 'Aufmass';
            id: number;
            bezeichnung: string;
            laenge?: number | null;
            breite?: number | null;
            hoehe?: number | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
    } | null;
} & { ' $fragmentName'?: 'OfferPositionFieldsFragment' };

export type CreateOfferPositionMutationVariables = Exact<{
    data: AngebotspositionInput;
}>;

export type CreateOfferPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AngebotspositionItem';
        item?:
            | ({ __typename?: 'Angebotsposition' } & {
                  ' $fragmentRefs'?: { OfferPositionFieldsFragment: OfferPositionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOfferPositionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: AngebotspositionInput;
}>;

export type UpdateOfferPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AngebotspositionItem';
        item?:
            | ({ __typename?: 'Angebotsposition' } & {
                  ' $fragmentRefs'?: { OfferPositionFieldsFragment: OfferPositionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOfferPositionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOfferPositionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AngebotspositionItem';
        item?:
            | ({ __typename?: 'Angebotsposition' } & {
                  ' $fragmentRefs'?: { OfferPositionFieldsFragment: OfferPositionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOfferPositionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOfferPositionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'AngebotspositionItem';
        item?:
            | ({ __typename?: 'Angebotsposition' } & {
                  ' $fragmentRefs'?: { OfferPositionFieldsFragment: OfferPositionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOffersPositionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<AngebotspositionOrderByInput>> | InputMaybe<AngebotspositionOrderByInput>
    >;
    filter: AngebotspositionFilterInput;
}>;

export type ListOffersPositionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'AngebotspositionCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Angebotsposition' } & {
                ' $fragmentRefs'?: { OfferPositionFieldsFragment: OfferPositionFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OfferFieldsFragment = {
    __typename?: 'Angebot';
    id: number;
    datum?: any | null;
    deleted?: any | null;
    gesamtpreisNetto: number;
    mitarbeiterIdProjektleitung?: number | null;
    mitarbeiterIdSachbearbeiter?: number | null;
    mwstSatz: number;
    nummer: string;
    rabatt: string;
    statusSnippetId: number;
    subprojektId: number;
    dokument?: Array<{
        __typename?: 'Dokument';
        id: number;
        pfad: string;
        name: string;
        beschreibung: string;
        deleted?: any | null;
    } | null> | null;
    freitext: { __typename?: 'Richtext'; html: string; raw: any };
    freitextFooter: { __typename?: 'Richtext'; html: string; raw: any };
    statusSnippet?: {
        __typename?: 'Snippet';
        id: number;
        eigenschaft: string;
        kategorie: string;
        name: string;
        kuerzel: string;
    } | null;
    angebotspositionen?: Array<{
        __typename?: 'Angebotsposition';
        id: number;
        beschreibung: string;
        einheit: string;
        einzelpreis: number;
        lfdNr: string;
        menge: number;
        rahmenvertragspositionId?: number | null;
        subprojektWohneinheitId?: number | null;
        rahmenvertragsposition?: {
            __typename?: 'Rahmenvertragsposition';
            id: number;
            nr: string;
            beschreibung: string;
            rahmenvertragId: number;
            rahmenvertrag?: {
                __typename?: 'Rahmenvertrag';
                id: number;
                bezeichnung: string;
            } | null;
            leistung?: { __typename?: 'Leistung'; einheit: string; bezeichnung: string } | null;
        } | null;
    } | null> | null;
    projektleitung?: {
        __typename?: 'Mitarbeiter';
        name: string;
        id: number;
        vorname: string;
        kennung: string;
    } | null;
    sachbearbeiter?: {
        __typename?: 'Mitarbeiter';
        id: number;
        kennung: string;
        name: string;
        vorname: string;
    } | null;
} & { ' $fragmentName'?: 'OfferFieldsFragment' };

export type CreateOfferMutationVariables = Exact<{
    data: AngebotInput;
}>;

export type CreateOfferMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AngebotItem';
        item?:
            | ({ __typename?: 'Angebot' } & {
                  ' $fragmentRefs'?: { OfferFieldsFragment: OfferFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOfferMutationVariables = Exact<{
    id: Scalars['Int'];
    data: AngebotInput;
}>;

export type UpdateOfferMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AngebotItem';
        item?:
            | ({ __typename?: 'Angebot' } & {
                  ' $fragmentRefs'?: { OfferFieldsFragment: OfferFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOfferMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOfferMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AngebotItem';
        item?:
            | ({ __typename?: 'Angebot' } & {
                  ' $fragmentRefs'?: { OfferFieldsFragment: OfferFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOfferQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOfferQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'AngebotItem';
        item?:
            | ({ __typename?: 'Angebot' } & {
                  ' $fragmentRefs'?: { OfferFieldsFragment: OfferFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOffersQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AngebotOrderByInput>> | InputMaybe<AngebotOrderByInput>>;
    filter: AngebotFilterInput;
}>;

export type ListOffersQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'AngebotCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Angebot' } & {
                ' $fragmentRefs'?: { OfferFieldsFragment: OfferFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateSalesForecastMutationVariables = Exact<{
    subprojektId: Scalars['Int'];
}>;

export type UpdateSalesForecastMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        salesForecast: {
            __typename?: 'SubprojektItem';
            item?: { __typename?: 'Subprojekt'; umsatzPrognose?: number | null } | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type MeasureFieldsFragment = {
    __typename?: 'Aufmass';
    id: number;
    bezeichnung: string;
    subprojektWohneinheitId: number;
    typSnippetId: number;
    laenge?: number | null;
    breite?: number | null;
    hoehe?: number | null;
    subprojektWohneinheit?: {
        __typename?: 'SubprojektWohneinheit';
        id: number;
        wohneinheitId: number;
        wattroProjectExists: boolean;
        wohneinheit?: {
            __typename?: 'Wohneinheit';
            id: number;
            nr: number;
            bezeichnung: string;
        } | null;
    } | null;
    typSnippet?: {
        __typename?: 'Snippet';
        id: number;
        name: string;
        kategorie: string;
        eigenschaft: string;
    } | null;
} & { ' $fragmentName'?: 'MeasureFieldsFragment' };

export type CreateMeasureMutationVariables = Exact<{
    data: AufmassInput;
}>;

export type CreateMeasureMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AufmassItem';
        item?:
            | ({ __typename?: 'Aufmass' } & {
                  ' $fragmentRefs'?: { MeasureFieldsFragment: MeasureFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateMeasureMutationVariables = Exact<{
    id: Scalars['Int'];
    data: AufmassInput;
}>;

export type UpdateMeasureMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AufmassItem';
        item?:
            | ({ __typename?: 'Aufmass' } & {
                  ' $fragmentRefs'?: { MeasureFieldsFragment: MeasureFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteMeasureMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteMeasureMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AufmassItem';
        item?:
            | ({ __typename?: 'Aufmass' } & {
                  ' $fragmentRefs'?: { MeasureFieldsFragment: MeasureFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListMeasuresQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AufmassOrderByInput>> | InputMaybe<AufmassOrderByInput>>;
    filter: AufmassFilterInput;
}>;

export type ListMeasuresQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'AufmassCollection';
        items: Array<
            { __typename?: 'Aufmass' } & {
                ' $fragmentRefs'?: { MeasureFieldsFragment: MeasureFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetMeasureQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetMeasureQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'AufmassItem';
        item?:
            | ({ __typename?: 'Aufmass' } & {
                  ' $fragmentRefs'?: { MeasureFieldsFragment: MeasureFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateMeasureRemarkMutationVariables = Exact<{
    subprojectId: Scalars['Int'];
    remark: Scalars['String'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateMeasureRemarkMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        item: {
            __typename?: 'SubprojektItem';
            item?: { __typename?: 'Subprojekt'; id: number } | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type GetMeasureRemarkQueryVariables = Exact<{
    subprojectId: Scalars['Int'];
}>;

export type GetMeasureRemarkQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'SubprojektItem';
        item?: {
            __typename?: 'Subprojekt';
            id: number;
            bemerkungAufmass: string;
            version: number;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportDetailFieldsFragment = {
    __typename?: 'EinsatzberichtDetail';
    id: number;
    einsatzberichtId: number;
    einsatzberichtOptionId: number;
    anzahl?: number | null;
    jaNein?: boolean | null;
    text?: string | null;
    einsatzberichtOption?: {
        __typename?: 'EinsatzberichtOption';
        id: number;
        bezeichnung: string;
    } | null;
} & { ' $fragmentName'?: 'OperationReportDetailFieldsFragment' };

export type CreateOperationReportDetailMutationVariables = Exact<{
    data: EinsatzberichtDetailInput;
}>;

export type CreateOperationReportDetailMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtDetailItem';
        item?:
            | ({ __typename?: 'EinsatzberichtDetail' } & {
                  ' $fragmentRefs'?: {
                      OperationReportDetailFieldsFragment: OperationReportDetailFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportDetailMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtDetailInput;
}>;

export type UpdateOperationReportDetailMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtDetailItem';
        item?:
            | ({ __typename?: 'EinsatzberichtDetail' } & {
                  ' $fragmentRefs'?: {
                      OperationReportDetailFieldsFragment: OperationReportDetailFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportDetailMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportDetailMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtDetailItem';
        item?:
            | ({ __typename?: 'EinsatzberichtDetail' } & {
                  ' $fragmentRefs'?: {
                      OperationReportDetailFieldsFragment: OperationReportDetailFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportDetailsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<EinsatzberichtDetailOrderByInput>>
        | InputMaybe<EinsatzberichtDetailOrderByInput>
    >;
    filter: EinsatzberichtDetailFilterInput;
}>;

export type ListOperationReportDetailsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtDetailCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'EinsatzberichtDetail' } & {
                ' $fragmentRefs'?: {
                    OperationReportDetailFieldsFragment: OperationReportDetailFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportDetailQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportDetailQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtDetailItem';
        item?:
            | ({ __typename?: 'EinsatzberichtDetail' } & {
                  ' $fragmentRefs'?: {
                      OperationReportDetailFieldsFragment: OperationReportDetailFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportModuleFieldsFragment = {
    __typename?: 'EinsatzberichtModul';
    id: number;
    name: string;
    deleted?: any | null;
} & { ' $fragmentName'?: 'OperationReportModuleFieldsFragment' };

export type CreateOperationReportModuleMutationVariables = Exact<{
    data: EinsatzberichtModulInput;
}>;

export type CreateOperationReportModuleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportModuleFieldsFragment: OperationReportModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportModuleMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtModulInput;
}>;

export type UpdateOperationReportModuleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportModuleFieldsFragment: OperationReportModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportModuleMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportModuleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportModuleFieldsFragment: OperationReportModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportModulesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<EinsatzberichtModulOrderByInput>>
        | InputMaybe<EinsatzberichtModulOrderByInput>
    >;
    filter: EinsatzberichtModulFilterInput;
}>;

export type ListOperationReportModulesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtModulCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'EinsatzberichtModul' } & {
                ' $fragmentRefs'?: {
                    OperationReportModuleFieldsFragment: OperationReportModuleFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportModuleQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportModuleQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportModuleFieldsFragment: OperationReportModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportOptionFieldsFragment = {
    __typename?: 'EinsatzberichtOption';
    id: number;
    typ: EinsatzberichtOptionTyp;
    bezeichnung: string;
    deleted?: any | null;
    einsatzberichtModulId: number;
    einsatzberichtModul?: { __typename?: 'EinsatzberichtModul'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'OperationReportOptionFieldsFragment' };

export type CreateOperationReportOptionMutationVariables = Exact<{
    data: EinsatzberichtOptionInput;
}>;

export type CreateOperationReportOptionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtOptionItem';
        item?:
            | ({ __typename?: 'EinsatzberichtOption' } & {
                  ' $fragmentRefs'?: {
                      OperationReportOptionFieldsFragment: OperationReportOptionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportOptionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtOptionInput;
}>;

export type UpdateOperationReportOptionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtOptionItem';
        item?:
            | ({ __typename?: 'EinsatzberichtOption' } & {
                  ' $fragmentRefs'?: {
                      OperationReportOptionFieldsFragment: OperationReportOptionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportOptionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportOptionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtOptionItem';
        item?:
            | ({ __typename?: 'EinsatzberichtOption' } & {
                  ' $fragmentRefs'?: {
                      OperationReportOptionFieldsFragment: OperationReportOptionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportOptionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<EinsatzberichtOptionOrderByInput>>
        | InputMaybe<EinsatzberichtOptionOrderByInput>
    >;
    filter: EinsatzberichtOptionFilterInput;
}>;

export type ListOperationReportOptionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtOptionCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'EinsatzberichtOption' } & {
                ' $fragmentRefs'?: {
                    OperationReportOptionFieldsFragment: OperationReportOptionFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportOptionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportOptionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtOptionItem';
        item?:
            | ({ __typename?: 'EinsatzberichtOption' } & {
                  ' $fragmentRefs'?: {
                      OperationReportOptionFieldsFragment: OperationReportOptionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportTradeFieldsFragment = {
    __typename?: 'EinsatzberichtGewerk';
    id: number;
    einsatzberichtId: number;
    gewerkId: number;
    gewerk?: { __typename?: 'Gewerk'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'OperationReportTradeFieldsFragment' };

export type CreateOperationReportTradeMutationVariables = Exact<{
    data: EinsatzberichtGewerkInput;
}>;

export type CreateOperationReportTradeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtGewerkItem';
        item?:
            | ({ __typename?: 'EinsatzberichtGewerk' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTradeFieldsFragment: OperationReportTradeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportTradeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtGewerkInput;
}>;

export type UpdateOperationReportTradeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtGewerkItem';
        item?:
            | ({ __typename?: 'EinsatzberichtGewerk' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTradeFieldsFragment: OperationReportTradeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportTradeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportTradeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtGewerkItem';
        item?:
            | ({ __typename?: 'EinsatzberichtGewerk' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTradeFieldsFragment: OperationReportTradeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportTradesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<EinsatzberichtGewerkOrderByInput>>
        | InputMaybe<EinsatzberichtGewerkOrderByInput>
    >;
    filter: EinsatzberichtGewerkFilterInput;
}>;

export type ListOperationReportTradesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtGewerkCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'EinsatzberichtGewerk' } & {
                ' $fragmentRefs'?: {
                    OperationReportTradeFieldsFragment: OperationReportTradeFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportTradeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportTradeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtGewerkItem';
        item?:
            | ({ __typename?: 'EinsatzberichtGewerk' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTradeFieldsFragment: OperationReportTradeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportTypeModuleFieldsFragment = {
    __typename?: 'EinsatzberichtTypModul';
    id: number;
    einsatzberichtTypId: number;
    einsatzberichtModulId: number;
    einsatzberichtTyp?: { __typename?: 'EinsatzberichtTyp'; id: number; name: string } | null;
    einsatzberichtModul?: { __typename?: 'EinsatzberichtModul'; id: number; name: string } | null;
} & { ' $fragmentName'?: 'OperationReportTypeModuleFieldsFragment' };

export type CreateOperationReportTypeModuleMutationVariables = Exact<{
    data: EinsatzberichtTypModulInput;
}>;

export type CreateOperationReportTypeModuleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtTypModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTypModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeModuleFieldsFragment: OperationReportTypeModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportTypeModuleMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtTypModulInput;
}>;

export type UpdateOperationReportTypeModuleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtTypModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTypModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeModuleFieldsFragment: OperationReportTypeModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportTypeModuleMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportTypeModuleMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtTypModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTypModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeModuleFieldsFragment: OperationReportTypeModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportTypeModulesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<EinsatzberichtTypModulOrderByInput>>
        | InputMaybe<EinsatzberichtTypModulOrderByInput>
    >;
    filter: EinsatzberichtTypModulFilterInput;
}>;

export type ListOperationReportTypeModulesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtTypModulCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'EinsatzberichtTypModul' } & {
                ' $fragmentRefs'?: {
                    OperationReportTypeModuleFieldsFragment: OperationReportTypeModuleFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportTypeModuleQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportTypeModuleQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtTypModulItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTypModul' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeModuleFieldsFragment: OperationReportTypeModuleFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportTypeFieldsFragment = {
    __typename?: 'EinsatzberichtTyp';
    id: number;
    name: string;
} & { ' $fragmentName'?: 'OperationReportTypeFieldsFragment' };

export type CreateOperationReportTypeMutationVariables = Exact<{
    data: EinsatzberichtTypInput;
}>;

export type CreateOperationReportTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtTypItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTyp' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeFieldsFragment: OperationReportTypeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportTypeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtTypInput;
}>;

export type UpdateOperationReportTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtTypItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTyp' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeFieldsFragment: OperationReportTypeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportTypeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportTypeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtTypItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTyp' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeFieldsFragment: OperationReportTypeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportTypesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<EinsatzberichtTypOrderByInput>> | InputMaybe<EinsatzberichtTypOrderByInput>
    >;
    filter: EinsatzberichtTypFilterInput;
}>;

export type ListOperationReportTypesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtTypCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'EinsatzberichtTyp' } & {
                ' $fragmentRefs'?: {
                    OperationReportTypeFieldsFragment: OperationReportTypeFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportTypeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportTypeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtTypItem';
        item?:
            | ({ __typename?: 'EinsatzberichtTyp' } & {
                  ' $fragmentRefs'?: {
                      OperationReportTypeFieldsFragment: OperationReportTypeFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type OperationReportFieldsFragment = {
    __typename?: 'Einsatzbericht';
    id: number;
    datum?: any | null;
    deleted?: any | null;
    kontaktId?: number | null;
    subprojektId: number;
    anfahrtFrom?: any | null;
    anfahrtUntil?: any | null;
    einsatzFrom?: any | null;
    einsatzUntil?: any | null;
    abfahrtFrom?: any | null;
    abfahrtUntil?: any | null;
    mitarbeiterIdUrheber?: number | null;
    einsatzberichtTyp?: { __typename?: 'EinsatzberichtTyp'; id: number; name: string } | null;
    kontakt?: {
        __typename?: 'Kontakt';
        id: number;
        name: string;
        vorname: string;
        firma1: string;
        firma2: string;
    } | null;
    einsatzberichtSachbearbeiter?: Array<{
        __typename?: 'EinsatzberichtSachbearbeiter';
        id: number;
        einsatzberichtId: number;
        mitarbeiterId: number;
        mitarbeiter?: {
            __typename?: 'Mitarbeiter';
            id: number;
            name: string;
            vorname: string;
        } | null;
    } | null> | null;
    urheber?: { __typename?: 'Mitarbeiter'; id: number; name: string; vorname: string } | null;
    einsatzberichtDetail?: Array<{
        __typename?: 'EinsatzberichtDetail';
        id: number;
        einsatzberichtOptionId: number;
        jaNein?: boolean | null;
        anzahl?: number | null;
        text?: string | null;
        einsatzberichtOption?: {
            __typename?: 'EinsatzberichtOption';
            id: number;
            bezeichnung: string;
            typ: EinsatzberichtOptionTyp;
        } | null;
    } | null> | null;
} & { ' $fragmentName'?: 'OperationReportFieldsFragment' };

export type UpsertOperationReportMutationVariables = Exact<{
    einsatzbericht?: InputMaybe<EinsatzberichtInput>;
    sachbearbeiterIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    einsatzberichtDetails?: InputMaybe<
        Array<UpsertEinsatzberichtDetailInput> | UpsertEinsatzberichtDetailInput
    >;
    id?: InputMaybe<Scalars['Int']>;
}>;

export type UpsertOperationReportMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        upsertEinsatzbericht: {
            __typename?: 'UpsertEinsatzberichtReturn';
            einsatzbericht?:
                | ({ __typename?: 'Einsatzbericht' } & {
                      ' $fragmentRefs'?: {
                          OperationReportFieldsFragment: OperationReportFieldsFragment;
                      };
                  })
                | null;
            detailsError?: Array<{
                __typename?: 'UpsertEinsatzberichtDetailsErrorReturn';
                einsatzberichtDetail: {
                    __typename?: 'UpsertEinsatzberichtDetailInputAsOutput';
                    anzahl?: number | null;
                    einsatzberichtOptionId: number;
                    jaNein?: boolean | null;
                    text?: string | null;
                };
                error: { __typename?: 'Error'; code: string; data: string; message: string };
            }> | null;
            globalError?: {
                __typename?: 'Error';
                code: string;
                data: string;
                message: string;
            } | null;
        };
    } | null;
};

export type CreateOperationReportMutationVariables = Exact<{
    data: EinsatzberichtInput;
}>;

export type CreateOperationReportMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtItem';
        item?:
            | ({ __typename?: 'Einsatzbericht' } & {
                  ' $fragmentRefs'?: {
                      OperationReportFieldsFragment: OperationReportFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateOperationReportMutationVariables = Exact<{
    id: Scalars['Int'];
    data: EinsatzberichtInput;
}>;

export type UpdateOperationReportMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtItem';
        item?:
            | ({ __typename?: 'Einsatzbericht' } & {
                  ' $fragmentRefs'?: {
                      OperationReportFieldsFragment: OperationReportFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteOperationReportMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteOperationReportMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'EinsatzberichtItem';
        item?:
            | ({ __typename?: 'Einsatzbericht' } & {
                  ' $fragmentRefs'?: {
                      OperationReportFieldsFragment: OperationReportFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListOperationReportsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<EinsatzberichtOrderByInput>> | InputMaybe<EinsatzberichtOrderByInput>
    >;
    filter: EinsatzberichtFilterInput;
}>;

export type ListOperationReportsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'EinsatzberichtCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Einsatzbericht' } & {
                ' $fragmentRefs'?: { OperationReportFieldsFragment: OperationReportFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetOperationReportQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetOperationReportQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'EinsatzberichtItem';
        item?:
            | ({ __typename?: 'Einsatzbericht' } & {
                  ' $fragmentRefs'?: {
                      OperationReportFieldsFragment: OperationReportFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ParticipantFieldsFragment = {
    __typename?: 'Beteiligter';
    id: number;
    version: number;
    kontaktId: number;
    typSnippetId: number;
    subprojektWohneinheitId?: number | null;
    kontakt?: {
        __typename?: 'Kontakt';
        id: number;
        version: number;
        anredeSnippetId?: number | null;
        email: string;
        fax: string;
        firma1: string;
        firma2: string;
        landSnippetId?: number | null;
        name: string;
        ort: string;
        plz: string;
        postfach: string;
        statusSnippetId?: number | null;
        strasse: string;
        telefon: string;
        telefonDirekt: string;
        telefonMobil: string;
        telefonPrivat: string;
        typSnippetId?: number | null;
        vorname: string;
        zustellOrt: string;
        zustellPlz: string;
        zustellStrasse: string;
        freitext: { __typename?: 'Richtext'; html: string; raw: any };
        typSnippet?: { __typename?: 'Snippet'; id: number; name: string; kategorie: string } | null;
    } | null;
    typSnippet?: { __typename?: 'Snippet'; id: number; name: string; kategorie: string } | null;
} & { ' $fragmentName'?: 'ParticipantFieldsFragment' };

export type CreateParticipantMutationVariables = Exact<{
    data: BeteiligterInput;
}>;

export type CreateParticipantMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'BeteiligterItem';
        item?:
            | ({ __typename?: 'Beteiligter' } & {
                  ' $fragmentRefs'?: { ParticipantFieldsFragment: ParticipantFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateParticipantMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: BeteiligterInput;
}>;

export type UpdateParticipantMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'BeteiligterItem';
        item?:
            | ({ __typename?: 'Beteiligter' } & {
                  ' $fragmentRefs'?: { ParticipantFieldsFragment: ParticipantFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteParticipantMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteParticipantMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'BeteiligterItem';
        item?:
            | ({ __typename?: 'Beteiligter' } & {
                  ' $fragmentRefs'?: { ParticipantFieldsFragment: ParticipantFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListParticipantsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<BeteiligterOrderByInput>> | InputMaybe<BeteiligterOrderByInput>
    >;
    filter?: InputMaybe<BeteiligterFilterInput>;
}>;

export type ListParticipantsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'BeteiligterCollection';
        items: Array<
            { __typename?: 'Beteiligter' } & {
                ' $fragmentRefs'?: { ParticipantFieldsFragment: ParticipantFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SubprojektSucheFieldsFragment = {
    __typename?: 'Subprojekt';
    id: number;
    lfdNr: number;
    auftragsNr: string;
    anlageDatum: any;
    bemerkungAufmass: string;
    abrechnungsDatum?: any | null;
    bonitaetSnippetId: number;
    statusSnippetId: number;
    projekttypId: number;
    projektId: number;
    bonitaetSnippet?: { __typename?: 'Snippet'; id: number; name: string; kuerzel: string } | null;
    statusSnippet?: { __typename?: 'Snippet'; id: number; name: string; kuerzel: string } | null;
    projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
    projekt?: {
        __typename?: 'Projekt';
        id: number;
        lfdNr: number;
        anlageDatum: any;
        plz: string;
        ort: string;
        strasse: string;
        niederlassungId: number;
        schadenTag?: any | null;
        statusSnippetId: number;
        niederlassung?: {
            __typename?: 'Niederlassung';
            id: number;
            nummer: number;
            bezeichnung: string;
        } | null;
        statusSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
    } | null;
    beteiligte?: Array<{
        __typename?: 'Beteiligter';
        id: number;
        version: number;
        kontaktId: number;
        typSnippetId: number;
        kontakt?: {
            __typename?: 'Kontakt';
            id: number;
            version: number;
            name: string;
            vorname: string;
            firma1: string;
            firma2: string;
            plz: string;
            ort: string;
            strasse: string;
            telefon: string;
            telefonDirekt: string;
            telefonMobil: string;
            telefonPrivat: string;
            email: string;
            fax: string;
        } | null;
        typSnippet?: { __typename?: 'Snippet'; name: string; kategorie: string } | null;
    } | null> | null;
} & { ' $fragmentName'?: 'SubprojektSucheFieldsFragment' };

export type SubprojektFieldsFragment = {
    __typename?: 'Subprojekt';
    id: number;
    version: number;
    deleted?: any | null;
    lfdNr: number;
    weg: string;
    auftragsNr: string;
    anlageDatum: any;
    bemerkungAufmass: string;
    abrechnungsDatum?: any | null;
    bonitaetSnippetId: number;
    buergschaft: number;
    ihrZeichen: string;
    notdienst: boolean;
    schadenNr: string;
    scheinNr: string;
    versicherungId?: number | null;
    versicherungsArtSnippetId?: number | null;
    umsatzPrognose?: number | null;
    statusSnippetId: number;
    rahmenvertragId?: number | null;
    projekttypId: number;
    projektId: number;
    bemerkung: { __typename?: 'Richtext'; html: string; raw: any };
    bonitaetSnippet?: { __typename?: 'Snippet'; id: number; name: string; kuerzel: string } | null;
    versicherung?: { __typename?: 'Versicherung'; id: number; bezeichnung: string } | null;
    versicherungsArtSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
    termine?: Array<{
        __typename?: 'Termin';
        id: number;
        version: number;
        angelegt?: any | null;
        geplant?: any | null;
        erledigt?: any | null;
        dauer: number;
        mitarbeiterIdSachbearbeiter: number;
        mitarbeiterIdUrheber?: number | null;
        prio?: number | null;
        typSnippetId: number;
        typSnippet?: { __typename?: 'Snippet'; id: number; name: string; kuerzel: string } | null;
    } | null> | null;
    subprojektWohneinheit?: Array<{
        __typename?: 'SubprojektWohneinheit';
        id: number;
        wohneinheitId: number;
        deleted?: any | null;
        wattroProjectExists: boolean;
        beteiligter?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            version: number;
            typSnippetId: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                version: number;
                firma1: string;
                firma2: string;
                name: string;
                vorname: string;
            } | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
        wohneinheit?: {
            __typename?: 'Wohneinheit';
            id: number;
            nr: number;
            bezeichnung: string;
            istGesamtobjekt: boolean;
        } | null;
    } | null> | null;
    statusSnippet?: { __typename?: 'Snippet'; id: number; name: string; kuerzel: string } | null;
    subprojektGewerk?: Array<{
        __typename?: 'SubprojektGewerk';
        id: number;
        gewerkId: number;
        gewerk?: { __typename?: 'Gewerk'; id: number; name: string } | null;
    } | null> | null;
    rahmenvertrag?: {
        __typename?: 'Rahmenvertrag';
        id: number;
        isStandard: boolean;
        bezeichnung: string;
    } | null;
    projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
    projekt?: {
        __typename?: 'Projekt';
        id: number;
        version: number;
        lfdNr: number;
        anlageDatum: any;
        plz: string;
        ort: string;
        strasse: string;
        niederlassungId: number;
        schadenTag?: any | null;
        statusSnippetId: number;
        niederlassung?: {
            __typename?: 'Niederlassung';
            id: number;
            nummer: number;
            bezeichnung: string;
        } | null;
        statusSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
    } | null;
    beteiligte?: Array<{
        __typename?: 'Beteiligter';
        id: number;
        version: number;
        kontaktId: number;
        typSnippetId: number;
        kontakt?: {
            __typename?: 'Kontakt';
            id: number;
            version: number;
            name: string;
            vorname: string;
            firma1: string;
            firma2: string;
            plz: string;
            ort: string;
            strasse: string;
            telefon: string;
            telefonDirekt: string;
            telefonMobil: string;
            telefonPrivat: string;
            email: string;
            fax: string;
            freitext: { __typename?: 'Richtext'; html: string; raw: any };
        } | null;
        typSnippet?: { __typename?: 'Snippet'; name: string; kategorie: string } | null;
    } | null> | null;
} & { ' $fragmentName'?: 'SubprojektFieldsFragment' };

export type ProjektFieldsFragment = {
    __typename?: 'Projekt';
    id: number;
    version: number;
    deleted?: any | null;
    lfdNr: number;
    anlageDatum: any;
    schadenTag?: any | null;
    niederlassungId: number;
    plz: string;
    ort: string;
    strasse: string;
    statusSnippetId: number;
    niederlassung?: {
        __typename?: 'Niederlassung';
        id: number;
        bezeichnung: string;
        nummer: number;
    } | null;
    statusSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
    subprojekt?: Array<{
        __typename?: 'Subprojekt';
        id: number;
        version: number;
        anlageDatum: any;
        projektId: number;
        versicherungsArtSnippetId?: number | null;
        lfdNr: number;
        projekttypId: number;
        intern: boolean;
        weg: string;
        ihrZeichen: string;
        buergschaft: number;
        schadenNr: string;
        scheinNr: string;
        notdienst: boolean;
        rahmenvertragId?: number | null;
        versicherungId?: number | null;
        bonitaetSnippetId: number;
        subprojektWohneinheit?: Array<{
            __typename?: 'SubprojektWohneinheit';
            id: number;
            wohneinheitId: number;
            wattroProjectExists: boolean;
            beteiligter?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                version: number;
                typSnippetId: number;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    version: number;
                    firma1: string;
                    firma2: string;
                    name: string;
                    vorname: string;
                } | null;
                typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
            } | null> | null;
            wohneinheit?: {
                __typename?: 'Wohneinheit';
                id: number;
                nr: number;
                bezeichnung: string;
            } | null;
        } | null> | null;
        projekt?: {
            __typename?: 'Projekt';
            id: number;
            lfdNr: number;
            anlageDatum: any;
            niederlassung?: { __typename?: 'Niederlassung'; id: number; nummer: number } | null;
        } | null;
        beteiligte?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            version: number;
            kontaktId: number;
            typSnippetId: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                version: number;
                name: string;
                vorname: string;
                firma1: string;
                firma2: string;
            } | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
        bemerkung: { __typename?: 'Richtext'; html: string; raw: any };
        subprojektGewerk?: Array<{
            __typename?: 'SubprojektGewerk';
            id: number;
            gewerk?: { __typename?: 'Gewerk'; id: number; name: string } | null;
        } | null> | null;
        projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
    } | null> | null;
    wohneinheit?: Array<{
        __typename?: 'Wohneinheit';
        id: number;
        nr: number;
        bezeichnung: string;
        istGesamtobjekt: boolean;
    } | null> | null;
} & { ' $fragmentName'?: 'ProjektFieldsFragment' };

export type CreateSubprojektToProjektMutationVariables = Exact<{
    input: CreateSubprojektToProjektInput;
}>;

export type CreateSubprojektToProjektMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        subproject: {
            __typename?: 'SubprojektItem';
            item?:
                | ({ __typename?: 'Subprojekt' } & {
                      ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
                  })
                | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type UpdateSubprojectWithProjectMutationVariables = Exact<{
    input: UpdateSubprojektWithProjektInput;
}>;

export type UpdateSubprojectWithProjectMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        subproject: {
            __typename?: 'SubprojektItem';
            item?:
                | ({ __typename?: 'Subprojekt' } & {
                      ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
                  })
                | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type SubprojectCsvExportQueryVariables = Exact<{
    searchInput: SubprojektSearch;
    orderBy?: InputMaybe<
        Array<InputMaybe<SubprojektOrderByInput>> | InputMaybe<SubprojektOrderByInput>
    >;
}>;

export type SubprojectCsvExportQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        subprojectCsvExport: {
            __typename?: 'CsvItem';
            item?: string | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type SubprojectSearchQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<SubprojektOrderByInput>> | InputMaybe<SubprojektOrderByInput>
    >;
    searchInput: SubprojektSearch;
}>;

export type SubprojectSearchQuery = {
    __typename?: 'Query';
    app?: {
        __typename?: 'AppQuery';
        subprojektSearch: {
            __typename?: 'SubprojektCollection';
            pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
            items: Array<
                { __typename?: 'Subprojekt' } & {
                    ' $fragmentRefs'?: {
                        SubprojektSucheFieldsFragment: SubprojektSucheFieldsFragment;
                    };
                }
            >;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type CreateSubprojectMutationVariables = Exact<{
    data: SubprojektInput;
}>;

export type CreateSubprojectMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SubprojektItem';
        item?:
            | ({ __typename?: 'Subprojekt' } & {
                  ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateSubprojectMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: SubprojektInput;
}>;

export type UpdateSubprojectMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SubprojektItem';
        item?:
            | ({ __typename?: 'Subprojekt' } & {
                  ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteSubprojectMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteSubprojectMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SubprojektItem';
        item?:
            | ({ __typename?: 'Subprojekt' } & {
                  ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListSubprojectsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<SubprojektOrderByInput>> | InputMaybe<SubprojektOrderByInput>
    >;
    filter: SubprojektFilterInput;
}>;

export type ListSubprojectsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'SubprojektCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Subprojekt' } & {
                ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetSubprojectQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetSubprojectQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'SubprojektItem';
        item?:
            | ({ __typename?: 'Subprojekt' } & {
                  ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CreateProjectMutationVariables = Exact<{
    data: ProjektInput;
}>;

export type CreateProjectMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'ProjektItem';
        item?:
            | ({ __typename?: 'Projekt' } & {
                  ' $fragmentRefs'?: { ProjektFieldsFragment: ProjektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateProjectMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: ProjektInput;
}>;

export type UpdateProjectMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'ProjektItem';
        item?:
            | ({ __typename?: 'Projekt' } & {
                  ' $fragmentRefs'?: { ProjektFieldsFragment: ProjektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CreateSubprojektWithProjectMutationVariables = Exact<{
    input: CreateSubprojektWithProjektInput;
}>;

export type CreateSubprojektWithProjectMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        subproject: {
            __typename?: 'SubprojektItem';
            item?:
                | ({ __typename?: 'Subprojekt' } & {
                      ' $fragmentRefs'?: { SubprojektFieldsFragment: SubprojektFieldsFragment };
                  })
                | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type DeleteProjectMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteProjectMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'ProjektItem';
        item?:
            | ({ __typename?: 'Projekt' } & {
                  ' $fragmentRefs'?: { ProjektFieldsFragment: ProjektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListProjectsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<ProjektOrderByInput>> | InputMaybe<ProjektOrderByInput>>;
    filter?: InputMaybe<ProjektFilterInput>;
}>;

export type ListProjectsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'ProjektCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Projekt' } & {
                ' $fragmentRefs'?: { ProjektFieldsFragment: ProjektFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetProjectQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetProjectQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'ProjektItem';
        item?:
            | ({ __typename?: 'Projekt' } & {
                  ' $fragmentRefs'?: { ProjektFieldsFragment: ProjektFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ProjectDetailsQueryVariables = Exact<{
    projektId: Scalars['Int'];
    subprojektId: Scalars['Int'];
}>;

export type ProjectDetailsQuery = {
    __typename?: 'Query';
    project: {
        __typename?: 'ProjektItem';
        item?: {
            __typename?: 'Projekt';
            id: number;
            version: number;
            lfdNr: number;
            anlageDatum: any;
            strasse: string;
            plz: string;
            ort: string;
            niederlassungId: number;
            schadenTag?: any | null;
            statusSnippetId: number;
            niederlassung?: {
                __typename?: 'Niederlassung';
                id: number;
                nummer: number;
                bezeichnung: string;
            } | null;
            statusSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
    subproject: {
        __typename?: 'SubprojektItem';
        item?: {
            __typename?: 'Subprojekt';
            id: number;
            version: number;
            lfdNr: number;
            projekttypId: number;
            notdienst: boolean;
            intern: boolean;
            weg: string;
            rahmenvertragId?: number | null;
            ihrZeichen: string;
            bonitaetSnippetId: number;
            buergschaft: number;
            versicherungId?: number | null;
            versicherungsArtSnippetId?: number | null;
            schadenNr: string;
            scheinNr: string;
            umsatzPrognose?: number | null;
            abrechnungsDatum?: any | null;
            auftragsNr: string;
            bemerkungAufmass: string;
            bemerkung: { __typename?: 'Richtext'; html: string; raw: any };
            projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
            subprojektGewerk?: Array<{
                __typename?: 'SubprojektGewerk';
                gewerkId: number;
                gewerk?: { __typename?: 'Gewerk'; id: number; name: string } | null;
            } | null> | null;
            rahmenvertrag?: {
                __typename?: 'Rahmenvertrag';
                id: number;
                bezeichnung: string;
            } | null;
            bonitaetSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
            versicherung?: { __typename?: 'Versicherung'; id: number; bezeichnung: string } | null;
            versicherungsArtSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
            subprojektWohneinheit?: Array<{
                __typename?: 'SubprojektWohneinheit';
                id: number;
                wattroProjectExists: boolean;
                wohneinheitId: number;
                beteiligter?: Array<{
                    __typename?: 'Beteiligter';
                    id: number;
                    version: number;
                    typSnippetId: number;
                    kontakt?: {
                        __typename?: 'Kontakt';
                        id: number;
                        version: number;
                        firma1: string;
                        firma2: string;
                        name: string;
                        vorname: string;
                    } | null;
                    typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
                } | null> | null;
                wohneinheit?: {
                    __typename?: 'Wohneinheit';
                    id: number;
                    nr: number;
                    bezeichnung: string;
                } | null;
            } | null> | null;
            beteiligte?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                version: number;
                kontaktId: number;
                typSnippetId: number;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    version: number;
                    name: string;
                    vorname: string;
                    firma1: string;
                    firma2: string;
                    plz: string;
                    ort: string;
                    strasse: string;
                    telefon: string;
                    telefonDirekt: string;
                    telefonMobil: string;
                    telefonPrivat: string;
                    email: string;
                    fax: string;
                } | null;
                typSnippet?: { __typename?: 'Snippet'; name: string; kategorie: string } | null;
            } | null> | null;
            statusSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SelectProjectTypeQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<ProjekttypOrderByInput>> | InputMaybe<ProjekttypOrderByInput>
    >;
    filter?: InputMaybe<ProjekttypFilterInput>;
}>;

export type SelectProjectTypeQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'ProjekttypCollection';
        items: Array<{ __typename?: 'Projekttyp'; value: number; label: string }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SelectBranchesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<NiederlassungOrderByInput>> | InputMaybe<NiederlassungOrderByInput>
    >;
    filter?: InputMaybe<NiederlassungFilterInput>;
}>;

export type SelectBranchesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'NiederlassungCollection';
        items: Array<{
            __typename?: 'Niederlassung';
            nummer: number;
            bezeichnung: string;
            value: number;
            label: string;
        }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SelectTradeQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<GewerkOrderByInput>> | InputMaybe<GewerkOrderByInput>>;
    filter?: InputMaybe<GewerkFilterInput>;
}>;

export type SelectTradeQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'GewerkCollection';
        items: Array<{ __typename?: 'Gewerk'; value: number; label: string }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SelectGeneralAgreementsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<RahmenvertragOrderByInput>> | InputMaybe<RahmenvertragOrderByInput>
    >;
    filter?: InputMaybe<RahmenvertragFilterInput>;
}>;

export type SelectGeneralAgreementsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'RahmenvertragCollection';
        items: Array<{ __typename?: 'Rahmenvertrag'; value: number; label: string }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SelectInsurancesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<VersicherungOrderByInput>> | InputMaybe<VersicherungOrderByInput>
    >;
    filter?: InputMaybe<VersicherungFilterInput>;
}>;

export type SelectInsurancesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'VersicherungCollection';
        items: Array<{ __typename?: 'Versicherung'; value: number; label: string }>;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ResidentialUnitFieldsFragment = {
    __typename?: 'Wohneinheit';
    id: number;
    bezeichnung: string;
    nr: number;
    istGesamtobjekt: boolean;
    subprojektWohneinheit?: Array<{
        __typename?: 'SubprojektWohneinheit';
        id: number;
        projektId: number;
        deleted?: any | null;
        wattroProjectExists: boolean;
        subprojektId: number;
        aufmass?: Array<{
            __typename?: 'Aufmass';
            id: number;
            bezeichnung: string;
            laenge?: number | null;
            breite?: number | null;
            hoehe?: number | null;
        } | null> | null;
        wohneinheit?: {
            __typename?: 'Wohneinheit';
            id: number;
            projekt?: {
                __typename?: 'Projekt';
                id: number;
                plz: string;
                ort: string;
                strasse: string;
            } | null;
        } | null;
        subprojekt?: {
            __typename?: 'Subprojekt';
            id: number;
            beteiligte?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                kontaktId: number;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    name: string;
                    vorname: string;
                    firma1: string;
                    firma2: string;
                } | null;
            } | null> | null;
        } | null;
        beteiligter?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            version: number;
            typSnippetId: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                version: number;
                anredeSnippetId?: number | null;
                email: string;
                fax: string;
                firma1: string;
                firma2: string;
                landSnippetId?: number | null;
                name: string;
                ort: string;
                plz: string;
                postfach: string;
                statusSnippetId?: number | null;
                strasse: string;
                telefon: string;
                telefonDirekt: string;
                telefonMobil: string;
                telefonPrivat: string;
                typSnippetId?: number | null;
                vorname: string;
                zustellOrt: string;
                zustellPlz: string;
                zustellStrasse: string;
                freitext: { __typename?: 'Richtext'; html: string; raw: any };
                typSnippet?: {
                    __typename?: 'Snippet';
                    id: number;
                    name: string;
                    kategorie: string;
                } | null;
            } | null;
        } | null> | null;
    } | null> | null;
} & { ' $fragmentName'?: 'ResidentialUnitFieldsFragment' };

export type CreateSubprojectResidentialUnitMutationVariables = Exact<{
    data: SubprojektWohneinheitInput;
}>;

export type CreateSubprojectResidentialUnitMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SubprojektWohneinheitItem';
        item?: {
            __typename?: 'SubprojektWohneinheit';
            id: number;
            projektId: number;
            subprojektId: number;
            wohneinheitId: number;
            wohneinheit?: {
                __typename?: 'Wohneinheit';
                id: number;
                nr: number;
                bezeichnung: string;
                istGesamtobjekt: boolean;
            } | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type CreateResidentialUnitMutationVariables = Exact<{
    bezeichnung: Scalars['String'];
    subprojektId: Scalars['Int'];
}>;

export type CreateResidentialUnitMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        item: {
            __typename?: 'WohneinheitItem';
            item?:
                | ({ __typename?: 'Wohneinheit' } & {
                      ' $fragmentRefs'?: {
                          ResidentialUnitFieldsFragment: ResidentialUnitFieldsFragment;
                      };
                  })
                | null;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type UpdateResidentialUnitMutationVariables = Exact<{
    id: Scalars['Int'];
    data: WohneinheitInput;
}>;

export type UpdateResidentialUnitMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'WohneinheitItem';
        item?:
            | ({ __typename?: 'Wohneinheit' } & {
                  ' $fragmentRefs'?: {
                      ResidentialUnitFieldsFragment: ResidentialUnitFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteResidentialUnitMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteResidentialUnitMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'WohneinheitItem';
        item?:
            | ({ __typename?: 'Wohneinheit' } & {
                  ' $fragmentRefs'?: {
                      ResidentialUnitFieldsFragment: ResidentialUnitFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListResidentialUnitsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<WohneinheitOrderByInput>> | InputMaybe<WohneinheitOrderByInput>
    >;
    filter?: InputMaybe<WohneinheitFilterInput>;
}>;

export type ListResidentialUnitsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'WohneinheitCollection';
        items: Array<
            { __typename?: 'Wohneinheit' } & {
                ' $fragmentRefs'?: { ResidentialUnitFieldsFragment: ResidentialUnitFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type SubprojektResidentialUnitFieldsFragment = {
    __typename?: 'SubprojektWohneinheit';
    id: number;
    projektId: number;
    subprojektId: number;
    wohneinheitId: number;
    beteiligter?: Array<{
        __typename?: 'Beteiligter';
        id: number;
        typSnippetId: number;
        kontakt?: { __typename?: 'Kontakt'; id: number } | null;
        typSnippet?: { __typename?: 'Snippet'; name: string; kategorie: string } | null;
    } | null> | null;
    subprojekt?: {
        __typename?: 'Subprojekt';
        id: number;
        beteiligte?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            kontaktId: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                name: string;
                vorname: string;
                firma1: string;
                firma2: string;
            } | null;
        } | null> | null;
    } | null;
    wohneinheit?: {
        __typename?: 'Wohneinheit';
        id: number;
        bezeichnung: string;
        nr: number;
        istGesamtobjekt: boolean;
        projektId: number;
        projekt?: {
            __typename?: 'Projekt';
            id: number;
            plz: string;
            ort: string;
            strasse: string;
        } | null;
    } | null;
    aufmass?: Array<{
        __typename?: 'Aufmass';
        id: number;
        bezeichnung: string;
        laenge?: number | null;
        breite?: number | null;
        hoehe?: number | null;
    } | null> | null;
} & { ' $fragmentName'?: 'SubprojektResidentialUnitFieldsFragment' };

export type UpdateSubprojectResidentialUnitMutationVariables = Exact<{
    id: Scalars['Int'];
    data: SubprojektWohneinheitInput;
}>;

export type UpdateSubprojectResidentialUnitMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SubprojektWohneinheitItem';
        item?:
            | ({ __typename?: 'SubprojektWohneinheit' } & {
                  ' $fragmentRefs'?: {
                      SubprojektResidentialUnitFieldsFragment: SubprojektResidentialUnitFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteSubprojectResidentialUnitMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteSubprojectResidentialUnitMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'SubprojektWohneinheitItem';
        item?:
            | ({ __typename?: 'SubprojektWohneinheit' } & {
                  ' $fragmentRefs'?: {
                      SubprojektResidentialUnitFieldsFragment: SubprojektResidentialUnitFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListSubprojectResidentialUnitsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<SubprojektWohneinheitOrderByInput>>
        | InputMaybe<SubprojektWohneinheitOrderByInput>
    >;
    filter?: InputMaybe<SubprojektWohneinheitFilterInput>;
}>;

export type ListSubprojectResidentialUnitsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'SubprojektWohneinheitCollection';
        items: Array<
            { __typename?: 'SubprojektWohneinheit' } & {
                ' $fragmentRefs'?: {
                    SubprojektResidentialUnitFieldsFragment: SubprojektResidentialUnitFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type TaskFunctionFieldsFragment = {
    __typename?: 'Funktion';
    id: number;
    bezeichnung: string;
    aufgabe?: Array<{
        __typename?: 'Aufgabe';
        id: number;
        version: number;
        angelegt?: any | null;
        beschreibung: { __typename?: 'Richtext'; html: string; raw: any };
    } | null> | null;
    mitarbeiterFunktion?: Array<{
        __typename?: 'MitarbeiterFunktion';
        id: number;
        mitarbeiterId: number;
        mitarbeiter?: {
            __typename?: 'Mitarbeiter';
            id: number;
            name: string;
            vorname: string;
        } | null;
    } | null> | null;
} & { ' $fragmentName'?: 'TaskFunctionFieldsFragment' };

export type CreateTaskFunctionMutationVariables = Exact<{
    data: FunktionInput;
}>;

export type CreateTaskFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { TaskFunctionFieldsFragment: TaskFunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateTaskFunctionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: FunktionInput;
}>;

export type UpdateTaskFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { TaskFunctionFieldsFragment: TaskFunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteTaskFunctionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteTaskFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { TaskFunctionFieldsFragment: TaskFunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListTaskFunctionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<FunktionOrderByInput>> | InputMaybe<FunktionOrderByInput>
    >;
    filter: FunktionFilterInput;
}>;

export type ListTaskFunctionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'FunktionCollection';
        items: Array<
            { __typename?: 'Funktion' } & {
                ' $fragmentRefs'?: { TaskFunctionFieldsFragment: TaskFunctionFieldsFragment };
            }
        >;
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetTaskFunctionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetTaskFunctionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'FunktionItem';
        item?:
            | ({ __typename?: 'Funktion' } & {
                  ' $fragmentRefs'?: { TaskFunctionFieldsFragment: TaskFunctionFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type TaskFieldsFragment = {
    __typename?: 'Aufgabe';
    id: number;
    version: number;
    angelegt?: any | null;
    funktionId?: number | null;
    erledigt?: any | null;
    mitarbeiterIdSachbearbeiter?: number | null;
    mitarbeiterIdUrheber?: number | null;
    rolleId?: number | null;
    mitarbeiterIdAbschlusszustaendiger?: number | null;
    subprojektId: number;
    typSnippetId?: number | null;
    zuErledigen?: any | null;
    funktion?: {
        __typename?: 'Funktion';
        id: number;
        bezeichnung: string;
        deleted?: any | null;
    } | null;
    beschreibung: { __typename?: 'Richtext'; html: string; raw: any };
    rolle?: { __typename?: 'Rolle'; id: number; bezeichnung: string } | null;
    abschlusszustaendiger?: {
        __typename?: 'Mitarbeiter';
        name: string;
        vorname: string;
        kennung: string;
    } | null;
    subprojekt?: {
        __typename?: 'Subprojekt';
        lfdNr: number;
        id: number;
        projekttyp?: { __typename?: 'Projekttyp'; id: number; name: string } | null;
        beteiligte?: Array<{
            __typename?: 'Beteiligter';
            id: number;
            kontakt?: {
                __typename?: 'Kontakt';
                id: number;
                name: string;
                vorname: string;
                firma1: string;
                firma2: string;
            } | null;
            typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
        } | null> | null;
        projekt?: {
            __typename?: 'Projekt';
            plz: string;
            ort: string;
            strasse: string;
            niederlassungId: number;
            anlageDatum: any;
            id: number;
            lfdNr: number;
            niederlassung?: { __typename?: 'Niederlassung'; nummer: number } | null;
        } | null;
    } | null;
    typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
    urheber?: {
        __typename?: 'Mitarbeiter';
        name: string;
        vorname: string;
        kennung: string;
        deleted?: any | null;
    } | null;
    sachbearbeiter?: {
        __typename?: 'Mitarbeiter';
        name: string;
        vorname: string;
        kennung: string;
        deleted?: any | null;
    } | null;
} & { ' $fragmentName'?: 'TaskFieldsFragment' };

export type ListOpenTasksQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    mitarbeiterId: Scalars['Int'];
    niederlassungId?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AufgabeOrderByInput>> | InputMaybe<AufgabeOrderByInput>>;
}>;

export type ListOpenTasksQuery = {
    __typename?: 'Query';
    items?: {
        __typename?: 'AppQuery';
        offeneAufgaben: {
            __typename?: 'AufgabeCollection';
            pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
            items: Array<
                { __typename?: 'Aufgabe' } & {
                    ' $fragmentRefs'?: { TaskFieldsFragment: TaskFieldsFragment };
                }
            >;
            error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
        };
    } | null;
};

export type CreateTaskMutationVariables = Exact<{
    data: AufgabeCreateInput;
}>;

export type CreateTaskMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AufgabeItem';
        item?:
            | ({ __typename?: 'Aufgabe' } & {
                  ' $fragmentRefs'?: { TaskFieldsFragment: TaskFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateTaskMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
    data: AufgabeUpdateInput;
}>;

export type UpdateTaskMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AufgabeItem';
        item?:
            | ({ __typename?: 'Aufgabe' } & {
                  ' $fragmentRefs'?: { TaskFieldsFragment: TaskFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteTaskMutationVariables = Exact<{
    id: Scalars['Int'];
    version: Scalars['Int'];
    forceOverwrite?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteTaskMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AufgabeItem';
        item?:
            | ({ __typename?: 'Aufgabe' } & {
                  ' $fragmentRefs'?: { TaskFieldsFragment: TaskFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListTasksQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<InputMaybe<AufgabeOrderByInput>> | InputMaybe<AufgabeOrderByInput>>;
    filter: AufgabeFilterInput;
}>;

export type ListTasksQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'AufgabeCollection';
        items: Array<
            { __typename?: 'Aufgabe' } & {
                ' $fragmentRefs'?: { TaskFieldsFragment: TaskFieldsFragment };
            }
        >;
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetTaskQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetTaskQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'AufgabeItem';
        item?:
            | ({ __typename?: 'Aufgabe' } & {
                  ' $fragmentRefs'?: { TaskFieldsFragment: TaskFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type TaskDefaultValuesQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type TaskDefaultValuesQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'SubprojektItem';
        item?: {
            __typename?: 'Subprojekt';
            id: number;
            lfdNr: number;
            projekt?: {
                __typename?: 'Projekt';
                id: number;
                lfdNr: number;
                strasse: string;
                ort: string;
                plz: string;
                niederlassungId: number;
                anlageDatum: any;
                niederlassung?: { __typename?: 'Niederlassung'; id: number; nummer: number } | null;
            } | null;
            beteiligte?: Array<{
                __typename?: 'Beteiligter';
                id: number;
                typSnippet?: { __typename?: 'Snippet'; id: number; name: string } | null;
                kontakt?: {
                    __typename?: 'Kontakt';
                    id: number;
                    firma1: string;
                    firma2: string;
                    name: string;
                    vorname: string;
                } | null;
            } | null> | null;
        } | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type WattroFieldsFragment = {
    __typename?: 'CommandResult';
    result?: boolean | null;
    error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
} & { ' $fragmentName'?: 'WattroFieldsFragment' };

export type WattroUpdateDeploymentsToProjektMutationVariables = Exact<{
    projektId: Scalars['Int'];
}>;

export type WattroUpdateDeploymentsToProjektMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        wattroUpdateDeploymentsToProjekt: { __typename?: 'CommandResult' } & {
            ' $fragmentRefs'?: { WattroFieldsFragment: WattroFieldsFragment };
        };
    } | null;
};

export type WattroCreateProjectForSubprojektWohneinheitMutationVariables = Exact<{
    subprojektWohneinheitId: Scalars['Int'];
}>;

export type WattroCreateProjectForSubprojektWohneinheitMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        wattroCreateProjectForSubprojektWohneinheit: { __typename?: 'CommandResult' } & {
            ' $fragmentRefs'?: { WattroFieldsFragment: WattroFieldsFragment };
        };
    } | null;
};

export type WattroAssignNotdienstprojektToSubprojektWohneinheitMutationVariables = Exact<{
    notdienstprojektId: Scalars['Int'];
    subprojektWohneinheitId: Scalars['Int'];
}>;

export type WattroAssignNotdienstprojektToSubprojektWohneinheitMutation = {
    __typename?: 'Mutation';
    app?: {
        __typename?: 'AppMutation';
        wattroAssignNotdienstprojektToSubprojektWohneinheit: { __typename?: 'CommandResult' } & {
            ' $fragmentRefs'?: { WattroFieldsFragment: WattroFieldsFragment };
        };
    } | null;
};

export type AbsenceFieldsFragment = {
    __typename?: 'Abwesenheit';
    id: number;
    datumFrom: any;
    datumUntil: any;
    mitarbeiterId: number;
    typSnippetId: number;
    mitarbeiter?: {
        __typename?: 'Mitarbeiter';
        id: number;
        name: string;
        vorname: string;
        kennung: string;
    } | null;
    typSnippet?: { __typename?: 'Snippet'; id: number; name: string; kuerzel: string } | null;
} & { ' $fragmentName'?: 'AbsenceFieldsFragment' };

export type CreateAbsenceMutationVariables = Exact<{
    data: AbwesenheitInput;
}>;

export type CreateAbsenceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AbwesenheitItem';
        item?:
            | ({ __typename?: 'Abwesenheit' } & {
                  ' $fragmentRefs'?: { AbsenceFieldsFragment: AbsenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateAbsenceMutationVariables = Exact<{
    id: Scalars['Int'];
    data: AbwesenheitInput;
}>;

export type UpdateAbsenceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AbwesenheitItem';
        item?:
            | ({ __typename?: 'Abwesenheit' } & {
                  ' $fragmentRefs'?: { AbsenceFieldsFragment: AbsenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteAbsenceMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteAbsenceMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'AbwesenheitItem';
        item?:
            | ({ __typename?: 'Abwesenheit' } & {
                  ' $fragmentRefs'?: { AbsenceFieldsFragment: AbsenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListAbsencesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<AbwesenheitOrderByInput>> | InputMaybe<AbwesenheitOrderByInput>
    >;
    filter: AbwesenheitFilterInput;
}>;

export type ListAbsencesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'AbwesenheitCollection';
        items: Array<
            { __typename?: 'Abwesenheit' } & {
                ' $fragmentRefs'?: { AbsenceFieldsFragment: AbsenceFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetAbsenceQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetAbsenceQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'AbwesenheitItem';
        item?:
            | ({ __typename?: 'Abwesenheit' } & {
                  ' $fragmentRefs'?: { AbsenceFieldsFragment: AbsenceFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type EmployeeFunctionFieldsFragment = {
    __typename?: 'MitarbeiterFunktion';
    id: number;
    funktionId: number;
    mitarbeiterId: number;
    funktion?: {
        __typename?: 'Funktion';
        deleted?: any | null;
        bezeichnung: string;
        id: number;
    } | null;
    mitarbeiter?: { __typename?: 'Mitarbeiter'; name: string; vorname: string } | null;
} & { ' $fragmentName'?: 'EmployeeFunctionFieldsFragment' };

export type CreateEmployeeFunctionMutationVariables = Exact<{
    data: MitarbeiterFunktionInput;
}>;

export type CreateEmployeeFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'MitarbeiterFunktionItem';
        item?:
            | ({ __typename?: 'MitarbeiterFunktion' } & {
                  ' $fragmentRefs'?: {
                      EmployeeFunctionFieldsFragment: EmployeeFunctionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateEmployeeFunctionMutationVariables = Exact<{
    id: Scalars['Int'];
    data: MitarbeiterFunktionInput;
}>;

export type UpdateEmployeeFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'MitarbeiterFunktionItem';
        item?:
            | ({ __typename?: 'MitarbeiterFunktion' } & {
                  ' $fragmentRefs'?: {
                      EmployeeFunctionFieldsFragment: EmployeeFunctionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteEmployeeFunctionMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteEmployeeFunctionMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'MitarbeiterFunktionItem';
        item?:
            | ({ __typename?: 'MitarbeiterFunktion' } & {
                  ' $fragmentRefs'?: {
                      EmployeeFunctionFieldsFragment: EmployeeFunctionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListEmployeeFunctionsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        | Array<InputMaybe<MitarbeiterFunktionOrderByInput>>
        | InputMaybe<MitarbeiterFunktionOrderByInput>
    >;
    filter: MitarbeiterFunktionFilterInput;
}>;

export type ListEmployeeFunctionsQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'MitarbeiterFunktionCollection';
        items: Array<
            { __typename?: 'MitarbeiterFunktion' } & {
                ' $fragmentRefs'?: {
                    EmployeeFunctionFieldsFragment: EmployeeFunctionFieldsFragment;
                };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetEmployeeFunctionQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetEmployeeFunctionQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'MitarbeiterFunktionItem';
        item?:
            | ({ __typename?: 'MitarbeiterFunktion' } & {
                  ' $fragmentRefs'?: {
                      EmployeeFunctionFieldsFragment: EmployeeFunctionFieldsFragment;
                  };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type EmployeeFieldsFragment = {
    __typename?: 'Mitarbeiter';
    id: number;
    rolleId: number;
    name: string;
    vorname: string;
    telefon: string;
    email: string;
    projekthistorie?: any | null;
    niederlassungId: number;
    urlaubstage: number;
    urlaubsgruppe: string;
    farbe: string;
    kennung: string;
    passwort: string;
    accessIp: string;
    rolle?: { __typename?: 'Rolle'; id: number; bezeichnung: string } | null;
    mitarbeiterFunktion?: Array<{
        __typename?: 'MitarbeiterFunktion';
        id: number;
        funktionId: number;
        funktion?: { __typename?: 'Funktion'; id: number; bezeichnung: string } | null;
    } | null> | null;
    niederlassung?: {
        __typename?: 'Niederlassung';
        id: number;
        nummer: number;
        bezeichnung: string;
    } | null;
} & { ' $fragmentName'?: 'EmployeeFieldsFragment' };

export type CreateEmployeeMutationVariables = Exact<{
    data: MitarbeiterInput;
}>;

export type CreateEmployeeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'MitarbeiterItem';
        item?:
            | ({ __typename?: 'Mitarbeiter' } & {
                  ' $fragmentRefs'?: { EmployeeFieldsFragment: EmployeeFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type UpdateEmployeeMutationVariables = Exact<{
    id: Scalars['Int'];
    data: MitarbeiterInput;
}>;

export type UpdateEmployeeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'MitarbeiterItem';
        item?:
            | ({ __typename?: 'Mitarbeiter' } & {
                  ' $fragmentRefs'?: { EmployeeFieldsFragment: EmployeeFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type DeleteEmployeeMutationVariables = Exact<{
    id: Scalars['Int'];
}>;

export type DeleteEmployeeMutation = {
    __typename?: 'Mutation';
    item: {
        __typename?: 'MitarbeiterItem';
        item?:
            | ({ __typename?: 'Mitarbeiter' } & {
                  ' $fragmentRefs'?: { EmployeeFieldsFragment: EmployeeFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type ListEmployeesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<
        Array<InputMaybe<MitarbeiterOrderByInput>> | InputMaybe<MitarbeiterOrderByInput>
    >;
    filter: MitarbeiterFilterInput;
}>;

export type ListEmployeesQuery = {
    __typename?: 'Query';
    items: {
        __typename?: 'MitarbeiterCollection';
        pageInfo: { __typename?: 'PageInfo'; pageCount: number; totalCount: number };
        items: Array<
            { __typename?: 'Mitarbeiter' } & {
                ' $fragmentRefs'?: { EmployeeFieldsFragment: EmployeeFieldsFragment };
            }
        >;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export type GetEmployeeQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetEmployeeQuery = {
    __typename?: 'Query';
    item: {
        __typename?: 'MitarbeiterItem';
        item?:
            | ({ __typename?: 'Mitarbeiter' } & {
                  ' $fragmentRefs'?: { EmployeeFieldsFragment: EmployeeFieldsFragment };
              })
            | null;
        error?: { __typename?: 'Error'; code: string; data: string; message: string } | null;
    };
};

export const NiederlassungFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NiederlassungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Niederlassung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NiederlassungFieldsFragment, unknown>;
export const PostleitzahlFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PostleitzahlFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Postleitzahl' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PostleitzahlFieldsFragment, unknown>;
export const KontaktMutationFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktMutationFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<KontaktMutationFieldsFragment, unknown>;
export const KontaktFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<KontaktFieldsFragment, unknown>;
export const GeraetetypFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GeraetetypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraetetyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitZaehler' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GeraetetypFieldsFragment, unknown>;
export const FunctionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'functionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FunctionFieldsFragment, unknown>;
export const RahmenvertragspositionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RahmenvertragspositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rahmenvertragsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'preisProEinheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'leistungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leistung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RahmenvertragspositionFieldsFragment, unknown>;
export const VersicherungFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VersicherungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Versicherung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<VersicherungFieldsFragment, unknown>;
export const ProjekttypFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjekttypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekttyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProjekttypFieldsFragment, unknown>;
export const RoleFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RoleFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rolle' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoleFieldsFragment, unknown>;
export const SnippetFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SnippetFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snippet' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bearbeitbar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SnippetFieldsFragment, unknown>;
export const TextbausteinFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextbausteinFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Textbaustein' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gruppe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TextbausteinFieldsFragment, unknown>;
export const GewerkFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GewerkFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Gewerk' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienstFaehig' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GewerkFieldsFragment, unknown>;
export const CorrespondenceFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CorrespondenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Korrespondenz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beteiligter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'eigenschaft' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zeit' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CorrespondenceFieldsFragment, unknown>;
export const DeviceUsageFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceUsageFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraeteeinsatz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'berechnungsmethodeSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'berechnungsmethodeSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetetypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeviceUsageFieldsFragment, unknown>;
export const DocumentFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dokument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'korrespondenz' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtDetail' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'jaNein' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtOption',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DocumentFieldsFragment, unknown>;
export const ElectricityMeterFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ElectricityMeterFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stromzaehler' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ElectricityMeterFieldsFragment, unknown>;
export const NotdienstprojektFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NotdienstprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notdienstprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'humanid' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByStartedBy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByEmail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByStartedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NotdienstprojektFieldsFragment, unknown>;
export const EventFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EventFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Termin' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EventFieldsFragment, unknown>;
export const CalculationPositionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalculationPositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Kalkulationsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subprojektWohneinheit' },
                        name: { kind: 'Name', value: 'subprojekt_wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CalculationPositionFieldsFragment, unknown>;
export const InvoicePositionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoicePositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rechnungsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InvoicePositionFieldsFragment, unknown>;
export const InvoiceFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoiceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rechnung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rechnungspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InvoiceFieldsFragment, unknown>;
export const OfferPositionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferPositionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebotsposition' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OfferPositionFieldsFragment, unknown>;
export const OfferFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebot' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'angebotspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OfferFieldsFragment, unknown>;
export const MeasureFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MeasureFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufmass' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MeasureFieldsFragment, unknown>;
export const OperationReportDetailFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportDetailFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtDetail' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtOptionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportDetailFieldsFragment, unknown>;
export const OperationReportModuleFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportModuleFieldsFragment, unknown>;
export const OperationReportOptionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportOptionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtOption' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportOptionFieldsFragment, unknown>;
export const OperationReportTradeFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTradeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtGewerk' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportTradeFieldsFragment, unknown>;
export const OperationReportTypeModuleFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTypModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtTypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportTypeModuleFieldsFragment, unknown>;
export const OperationReportTypeFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTyp' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportTypeFieldsFragment, unknown>;
export const OperationReportFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperationReportFieldsFragment, unknown>;
export const ParticipantFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParticipantFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Beteiligter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ParticipantFieldsFragment, unknown>;
export const SubprojektSucheFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektSucheFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubprojektSucheFieldsFragment, unknown>;
export const SubprojektFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubprojektFieldsFragment, unknown>;
export const ProjektFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bemerkung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'intern' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektGewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProjektFieldsFragment, unknown>;
export const ResidentialUnitFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ResidentialUnitFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wohneinheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'email' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'fax' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'landSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'postfach',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefon',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonDirekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonMobil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonPrivat',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kategorie',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellOrt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellPlz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellStrasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResidentialUnitFieldsFragment, unknown>;
export const SubprojektResidentialUnitFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektResidentialUnitFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SubprojektWohneinheit' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufmass' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubprojektResidentialUnitFieldsFragment, unknown>;
export const TaskFunctionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFunctionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufgabe' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beschreibung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TaskFunctionFieldsFragment, unknown>;
export const TaskFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TaskFieldsFragment, unknown>;
export const WattroFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WattroFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommandResult' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<WattroFieldsFragment, unknown>;
export const AbsenceFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AbsenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Abwesenheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AbsenceFieldsFragment, unknown>;
export const EmployeeFunctionFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFunctionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MitarbeiterFunktion' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EmployeeFunctionFieldsFragment, unknown>;
export const EmployeeFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Mitarbeiter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'funktion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekthistorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubstage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubsgruppe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'passwort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessIp' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EmployeeFieldsFragment, unknown>;
export const CreateBranchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createBranch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NiederlassungInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createNiederlassung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateBranchMutation, CreateBranchMutationVariables>;
export const UpdateBranchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateBranch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NiederlassungInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateNiederlassung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateBranchMutation, UpdateBranchMutationVariables>;
export const DeleteBranchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteBranch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteNiederlassung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteBranchMutation, DeleteBranchMutationVariables>;
export const ListZipCodesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listZipCodes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'PostleitzahlFilterInput' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PostleitzahlOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'postleitzahl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListZipCodesQuery, ListZipCodesQueryVariables>;
export const GetBranchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBranch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'niederlassungById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'NiederlassungFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NiederlassungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Niederlassung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetBranchQuery, GetBranchQueryVariables>;
export const CreateBranchZipCodeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createBranchZipCode' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PostleitzahlInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createPostleitzahl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PostleitzahlFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PostleitzahlFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Postleitzahl' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateBranchZipCodeMutation, CreateBranchZipCodeMutationVariables>;
export const UpdateBranchZipCodeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateBranchZipCode' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PostleitzahlInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updatePostleitzahl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PostleitzahlFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PostleitzahlFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Postleitzahl' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateBranchZipCodeMutation, UpdateBranchZipCodeMutationVariables>;
export const DeleteBranchZipCodeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteBranchZipCode' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deletePostleitzahl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PostleitzahlFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PostleitzahlFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Postleitzahl' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteBranchZipCodeMutation, DeleteBranchZipCodeMutationVariables>;
export const GetBranchZipCodeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBranchZipCode' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'postleitzahlById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PostleitzahlFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PostleitzahlFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Postleitzahl' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetBranchZipCodeQuery, GetBranchZipCodeQueryVariables>;
export const ListBranchesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listBranches' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NiederlassungOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'NiederlassungFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'niederlassung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'NiederlassungFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NiederlassungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Niederlassung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListBranchesQuery, ListBranchesQueryVariables>;
export const CreateContactDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createContact' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'KontaktInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createKontakt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'KontaktMutationFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktMutationFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateContact' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'KontaktInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateKontakt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'KontaktMutationFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktMutationFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteContact' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteKontakt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'KontaktMutationFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktMutationFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteContactMutation, DeleteContactMutationVariables>;
export const ListContactDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listContact' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KontaktOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'KontaktFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'kontakt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'KontaktFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListContactQuery, ListContactQueryVariables>;
export const GetContactDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getContact' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'kontaktById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'KontaktFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetContactQuery, GetContactQueryVariables>;
export const GetContactCsvExportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getContactCsvExport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontaktCsvExport' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'searchTerm' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'searchTerm' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetContactCsvExportQuery, GetContactCsvExportQueryVariables>;
export const SearchContactDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchContact' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontaktSearch' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'searchTerm' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'searchTerm' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'limit' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'offset' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'offset' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'KontaktFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageInfo' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pageCount',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'totalCount',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'KontaktFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Kontakt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchContactQuery, SearchContactQueryVariables>;
export const ListVacantPositionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listVacantPositions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'niederlassungId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'only28DaysAndOlder' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'OffenePostenOrderByInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: { kind: 'Name', value: 'offenePosten' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'limit' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'offset' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'offset' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'mitarbeiterId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'mitarbeiterId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'niederlassungId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'only28DaysAndOlder' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'only28DaysAndOlder' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'orderBy' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'orderBy' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gesamtUmsatz' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'aufbau' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'letzterTermin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'niederlassung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'objekt' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'projekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'umsatz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'offenerTermin',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageInfo' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pageCount',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'totalCount',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListVacantPositionsQuery, ListVacantPositionsQueryVariables>;
export const ListVacantPositionEmployeesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listVacantPositionEmployees' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'niederlassungId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: { kind: 'Name', value: 'offenePostenMitarbeiter' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'niederlassungId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageInfo' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pageCount',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'totalCount',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListVacantPositionEmployeesQuery,
    ListVacantPositionEmployeesQueryVariables
>;
export const VacantPositionCsvExportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'vacantPositionCsvExport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'niederlassungId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'only28DaysAndOlder' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: { kind: 'Name', value: 'offenePostenCsvExport' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'mitarbeiterId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'mitarbeiterId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'niederlassungId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'only28DaysAndOlder' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'only28DaysAndOlder' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<VacantPositionCsvExportQuery, VacantPositionCsvExportQueryVariables>;
export const GetProjectByLfdNrDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getProjectByLfdNr' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'lfdNr' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: { kind: 'Name', value: 'projektByLfdNr' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'lfdNr' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'lfdNr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'subprojekt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lfdNr',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProjectByLfdNrQuery, GetProjectByLfdNrQueryVariables>;
export const CreateDeviceTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createDeviceType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraetetypInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createGeraetetyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GeraetetypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GeraetetypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraetetyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitZaehler' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateDeviceTypeMutation, CreateDeviceTypeMutationVariables>;
export const UpdateDeviceTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateDeviceType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraetetypInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateGeraetetyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GeraetetypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GeraetetypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraetetyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitZaehler' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateDeviceTypeMutation, UpdateDeviceTypeMutationVariables>;
export const DeleteDeviceTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteDeviceType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteGeraetetyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GeraetetypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GeraetetypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraetetyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitZaehler' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteDeviceTypeMutation, DeleteDeviceTypeMutationVariables>;
export const ListDeviceTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listDeviceType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraetetypOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'GeraetetypFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'geraetetyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GeraetetypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GeraetetypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraetetyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitZaehler' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListDeviceTypeQuery, ListDeviceTypeQueryVariables>;
export const GetDeviceTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDeviceType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'geraetetypById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GeraetetypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GeraetetypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraetetyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitZaehler' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetDeviceTypeQuery, GetDeviceTypeQueryVariables>;
export const CreateFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'FunktionInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'functionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'functionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateFunctionMutation, CreateFunctionMutationVariables>;
export const UpdateFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'FunktionInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'functionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'functionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateFunctionMutation, UpdateFunctionMutationVariables>;
export const DeleteFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'functionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'functionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteFunctionMutation, DeleteFunctionMutationVariables>;
export const ListFunctionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listFunctions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FunktionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FunktionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'funktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'functionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'functionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListFunctionsQuery, ListFunctionsQueryVariables>;
export const GetFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'funktionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'functionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'functionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetFunctionQuery, GetFunctionQueryVariables>;
export const CreateGeneralAgreementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createGeneralAgreement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createRahmenvertrag' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigAb' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigBis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isStandard' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateGeneralAgreementMutation,
    CreateGeneralAgreementMutationVariables
>;
export const UpdateGeneralAgreementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateGeneralAgreement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateRahmenvertrag' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigAb' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigBis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isStandard' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateGeneralAgreementMutation,
    UpdateGeneralAgreementMutationVariables
>;
export const DeleteGeneralAgreementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteGeneralAgreement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteRahmenvertrag' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigAb' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigBis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isStandard' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteGeneralAgreementMutation,
    DeleteGeneralAgreementMutationVariables
>;
export const ListGeneralAgreementsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listGeneralAgreements' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'RahmenvertragFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigAb' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigBis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isStandard' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListGeneralAgreementsQuery, ListGeneralAgreementsQueryVariables>;
export const GetGeneralAgreementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getGeneralAgreement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'rahmenvertragById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isStandard' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'deleted' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigAb' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gueltigBis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rahmenvertragsposition',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'beschreibung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'details',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'leistungId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'leistung',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bezeichnung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einheit',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'preisProEinheit',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subprojekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGeneralAgreementQuery, GetGeneralAgreementQueryVariables>;
export const CreateGeneralAgreementPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createGeneralAgreementPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createRahmenvertragsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RahmenvertragspositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RahmenvertragspositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rahmenvertragsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'preisProEinheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'leistungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leistung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateGeneralAgreementPositionMutation,
    CreateGeneralAgreementPositionMutationVariables
>;
export const UpdateGeneralAgreementPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateGeneralAgreementPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateRahmenvertragsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RahmenvertragspositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RahmenvertragspositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rahmenvertragsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'preisProEinheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'leistungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leistung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateGeneralAgreementPositionMutation,
    UpdateGeneralAgreementPositionMutationVariables
>;
export const DeleteGeneralAgreementPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteGeneralAgreementPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteRahmenvertragsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RahmenvertragspositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RahmenvertragspositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rahmenvertragsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'preisProEinheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'leistungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leistung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteGeneralAgreementPositionMutation,
    DeleteGeneralAgreementPositionMutationVariables
>;
export const ListGeneralAgreementPostionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listGeneralAgreementPostion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'RahmenvertragspositionFilterInput' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragspositionOrderByInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RahmenvertragspositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RahmenvertragspositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rahmenvertragsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'preisProEinheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'leistungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leistung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListGeneralAgreementPostionQuery,
    ListGeneralAgreementPostionQueryVariables
>;
export const GetGeneralAgreementPostionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getGeneralAgreementPostion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'rahmenvertragspositionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RahmenvertragspositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RahmenvertragspositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rahmenvertragsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'preisProEinheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'leistungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leistung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetGeneralAgreementPostionQuery,
    GetGeneralAgreementPostionQueryVariables
>;
export const CreateInsuranceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createInsurance' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'VersicherungInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createVersicherung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'VersicherungFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VersicherungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Versicherung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateInsuranceMutation, CreateInsuranceMutationVariables>;
export const UpdateInsuranceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateInsurance' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'VersicherungInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateVersicherung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'VersicherungFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VersicherungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Versicherung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateInsuranceMutation, UpdateInsuranceMutationVariables>;
export const DeleteInsuranceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteInsurance' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteVersicherung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'VersicherungFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VersicherungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Versicherung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteInsuranceMutation, DeleteInsuranceMutationVariables>;
export const ListInsurancesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listInsurances' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'VersicherungOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'VersicherungFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'versicherung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'VersicherungFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VersicherungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Versicherung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListInsurancesQuery, ListInsurancesQueryVariables>;
export const GetInsuranceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInsurance' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'versicherungById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'VersicherungFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VersicherungFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Versicherung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInsuranceQuery, GetInsuranceQueryVariables>;
export const CreateProjectTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createProjectType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ProjekttypInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createProjekttyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateProjectTypeMutation, CreateProjectTypeMutationVariables>;
export const UpdateProjectTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateProjectType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ProjekttypInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateProjekttyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateProjectTypeMutation, UpdateProjectTypeMutationVariables>;
export const DeleteProjectTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteProjectType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteProjekttyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteProjectTypeMutation, DeleteProjectTypeMutationVariables>;
export const ListProjectTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listProjectType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ProjekttypOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ProjekttypFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'projekttyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjekttypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjekttypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekttyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListProjectTypeQuery, ListProjectTypeQueryVariables>;
export const GetProjectTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getProjectType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'projekttypById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjekttypFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjekttypFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekttyp' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProjectTypeQuery, GetProjectTypeQueryVariables>;
export const CreateRoleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createRole' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'RolleInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createRolle' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RoleFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RoleFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rolle' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateRole' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'RolleInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateRolle' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RoleFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RoleFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rolle' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteRole' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteRolle' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RoleFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RoleFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rolle' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const ListRolesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listRoles' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RolleOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RolleFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'rolle' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RoleFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RoleFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rolle' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListRolesQuery, ListRolesQueryVariables>;
export const GetRoleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getRole' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'rolleById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RoleFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RoleFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rolle' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetRoleQuery, GetRoleQueryVariables>;
export const CreateSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SnippetInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createSnippet' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SnippetFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SnippetFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snippet' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bearbeitbar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateSnippetMutation, CreateSnippetMutationVariables>;
export const UpdateSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SnippetInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateSnippet' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SnippetFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SnippetFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snippet' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bearbeitbar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSnippetMutation, UpdateSnippetMutationVariables>;
export const DeleteSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteSnippet' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SnippetFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SnippetFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snippet' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bearbeitbar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSnippetMutation, DeleteSnippetMutationVariables>;
export const ListSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SnippetOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SnippetFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'snippet' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SnippetFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SnippetFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snippet' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bearbeitbar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListSnippetQuery, ListSnippetQueryVariables>;
export const GetSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'snippetById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SnippetFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SnippetFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snippet' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bearbeitbar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSnippetQuery, GetSnippetQueryVariables>;
export const CreateTextSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createTextSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextbausteinInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createTextbaustein' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TextbausteinFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextbausteinFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Textbaustein' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gruppe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateTextSnippetMutation, CreateTextSnippetMutationVariables>;
export const UpdateTextSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTextSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextbausteinInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateTextbaustein' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TextbausteinFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextbausteinFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Textbaustein' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gruppe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateTextSnippetMutation, UpdateTextSnippetMutationVariables>;
export const DeleteTextSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteTextSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteTextbaustein' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TextbausteinFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextbausteinFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Textbaustein' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gruppe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteTextSnippetMutation, DeleteTextSnippetMutationVariables>;
export const ListTextSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listTextSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextbausteinOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'TextbausteinFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'textbaustein' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TextbausteinFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextbausteinFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Textbaustein' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gruppe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListTextSnippetQuery, ListTextSnippetQueryVariables>;
export const GetTextSnippetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTextSnippet' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'textbausteinById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TextbausteinFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextbausteinFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Textbaustein' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gruppe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTextSnippetQuery, GetTextSnippetQueryVariables>;
export const CreateTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'GewerkInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: { kind: 'Name', value: 'upsertGewerk' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'gewerk' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'gewerkProjekttypen' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'gewerkProjekttyp' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'item' },
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'GewerkFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'error' },
                                                name: { kind: 'Name', value: 'globalError' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GewerkFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Gewerk' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienstFaehig' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateTradeMutation, CreateTradeMutationVariables>;
export const UpdateTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'GewerkInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: { kind: 'Name', value: 'upsertGewerk' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'gewerk' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'gewerkProjekttypen' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'gewerkProjekttyp' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'item' },
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'GewerkFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'error' },
                                                name: { kind: 'Name', value: 'globalError' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GewerkFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Gewerk' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienstFaehig' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateTradeMutation, UpdateTradeMutationVariables>;
export const DeleteTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteGewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GewerkFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GewerkFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Gewerk' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienstFaehig' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteTradeMutation, DeleteTradeMutationVariables>;
export const ListTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GewerkOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'GewerkFilterInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'gewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GewerkFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GewerkFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Gewerk' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienstFaehig' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListTradeQuery, ListTradeQueryVariables>;
export const GetTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'gewerkById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'GewerkFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GewerkFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Gewerk' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienstFaehig' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerkProjekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTradeQuery, GetTradeQueryVariables>;
export const ListCorrespondencesAndEventsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listCorrespondencesAndEvents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'projektId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojektId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'items' },
                                    name: { kind: 'Name', value: 'korrespondenzenUndTermine' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'projektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'projektId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'subprojektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'subprojektId' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageInfo' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pageCount',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'totalCount',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'typ' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'datum' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'beschreibung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdSachbearbeiter',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdUrheber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'urheber',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'terminId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'termin' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'angelegt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bemerkung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'dauer',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'erledigt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'geplant',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'mitarbeiterIdSachbearbeiter',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sachbearbeiter',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'mitarbeiterIdUrheber',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'urheber',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'prio',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'subprojektId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'subprojekt',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'lfdNr',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'projektId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'beteiligte',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'id',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'kontakt',
                                                                                                    },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'id',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'name',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'vorname',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'firma1',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'firma2',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'typSnippet',
                                                                                                    },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'id',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'name',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'projekt',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'id',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'anlageDatum',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'lfdNr',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'strasse',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'plz',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'ort',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'niederlassungId',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'niederlassung',
                                                                                                    },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'id',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'nummer',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sachbearbeiter',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'urheber',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'korrespondenzId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'korrespondenz',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'beteiligter',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'angelegt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'freitext',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'html',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'raw',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'mitarbeiterIdSachbearbeiter',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sachbearbeiter',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'mitarbeiterIdUrheber',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'urheber',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'zeit',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'dokumentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'dokument',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'beschreibung',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'pfad',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'typSnippetId',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'subprojekt',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'lfdNr',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'projekttyp',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'id',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'beteiligte',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'id',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'kontakt',
                                                                                                    },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'id',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'name',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'vorname',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'firma1',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'firma2',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'typSnippet',
                                                                                                    },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'id',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'name',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'projekt',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'plz',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'ort',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'strasse',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'niederlassungId',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'anlageDatum',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'niederlassung',
                                                                                                    },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'id',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'nummer',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'id',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'lfdNr',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListCorrespondencesAndEventsQuery,
    ListCorrespondencesAndEventsQueryVariables
>;
export const CreateCorrespondenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createCorrespondence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KorrespondenzCreateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createKorrespondenz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CorrespondenceFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CorrespondenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Korrespondenz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beteiligter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'eigenschaft' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zeit' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateCorrespondenceMutation, CreateCorrespondenceMutationVariables>;
export const UpdateCorrespondenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCorrespondence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KorrespondenzUpdateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateKorrespondenz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CorrespondenceFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CorrespondenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Korrespondenz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beteiligter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'eigenschaft' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zeit' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateCorrespondenceMutation, UpdateCorrespondenceMutationVariables>;
export const DeleteCorrespondenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteCorrespondence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteKorrespondenz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CorrespondenceFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CorrespondenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Korrespondenz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beteiligter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'eigenschaft' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zeit' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteCorrespondenceMutation, DeleteCorrespondenceMutationVariables>;
export const ListCorrespondenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listCorrespondence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KorrespondenzOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KorrespondenzFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'korrespondenz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CorrespondenceFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CorrespondenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Korrespondenz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beteiligter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'eigenschaft' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zeit' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListCorrespondenceQuery, ListCorrespondenceQueryVariables>;
export const GetCorrespondenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCorrespondence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'korrespondenzById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CorrespondenceFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CorrespondenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Korrespondenz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beteiligter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dokumentId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'eigenschaft' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zeit' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCorrespondenceQuery, GetCorrespondenceQueryVariables>;
export const CreateDeviceUsageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createDeviceUsage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraeteeinsatzInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createGeraeteeinsatz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceUsageFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceUsageFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraeteeinsatz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'berechnungsmethodeSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'berechnungsmethodeSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetetypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateDeviceUsageMutation, CreateDeviceUsageMutationVariables>;
export const UpdateDeviceUsageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateDeviceUsage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraeteeinsatzInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateGeraeteeinsatz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceUsageFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceUsageFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraeteeinsatz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'berechnungsmethodeSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'berechnungsmethodeSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetetypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateDeviceUsageMutation, UpdateDeviceUsageMutationVariables>;
export const DeleteDeviceUsageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteDeviceUsage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteGeraeteeinsatz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceUsageFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceUsageFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraeteeinsatz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'berechnungsmethodeSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'berechnungsmethodeSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetetypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteDeviceUsageMutation, DeleteDeviceUsageMutationVariables>;
export const ListDeviceUsageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listDeviceUsage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraeteeinsatzOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeraeteeinsatzFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'geraeteeinsatz' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceUsageFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceUsageFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraeteeinsatz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'berechnungsmethodeSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'berechnungsmethodeSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetetypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListDeviceUsageQuery, ListDeviceUsageQueryVariables>;
export const GetDeviceUsageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDeviceUsage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'geraeteeinsatzById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceUsageFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceUsageFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Geraeteeinsatz' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'berechnungsmethodeSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'berechnungsmethodeSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'geraetetypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nennleistung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetDeviceUsageQuery, GetDeviceUsageQueryVariables>;
export const GetDeviceUsagePdfDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDeviceUsagePdf' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'subprojektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subprojektId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ort',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'plz',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'strasse',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'subprojektWohneinheit',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wattroProjectExists',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wohneinheit',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bezeichnung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'nr',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'istGesamtobjekt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'projekt',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'ort',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'plz',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'strasse',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'geraeteeinsatz',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'abrechnen',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'geraetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'beginnTag',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'endeTag',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'berechnungsmethodeSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'geraetetypId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'geraetetyp',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'isWattro',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'bezeichnung',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'nennleistung',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'stromzaehler',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'wattroId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'wattroAssetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'nummer',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'abrechnen',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'beginnTag',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'endeTag',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'beginnWert',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'endeWert',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippet',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'kategorie',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'eigenschaft',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetDeviceUsagePdfQuery, GetDeviceUsagePdfQueryVariables>;
export const CreateDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DokumentInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createDokument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dokument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'korrespondenz' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtDetail' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'jaNein' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtOption',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DokumentInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateDokument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dokument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'korrespondenz' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtDetail' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'jaNein' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtOption',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const DeleteDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteDokument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dokument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'korrespondenz' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtDetail' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'jaNein' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtOption',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const ListDocumentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listDocuments' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DokumentOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DokumentFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'dokument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dokument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'korrespondenz' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtDetail' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'jaNein' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtOption',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListDocumentsQuery, ListDocumentsQueryVariables>;
export const GetDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'dokumentById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dokument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geraetetyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isWattro' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hersteller' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'korrespondenz' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtDetail' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'jaNein' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtOption',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetDocumentQuery, GetDocumentQueryVariables>;
export const DocumentDetailsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'documentDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'subprojektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subprojektId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einsatzbericht' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'datum' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'deleted',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einsatzberichtTyp',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'subprojektId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anfahrtFrom',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anfahrtUntil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einsatzFrom',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einsatzUntil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'abfahrtFrom',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'abfahrtUntil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einsatzberichtSachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einsatzberichtId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'mitarbeiterId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'mitarbeiter',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdUrheber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'urheber',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einsatzberichtDetail',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einsatzberichtOptionId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einsatzberichtOption',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'bezeichnung',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'typ',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'jaNein',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'anzahl',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'text',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'subprojektWohneinheit',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wattroProjectExists',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'aufmass',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bezeichnung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'laenge',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'breite',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'hoehe',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippet',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wohneinheit',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'nr',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bezeichnung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'istGesamtobjekt',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'ihrZeichen' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekttyp' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projektId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'lfdNr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anlageDatum',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'niederlassung',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'nummer',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bezeichnung',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bemerkung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'angebote' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'deleted',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'datum' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dokument',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'pfad',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitextFooter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'gesamtpreisNetto',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mwstSatz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'angebotspositionen',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'subprojektWohneinheitId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'beschreibung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einheit',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einzelpreis',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'menge',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lfdNr',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdSachbearbeiter',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdProjektleitung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'rabatt' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rechnungen' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'deleted',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'datum' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dokument',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'pfad',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitextFooter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mwstSatz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rechnungspositionen',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'subprojektWohneinheitId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'beschreibung',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einheit',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einzelpreis',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'menge',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lfdNr',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdSachbearbeiter',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdProjektleitung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'rabatt' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kuerzel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'subprojektWohneinheitId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'subprojektWohneinheit',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'wattroProjectExists',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'wohneinheitId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'wohneinheit',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'bezeichnung',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'anredeSnippet',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'kuerzel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'freitext',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'raw',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ort',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'plz',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'strasse',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefon',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonDirekt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonMobil',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonPrivat',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fax',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'termine' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'angelegt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bemerkung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kuerzel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdUrheber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'urheber',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdSachbearbeiter',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'geplant',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'dauer' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'subprojekt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'dokumente' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'beschreibung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'pfad' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'scheinNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'schadenNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'versicherung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'aufgaben' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'angelegt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'beschreibung',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zuErledigen',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdUrheber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'urheber',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'korrespondenzen' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'angelegt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdSachbearbeiter',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mitarbeiterIdUrheber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sachbearbeiter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'urheber',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DocumentDetailsQuery, DocumentDetailsQueryVariables>;
export const CreateElectricityMeterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createElectricityMeter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'StromzaehlerInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createStromzaehler' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ElectricityMeterFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ElectricityMeterFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stromzaehler' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateElectricityMeterMutation,
    CreateElectricityMeterMutationVariables
>;
export const UpdateElectricityMeterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateElectricityMeter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'StromzaehlerInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateStromzaehler' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ElectricityMeterFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ElectricityMeterFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stromzaehler' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateElectricityMeterMutation,
    UpdateElectricityMeterMutationVariables
>;
export const DeleteElectricityMeterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteElectricityMeter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteStromzaehler' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ElectricityMeterFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ElectricityMeterFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stromzaehler' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteElectricityMeterMutation,
    DeleteElectricityMeterMutationVariables
>;
export const ListElectricityMeterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listElectricityMeter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'StromzaehlerOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'StromzaehlerFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'stromzaehler' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ElectricityMeterFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ElectricityMeterFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stromzaehler' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListElectricityMeterQuery, ListElectricityMeterQueryVariables>;
export const GetElectricityMeterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getElectricityMeter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'stromzaehlerById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ElectricityMeterFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ElectricityMeterFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stromzaehler' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beginnWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endeWert' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'wattroAssetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetElectricityMeterQuery, GetElectricityMeterQueryVariables>;
export const CreateNotdienstprojektDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createNotdienstprojekt' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NotdienstprojektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createNotdienstprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'NotdienstprojektFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NotdienstprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notdienstprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'humanid' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByStartedBy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByEmail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByStartedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateNotdienstprojektMutation,
    CreateNotdienstprojektMutationVariables
>;
export const UpdateNotdienstprojektDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateNotdienstprojekt' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NotdienstprojektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateNotdienstprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'NotdienstprojektFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NotdienstprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notdienstprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'humanid' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByStartedBy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByEmail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByStartedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateNotdienstprojektMutation,
    UpdateNotdienstprojektMutationVariables
>;
export const DeleteNotdienstprojektDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteNotdienstprojekt' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteNotdienstprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'NotdienstprojektFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NotdienstprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notdienstprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'humanid' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByStartedBy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByEmail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByStartedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteNotdienstprojektMutation,
    DeleteNotdienstprojektMutationVariables
>;
export const ListNotdienstprojektDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listNotdienstprojekt' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NotdienstprojektOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NotdienstprojektFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'notdienstprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'NotdienstprojektFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NotdienstprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notdienstprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'humanid' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedByEmail' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdByStartedBy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByEmail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterByStartedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListNotdienstprojektQuery, ListNotdienstprojektQueryVariables>;
export const CreateEventDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createEvent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TerminCreateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createTermin' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EventFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EventFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Termin' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEvent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TerminUpdateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateTermin' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EventFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EventFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Termin' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEvent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteTermin' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EventFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EventFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Termin' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteEventMutation, DeleteEventMutationVariables>;
export const ListEventsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listEvents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TerminOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TerminFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'termin' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EventFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EventFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Termin' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListEventsQuery, ListEventsQueryVariables>;
export const GetEventDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEvent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'terminById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EventFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EventFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Termin' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEventQuery, GetEventQueryVariables>;
export const CreateCalculationPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createCalculationPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KalkulationspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createKalkulationsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CalculationPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalculationPositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Kalkulationsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subprojektWohneinheit' },
                        name: { kind: 'Name', value: 'subprojekt_wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateCalculationPositionMutation,
    CreateCalculationPositionMutationVariables
>;
export const UpdateCalculationPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCalculationPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KalkulationspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateKalkulationsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CalculationPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalculationPositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Kalkulationsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subprojektWohneinheit' },
                        name: { kind: 'Name', value: 'subprojekt_wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateCalculationPositionMutation,
    UpdateCalculationPositionMutationVariables
>;
export const DeleteCalculationPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteCalculationPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteKalkulationsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CalculationPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalculationPositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Kalkulationsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subprojektWohneinheit' },
                        name: { kind: 'Name', value: 'subprojekt_wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteCalculationPositionMutation,
    DeleteCalculationPositionMutationVariables
>;
export const ListCalculationPositionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listCalculationPositions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KalkulationspositionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'KalkulationspositionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'kalkulationsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CalculationPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalculationPositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Kalkulationsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subprojektWohneinheit' },
                        name: { kind: 'Name', value: 'subprojekt_wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListCalculationPositionsQuery, ListCalculationPositionsQueryVariables>;
export const GetCalculationPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCalculationPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'kalkulationspositionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CalculationPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalculationPositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Kalkulationsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subprojektWohneinheit' },
                        name: { kind: 'Name', value: 'subprojekt_wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCalculationPositionQuery, GetCalculationPositionQueryVariables>;
export const SelectSubprojectResidentialUnitsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'selectSubprojectResidentialUnits' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektWohneinheitOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SubprojektWohneinheitFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subprojektId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'deleted' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subprojekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'lfdNr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'projekt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lfdNr',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'projekttyp',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'subprojektId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'anredeSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fax',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'freitext',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'html',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'raw',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'landSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ort',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'plz',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'postfach',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'statusSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'strasse',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefon',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonDirekt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonMobil',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonPrivat',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippet',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'kategorie',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'zustellOrt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'zustellPlz',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'zustellStrasse',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'aufmass' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'laenge' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'breite' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'hoehe' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'istGesamtobjekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'deleted',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'projekt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'plz',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ort',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'strasse',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SelectSubprojectResidentialUnitsQuery,
    SelectSubprojectResidentialUnitsQueryVariables
>;
export const CreateInvoicePositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createInvoicePosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RechnungspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createRechnungsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'InvoicePositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoicePositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rechnungsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateInvoicePositionMutation, CreateInvoicePositionMutationVariables>;
export const UpdateInvoicePositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateInvoicePosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RechnungspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateRechnungsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'InvoicePositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoicePositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rechnungsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateInvoicePositionMutation, UpdateInvoicePositionMutationVariables>;
export const DeleteInvoicePositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteInvoicePosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteRechnungsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'InvoicePositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoicePositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rechnungsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteInvoicePositionMutation, DeleteInvoicePositionMutationVariables>;
export const GetInvoicePositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInvoicePosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'rechnungspositionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'InvoicePositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoicePositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rechnungsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInvoicePositionQuery, GetInvoicePositionQueryVariables>;
export const ListInvoicePositionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listInvoicePositions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RechnungspositionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RechnungspositionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'rechnungsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'InvoicePositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoicePositionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Rechnungsposition' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rechnungId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListInvoicePositionsQuery, ListInvoicePositionsQueryVariables>;
export const CreateInvoiceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createInvoice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'RechnungInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createRechnung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InvoiceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoiceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rechnung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rechnungspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const UpdateInvoiceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateInvoice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'RechnungInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateRechnung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InvoiceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoiceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rechnung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rechnungspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const DeleteInvoiceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteInvoice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteRechnung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InvoiceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoiceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rechnung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rechnungspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const GetInvoiceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInvoice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'rechnungById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InvoiceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoiceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rechnung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rechnungspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const ListInvoicesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listInvoices' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RechnungOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RechnungFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'rechnung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InvoiceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InvoiceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Rechnung' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rechnungspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListInvoicesQuery, ListInvoicesQueryVariables>;
export const CreateOfferPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOfferPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AngebotspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createAngebotsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OfferPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferPositionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebotsposition' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateOfferPositionMutation, CreateOfferPositionMutationVariables>;
export const UpdateOfferPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOfferPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AngebotspositionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateAngebotsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OfferPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferPositionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebotsposition' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOfferPositionMutation, UpdateOfferPositionMutationVariables>;
export const DeleteOfferPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOfferPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteAngebotsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OfferPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferPositionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebotsposition' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOfferPositionMutation, DeleteOfferPositionMutationVariables>;
export const GetOfferPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOfferPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'angebotspositionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OfferPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferPositionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebotsposition' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOfferPositionQuery, GetOfferPositionQueryVariables>;
export const ListOffersPositionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOffersPositions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AngebotspositionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AngebotspositionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'angebotsposition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OfferPositionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferPositionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebotsposition' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angebotId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragspositionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertragsposition' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertrag' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leistung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einheit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListOffersPositionsQuery, ListOffersPositionsQueryVariables>;
export const CreateOfferDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOffer' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AngebotInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createAngebot' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'OfferFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebot' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'angebotspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateOfferMutation, CreateOfferMutationVariables>;
export const UpdateOfferDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOffer' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AngebotInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateAngebot' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'OfferFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebot' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'angebotspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOfferMutation, UpdateOfferMutationVariables>;
export const DeleteOfferDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOffer' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteAngebot' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'OfferFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebot' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'angebotspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const GetOfferDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOffer' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'angebotById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'OfferFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebot' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'angebotspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOfferQuery, GetOfferQueryVariables>;
export const ListOffersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOffers' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AngebotOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AngebotFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'angebot' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'OfferFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OfferFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Angebot' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dokument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pfad' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitext' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freitextFooter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'gesamtpreisNetto' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdProjektleitung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mwstSatz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rabatt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'angebotspositionen' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beschreibung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einheit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'einzelpreis' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'menge' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragspositionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rahmenvertragsposition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beschreibung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'leistung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einheit',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projektleitung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListOffersQuery, ListOffersQueryVariables>;
export const UpdateSalesForecastDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSalesForecast' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'salesForecast' },
                                    name: { kind: 'Name', value: 'updateUmsatzPrognose' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'subprojektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'subprojektId' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'umsatzPrognose',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSalesForecastMutation, UpdateSalesForecastMutationVariables>;
export const CreateMeasureDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createMeasure' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AufmassInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createAufmass' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MeasureFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MeasureFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufmass' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateMeasureMutation, CreateMeasureMutationVariables>;
export const UpdateMeasureDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateMeasure' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AufmassInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateAufmass' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MeasureFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MeasureFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufmass' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateMeasureMutation, UpdateMeasureMutationVariables>;
export const DeleteMeasureDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteMeasure' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteAufmass' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MeasureFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MeasureFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufmass' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteMeasureMutation, DeleteMeasureMutationVariables>;
export const ListMeasuresDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listMeasures' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufmassOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufmassFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'aufmass' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MeasureFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MeasureFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufmass' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListMeasuresQuery, ListMeasuresQueryVariables>;
export const GetMeasureDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getMeasure' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'aufmassById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MeasureFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MeasureFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufmass' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eigenschaft' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetMeasureQuery, GetMeasureQueryVariables>;
export const UpdateMeasureRemarkDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateMeasureRemark' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojectId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'remark' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: {
                                        kind: 'Name',
                                        value: 'updateSubprojektBemerkungAufmass',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'bemerkungAufmass' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'remark' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'subprojektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'subprojectId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'version' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'forceOverwrite' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'forceOverwrite' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateMeasureRemarkMutation, UpdateMeasureRemarkMutationVariables>;
export const GetMeasureRemarkDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getMeasureRemark' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojectId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'subprojektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subprojectId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bemerkungAufmass' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetMeasureRemarkQuery, GetMeasureRemarkQueryVariables>;
export const CreateOperationReportDetailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReportDetail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtDetailInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzberichtDetail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportDetailFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportDetailFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtDetail' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtOptionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOperationReportDetailMutation,
    CreateOperationReportDetailMutationVariables
>;
export const UpdateOperationReportDetailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReportDetail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtDetailInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzberichtDetail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportDetailFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportDetailFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtDetail' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtOptionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOperationReportDetailMutation,
    UpdateOperationReportDetailMutationVariables
>;
export const DeleteOperationReportDetailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReportDetail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzberichtDetail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportDetailFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportDetailFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtDetail' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtOptionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteOperationReportDetailMutation,
    DeleteOperationReportDetailMutationVariables
>;
export const ListOperationReportDetailsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReportDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtDetailOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtDetailFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportDetailFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportDetailFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtDetail' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtOptionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListOperationReportDetailsQuery,
    ListOperationReportDetailsQueryVariables
>;
export const GetOperationReportDetailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReportDetail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtDetailById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportDetailFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportDetailFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtDetail' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtOptionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOperationReportDetailQuery, GetOperationReportDetailQueryVariables>;
export const CreateOperationReportModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReportModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtModulInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzberichtModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOperationReportModuleMutation,
    CreateOperationReportModuleMutationVariables
>;
export const UpdateOperationReportModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReportModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtModulInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzberichtModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOperationReportModuleMutation,
    UpdateOperationReportModuleMutationVariables
>;
export const DeleteOperationReportModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReportModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzberichtModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteOperationReportModuleMutation,
    DeleteOperationReportModuleMutationVariables
>;
export const ListOperationReportModulesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReportModules' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtModulOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtModulFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListOperationReportModulesQuery,
    ListOperationReportModulesQueryVariables
>;
export const GetOperationReportModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReportModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtModulById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOperationReportModuleQuery, GetOperationReportModuleQueryVariables>;
export const CreateOperationReportOptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReportOption' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtOptionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzberichtOption' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportOptionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportOptionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtOption' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOperationReportOptionMutation,
    CreateOperationReportOptionMutationVariables
>;
export const UpdateOperationReportOptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReportOption' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtOptionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzberichtOption' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportOptionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportOptionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtOption' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOperationReportOptionMutation,
    UpdateOperationReportOptionMutationVariables
>;
export const DeleteOperationReportOptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReportOption' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzberichtOption' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportOptionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportOptionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtOption' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteOperationReportOptionMutation,
    DeleteOperationReportOptionMutationVariables
>;
export const ListOperationReportOptionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReportOptions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtOptionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtOptionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzberichtOption' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportOptionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportOptionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtOption' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListOperationReportOptionsQuery,
    ListOperationReportOptionsQueryVariables
>;
export const GetOperationReportOptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReportOption' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtOptionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportOptionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportOptionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtOption' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOperationReportOptionQuery, GetOperationReportOptionQueryVariables>;
export const CreateOperationReportTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReportTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtGewerkInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzberichtGewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTradeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTradeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtGewerk' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOperationReportTradeMutation,
    CreateOperationReportTradeMutationVariables
>;
export const UpdateOperationReportTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReportTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtGewerkInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzberichtGewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTradeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTradeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtGewerk' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOperationReportTradeMutation,
    UpdateOperationReportTradeMutationVariables
>;
export const DeleteOperationReportTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReportTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzberichtGewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTradeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTradeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtGewerk' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteOperationReportTradeMutation,
    DeleteOperationReportTradeMutationVariables
>;
export const ListOperationReportTradesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReportTrades' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtGewerkOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtGewerkFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzberichtGewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTradeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTradeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtGewerk' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListOperationReportTradesQuery,
    ListOperationReportTradesQueryVariables
>;
export const GetOperationReportTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReportTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtGewerkById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTradeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTradeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtGewerk' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOperationReportTradeQuery, GetOperationReportTradeQueryVariables>;
export const CreateOperationReportTypeModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReportTypeModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypModulInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzberichtTypModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTypModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtTypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOperationReportTypeModuleMutation,
    CreateOperationReportTypeModuleMutationVariables
>;
export const UpdateOperationReportTypeModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReportTypeModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypModulInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzberichtTypModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTypModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtTypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOperationReportTypeModuleMutation,
    UpdateOperationReportTypeModuleMutationVariables
>;
export const DeleteOperationReportTypeModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReportTypeModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzberichtTypModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTypModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtTypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteOperationReportTypeModuleMutation,
    DeleteOperationReportTypeModuleMutationVariables
>;
export const ListOperationReportTypeModulesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReportTypeModules' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypModulOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypModulFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzberichtTypModul' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTypModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtTypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListOperationReportTypeModulesQuery,
    ListOperationReportTypeModulesQueryVariables
>;
export const GetOperationReportTypeModuleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReportTypeModule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtTypModulById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeModuleFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeModuleFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTypModul' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtTypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzberichtModulId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtModul' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetOperationReportTypeModuleQuery,
    GetOperationReportTypeModuleQueryVariables
>;
export const CreateOperationReportTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReportType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzberichtTyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTyp' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOperationReportTypeMutation,
    CreateOperationReportTypeMutationVariables
>;
export const UpdateOperationReportTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReportType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzberichtTyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTyp' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOperationReportTypeMutation,
    UpdateOperationReportTypeMutationVariables
>;
export const DeleteOperationReportTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReportType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzberichtTyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTyp' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteOperationReportTypeMutation,
    DeleteOperationReportTypeMutationVariables
>;
export const ListOperationReportTypesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReportTypes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtTypFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTyp' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListOperationReportTypesQuery, ListOperationReportTypesQueryVariables>;
export const GetOperationReportTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReportType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtTypById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportTypeFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportTypeFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EinsatzberichtTyp' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOperationReportTypeQuery, GetOperationReportTypeQueryVariables>;
export const UpsertOperationReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'upsertOperationReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'einsatzbericht' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EinsatzberichtInput' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sachbearbeiterIds' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'einsatzberichtDetails' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'UpsertEinsatzberichtDetailInput' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'upsertEinsatzbericht' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'einsatzbericht' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'einsatzbericht' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'sachbearbeiterIds' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'sachbearbeiterIds' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'einsatzberichtDetails' },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'einsatzberichtDetails',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'einsatzbericht' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'OperationReportFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'detailsError' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'einsatzberichtDetail',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'anzahl',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'einsatzberichtOptionId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'jaNein',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'text',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'error' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'code',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'data',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'message',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'globalError' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpsertOperationReportMutation, UpsertOperationReportMutationVariables>;
export const CreateOperationReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createOperationReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createEinsatzbericht' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateOperationReportMutation, CreateOperationReportMutationVariables>;
export const UpdateOperationReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateOperationReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateEinsatzbericht' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateOperationReportMutation, UpdateOperationReportMutationVariables>;
export const DeleteOperationReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteOperationReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteEinsatzbericht' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteOperationReportMutation, DeleteOperationReportMutationVariables>;
export const ListOperationReportsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOperationReports' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EinsatzberichtFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'einsatzbericht' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListOperationReportsQuery, ListOperationReportsQueryVariables>;
export const GetOperationReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOperationReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'einsatzberichtById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'OperationReportFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperationReportFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Einsatzbericht' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtTyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anfahrtUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'einsatzUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abfahrtUntil' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtSachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'einsatzberichtDetail' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOptionId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'einsatzberichtOption' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'typ' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'jaNein' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anzahl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOperationReportQuery, GetOperationReportQueryVariables>;
export const CreateParticipantDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createParticipant' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BeteiligterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createBeteiligter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParticipantFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Beteiligter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateParticipantMutation, CreateParticipantMutationVariables>;
export const UpdateParticipantDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateParticipant' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BeteiligterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateBeteiligter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParticipantFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Beteiligter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateParticipantMutation, UpdateParticipantMutationVariables>;
export const DeleteParticipantDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteParticipant' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteBeteiligter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParticipantFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Beteiligter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteParticipantMutation, DeleteParticipantMutationVariables>;
export const ListParticipantsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listParticipants' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BeteiligterOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'BeteiligterFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'beteiligter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParticipantFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Beteiligter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektWohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kontakt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anredeSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firma2' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freitext' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'landSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postfach' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonDirekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonMobil' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'telefonPrivat' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellOrt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellPlz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'zustellStrasse' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kategorie' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListParticipantsQuery, ListParticipantsQueryVariables>;
export const CreateSubprojektToProjektDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createSubprojektToProjekt' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CreateSubprojektToProjektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'subproject' },
                                    name: { kind: 'Name', value: 'createSubprojektToProjekt' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'input' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'input' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'SubprojektFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateSubprojektToProjektMutation,
    CreateSubprojektToProjektMutationVariables
>;
export const UpdateSubprojectWithProjectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSubprojectWithProject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateSubprojektWithProjektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'subproject' },
                                    name: { kind: 'Name', value: 'updateSubprojektWithProjekt' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'input' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'input' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'SubprojektFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateSubprojectWithProjectMutation,
    UpdateSubprojectWithProjectMutationVariables
>;
export const SubprojectCsvExportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'subprojectCsvExport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektSearch' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektOrderByInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojectCsvExport' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'searchInput' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'searchInput' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'orderBy' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'orderBy' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubprojectCsvExportQuery, SubprojectCsvExportQueryVariables>;
export const SubprojectSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'subprojectSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektSearch' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektSearch' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'limit' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'offset' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'offset' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'orderBy' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'orderBy' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'searchInput' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'searchInput' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageInfo' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pageCount',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'totalCount',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'SubprojektSucheFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektSucheFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SubprojectSearchQuery, SubprojectSearchQueryVariables>;
export const CreateSubprojectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createSubproject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createSubprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SubprojektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateSubprojectMutation, CreateSubprojectMutationVariables>;
export const UpdateSubprojectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSubproject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateSubprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SubprojektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSubprojectMutation, UpdateSubprojectMutationVariables>;
export const DeleteSubprojectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteSubproject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteSubprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SubprojektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSubprojectMutation, DeleteSubprojectMutationVariables>;
export const ListSubprojectsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listSubprojects' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'subprojekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SubprojektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListSubprojectsQuery, ListSubprojectsQueryVariables>;
export const GetSubprojectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSubproject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'subprojektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SubprojektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSubprojectQuery, GetSubprojectQueryVariables>;
export const CreateProjectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createProject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjektInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createProjekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bemerkung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'intern' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektGewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateProject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjektInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateProjekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bemerkung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'intern' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektGewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateSubprojektWithProjectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createSubprojektWithProject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CreateSubprojektWithProjektInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'subproject' },
                                    name: { kind: 'Name', value: 'createSubprojektWithProjekt' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'input' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'input' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'SubprojektFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subprojekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bemerkung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'auftragsNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bemerkungAufmass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'abrechnungsDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bonitaetSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonitaetSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'versicherungsArtSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versicherungsArtSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'umsatzPrognose' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termine' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'geplant' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dauer' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiterIdUrheber' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'prio' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kuerzel' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'istGesamtobjekt' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektGewerk' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'gewerkId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isStandard' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekttyp' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'niederlassung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligte' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kontaktId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freitext' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma1' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'firma2' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonDirekt' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonMobil' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'telefonPrivat' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateSubprojektWithProjectMutation,
    CreateSubprojektWithProjectMutationVariables
>;
export const DeleteProjectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteProject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteProjekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bemerkung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'intern' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektGewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const ListProjectsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listProjects' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ProjektOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ProjektFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'projekt' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bemerkung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'intern' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektGewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListProjectsQuery, ListProjectsQueryVariables>;
export const GetProjectDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getProject' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'projektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ProjektFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProjektFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Projekt' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'schadenTag' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strasse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'statusSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektWohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wattroProjectExists',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligter' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'anlageDatum' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projekttypId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bemerkung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'intern' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ihrZeichen' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'buergschaft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'schadenNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheinNr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'notdienst' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'rahmenvertragId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'versicherungId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'versicherungsArtSnippetId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojektGewerk' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'gewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const ProjectDetailsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'projectDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'projektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'project' },
                        name: { kind: 'Name', value: 'projektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'projektId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'schadenTag' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'statusSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'statusSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'subproject' },
                        name: { kind: 'Name', value: 'subprojektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subprojektId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bemerkung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'html' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'raw' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekttypId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekttyp' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'notdienst' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subprojektGewerk' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'gewerkId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'gewerk' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'intern' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weg' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertragId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rahmenvertrag' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'ihrZeichen' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bonitaetSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bonitaetSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'buergschaft' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'versicherungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'versicherung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'versicherungsArtSnippetId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'versicherungsArtSnippet',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'subprojektWohneinheit',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wattroProjectExists',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wohneinheitId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'beteiligter',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kontakt',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'version',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'firma1',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'firma2',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'vorname',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippetId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'typSnippet',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'wohneinheit',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'nr',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'bezeichnung',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'schadenNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'scheinNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'version',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'plz',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ort',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'strasse',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefon',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonDirekt',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonMobil',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'telefonPrivat',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fax',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kategorie',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'umsatzPrognose' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'abrechnungsDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'auftragsNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'statusSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bemerkungAufmass' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProjectDetailsQuery, ProjectDetailsQueryVariables>;
export const SelectProjectTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'selectProjectType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ProjekttypOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ProjekttypFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'projekttyp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'value' },
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'label' },
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SelectProjectTypeQuery, SelectProjectTypeQueryVariables>;
export const SelectBranchesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'selectBranches' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'NiederlassungOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'NiederlassungFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'niederlassung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'value' },
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'label' },
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nummer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SelectBranchesQuery, SelectBranchesQueryVariables>;
export const SelectTradeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'selectTrade' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GewerkOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'GewerkFilterInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'gewerk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'value' },
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'label' },
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SelectTradeQuery, SelectTradeQueryVariables>;
export const SelectGeneralAgreementsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'selectGeneralAgreements' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RahmenvertragOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'RahmenvertragFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'rahmenvertrag' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'value' },
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'label' },
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SelectGeneralAgreementsQuery, SelectGeneralAgreementsQueryVariables>;
export const SelectInsurancesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'selectInsurances' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'VersicherungOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'VersicherungFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'versicherung' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'value' },
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'label' },
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SelectInsurancesQuery, SelectInsurancesQueryVariables>;
export const CreateSubprojectResidentialUnitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createSubprojectResidentialUnit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektWohneinheitInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createSubprojektWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projektId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subprojektId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheitId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'wohneinheit' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'bezeichnung',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'istGesamtobjekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateSubprojectResidentialUnitMutation,
    CreateSubprojectResidentialUnitMutationVariables
>;
export const CreateResidentialUnitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createResidentialUnit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'bezeichnung' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'subprojektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'item' },
                                    name: {
                                        kind: 'Name',
                                        value: 'createWohneinheitWithSubprojektWohneinheit',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'bezeichnung' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'subprojektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'subprojektId' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'item' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'ResidentialUnitFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ResidentialUnitFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wohneinheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'email' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'fax' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'landSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'postfach',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefon',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonDirekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonMobil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonPrivat',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kategorie',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellOrt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellPlz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellStrasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateResidentialUnitMutation, CreateResidentialUnitMutationVariables>;
export const UpdateResidentialUnitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateResidentialUnit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WohneinheitInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ResidentialUnitFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ResidentialUnitFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wohneinheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'email' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'fax' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'landSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'postfach',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefon',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonDirekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonMobil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonPrivat',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kategorie',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellOrt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellPlz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellStrasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateResidentialUnitMutation, UpdateResidentialUnitMutationVariables>;
export const DeleteResidentialUnitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteResidentialUnit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ResidentialUnitFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ResidentialUnitFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wohneinheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'email' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'fax' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'landSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'postfach',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefon',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonDirekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonMobil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonPrivat',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kategorie',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellOrt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellPlz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellStrasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteResidentialUnitMutation, DeleteResidentialUnitMutationVariables>;
export const ListResidentialUnitsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listResidentialUnits' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WohneinheitOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'WohneinheitFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'wohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ResidentialUnitFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ResidentialUnitFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wohneinheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wattroProjectExists' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aufmass' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'laenge' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'breite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hoehe' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wohneinheit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subprojekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontaktId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anredeSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'email' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'fax' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'freitext',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'html',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'raw',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'landSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'postfach',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'statusSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefon',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonDirekt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonMobil',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telefonPrivat',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippetId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'kategorie',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellOrt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellPlz',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zustellStrasse',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListResidentialUnitsQuery, ListResidentialUnitsQueryVariables>;
export const UpdateSubprojectResidentialUnitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSubprojectResidentialUnit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektWohneinheitInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateSubprojektWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'SubprojektResidentialUnitFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektResidentialUnitFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SubprojektWohneinheit' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufmass' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateSubprojectResidentialUnitMutation,
    UpdateSubprojectResidentialUnitMutationVariables
>;
export const DeleteSubprojectResidentialUnitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteSubprojectResidentialUnit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteSubprojektWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'SubprojektResidentialUnitFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektResidentialUnitFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SubprojektWohneinheit' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufmass' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteSubprojectResidentialUnitMutation,
    DeleteSubprojectResidentialUnitMutationVariables
>;
export const ListSubprojectResidentialUnitsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listSubprojectResidentialUnits' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SubprojektWohneinheitOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SubprojektWohneinheitFilterInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'subprojektWohneinheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'SubprojektResidentialUnitFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SubprojektResidentialUnitFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SubprojektWohneinheit' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beteiligter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kontakt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typSnippet' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kategorie' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontaktId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'wohneinheitId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wohneinheit' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nr' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'istGesamtobjekt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'projektId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufmass' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'laenge' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'breite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hoehe' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListSubprojectResidentialUnitsQuery,
    ListSubprojectResidentialUnitsQueryVariables
>;
export const CreateTaskFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createTaskFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'FunktionInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFunctionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFunctionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufgabe' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beschreibung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateTaskFunctionMutation, CreateTaskFunctionMutationVariables>;
export const UpdateTaskFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTaskFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'FunktionInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFunctionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFunctionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufgabe' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beschreibung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateTaskFunctionMutation, UpdateTaskFunctionMutationVariables>;
export const DeleteTaskFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteTaskFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFunctionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFunctionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufgabe' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beschreibung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteTaskFunctionMutation, DeleteTaskFunctionMutationVariables>;
export const ListTaskFunctionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listTaskFunctions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FunktionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FunktionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'funktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFunctionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFunctionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufgabe' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beschreibung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListTaskFunctionsQuery, ListTaskFunctionsQueryVariables>;
export const GetTaskFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTaskFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'funktionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFunctionFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFunctionFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Funktion' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aufgabe' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beschreibung' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mitarbeiter' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'vorname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTaskFunctionQuery, GetTaskFunctionQueryVariables>;
export const ListOpenTasksDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listOpenTasks' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'niederlassungId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufgabeOrderByInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'offeneAufgaben' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'offset' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'offset' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'limit' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'mitarbeiterId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'mitarbeiterId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'niederlassungId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'orderBy' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'orderBy' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageInfo' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pageCount',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'totalCount',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'TaskFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'error' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'code' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'message',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListOpenTasksQuery, ListOpenTasksQueryVariables>;
export const CreateTaskDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createTask' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufgabeCreateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createAufgabe' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTask' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufgabeUpdateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateAufgabe' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const DeleteTaskDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteTask' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'forceOverwrite' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteAufgabe' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'version' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'forceOverwrite' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'forceOverwrite' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const ListTasksDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listTasks' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufgabeOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AufgabeFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'aufgabe' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListTasksQuery, ListTasksQueryVariables>;
export const GetTaskDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTask' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'aufgabeById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TaskFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TaskFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Aufgabe' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'angelegt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beschreibung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'erledigt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdSachbearbeiter' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterIdUrheber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterIdAbschlusszustaendiger' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abschlusszustaendiger' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'subprojektId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subprojekt' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'lfdNr' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekttyp' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'beteiligte' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kontakt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vorname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma1' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'firma2' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'typSnippet' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projekt' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'plz' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'ort' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'strasse' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassungId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'anlageDatum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'niederlassung' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'nummer' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'zuErledigen' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'urheber' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sachbearbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTaskQuery, GetTaskQueryVariables>;
export const TaskDefaultValuesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'taskDefaultValues' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'subprojektById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lfdNr' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'projekt' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'lfdNr' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'strasse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ort' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'plz' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'niederlassungId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'anlageDatum',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'niederlassung',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'nummer',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'beteiligte' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'typSnippet',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kontakt',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma1',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firma2',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'vorname',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TaskDefaultValuesQuery, TaskDefaultValuesQueryVariables>;
export const WattroUpdateDeploymentsToProjektDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'wattroUpdateDeploymentsToProjekt' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'projektId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'wattroUpdateDeploymentsToProjekt',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'projektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'projektId' },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WattroFields' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WattroFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommandResult' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WattroUpdateDeploymentsToProjektMutation,
    WattroUpdateDeploymentsToProjektMutationVariables
>;
export const WattroCreateProjectForSubprojektWohneinheitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'wattroCreateProjectForSubprojektWohneinheit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'wattroCreateProjectForSubprojektWohneinheit',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: {
                                                kind: 'Name',
                                                value: 'subprojektWohneinheitId',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'subprojektWohneinheitId',
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WattroFields' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WattroFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommandResult' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WattroCreateProjectForSubprojektWohneinheitMutation,
    WattroCreateProjectForSubprojektWohneinheitMutationVariables
>;
export const WattroAssignNotdienstprojektToSubprojektWohneinheitDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'wattroAssignNotdienstprojektToSubprojektWohneinheit' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notdienstprojektId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'subprojektWohneinheitId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'wattroAssignNotdienstprojektToSubprojektWohneinheit',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'notdienstprojektId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'notdienstprojektId' },
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: {
                                                kind: 'Name',
                                                value: 'subprojektWohneinheitId',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'subprojektWohneinheitId',
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WattroFields' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WattroFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommandResult' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WattroAssignNotdienstprojektToSubprojektWohneinheitMutation,
    WattroAssignNotdienstprojektToSubprojektWohneinheitMutationVariables
>;
export const CreateAbsenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createAbsence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AbwesenheitInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createAbwesenheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AbsenceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AbsenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Abwesenheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateAbsenceMutation, CreateAbsenceMutationVariables>;
export const UpdateAbsenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateAbsence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AbwesenheitInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateAbwesenheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AbsenceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AbsenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Abwesenheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateAbsenceMutation, UpdateAbsenceMutationVariables>;
export const DeleteAbsenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteAbsence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteAbwesenheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AbsenceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AbsenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Abwesenheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteAbsenceMutation, DeleteAbsenceMutationVariables>;
export const ListAbsencesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listAbsences' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AbwesenheitOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AbwesenheitFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'abwesenheit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AbsenceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AbsenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Abwesenheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListAbsencesQuery, ListAbsencesQueryVariables>;
export const GetAbsenceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAbsence' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'abwesenheitById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AbsenceFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AbsenceFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Abwesenheit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumFrom' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'datumUntil' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'typSnippetId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typSnippet' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'kuerzel' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAbsenceQuery, GetAbsenceQueryVariables>;
export const CreateEmployeeFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createEmployeeFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterFunktionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createMitarbeiterFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'EmployeeFunctionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFunctionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MitarbeiterFunktion' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateEmployeeFunctionMutation,
    CreateEmployeeFunctionMutationVariables
>;
export const UpdateEmployeeFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEmployeeFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterFunktionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateMitarbeiterFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'EmployeeFunctionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFunctionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MitarbeiterFunktion' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateEmployeeFunctionMutation,
    UpdateEmployeeFunctionMutationVariables
>;
export const DeleteEmployeeFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEmployeeFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteMitarbeiterFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'EmployeeFunctionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFunctionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MitarbeiterFunktion' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteEmployeeFunctionMutation,
    DeleteEmployeeFunctionMutationVariables
>;
export const ListEmployeeFunctionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listEmployeeFunctions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterFunktionOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterFunktionFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'EmployeeFunctionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFunctionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MitarbeiterFunktion' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListEmployeeFunctionsQuery, ListEmployeeFunctionsQueryVariables>;
export const GetEmployeeFunctionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEmployeeFunction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'mitarbeiterFunktionById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'EmployeeFunctionFields',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFunctionFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MitarbeiterFunktion' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'funktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'mitarbeiterId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEmployeeFunctionQuery, GetEmployeeFunctionQueryVariables>;
export const CreateEmployeeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createEmployee' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'createMitarbeiter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EmployeeFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Mitarbeiter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'funktion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekthistorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubstage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubsgruppe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'passwort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessIp' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateEmployeeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEmployee' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'updateMitarbeiter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EmployeeFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Mitarbeiter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'funktion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekthistorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubstage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubsgruppe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'passwort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessIp' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEmployee' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'deleteMitarbeiter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EmployeeFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Mitarbeiter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'funktion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekthistorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubstage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubsgruppe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'passwort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessIp' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const ListEmployeesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listEmployees' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterOrderByInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MitarbeiterFilterInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'items' },
                        name: { kind: 'Name', value: 'mitarbeiter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pageCount' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalCount' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EmployeeFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Mitarbeiter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'funktion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekthistorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubstage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubsgruppe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'passwort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessIp' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListEmployeesQuery, ListEmployeesQueryVariables>;
export const GetEmployeeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEmployee' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'item' },
                        name: { kind: 'Name', value: 'mitarbeiterById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'EmployeeFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'error' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmployeeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Mitarbeiter' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rolleId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mitarbeiterFunktion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'funktionId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'funktion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bezeichnung' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'vorname' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'telefon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'projekthistorie' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'niederlassungId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'niederlassung' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nummer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bezeichnung' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubstage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'urlaubsgruppe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'farbe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'kennung' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'passwort' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessIp' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEmployeeQuery, GetEmployeeQueryVariables>;
