import { Fragment } from 'react';
import { AiFillEdit, AiOutlineCheck } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';
import { projectParticipant, localeDateString } from '~/pages/projects/utils';
import { generateProjectNumber, isJsonString } from '~/utils';
import { Property } from './Property';
import { PropertyCard } from './PropertyCard';
import { ProjectType } from '~/pages/projects/components/ProjectType';
import { join } from '~/utils';
import { HasPermission } from '~/layout/HasPermission';
import { renderElement } from '~/components/Renderers';

export const ProjectProperties = ({
    isLoading = false,
    linkTo,
    project,
    subproject,
}: {
    isLoading?: boolean;
    linkTo?: string;
    project?: any;
    subproject: any;
}) => {
    const projektNr = project
        ? generateProjectNumber(project.anlageDatum, project.niederlassung?.nummer, project.lfdNr)
        : '';

    const metadata = {
        'Beauftragter Handwerker':
            projectParticipant('HW', subproject?.beteiligte, true) ||
            projectParticipant('Beauftragter Handwerker', subproject?.beteiligte, true),
    };

    const properties = {
        'Projekt-Nr (Schaden)': projektNr,
        'Projekt-Nr (SubAuftrag)': join([project?.lfdNr, subproject?.lfdNr], '-'),
        Erfassungsdatum: localeDateString(project?.anlageDatum, {
            hour: '2-digit',
            minute: '2-digit',
        }),
        Niederlassung: join(
            [project?.niederlassung?.nummer, project?.niederlassung?.bezeichnung],
            ' - '
        ),
        Typ: <ProjectType>{subproject?.projekttyp?.name}</ProjectType>,
        Intern: subproject?.intern ? <AiOutlineCheck /> : '-',
        'Beauftragter Handwerker':
            projectParticipant('HW', subproject?.beteiligte) ||
            projectParticipant('Beauftragter Handwerker', subproject?.beteiligte),
        Gewerk: subproject?.subprojektGewerk?.[0]?.gewerk?.name,
        'Schaden Tag': localeDateString(project?.schadenTag),
        Status: subproject?.statusSnippet?.name || project?.statusSnippet?.name,
        'Umsatz (Prognose)': subproject?.umsatzPrognose
            ? new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            }).format(subproject?.umsatzPrognose)
            : '-',
        Abrechnungsdatum:
            subproject?.abrechnungsDatum &&
            new Date(subproject?.abrechnungsDatum).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }),
    };

    return (
        <PropertyCard
            title="Projekt"
            data-test-id="project-card"
            actions={
                <HasPermission resource="project.project" permission="update">
                    <IconButton
                        as={Link}
                        to={
                            linkTo || `/projekt/${project?.id}/${subproject?.id}/bearbeiten#projekt`
                        }
                        aria-label="Projekteigenschaften bearbeiten"
                        icon={<AiFillEdit />}
                        variant="outline"
                    />
                </HasPermission>
            }
        >
            {Object.entries(properties).map(([label, value]) => (
                <Property
                    key={label}
                    isLoading={isLoading}
                    label={label}
                    value={value}
                    metadata={metadata}
                />
            ))}
        </PropertyCard>
    );
};

export const ClientProperties = ({
    isLoading = false,
    linkTo,
    subproject,
    project,
}: {
    isLoading?: boolean;
    linkTo?: string;
    project?: any;
    subproject: any;
}) => {
    const metadata = {
        Vermittler: projectParticipant('Vermittler', subproject?.beteiligte, true),
        Auftraggeber: projectParticipant('Auftraggeber', subproject?.beteiligte, true),
        Eigentümer: projectParticipant('Eigentümer', subproject?.beteiligte, true),
    };

    const properties = {
        Vermittler: projectParticipant('Vermittler', subproject?.beteiligte),
        Auftraggeber: projectParticipant('Auftraggeber', subproject?.beteiligte),
        Eigentümer: projectParticipant('Eigentümer', subproject?.beteiligte),
        WEG: subproject?.weg,
        Rahmenvereinbarung: subproject?.rahmenvertrag?.bezeichnung,
        'Ihr Zeichen': subproject?.ihrZeichen,
        Bonität: subproject?.bonitaetSnippet?.name,
    };

    return (
        <PropertyCard
            title="Auftraggeber"
            data-test-id="client-card"
            actions={
                <HasPermission resource="project.project" permission="update">
                    <IconButton
                        as={Link}
                        to={
                            linkTo ||
                            `/projekt/${project?.id}/${subproject?.id}/bearbeiten#auftraggeber`
                        }
                        aria-label="Auftraggebereigenschaften bearbeiten"
                        icon={<AiFillEdit />}
                        variant="outline"
                    />
                </HasPermission>
            }
        >
            {Object.entries(properties).map(([label, value]) => (
                <Property
                    key={label}
                    isLoading={isLoading}
                    label={label}
                    value={value}
                    metadata={metadata}
                />
            ))}
        </PropertyCard>
    );
};

export const InsuranceProperties = ({
    isLoading = false,
    linkTo,
    subproject,
    project,
}: {
    isLoading?: boolean;
    linkTo?: string | any;
    project?: any;
    subproject: any;
}) => {
    const metadata = {
        'Vers. Nehmer': projectParticipant('Versicherungsnehmer', subproject?.beteiligte, true),
    };
    const properties = {
        Versicherung: subproject?.versicherung?.bezeichnung,
        'Vers. Nehmer': projectParticipant('Versicherungsnehmer', subproject?.beteiligte),
        'Vers. Art': subproject?.versicherungsArtSnippet?.name,
        'Schaden-Nr': subproject?.schadenNr,
        'Vertrags-Nr': subproject?.scheinNr,
    };

    return (
        <PropertyCard
            title="Versicherung"
            data-test-id="insurance-card"
            actions={
                <HasPermission resource="project.project" permission="update">
                    <IconButton
                        as={Link}
                        to={
                            linkTo ||
                            `/projekt/${project?.id}/${subproject?.id}/bearbeiten#versicherung`
                        }
                        aria-label="Versicherungseigenschaften bearbeiten"
                        icon={<AiFillEdit />}
                        variant="outline"
                    />
                </HasPermission>
            }
        >
            {Object.entries(properties).map(([label, value]) => (
                <Property
                    key={label}
                    isLoading={isLoading}
                    label={label}
                    value={value}
                    metadata={metadata}
                />
            ))}
        </PropertyCard>
    );
};

export const ObjectProperties = ({
    isLoading = false,
    linkTo,
    project,
    subproject,
}: {
    isLoading?: boolean;
    linkTo?: string;
    project?: any;
    subproject: any;
}) => {
    const comment = subproject?.bemerkung?.raw && isJsonString(subproject?.bemerkung?.raw) ? JSON.parse(subproject?.bemerkung?.raw) : [];
    const properties = {
        Straße: project?.strasse,
        PLZ: project?.plz,
        Ort: project?.ort,
        Bemerkung: comment.map((node: any, idx: number) => (
            <Fragment key={`object-property-comment-${idx}`}>
                {renderElement(node)}
            </Fragment>
        )),
    };

    return (
        <PropertyCard
            title="Objekt"
            data-test-id="object-card"
            actions={
                <HasPermission resource="project.project" permission="update">
                    <IconButton
                        as={Link}
                        to={linkTo || `/projekt/${project?.id}/${subproject?.id}/bearbeiten#objekt`}
                        aria-label="Objekteigenschaften bearbeiten"
                        icon={<AiFillEdit />}
                        variant="outline"
                    />
                </HasPermission>
            }
        >
            {Object.entries(properties).map(([label, value]) => (
                <Property key={label} isLoading={isLoading} label={label} value={value} />
            ))}
        </PropertyCard>
    );
};

export const OperationalReportProperties = ({
    isLoading = false,
    project,
    subproject,
}: {
    isLoading?: boolean;
    linkTo?: string;
    project?: any;
    subproject: any;
}) => {
    const metadata = {
        Auftraggeber: projectParticipant('Auftraggeber', subproject?.beteiligte, true),
    };
    const properties = {
        Auftraggeber: projectParticipant('Auftraggeber', subproject?.beteiligte),
        'Projekt-Nr (SubAuftrag)': [project?.lfdNr, subproject?.lfdNr].join('-'),
        Typ: <ProjectType>{subproject?.projekttyp?.name}</ProjectType>,
        'Ihr Zeichen': subproject?.ihrZeichen,
        'Vertrags-Nr': subproject?.scheinNr,
        'Schaden-Nr': subproject?.schadenNr,
        Schadenobjekt: '',
        Straße: project?.strasse,
        PLZ: project?.plz,
        Ort: project?.ort,
    };

    return (
        <PropertyCard>
            {Object.entries(properties).map(([label, value]) => (
                <Property
                    key={label}
                    isLoading={isLoading}
                    label={label}
                    value={value}
                    metadata={metadata}
                />
            ))}
        </PropertyCard>
    );
};
