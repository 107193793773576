import { EmergencyProjectsDialog } from "~/pages/projects/ui/Wattro/EmergencyProjectsDialog"
import { WattroDialog } from "~/pages/projects/ui/Wattro/WattroDialog"

export const DialogProvider = () => {
    return (
        <>
            <WattroDialog />
            <EmergencyProjectsDialog />
        </>
    )
}