import React from 'react';
import {
    CreateElectricityMeterDocument,
    DeleteElectricityMeterDocument,
    ListElectricityMeterDocument,
    ListElectricityMeterQueryVariables,
    Stromzaehler,
    UpdateElectricityMeterDocument,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/projects/meta/data/electricity-meter.schema';
import { ProjectIds } from '~/pages/projects/types';
import {
    useContextAndRefetchQueries,
    useDeleteWithConfirmation,
    useModal,
    usePermission,
} from '~/hooks';
import { useElectricityMeterSettlement } from './useElectricityMeterSettlement';
import { useMutation, useQuery } from '@apollo/client';
import { Modals } from '~/pages/projects/ui/DeviceUsageAndElectricalMeters';

type Props = {
    tableName?: 'electricityMeter';
    residentialUnitId: number;
    dispatch?: React.Dispatch<any>;
    onOpen?: () => void;
    refetchQueries?: [];
    residentialUnit?: any;
    limit?: number;
} & ProjectIds;

export const useElectricityMeter = ({
    tableName = 'electricityMeter',
    residentialUnitId,
    subprojectId,
    projectId,
    residentialUnit,
    limit = 250,
    ...props
}: Props) => {
    const { canCreate, canView, canEdit, canDelete } = usePermission('project.deviceUsage');
    const readOnly = canView && !(canCreate || canEdit);

    // ===============================================
    // LIST
    // ===============================================

    const context = { clientName: 'ucpw' };
    const variables: ListElectricityMeterQueryVariables = {
        limit,
        filter: {
            subprojektWohneinheitId: residentialUnitId,
            subprojektWohneinheit: {
                subprojektId: subprojectId,
                subprojekt: {
                    projektId: projectId,
                },
            },
        },
    };

    const { refetchQueries: subprojektWohneinheitIdRefetchQueries } = useContextAndRefetchQueries(
        ListElectricityMeterDocument,
        variables
    );
    const { refetchQueries: subprojektWohneinheitenRefetchQueries } = useContextAndRefetchQueries(
        ListElectricityMeterDocument,
        {
            limit,
            filter: {
                subprojektWohneinheit: {
                    subprojektId: subprojectId,
                    subprojekt: {
                        projektId: projectId,
                    },
                },
            },
        }
    );

    const {
        data,
        loading: listLoading,
        error,
    } = useQuery(ListElectricityMeterDocument, { variables, context });
    const electricityMeters = data?.items?.items || [];

    const refetchQueries = [
        // internal
        ...subprojektWohneinheitIdRefetchQueries,
        ...subprojektWohneinheitenRefetchQueries,
        {
            query: ListElectricityMeterDocument,
            context,
            variables: {
                limit,
                filter: {
                    subprojektWohneinheit: {
                        subprojektId: subprojectId,
                        subprojekt: {
                            projektId: projectId,
                        },
                    },
                },
            },
        },
        // external
        ...(props.refetchQueries || []),
    ];

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const options = { context, refetchQueries };
    const [createMutation, { loading: createLoading }] = useMutation(
        CreateElectricityMeterDocument,
        options
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateElectricityMeterDocument,
        options
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteElectricityMeterDocument,
        options
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries,
        dependencies: [deleteMutation, refetchQueries],
    });

    // ===============================================
    // Modal
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // TABLE
    // ===============================================

    const isNotEmpty = electricityMeters.length > 0;
    const allSettled = electricityMeters.reduce(
        (acc: any, meter: any) => acc && meter.abrechnen,
        true
    );
    const allUnsettled: any = electricityMeters.filter((meter: any) => !meter.abrechnen);

    const { updateSettlement } = useElectricityMeterSettlement({ subprojectId, projectId });

    const settleAll = async () => {
        for (const meter of allUnsettled) {
            await updateSettlement({
                id: meter.id,
                electricityMeter: meter as Stromzaehler,
                settle: true,
            });
        }
    };

    const setElectricityMeterModal = () => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.ElectricityMeter,
                props: {
                    createMutation,
                    updateMutation,
                    residentialUnit,
                },
            },
        });
    };

    const onClick = (data = {}) => {
        setElectricityMeterModal();
        dispatch?.({ type: 'setRow', data });
        onOpen?.();
    };

    const controls = [
        {
            title: 'Ansehen',
            props: {
                onClick: (row: any) => onClick({ row }),
            },
            enabled: () => readOnly,
        },
        {
            title: 'Einfügen',
            props: {
                onClick: () => onClick({ row: { original: {} } }),
            },
            enabled: () => canCreate,
        },
        {
            title: 'Bearbeiten',
            props: {
                onClick: (row: any) => onClick({ row }),
            },
            enabled: () => canEdit && isNotEmpty,
        },
        {
            title: 'Abrechnen',
            props: (row: { original: Stromzaehler }) => {
                const { original } = row;

                return {
                    onClick: () => {
                        updateSettlement({
                            id: row.original?.id,
                            electricityMeter: row.original,
                            settle: true,
                        });
                    },
                    isDisabled: original.abrechnen === true,
                };
            },
            enabled: () => canEdit && isNotEmpty,
        },
        {
            title: 'Abrechnen [Alle]',
            props: {
                onClick: () => settleAll(),
                isDisabled: allSettled,
            },
            enabled: () => canEdit && isNotEmpty,
        },
        {
            title: 'divider',
            enabled: () => canDelete && isNotEmpty && (canEdit || canCreate || readOnly),
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => deleteWithConfirmation({ id: row?.original?.id }),
            },
            enabled: () => canDelete && isNotEmpty,
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables[tableName].columns, controls),
        [allSettled, allUnsettled]
    );
    const hiddenColumns = tables[tableName].hiddenColumns;

    const loading = listLoading || createLoading || updateLoading || deleteLoading;

    return {
        data: isNotEmpty ? electricityMeters : [{} as Stromzaehler],
        loading,
        error,
        columns,
        pageSize: limit,
        hiddenColumns,
        createMutation,
        updateMutation,
        deleteMutation,
        deleteWithConfirmation,
    };
};
