import ReactSelect, { components, Props } from 'react-select';

export const StyledSelect = (props: Props) => {
    const Option = (optionProps: any) => {
        const testId = `${props.name}-option-${optionProps.data?.label}`;
        return (
            <components.Option
                {...optionProps}
                innerProps={Object.assign({}, optionProps.innerProps, { 'data-test-id': testId })}
            />
        );
    };
    const invalid = props['aria-invalid'];

    return (
        <ReactSelect
            components={{
                Option,
            }}
            styles={{
                indicatorsContainer: (provided) => ({
                    ...provided,
                    '& svg': {
                        fill: 'var(--chakra-colors-gray-700)',
                    },
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    backgroundColor: 'var(--chakra-colors-gray-200)',
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: 'rgba(0, 0, 0, 0.36)',
                }),
                control: (provided) => ({
                    ...provided,
                    borderColor: 'var(--chakra-colors-gray-200)',
                    ...(invalid && {
                        borderColor: 'var(--chakra-colors-red-500)',
                        boxShadow: '0 0 0 1px var(--chakra-colors-red-500)',
                    }),
                    borderRadius: 'var(--chakra-radii-md)',
                    minHeight: 'var(--chakra-sizes-10)',
                    '&:hover': {
                        borderColor: 'var(--chakra-colors-gray-300)',
                    },
                }),
            }}
            {...props}
        />
    );
};
