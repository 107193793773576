import {
    Avatar,
    Box,
    Button,
    Flex,
    FlexProps,
    HStack,
    Icon,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useMenuButton,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { HiSelector } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { useSecurity } from '@ucc/react/security';
import React from 'react';
import { useAsset } from '~/hooks';

export const UserProfile = () => {
    const { authService, viewer } = useSecurity();

    return (
        <Menu>
            <UserProfileButton
                data-test-id="userProfile"
                role={viewer?.email || ''}
                name={viewer?.name || ''}
                avatar={viewer?.avatar}
            />
            {/** wrapping MenuList with Box fixes Popper warning: CSS "margin" styles cannot be used */}
            <Box>
                <MenuList
                    shadow="lg"
                    py="4"
                    color={useColorModeValue('gray.600', 'gray.200')}
                    px="3"
                >
                    <Text fontWeight="medium" mb="2">
                        {viewer?.email || ''}
                    </Text>
                    <MenuDivider />
                    <MenuItem rounded="md" as={NavLink} to="/account">
                        <HStack spacing="3" w="full">
                            <Icon as={AiOutlineUser} boxSize="4" />
                            <Text fontSize="sm">Dein Profil</Text>
                        </HStack>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                        data-test-id="logout"
                        rounded="md"
                        onClick={() => authService.send('LOGOUT')}
                    >
                        <HStack spacing="3" w="full">
                            <Icon as={FiLogOut} boxSize="4" />
                            <Text fontSize="sm">Logout</Text>
                        </HStack>
                    </MenuItem>
                </MenuList>
            </Box>
        </Menu>
    );
};

export interface UserProfileButtonProps extends FlexProps {
    role?: string;
    name?: string;
    avatar?: string;
}

export const UserProfileButton = ({ role, name, avatar, ...props }: UserProfileButtonProps) => {
    const buttonProps = useMenuButton(props);
    const [imgSrc, setImgSrc] = React.useState<string>();
    const { download, upload } = useAsset();

    React.useEffect(() => {
        if (!avatar) return;
        download(avatar)
            .then((img) => {
                setImgSrc(img as string);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [avatar]);

    return (
        <Flex
            as={Button}
            w="full"
            display="flex"
            alignItems="center"
            rounded="lg"
            fontSize="sm"
            userSelect="none"
            cursor="pointer"
            outline="0"
            transition="all 0.2s"
            variant="ghost-on-accent"
            py={7}
            className="user-profile-button"
            {...buttonProps}
        >
            <HStack flex="1" spacing="3" className="user">
                <Avatar name={name} src={imgSrc} boxSize="10" />
                <Box textAlign="start">
                    <Box isTruncated fontWeight="semibold">
                        {name}
                    </Box>
                    <Box
                        maxW="142px"
                        isTruncated
                        fontSize="xs"
                        color="on-accent"
                        sx={{
                            '.user-profile-button:hover &': {
                                color: 'inverted',
                            },
                        }}
                    >
                        {role}
                    </Box>
                </Box>
            </HStack>
            <Box fontSize="lg">
                <HiSelector />
            </Box>
        </Flex>
    );
};
