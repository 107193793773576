import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { PageHeaderLink, PageHeader } from '~/components/Layout/PageHeader';
import { useSnippets } from '~/pages/master-data/hooks/useSnippets';
import { HasPermission } from '~/layout/HasPermission';

const links = [
    { title: 'Aufgabe', path: 'aufgabe' },
    { title: 'Aufmaß', path: 'aufmass' },
    { title: 'Dokument', path: 'dokument' },
    { title: 'Kontakt', path: 'kontakt' },
    { title: 'Korrespondenz', path: 'korrespondenz' },
    { title: 'Projekt', path: 'projekt' },
    { title: 'Termin', path: 'termin' },
    { title: 'Versicherung', path: 'versicherung' },
]

export function Snippets({ title }: { title: string }) {
    const { category = 'aufgabe' } = useParams<{ category: string }>()
    const [activeSnippet, setActiveSnippet] = React.useState<any>(links.find((link) => link.path === category));
    const { plusButtonProps, ...dataTableProps } = useSnippets(
        activeSnippet && { kategoriePrefix: activeSnippet?.title }
    );

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
                <PageHeader.Navigation>
                    <Navigation onClick={setActiveSnippet} />
                </PageHeader.Navigation>
            </PageHeader>
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>{activeSnippet?.title || ''}</CardHeading>
                    <HasPermission resource="masterData.others" permission="create">
                        <CardActions>
                            <PlusButton {...plusButtonProps} />
                        </CardActions>
                    </HasPermission>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </>
    );
}

function Navigation({ onClick }: { onClick?: (args?: any) => void }) {

    return (
        <Stack direction="row" data-test-id="page-header-links">
            {links.map((link) => {
                const to = `/stammdaten/sonstige-stammdaten/${link.path}`
                return (
                    <PageHeaderLink
                        key={to}
                        to={to}
                        onClick={() => onClick?.(link)}
                        data-test-id={`link-${link.path}`}
                        end
                    >
                        {link.title}
                    </PageHeaderLink>
                )
            })}
        </Stack>
    );
}
