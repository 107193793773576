import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import { ListGeneralAgreementPostionDocument } from '~/gql/ucpw/graphql';
import { useDataLoader } from '~/hooks/useDataLoader';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/projects/meta/data/finance.schema';
import { ProjectIds } from '~/pages/projects/types';
import { useLazyQuery } from '@apollo/client';
import log from '~/log';

type Props = {
    tableName?: 'searchGeneralAggrements';
    generalAgreementPositionIds: number[];
    refetchQueries?: [];
} & ProjectIds;


export const useCalculationAgreements = ({
    tableName = 'searchGeneralAggrements',
    generalAgreementPositionIds = [],
}: Props) => {
    log.trace('useCalculationAgreements', { generalAgreementPositionIds });

    // ===============================================
    // LIST
    // ===============================================

    const context = { clientName: 'ucpw' };
    const [loadAgreements, { data, loading, error }] = useLazyQuery(
        ListGeneralAgreementPostionDocument,
        { context }
    );
    const positions = data?.items?.items || [];
    const { pageCount, totalCount } = data?.items?.pageInfo || {};

    const { fetchData } = useDataLoader(loadAgreements);

    // ===============================================
    // TABLE
    // ===============================================

    const renderers = {
        MultiSelect: ({ row }: any) => {
            return (
                <Checkbox
                    readOnly
                    isChecked={generalAgreementPositionIds?.includes(row?.original?.id)}
                />
            );
        },
    };

    const columns = React.useMemo(
        () => withRenderers(tables[tableName].columns, [], renderers),
        [generalAgreementPositionIds]
    );
    const hiddenColumns = tables[tableName].hiddenColumns;

    return {
        data: positions,
        loading,
        error,
        columns,
        loadAgreements,
        hiddenColumns,
        pageCount,
        totalCount,
        fetchData,
    };
};
