import React from 'react';
import { isEqual } from 'lodash';
import { useInterpret, useActor } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { createModalMachine, ModalMachine, context as modalContext, ModalMachineContext } from './machines/modalMachine';
import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react';
import log from '~/log';

type Component = | React.ReactNode | null;

export interface ModalContextProps extends UseDisclosureProps {
    modalService: InterpreterFrom<ModalMachine>;
    modalContext?: ModalMachineContext;
    setModalInterpreterOptions?: (options: any) => void;
    state?: any;
    dispatch?: React.Dispatch<{ type: string; data?: any }>;
    setReducer?: (reducer: (state: any) => any, defaultState: Record<string, any>) => void;
    component?: Component
    setComponent?: (component: any) => void;
    hist?: Record<string, any>;
}



export const ModalContext = React.createContext<ModalContextProps>({
    modalService: {} as InterpreterFrom<ModalMachine>,
    modalContext,
    state: {},
    dispatch: () => log.debug('ModalContext.dispatch'),
    component: <h1>Hier könnte Ihre Werbung stehen</h1>,
    hist: []
});


export function ModalProvider<TState = any>(
    { children, ...props }: React.PropsWithChildren<{
        defaultState?: TState;
        reducer?: (state: TState) => TState;
    }>
) {
    const [hist, setHist] = React.useState<any>({})
    const [modalInterpreterOptions, setModalInterpreterOptions] = React.useState<any>({});
    const { defaultState: controlledDefaultState = {} as TState, reducer: controlledReducer = (state: TState) => state } = props;
    const [reducerState, setReducer] = React.useState<any>({ reducer: controlledReducer, defaultState: controlledDefaultState });
    const [component, setComponent] = React.useState<any>(null)
    const logger = (state: any) => log.debug(`modal.subscription:${state.value}`, state.context)
    const modalService = useInterpret(
        createModalMachine,
        modalInterpreterOptions,
        // logger
    );
    const [state, dispatch]: any = React.useReducer<any>(reducerState?.reducer, reducerState?.defaultState);
    const disclosure = useDisclosure();
    const [modalState] = useActor(modalService);
    const modalContext = modalState.context;

    React.useEffect(() => {
        const { modals, rows } = state || {};
        setHist((prev: any) => ({
            ...prev,
            ...(modals?.activeModal && rows?.row && { [modals?.activeModal]: state })
        }))
    }, [state])



    React.useEffect(() => {
        !disclosure.isOpen && setHist({});
    }, [disclosure.isOpen])


    const value = {
        state,
        hist,
        dispatch,
        modalService,
        modalContext,
        setModalInterpreterOptions,
        setReducer: (reducer?: (state: TState) => TState, defaultState?: Record<string, any>) => reducer && defaultState && !isEqual(reducerState, { reducer, defaultState }) && setReducer({ reducer, defaultState }),
        component,
        setComponent: (state: any) => state && !isEqual(state, component) && setComponent(state),
        ...disclosure,
    };

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}
