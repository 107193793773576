import React from 'react';
import { Button, chakra } from '@chakra-ui/react';
import { useAsset } from '~/hooks/useAsset';
import log from '~/log';

export const DocumentDownload = ({ path, label }: any) => {
    const { download } = useAsset();

    const downloadAsset = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const migratedFile = path?.startsWith('docs/');

        // Migrated files (~200GB) from the legacy system are not available in development
        // these files can be identified by the path starting with docs/
        // new files are stored in the assets/ folder
        if (process.env.NODE_ENV === 'development' && migratedFile) {
            log.error(`In development we don't have access to the migrated files (~200GB).`, {
                path,
                migratedFile,
            });
            return;
        }

        const url = await download(path);

        log.debug('DocumentDownload.url', { path, url });
        if (url) {
            window.open(url);
        }
    };

    return (
        <Button w="full" colorScheme="blue" variant="link" onClick={downloadAsset}>
            <chakra.span
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                display="block"
            >
                {label}
            </chakra.span>
        </Button>
    );
};
