import { format } from 'date-fns';

export const useTimeRange = (deviceUsage: any[]) => {
    const allDates = deviceUsage?.reduce((acc, item) => {
        if (item?.endeTag && item.beginnTag) {
            const tage =
                (new Date(item?.endeTag)?.getTime() - new Date(item?.beginnTag)?.getTime()) /
                (1000 * 3600 * 24);
            return [
                ...acc,
                `Von: ${format(new Date(item?.beginnTag), 'dd.MM.yyyy')} Bis: ${format(
                    new Date(item?.endeTag),
                    'dd.MM.yyyy'
                )} = ${tage?.toFixed(2)} Tage`,
            ];
        }
        return [...acc];
    }, []);

    return [...new Set(allDates)];
};
