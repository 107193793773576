import React from 'react';
import { max } from 'lodash';
import {
    Angebotsposition,
    Kalkulationsposition,
    DeleteOfferDocument,
    DeleteInvoiceDocument,
    ListDocumentsDocument,
    Rechnungsposition,
    ListCalculationPositionsDocument,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/projects/meta/data/finance.schema';
import { ProjectIds } from '~/pages/projects/types';
import { usePdf } from '~/pages/projects/hooks/usePdf';
import { VALID_OFFER_STATUS_KEY } from '~/pages/projects/ui/Finance/constants';
import { useDeleteWithConfirmation, usePermission } from '~/hooks';
import { useEditable } from './useEditable';
import { client } from '~/apollo';
import { useMutation } from '@apollo/client';
import log from '~/log';

export const Modals = {
    EditFreePosition: 'EditFreePosition',
    SelectGeneralAggrements: 'SelectGeneralAggrements',
    EditGeneralAggrementPosition: 'EditGeneralAggrementPosition',
};

export type FinancePosition = Kalkulationsposition | Angebotsposition | Rechnungsposition;

export const getCalculations = (positions = []) => {
    const generalAgreementPositionIds = positions
        .map((p: any) => p.rahmenvertragspositionId)
        .filter(Boolean);
    const highestPosition = max(positions.map((p: any) => parseInt(p.lfdNr, 10)));

    return { generalAgreementPositionIds, highestPosition };
};

export const getOriginalAndModalFromPosition = (position: FinancePosition) => {
    const original = {
        ...position,
        preisProEinheit: position.einzelpreis,
        leistung: { einheit: position.einheit },
        residentialUnitId: position.subprojektWohneinheitId,
    };
    const modal = position.rahmenvertragspositionId
        ? Modals.EditGeneralAggrementPosition
        : Modals.EditFreePosition;

    log.trace('getOriginalAndModalFromPosition', { position, original, modal });

    return { original, modal };
};

export const calculateTotalPriceNet = (positions = []) => {
    const totalPriceNet = positions.reduce(
        (acc: number, pos: FinancePosition) => acc + pos?.menge * pos?.einzelpreis,
        0
    );
    return totalPriceNet;
};

type Props = {
    refetchQueries?: [];
    totalPriceNet?: number;
    pageHash?: any;
    calculationPositions?: any[];
    send?: (args?: any) => void;
    viewer?: any;
    permissions?: any;
} & ProjectIds;

export const useFinance = ({
    projectId,
    subprojectId,
    calculationPositions,
    send,
    viewer,
}: Props) => {
    log.debug('useFinance', { projectId, subprojectId, calculationPositions, viewer });
    const {
        canView: canViewInvoice,
        canEdit: canEditInvoice,
        canDelete: canDeleteInvoice,
        canCreate: canCreateInvoice,
    } = usePermission('finance.invoice');
    const {
        canView: canViewOffer,
        canEdit: canEditOffer,
        canDelete: canDeleteOffer,
    } = usePermission('finance.offer');
    const downloadInvoiceEnabled = viewer.hasPermission('additional.downloadInvoice', 'enabled');

    const pdf = usePdf({ projectId, subprojectId });
    const { updateEditable } = useEditable();

    // ===============================================
    // LIST
    const context = { clientName: 'ucpw' };
    const [offersDeleteMutation] = useMutation(DeleteOfferDocument, { context });
    const [invoicesDeleteMutation] = useMutation(DeleteInvoiceDocument, { context });

    const refetchQueries = [
        // external
        {
            query: ListCalculationPositionsDocument,
            context,
            variables: {
                filter: {
                    subprojektId: subprojectId,
                },
                limit: 1000,
            },
        },
    ];

    // ===============================================
    // DELETE
    // ===============================================

    const { deleteWithConfirmation: deleteOfferWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation: offersDeleteMutation,
        refetchQueries,
    });
    const { deleteWithConfirmation: deleteInvoiceWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation: invoicesDeleteMutation,
        refetchQueries,
    });

    // ===============================================
    // TABLE
    // ===============================================

    const fetchRelatedDocuments = async (filter: any) => {
        const response = await client.query({
            query: ListDocumentsDocument,
            context: { clientName: 'ucpw' },
            fetchPolicy: 'network-only',
            variables: { limit: 50, filter },
        });
        const documents = response?.data?.items?.items || [];
        return documents;
    };

    const deleteEnabled = React.useCallback(
        (row: any) => {
            const { statusSnippet, __typename } = row?.original || {};

            if (['gültig', 'D', 'S'].includes(statusSnippet?.name)) {
                return false;
            }

            if (__typename === 'Angebot' && canDeleteOffer) {
                return canDeleteOffer;
            }

            if (__typename === 'Rechnung' && canDeleteInvoice) {
                return canDeleteInvoice;
            }

            return false;
        },
        [canDeleteInvoice, canDeleteOffer]
    );

    const controls = React.useMemo(
        () =>
            [
                {
                    title: canViewOffer && !canEditOffer ? 'Ansehen' : 'Bearbeiten',
                    props: {
                        onClick: (row: any) => {
                            const { id } = row?.original || {};
                            const type = 'offer';
                            send?.({ type: `EDIT_${type.toUpperCase()}`, id });
                            updateEditable(id, type);
                        },
                    },
                    enabled: (row: any) =>
                        row?.original?.__typename === 'Angebot' && (canEditOffer || canViewOffer),
                },
                {
                    title: canViewInvoice && !canEditInvoice ? 'Ansehen' : 'Bearbeiten',
                    props: {
                        onClick: (row: any) => {
                            const { id } = row?.original || {};
                            const type = 'invoice';
                            send?.({ type: `EDIT_${type.toUpperCase()}`, id });
                            updateEditable(id, type);
                        },
                    },
                    enabled: (row: any) =>
                        row?.original?.__typename === 'Rechnung' &&
                        (canEditInvoice || canViewInvoice),
                },
                {
                    title: 'Download',
                    props: {
                        onClick: async (row: any) => {
                            const { id: angebotId, statusSnippet } = row?.original || {};
                            const dokument = await fetchRelatedDocuments({
                                angebotId,
                                deletedExists: false,
                            });
                            return pdf.offer({
                                ...row?.original,
                                dokument,
                                ...(statusSnippet?.name === 'in Bearbeitung' && {
                                    angebotspositionen: calculationPositions,
                                }),
                            });
                        },
                    },
                    enabled: (row: any) =>
                        row?.original?.__typename === 'Angebot' && (canEditOffer || canViewOffer),
                },
                {
                    title: 'Download',
                    props: {
                        onClick: async (row: any) => {
                            const { id: rechnungId, statusSnippet } = row?.original || {};
                            const dokument = await fetchRelatedDocuments({
                                rechnungId,
                                deletedExists: false,
                            });
                            return pdf.invoice({
                                ...row?.original,
                                dokument,
                                ...(statusSnippet?.name === 'Offen' &&
                                    !row?.original?.rechnungspositionen?.length && {
                                        rechnungspositionen: calculationPositions,
                                    }),
                            });
                        },
                    },
                    enabled: (row: any) =>
                        row?.original?.__typename === 'Rechnung' && downloadInvoiceEnabled,
                },
                {
                    title: 'Rechnung erzeugen',
                    props: {
                        onClick: (row: any) => {
                            const { id } = row?.original || {};
                            send?.({ type: 'NEW_INVOICE', fromOfferId: id });
                            updateEditable('new', 'invoice', id);
                        },
                    },
                    enabled: (row: any) =>
                        row?.original?.__typename === 'Angebot' &&
                        row?.original?.statusSnippet?.kuerzel === VALID_OFFER_STATUS_KEY &&
                        canCreateInvoice,
                },
                { title: 'divider', enabled: deleteEnabled },
                {
                    title: 'Löschen',
                    props: {
                        color: 'red.400',
                        onClick: (row: any) => {
                            const callAfter = () => send?.('GET_OFFERS_AND_INVOICES');
                            const { id, __typename } = row?.original || {};
                            if (__typename === 'Angebot') {
                                deleteOfferWithConfirmation({ id }, { callAfter });
                            }
                            if (__typename === 'Rechnung') {
                                deleteInvoiceWithConfirmation({ id }, { callAfter });
                            }
                        },
                    },
                    enabled: deleteEnabled,
                },
            ].filter(Boolean),
        [
            pdf,
            calculationPositions,
            deleteEnabled,
            canCreateInvoice,
            canEditInvoice,
            canViewInvoice,
            canEditOffer,
            canViewOffer,
        ]
    );

    const columns = React.useMemo(
        () => withRenderers(tables.finance.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.finance.hiddenColumns;

    return {
        columns,
        hiddenColumns,
    };
};
