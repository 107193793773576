import React from 'react';
import { format } from 'date-fns';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ProjectIds } from '~/pages/projects/types';
import { CreateEventModalContent, ProjectSearchModalContent } from './EventModal';
import { CalendarCard } from './CalendarCard';
import { useModal } from '~/hooks/useModal';
import { MultiModal } from '~/components/MultiModal';
import { combineReducers, formStateReducer, modalReducer, rowReducer } from '~/reducers';

export type CalendarProps = {
    [key: string]: any;
    branchId?: number;
    projectIds?: ProjectIds;
};

export const Modals = {
    ProjectSearch: 'ProjectSearch',
    CreateEvent: 'CreateEvent',

};

export const Calendar = (props: CalendarProps) => {
    const ctx: any = useOutletContext()
    const params = useParams()
    const modal = useModal({
        defaultState: { modals: { activeModal: 'CreateEvent' }, },
        dependencies: [ctx?.states?.branchId],
        component: <MultiModal>
            <ProjectSearchModalContent
                id={Modals.ProjectSearch}
                branchId={ctx?.states?.branchId}
                {...ctx}
            />
            <CreateEventModalContent
                id={Modals.CreateEvent}
                branchId={ctx?.states?.branchId}
                {...ctx}
            />
        </MultiModal>,
        reducer: combineReducers({
            modals: modalReducer('CreateEvent'),
            row: rowReducer,
            formStateReducer,
        }),
    });



    const navigate = useNavigate()
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date(ctx.date));

    console.log('Calendar', { props, ctx, params, modal, selectedDate })

    return (
        <CalendarCard
            selectedDate={selectedDate}
            setSelectedDate={(date: any) => {
                ctx?.scheduleType === 'day' ? navigate(`/projekte/termine/${format(date, 'yyyy-MM-dd')}`) : ctx?.onSelectedDateChange(date)
                setSelectedDate(date)
            }}
            {...ctx}
            states={{ ...ctx?.states, ...(params?.date ? { scheduleType: 'day' } : { scheduleType: 'week' }) }}

        />
    );
};
