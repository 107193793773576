import { useMutation } from '@apollo/client';
import {
    Geraeteeinsatz,
    ListDeviceUsageDocument,
    UpdateDeviceUsageDocument,
} from '~/gql/ucpw/graphql';
import { ProjectIds } from '../types';

type Props = { id: number; deviceUsage: Geraeteeinsatz; settle: boolean };

export const useDeviceUsageSettlement = ({ subprojectId, projectId }: ProjectIds = {}) => {
    const [updateMutation, { loading }] = useMutation(UpdateDeviceUsageDocument, {
        context: { clientName: 'ucpw' },
    });

    // TODO: single value updates or special mutation
    const updateSettlement = async ({ id, deviceUsage, settle }: Props) => {
        const {
            geraetetypId,
            subprojektWohneinheitId,
            berechnungsmethodeSnippetId,
            beginnTag,
            endeTag,
        } = deviceUsage;
        const variables = {
            id,
            data: {
                geraetetypId,
                subprojektWohneinheitId,
                berechnungsmethodeSnippetId,
                beginnTag,
                endeTag,
                abrechnen: settle,
            },
        };

        const refetchQueries: any = [
            {
                query: ListDeviceUsageDocument,
                context: { clientName: 'ucpw' },
                variables: {
                    limit: 250,
                    filter: {
                        subprojektWohneinheit: {
                            subprojektId: subprojectId,
                            subprojekt: {
                                projektId: projectId,
                            },
                        },
                    },
                },
            },
        ];

        await updateMutation({
            variables,
            refetchQueries,
        });
    };

    return {
        updateSettlement,
        loading,
    };
};
