import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Subprojekt, GetProjectDocument, Projekt } from '~/gql/ucpw/graphql';
import { generateProjectNumberOutOfSubprojectData } from '~/utils/generateProjectNumber';
import { useRedirectToProjectSearch } from './useRedirectToProjectSearch';

export const useTitle = (subproject: Subprojekt) => {
    const onCompleted = useRedirectToProjectSearch();
    const params = useParams();
    const projectId = parseInt(params?.projectId as string, 10);
    const subprojectId = parseInt(params?.subprojectId as string, 10);

    const { data: projectData, loading } = useQuery(GetProjectDocument, {
        variables: { id: projectId },
        context: { clientName: 'ucpw' },
        skip: [projectId, subprojectId].every(Boolean),
        onCompleted,
    });
    const project = (projectData?.item?.item || {}) as Projekt;

    if ([projectId, subprojectId, Object.keys(subproject).length].every(Boolean)) {
        const subprojectNo = generateProjectNumberOutOfSubprojectData(subproject);
        return {
            loading,
            title: `Projekt - ${subprojectNo}`,
            subprojectNo,
            subproject,
        };
    }

    return {
        loading,
        title: `Projekt - ${generateProjectNumberOutOfSubprojectData(
            project?.subprojekt?.[0] as any
        )}`,
        subproject: project?.subprojekt?.[0],
    };
};
