import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { format } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { MultiModal } from '~/components/MultiModal';
import { useModal, useSnippetId, useViewer } from '~/hooks';
import { useProjectForm } from '~/pages/projects/hooks/useProjectForm';
import { useCreateContactDefaultValues } from '~/pages/projects/hooks/useCreateContactDefaultValues';
import { combineReducers, contactReducer, formStateReducer, modalReducer } from '~/reducers';
import { ContactSearchModalContent } from './ContactSearchModalContent';
import { PageHeader, Title, Actions } from '~/components/Layout/PageHeader';
import {
    CreateSubprojektWithProjectDocument,
    CreateCorrespondenceDocument,
    ListZipCodesDocument,
} from '~/gql/ucpw/graphql';
import { CreateContactModalContent } from './CreateContactModalContent';
import { client } from '~/apollo'
import { useMutation } from '@apollo/client';
import { getParticipant } from '~/utils';
import { Question } from '../components/Question';
import { Form, renderField } from '~/components/Form/Form';
import { SectionCard } from '~/components/SectionCard';
import log from '~/log';

export function CreateProject({ title }: { title: string }) {
    const { typSnippetId } = useSnippetId({ name: 'Projektanlage', category: 'Korrespondenz' });
    const viewer = useViewer()
    const employeeId = viewer?.app?.mitarbeiterId;
    const navigate = useNavigate()
    const location = useLocation()

    const {
        watch,
        setError,
        clearErrors,
        setValue,
        formToGql,
        formFields,
        control,
        register,
        handleSubmit,
        errors,
        reset,
        watchFields,
    } = useProjectForm();


    const { objectStreet, objectLocation, objectZipCode, participantClientId, projectBranchId, participantPolicyholderId } = watch();
    useCreateContactDefaultValues({ objectStreet, objectLocation, objectZipCode })

    const { isOpen = false, onClose, dispatch } = useModal({
        defaultState: { modals: { activeModal: 'SearchContact' } },
        component: <MultiModal>
            <ContactSearchModalContent id="SearchContact" />
            <CreateContactModalContent id="CreateContact" />
        </MultiModal>,
        reducer: combineReducers({
            modals: modalReducer('SearchContact'),
            contacts: contactReducer('SearchContact'),
            formState: formStateReducer,
        }),
    });

    React.useEffect(() => {
        /** just make sure that modal is definitely closed if participantClientId is provided */
        if (participantClientId && isOpen) {
            onClose?.()
        }
    }, [participantClientId])



    React.useEffect(() => {
        projectBranchId && clearErrors('projectBranchId')
    }, [projectBranchId])

    const changeZipCodeRelations = React.useCallback(async () => {
        const response = await client.query({
            query: ListZipCodesDocument,
            context: { clientName: 'ucpw' },
            variables: {
                filter: {
                    plzPrefix: objectZipCode
                }
            },
        })
        const data: any = response?.data?.items?.items || []

        if (data?.length === 0) {
            setError('projectBranchId', { message: 'PLZ unbekannt, NL bitte selbst auswählen' });
        }

        if (data?.length === 1 && !objectLocation) {
            const [zipCode] = data;
            setValue('objectLocation', objectLocation ? objectLocation : zipCode?.ort)
            zipCode?.niederlassungId && !projectBranchId &&
                setValue('projectBranchId', { value: zipCode.niederlassungId, label: 'loading...' });
        }

        data?.length > 1 &&
            setError('projectBranchId', { message: 'PLZ nicht eindeutig, Ort / Niederlassung bitte selbst wählen' })

    }, [objectZipCode, objectLocation, projectBranchId])


    const options = { context: { clientName: 'ucpw' } }

    const [createProject, { loading: createProjectLoading }] = useMutation(CreateSubprojektWithProjectDocument, options);
    const [createCorrespondence, { loading: createCorrespondenceLoading }] = useMutation(CreateCorrespondenceDocument, options);

    const loading = createProjectLoading || createCorrespondenceLoading

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (formData) => {
            log.debug('onSubmit.formData', formData);
            const subprojectComment = formData?.subprojectComment || [{ type: 'paragraph', children: [{ text: '' }] }]

            const data: any = formToGql(formData);
            log.debug('onSubmit.data', data);
            const response: any = await createProject({ variables: { input: { ...data, subprojektBemerkung: JSON.stringify(subprojectComment) } } });
            log.debug('onSubmit.response', response);
            const { id: subprojectId, projektId } = response.data?.app?.subproject?.item || {};

            if (subprojectId) {
                const correspondence = await createCorrespondence({
                    variables: {
                        data: {
                            typSnippetId,
                            subprojektId: subprojectId,
                            freitext: JSON.stringify([
                                { type: 'paragraph', children: [{ text: '...Bitte ausfüllen!' }] },
                            ]),
                            beteiligter: '',
                            zeit: new Date(),
                            ...(employeeId && {
                                mitarbeiterIdSachbearbeiter: employeeId,
                                mitarbeiterIdUrheber: employeeId
                            }),
                        },
                    },
                    context: { clientName: 'ucpw' },
                });
                console.log('onSubmit.createCorrespondence', correspondence)
            }

            projektId && subprojectId && navigate(`/projekte/${projektId}/${subprojectId}/details`);
        },
        [typSnippetId, location?.state, employeeId]
    );

    React.useEffect(() => {
        if (location?.state) {
            reset(feedForm(location?.state))
        }
        return () => dispatch?.({ type: 'resetState' })
    }, [location?.state]);



    return <>
        <PageHeader>
            <Title>{title}</Title>
            <Actions>
                <HStack>
                    <Button
                        data-test-id={`cancel`}
                        size="sm"
                        onClick={() =>
                            location?.state ? navigate(-1) : navigate(`/dashboard`)
                        }
                    >
                        Abbrechen
                    </Button>
                    <Button
                        data-test-id={`save`}
                        colorScheme="blue"
                        size="sm"
                        isDisabled={loading}
                        isLoading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </Actions>
        </PageHeader>
        <Container maxW="container.lg" p={3}>
            <Question
                listenTo={!isOpen && participantClientId && !participantPolicyholderId}
                onConfirmation={() => setValue('participantPolicyholderId', participantClientId)}
                question="Als Versicherungsnehmer übernehmen"
                description="Handelt es sich bei dem Auftraggeber ebenfalls um den
            Versicherungsnehmer?"
            />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <VStack alignItems="center" spacing={14}>
                    <SectionCard title="Objekt">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.object.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                        context: {
                                            changeZipCodeRelations
                                        },
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard title="Projekt">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.project.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard title="Auftraggeber">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.client.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard title="Versicherung">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.insurance.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                </VStack>
            </Form>
        </Container>
    </>;
}

function feedForm(data: any) {
    const subprojekt: any = data?.subprojekt?.find((sub: any) => sub.lfdNr === 1) || {};
    const {
        beteiligte,
        rahmenvertragId: subprojectGeneralAgreementId,
        bonitaetSnippetId: subprojectCreditStandingSnippetId,
        versicherungsArtSnippetId: subprojectInsuranceTypeSnippetId,
        versicherungId: subprojectInsuranceId
    } = subprojekt;
    const participantBrokerId = getParticipant(beteiligte, 'Vermittler')?.id || null;
    const participantClientId = getParticipant(beteiligte, 'Auftraggeber')?.id || null;
    const participantOwnerId = getParticipant(beteiligte, 'Eigentümer')?.id || null;
    const participantPolicyholderId = getParticipant(beteiligte, 'Versicherungsnehmer')?.id || null;

    return {
        objectStreet: data?.strasse,
        objectZipCode: data?.plz,
        objectLocation: data?.ort,
        subprojectIntern: subprojekt?.intern,
        subprojectWeg: subprojekt?.weg,
        subprojectYourSign: subprojekt?.ihrZeichen,
        subprojectGuarantee: subprojekt?.buergschaft,
        projectDamageday: format(new Date(), 'yyyy-MM-dd'),
        subprojectContractNumber: subprojekt?.scheinNr,
        subprojectProjectTypeId: {
            value: subprojekt?.projekttyp?.id,
            label: subprojekt?.projekttyp?.name,
        },
        projectBranchId: {
            value: data?.niederlassungId,
            label: `${data?.niederlassung?.nummer} - ${data?.niederlassung?.bezeichnung}`,
        },
        subprojectEmergency: subprojekt?.notdienst || null,
        participantCraftsmanId: null,
        subprojectTradeIds: subprojekt?.subprojektGewerk?.map(({ gewerk }: any) => ({
            value: gewerk?.id,
            label: gewerk?.name,
        })),
        participantBrokerId,
        participantClientId,
        participantOwnerId,
        participantPolicyholderId,
        ...Object.entries({
            subprojectInsuranceTypeSnippetId,
            subprojectCreditStandingSnippetId,
            subprojectGeneralAgreementId,
            subprojectInsuranceId,
        }).reduce((acc, [key, value]) => value ? ({ ...acc, [key]: { value, label: 'loading...' } }) : acc, {})

    };
}

