import { GridItem, HStack, SimpleGrid, StackProps, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import { PermissionGroup, PermissionResource } from './types';

type Props = {
    permissionGroup: PermissionGroup;
    onChange?: (permissionGroup: PermissionGroup) => void;
};

export const FLAGS = ({ permissionGroup, onChange }: Props) => {
    const allCecked = permissionGroup.resources.every((resource) =>
        [...(resource?.permissions || [])].includes('enabled')
    );

    const onPermissionAll = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            console.log('onPermissionAll', event.target.checked);

            const resources = event.target.checked
                ? permissionGroup.resources.map((resource) => ({
                    ...resource,
                    permissions: ['enabled'],
                }))
                : permissionGroup.resources.map((resource) => ({ ...resource, permissions: [] }));

            onChange?.({ ...permissionGroup, resources });
        },
        [permissionGroup]
    );
    const onPermissionChange = React.useCallback(
        (changedResource: PermissionResource, event: React.ChangeEvent<HTMLInputElement>) => {
            const resources = permissionGroup.resources.map((resource) => {
                if (changedResource.id !== resource.id) {
                    return resource;
                }

                const permissions = event.target.checked ? ['enabled'] : [];

                return { ...resource, permissions };
            });

            onChange?.({ ...permissionGroup, resources });
        },
        [permissionGroup]
    );

    return (
        <SimpleGrid columns={2} spacing={6} mb={6}>
            <GridItem colSpan={2}>
                <Text as="h3" fontWeight="bold" fontSize={18}>
                    {permissionGroup.name}
                </Text>
            </GridItem>
            {permissionGroup.resources.map((resource) => (
                <ResourceWrapper key={resource.id}>
                    <Switch
                        isChecked={[...(resource?.permissions || [])].includes('enabled')}
                        onChange={(event) => onPermissionChange(resource, event)}
                    />
                    <Text>{resource.name}</Text>
                </ResourceWrapper>
            ))}
            <ResourceWrapper>
                <Switch isChecked={allCecked} onChange={(event) => onPermissionAll(event)} />
                <Text>Alle</Text>
            </ResourceWrapper>
        </SimpleGrid>
    );
};

const ResourceWrapper = (props: StackProps) => (
    <HStack
        borderColor="gray.200"
        align="center"
        rounded="md"
        w="full"
        borderWidth={1}
        spacing={4}
        p={3}
        {...props}
    />
);
