import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Tooltip,
    FormLabel,
    useBoolean,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Button,
} from '@chakra-ui/react';
import { DataTable, Card, CardHeader, CardActions, CardContent, CardHeading, PlusButton } from '@ucc/react/ui';
import { ListBranchesDocument, Niederlassung } from '~/gql/ucpw/graphql';
import { PageHeaderLink } from '~/components/Layout/PageHeader';
import { useDebounce } from '~/hooks/useDebounce';
import { PageHeader } from '~/components/Layout/PageHeader';
import { client } from '~/apollo';
import { useBranches } from '../hooks/useBranches';
import { HasPermission } from '~/layout/HasPermission';
import { usePermission } from '~/hooks/usePermission';

export async function getBranches() {
    const branches = await client
        .query({ query: ListBranchesDocument, context: { clientName: 'ucpw' } })
        .then(({ data }) => data.items?.items);
    return branches.map(({ __typename, ...branch }) => branch);
}

export function Branches({ title }: { title: string }) {
    const { canCreate, canEdit, canDelete } = usePermission('masterData.branches');
    const { id } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useBoolean();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
    const navigate = useNavigate();
    const [selectedBranch, setSelectedBranch] = React.useState<Niederlassung>();
    const {
        actionProps,
        plusButtonProps,
        getPlusButtonProps,
        branches: refetchedBranches,
        ...dataTableProps
    } = useBranches({
        branchId: id,
        debouncedSearchTerm,
        branch: selectedBranch,
        onSelectBranch: setSelectedBranch,
    });

    React.useEffect(() => {
        if (!id) {
            const branch: any = refetchedBranches?.reduce(
                (acc: any, b: any) =>
                    acc.nummer < b.nummer
                        ? acc
                        : b, {}
            );
            setSelectedBranch(branch);
            navigate(`/stammdaten/niederlassungen/${branch?.id}`);
        }
    }, [id, refetchedBranches]);

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
                <PageHeader.Actions>
                    {canCreate || canEdit || canDelete ? (
                        <Menu>
                            {({ isOpen }) => (
                                <>
                                    <MenuButton
                                        as={Button}
                                        colorScheme="blue"
                                        rightIcon={!isOpen ? <FiChevronDown /> : <FiChevronUp />}
                                    >
                                        Niederlassung
                                    </MenuButton>
                                    <Box>
                                        <MenuList>
                                            {canEdit && (
                                                <MenuItem onClick={actionProps?.onEdit}>
                                                    Bearbeiten
                                                </MenuItem>
                                            )}
                                            {canCreate && (
                                                <MenuItem onClick={actionProps?.onCreate}>Neu</MenuItem>
                                            )}
                                            {(canEdit || canCreate) && canDelete && <MenuDivider />}
                                            {canDelete && (
                                                <MenuItem onClick={actionProps?.onDelete} color="red.500">
                                                    Löschen
                                                </MenuItem>
                                            )}
                                        </MenuList>
                                    </Box>
                                </>
                            )}
                        </Menu>
                    ) : null}
                </PageHeader.Actions>
                <PageHeader.Navigation>
                    <Navigation
                        branches={refetchedBranches as Niederlassung[]}
                        onSelectBranch={setSelectedBranch}
                    />
                </PageHeader.Navigation>
            </PageHeader>
            <Card boxShadow="none">
                <CardHeader>
                    <CardHeading>{selectedBranch?.bezeichnung}</CardHeading>
                    <CardActions>
                        <Tooltip
                            label='Für unzugeordnete Postleitzahlen "/" tippen'
                            placement="top"
                            isOpen={isOpen}
                        >
                            <Box onMouseLeave={setIsOpen.off}>
                                <InputGroup maxWidth="200px" onClick={setIsOpen.on}>
                                    <FormLabel srOnly>Filter ...</FormLabel>
                                    <InputLeftElement pointerEvents="none" color="gray.400">
                                        <BsSearch />
                                    </InputLeftElement>
                                    <Input
                                        variant="flushed"
                                        type="search"
                                        placeholder="Filter ..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </InputGroup>
                            </Box>
                        </Tooltip>
                        <HasPermission resource="masterData.branches" permission="create">
                            <PlusButton key={id} {...plusButtonProps}>
                                Neue Postleitzahl
                            </PlusButton>
                        </HasPermission>
                    </CardActions>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </>

    );
}

function Navigation({
    branches,
    onSelectBranch,
}: {
    branches: Niederlassung[];
    onSelectBranch: (branch: Niederlassung) => void;
}) {

    const links = [...branches].sort((a, b) => a.nummer - b.nummer)?.map?.((branch) => ({
        ...branch,
        path: `/stammdaten/niederlassungen/${branch.id}`,
    }));

    return (
        <Stack direction="row" data-test-id="page-header-links">
            {links.map((link) => (
                <PageHeaderLink
                    onClick={() => onSelectBranch(link)}
                    key={link.path}
                    data-test-id={`link-${link.path}`}
                    to={link.path}
                    end
                >
                    {link.bezeichnung}
                </PageHeaderLink>
            ))}
        </Stack>
    );
}
