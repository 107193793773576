import React from "react";
import * as yup from "yup";
import { pick } from "lodash";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import { forms, fields } from '~/pages/master-data/meta/data/contacts.schema';
import { CreateContactDocument, KontaktInput } from "~/gql/ucpw/graphql";
import { Form, renderField } from "~/components/Form/Form";
import { useModal, useYupValidationResolver } from "~/hooks";
import { resolveFormFields } from "~/utils";

export const Modals = {
    SearchContact: 'SearchContact',
    CreateContact: 'CreateContact',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CreateContactModalContent = (props: { id: string; modalStateOnSelect?: any, defaultValues?: any }) => {
    const { dispatch, state } = useModal();
    const formFields = resolveFormFields(forms.contacts, fields.contacts, { skipValidation: false });
    const defaultValues =
        typeof formFields.defaultValues === 'function'
            ? formFields.defaultValues({})
            : formFields.defaultValues || {};
    const message = 'Bitte entweder "Firma", "Name" oder "Vorname" ausfüllen.'

    const { control, register, handleSubmit, formState: { errors }, reset, setError, clearErrors, watch } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(
            yup.object().shape({
                ...formFields.rules,
                company1: yup
                    .string()
                    .test(
                        'company1',
                        message,
                        (_, { parent }) => [parent?.company1, parent?.firstName, parent?.lastName].some(Boolean)

                    ),
                firstName: yup
                    .string()
                    .test(
                        'firstName',
                        message,
                        (_, { parent }) => [parent?.company1, parent?.firstName, parent?.lastName].some(Boolean)
                    ),
                lastName: yup
                    .string()
                    .test(
                        'lastName',
                        message,
                        (_, { parent }) => [parent?.company1, parent?.firstName, parent?.lastName].some(Boolean)
                    ),
            })
        ),
    });

    const validation = watch(['company1', 'firstName', 'lastName'])


    React.useEffect(() => {
        let isMounted = true
        const hasErrors = errors?.company1 || errors?.firstName || errors?.lastName

        if (isMounted && hasErrors && Object.values(validation)?.some(Boolean)) {
            clearErrors(['company1', 'firstName', 'lastName'])
        }
        return () => {
            isMounted = false
        }
    }, [validation, errors])


    React.useEffect(() => {
        const formStateValues = pick(state?.formState, 'street', 'location', 'zipCode')
        reset({ ...defaultValues, ...props?.defaultValues, ...formStateValues, ...pick(state?.defaultAddress || {}, 'street', 'location', 'zipCode') });
    }, [state?.formState, props.defaultValues, state?.defaultAddress]);

    const [createMutation] = useMutation(CreateContactDocument, {
        context: { clientName: 'ucpw' },
    });

    const onSubmit: SubmitHandler<any> = async (values) => {
        console.log('onSubmit.values', JSON.stringify(values, null, 2));
        const cond = Object.values(pick(values, 'company1', 'firstName', 'lastName')).some(Boolean)
        if (!cond) {
            setError('company1', { message });
            setError('firstName', { message });
            setError('lastName', { message });
        } else if (cond) {
            const data = formFields.toGql(values, defaultValues) as KontaktInput;
            console.log('onSubmit.data', JSON.stringify(data, null, 2));
            const response = await createMutation({ variables: { data } });
            console.log('onSubmit.response', JSON.stringify(response, null, 2));
            const contact = response.data?.item?.item || {};
            dispatch?.({
                type: 'selectContact',
                data: {
                    contact,
                    ...(props?.modalStateOnSelect && { modalStateOnSelect: { ...(props.modalStateOnSelect || { closeModal: true }) } }),
                    ...(state?.modalStateOnSelect && { modalStateOnSelect: { ...(state.modalStateOnSelect || { closeModal: true }) } })
                },
            });
        }
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                Neuer Kontakt
                <HStack>
                    <Button
                        data-test-id="button-cancel"
                        variant="outline"
                        onClick={() =>
                            dispatch?.({
                                type: 'setModal',
                                data: { modal: Modals.SearchContact },
                            })
                        }
                    >
                        Abbrechen
                    </Button>
                    <Button
                        data-test-id="button-save"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid spacing={4} columns={2} mb={4}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};