import React from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useModal, useDebounce, useYupValidationResolver, usePermission } from '~/hooks';
import {
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    HStack,
    SimpleGrid,
    useBoolean,
    GridItem,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { forms, fields } from '~/pages/projects/meta/data/participants.schema';
import { Form, renderField } from '~/components/Form/Form';
import { resolveFormFields, getPolicyholder, getClient } from '~/utils';
import { HasPermission } from '~/layout/HasPermission';
import log from '~/log';

export const ParticipantModal = ({ formState = {}, ...props }: any) => {
    const { onClose, state, dispatch } = useModal();
    const { createMutation, updateMutation, data, subprojectId } =
        state?.modals?.props || props || {};
    const { row: participant = {}, rowId } = state.rows || {};

    const [snippetId, setSnippetId] = React.useState(participant?.typSnippetId);
    const [hasPolicyholder, setHasPolicyholder] = useBoolean();
    const formFields = resolveFormFields(forms.participants, fields.participants);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(participant),
        ...(state.formState || {}),
    };

    const participantId = useDebounce(rowId, 200);


    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    /** Permit */
    const { canView, canEdit } = usePermission('project.participants');
    const readOnly = rowId && canView && !canEdit;
    const modalTitle = readOnly ? 'Beteiligter ansehen' : rowId ? 'Beteiligter bearbeiten' : 'Neuer Beteiligter';
    const cursor = modalTitle === 'Beteiligter ansehen' ? 'not-allowed' : 'auto';
    const pointerEvents = modalTitle === 'Beteiligter ansehen' ? 'none' : 'auto';


    React.useEffect(() => {
        /**
         * because ContactSearchModalContent can now be triggered by several Modals inside a MultiModal,
         * please specify here the modalStateOnSelect
         * */
        dispatch?.({
            type: 'setModalStateOnSelect',
            data: {
                modalStateOnSelect: {
                    activeModal: 'Participants',
                    closeModal: false,
                    props: {
                        updateMutation,
                        createMutation,
                        subprojectId,
                    },
                },
            },
        });
    }, []);

    const { typeSnippetId, contactId } = watch();

    React.useEffect(() => {
        setSnippetId(typeSnippetId);
    }, [typeSnippetId]);

    React.useEffect(() => {
        const typeIsPolicyholder = typeSnippetId?.label === 'Versicherungsnehmer';
        const policyholder = getPolicyholder(data);
        if (policyholder) {
            typeIsPolicyholder ? setHasPolicyholder.on() : setHasPolicyholder.off();
        }
        // has no policyholder + type is policyholder + contact is not selected nor are we editing
        const cond =
            !policyholder &&
            typeIsPolicyholder &&
            !state?.contacts?.values?.contactId?.contactId &&
            !Boolean(participant?.kontaktId);

        if (cond) {
            const client = getClient(data);
            if (client) {
                log.debug('typeSnippetId.Participants', { typeSnippetId, data, client, contactId });
                setValue('contactId', client?.id);
            }
        }
    }, [typeSnippetId, data, state?.contacts]);

    React.useEffect(() => {
        dispatch?.({
            type: 'formState',
            data: { ...(snippetId && { typeSnippetId: snippetId }) },
        });
    }, [snippetId]);

    const onSubmit: SubmitHandler<any> = async (values) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const fixedValues = { subprojectId: subprojectId, contacts: state?.contacts };
        log.debug('onSubmit.fixedValues', JSON.stringify(fixedValues, null, 2));
        const data = formFields.toGql(values, {}, fixedValues);
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));
        const response = participantId
            ? await updateMutation({
                variables: {
                    id: participantId,
                    version: participant?.version,
                    data,
                    forceOverwrite: true,
                },
            })
            : await createMutation({ variables: { data } });
        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    };

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };



    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {readOnly ? 'Schließen' : 'Abbrechen'}
                    </Button>
                    <HasPermission
                        resource="project.participants"
                        permission={participantId ? 'update' : 'create'}
                    >
                        <Button
                            data-test-id="button-save-participant"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmitWithOnClose)}
                        >
                            Speichern
                        </Button>
                    </HasPermission>
                </HStack>
            </ModalHeader>
            <ModalBody cursor={cursor}>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid
                        spacing={4}
                        columns={2}
                        mb={4}
                        pointerEvents={pointerEvents}
                    >
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                        {hasPolicyholder && !participantId && (
                            <GridItem colSpan={2}>
                                <Alert status="warning">
                                    <AlertIcon />
                                    Es gibt bereits einen beteiligten Versicherungsnehmner
                                </Alert>
                            </GridItem>
                        )}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};
