import { PageHeader } from '~/components/Layout/PageHeader';
import { DataTable, Card, CardHeader, CardActions, CardContent, PlusButton } from '@ucc/react/ui';
import { useMasterData, MasterDataType } from '~/pages/master-data/hooks/useMasterData';
import { HasPermission } from '~/layout/HasPermission';

export function MasterData({ title }: { title: MasterDataType }) {
    const { plusButtonProps, resource, ...dataTableProps } = useMasterData(title);

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
            </PageHeader>
            <Card boxShadow="none">
                <CardHeader>
                    <HasPermission resource={resource} permission="create">
                        <CardActions>
                            <PlusButton {...plusButtonProps} />
                        </CardActions>
                    </HasPermission>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </>
    );
}
