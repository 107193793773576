import React from 'react';
import { useInterpret, useActor } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import {
    createPageMachine,
    PageMachine,
    PageMachineContext,
    context,
} from './machines/pageMachine';

export interface PageContext {
    pageService: InterpreterFrom<PageMachine>;
    pageContext?: PageMachineContext;
    setBranchId?: (branchId: number | undefined) => void;
}

export const PageContext = React.createContext<PageContext>({
    pageService: {} as InterpreterFrom<PageMachine>,
    pageContext: context,
});

export const PageProvider = ({ children }: React.PropsWithChildren) => {
    const pageHeaderRef = React.useRef<any>();
    const pageActionsRef = React.useRef<any>();
    const pageService = useInterpret(createPageMachine, { context: { pageHeaderRef, pageActionsRef, ...context } });
    const [pageState] = useActor(pageService);
    const pageContext = pageState.context;

    const setBranchId = (branchId: number | undefined) =>
        pageService.send({ type: 'SET_BRANCH_ID', branchId });

    return (
        <PageContext.Provider
            value={{
                pageContext,
                pageService,
                setBranchId,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};
