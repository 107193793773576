import React from 'react';
import { isEqual } from 'lodash'
import { useOutletContext } from 'react-router-dom';
import { SimpleGrid, GridItem, VStack, } from '@chakra-ui/react';
import { useModal } from '~/hooks/useModal';
import * as Properties from '~/pages/projects/components/Properties';
import { Correspondence } from './Correspondence';
import { ResidentialUnits } from './ResidentialUnits';
import { ContactSearchModalContent } from './ContactSearchModalContent';
import { CreateContactModalContent } from './CreateContactModalContent'
import { ParticipantModal } from './ParticipantModal'
import { Participants } from './Participants';
import { Projekt } from '~/gql/ucpw/graphql';
import { combineReducers, contactReducer, formStateReducer, modalReducer, rowReducer } from '~/reducers';
import { MultiModal } from '~/components/MultiModal';
import { CorrespondenceModal } from './CorrespondenceModal';
import { ResidentialUnitsModalContent } from './ResidentialUnitsModal';
import { TaskModal } from './Task/TaskModal';
import { CreateEventModalContent, ProjectSearchModalContent } from './Calendar/EventModal';
import { ContactModal } from '~/pages/master-data/hooks/useContacts';
import { Tasks } from './Tasks';
import log from '~/log'


export function ProjectDetails() {
    const { subproject, title, ...outletContext }: any = useOutletContext()
    log.debug(`ProjectDetails.${title}`, { title, subproject, ...outletContext })
    const project = (subproject?.projekt || {}) as Projekt
    const properties = { project, subproject }
    const subprojectId = subproject?.id
    const projectId = project?.id


    const { dispatch, state } = useModal({
        defaultState: { modals: { activeModal: 'CorrespondenceModal' } },
        dependencies: [subprojectId, subproject],
        component: <MultiModal>
            <CorrespondenceModal id="CorrespondenceModal" subprojectId={subprojectId} />
            <TaskModal id="TaskModal" subprojectId={subprojectId} subproject={subproject} />
            <CreateEventModalContent id="CreateEvent" />
            <ProjectSearchModalContent id="ProjectSearch" />
            <ResidentialUnitsModalContent id="ResidentialUnits" subprojectId={subprojectId} />
            <ContactSearchModalContent id="SearchContact" />
            <CreateContactModalContent id="CreateContact" />
            <ContactModal id="Contact" />
            <ParticipantModal id="Participants" />
        </MultiModal>,
        reducer: combineReducers({
            modals: modalReducer('CorrespondenceModal'),
            contacts: contactReducer('SearchContact'),
            formState: formStateReducer,
            row: rowReducer,
        }),
    });

    React.useEffect(() => {
        const defaultAddress = {
            street: project?.strasse,
            zipCode: project?.plz,
            location: project?.ort,
        }
        !isEqual(defaultAddress, state?.defaultAddress) && dispatch?.({ type: 'setDefaultAddress', data: { defaultAddress } });
    }, [project, state?.defaultAddress])


    return <SimpleGrid columns={3} spacing={5} p={4} w="full">
        <GridItem colSpan={{ base: 3, md: 1 }}>
            <VStack spacing={6}>
                <Properties.ProjectProperties {...properties} />
                <Properties.ClientProperties {...properties} />
                <Properties.InsuranceProperties {...properties} />
                <Properties.ObjectProperties {...properties} />
            </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 3, md: 2 }}>
            <VStack spacing={6}>
                <Correspondence
                    projectId={project?.id}
                    subprojectId={subprojectId}
                    branchId={project?.niederlassungId}
                    disableScheduleButton={[600, 700].includes(project?.niederlassung?.nummer as number)}
                />
                <Tasks subprojectId={subprojectId} />
                <ResidentialUnits subprojectId={subprojectId} projectId={projectId} />
                <Participants subprojectId={subprojectId} />
            </VStack>
        </GridItem>
    </SimpleGrid>;
}
