import React from 'react';
import { withRenderers } from '~/utils/withRenderers';
import { table } from '~/pages/projects/meta/data/emergencies.schema';
import { useMutation, useQuery } from '@apollo/client';
import { ListNotdienstprojektDocument } from '~/gql/ucpw/graphql';
import { useDialog } from '~/hooks/useDialog';

export const useEmergencyProjects = () => {
    const { onOpen } = useDialog()
    const { data, loading } = useQuery(ListNotdienstprojektDocument, { context: { clientName: 'ucpw' }, variables: { limit: 500, filter: {} } })
    const emergencyProjects = data?.items?.items || []

    const controls = [
        {
            title: 'Projekt zuordnen',
            props: {
                onClick: (row: any) => {
                    onOpen?.('selectProject', row.original)
                }
            },
        },
    ]



    const columns = React.useMemo(() => withRenderers(table.columns, controls), [controls]);


    return {
        data: emergencyProjects,
        loading,
        columns,
    };
};
