export const tableColumnMapper =
    (columns: any, overrides = {}) =>
    (config: string | ({ key: string } & any)) => {
        const { key, ...column } = typeof config === 'string' ? { key: config } : config;
        const accessor = columns?.[key]?.accessor || key;
        return {
            id: accessor,
            ...(columns[key] || {}),
            ...column,
            ...overrides,
        };
    };
