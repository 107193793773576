import React from 'react';
import { Props } from 'react-select';
import { Control, FieldValues, useController } from 'react-hook-form';
import { useQuery, gql } from '@apollo/client';
import { StyledSelect } from './StyledSelect';
import log from '~/log';

type Option = { value: any; label: string; key?: string };

type SnippetSelectProps = Props & {
    limit?: number;
    category?: string;
    property?: string;
    control: Control<FieldValues>;
    mapOptions?: (data: any) => Option;
    filterOptions?: (data?: any) => boolean;
    onSelect?: (data: any) => any;
};

const query = gql`
    query selectSnippet(
        $offset: Int
        $limit: Int
        $orderBy: [SnippetOrderByInput]
        $filter: SnippetFilterInput
    ) {
        items: snippet(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
            items {
                value: id
                label: name
                key: kuerzel
            }
            error {
                code
                data
                message
            }
        }
    }
`;

export const SnippetSelect = ({
    control,
    filterOptions = () => true,
    mapOptions = (item) => ({ value: item?.value, label: item?.label, key: item?.key }),
    onSelect,
    ...props
}: SnippetSelectProps) => {
    const {
        field: { ref, value, ...inputProps }, // eslint-disable-line @typescript-eslint/no-unused-vars
        fieldState: { invalid, isTouched, isDirty }, // / eslint-disable-line @typescript-eslint/no-unused-vars
    } = useController({
        name: props.name || '',
        control,
        defaultValue: props.defaultValue || null,
    });


    const { limit, category, property } = props;

    const { data } = useQuery(query, {
        variables: { orderBy: [{ name: 'asc' }], limit, filter: { kategoriePrefix: category, eigenschaftPrefix: property } },
        context: { clientName: 'ucpw' },
    });

    const options = data?.items?.items?.map(mapOptions)?.filter(filterOptions) || [];

    const initialValue = value
        ? options.find((option: any) =>
            [
                option.value === value.value,
                option?.key?.length && value?.key?.length && option?.key === value?.key
                    ? true
                    : false,
                option.label === value.label,
            ].some(Boolean)
        )
        : value;

    React.useEffect(() => {
        const hasNoLabel = !value?.label;
        const hasLoadingLabel = value?.label === 'loading...';
        if (initialValue?.label && (hasNoLabel || hasLoadingLabel)) {
            inputProps?.onChange?.(initialValue);
        }
    }, [initialValue]);
    log.trace('SnippetSelect', props.name, { value, initialValue, options });

    return (
        <StyledSelect
            aria-invalid={invalid}
            value={initialValue}
            options={options}
            {...inputProps}
            onChange={(e) => {
                inputProps?.onChange?.(e);
                onSelect?.(e);
            }}
            {...props}
        />
    );
};
