export const combineReducers: any = (reducers: any) => {
    return (state: Record<string, any>, action: any) => {
        return Object.keys(reducers).reduce((acc, prop) => {
            return {
                ...acc,
                ...reducers[prop]({ [prop]: acc[prop] }, action),
            };
        }, state);
    };
};
