import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import { useModal } from '~/hooks/useModal';
import { tables } from '~/pages/projects/meta/data/task.schema';
import {
    ListTasksDocument,
    CreateTaskDocument,
    UpdateTaskDocument,
    DeleteTaskDocument,
    DeleteTaskFunctionDocument,
    CreateTaskFunctionDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';
import { useContextAndRefetchQueries, useDeleteWithConfirmation, useViewer } from '~/hooks';
import { withRenderers, mutationGuard } from '~/utils';
import { useTaskPermissions } from '../ui/Task/useTaskPermissions';

export const useTasks = ({ subprojectId }: any) => {
    const { getTaskPermission } = useTaskPermissions();
    const viewer = useViewer();
    const hasEditAllTasks = viewer.hasPermission('additional.editAllTasks', 'enabled');
    const location = useLocation();

    // ===============================================
    //  LISTING
    // ===============================================
    const limit = 500;
    const orderBy = [{ angelegt: SortOrder.Desc }];
    const variables = {
        limit,
        filter: { subprojektId: subprojectId },
        orderBy,
    };
    const { context, refetchQueries } = useContextAndRefetchQueries(ListTasksDocument, variables);
    const contextAndRefetchQueries = {
        context,
        refetchQueries: [
            ...refetchQueries,
            {
                query: ListTasksDocument,
                variables: {
                    limit: 10,
                    offset: 0,
                    orderBy: [
                        {
                            angelegt: SortOrder.Desc,
                        },
                    ],
                    filter: {
                        erledigtExists: false,
                    },
                },

                context,
            },
        ],
    };

    const {
        data,
        loading: listLoading,
        networkStatus,
    } = useQuery(ListTasksDocument, {
        variables,
        skip: !Boolean(subprojectId),
        notifyOnNetworkStatusChange: true,
        context,
    });
    const tasks: any = data?.items?.items || [];

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateTaskDocument,
        contextAndRefetchQueries
    );

    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateTaskDocument,
        contextAndRefetchQueries
    );

    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteTaskDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation: async (variables: any) =>
            await mutationGuard(deleteMutation, {
                variables,
                path: 'data.item.error',
                actions: ['addBreadcrumb', 'captureMessage'],
            }),
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    // ===============================================
    // (C)RU(D) TASK FUNCTIONS
    // ===============================================

    const [createTaskFunctionMutation, { loading: createTaskFunctionLoading }] = useMutation(
        CreateTaskFunctionDocument,
        contextAndRefetchQueries
    );

    const [deleteTaskFunctionMutation, { loading: deleteTaskFunctionLoading }] = useMutation(
        DeleteTaskFunctionDocument,
        contextAndRefetchQueries
    );

    // ===============================================
    // CONTROLS
    // ===============================================

    const setTaskModal = () => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'TaskModal',
                props: {
                    createMutation,
                    updateMutation: async (variables: any) =>
                        await mutationGuard(updateMutation, {
                            variables,
                            path: 'data.item.error',
                            actions: ['addBreadcrumb', 'captureMessage'],
                        }),
                    createTaskFunction: createTaskFunctionMutation,
                    deleteTaskFunction: deleteTaskFunctionMutation,
                },
            },
        });
    };

    const onClick = React.useCallback(
        (row: any) => {
            setTaskModal();
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen]
    );

    React.useEffect(() => {
        if (location?.state?.__typename === 'Aufgabe') {
            onClick({ original: location?.state });
        }
    }, [location, onClick]);

    const enableView = (row: any) =>
        getTaskPermission(row?.original)?.context?.menuItemTitle === 'Ansehen' && !hasEditAllTasks;

    const enableEdit = (row: any) =>
        getTaskPermission(row?.original)?.context?.menuItemTitle === 'Bearbeiten' ||
        hasEditAllTasks;

    const enableDelete = (row: any) => {
        const { context } = getTaskPermission(row?.original);
        const canUpdate = typeof context?.canUpdate === 'boolean' ? context?.canUpdate : false;
        console.log('useTaskPermissions.enableDelete', { context, canUpdate });
        return (context?.permissions?.project?.tasks?.canDelete && canUpdate) || hasEditAllTasks;
    };

    const controls = [
        {
            title: 'Ansehen',
            props: {
                onClick,
            },
            enabled: enableView,
        },
        {
            title: 'Bearbeiten',
            props: { onClick },
            enabled: enableEdit,
        },
        {
            title: 'divider',
            enabled: enableDelete,
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) =>
                    deleteWithConfirmation({
                        id: row?.original?.id,
                        version: row?.original?.version,
                        forceOverwrite: true,
                    }),
            },
            enabled: enableDelete,
        },
    ].filter(Boolean);

    // ===============================================
    // TABLE
    // ===============================================

    const columns = React.useMemo(() => withRenderers(tables.columns as any, controls), [controls]);
    const hiddenColumns = tables?.hiddenColumns;

    const loading =
        listLoading ||
        createLoading ||
        updateLoading ||
        deleteLoading ||
        deleteTaskFunctionLoading ||
        createTaskFunctionLoading ||
        networkStatus === NetworkStatus.loading;

    const subprojekt = tasks?.[0]?.subprojekt || {};

    const onCreate = React.useCallback(() => {
        setTaskModal();
        dispatch?.({ type: 'setRow', data: { row: { original: { subprojekt } } } });
        onOpen?.();
    }, [subprojekt]);

    return {
        data: tasks,
        loading,
        columns,
        hiddenColumns,
        onEdit: onClick,
        pageSize: limit,
        onClick,
        onCreate,
    };
};
