import React from 'react';
import { pick } from 'lodash';
import { addMinutes, startOfDay, endOfDay, subDays, addDays } from 'date-fns';
import { absences } from '~/pages/staffing/meta/data/absences.gql';
import { events } from '~/pages/projects/meta/data/events.gql';
import { client } from '~/apollo'

export function useOverlap(data?: any) {
    console.log('useOverlap', { data });
    const [absenceOverlap, setAbsenceOverlap] = React.useState<any[]>([]);
    const [eventOverlap, setEventOverlap] = React.useState<any[]>([]);

    const fetchAbsences = async (filter: any = {}) => {
        return await client.query({
            query: absences.listQuery,
            variables: { limit: 500, filter },
            context: { clientName: 'ucpw' },
        });
    };

    const fetchEvents = async (filter: any = {}) => {
        return await client.query({
            query: events.listQuery,
            variables: { limit: 1000, filter },
            context: { clientName: 'ucpw' },
        });
    };


    const absenceFilter = {
        mitarbeiterId: data?.employeeId,
        ...(data?.planed && {
            datumFromTo: data?.planed,
            datumUntilFrom: data?.planed
        }),
    };


    const eventFilter = {
        mitarbeiterIdSachbearbeiter: data?.employeeId,
        ...(data?.planed && {
            geplantFrom: startOfDay(subDays(new Date(data?.planed), 1)),
            geplantTo: endOfDay(addDays(new Date(data?.planed), 1)),
        }),
    };

    React.useEffect(() => {
        let mounted = true;
        if (Object.values(pick(data, 'duration', 'employeeId', 'planed', 'time')).every(Boolean) && mounted) {
            /** cancel subscription in order to avoid memory leak */
            fetchAbsences(absenceFilter).then((res) => mounted ? setAbsenceOverlap(res?.data?.items?.items || []) : null);
            fetchEvents(eventFilter).then((res) => mounted ? setEventOverlap(res?.data?.items?.items || []) : null);
        }
        return () => {
            mounted = false;
        };
    }, [data]);

    const overlaps = React.useMemo(
        () =>
            eventOverlap.map((item) => {
                const planed = new Date(`${data?.planed} ${data?.time}`);
                const planedWithDuration = addMinutes(planed, data?.duration);
                const from = new Date(item.geplant);
                const until = addMinutes(from, item.dauer);
                return {
                    id: item?.id,
                    from,
                    until,
                    hasOverlap:
                        (from < planed && until > planed) ||
                        (from < planedWithDuration && until > planedWithDuration) ||
                        (from.getTime() === planed.getTime() &&
                            until.getTime() === planedWithDuration?.getTime() &&
                            !(data?.id === item?.id)),
                };
            }),
        [eventOverlap, data]
    );

    return {
        absenceOverlap,
        hasAbsenceOverlap: Boolean(absenceOverlap.length),
        eventOverlap,
        hasEventOverlap: overlaps?.some((item) => item?.hasOverlap),
        overlaps,
        has: overlaps?.some((item) => item?.hasOverlap),
        data,
        absenceFilter,
        eventFilter,
    };
}
