import { format } from 'date-fns';
import { join, tableColumnMapper } from '~/utils';
import {selects} from '~/meta/data/selects.gql';

// Tables
const columns = {
  operationalReports: {
    __graphqlType: 'Einsatzbericht',
    id: { header: 'Id', accessor: 'id' },
    date: { header: 'Datum', accessor: 'datum', cell: (info:any) => (info.getValue() ? format(new Date(info.getValue()), 'dd.MM.yyyy') : null) },
    description: {
      header: 'Beschreibung',
      accessor: 'einsatzberichtDetail',
      renderer: { name: 'OperationalReportDescription' },
    },
    employee: { header: 'Mitarbeiter', accessor: 'einsatzberichtSachbearbeiter', renderer: { name:'Leckorter' }},
    createdBy: { header: 'Angelegt von', accessor: 'urheber', renderer: { name:'Name' } },
    controls: { accessor: 'controls', width: 20, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  operationalReports: {
    columns: ['id', 'date', 'description', 'employee', 'createdBy', 'controls'].map(tableColumnMapper(columns.operationalReports)),
    hiddenColumns: ['id'],
  },
};

// Forms

const colSpans = {
  measuresTaken: { sm: 6, md: 6, lg: 3, xl: 2 },
  causeOfDamage: { sm: 6, md: 6, lg: 3, xl: 2 },
  reportMasterData: { sm: 6, md: 6, lg: 6, xl: 2 },
  erstortungTyp: { sm: 6, md: 6, lg: 6, xl: 4 },
};

export const forms = {
  reportMasterData: [
    {
      path: 'erstortungTyp',
      ui: {
        colSpan: colSpans.erstortungTyp,
        component: 'Radiobox',
        props: {
          options: [
            { name: 'erstortungTyp', label: 'Erstortung' },
            { name: 'nachortungTyp', label: 'Nachortung' },
          ],
        },
      },
    },
    { path: 'notdiensteinsatzTyp', ui: { label: 'Notdiensteinsatz', colSpan: colSpans.reportMasterData, component: 'CheckboxControl', hideLabel: true } },
    { path: 'datum', required: true, ui: { label: 'Datum Ortstermin', colSpan: colSpans.reportMasterData, component: 'DatePicker' } },
    { path: 'kontaktId', ui: { label: 'Ansprechpartner vor Ort', colSpan: colSpans.reportMasterData, component: 'ContactInput' } },
    {
      path: 'sachbearbeiterIds',
      required: true,
      decorators: {
        form: [
          (value:any) => {
          console.log('onSub.form.VALUE', value)
          return value;
          },
        ],
        api: [
          (value = {} as any) => {
            console.log('onSub.api.VALUE', value)
            return Array.isArray(value) ? value.map((item:any) => item.value) : [];
          },
        ],
      },
      ui: {
        label: 'Leckorter',
        colSpan: colSpans.reportMasterData,
        component: 'QuerySelect',
        props: {
          isMulti: true,
          query: selects.employees,
          variables:{ filter: { mitarbeiterFunktion: { funktion: { bezeichnungPrefix:'Thermographie'}}}},
          mapOptions: (item:any) => ({
            value: item?.value,
            label: join([item?.name, item?.vorname]),
          }),
        },
      },
    },
    {
      path: 'anfahrt',
      ui: { label: 'Anfahrt', component: 'TimeRange', colSpan: 6},
    },
    {
      path: 'einsatz',
      ui: { label: 'Einsatz', component: 'TimeRange', colSpan: 6},
    },
    {
      path: 'abfahrt',
      ui: { label: 'Abfahrt', component: 'TimeRange', colSpan: 6},
    },
  ],
  causeOfDamage: [
    { path: 'innerhalb_schadenursache', ui: { label: 'Schaden innerhalb', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true } },
    { path: 'ausserhalb_schadenursache', ui: { label: 'Schaden ausserhalb', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true } },
    { path: 'frostschaden_schadenursache', ui: { label: 'Frostschaden', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true } },
    { path: 'kaltwasser_schadenursache', ui: { label: 'Kaltwasser', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true } },
    { path: 'warmwasser_schadenursache', ui: { label: 'Warmwasser', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true } },
    {
      path: 'mischwasser_schadenursache',
      ui: { label: 'Abwasser / Mischwasser', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true },
    },
    { path: 'heizung_schadenursache', ui: { label: 'Heizung', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true } },
    {
      path: 'verfugung_schadenursache',
      ui: { label: 'Verfugung / Abdichtung', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true },
    },
    { path: 'sonstiges_schadenursache', ui: { label: 'Sonstiges', colSpan: colSpans.causeOfDamage, component: 'InputOnDemand' } },
    {
      path: 'einfamilienhaus_schadenursache',
      ui: { label: 'Einfamilienhaus', colSpan: colSpans.causeOfDamage, component: 'CheckboxControl', hideLabel: true },
    },
    {
      path: 'mehrfamilienhaus_schadenursache',
      ui: { label: 'Mehrfamilienhaus', colSpan: colSpans.causeOfDamage, component: 'InputOnDemand', props: { type: 'number' } },
    },
    { path: 'gewerbe_schadenursache', ui: { label: 'Gewerbe', colSpan: colSpans.causeOfDamage, component: 'InputOnDemand' } },
  ],
  workReport: [{ path: 'beschreibung_einsatzbericht', ui: { label: 'Beschreibung', hideLabel: true, colSpan: 2, component: 'RichTextEditor' } }],
  measuresTaken: [
    { path: 'druckpruefung_massnahme', ui: { label: 'Druckprüfung', hideLabel: true, colSpan: 6, component: 'PressureTest' } },
    {
      path: 'leitungsortung_massnahme',
      ui: { label: 'Leitungsortung', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true },
    },
    { path: 'thermographie_massnahme', ui: { label: 'Thermographie', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true } },
    { path: 'endoskopie_massnahme', ui: { label: 'Endoskopie', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true } },
    { path: 'tracer_gas_massnahme', ui: { label: 'Tracer Gas in m3', colSpan: 6, component: 'InputOnDemand', props: { columns: 3 } } },
    { path: 'elektroakkustik_massnahme', ui: { label: 'Elektro-Akkustik', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true } },
    { path: 'rohrkamera_massnahme', ui: { label: 'Rohrkamera', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true } },
    {
      path: 'farbproben_luminat_massnahme',
      ui: { label: 'Farbproben Luminat', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true },
    },
    { path: 'hd_mini_kamera_massnahme', ui: { label: 'HD-Mini-Kamera', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true } },
    {
      path: 'digitale_fotodokumentation_massnahme',
      ui: { label: 'Digitale Fotodokumentation', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true },
    },
    {
      path: 'fugenundichtigkeit_massnahme',
      ui: { label: 'Fugenundichtigkeit', colSpan: colSpans.measuresTaken, component: 'CheckboxControl', hideLabel: true },
    },
    { path: 'sonstiges_massnahme', ui: { label: 'Sonstiges', colSpan: colSpans.measuresTaken, component: 'InputOnDemand' } },
    { path: 'geboschelle_massnahme', ui: { label: 'Geboschelle Stk.', colSpan: colSpans.measuresTaken, component: 'InputOnDemand' } },
  ],
  evidence: [
    { path: 'wasser_nachbargebaeude_fremdverschulden', ui: { label: 'Wasser vom Nachbargebäude', colSpan: 2, component: 'CheckboxControl', hideLabel: true } },
    { path: 'handwerkerfehler_fremdverschulden', ui: { label: 'Handwerkerfehler', colSpan: 2, component: 'CheckboxControl', hideLabel: true } },
    { path: 'anwendungsfehler_fremdverschulden', ui: { label: 'Anwendungsfehler', colSpan: 2, component: 'CheckboxControl', hideLabel: true } },
    { path: 'gewaehrleistung_fremdverschulden', ui: { label: 'Gewährleistung', colSpan: 2, component: 'CheckboxControl', hideLabel: true } },
  ],
  customerInformation: [
    {
      path: 'leckage',
      ui: {
        colSpan: 6,
        component: 'Dependants',
        props: {
          options: [
            { name: 'leckage_freigelegt', label: 'Leckage freigelegt', parent: true },
            { name: 'leckage_eingegrenzt', label: 'Leckage eingegrenzt' },
          ],
        },
      },
    },
    {
      path: 'fliesenanzahl_sum',
      ui: { label: 'Fliesen zerstörungsfrei entfernt (Anzahl)', colSpan: 3, component: 'InputOnDemand', props: { type: 'number' } },
    },
    { path: 'ersatzfliesen', ui: { label: 'Ausreichend Ersatzfliesen vorhanden', colSpan: 3, component: 'CheckboxControl', hideLabel: true } },
    { path: 'boden_wand', ui: { label: 'Boden / Wand durfte geöffnet werden', colSpan: 3, component: 'CheckboxControl', hideLabel: true } },
  ],
  followUpActions: [
    {
      path: 'technische_trocknung',
      ui: { label: 'Technische Trocknung erforderlich', colSpan: { sm: 6, xl: 3, '2xl': 3 }, component: 'CheckboxControl', hideLabel: true },
    },
    {
      path: 'gesamtschadenhoehe_estimated',
      ui: { label: 'Geschätzte Gesamtschadenhöhe > 5000€', colSpan: { sm: 6, xl: 3, '2xl': 3 }, component: 'CheckboxControl', hideLabel: true },
    },
    {
      path: 'folgemassnahmen_notwendig',
      ui:({isActive}:any = {}) => ({ label: 'Folgemaßnahmen notwendig', colSpan: { sm: 6, xl: 6, '2xl': 3 }, component: 'CheckboxControl', props: { defaultChecked: isActive }, hideLabel: true }),
    },
    {
      path: 'ag_anwesenheit',
      ui: { label: 'AG war bei der Durchführung anwesend', colSpan: { sm: 6, xl: 6, '2xl': 3 }, component: 'CheckboxControl',  hideLabel: true },
    },
    {
      path: 'weitere_arbeiten',
      ui: (props: any) => {
        return {
          label: 'in Klärung', // former 'Weitere Arbeiten erwünscht'
          hideLabel: true,
          colSpan: { sm: 6, xl: 6, '2xl': 6 },
          component: 'FollowUp',
          props: { isActive: Boolean(props?.isActive), ...props },
        };
      },
    },
    {
      path: 'ag_handwerker',
      ui: (props: any) => {
        return {
          label: 'AG hat eigene Handwerker',
          hideLabel: true,
          colSpan: { sm: 6, xl: 6, '2xl': 6 },
          component: 'FollowUp',
          props: { isActive: Boolean(props?.isActive), ...props },
        };
      },
    },
   
  ],
  photoDocumentation: [{ path: 'fotodokumentation', ui: { hideLabel: true, colSpan: 2, component: 'PhotoDocumentation' } }],
  checklist: [
    { path: 'agb_checkliste', ui: { label: 'AGBs übergeben', colSpan: 3, component: 'CheckboxControl', hideLabel: true } },
    { path: 'infoblatt_checkliste', ui: { label: 'Infoblatt übergeben', colSpan: 3, component: 'CheckboxControl', hideLabel: true } },
    { path: 'baustelle_checkliste', ui: { label: 'Baustelle sauber hinterlassen', colSpan: 3, component: 'CheckboxControl', hideLabel: true } },
    { path: 'unterschrift_checkliste', ui: { label: 'Unterschrift des Kunden eingeholt', colSpan: 3, component: 'CheckboxControl', hideLabel: true } },
  ],
  meterReadings: [{ path: 'zaehlerstaende', ui: { hideLabel: true, colSpan: 2, component: 'MeterReadings' } }],
};

const resolveFields = (form:any) =>
  form.reduce(
    (acc:any, field:any) => ({
      ...acc,
      [field?.path]: { api: { path: field?.path, required: field?.required } },
    }),
    {}
  );

export const categories = {
  reportMasterData: 'Berichtsstammdaten',
  causeOfDamage: 'Schadenursache',
  workReport: 'Arbeitsbericht',
  measuresTaken: 'Durchgeführte Maßnahmen',
  evidence: 'Hinweise auf Fremdverschulden',
  customerInformation: 'Kundeninformation',
  followUpActions: 'Folgemaßnahmen',
  photoDocumentation: 'Fotodokumentation des Schadens',
  checklist: 'Checkliste',
  meterReadings: 'Zählerstände',
};

const documentExclude = ['datum', 'kontaktId', 'mitarbeiterIdSachbearbeiter'];

export const document:any = Object.entries(forms)?.reduce((acc: any, [property, value]:any) => {
  const data:any =[{
    label: categories[property as keyof typeof categories],
    items: value?.reduce((acc:any, { path: name, ui }:any) => {
      if (documentExclude.includes(name)) {
        return acc;
      }

      switch (ui.component) {
        case 'Radiobox': {
          return [
            ...acc,
            ...ui.props.options?.map(({ label, name }:any) => ({ label, name: name.replace(/[A-Z]/g, '_$&').toLowerCase(), key: name, isCheckbox: true })),
          ];
        }
        case 'Dependants': {
          return [...acc, ...ui?.props?.options];
        }
        case 'FollowUp': {
          const [klaerung] = ui?.props?.clarification?.name?.split('_').reverse();
          return [
            ...acc,
            { name, label: ui.label },
            { name: ui?.props?.clarification?.name, label: 'Klärung ' + klaerung?.charAt(0)?.toUpperCase() + klaerung.slice(1) },
          ];
        }
        case 'PressureTest': {
          return [
            ...acc,
            { label: ui.label, name: name.replace(/[A-Z]/g, '_$&').toLowerCase() },
            {
              label: 'Kaltwasser',
              name: 'kaltwasser_druckpruefung_massnahme',
              isCheckbox: true,
            },
            {
              label: 'Druckverlust Kaltwasser',
              name: 'druckverlust_kaltwasser_druckpruefung',
              isCheckbox: true,
            },
            {
              label: 'Warmwasser',
              name: 'warmwasser_druckpruefung_massnahme',
              isCheckbox: true,
            },
            {
              label: 'Druckverlust Warmwasser',
              name: 'druckverlust_warmwasser_druckpruefung',
              isCheckbox: true,
            },
            {
              label: 'Heizung',
              name: 'heizung_druckpruefung_massnahme',
              isCheckbox: true,
            },
            {
              label: 'Druckverlust Heizung',
              name: 'druckverlust_heizung_druckpruefung',
              isCheckbox: true,
            },
          ];
        }
        default:
          return [
            ...acc,
            {
              label: ui.label,
              name: name.replace(/[A-Z]/g, '_$&').toLowerCase(),
              ...(['CheckboxControl', 'FollowUp', 'InputOnDemand'].includes(ui.component) && { isCheckbox: true }),
            },
          ];
      }
    }, []),
  }]
  return [...acc,...data];
},[])


export const fields = {
  ...resolveFields(forms.reportMasterData),
  ...resolveFields(forms.causeOfDamage),
  ...resolveFields(forms.workReport),
  ...resolveFields(forms.measuresTaken),
  ...resolveFields(forms.evidence),
  ...resolveFields(forms.customerInformation),
  ...resolveFields(forms.followUpActions),
  ...resolveFields(forms.photoDocumentation),
  ...resolveFields(forms.checklist),
  ...resolveFields(forms.meterReadings),
};
