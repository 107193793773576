import { Props } from 'react-select';
import { Control, FieldValues, useController } from 'react-hook-form';
import { StyledSelect } from './StyledSelect';

type Option = { value: any; label: string };

// https://react-hook-form.com/api/usecontroller/#main
export const Select = ({
    control,
    options,
    ...props
}: Props & { control: Control<FieldValues>; options: any[] }) => {
    const {
        field: { ref, value, ...inputProps }, // eslint-disable-line @typescript-eslint/no-unused-vars
        fieldState: { invalid, isTouched, isDirty }, // eslint-disable-line @typescript-eslint/no-unused-vars
    } = useController({
        name: props?.name || '',
        control,
        defaultValue: props.defaultValue || null,
    });


    const defaultValue = value || value === 0
        ? Array.isArray(value)
            ? options.filter((option: Option) =>
                value.map(({ value }) => value).includes(option.value)
            )
            : options.find((option: Option) => option.value === value.value || option.value === value)
        : value;

    return (
        <StyledSelect
            aria-invalid={invalid}
            value={defaultValue}
            {...inputProps}
            options={options}
            {...props}
        />
    );
};
