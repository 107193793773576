import React from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { pick } from 'lodash'
import {
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    HStack,
    SimpleGrid,
    ButtonProps,
    useBoolean,
} from '@chakra-ui/react';
// Textbausteine
import {
    ListTextSnippetDocument,
    CreateTextSnippetDocument,
    UpdateTextSnippetDocument,
    DeleteTextSnippetDocument,
    SortOrder,
    GetTradeDocument,
} from '~/gql/ucpw/graphql';
import {
    fields as textSnippetsFields,
    forms as textSnippetsForms,
    tables as textSnippetsTables,
} from '~/pages/master-data/meta/data/textSnippets.schema';
// Gewerk
import {
    ListTradeDocument,
    CreateTradeDocument,
    UpdateTradeDocument,
    DeleteTradeDocument,
} from '~/gql/ucpw/graphql';
import {
    fields as tradeFields,
    forms as tradeForms,
    tables as tradeTables,
} from '~/pages/master-data/meta/data/trade.schema';
// Projekttyp
import {
    ListProjectTypeDocument,
    CreateProjectTypeDocument,
    UpdateProjectTypeDocument,
    DeleteProjectTypeDocument,
} from '~/gql/ucpw/graphql';
import {
    fields as projectTypeFields,
    forms as projectTypeForms,
    tables as projectTypeTables,
} from '~/pages/master-data/meta/data/projectType.schema';
// Gerätetyp
import {
    ListDeviceTypeDocument,
    CreateDeviceTypeDocument,
    UpdateDeviceTypeDocument,
    DeleteDeviceTypeDocument,
} from '~/gql/ucpw/graphql';
import {
    fields as deviceTypeFields,
    forms as deviceTypeForms,
    tables as deviceTypeTables,
} from '~/pages/master-data/meta/data/deviceType.schema';
// Funktion
import {
    ListFunctionsDocument,
    CreateFunctionDocument,
    UpdateFunctionDocument,
    DeleteFunctionDocument,
} from '~/gql/ucpw/graphql';
import {
    fields as functionFields,
    forms as functionForms,
    tables as functionTables,
} from '~/pages/master-data/meta/data/function.schema';

import { resolveFormFields, withRenderers } from '~/utils';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useDataLoader } from '~/hooks/useDataLoader';
import { useModal } from '~/hooks/useModal';
import { Form, renderField } from '~/components/Form/Form';
import { useDebounce } from '~/hooks/useDebounce';
import { usePermission, Resource } from '~/hooks/usePermission';
import { useFetchDataWithFilter } from '~/hooks';
import { client } from '~/apollo'
import log from '~/log';


const masterData = {
    Textbausteine: {
        resource: 'masterData.textSnippets' as Resource,
        plusButtonProps: {
            children: 'Neuer Textbaustein',
            'data-test-id': 'button-new-text-snippet',
        },
        fields: textSnippetsFields.textSnippets,
        forms: textSnippetsForms.textSnippets,
        tables: textSnippetsTables.textSnippets,
        list: ListTextSnippetDocument,
        create: CreateTextSnippetDocument,
        update: UpdateTextSnippetDocument,
        delete: DeleteTextSnippetDocument,
        orderBy: [{ gruppe: SortOrder.Asc }],
        heading: (id: number) => (id ? 'Textbaustein bearbeiten' : 'Neuer Textbaustein'),
    },
    Gewerk: {
        resource: 'masterData.trade' as Resource,
        plusButtonProps: {
            children: 'Neues Gewerk hinzufügen',
            'data-test-id': 'button-new-trade',
        },
        fields: tradeFields.trade,
        forms: tradeForms.trade,
        tables: tradeTables.trade,
        list: ListTradeDocument,
        create: CreateTradeDocument,
        update: UpdateTradeDocument,
        delete: DeleteTradeDocument,
        orderBy: [{ name: SortOrder.Asc }],
        inputFormatter: (data: any) => ({ gewerkProjekttyp: data?.gewerkProjekttyp || [], data: { notdienstFaehig: data?.notdienstFaehig || false, ...pick(data, 'publicId', 'name') } }),
        heading: (id: number) => (id ? 'Gewerk bearbeiten' : 'Neues Gewerk'),
    },
    Projekttyp: {
        resource: 'masterData.projectType' as Resource,
        plusButtonProps: {
            children: 'Neuen Projekttyp hinzufügen',
            'data-test-id': 'button-new-project-type',
        },
        fields: projectTypeFields.projectType,
        forms: projectTypeForms.projectType,
        tables: projectTypeTables.projectType,
        list: ListProjectTypeDocument,
        create: CreateProjectTypeDocument,
        update: UpdateProjectTypeDocument,
        delete: DeleteProjectTypeDocument,
        orderBy: [{ name: SortOrder.Asc }],
        heading: (id: number) => (id ? 'Projekttyp bearbeiten' : 'Neuer Projekttyp'),
        columns: 1,
    },
    Gerätetyp: {
        resource: 'masterData.deviceType' as Resource,
        plusButtonProps: {
            children: 'Neuer Gerätetyp',
            'data-test-id': 'button-new-device-type',
        },
        fields: deviceTypeFields.deviceType,
        forms: deviceTypeForms.deviceType,
        tables: deviceTypeTables.deviceType,
        list: ListDeviceTypeDocument,
        create: CreateDeviceTypeDocument,
        update: UpdateDeviceTypeDocument,
        delete: DeleteDeviceTypeDocument,
        orderBy: [{ bezeichnung: SortOrder.Asc }],
        heading: (id: number) => (id ? 'Gerätetyp bearbeiten' : 'Neuer Gerätetyp'),
    },
    'Funktion': {
        resource: 'masterData.function' as Resource,
        plusButtonProps: {
            children: 'Neue Funktion',
            'data-test-id': 'button-new-function',
        },
        fields: functionFields.function,
        forms: functionForms.function,
        tables: functionTables.function,
        list: ListFunctionsDocument,
        create: CreateFunctionDocument,
        update: UpdateFunctionDocument,
        delete: DeleteFunctionDocument,
        orderBy: [{ bezeichnung: SortOrder.Asc }],
        heading: (id: number) => (id ? 'Funktion bearbeiten' : 'Neue Funktion'),
        columns: 1,
    }
};

const MasterDataModal = (props: any) => {
    const { createMutation, updateMutation, loading: isLoading, config, type } = props;
    const { state, dispatch, onClose } = useModal();
    const { row: insurance = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(config.forms, config?.fields);
    const id = useDebounce(rowId, 200);

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(insurance),
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({ defaultValues });

    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const data = formFields.toGql(values, {}) as any;
            log.debug('onSubmit.data', JSON.stringify(data, null, 2), config?.inputFormatter?.(data));
            const response = id
                ? await updateMutation({
                    variables: {
                        id,
                        ...(config?.inputFormatter ? config?.inputFormatter?.(data) : { data })
                    },
                    ...(type === 'Gewerk' && {
                        refetchQueries: [{
                            query: GetTradeDocument,
                            context: { clientName: 'ucpw' },
                            variables: { id },
                        }]
                    })
                })
                : await createMutation({ variables: { ...(config?.inputFormatter ? config?.inputFormatter?.(data) : { data }) } });
            log.debug('onSubmit.response', JSON.stringify(response, null, 2));
        },
        [id]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {config?.heading?.(id)}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        isLoading={isLoading}
                        data-test-id="button-save-insurance"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={config?.columns || 2} mb={6}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

export type MasterDataType = keyof typeof masterData;

export const useMasterData = (type: MasterDataType) => {
    const [newItems, setNewItems] = React.useState([]);
    const [loadNewItems, setLoadNewItems] = useBoolean();
    const config = masterData[type];
    const { canEdit, canDelete } = usePermission(config.resource);

    const getPlusButtonProps = (props: ButtonProps) => ({
        plusButtonProps: { ...config?.plusButtonProps, ...props },
    });

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const [load, { data, loading: lazyLoading, refetch }] = useLazyQuery(config.list, {
        context: { clientName: 'ucpw' },
    });
    const items: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData, variables } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, pick(config, ['orderBy']));
    const contextAndRefetchQueries = useContextAndRefetchQueries(config.list, variables);

    // ===============================================
    // (C)R(U)D
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        config.create,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        config.update,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        config.delete,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading = lazyLoading || createLoading || updateLoading || deleteLoading || loadNewItems;

    React.useEffect(() => {
        let isMounted = true;
        if (type === 'Gewerk') {
            const fetchData = async () => {
                setLoadNewItems.on()
                const newItems: any = await Promise.all(items?.map(async ({ id }: any) => {
                    const { data } = await client.query({
                        query: GetTradeDocument,
                        context: { clientName: 'ucpw' },
                        variables: { id },
                    })
                    const item: any = data?.item?.item || {}
                    const gewerkProjekttyp = [...(item?.gewerkProjekttyp || [])]

                    return {
                        ...item,
                        gewerkProjekttyp: gewerkProjekttyp?.sort((a: any, b: any) => {
                            const nameA = a?.projekttyp?.name.toLowerCase();
                            const nameB = b?.projekttyp?.name.toLowerCase();
                            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                        })
                    }
                }))

                if (isMounted && newItems?.length) {
                    setNewItems(newItems)
                    setLoadNewItems.off()
                }
            }

            fetchData().catch(console.error);
        }
        return () => {
            isMounted = false;
        }
    }, [type, items])

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal(
        <MasterDataModal {...{ createMutation, updateMutation, refetch, loading, config, type }} />
    );

    // ===============================================
    // CONTROLS
    // ===============================================

    const onClick = React.useCallback(
        (row: any) => {
            if (!canEdit) return;
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen, canEdit]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: {
                onClick,
            },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(() => withRenderers(config.tables.columns, controls), [controls]);
    const hiddenColumns = config.tables.hiddenColumns;

    const onCreate = () => {
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    };

    return {
        resource: config.resource,
        data: newItems.length ? newItems : items,
        pageCount,
        hiddenColumns,
        columns,
        loading,
        fetchData: fetchDataWithFilters,
        onClick,
        ...getPlusButtonProps({ onClick: onCreate }),
    };
};
