import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { BsSearch } from 'react-icons/bs';
import {
    ModalContent,
    ModalHeader,
    ModalBody,
    HStack,
    InputGroup,
    InputLeftElement,
    Box,
    Input,
} from '@chakra-ui/react';
import { DataTable, PlusButton } from '@ucc/react/ui';
import { SearchContactDocument } from '~/gql/ucpw/graphql';
import { tables } from '~/pages/master-data/meta/data/contacts.schema';
import { useModal, useDebounce, usePermission } from '~/hooks';
import { withRenderers } from '~/utils';
import { HasPermission } from '~/layout/HasPermission';

export const Modals = {
    SearchContact: 'SearchContact',
    CreateContact: 'CreateContact',
};

export const ContactSearchModalContent = (props: { id: string; modalStateOnSelect?: any }) => {
    const { canView } = usePermission('masterData.contacts')
    const { dispatch, state } = useModal();

    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
    const [searchContact, { data, loading }] = useLazyQuery(SearchContactDocument, {
        context: { clientName: 'ucpw' },
    });

    const columns = React.useMemo(() => withRenderers(tables.contactSearch.columns), []);
    const hiddenColumns = tables.contactSearch.hiddenColumns;

    React.useEffect(() => {
        if (debouncedSearchTerm.length) {
            searchContact({
                variables: { limit: 250, searchTerm: debouncedSearchTerm },
            });
        }
    }, [debouncedSearchTerm]);

    const items = debouncedSearchTerm.length ? data?.app?.kontaktSearch?.items ?? [] : [];

    return (
        <ModalContent rounded="none" maxWidth="container.xl">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                p={5}
                borderColor="gray.200"
            >
                Kontakt auswählen
                <HStack>
                    <InputGroup width={400} >
                        <InputLeftElement pointerEvents="none" color="gray.400">
                            <BsSearch />
                        </InputLeftElement>
                        <Input
                            type="search"
                            placeholder={canView ? 'Suche' : 'Keine Berechtigung'}
                            value={searchTerm}
                            isDisabled={!canView}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setSearchTerm(e.target.value)
                            }
                        />
                    </InputGroup>
                    <Box>
                        <HasPermission resource="masterData.contacts" permission="create">
                            <PlusButton
                                onClick={() => {
                                    dispatch?.({
                                        type: 'setModal',
                                        data: { modal: Modals.CreateContact },
                                    });
                                }}
                            >
                                Neuer Kontakt
                            </PlusButton>
                        </HasPermission>
                    </Box>
                </HStack>
            </ModalHeader>
            <ModalBody p={0}>
                <DataTable<any>
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    loading={loading}
                    data={items}
                    onClick={(row) =>
                        dispatch?.({
                            type: 'selectContact',
                            data: {
                                contact: row?.original,
                                modalStateOnSelect:
                                    state?.modalStateOnSelect || props.modalStateOnSelect,
                            },
                        })
                    }
                />
            </ModalBody>
        </ModalContent>
    );
};
