import React, { useContext } from 'react';
import { DialogContext } from '~/contexts/DialogContext';
import { DialogMachineContext } from '~/contexts/machines/dialogMachine';

export const useDialog = (): DialogContext => {
    return useContext(DialogContext);
};

export const useConfirmationDialog = (config: DialogMachineContext = {}) => {
    const context: DialogContext = useContext(DialogContext);
    if (!context) {
        throw new Error('useConfirmationDialog must be used within a DialogProvider');
    }

    const { dialogService } = context;

    React.useEffect(() => {
        dialogService?.send({ type: 'SET_CONFIG', ...config });
    }, []);

    return context;
};
