import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react';
import { Sidebar } from './Sidebar';
import { ToggleButton } from './ToggleButton';

export const NavBar = () => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    return (
        <Box width="full" py="4" px={{ base: '4', md: '8' }} w="full" bg="bg-accent">
            <Flex justify="space-between">
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    isFullHeight
                    preserveScrollBarGap
                    trapFocus={false}

                >
                    <DrawerOverlay />
                    <DrawerContent w="full" position="relative">
                        <Box
                            className="close-box"
                            p={1}
                            position="absolute"
                            transform="translateX(100%)"
                            right={0}
                            opacity={0.75}
                            bg="bg-accent"
                        >
                            <ToggleButton
                                isOpen={isOpen}
                                aria-label="Open Menu"
                                onClick={onToggle}
                            />
                        </Box>
                        <Sidebar />
                    </DrawerContent>
                </Drawer>
                {!isOpen && (
                    <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
                )}
            </Flex>
        </Box>
    );
};
