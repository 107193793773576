export const modalReducer =
    (startModal: string) =>
    (state: any, action: any = {}) => {
        const { type, data = {} } = action;

        switch (type) {
            case 'setModal': {
                return {
                    ...state,
                    modals: { activeModal: data.modal || state.modal, props: data?.props },
                };
            }
            case 'resetState': {
                const resetState = { modals: { activeModal: startModal } };
                console.log('modalsReducer.resetState', resetState);
                return resetState;
            }
            default: {
                return state;
            }
        }
    };
