import gql from 'graphql-tag';

export const selects = {
    employees: gql`
        query selectEmployees(
            $offset: Int
            $limit: Int
            $orderBy: [MitarbeiterOrderByInput]
            $filter: MitarbeiterFilterInput
        ) {
            items: mitarbeiter(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: name
                    id
                    name
                    vorname
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    function: gql`
        query selectFunction(
            $offset: Int
            $limit: Int
            $orderBy: [RolleOrderByInput]
            $filter: RolleFilterInput
        ) {
            items: rolle(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: bezeichnung
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    deviceType: gql`
        query selectDeviceType(
            $offset: Int
            $limit: Int
            $orderBy: [GeraetetypOrderByInput]
            $filter: GeraetetypFilterInput
        ) {
            items: geraetetyp(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: bezeichnung
                    isWattro
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    units: gql`
        query selectUnits(
            $offset: Int
            $limit: Int
            $orderBy: [LeistungOrderByInput]
            $filter: LeistungFilterInput
        ) {
            items: leistung(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: bezeichnung
                    bezeichnung
                    einheit
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    functions: gql`
        query selectFunctions(
            $offset: Int
            $filter: FunktionFilterInput
            $orderBy: [FunktionOrderByInput]
            $limit: Int
        ) {
            items: funktion(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: bezeichnung
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    projectType: gql`
        query selectProjectType(
            $offset: Int
            $limit: Int
            $orderBy: [ProjekttypOrderByInput]
            $filter: ProjekttypFilterInput
        ) {
            items: projekttyp(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: name
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    branches: gql`
        query selectBranches(
            $offset: Int
            $limit: Int
            $orderBy: [NiederlassungOrderByInput]
            $filter: NiederlassungFilterInput
        ) {
            items: niederlassung(
                offset: $offset
                limit: $limit
                orderBy: $orderBy
                filter: $filter
            ) {
                items {
                    value: id
                    label: bezeichnung
                    nummer
                    bezeichnung
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    trade: gql`
        query selectTrade(
            $offset: Int
            $limit: Int
            $orderBy: [GewerkOrderByInput]
            $filter: GewerkFilterInput
        ) {
            items: gewerk(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
                items {
                    value: id
                    label: name
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    generalAgreements: gql`
        query selectGeneralAgreements(
            $offset: Int
            $limit: Int
            $orderBy: [RahmenvertragOrderByInput]
            $filter: RahmenvertragFilterInput
        ) {
            items: rahmenvertrag(
                offset: $offset
                limit: $limit
                orderBy: $orderBy
                filter: $filter
            ) {
                items {
                    value: id
                    label: bezeichnung
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
    insurances: gql`
        query selectInsurances(
            $offset: Int
            $limit: Int
            $orderBy: [VersicherungOrderByInput]
            $filter: VersicherungFilterInput
        ) {
            items: versicherung(
                offset: $offset
                limit: $limit
                orderBy: $orderBy
                filter: $filter
            ) {
                items {
                    value: id
                    label: bezeichnung
                }
                error {
                    code
                    data
                    message
                }
            }
        }
    `,
};
