import { assign, createMachine } from 'xstate';

export type EventsContext = {
    created?: any[];
    updated?: any[];
    deleted?: any[];
};

export const context: EventsContext = {
    created: [],
    updated: [],
    deleted: [],
};

export const eventsMachine =
    /** @xstate-layout N4IgpgJg5mDOIC5QGMCGAbMA7CqBOAdMnmKgC5gCiAbtmQOoCWZAFgMID2eJsADhzmzIwAYggCwBRlmocA1pLSYc+IiXJVaWBs3Zce-QVmEJpstGUYCA2gAYAunfuJQ-WMytYXIAB6IATADMAGwEAIwAHADsAKz+AJzxwcER-rYxwQA0IACeiCERBAAstsEZYbFF-pFhwQC+ddlK2LiEAK68uBQ0dEysnNxwhhBCouJYkmbyihgtqh1dmr26AwYCI8ZgpjIcFp5OTt5uHgLefghB-gS28YFRUUWB8ba2gY8R2XkIEWEEMS9hIoxVIJKLBQL+BpNWYqQgjTDdLQ6fr6IbrUZiCRSHYKIgw1oEeFgRHLFGDPjozbbczkfYOQ5IEDHSynRnnIKhYJRSIRGIxEpFWqRT4BfxFAjxGKBQJSqIRXnxKJiqEgZqwggAd1QzBEbAASpQAIIAFUoAH1KAA1SgAOWNZvoAEljQAJM1sADyeoNAGUAAoem0AEVtbEoDNcHHcLK8bMQFV+USS8p+YKKEXTgRFCCKwV+MsVBcF8pSURVaoJWp1AFU-UGTearbb7U7Xe6vb6A8HQ+GHEcoydY6BzgmCEmUvKKsF05nsxVxUDpWlbGFSkDSuX8aoq2QRCGADKUU0W612h3Ot2e72Uf2BkM2sMRpkDmNnePcsfJydpjMRLO5fIKglUo4iTMF-GSBpGhALAOBGeBGQrfB+2jTw3wQABaLIAMwmIJUSVIpVsJMnm5epoKQwhiFIEltD6PRyWGUYUMHdD+XiMdIjlIokgeVcPhwwJUj+Hik2BV5JXiXlN2UAkFg0Ho6JWVEKSMYQWNfOMEHYzjogzXjBVsASvklAhp2lJNon8bkIlsIoZLmOEwARJYlLJNY1LADS0K0nSbO4gz+OzIIOJuMo9KE6UIluBz1R3bzWWHRAqjnWozLCSVeSCKpc2IqC6iAA */
    createMachine<EventsContext>({
        id: 'calendar',
        initial: 'wait',
        context,
        states: {
            createEvent: {
                invoke: {
                    src: 'createEvent',
                    onDone: {
                        target: 'wait',
                        actions: assign(({ created = [] }, { data }) => {
                            return {
                                created: [...created, data],
                            };
                        }),
                    },
                },
            },
            updateEvent: {
                invoke: {
                    src: 'updateEvent',
                    onDone: {
                        target: 'wait',
                        actions: assign(({ updated = [] }, { data }) => {
                            return {
                                updated: [...updated, data],
                            };
                        }),
                    },
                },
            },
            deleteEvent: {
                invoke: {
                    src: 'deleteEvent',
                    onDone: {
                        target: 'wait',
                        actions: assign(({ deleted = [] }, { data }) => {
                            return {
                                deleted: [...deleted, data],
                            };
                        }),
                    },
                },
            },
            wait: {
                on: {
                    CREATE_EVENT: 'createEvent',
                    UPDATE_EVENT: 'updateEvent',
                    DELETE_EVENT: 'deleteEvent',
                },
            },
        },
    });
