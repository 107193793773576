import React from 'react';
import { FiUser } from 'react-icons/fi';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Input, InputProps, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { useModal } from '~/hooks/useModal';
import { GetContactDocument, Kontakt } from '~/gql/ucpw/graphql';
import { CloseIcon } from '@chakra-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { join } from '~/utils';

type Props = InputProps & { control: Control<FieldValues> } & { modalStateOnSelect?: { activeModal?: string; closeModal?: boolean } };

export const ContactInput = ({ control, modalStateOnSelect, ...props }: Props) => {
    const { onOpen, onClose, state, dispatch } = useModal();
    const {
        field: { ref, ...inputProps }, // eslint-disable-line @typescript-eslint/no-unused-vars
        fieldState: { invalid, isTouched, isDirty }, // eslint-disable-line @typescript-eslint/no-unused-vars
    } = useController({
        name: props?.name || '',
        control,
        defaultValue: props.defaultValue || null,
    });
    const { name, onChange, value } = inputProps;
    // eslint-disable-next-line prefer-const
    let { contactId = '', displayName = '' } = state.contacts?.values?.[name] || {};
    // console.log(`ContactInput.${name}`, name, { value, contactId, displayName, state });
    const { closeModal } = state.modals || {};

    const [getContact, { data }] = useLazyQuery(GetContactDocument, { context: { clientName: 'ucpw' } });
    const item = (data?.item?.item || {}) as Kontakt;
    if (item) {
        displayName = value
            ? join([item?.firma1, item?.firma2, item?.name, item?.vorname])
            : '';
    }

    React.useEffect(() => {
        if (contactId) {
            onChange(contactId);
            closeModal && onClose?.();
        }
    }, [contactId, closeModal, onClose]);

    React.useEffect(() => {
        if (value) {
            getContact({ variables: { id: value } });
        } else if (value === null) {
            dispatch?.({ type: 'resetContact', data: { fieldName: name } });
        }
    }, [value]);

    return (
        <InputGroup>
            <Input
                data-test-id={name}
                fontSize="md"
                {...props}
                value={displayName}
                onChange={onChange}
                onClick={() => {
                    if (props.isDisabled) {
                        return;
                    }
                    modalStateOnSelect && dispatch?.({ type: 'modalStateOnSelect', data: { modalStateOnSelect } });
                    dispatch?.({ type: 'searchContact', data: { fieldName: name } });
                    onOpen?.();
                }}
            />

            <InputRightElement h="full" zIndex="0">
                {Boolean(displayName) && (
                    <IconButton
                        aria-label="reset contacts"
                        variant="link"
                        onClick={(e) => {
                            e.preventDefault();
                            onChange(null);
                        }}
                        icon={<CloseIcon />}
                    />
                )}
                <FiUser />
            </InputRightElement>
        </InputGroup>
    );
};
