import React from 'react';
import { Box, Spinner, Stack } from '@chakra-ui/react';
import { Outlet, useParams } from 'react-router-dom';
import { PageHeaderLink } from '~/components/Layout/PageHeader';
import { Projekttyp } from '~/gql/ucpw/graphql';
import { PageHeader } from '~/components/Layout/PageHeader';
import { useSubproject } from '../hooks/useSubproject';
import { useBrowseHistory } from '~/pages/projects/hooks/useBrowseHistory';
import log from '~/log';


export function Project() {
    //TODO: const { projectId, subprojectId = 'TODO' }: any = useParams();
    const { projectId, subprojectId = '' }: any = useParams();

    const {
        data: subproject,
        title,
        subprojectNo,
        titleIsLoading,
    } = useSubproject({
        projectId,
        subprojectId: subprojectId || projectId,
    });
    useBrowseHistory(subprojectNo, subproject);

    const activeSubprojectId = subproject?.id;
    const projectType = subproject?.projekttyp as Projekttyp;
    const projectIds = React.useMemo(
        () => ({
            projectId,
            subprojectId: subprojectId || activeSubprojectId,
            version: subproject?.version,
        }),
        [activeSubprojectId, subprojectId, subproject?.version, projectId]
    );

    const pageHeadertitle = titleIsLoading ? <Box>Projekt - <Spinner /></Box> : title;

    return <>
        <PageHeader>
            <PageHeader.Title>{pageHeadertitle}</PageHeader.Title>
            <PageHeader.Navigation>
                <Navigation {...projectIds} projectType={projectType} />
            </PageHeader.Navigation>
        </PageHeader>
        <Outlet context={{ ...projectIds, subproject, title }} />
    </>;
}

type ProjectNavigationProps = {
    projectId?: string | number;
    subprojectId?: string | number;
    projectType?: Projekttyp;
};

export function Navigation({ projectId, subprojectId, projectType }: ProjectNavigationProps) {
    const isLeckortung = projectType?.name === 'Leckortung';

    const links = React.useMemo(() => {
        const links = projectLinks({ projectId, subprojectId, projectType }).filter(
            (link) => !link.path.includes('undefined')
        );
        log.debug('ProjectNavigation.links', { projectId, subprojectId, projectType, links });
        return links;
    }, [projectId, subprojectId, projectType]);

    return (
        <Stack direction="row" data-test-id="page-header-links">
            {links.map((link) => (
                <PageHeaderLink
                    data-test-id={`link-${link.id}`}
                    end={!link.path.includes('kalkulation')}
                    key={link.path}
                    to={{ pathname: link.path }}
                >
                    {isLeckortung && link?.title === 'Einsatzberichte'
                        ? 'Leckageortungen'
                        : link.title}
                </PageHeaderLink>
            ))}
        </Stack>
    );
}

const projectLinks = ({ projectId, subprojectId, projectType }: ProjectNavigationProps) => {
    return [
        { id: `overview`, title: `Projektüberblick`, path: `/projekte/${projectId}` },
        { id: `details`, title: `Details`, path: `/projekte/${projectId}/${subprojectId}/details` },
        {
            id: `documents`,
            title: `Dokumente`,
            path: `/projekte/${projectId}/${subprojectId}/dokumente`,
        },
        {
            id: `allowance`,
            title: `Aufmaß`,
            path: `/projekte/${projectId}/${subprojectId}/aufmass`,
        },
        {
            id: `reports`,
            title: `Einsatzberichte`,
            path: `/projekte/${projectId}/${subprojectId}/einsatzberichte`,
            enabled: projectType && ['Leckortung'].includes(projectType?.name),
        },
        {
            id: `calculation`,
            title: `Kalkulation`,
            path: `/projekte/${projectId}/${subprojectId}/kalkulation`,
        },
        {
            id: `deviceUsage`,
            title: `Geräteeinsatz`,
            path: `/projekte/${projectId}/${subprojectId}/geraeteeinsatz`,
            enabled: projectType && ['Trocknung'].includes(projectType?.name),
        },
    ].filter((link) => link.enabled === undefined || link.enabled);
};
