import { format } from 'date-fns';
import { selects } from '~/meta/data/selects.gql';

export const weekdays = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];
export const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];

// Forms
export const fields = {
    absences: {
        __graphqlInput: 'AbwesenheitInput',
        employeeOriginatorId: { api: { path: 'mitarbeiterIdUrheber' } }, // TODO: field is missing on api side
        employeeId: { api: { path: 'mitarbeiterId', required: true } },
        dateFrom: {
            api: { path: 'datumFrom', required: true },
            defaultValue: format(new Date(), `yyyy-MM-dd`),
        },
        dateUntil: {
            api: { path: 'datumUntil', required: true },
            defaultValue: format(new Date(), `yyyy-MM-dd`),
        },
        typeSnippetId: { api: { path: 'typSnippetId', required: true } },
    },
};

export const forms = {
    absences: [
        {
            path: 'employeeOriginatorId',
            ui: {
                label: 'Angelegt von',
                component: 'QuerySelect',
                props: {
                    limit: 1000,
                    query: selects.employees,
                    mapOptions: (item: any) => ({
                        value: item?.value,
                        label: `${item?.name}, ${item?.vorname}`,
                    }),
                },
                include: false,
            },
        },
        {
            path: 'employeeId',
            ui: {
                label: 'Abwesenheit für',
                component: 'QuerySelect',
                props: {
                    limit: 1000,
                    query: selects.employees,
                    mapOptions: (item: any) => ({
                        value: item?.value,
                        label: `${item?.name}, ${item?.vorname}`,
                    }),
                },
            },
        },
        { path: 'dateFrom', ui: { label: 'Datum von', component: 'DatePicker' } },
        { path: 'dateUntil', ui: { label: 'Datum bis', component: 'DatePicker' } },
        {
            path: 'typeSnippetId',
            ui: {
                label: 'Typ',
                component: 'SnippetSelect',
                props: { category: 'Abwesenheit', property: 'Typ' },
            },
        },
    ],
};
