import { resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = {
  tables: [{ id: 'projectType', colSpan: 3, snippets: { create: 'Neuen Projekttyp hinzufügen' } }],
};

// ===============================================
// TABLES
// ===============================================

const columns = {
  projectType: {
    __graphqlType: 'Projekttyp',
    id: { header: 'ID', accessor: 'id' },
    name: { header: 'Name', accessor: 'name', disableSortBy: true },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  projectType: {
    columns: ['id', 'name', 'controls'].map(tableColumnMapper(columns.projectType)),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  projectType: {
    __graphqlInput: 'ProjekttypInput',
    name: { api: { path: 'name', required: true } },
  },
};

export const forms = {
  projectType: [{ path: 'name', ui: { label: 'Name' } }],
};

export const projectType = {
  columns: tables.projectType.columns,
  tableProps: { pageSize: 10 },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.projectType, fields: fields.projectType },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.projectType, fields.projectType, context),

      snippets: {
        create: 'Neuer Projekttyp',
        update: 'Projekttyp bearbeiten',
        displayName: (item: any) => item?.name,
      },
    };
  },
};
