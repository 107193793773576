import { useMutation, useQuery } from '@apollo/client';
import { GetMeasureRemarkDocument, UpdateMeasureRemarkDocument } from '~/gql/ucpw/graphql';
import { ProjectIds } from '../types';
import { usePdfRefetchQueries } from './usePdf';
import { mutationGuard } from '~/utils';

export const useMeasureRemark = ({ subprojectId }: ProjectIds & { version?: number }) => {
    const { subproject: subprojectRefetchQueries } = usePdfRefetchQueries({ subprojectId });
    const variables = { subprojectId: subprojectId as number };
    const context = { clientName: 'ucpw' };

    const { data: remarkData, loading: queryLoading } = useQuery(GetMeasureRemarkDocument, {
        variables,
        context,
    });
    const remark = (remarkData?.item?.item || {}) as any;
    const version = remark?.version;

    const refetchQueries = [
        { query: GetMeasureRemarkDocument, variables, context },
        subprojectRefetchQueries,
    ].filter(Boolean) as any[];

    const [updateMutation, { loading: updateLoading }] = useMutation(UpdateMeasureRemarkDocument, {
        context,
        refetchQueries,
    });

    const loading = queryLoading || updateLoading;

    return {
        updateRemark: async (remark: string) =>
            await mutationGuard(updateMutation, {
                variables: {
                    subprojectId: subprojectId as number,
                    remark,
                    version,
                    forceOverwrite: true,
                },
                path: 'data.item.error',
                actions: ['addBreadcrumb', 'captureMessage'],
            }),
        remark: remark?.bemerkungAufmass,
        loading,
    };
};
