import { tableColumnMapper } from '~/utils/tableColumnMapper';

// Tables
const columns = {
    __graphqlType: 'Roles',
    id: { header: 'Id', accessor: 'id' },
    name: { header: 'Name', accessor: 'name' },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
};

export const tables = {
    roles: {
        columns: ['id', 'name', 'controls'].map(tableColumnMapper(columns)),
        hiddenColumns: ['id'],
        pageSize: 50,
    },
};
