import { FiDownload } from 'react-icons/fi';
import { Button, ButtonProps } from '@chakra-ui/react';

export function DownloadButton({ children = 'Download', ...props }: ButtonProps) {
    return (
        <Button
            loadingText={typeof children === 'string' ? children : 'Download'}
            leftIcon={<FiDownload />}
            variant="outline"
            px={5}
            {...props}
        >
            {children}
        </Button>
    );
}
