import gql from 'graphql-tag';

const absenceFields = gql`
  fragment AbsenceFields on Abwesenheit {
    id
    datumFrom
    datumUntil
    mitarbeiterId
    mitarbeiter {
      id
      name
      vorname
      kennung
    }
    typSnippetId
    typSnippet {
      id
      name
      kuerzel
    }
  }
`;

export const absences = {
  createMutation: gql`
    mutation createAbsence($data: AbwesenheitInput!) {
      item: createAbwesenheit(data: $data) {
        item {
          ...AbsenceFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${absenceFields}
  `,
  updateMutation: gql`
    mutation updateAbsence($id: Int!, $data: AbwesenheitInput!) {
      item: updateAbwesenheit(id: $id, data: $data) {
        item {
          ...AbsenceFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${absenceFields}
  `,
  deleteMutation: gql`
    mutation deleteAbsence($id: Int!) {
      item: deleteAbwesenheit(id: $id) {
        item {
          ...AbsenceFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${absenceFields}
  `,
  listQuery: gql`
    query listAbsences($offset: Int, $limit: Int, $orderBy: [AbwesenheitOrderByInput], $filter: AbwesenheitFilterInput!) {
      items: abwesenheit(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          ...AbsenceFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${absenceFields}
  `,
  getQuery: gql`
    query getAbsence($id: Int!) {
      item: abwesenheitById(id: $id) {
        item {
          ...AbsenceFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${absenceFields}
  `,
};
