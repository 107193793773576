import { unflatten } from 'flat';

export function getOrderBy(args: any, defaultValue?: any[]) {
    return args?.sortBy && args?.sortBy.length
        ? args?.sortBy?.map((item: any) =>
              unflatten({
                  [item.id]: item.desc ? 'desc' : 'asc',
              })
          )
        : defaultValue;
}

export function getOrderByProps(args: any, defaultValue?: any[]) {
    const orderBy = getOrderBy(args, defaultValue);
    return { ...(orderBy && { orderBy }) };
}
