import { useController } from 'react-hook-form';
import { Input, Checkbox, FormLabel, SimpleGrid } from '@chakra-ui/react';

export const InputOnDemand = ({ control, children, type, ...props }: any) => {
    const {
        field: { value, ...inputProps },
    } = useController({
        name: props.name,
        control,
        defaultValue: null,
    });

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputProps.onChange({ checked: e.target?.checked });
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputProps.onChange({
            checked: true,
            input: e.target?.value,
        });
    };

    return (
        <>
            <FormLabel data-test-id={`field-${props.name}-checkbox`}>
                <Checkbox onChange={onCheckboxChange} isChecked={value?.checked || false}>
                    {children}
                </Checkbox>
            </FormLabel>
            <SimpleGrid columns={props.columns || 1}>
                <Input
                    data-test-id={`field-${props.name}-input`}
                    type={type}
                    value={value?.input || ''}
                    onChange={onInputChange}
                    {...(!value?.checked && { display: 'none' })}
                />
            </SimpleGrid>
        </>
    );
};
