import React from 'react';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { UpdateSubprojectWithProjectDocument, UpdateProjectDocument, } from '~/gql/ucpw/graphql';
import { SectionCard } from '~/components/SectionCard';
import { Form, renderField } from '~/components/Form/Form';
import { ContactSearchModalContent } from '~/pages/projects/ui/ContactSearchModalContent';
import { CreateContactModalContent } from '~/pages/projects/ui/CreateContactModalContent';
import { useProjectDetails } from '~/pages/projects/hooks/useProjectDetails';
import { fields, forms } from '~/pages/projects/meta/data/projects.schema';
import { useProjectParams } from '~/pages/projects/hooks/useProjectParams';
import { useYupValidationResolver } from '~/hooks/useYupValidationResolver';
import { PageHeader, Title, Actions } from '~/components/Layout/PageHeader';
import { useSnippetId, useScrollIntoViewAnchorRefs, useModal } from '~/hooks';
import { combineReducers, contactReducer, formStateReducer, modalReducer } from '~/reducers';
import { combineFields, formFieldsToGraphQl, graphQlToFormFields, resolveFormFields, mutationGuard } from '~/utils';
import { MultiModal } from '~/components/MultiModal';
import { omit, pick } from 'lodash';


export function EditProject({ title }: { title: string }) {
    const { projectId, subprojectId } = useProjectParams()
    const navigate = useNavigate();
    const location = useLocation();
    const isEditProject = location.state === 'EDIT_PROJECT';


    const form: any = [...forms.object, ...forms.projectEdit, ...forms.client, ...forms.insurance];

    const { dispatch } = useModal({
        defaultState: { modals: { activeModal: 'SearchContact' } },
        component: <MultiModal>
            <ContactSearchModalContent id="SearchContact" />
            <CreateContactModalContent id="CreateContact" />
        </MultiModal>,
        reducer: combineReducers({
            modals: modalReducer('SearchContact'),
            contacts: contactReducer('SearchContact'),
            formState: formStateReducer,
        }),
    });

    // Data preparation (for form & graphql api)
    const formToGql = formFieldsToGraphQl(form, fields.project);
    const gqlToForm = graphQlToFormFields(form, fields.project);


    function justEditProject(data: any, depencies: any) {
        const rules = data?.rules
            ? Object.entries(data.rules)?.reduce(
                (acc, [k, v]) => (depencies.includes(k) ? { ...acc, [k]: v } : { ...acc }),
                {}
            )
            : {};

        return {
            ...data,
            rules,
            fields: data?.fields?.filter(({ name }: any) => depencies.includes(name)),
        };
    }

    // Form Fields
    const formFields = {
        // object: resolveFormFields(forms.object, fields.project),
        object: isEditProject
            ? justEditProject(resolveFormFields(forms.object, fields.project), [
                'objectStreet',
                'objectZipCode',
                'objectLocation',
            ])
            : resolveFormFields(forms.object, fields.project),
        // project: resolveFormFields(forms.projectEdit, fields.project),
        project: isEditProject
            ? justEditProject(resolveFormFields(forms.projectEdit, fields.project), [
                'projectBranchId',
                'projectDamageday',
            ])
            : resolveFormFields(forms.projectEdit, fields.project),
        client: resolveFormFields(forms.client, fields.project),
        insurance: resolveFormFields(forms.insurance, fields.project),

    };

    // Default (remote) values & Validation
    const { defaultValues, rules, watchFields: watchFieldsArray } = combineFields(
        ...Object.values(formFields)
    );


    const { control, register, handleSubmit, reset, formState: { errors }, watch } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(rules)),
    });

    const watched = watch()
    const watchFields = pick(watched, ...watchFieldsArray);

    const { project, subproject, participants, loading, error } = useProjectDetails({
        projectId,
        subprojectId,
    });

    const category = isEditProject ? 'Projekt' : "Subprojekt";
    const { typSnippetId: statusSnippetId } = useSnippetId({
        name: subproject?.statusSnippet?.name as string,
        property: 'Status',
        category,
    });

    React.useEffect(() => {
        const resetValues = {
            ...defaultValues,
            ...gqlToForm({ projekt: project, subprojekt: { ...subproject, ...(subproject?.bemerkung?.raw && { bemerkung: subproject?.bemerkung?.raw }) }, beteiligte: participants }),
            ...(!isEditProject && {
                projectStatusId: { value: statusSnippetId, label: 'loading...' },

            }),
        };
        reset(resetValues);
    }, [project?.id, subproject?.id, statusSnippetId]);

    const [
        updateSubproject,
        { loading: subprojectWithProjectLoading },
    ] = useMutation(UpdateSubprojectWithProjectDocument, {
        context: { clientName: 'ucpw' },
    });

    const [updateProject, { loading: projectLoading }] = useMutation(UpdateProjectDocument, {
        context: { clientName: 'ucpw' },
    });

    const updateProjectWithRedirect = async (data: any) => {
        await mutationGuard(updateProject, {
            variables: {
                id: projectId as number,
                version: project?.version,
                forceOverwrite: true,
                data,
            },
            path: 'data.item.error',
            actions: ['addBreadcrumb', 'captureMessage'],
        })
        dispatch?.({ type: 'resetState' });
        navigate(`/projekte/${projectId}`)
    };

    const updateSubprojectWithRedirect = async (data: any) => {
        await mutationGuard(updateSubproject, {
            variables: {
                input: {
                    ...data,
                    forceOverwrite: true,
                    projektVersion: project?.version,
                    subprojektVersion: subproject?.version,
                    beteiligteVersion: subproject?.beteiligte?.reduce((acc: any, { id, version, typSnippet: { name } }: any) => ["Auftraggeber", "Versicherungsnehmer", "Eigentümer", "Vermittler", "Beauftragter Handwerker"].includes(name) ? [...acc, { id, version }] : acc, [])
                }
            },
            path: 'data.item.error',
            actions: ['addBreadcrumb', 'captureMessage'],
        })
        dispatch?.({ type: 'resetState' });
        navigate(`/projekte/${projectId}/${subprojectId}/details`);
    };



    const isLoading = subprojectWithProjectLoading || projectLoading;

    const onSubmit: SubmitHandler<any> = React.useCallback(
        async (formData) => {
            const fixedValues = { subprojectId };
            const data: any = formToGql(formData, {}, fixedValues);
            const isClearable = Object.entries({
                // subprojektBonitaetSnippetId: 'subprojectCreditStandingSnippetId',
                subprojektVersicherungId: 'subprojectInsuranceId',
                subprojektVersicherungsArtSnippetId: 'subprojectInsuranceTypeSnippetId',
                subprojektRahmenvertragId: 'subprojectGeneralAgreementId',
            })?.reduce((acc, [k, v]) => formData?.[v] === null ? ({ ...acc, [k]: 0 }) : acc, {});
            const projektSchadenTag = formData?.projectDamageday === '' ? null : formData?.projectDamageday;
            const subprojektVertragsNr = formData?.subprojectContractNumber === '' ? ' ' : formData?.subprojectContractNumber;

            isEditProject && data
                ? await updateProjectWithRedirect({
                    niederlassungId: data?.projektNiederlassungId,
                    statusSnippetId: data?.statusSnippetId,
                    schadenTag: projektSchadenTag,
                    plz: data?.objektPlz,
                    ort: data?.objektOrt,
                    strasse: data?.objektStrasse,
                })
                : await updateSubprojectWithRedirect(omit({
                    ...data,
                    projektSchadenTag,
                    subprojektVertragsNr,
                    ...isClearable,
                }, 'projektLfdNr', 'subprojektLfdNr', 'projektAnlageDatum', 'umsatzPrognose')) //FIXME: omit this inside formToGql @bjoern
        },
        [isEditProject, project?.version, subproject?.beteiligte]
    );

    const [objectRef, projectRef, insuranceRef, clientRef] = useScrollIntoViewAnchorRefs(
        'objekt',
        'projekt',
        'versicherung',
        'auftraggeber'
    );

    return <>
        <PageHeader>
            <Title>{title}</Title>
            <Actions>
                <HStack>
                    <Button
                        data-test-id={`cancel`}
                        size="sm"
                        onClick={() => navigate(`/projekte/${projectId}`)}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        data-test-id={`save`}
                        colorScheme="blue"
                        size="sm"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                    >
                        Speichern
                    </Button>
                </HStack>
            </Actions>
        </PageHeader>
        <Container maxW="container.lg" p={3}>
            <Form Form onSubmit={handleSubmit(onSubmit)} >
                <VStack alignItems="center" spacing={14}>
                    <SectionCard ref={objectRef} title="Objekt">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.object.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard ref={projectRef} title="Projekt">
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.project.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                        context: { category }
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard
                        ref={clientRef}
                        title="Auftraggeber"
                        {...(isEditProject && { display: 'none' })}
                    >
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.client.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard
                        ref={insuranceRef}
                        title="Versicherung"
                        {...(isEditProject && { display: 'none' })}
                    >
                        <SimpleGrid spacing={4} columns={2}>
                            {formFields.insurance.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({
                                        field,
                                        control,
                                        register,
                                        errors,
                                        watchFields,
                                    })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </SectionCard>
                </VStack>
            </Form>
        </Container >
    </>;
}
