import React from 'react';
import { useInterpret, useActor } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { createDialogMachine, DialogMachine, context as dialogContext, DialogMachineContext } from './machines/dialogMachine';
import log from '~/log';

export interface DialogContext {
    dialogService: InterpreterFrom<DialogMachine>;
    dialogContext?: DialogMachineContext;
    setInterpreterOptions?: (options: any) => void;
    onClose?: () => void;
}

export const DialogContext = React.createContext<DialogContext>({
    dialogService: {} as InterpreterFrom<DialogMachine>,
    dialogContext,
    onClose: () => log.trace('close'),
});


export const DialogProvider = ({ children }: React.PropsWithChildren) => {
    const [interpreterOptions, setInterpreterOptions] = React.useState<any>({});
    const logger = (state: any) => log.trace(`dialog.subscription:${state.value}`, state.context)
    const dialogService = useInterpret(
        createDialogMachine,
        interpreterOptions,
        // logger
    );
    const [dialogState] = useActor(dialogService);
    const dialogContext = dialogState.context;

    const onClose = () => dialogService.send({ type: 'SET_IS_OPEN', isOpen: false });

    return (
        <DialogContext.Provider
            value={{
                dialogService,
                dialogContext,
                setInterpreterOptions,
                onClose,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};
