import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading, PlusButton } from '@ucc/react/ui';
import { useModal } from '~/hooks/useModal';
import { HasPermission } from '~/layout/HasPermission';
import { ProjectIds } from '~/pages/projects/types';
import { Modals } from '~/pages/projects/ui/Finance/Finance';
import { FinanceContext } from './financeMachine';
import { LIMIT } from './hooks/useFinanceServices'


export type FinanceCalculationProps = {
    send?: (args?: any) => void;
    fixed?: boolean;
    context?: FinanceContext;
    columns?: any[]
    hiddenColumns?: string[]
} & ProjectIds;


export const FinanceCalculation = (props: FinanceCalculationProps) => {
    const { fixed = false, context = {}, columns = [], hiddenColumns = [] } = props;
    const { generalAgreementIdIsStandard = false, cache = {}, scopeId } = context;
    const { data = [], } = cache;
    const { onOpen, dispatch } = useModal();


    const setModal = (modal: any) => {
        dispatch?.({
            type: 'setModal',
            data: { modal },
        });
        onOpen?.();
    }

    return (
        <Card boxShadow="none">
            <CardHeader>
                <CardHeading>{!scopeId ? 'Kalkulation' : cache?.type === 'offer' ? 'Angebotspositionen' : 'Rechnungspositionen'}</CardHeading>
                <HasPermission resource="project.project" permission="update">
                    {!fixed && (
                        <CardActions>
                            {!generalAgreementIdIsStandard && (
                                <PlusButton
                                    variant="outline"
                                    borderColor="blue.500"
                                    color="blue.500"
                                    data-test-id="button-new-standard-general-aggrement-position"
                                    onClick={() => setModal(Modals.SelectStandardGeneralAggrements)}
                                >
                                    Neue Standard-Position
                                </PlusButton>
                            )}
                            <PlusButton
                                variant="outline"
                                borderColor="blue.500"
                                color="blue.500"
                                data-test-id="button-new-free-position"
                                onClick={() => setModal(Modals.EditFreePosition)}
                            >
                                Neue freie Position
                            </PlusButton>
                            <PlusButton
                                data-test-id="button-new-general-aggrement-position"
                                onClick={() => setModal(Modals.SelectGeneralAggrements)}
                            >
                                Neue RV-Position
                            </PlusButton>
                        </CardActions>
                    )}
                </HasPermission>
            </CardHeader>
            <CardContent>
                <DataTable
                    pageSize={LIMIT}
                    columns={columns}
                    hiddenColumns={hiddenColumns as string[]}
                    data={data}
                />
            </CardContent>
        </Card>
    );
};
