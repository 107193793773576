import { tableColumnMapper } from '~/utils';


const columns = {
  participants: {
    __graphqlType: 'Beteiligter',
    id: { header: 'Id', accessor: 'id' },
    type: {
      header: 'Typ',
      accessor: 'typSnippet.name',
      cell: ({ row:{ original} }:any) => {
        const typeSnippet =
          ['Sonstiges', 'Beauftragter Handwerker'].includes(original?.typSnippet?.name) && original?.kontakt?.typSnippet
            ? original?.kontakt?.typSnippet?.name
            : original?.typSnippet?.name;

        return typeSnippet ? typeSnippet : null;
      },
    },
    contact: {
      header: 'Kontakt',
      accessor: 'kontakt',
      renderer: { name: 'ShowContact' },
    },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  participants: {
    columns: ['id', 'type', 'contact', 'controls'].map(tableColumnMapper(columns.participants)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  participants: {
    __graphqlInput: 'BeteiligterInput',
    subprojectId: { api: { path: 'subprojektId', required: true } },
    contactId: { api: { path: 'kontaktId', required: true } },
    typeSnippetId: { api: { path: 'typSnippetId', required: true } },
    residentialUnitId: { api: { path: 'subprojektWohneinheitId' } },
  },
};

export const forms = {
  participants: [
    { path: 'subprojectId', ui: { label: 'Kontakt', component: 'ContactInput' }, visibility: { hide: () => true }, validation: false },
    { path: 'typeSnippetId', ui: { label: 'Typ', component: 'SnippetSelect', props: { category: 'Kontakt', property: 'Projektrolle' } },
      decorators: {
        api: [
          (value:any, context: any) => {
            const { contact, contactId, displayName } = context?.dataValues.contacts?.values?.['contactId'] || {};
            console.log('api:decorator:typeSnippetId', value, contactId, displayName);
            // TODO: tests for hard-coded values
            // SELECT * FROM snippet WHERE kategorie = 'Kontakt' AND eigenschaft = 'Projektrolle'; -- 205 Vermittler
            // UPDATE kontakt SET typ_snippet_id = 205 WHERE firma1 = 'Cosmos Direkt';
            if (['Sonstiges', 'Beauftragter Handwerker'].includes(value?.label)) {
              const newValue = contact?.typSnippetId ? { value: contact?.typSnippetId } : value;
              console.log('api:decorator:typeSnippetId decorate', { contact, newValue });
              return newValue;
            }
            return value;
          },
        ],
      },
    },
    { path: 'contactId', ui: { label: 'Kontakt', component: 'ContactInput' } },
  ],
};
