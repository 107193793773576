import { getNavigation, NavAuth, routes } from '~/navigation';
import { Permission, Resource, useViewer } from './useViewer';
import log from '~/log';

export function useNavigation() {
    const viewer = useViewer();
    const appNavigation = getNavigation(routes);

    log.trace('viewer.appNavigation', appNavigation);

    const navigation = appNavigation
        .map((nav) => ({
            ...nav,
            children: nav.children?.filter((child: NavAuth) => {
                const { resource, permission = 'view' } = child.auth || {};
                return viewer.hasPermission(resource as Resource, permission as Permission);
            }),
        }))
        .filter((nav) => nav.children === undefined || (nav.children && nav.children?.length > 0));

    log.trace('viewer.navigation', navigation);

    return navigation;
}
