import { StyledSelect } from '~/components/Form/StyledSelect';
import { SimpleGrid, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

const Select = ({ placeholder, isLoading, isClearable = false, ...props }: any) => {
    const options = props.options?.map(props.mapOptions)
    const value = options.find(({ value }: any) => value === props.value)
    return <Box w="full">
        <StyledSelect
            isClearable={isClearable}
            options={options}
            value={value}
            onChange={(option: any) => props?.onChange?.(option?.value)}
            placeholder={placeholder}
            isLoading={isLoading} />
    </Box>
}

export const CalendarFilter = (props: any) => {
    const params = useParams()
    const { isLoading } = props
    const scheduleType = params?.date ? 'day' : 'week'

    return (
        <SimpleGrid columns={2} spacing={4} w={450}>
            <Select
                key={props?.branchId}
                placeholder="Niederlassung"
                onChange={props?.onSelectBranch}
                isLoading={isLoading}
                options={props?.branches}
                value={props?.branchId}
                // isClearable
                mapOptions={
                    ({ id: value, nummer, bezeichnung }: any) => ({
                        value,
                        label: [nummer, bezeichnung].join(' - ')
                    })}
            />
            {scheduleType === 'week' ? <Select
                key={[scheduleType, props?.branchId].filter(Boolean).join('.')}
                placeholder="Mitarbeiter"
                value={props?.employeeId}
                onChange={(value: number) => props.onSelectEmployee({ employeeId: value, locationState: undefined })}
                isLoading={isLoading}
                options={props?.employees}
                mapOptions={
                    ({ id: value, name, vorname }: any) => ({
                        value,
                        label: [name, vorname].filter(Boolean).join(', ')
                    })}
            /> : <>
                {/* <Select
                    key={scheduleType}
                    isClearable
                    placeholder="Rolle"
                    onChange={props?.onSelectRole}
                    isLoading={isLoading}
                    options={props?.roles}
                    value={props?.roleId}
                    mapOptions={({ id: value, bezeichnung: label }: any) => ({ value, label })}
                /> */}
                <Select
                    key={scheduleType}
                    isClearable
                    placeholder="Funktion"
                    onChange={props?.onSelectFunction}
                    isLoading={isLoading}
                    options={props?.employeeFunctions}
                    value={props?.functionId}
                    mapOptions={({ id: value, bezeichnung: label }: any) => ({ value, label })}
                />
            </>}


        </SimpleGrid>

    );
};


