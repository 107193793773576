import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListSubprojectsDocument, DeleteSubprojectDocument, SortOrder } from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/projects/meta/data/projects.schema';
import { ProjectId } from '~/pages/projects/types';
import { useContextAndRefetchQueries, useDeleteWithConfirmation, usePermission } from '~/hooks';
import { useMutation, useQuery } from '@apollo/client';
import { useOrderBy } from './useOrderBy';

export const useRelatedProjects = ({ projectId }: ProjectId) => {
    const { canView, canEdit, canCreate, canDelete } = usePermission('project.project');
    const [orderBy, setOrderBy]: any = useOrderBy();
    const navigate = useNavigate();

    const variables = {
        filter: { projektId: projectId },
        orderBy: orderBy.length
            ? orderBy
            : [{ lfdNr: SortOrder.Asc }, { anlageDatum: SortOrder.Desc }],
    };
    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListSubprojectsDocument,
        variables
    );

    // ===============================================
    // DELETE Project
    // ===============================================

    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteSubprojectDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries],
    });

    // ===============================================
    // LIST
    // ===============================================

    const { data, loading, error } = useQuery(ListSubprojectsDocument, {
        context: contextAndRefetchQueries.context,
        variables,
    });
    const subprojects = data?.items?.items || [];

    // ===============================================
    // TABLE
    // ===============================================

    const controls = [
        {
            title: 'Anzeigen',
            props: {
                onClick: (row: any) =>
                    navigate(`/projekte/${projectId}/${row?.original.id}/details`),
            },
            enabled: () => canView,
        },
        {
            title: 'Bearbeiten',
            props: {
                onClick: (row: any) =>
                    navigate(`/projekt/${projectId}/${row?.original.id}/bearbeiten`),
            },
            enabled: () => canEdit,
        },
        {
            title: 'Neues Subprojekt',
            props: {
                onClick: (row: any) =>
                    navigate(`/projekt/${projectId}/neu`, {
                        state: { type: 'NEW_SUBPROJECT', subprojectId: row?.original?.id },
                    }),
            },
            enabled: () => canCreate,
        },
        {
            title: 'divider',
            enabled: (row: any) =>
                canDelete && row?.original?.lfdNr !== 1 && (canView || canEdit || canCreate),
        },
        {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) =>
                    deleteWithConfirmation({
                        id: row?.original?.id,
                        version: row?.original?.version,
                        forceOverwrite: true,
                    }),
            },
            enabled: (row: any) => canDelete && row?.original?.lfdNr !== 1,
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.relatedProjects.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.relatedProjects.hiddenColumns;

    return {
        data: subprojects,
        loading: loading || deleteLoading,
        error,
        columns,
        hiddenColumns,
        onSortChange: setOrderBy,
    };
};
