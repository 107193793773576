import React from 'react';
import { omit, orderBy } from 'lodash';
import { format } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { client } from '~/apollo';
import {
    ListDocumentsDocument,
    DokumentInput,
    Dokument,
    EinsatzberichtDetail,
    CreateDocumentDocument,
    DeleteDocumentDocument,
    UpdateOperationReportDetailDocument,
    ListOperationReportDetailsDocument,
    ListCorrespondencesAndEventsDocument,
    UpdateDocumentDocument,
    CreateCorrespondenceDocument,
    KorrespondenzCreateInput,
} from '~/gql/ucpw/graphql';
import { tables } from '~/pages/projects/meta/data/documents.schema';
import { ProjectIds } from '~/pages/projects/types';
import { Modals } from '~/pages/projects/ui/Documents';
import { isJsonString, withRenderers } from '~/utils';
import {
    useContextAndRefetchQueries,
    useDeleteWithConfirmation,
    useModal,
    useAsset,
    usePermission,
    useSnippetId,
    useViewer,
} from '~/hooks';
import log from '~/log';

type Props = {
    tableName?: 'documents';
    refetchQueries?: [];
} & ProjectIds;

export const useDocuments = ({ tableName = 'documents', subprojectId, ...props }: Props) => {
    const viewer = useViewer();
    const mitarbeiterIdSachbearbeiter = viewer?.app?.mitarbeiterId;
    const { typSnippetId } = useSnippetId({
        name: 'Sonstiges',
        category: 'Korrespondenz',
        property: 'Typ',
    });
    const { canEdit, canCreate, canDelete, canView } = usePermission('project.documents');
    const readOnly = canView && !(canCreate || canEdit);

    const { canEdit: canEditCorrespondenceDocument, canDelete: canDeleteCorrespondenceDocument } =
        usePermission('project.correspondence');
    const { dispatch, onOpen } = useModal();
    const { upload } = useAsset();

    const context = { clientName: 'ucpw' };
    const pageSize = 100;
    const variables = { filter: { subprojektId: subprojectId }, limit: pageSize };
    const contextAndRefetchQueries = useContextAndRefetchQueries(ListDocumentsDocument, variables);

    // ===============================================
    // LIST
    // ===============================================

    const {
        data: listData,
        loading: listLoading,
        error: listError,
    } = useQuery(ListDocumentsDocument, { variables, context });

    const documents = orderBy(listData?.items?.items || [], ['id'], ['desc']);

    const refetchQueries = [
        // internal
        ...contextAndRefetchQueries.refetchQueries,
        // external
        ...(props.refetchQueries || []),
        {
            query: ListCorrespondencesAndEventsDocument,
            variables: { subprojektId: subprojectId },
            context,
        },
    ];

    // ===============================================
    // CREATE
    // ===============================================

    const options = { context, refetchQueries };
    const [createMutation] = useMutation(CreateDocumentDocument, options);
    const [createCorrespondenceMutation] = useMutation(CreateCorrespondenceDocument, options);

    const generateFileName = (fileName: string = '') => {
        const timestamp = format(new Date(), 'yyyyMMdd_HHmmss');
        if (!Boolean(fileName)) {
            return `dokument_${timestamp}.pdf`;
        }

        if (fileName.includes('.pdf')) {
            const [name] = fileName.split('.');
            return [name, timestamp].join('_') + '.pdf';
        }

        return [fileName, timestamp].join('_') + '.pdf';
    };

    const createDocumentFromSignedUrl = React.useCallback(
        async (
            signedUrl: string,
            {
                data,
                fileName,
                freitext,
            }: { data: DokumentInput; fileName?: string; freitext?: string }
        ) => {
            const documentName = generateFileName(fileName);

            try {
                const oReq = new XMLHttpRequest();
                oReq.open('GET', signedUrl, true);
                oReq.responseType = 'blob';
                oReq.onload = async function () {
                    const file = new File(
                        [oReq.response],
                        data?.name ? generateFileName(data?.name) : documentName,
                        {
                            type: 'application/pdf',
                        }
                    );
                    const { key, name, ...response }: any = await upload(file);
                    const { data: documentData } = await createMutation({
                        variables: { data: { name, pfad: key, ...omit(data, 'name', 'pfad') } },
                    });
                    const document = documentData?.item?.item as Dokument;

                    if (document) {
                        const correspondence: KorrespondenzCreateInput = {
                            // subprojektId: Int!;
                            subprojektId: subprojectId as number,
                            // typSnippetId: Int!;
                            typSnippetId,
                            // freitext: AWSJSON!;
                            freitext,
                            // mitarbeiterIdSachbearbeiter: Int;
                            mitarbeiterIdSachbearbeiter,
                            // mitarbeiterIdUrheber: Int;
                            mitarbeiterIdUrheber: mitarbeiterIdSachbearbeiter,
                            // zeit: AWSDateTime;
                            zeit: new Date(),
                            // beteiligter: String!;
                            beteiligter: ' ',
                            // dokumentId: Int;
                            dokumentId: document?.id,
                        };
                        log.debug('createDocumentFromSignedUrl', {
                            document,
                            response,
                            correspondence,
                        });
                        if ([subprojectId, typSnippetId, freitext].every(Boolean)) {
                            await createCorrespondenceMutation({
                                variables: {
                                    data: correspondence,
                                },
                            });
                        }
                    }
                };
                oReq.send();
            } catch (err) {
                console.log(err);
            }
        },
        [typSnippetId, mitarbeiterIdSachbearbeiter]
    );

    // ===============================================
    // UPDATE
    // ===============================================

    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateDocumentDocument,
        options
    );

    // ===============================================
    // UPDATE OPERATIONAL DETAIL
    // ===============================================

    const [updateOperationalDetailMutation, { loading: updateOperationalDetailLoading }] =
        useMutation(UpdateOperationReportDetailDocument, options);

    const upsertOperationalDetails = async (
        document: Dokument,
        mutationType: 'update' | 'delete' = 'update'
    ) => {
        const response = await client.query({
            query: ListOperationReportDetailsDocument,
            context,
            fetchPolicy: 'network-only',
            variables: {
                filter: { einsatzberichtId: document?.einsatzberichtId },
                einsatzberichtOption: { bezeichnungPrefix: 'fotodokumentation' },
            },
        } as any);
        const details = response?.data?.items?.items || [];
        const data = details?.find(
            (detail: EinsatzberichtDetail) =>
                detail?.einsatzberichtOption?.bezeichnung === 'fotodokumentation'
        );

        const photoCollection = isJsonString(data?.text) ? JSON.parse(data?.text) : [];

        const upsertOperationalDetail =
            (original: Dokument) => (type: 'update' | 'delete', data: any) => {
                if (data?.attachment?.key === original?.pfad) {
                    return type === 'update'
                        ? {
                              id: data?.id,
                              description: original?.beschreibung,
                              attachment: {
                                  key: original?.pfad,
                                  fileName: original?.name,
                              },
                              fileType: {
                                  value: original?.typSnippet?.id,
                                  label: original?.typSnippet?.name,
                                  key: original?.typSnippet?.kuerzel,
                              },
                          }
                        : null;
                }
                return data;
            };

        const upsert = upsertOperationalDetail(document);
        const variables: any = {
            id: data?.id,
            data: {
                einsatzberichtId: document?.einsatzberichtId,
                einsatzberichtOptionId: data?.einsatzberichtOption?.id,
                text: JSON.stringify(
                    photoCollection
                        ?.map?.((data: any) => upsert(mutationType, data))
                        .filter(Boolean)
                ),
            },
        };

        return async () => await updateOperationalDetailMutation({ variables });
    };

    // ===============================================
    // DELETE
    // ===============================================

    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteDocumentDocument,
        options
    );
    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries,
        dependencies: [deleteMutation, refetchQueries],
    });

    // ===============================================
    // TABLE
    // ===============================================

    const onEdit = (row: any) => {
        log.debug('useDocuments.onEdit', row.original);
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.Documents,
                props: {
                    createMutation,
                    updateMutation,
                    deleteMutation,
                    subprojectId,
                },
            },
        });
        dispatch?.({
            type: 'setRow',
            data: {
                row,
                upsertOperationalDetails,
            },
        });
        onOpen?.();
    };

    const onMove = (row: any) => {
        log.debug('useDocuments.onMove', row.original);
        dispatch?.({
            type: 'setModal',
            data: { modal: Modals.ProjectSearch },
        });
        dispatch?.({
            type: 'formState',
            data: {
                ...row?.original,
                updateMutation,
            },
        });
        onOpen?.();
    };

    const onDelete = async (row: any) => {
        const upsert = await upsertOperationalDetails(row?.original, 'delete');
        const options = row?.original?.einsatzberichtId
            ? { callAfter: async () => await upsert() }
            : {};

        deleteWithConfirmation({ id: row?.original?.id }, options);
    };

    const correspondenceCreatorMatchesViewer = (row: any) => {
        const correspondence = row?.original?.korrespondenz || [];
        const hasCorrespondence = correspondence?.length > 0;
        const match = correspondence?.find((corr: any) => {
            const creatorId = corr?.mitarbeiterIdUrheber;
            return creatorId === mitarbeiterIdSachbearbeiter || creatorId === null;
        });

        return {
            hasCorrespondence,
            match,
            correspondenceWithNoMatch: hasCorrespondence && !match,
        };
    };

    /** don't touch it! Matches permissions requirements of UCPWO-29 regarding uploaded correspondence documents */
    const controls = [
        {
            title: 'Ansehen',
            props: { onClick: onEdit },
            enabled: (row: any) =>
                readOnly || correspondenceCreatorMatchesViewer(row).correspondenceWithNoMatch,
        },
        {
            title: 'Bearbeiten',
            props: { onClick: onEdit },
            enabled: (row: any) =>
                !correspondenceCreatorMatchesViewer(row)?.hasCorrespondence && canEdit,
        },
        {
            title: 'Bearbeiten',
            props: { onClick: onEdit },
            enabled: (row: any) =>
                correspondenceCreatorMatchesViewer(row)?.match && canEditCorrespondenceDocument,
        },
        {
            title: 'Verschieben',
            props: { onClick: onMove },
            enabled: (row: any) =>
                !correspondenceCreatorMatchesViewer(row).hasCorrespondence && canEdit,
        },
        {
            title: 'Verschieben',
            props: { onClick: onMove },
            enabled: (row: any) =>
                correspondenceCreatorMatchesViewer(row).match && canEditCorrespondenceDocument,
        },
        {
            title: 'divider',
            enabled: (row: any) =>
                correspondenceCreatorMatchesViewer(row).match &&
                canEditCorrespondenceDocument &&
                canDeleteCorrespondenceDocument,
        },
        {
            title: 'divider',
            enabled: (row: any) =>
                !correspondenceCreatorMatchesViewer(row).hasCorrespondence && canEdit && canDelete,
        },
        {
            title: 'Löschen',
            props: { color: 'red.400', onClick: onDelete },
            enabled: (row: any) =>
                correspondenceCreatorMatchesViewer(row).match && canDeleteCorrespondenceDocument,
        },
        {
            title: 'Löschen',
            props: { color: 'red.400', onClick: onDelete },
            enabled: (row: any) =>
                !correspondenceCreatorMatchesViewer(row).hasCorrespondence && canDelete,
        },
    ].filter(Boolean);

    const onCreate = () => {
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.Documents,
                props: {
                    createMutation,
                    updateMutation,
                    deleteMutation,
                    subprojectId,
                },
            },
        });
        dispatch?.({
            type: 'setRow',
            data: {
                row: { original: {} },
                upsertOperationalDetails,
            },
        });
        onOpen?.();
    };

    const columns = React.useMemo(() => withRenderers(tables[tableName].columns, controls), []);
    const hiddenColumns = tables[tableName].hiddenColumns;

    const loading = listLoading || deleteLoading || updateOperationalDetailLoading || updateLoading;
    const error = listError;

    return {
        data: documents,
        pageSize,
        loading,
        error,
        columns,
        hiddenColumns,
        createMutation,
        createDocumentFromSignedUrl,
        updateMutation,
        onCreate,
    };
};
