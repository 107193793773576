import * as Renderers from '~/components/Renderers';
import { SimpleGrid, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<any>();

export const Content = (info: any) => {
    const value = info.getValue();
    return (
        <SimpleGrid column={1} whiteSpace="normal">
            <Text>{value}</Text>
        </SimpleGrid>
    );
};

// FIXME: types
export const withRenderers = (columns: any[] = [], controls: any[] = [], renderers = {}) => {
    return columns.map((col: Record<string, any>) => {
        const hasCellFn = col?.cell && typeof col.cell === 'function';
        const Component: any = hasCellFn
            ? Renderers?.Content
            : renderers[col.renderer?.name as keyof typeof renderers] ||
              Renderers[col.renderer?.name as keyof typeof Renderers];
        return (col.renderer || hasCellFn) && Component
            ? columnHelper.accessor(col.accessor, {
                  ...col,
                  cell: (cell: any) => {
                      const props = col.renderer?.props || {};

                      return (
                          <Component
                              controls={controls}
                              {...cell}
                              {...{
                                  ...(hasCellFn && { cell: col?.cell }),
                                  ...props,
                              }}
                          />
                      );
                  },
              })
            : columnHelper.accessor(col.accessor, {
                  ...col,
                  cell: (cell: any) => <Content {...cell} />,
              });
    });
};
