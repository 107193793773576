import React from "react";
import * as yup from "yup";
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import { Form, renderField } from "~/components/Form/Form";
import { useModal, useDebounce, useYupValidationResolver, usePermission } from "~/hooks";
import { HasPermission } from '~/layout/HasPermission';
import { resolveFormFields } from "~/utils";
import { forms, fields } from '~/pages/projects/meta/data/measures.schema';
import { ProjectIds } from "~/pages/projects/types";
import log from "~/log";

export type ResidentialUnit = {
    id: number;
    title: string;
    isOverallObject: boolean;
};

type ResidentialUnitMeasureProps = {
    unit?: ResidentialUnit;
    id?: string;
} & ProjectIds;

export const ResidentialUnitMeasureModal = (props: ResidentialUnitMeasureProps) => {
    const { onClose, dispatch, state } = useModal();
    const { unit } = state?.modals?.props || {}
    const { row: measure = {}, rowId, context = {} } = state.rows || {};
    const { createMutation, updateMutation } = state?.modals?.props || {}
    const measureId = useDebounce(rowId, 200);
    const formFields = resolveFormFields(forms.measure, fields.measure);
    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(measure),
    };
    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(yup.object(formFields.rules)),
    });

    /** Permit */
    const { canCreate, canEdit, canView } = usePermission('project.measures');
    const readOnly = rowId && canView && !(canCreate || canEdit);
    const modalTitle = readOnly ? 'Position ansehen' : rowId ? 'Position bearbeiten' : 'Neue Position';
    const permssionType = rowId ? 'update' : 'create';
    const cursor = readOnly ? 'not-allowed' : 'auto';
    const pointerEvents = readOnly ? 'none' : 'auto';

    React.useEffect(() => {
        reset(defaultValues);
    }, [measureId]);

    const onSubmit: SubmitHandler<any> = React.useCallback(async (values) => {
        const isUpdate = measureId && context.mode !== 'copy';
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const residentialUnitId: number = isUpdate ? measure?.subprojektWohneinheitId : unit?.id;
        const fixedValues = { residentialUnitId };
        log.debug('onSubmit.fixedValues', JSON.stringify(fixedValues, null, 2));
        const data: any = formFields.toGql(values, {}, fixedValues);
        log.debug('onSubmit.data', JSON.stringify(data, null, 2));
        const response = isUpdate
            ? await updateMutation({ variables: { id: measureId, data } })
            : await createMutation({ variables: { data } });
        log.debug('onSubmit.response', JSON.stringify(response, null, 2));
    }, [measureId, unit, context.mode]);

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        reset(defaultValues);
        onClose?.();
    };

    return (
        <ModalContent rounded="none" maxWidth="container.md">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {readOnly ? 'Schließen' : 'Abbrechen'}
                    </Button>
                    <HasPermission resource='project.measures' permission={permssionType}>
                        <Button
                            data-test-id="button-save"
                            colorScheme="blue"
                            onClick={handleSubmit(onSubmitWithOnClose)}
                        >
                            Speichern
                        </Button>
                    </HasPermission>
                </HStack>
            </ModalHeader>
            <ModalBody cursor={cursor}>
                <Form>
                    <SimpleGrid spacing={4} columns={6} mb={4} pointerEvents={pointerEvents}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({ field, control, register, errors })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};