import * as Sentry from '@sentry/react';
import { createBrowserRouter, LoaderFunction, Navigate, RouteObject } from 'react-router-dom';
import { SignInMethods } from './components/SignIn';
import { Layout } from './layout/Layout';
import { Root } from './layout/Root';
import { Route, routes } from './routes';
import { getBranches } from './pages/master-data/ui/Branches';
import { getEmployeeFunctions } from './pages/staffing/ui/Staff';
import { Page } from './layout/Page';
import { SentryErrorBoundary } from './layout/SentryErrorBoundary';
import { DialogProvider } from './providers/DialogProvider';

const loaders: Record<string, LoaderFunction> = {
    getBranches,
    getEmployeeFunctions,
};

export const routesReducer = (routes: Route[]): RouteObject[] =>
    routes.reduce((acc: any[], { meta, children, ...route }: Route) => {
        return [
            ...acc,
            {
                ...route,
                ...(meta?.loader && { loader: loaders[meta?.loader] }),
                children: children ? routesReducer(children) : undefined,
            },
        ];
    }, []);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <SentryErrorBoundary />,
        children: [
            {
                element: (
                    <Layout>
                        <SentryErrorBoundary>
                            <Page />
                            <DialogProvider />
                        </SentryErrorBoundary>
                    </Layout>
                ),
                children: [
                    { index: true, element: <Navigate to="/dashboard" replace /> },
                    ...routesReducer(routes),
                    { path: '*', element: <Navigate to="/dashboard" replace /> },
                ],
            },
            { path: '/auth', element: <SignInMethods /> },
        ],
    },
]);
