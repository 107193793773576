import React from 'react';
import { useModal } from '~/hooks';
import { tables } from '~/pages/projects/meta/data/finance.schema';
import { withRenderers } from '~/utils';
import { UseFinanceCalculation } from '~/pages/projects/types';
import { FinancePosition, getOriginalAndModalFromPosition } from './useFinance';

export const useFinanceInvoiceCalculation = ({
    fixed = false,
    permissions = {},
    send,
}: UseFinanceCalculation & { permissions?: any }) => {
    const { dispatch, onOpen } = useModal();
    const { canEdit, canDelete, canView } = permissions;

    const controls = React.useMemo(
        () =>
            [
                {
                    title: canView && !canEdit ? 'Ansehen' : 'Bearbeiten',
                    enabled: () => canEdit || canView,
                    props: {
                        onClick: (row: any) => {
                            const position = (row?.original || {}) as FinancePosition;
                            const { original, modal } = getOriginalAndModalFromPosition(position);
                            dispatch?.({ type: 'setModal', data: { modal } });
                            send?.({
                                type: 'SET_CACHE',
                                cache: ({ cache = {} }: any = {}) => ({
                                    ...cache,
                                    row: original,
                                    rowId: original?.id,
                                }),
                            });
                            onOpen?.();
                        },
                    },
                },
                { title: 'divider', enabled: () => canDelete },
                {
                    title: 'Löschen',
                    props: {
                        color: 'red.400',
                        onClick: (row: any) =>
                            send?.({
                                type: 'DELETE_POSITION',
                                variables: { id: row?.original?.id },
                            }),
                    },
                    enabled: () => canDelete,
                },
            ].filter(Boolean),
        [canEdit, canDelete, canView]
    );

    const columns = React.useMemo(
        () => withRenderers(tables.positions.columns, controls),
        [controls]
    );
    const hiddenColumns = [...tables.positions.hiddenColumns, fixed && 'controls'].filter(Boolean);

    return {
        columns,
        hiddenColumns,
    };
};
