import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { WarningIcon } from '@chakra-ui/icons';
import { SimpleGrid, GridItem, useBreakpointValue, Button, useDisclosure, Modal, ModalContent, HStack, Text, VStack, Fade, useMediaQuery } from '@chakra-ui/react';
import { BsFilter } from 'react-icons/bs';
import { DataTable, Card, CardActions, CardContent, CardHeader, CardHeading } from '@ucc/react/ui';
import { tables } from '../../meta/data/projects.schema';
import { withRenderers } from '~/utils/withRenderers';
import { useDataLoader } from '~/hooks/useDataLoader';
import { format, subMonths } from 'date-fns';
import { SearchFilter } from './SearchFilter';
import { SubprojectSearchDocument } from '~/gql/ucpw/graphql';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { CsvDownload } from '~/components/CsvDownload';
import { PageHeader } from '~/components/Layout/PageHeader';
import { HasPermission } from '~/layout/HasPermission';
import log from '~/log';

export const useSearchProject = () => {
    const columns = React.useMemo(() => withRenderers(tables.projectSearch.columns), []);

    const [searchProjects, { data, loading, error }] = useLazyQuery(SubprojectSearchDocument, {
        notifyOnNetworkStatusChange: true,
        context: { clientName: 'ucpw' },
        fetchPolicy: 'no-cache',
    });

    if (error) {
        // TODO: graphql errors to sentry
        log.debug('useSearchProject.error', error);
    }

    const pageCount = data?.app?.subprojektSearch?.pageInfo?.pageCount || 0;
    const projects = data?.app?.subprojektSearch?.items || [];
    const { fetchData } = useDataLoader(searchProjects);

    return {
        columns,
        loading,
        pageCount,
        projects,
        fetchData,
    };
};



export function SearchProject({ title }: { title: string }) {
    const mediaQuery = '(max-width: 1280px)';
    const [isMedia] = useMediaQuery(mediaQuery);
    const location: any = useLocation();
    const { isOpen: modalIsOpen, onClose, onOpen } = useDisclosure()
    const initialSearchInput = { anlageBis: format(new Date(), 'yyyy-MM-dd') };
    const anlageAb = format(subMonths(new Date(initialSearchInput.anlageBis), 12), 'yyyy-MM-dd');
    const [searchInput, setSearchInput] = React.useState({});
    const colSpanFilter = useBreakpointValue({ base: 4, md: 4, lg: 2, xl: 1 });
    const colSpanProjects = useBreakpointValue({ base: 4, md: 4, lg: 2, xl: 3 });
    const { onToggle, isOpen } = useDisclosure({ defaultIsOpen: true });
    const { columns, loading, pageCount, projects, fetchData } = useSearchProject();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (location?.state?.isProjectRedirect) {
            onOpen()
            setTimeout(() => onClose(), 4000)
        }
    }, [location?.state])


    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, {
        orderBy: [{ anlageDatum: "desc" }],
        searchInput: { ...initialSearchInput, ...searchInput },
    })


    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
        </PageHeader>
        <SimpleGrid columns={4} spacing={5} p={4}>
            {isOpen && (
                <GridItem colSpan={colSpanFilter}>
                    <Fade in={isOpen}>
                        <SearchFilter onFilter={setSearchInput} loading={loading} />
                    </Fade>
                </GridItem>
            )}
            <GridItem colSpan={isOpen ? colSpanProjects : 4}>
                <Card>
                    <CardHeader
                        flexDir={isMedia ? "column" : 'row'}
                    >
                        <CardHeading>Projekte</CardHeading>
                        <CardActions {...(isMedia && { justify: "flex-start" })}>
                            <Button
                                leftIcon={<BsFilter />}
                                variant="outline"
                                size="sm"
                                px={5}
                                onClick={onToggle}
                            >
                                {`Filter ${isOpen ? 'aus' : 'ein'}blenden`}
                            </Button>
                            <HasPermission resource="additional.downloadProjects" permission="enabled">
                                <CsvDownload
                                    searchInput={{ anlageAb, ...searchInput }}
                                    colorScheme="blue"
                                    size="sm"
                                    variant="solid"
                                />
                            </HasPermission>

                        </CardActions>
                    </CardHeader>
                    <CardContent>
                        <DataTable<any>
                            columns={columns}
                            data={projects}
                            fetchData={fetchDataWithFilters}
                            hiddenColumns={tables.projectSearch.hiddenColumns}
                            loading={loading}
                            pageCount={pageCount}
                            onClick={(row) => {
                                navigate(`/projekte/${row?.original?.projekt?.id}/${row?.original?.id}/details`);
                            }}
                        />
                    </CardContent>
                </Card>
            </GridItem>
            <Modal isOpen={modalIsOpen} onClose={onClose} >
                <ModalContent bg="orange.500" p={3} color="white" >
                    <VStack align="start" fontSize={16} spacing={1}>
                        <HStack>
                            <WarningIcon />
                            <Text size="sm" as="b">Sie wurden umgeleitet!</Text>
                        </HStack>
                        <Text pl={6} fontSize={14}>
                            Es wurde kein entsprechendes Projekt für <Text as="b">{location?.state?.location?.pathname as any}</Text> gefunden.
                        </Text>
                    </VStack>
                </ModalContent>
            </Modal>
        </SimpleGrid >
    </>;
}
