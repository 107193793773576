import React from 'react';
import { getOrderByProps } from '~/utils';
import log from '~/log';

export function useFetchDataWithFilter(
    fetchData: (args: any) => void,
    data?: {
        filter?: Record<string, any>;
        sortBy?: any[];
        orderBy?: any[];
        limit?: number;
        searchInput?: Record<string, any>;
        searchTerm?: string;
        [key: string]: any;
    }
) {
    return React.useCallback(
        (args?: Record<string, any>) => {
            log.trace('useFetchDataWithFilter', { ...args, ...(data || {}) });
            const orderBy = data?.orderBy || [];
            fetchData({
                ...args,
                ...(data || {}),
                ...getOrderByProps(args, orderBy),
            });
        },
        [data, fetchData]
    );
}
