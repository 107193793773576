import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    ListGeneralAgreementsDocument,
    CreateGeneralAgreementDocument,
    UpdateGeneralAgreementDocument,
    DeleteGeneralAgreementDocument,
} from '~/gql/ucpw/graphql';
import { withRenderers } from '~/utils';
import { tables } from '~/pages/master-data/meta/data/generalAgreements.schema';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useDataLoader } from '~/hooks/useDataLoader';
import { useModal } from '~/hooks/useModal';
import { usePermission } from '~/hooks/usePermission';

export const useGeneralAgreements = () => {
    const { canEdit, canDelete } = usePermission('masterData.agreements');

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const [load, { data, loading: lazyLoading, refetch }] = useLazyQuery(
        ListGeneralAgreementsDocument,
        {
            context: { clientName: 'ucpw' },
        }
    );
    const agreements: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData, variables } = useDataLoader(load, {
        orderBy: [{ isStandard: 'desc' }, { bezeichnung: 'asc' }],
    });
    const contextAndRefetchQueries = useContextAndRefetchQueries(
        ListGeneralAgreementsDocument,
        variables
    );

    // ===============================================
    // (C)R(U)D
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateGeneralAgreementDocument,
        contextAndRefetchQueries
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateGeneralAgreementDocument,
        contextAndRefetchQueries
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteGeneralAgreementDocument,
        contextAndRefetchQueries
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation,
        refetchQueries: contextAndRefetchQueries.refetchQueries,
        dependencies: [deleteMutation, contextAndRefetchQueries.refetchQueries],
    });

    const loading = lazyLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal();

    // ===============================================
    // CONTROLS
    // ===============================================

    const setGeneralAgreementsModal = () =>
        dispatch?.({
            type: 'setModal',
            data: {
                modal: 'GeneralAgreementsModal',
                props: { createMutation, updateMutation, refetch, loading },
            },
        });

    const onClick = React.useCallback(
        (row: any) => {
            setGeneralAgreementsModal();
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen, createMutation, updateMutation, refetch, loading]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: { onClick },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({ id: row?.original?.id });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.agreements.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.agreements.hiddenColumns;

    const onCreate = () => {
        setGeneralAgreementsModal();
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    };

    return {
        onCreate,
        data: agreements,
        pageCount,
        hiddenColumns,
        columns,
        loading,
        fetchData,
        standard: agreements?.[0],
        plusButtonProps: {
            onClick: onCreate,
            children: 'Neue Vereinbarung',
            mq: true,
        },
    };
};
