import { Button, Container, Stack, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { Permission, Resource, useViewer } from '~/hooks/useViewer';

interface SecureRouteProps {
    resource: string;
    permission?: Permission;
}

export function SecureRoute({
    resource,
    permission = 'view',
    children,
}: React.PropsWithChildren<SecureRouteProps>) {
    const viewer = useViewer();
    const canView = viewer.hasPermission(resource as Resource, permission);

    if (!canView) {
        return (
            <VStack alignItems="center" justifyContent="center" mt={16}>
                <Container maxW="800px">
                    <Stack spacing="1">
                        <Text fontWeight="medium">
                            Die Seite ist entweder nicht vorhanden oder Sie haben keine
                            Berechtigung, sie anzuzeigen.
                        </Text>
                        <Text size="xs" color="muted">
                            Bitte informieren Sie Ihren Administrator über das Problem.
                        </Text>
                        <Button as={NavLink} to="/">
                            Dashboard
                        </Button>
                    </Stack>
                </Container>
            </VStack>
        );
    }

    return <>{children}</>;
}
