import React from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useDataLoader } from '~/hooks/useDataLoader';
import { Form, renderField } from '~/components/Form/Form';
import { resolveFormFields, withRenderers } from '~/utils';
import { useModal } from '~/hooks/useModal';
import {
    ListBranchesDocument,
    CreateBranchDocument,
    UpdateBranchDocument,
    DeleteBranchDocument,
    ListZipCodesDocument,
    CreateBranchZipCodeDocument,
    UpdateBranchZipCodeDocument,
    DeleteBranchZipCodeDocument,
    SelectBranchesDocument,
} from '~/gql/ucpw/graphql';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { tables, forms, fields } from '~/pages/master-data/meta/data/branches.schema';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { MultiModal } from '~/components/MultiModal';
import { useDebounce } from '~/hooks/useDebounce';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import log from '~/log';
import { useNavigate } from 'react-router-dom';
import { usePermission } from '~/hooks/usePermission';

const ZipCodeModal = (props: any) => {
    const { createMutation, updateMutation, loading: isLoading, refetch } = props;
    const { state, dispatch, onClose } = useModal();
    const { row: zipCode = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.zipCode, fields.zipCode);
    const id = useDebounce(rowId, 200);

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(zipCode),
        ...(zipCode?.niederlassung && { branch: { value: zipCode?.niederlassung?.id } }),
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({ defaultValues });

    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const data = formFields.toGql(values, {}) as any;
            log.debug('onSubmit.data', JSON.stringify(data, null, 2));
            const response = id
                ? await updateMutation({ variables: { id, data } })
                : await createMutation({ variables: { data } });
            log.debug('onSubmit.response', JSON.stringify(response, null, 2));
            if (zipCode?.niederlassung?.id !== response?.data?.item?.item?.niederlassungId) {
                refetch?.(response);
            }
        },
        [id, zipCode, refetch]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {id ? 'Postleitzahl bearbeiten' : 'Neue Postleitzahl'}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        isLoading={isLoading}
                        data-test-id="button-save-zip-code"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={6}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

const BranchModal = (props: any) => {
    const navigate = useNavigate();
    const { createMutation, updateMutation, loading: isLoading, onSelectBranch } = props;
    const { state, dispatch, onClose } = useModal();
    const { row: position = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.branches, fields.branches);
    const id = useDebounce(rowId, 200);

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(position),
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({ defaultValues });

    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const data = formFields.toGql(values, {}) as any;
            log.debug('onSubmit.data', JSON.stringify(data, null, 2));
            const response = id
                ? await updateMutation({ variables: { id, data } })
                : await createMutation({ variables: { data } });
            log.debug('onSubmit.response', JSON.stringify(response, null, 2));
            if (!id) {
                onSelectBranch?.(response?.data?.item?.item);
                navigate(`/stammdaten/niederlassungen/${response?.data?.item?.item?.id}`);
            }
        },
        [id]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {id ? 'Niederlassung bearbeiten' : 'Neue Niederlassung'}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                    <Button
                        isLoading={isLoading}
                        data-test-id="button-save-branch"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={6}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

export const useBranches = ({ branchId, debouncedSearchTerm, branch, onSelectBranch }: any) => {
    const { canEdit, canDelete } = usePermission('masterData.branches');
    const navigate = useNavigate();
    // ===============================================
    // FILTERING
    // ===============================================

    const startWithSlash = debouncedSearchTerm.startsWith('/');
    const searchTerm = startWithSlash ? debouncedSearchTerm.slice(1) : debouncedSearchTerm;
    const allNum = /^\d+$/.test(searchTerm);
    const filterTerm = !searchTerm
        ? {}
        : allNum
            ? { plzPrefix: searchTerm }
            : { ortPrefix: searchTerm };
    log.debug('useBranches', {
        branch,
        branchId,
        debouncedSearchTerm,
        allNum,
        startWithSlash,
        filterTerm,
    });

    // ===============================================
    // LAZY ZIP CODE LISTING
    // ===============================================

    const context = { clientName: 'ucpw' };

    const [load, { data, loading: lazyLoading }] = useLazyQuery(ListZipCodesDocument, {
        context,
    });
    const zipCodes: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;

    const { fetchData, variables } = useDataLoader(load);
    const fetchDataWithFilters = useFetchDataWithFilter(fetchData, {
        ...(branchId && {
            filter: startWithSlash
                ? { niederlassungIdExists: false, ...filterTerm }
                : { ...(debouncedSearchTerm ? filterTerm : { niederlassungId: branchId }) },
        }),
    });
    const { refetchQueries: zipCodeRefetchQueries } = useContextAndRefetchQueries(
        ListZipCodesDocument,
        variables
    );
    const { refetchQueries: branchesRefetchQueries } =
        useContextAndRefetchQueries(ListBranchesDocument);
    const { refetchQueries: selectBranchesRefetchQueries } = useContextAndRefetchQueries(
        SelectBranchesDocument,
        { limit: 100 }
    );
    const refetchQueries = [
        ...zipCodeRefetchQueries,
        ...branchesRefetchQueries,
        ...selectBranchesRefetchQueries,
    ];
    const options = { context, refetchQueries };

    // ===============================================
    // (C)R(UD) ZIP CODE
    // ===============================================

    const [createZipCodeMutation, { loading: createZipCodeLoading }] = useMutation(
        CreateBranchZipCodeDocument,
        options
    );
    const [updateZipCodeMutation, { loading: updateZipCodeLoading }] = useMutation(
        UpdateBranchZipCodeDocument,
        options
    );
    const [deleteZipCodeMutation, { loading: deleteZipCodeLoading }] = useMutation(
        DeleteBranchZipCodeDocument,
        options
    );
    const { deleteWithConfirmation: deleteZipCodeWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation: deleteZipCodeMutation,
        refetchQueries,
        onSuccessMessage: (item) => `Postleitzahl "${item?.plz} ${item?.ort}" wurde gelöscht.`,
        dependencies: [deleteZipCodeMutation, refetchQueries],
    });

    // ===============================================
    // (C)R(UD) BRANCH
    // ===============================================

    const { data: branchesData } = useQuery(ListBranchesDocument, { context });
    const branches = branchesData?.items?.items || [];
    const [createBranchMutation, { loading: createBranchLoading }] = useMutation(
        CreateBranchDocument,
        options
    );
    const [updateBranchMutation, { loading: updateBranchLoading }] = useMutation(
        UpdateBranchDocument,
        options
    );
    const [deleteBranchMutation, { loading: deleteBranchLoading }] = useMutation(
        DeleteBranchDocument,
        options
    );
    const { deleteWithConfirmation: deleteBranchWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation: deleteBranchMutation,
        refetchQueries,
        dependencies: [deleteBranchMutation, refetchQueries],
    });

    const loading =
        lazyLoading ||
        createZipCodeLoading ||
        updateZipCodeLoading ||
        deleteZipCodeLoading ||
        createBranchLoading ||
        updateBranchLoading ||
        deleteBranchLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const redirectWhenZipCodeMovesToOtherBranch = React.useCallback(
        (response: any) => {
            const id = response?.data?.item?.item?.niederlassungId;
            const branch = branches.find((branch: any) => branch?.id === id);
            navigate(`/stammdaten/niederlassungen/${id}`);
            onSelectBranch(branch);
        },
        [branches]
    );

    const { onOpen, dispatch } = useModal({
        defaultState: { modals: { activeModal: 'BranchModal' } },
        component: (
            <MultiModal>
                <BranchModal
                    id="BranchModal"
                    {...{
                        createMutation: createBranchMutation,
                        updateMutation: updateBranchMutation,
                        loading,
                        onSelectBranch,
                    }}
                />
                <ZipCodeModal
                    id="ZipCodeModal"
                    {...{
                        createMutation: createZipCodeMutation,
                        updateMutation: updateZipCodeMutation,
                        loading,
                        refetch: redirectWhenZipCodeMovesToOtherBranch,
                    }}
                />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer('BranchModal'),
            row: rowReducer,
        }),
    });

    // ===============================================
    // CONTROLS
    // ===============================================

    const onClick = React.useCallback(
        (row: any) => {
            if (!canEdit) return;
            dispatch?.({
                type: 'setModal',
                data: { modal: 'ZipCodeModal' },
            });
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen, canEdit]
    );

    const controls: any = [
        canEdit && {
            title: 'Bearbeiten',
            props: {
                onClick,
            },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteZipCodeWithConfirmation(
                        { id: row?.original?.id },
                        {
                            callAfter: () =>
                                navigate(
                                    `/stammdaten/niederlassungen/${row?.original?.niederlassung?.id}`
                                ),
                        }
                    );
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(
        () => withRenderers(tables.branches.columns, controls),
        [controls]
    );
    const hiddenColumns = tables.branches.hiddenColumns;

    const actionProps = React.useMemo(
        () => ({
            onEdit: () => {
                dispatch?.({
                    type: 'setModal',
                    data: { modal: 'BranchModal' },
                });
                dispatch?.({ type: 'setRow', data: { row: { original: branch } } });
                onOpen?.();
            },
            onCreate: () => {
                dispatch?.({
                    type: 'setModal',
                    data: { modal: 'BranchModal' },
                });
                dispatch?.({ type: 'setRow', data: {} });
                onOpen?.();
            },
            onDelete: () => {
                const [redirect]: any = branches;
                deleteBranchWithConfirmation(
                    { id: branch?.id },
                    {
                        callAfter: () => {
                            onSelectBranch(redirect);
                            navigate(`/stammdaten/niederlassungen/${redirect?.id}`);
                        },
                    }
                );
            },
        }),
        [branch, branches]
    );

    const plusButtonProps = {
        children: 'Neue Postleitzahl',
        onClick: () => {
            dispatch?.({
                type: 'setModal',
                data: { modal: 'ZipCodeModal' },
            });
            dispatch?.({
                type: 'setRow',
                data: { ...(branch && { row: { original: { niederlassung: branch } } }) },
            });
            onOpen?.();
        },
    };

    const getPlusButtonProps = React.useCallback(
        (branch: any) => ({
            children: 'Neue Postleitzahl',
            onClick: () => {
                dispatch?.({
                    type: 'setModal',
                    data: { modal: 'ZipCodeModal' },
                });
                dispatch?.({
                    type: 'setRow',
                    data: { ...(branch && { row: { original: { niederlassung: branch } } }) },
                });
                onOpen?.();
            },
        }),
        [dispatch, onOpen]
    );

    return {
        columns,
        data: zipCodes,
        hiddenColumns,
        pageCount,
        loading,
        onClick,
        fetchData: fetchDataWithFilters,
        actionProps,
        plusButtonProps,
        branches,
        getPlusButtonProps,
    };
};
