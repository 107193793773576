import React from 'react';
import { VStack, SimpleGrid, Button } from '@chakra-ui/react';
import { useSecurity } from '@ucc/react/security';
import { Form, renderField } from '~/components/Form/Form';
import { SectionCard } from '~/components/SectionCard';
import { resolveFormFields } from '~/utils/resolveFormFields';
import { fields, forms } from '../meta/data/account.schema';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { ResetAvatarDocument, UpdateAvatarDocument } from '~/gql/ucc/graphql';
import { PageHeader } from '~/components/Layout/PageHeader';
import log from '~/log';

export function Account({ title }: { title: string }) {
    const { viewer } = useSecurity();

    const formFields = resolveFormFields(forms.profile, fields.profile);
    const [email, firstName, lastName, avatar] = formFields.fields;
    const defaultValues = {
        ...formFields.defaultValues,
        ...viewer,
    };
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues,
    });

    React.useEffect(() => {
        reset(defaultValues);
    }, [viewer]);

    const [updateAvatar, { loading: updateLoading }] = useMutation(UpdateAvatarDocument);
    const [resetAvatar, { loading: resetLoading }] = useMutation(ResetAvatarDocument);

    const onSubmit: SubmitHandler<any> = React.useCallback(async (values) => {
        log.debug('onSubmit.values', JSON.stringify(values, null, 2));
        const data: any = formFields.toGql(values, {});

        data.avatar
            ? await updateAvatar({ variables: { avatar: data.avatar } })
            : await resetAvatar();
    }, []);

    return (
        <>
            <PageHeader>
                <PageHeader.Title>{title}</PageHeader.Title>
                <PageHeader.Actions>
                    <Button
                        isLoading={updateLoading || resetLoading}
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Speichern
                    </Button>
                </PageHeader.Actions>
            </PageHeader>
            <VStack alignItems="center">
                <SectionCard title="Profil-Informationen">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <SimpleGrid spacing={4} columns={2} mb={4}>
                            <React.Fragment>
                                {renderField({ field: email, control, register, errors })}
                            </React.Fragment>
                            <VStack spacing={6} align="start" w="full">
                                <React.Fragment>
                                    {renderField({ field: firstName, control, register, errors })}
                                </React.Fragment>
                                <React.Fragment>
                                    {renderField({ field: lastName, control, register, errors })}
                                </React.Fragment>
                            </VStack>
                            <React.Fragment>
                                {renderField({ field: avatar, control, register, errors })}
                            </React.Fragment>
                        </SimpleGrid>
                    </Form>
                </SectionCard>
            </VStack>
        </>
    );
}
