import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { ButtonProps } from '@chakra-ui/react';
import { SubprojectCsvExportDocument, SubprojektSearch, SortOrder } from '~/gql/ucpw/graphql';
import { DownloadButton } from '~/components/DownloadButton';
import { download } from '~/hooks/useDownload';

export const CsvDownload = ({
    searchInput,
    fileName = 'projekte',
    ...props
}: ButtonProps & { fileName?: string; searchInput?: SubprojektSearch }) => {
    const [loadCsvExport, { loading }] = useLazyQuery(SubprojectCsvExportDocument, {
        fetchPolicy: 'network-only',
        context: { clientName: 'ucpw' },
        onCompleted: data => {
            const csv = data?.app?.subprojectCsvExport?.item || '';
            console.log('CsvDownload', csv)
            if (csv) {
                download({
                    fileName,
                    data: csv,
                });
            }
        }
    });

    const fetchData = React.useCallback(() => {
        searchInput && loadCsvExport({ variables: { searchInput, orderBy: { anlageDatum: SortOrder.Desc } } })
    }, [searchInput])

    return (
        <DownloadButton
            colorScheme="gray"
            isLoading={loading}
            isDisabled={loading}
            onClick={fetchData}
            {...props}
        />
    );
};
