import React from 'react';
import { useModal } from '~/hooks/useModal';

export function useCreateContactDefaultValues({
    objectStreet: street,
    objectLocation: location,
    objectZipCode: zipCode,
}: any) {
    const { dispatch } = useModal();
    const feedForm = React.useCallback(() => {
        dispatch?.({
            type: 'setModal',
            data: { modal: 'CreateContact' },
        });
        dispatch?.({
            type: 'setDefaultAddress',
            data: {
                defaultAddress: {
                    street,
                    zipCode,
                    location,
                },
            },
        });
    }, [street, zipCode, location]);

    React.useEffect(() => {
        feedForm();
    }, [feedForm]);
}
