export const rowReducer = (state: any = {}, action: any = {}) => {
    const { type, data = {} } = action;
    const { row, ...context } = data || {};
    const original = row?.original;
    switch (type) {
        case 'setRow': {
            return { ...state, rows: { row: original, rowId: original?.id, context } };
        }
        case 'resetState': {
            const resetState = {
                ...state,
                rows: {},
            };
            console.log('rowReducer.resetState', resetState);
            return resetState;
        }
    }
};
