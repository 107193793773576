import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FiSearch, FiRefreshCw } from 'react-icons/fi';
import { Button, IconButton, SimpleGrid, Text, Divider, VStack, HStack, GridItem, useMediaQuery, Tooltip } from '@chakra-ui/react';
import { Card, CardActions, CardContent, CardHeader, CardHeading } from '@ucc/react/ui';
import { Form, renderField } from '~/components/Form/Form';
import * as schema from '../../meta/data/projects.schema';
import { formFieldsToGraphQl, resolveFormFields, combineFields } from '~/utils';
import log from '~/log'

type Props = {
    onFilter?: (data?: any) => void;
    loading?: boolean;
};

const Rubric = ({ children, tooltip = '' }: React.PropsWithChildren<{ tooltip?: string }>) => (
    <GridItem colSpan={2}>
        <HStack w="full" cursor={tooltip ? 'help' : 'default'}>
            <Tooltip label={tooltip} fontSize='sm' hasArrow placement='top' openDelay={250}>
                <Text
                    fontSize="lg"
                    fontWeight="bold"
                    whiteSpace="nowrap"
                    color="gray.400"
                    sx={{
                        '.rubric:hover &':
                            { color: 'blue.500', fontWeight: 'bold' },
                    }}
                    transition="0.5s"
                >
                    {children}
                </Text>
            </Tooltip>
            <Divider />
        </HStack>
    </GridItem>
)


export const SearchFilter = ({ onFilter = () => null, loading }: Props) => {
    const { fields, forms } = schema || {}
    const formToGql = formFieldsToGraphQl(
        [
            ...forms?.projectSearchProject,
            ...forms?.projectSearchObject,
            ...forms?.projectSearchParticipant,
            ...forms?.projectSearchInsurance
        ] as any, fields?.projectSearch);

    const mediaQuery = '(max-width: 1536px)';
    const [isMedia] = useMediaQuery(mediaQuery);
    const [key, setKey] = React.useState(0);
    // form Fields
    const formFields: any = {
        project: resolveFormFields(forms?.projectSearchProject as any, fields?.projectSearch),
        object: resolveFormFields(forms?.projectSearchObject as any, fields?.projectSearch),
        participant: resolveFormFields(forms?.projectSearchParticipant as any, fields?.projectSearch),
        insurance: resolveFormFields(forms?.projectSearchInsurance as any, fields?.projectSearch),
    };
    const defaultValues = { ...combineFields(...Object.values(formFields)).defaultValues, };

    log.trace('SearchFilter.defaultValues', defaultValues);
    const { control, register, handleSubmit, reset, formState: { errors }, watch } = useForm({ defaultValues });
    const watchFields = watch([]);

    const onSubmit: SubmitHandler<any> = async (formData) => {
        log.trace('onSubmit.formData', JSON.stringify(formData, null, 2));
        const data = formToGql(formData);
        log.trace('onSubmit.data', JSON.stringify(data, null, 2));
        onFilter(data);
    };

    const onReset = () => {
        log.trace('SearchFilter.onReset', { defaultValues });
        reset(defaultValues);
        onFilter({});
        setKey(Date.now())
    };

    return (
        <Card>
            <CardHeader borderBottomWidth={1} borderColor="gray.200" flexDir={isMedia ? "column" : 'row'}>
                <CardHeading>Filter</CardHeading>
                <CardActions justify="flex-start" w="full">
                    <Button
                        {...(isMedia && { w: 'full' })}
                        loadingText="Suchen"
                        data-test-id="search-projects"
                        onClick={handleSubmit(onSubmit)}
                        isDisabled={loading}
                        isLoading={loading}
                        leftIcon={<FiSearch />}
                        colorScheme="blue"
                        variant="solid"
                        size="sm"
                        px={5}
                    >
                        Suchen
                    </Button>
                    <IconButton
                        data-test-id="search-projects-reset"
                        onClick={onReset}
                        aria-label="Reset"
                        icon={<FiRefreshCw />}
                        colorScheme="blue"
                        variant="outline"
                        size="sm"
                        px={5}
                    />
                </CardActions>
            </CardHeader>
            <CardContent p={5} key={key}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <VStack align="flex-start" spacing={8}>
                        <SimpleGrid columns={2} spacing={4} w="full" className='rubric'>
                            <Rubric>Projekt</Rubric>
                            {formFields.project?.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({ field, control, register, errors, watchFields })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                        <SimpleGrid columns={2} spacing={4} w="full" className='rubric'>
                            <Rubric>Objekt</Rubric>
                            {formFields.object?.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({ field, control, register, errors, watchFields })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                        <SimpleGrid columns={2} spacing={4} w="full" className='rubric'>
                            <Rubric>Beteiligter</Rubric>
                            {formFields.participant?.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({ field, control, register, errors, watchFields })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                        <SimpleGrid columns={2} spacing={4} w="full" className='rubric'>
                            <Rubric tooltip='Schaden-/Versicherungsnummer, Ihr Zeichen' >Referenznummern</Rubric>
                            {formFields.insurance?.fields.map((field: any) => (
                                <React.Fragment key={field.name}>
                                    {renderField({ field, control, register, errors, watchFields })}
                                </React.Fragment>
                            ))}
                        </SimpleGrid>
                    </VStack>
                    <Button
                        type="submit"
                        // doesn't work in safari
                        // display="none"
                        visibility="hidden"
                        w={0}
                        h={0}
                        overflow="hidden"
                    />
                </Form>
            </CardContent>
        </Card >
    );
};

