import React from 'react';
import { VStack, Text, Skeleton, chakra, HStack, Checkbox } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Form } from '~/components/Form/Form';
import { PageHeader } from '~/components/Layout/PageHeader';
import { DataTable, Card, CardHeader, CardActions, CardContent } from '@ucc/react/ui'
import { useControlling } from '~/pages/master-data/hooks/useControlling';
import { ControllingStates } from '~/pages/master-data/types';
import { CsvDownload } from '~/pages/master-data/components/CsvDownload';
import { SelectVacantPositionEmployees } from '~/pages/master-data/components/SelectVacantPositionEmployees';
import { QuerySelect } from '~/components/Form/QuerySelect';
import { selects } from '~/meta/data/selects.gql';

export function Controlling({ title }: { title: string }) {
    const [states, setState] = React.useState<ControllingStates>({
        branchId: 1,
        employeeId: null,
        only28DaysAndOlder: false,
    });
    const { totalSalesVolume, searchCount, loading, ...tableProps } = useControlling(states)

    const onChange = (props: Partial<ControllingStates>) =>
        setState((prev) => ({ ...prev, ...props }));

    return <>
        <PageHeader>
            <PageHeader.Title>{title}</PageHeader.Title>
            <PageHeader.Actions>
                <ControllingFilter values={states} onChange={onChange} />
            </PageHeader.Actions>
        </PageHeader>
        <Card boxShadow="none">
            <CardHeader><VStack align="flex-start">
                <HStack fontSize={18} fontWeight={700} lineHeight={7}>
                    <Text whiteSpace="nowrap">Umsatz (Summe):</Text>
                    <Skeleton isLoaded={!loading} minW="120px">
                        {totalSalesVolume && (
                            <chakra.span>
                                {new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                }).format(totalSalesVolume)}
                            </chakra.span>
                        )}
                    </Skeleton>
                </HStack>
                <Text color="gray.600">{`${searchCount} Suchergebnisse`}</Text>
            </VStack>
                <CardActions>
                    <Checkbox
                        onChange={(event) => onChange({ only28DaysAndOlder: event.target.checked })}
                    >
                        Nur älter als 28 Tage
                    </Checkbox>
                    <CsvDownload {...states} />
                </CardActions>
            </CardHeader>
            <CardContent>
                <DataTable loading={loading} {...tableProps} />
            </CardContent>
        </Card >
    </>;
}

const ControllingFilter = ({ onChange, values }: any) => {
    const [employeeId, setEmployeeId] = React.useState(values.employeeId);
    const { control, watch }: any = useForm({
        defaultValues: { branchId: { value: values.branchId } },
    });

    const { branchId } = watch();

    React.useEffect(() => {
        setEmployeeId(null);
    }, [branchId]);

    React.useEffect(() => {
        onChange?.({ branchId: branchId?.value, employeeId });
    }, [branchId, employeeId]);

    return (
        <Form>
            <HStack w={400}>
                <QuerySelect
                    mapOptions={(item) => ({
                        value: item?.value,
                        label: `${item?.nummer} - ${item?.bezeichnung}`,
                    })}
                    placeholder="Niederlassung"
                    name="branchId"
                    control={control}
                    query={selects.branches}
                />
                <SelectVacantPositionEmployees
                    isClearable
                    value={employeeId}
                    onChange={setEmployeeId}
                    placeholder="Mitarbeiter"
                    branchId={values.branchId}
                />
            </HStack>
        </Form>
    );
};
