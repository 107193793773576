import React from 'react';
import * as yup from 'yup';
import { pick } from 'lodash';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ModalContent, ModalHeader, ModalBody, Button, HStack, SimpleGrid } from '@chakra-ui/react';
import {
    CreateContactDocument,
    DeleteContactDocument,
    ListContactDocument,
    SearchContactDocument,
    UpdateContactDocument,
} from '~/gql/ucpw/graphql';
import { tables, forms, fields } from '~/pages/master-data/meta/data/contacts.schema';
import { useContextAndRefetchQueries } from '~/hooks/useContextAndRefetchQueries';
import { useFetchDataWithFilter } from '~/hooks/useFetchDataWithFilter';
import { useDeleteWithConfirmation } from '~/hooks/useDeleteWithConfirmation';
import { useModal, useDebounce, useDataLoader, useYupValidationResolver, usePermission } from '~/hooks';
import { Form, renderField } from '~/components/Form/Form';
import { resolveFormFields, withRenderers, mutationGuard } from '~/utils';
import log from '~/log';


export const ContactModal = (props: any) => {
    const { canEdit, canView, canCreate } = usePermission('masterData.contacts');
    const { state, dispatch, onClose } = useModal();
    const { createMutation, updateMutation, loading: isLoading = false } = state?.modals?.props || props || {}
    const { row: contact = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms.contacts, fields.contacts);
    const id = useDebounce(rowId, 200);

    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(contact),
    };
    const message = 'Bitte entweder "Firma", "Name" oder "Vorname" ausfüllen.';

    const {
        handleSubmit,
        control,
        register,
        reset,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(
            yup.object().shape({
                ...formFields.rules,
                company1: yup
                    .string()
                    .test('company1', message, (_, { parent }) =>
                        [parent?.company1, parent?.firstName, parent?.lastName].some(Boolean)
                    ),
                firstName: yup
                    .string()
                    .test('firstName', message, (_, { parent }) =>
                        [parent?.company1, parent?.firstName, parent?.lastName].some(Boolean)
                    ),
                lastName: yup
                    .string()
                    .test('lastName', message, (_, { parent }) =>
                        [parent?.company1, parent?.firstName, parent?.lastName].some(Boolean)
                    ),
            })
        ),
    });

    const validation = watch(['company1', 'firstName', 'lastName']);

    React.useEffect(() => {
        let isMounted = true;
        const hasErrors = errors?.company1 || errors?.firstName || errors?.lastName;

        if (isMounted && hasErrors && Object.values(validation)?.some(Boolean)) {
            clearErrors(['company1', 'firstName', 'lastName']);
        }
        return () => {
            isMounted = false;
        };
    }, [validation, errors]);

    const isSubmitted = React.useRef(false);
    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const cond = Object.values(pick(values, 'company1', 'firstName', 'lastName')).some(
                Boolean
            );
            if (cond && !isSubmitted.current) {
                const data = formFields.toGql(values, {}) as any;
                log.debug('onSubmit.data', JSON.stringify(data, null, 2));
                const response = id
                    ? await updateMutation({
                        variables: {
                            id,
                            forceOverwrite: true,
                            version: contact?.version,
                            data,
                        },
                    })
                    : await createMutation({ variables: { data } });
                isSubmitted.current = true;
                log.debug('onSubmit.response', JSON.stringify(response, null, 2));
            } else if (!cond) {
                setError('company1', { message });
                setError('firstName', { message });
                setError('lastName', { message });
            }
        },
        [id, contact, isSubmitted.current]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {id && canEdit ? 'Kontakt bearbeiten' : canCreate ? 'Neuer Kontakt' : canView ? 'Kontakt ansehen' : 'Kontakt'}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {(canCreate || canEdit) ? 'Abbrechen' : 'Schließen'}
                    </Button>
                    {(canCreate || canEdit) && <Button
                        isLoading={isLoading}
                        data-test-id="button-save-position"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmitWithOnClose)}
                    >
                        Speichern
                    </Button>}
                </HStack>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} mb={6}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {},
                                })}
                            </React.Fragment>
                        ))}
                    </SimpleGrid>
                </Form>
            </ModalBody>
        </ModalContent>
    );
};

export const useContacts = ({ searchTerm = '' }: any = {}) => {
    const { canEdit, canDelete } = usePermission('masterData.contacts');

    log.trace('useContacts', { searchTerm });

    // ===============================================
    // LAZY LISTING
    // ===============================================

    const context = { clientName: 'ucpw' };

    const [load, { data, loading: lazyLoading, refetch }] = useLazyQuery(ListContactDocument, {
        context,
    });
    const contacts: any = data?.items?.items || [];
    const pageCount = data?.items?.pageInfo?.pageCount || 0;
    const { fetchData, variables } = useDataLoader(load);
    const { refetchQueries: lazyLoadingRefetchQueries } = useContextAndRefetchQueries(
        ListContactDocument,
        variables
    );

    // ===============================================
    // LAZY SEARCH LISTING
    // ===============================================

    const [searchLoad, { data: searchContactData, loading: searchContactLoading }] = useLazyQuery(
        SearchContactDocument,
        { context }
    );
    const searchContacts = searchContactData?.app?.kontaktSearch?.items || [];
    const searchPageCount = searchContactData?.app?.kontaktSearch?.pageInfo?.pageCount || 0;
    const { fetchData: fetchSearchData, variables: searchVariables } = useDataLoader(searchLoad);
    const fetchSearchDataWithFilters = useFetchDataWithFilter(fetchSearchData, { searchTerm });

    const { refetchQueries: searchContactRefetchQueries } = useContextAndRefetchQueries(
        SearchContactDocument,
        searchVariables
    );





    const options = {
        context,
        refetchQueries: [
            ...lazyLoadingRefetchQueries,
            // FIXME: "Variable 'searchTerm' has coerced Null value for NonNull type 'String!'"
            // ...searchContactRefetchQueries
        ],
    };

    // ===============================================
    // (C)R(UD)
    // ===============================================

    const [createMutation, { loading: createLoading }] = useMutation(
        CreateContactDocument,
        options
    );
    const [updateMutation, { loading: updateLoading }] = useMutation(
        UpdateContactDocument,
        options
    );
    const [deleteMutation, { loading: deleteLoading }] = useMutation(
        DeleteContactDocument,
        options
    );

    const { deleteWithConfirmation } = useDeleteWithConfirmation({
        deleteMutation: async (variables: any) =>
            await mutationGuard(deleteMutation, {
                variables,
                path: 'data.item.error',
                actions: ['addBreadcrumb', 'captureMessage'],
            }),
        refetchQueries: options.refetchQueries,
        dependencies: [deleteMutation, options.refetchQueries],
    });

    const loading =
        lazyLoading || searchContactLoading || createLoading || updateLoading || deleteLoading;

    // ===============================================
    // MODAL
    // ===============================================

    const { onOpen, dispatch } = useModal(
        <ContactModal {...{
            loading,
            createMutation,
            updateMutation: async (variables: any) =>
                await mutationGuard(updateMutation, {
                    variables,
                    path: 'data.item.error',
                    actions: ['addBreadcrumb', 'captureMessage'],
                }),
        }} />
    );

    // ===============================================
    // TABLE
    // ===============================================

    const onClick = React.useCallback(
        (row: any) => {
            if (!canEdit) return;
            dispatch?.({ type: 'setRow', data: { row } });
            onOpen?.();
        },
        [dispatch, onOpen]
    );

    const controls = [
        canEdit && {
            title: 'Bearbeiten',
            props: { onClick },
        },
        canEdit && canDelete && 'divider',
        canDelete && {
            title: 'Löschen',
            props: {
                color: 'red.400',
                onClick: (row: any) => {
                    deleteWithConfirmation({
                        id: row?.original?.id,
                        version: row?.original?.version,
                        forceOverwrite: true,
                    });
                },
            },
        },
    ].filter(Boolean);

    const columns = React.useMemo(() => withRenderers(tables.contactSearch.columns, controls), [deleteWithConfirmation]);
    const hiddenColumns = tables.contactSearch.hiddenColumns;
    const pageSize = 15;

    const onCreate = () => {
        dispatch?.({ type: 'setRow', data: {} });
        onOpen?.();
    };

    const conditionalProps = React.useMemo(() => searchTerm ? ({
        fetchData: fetchSearchDataWithFilters,
        data: searchContacts,
        pageCount: searchPageCount,
    }) : ({
        fetchData,
        data: contacts,
        pageCount,
    }), [searchTerm, searchContacts, contacts, pageCount, searchPageCount])

    console.log('searchVariables', { variables, searchVariables, conditionalProps })

    return {
        columns,
        hiddenColumns,
        loading,
        pageSize: searchTerm ? 100 : pageSize,
        onCreate,
        ...conditionalProps,
    };
};
