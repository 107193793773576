import { DataTable, Card, CardHeader, CardActions, CardContent, PlusButton } from '@ucc/react/ui';
import { useInsurances } from '~/pages/master-data/hooks/useInsurances';
import { HasPermission } from '~/layout/HasPermission';
import { PageHeader } from '~/components/Layout/PageHeader';

export const Insurances = () => {
    const { onCreate, ...dataTableProps } = useInsurances();
    return (
        <>
            <PageHeader>
                <PageHeader.Title>Versicherungen</PageHeader.Title>
            </PageHeader>
            <Card boxShadow="none">
                <CardHeader>
                    <HasPermission resource="masterData.insurances" permission="create">
                        <CardActions>
                            <PlusButton data-test-id="button-new-insurance" onClick={onCreate}>
                                Neue Versicherung hinterlegen
                            </PlusButton>
                        </CardActions>
                    </HasPermission>
                </CardHeader>
                <CardContent>
                    <DataTable {...dataTableProps} />
                </CardContent>
            </Card>
        </>
    );
};
