import { assign, createMachine } from 'xstate';

export type PageMachine = ReturnType<typeof createPageMachine>;

export type PageMachineEvents =
    | { type: 'SET_PAGE_HASH'; pageHash?: string | number }
    | { type: 'SET_BRANCH_ID'; branchId?: number | undefined };

export type Component = React.ReactNode | null;

export type PageMachineContext = {
    pageHash?: string | number;
    branchId?: undefined | number;
    pageHeaderRef?: any;
    pageActionsRef?: any;
};

export const context = {};
export const createPageMachine = () =>
    createMachine(
        {
            id: 'page',
            predictableActionArguments: true,
            tsTypes: {} as import('./pageMachine.typegen').Typegen0,
            schema: {
                context: {} as PageMachineContext,
                events: {} as PageMachineEvents,
            },
            context,
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SET_PAGE_HASH: { actions: 'setPageHash' },
                        SET_BRANCH_ID: { actions: 'setBranchId' },
                    },
                },
            },
        },
        {
            actions: {
                setBranchId: assign((ctx, { branchId }) => ({ branchId })),
                setPageHash: assign((ctx, { pageHash }) => ({
                    pageHash: pageHash || Date.now(),
                })),
            },
        }
    );
